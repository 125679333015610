<div class="main">
  <app-header-section></app-header-section>

  <section class="author-area">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-md-12">
                <!-- Intro -->
                <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1">
                    <div class="intro-content">
                        <span>NFTs</span>
                        <h3 class="mt-3 mb-0">
                          List your NFTs like a Pro
                        </h3>
                        <p class="text-muted mb-0">
                          <small>
                            List to sell one of your NFT could be fun, but if you have tons of NFTs to deal with, that's a pain in the ass. The Pro Listing utility helps you to list all your NFTs with a blink.
                          </small>
                        </p>
                        <p class="text-muted mt-2">
                          <small>
                            Copy and paste a list of the NFTs and prices you want to ask. And all the remaining is a no-brainer.
                          </small>
                        </p>
                    </div>
                </div>
                <!-- Item Form -->
                <div class="item-form card no-hover bg-transparent border-0 shadow-none">
                  <p class="mb-5"><small><b class="text-danger">*</b> Required fields</small></p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-5">
                              <div class="txt-label-form font-weight-bold mb-2">NFTAddress <b class="text-danger">*</b></div>
                              <input [(ngModel)]="nftAddress" type="text" class="form-control pl-4" placeholder="0x3d7b0001e03096d3795Fd5D984AD679467546d73">
                            </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Token Id <b class="text-danger">*</b></div>
                            <input [(ngModel)]="tokenId" type="text" class="form-control pl-4" placeholder="1234">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Blockchain </div>
                            <div class="dropdown">
                              <button *ngIf="blockchain" class="btn dropdown-toggle w-100 py-2 pl-4 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                <img [src]="blockchain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                <span style="text-transform: capitalize;">
                                  {{blockchain.networkName}}
                                </span>
                              </button>
                              <button *ngIf="!blockchain" class="btn dropdown-toggle w-100 py-2 pl-4 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                <span style="text-transform: capitalize;">
                                  -- Select Blockchain --
                                </span>
                              </button>
                              <div id="dropdown-list" class="dropdown-menu dropdown-menu-full">
                                <a *ngFor="let chain of supportChains" class="dropdown-item" (click)="selectBlockchain(chain)">
                                  <img [src]="chain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                  {{chain.networkName}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-12 text-center">
                          <button [disabled]="!(this.nftAddress && this.tokenId && this.blockchain)" (click)="preview()" class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300">
                            Preview
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
