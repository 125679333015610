<div class="main">
  <app-header-section></app-header-section>

  <section class="collections-wrapper">
    <section class="container popular-collections-area">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10">
                <div class="intro mt-5 mt-lg-0 mb-4 mb-lg-5 d-flex justify-content-between align-items-center flex-wrap">
                    <div class="intro-content">
                        <h3 class="mt-0 mb-2">My Collections</h3>
                        <p class="mt-0"><small class="text-muted">Create, curate, and manage collections of unique NFTs to share and sell.</small></p>
                    </div>
                    <div class="my-3">
                      <a class="btn btn-primary text-white" [routerLink]="['/collection/create']">Create a collection</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row items">
          <div *ngFor="let myCollection of myCollections" class="col-12 col-sm-6 col-lg-4 item">
              <div class="card text-center p-0">
                  <div class="image-over">
                      <a [routerLink]="['/collection/' + myCollection.slug]" >
                          <img class="card-img-top" [src]="myCollection.collectionCoverImageUrl | renderImageUrl: 'feature'" alt="">
                      </a>
                      <!-- Seller -->
                      <a class="seller" [routerLink]="['/collection/' + myCollection.slug]" >
                          <div class="active-nft-profile active-nft-profile-header aura-effect-header image-profile-preview img-nft-profile avatar-lg pad1px">
                              <img class="rounded-circle" [src]="myCollection.collectionImageUrl | renderImageUrl: 'logo'" alt="">
                          </div>
                      </a>
                  </div>
                  <!-- Card Caption -->
                  <div class="card-caption col-12 p-0">
                      <!-- Card Body -->
                      <div class="card-body mt-4 px-4 pb-4 mb-3">
                          <a [routerLink]="['/collection/' + myCollection.slug]" >
                              <h5 class="d-flex align-items-center justify-content-center tx-16 mb-2">
                                <span class="text-truncate tx-16">
                                  {{myCollection.collectionName}}
                                </span>
                                <!-- <img [src]="getImageSupportChainByAbbr(myCollection.chain)"
                                [title]="myCollection.chain" width="16" class="ml-1"> -->
                                <i [ngClass]="myCollection.verification ? (myCollection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                                [title]="myCollection.verification ? (myCollection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                                class="ml-1"></i>
                              </h5>
                          </a>
                          <div class="text-truncate mb-4 tx-14"> By <a [routerLink]="['/account']" [queryParams]="{user: myCollection.user}" >you</a></div>
                          <span class="card-description">Explore the {{myCollection.collectionName}} collection</span>
                          <p class="text-muted tx-14 font-weight-bold mt-4">
                            {{myCollection.statistic ? (myCollection.statistic.assets) : '0'}} items
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </section>

  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>

</div>
