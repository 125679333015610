<div class="main">
  <app-header-section></app-header-section>

  <!-- <app-music-box [musicPath]="'assets/sounds/morroc-sound.mp4'"  [volumn]="0.2"></app-music-box> -->

  <section class="author-area">
    <div class="container">

      <button [routerLink]="['/order-list']" class="btn btn-outline-light border text-white-main mt-3 mt-md-0">
        Go Back
      </button>

        <div class="row justify-content-center mt-3" *ngIf="orderInfo">

            <div class="col-12 col-md-12 px-0">
              <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1 mx-auto max-w-700px">
                  <div class="intro-content">
                      <span>P2P Trade</span>
                      <h3 class="mt-3 mb-0">
                        Order #{{orderInfo.orderbookId}}

                      </h3>
                    <p class="text-warning" *ngIf="isOwnOrder">This is your order</p>

                  </div>

              </div>

              <div *ngIf="spend && receive" class="item-form card no-hover bg-transparent card-trade border mt-5 max-w-700px mx-auto">

                  <div class="text-right">
                    <span class="badge badge-{{orderInfo.status == 'created' ? 'warning':orderInfo.status == 'cancel'? 'danger':orderInfo.status == 'closed'? 'success':'secondary'}}">
                      {{orderInfo.status | eventTypeToText}}
                    </span>
                  </div>

                <div class="row">
                  <div class="tx-20 text-white-main font-weight-bold w-100 mb-3">By spend your</div>
                  <div class="bg-sub-main w-100 d-flex justify-content-between align-items-center px-3 py-2 border-radius-10px">
                    <div class="token-name-wrapper">
                      <div class="token-img mr-3">
                        <img class="rounded-circle" src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{chain}}/{{spend.address.toLowerCase()}}.png?raw=true"
                        onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';"/>
                      </div>
                      <div class="token-name">
                        <div class="tx-20 text-white-main">
                          {{spend.symbol}}
                        </div>
                        <div class="tx-14 text-muted">
                          {{spend.name}}
                        </div>
                        <div class="tx-12 text-muted address-token-order">
                          {{spend.address}}
                        </div>
                      </div>
                    </div>
                    <div class="token-amount-wrapper">
                      <div class="tx-13 text-muted text-right">Amount</div>
                      <div class="token-amount text-white-main tx-28 text-right">{{this.orderInfo.acceptedTokenAmountEther | number:'1.0-6'}}</div>
                    </div>
                  </div>
                  <div class="text-right text-white-main w-100 tx-14 mt-2">
                    Balance: {{spend.balance | number:'1.0-6'}} {{spend.symbol}}
                  </div>
                </div>
                <div class="mt-3 mb-2 text-center">
                  <a class="btn btn-icon-swap border rounded-circle shadow-none cursor-default">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-down-up text-white-main" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                  </a>
                </div>
                <div class="row mb-5">
                  <div class="tx-20 text-white-main font-weight-bold mb-2 w-100">You will get</div>
                  <div class="bg-sub-main w-100 d-flex justify-content-between align-items-center px-3 py-2 border-radius-10px">
                    <div class="token-name-wrapper">
                      <div class="token-img mr-3">
                        <img class="rounded-circle" src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{chain}}/{{receive.address.toLowerCase()}}.png?raw=true"
                        onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';"/>
                      </div>
                      <div class="token-name">
                        <div class="tx-20 text-white-main">
                          {{receive.symbol}}
                        </div>
                        <div class="tx-14 text-muted">
                          {{receive.name}}
                        </div>
                        <div class="tx-12 text-muted address-token-order">
                          {{receive.address}}
                        </div>
                      </div>
                    </div>
                    <div class="token-amount-wrapper">
                      <div class="tx-13 text-muted text-right">Amount</div>
                      <div class="token-amount text-white-main tx-28 text-right">{{this.orderInfo.itemAmountEther | number:'1.0-6'}}</div>
                    </div>
                  </div>
                  <div class="text-right text-white-main w-100 tx-14 mt-2">
                    Balance: {{receive.balance | number:'1.0-6'}} {{receive.symbol}}
                  </div>
                </div>
                <ng-container *ngIf="!isLoading && orderInfo.status === 'created'">
                  <div class="col-12 text-center mt-3" *ngIf="shouldApprove && !isOwnOrder">
                    <button (click)="approve()" class="btn btn-primary px-4 text-white w-100 max-w-300">
                      Approve
                    </button>
                  </div>
                  <div class="col-12 text-center mt-3" *ngIf="!shouldApprove && !isOwnOrder">

                    <button *ngIf="!isInsufficient()" (click)="buy()" class="btn btn-primary px-4 text-white w-100 max-w-300">
                      Buy
                    </button>
                    <button *ngIf="isInsufficient()" class="btn btn-default px-4 text-white w-100 max-w-300">
                      Insufficient {{spend.symbol}} balance
                    </button>
                  </div>
                  <div class="col-12 text-center mt-3" *ngIf="isOwnOrder">
                    <button (click)="cancel()" class="btn btn-danger px-4 text-white w-100 max-w-300">
                      Cancel Listing
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="isLoading">
                  <div class="text-center">
                    <app-spinner></app-spinner>
                  </div>
                </ng-container>
              </div>
            </div>
        </div>
    </div>
  </section>

  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>
</div>

