import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page-not-authorized',
  templateUrl: './error-page-not-authorized.component.html',
  styleUrls: ['./error-page-not-authorized.component.scss']
})
export class ErrorPageNotAuthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
