import { Component, OnInit } from '@angular/core';
import {MiningService} from "../../services/mining.service";
import { Logger } from '../../services/logger.service';
const log = new Logger('MiningPoolHealthComponent');


@Component({
  selector: 'app-mining-pool-health',
  templateUrl: './mining-pool-health.component.html',
  styleUrls: ['./mining-pool-health.component.scss']
})
export class MiningPoolHealthComponent implements OnInit {

  pools:any;
  addresses:[];
  poolInfos:any;

  constructor(
    private miningService:MiningService
  ) { }

  async ngOnInit(){
    this.pools = await this.miningService.getPools()
    this.addresses = this.pools.map(p => p.address);

    log.debug(this.addresses)

    this.miningService.getPoolInfos(this.addresses)
  }



}
