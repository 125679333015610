import { MiningService } from 'src/app/services/mining.service';
import { FreeCityContractService } from './free-city-contract.service';
import { BackendService } from 'src/app/services/backend.service';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
// import moment from 'moment'
import BigNumber from 'bignumber.js';

import { ethers } from "ethers";

import { Logger } from './logger.service';
const log = new Logger('SingletonService');

import {formatNumber, formatPercent} from '@angular/common';
import { TOKEN_TYPE } from '../constant/valuables';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {
  public readonly SUPPORT_FILE_EXTENTIONS = ["jpg","jpeg","png","gif"]
  public readonly ALLOW_FILE_SIZE_MAP = {
    "nft": {
      size:  100*1024*1024,
      displaySize: "100 MB"
    },
    "default": {
      size:  5*1024*1024,
      displaySize: "5 MB"
    },
  }

  public GLOBAL_DATETIME_FORMAT:string = 'DD-MM-YYYY hh:mm A Z'

  public supportChains = [
    {
      networkId:96, // **required in adding chain**
      networkName:"Bitkub Chain", // **required in adding chain**
      networkAbbr:"bkc",
      rpcUrls: ["https://rpc.bitkubchain.io/"], // **required in adding chain**
      nativeCurrency: { // **required in adding chain**
        name: "KUB COIN",
        symbol: "KUB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://bkcscan.com/"], // **required in adding chain**
      imgUrl: "../../assets/img/chains/bkc.png",
      scanUrl:'https://bkcscan.com',
      scanNFTUrl: "https://bkcscan.com/tokens/${nftAddress}/instance/${tokenId}/metadata",
      addressUrl: "https://bkcscan.com/address/",
      // enabled: true,
      enabled: false,
      use:'rpc', // rpc or wss
      rpc:'https://rpc.bitkubchain.io',
      wss:'wss://wss.bitkubchain.io',
      blockTimeSecond: 5, // use to calculate timeleft or countdown
    },
    {
      networkId:25925, // **required in adding chain**
      networkName:"Bitkub Chain - Testnet", // **required in adding chain**
      networkAbbr:"bkctestnet",
      rpcUrls: ["https://rpc-testnet.bitkubchain.io"], // **required in adding chain**
      nativeCurrency: { // **required in adding chain**
        name: "KUB COIN",
        symbol: "KUB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://testnet.bkcscan.com/"], // **required in adding chain**
      imgUrl: "../../assets/img/chains/bkc.png",
      scanUrl:'https://testnet.bkcscan.com',
      scanNFTUrl: "https://testnet.bkcscan.com/tokens/${nftAddress}/instance/${tokenId}/metadata",
      addressUrl: "https://testnet.bkcscan.com/address/",
      enabled: true,
      soon:false,
      use:'rpc', // rpc or wss
      rpc:'https://rpc-testnet.bitkubchain.io',
      wss:'wss://wss-testnet.bitkubchain.io',
      blockTimeSecond: 5, // use to calculate timeleft or countdown
    },
    {
      networkId:137, // **required in adding chain**
      networkName:"Polygon", // **required in adding chain**
      networkAbbr:"polygon",
      rpcUrls: ["https://polygon-rpc.com"], // **required in adding chain**
      nativeCurrency: { // **required in adding chain**
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"], // **required in adding chain**
      imgUrl: "../../assets/img/chains/polygon.png",
      scanUrl:'https://polygonscan.com',
      scanNFTUrl: undefined,
      addressUrl: "https://polygonscan.com/address/",
      enabled: true,
      soon:true
    },
    {
      networkId:80001, // **required in adding chain**
      networkName:"Mumbai Polygon Testnet", // **required in adding chain**
      networkAbbr:"polygontestnet",
      rpcUrls: ["https://rpc-mumbai.maticvigil.com"], // **required in adding chain**
      nativeCurrency: { // **required in adding chain**
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"], // **required in adding chain**
      imgUrl: "../../assets/img/chains/polygon.png",
      scanUrl:'https://mumbai.polygonscan.com',
      scanNFTUrl: undefined,
      addressUrl: "https://mumbai.polygonscan.com/address/",
      enabled: true,
      soon:false,
      use:'rpc', // rpc or wss
      rpc:'https://rpc-mumbai.maticvigil.com',
      wss:'wss://rpc-mumbai.matic.today',
    },
    {
      networkId:56, // **required in adding chain**
      networkName:"Binance Smart Chain", // **required in adding chain**
      networkAbbr:"Binance Smart Chain",
      rpcUrls: ["https://bsc-dataseed.binance.org/"], // **required in adding chain**
      nativeCurrency: { // **required in adding chain**
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://bscscan.com/"], // **required in adding chain**
      imgUrl: "../../assets/img/chains/bsc.png",
      scanNFTUrl: undefined,
      scanUrl:'https://bscscan.com/',
      addressUrl: "https://bscscan.com/address/",
      enabled: true,
      soon:true
    }


  ]

  public readonly customFieldsBySlug = [ // override using fields in anypage with slug (because ipfs cannot change)
    {
      slug: "yes-nft-exclusive",
      description: "“YES NFT” ลิมิเต็ดอิดิชันมีเพียง 100 ชิ้นเท่านั้น สามารถนำไปแลก (Redeem) เป็น iPhone 14 Pro Max 256GB สี Deep Purple ได้แล้วโดยการกดปุ่ม Redeem เพื่อกรอกข้อมูล ที่อยู่ การจัดส่ง iPhone ในกรณีต้องการรับแบบ Delivery หรือหากต้องการรับแบบรวดเร็วทันใจด้วยตัวเอง สามารถเดินทางมารับที่ตึก Sathorn Square ติดกับ BTS ช่องนนทรี ได้ทันที!",
      isEnabledShowcaseItems: true,
      showcaseItems: [
        {
          imageUrl: "../../assets/img/campaign/showcase-items/iPhone_14_Pro_Max_Deep_purple_256GB.png",
          imageClass: "item-showcase-image",
          innerHtmlText: `
            <div>iPhone 14 Pro Max</div>
            <div>
              <span class="color-deep-purple">Deep purple</span>
              256GB
            </div>
          `,
        },
      ]
    },
  ]

  public provider:any
  public publicProvider:any;

  public account:any
  public accountSubject = new Subject<any>();

  public isLoginAndLogoutWithoutRedirectToHomePage = false

  public currentChain: Observable<any>;
  public currentChainSubject: BehaviorSubject<any>;

  public isWrongNetwork: Observable<boolean>;
  public isWrongNetworkSubject: BehaviorSubject<boolean>;

  public currentUserInfos: Observable<any>;
  public currentUserInfosSubject: BehaviorSubject<any>;

  public currentLanguage = new Subject<any>();


  constructor(
    private router:Router,
    private http:HttpClient,
    private injector: Injector,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.currentChainSubject = new BehaviorSubject<any>(undefined);
    this.currentChain = this.currentChainSubject.asObservable();

    this.isWrongNetworkSubject = new BehaviorSubject<any>(undefined);
    this.isWrongNetwork = this.isWrongNetworkSubject.asObservable();

    this.currentUserInfosSubject = new BehaviorSubject<any>(undefined);
    this.currentUserInfos = this.currentUserInfosSubject.asObservable();

    this.getAccountEtherjs('99')
  }

  getLocal(){
    return this.locale
  }

  getAccountSubject():Observable<any>{
    return this.accountSubject.asObservable()
  }

  async initProvider(){ // metamask or EVM
    this.provider = await new ethers.providers.Web3Provider(window.ethereum)
    // log.debug("this.provider : %o",this.provider)
    await this.getPublicProvider()
  }

  async getPublicProvider(force = false){
    log.debug("getPublicProvider this.publicProvider => %o",this.publicProvider)

    if(!this.publicProvider || force){
      log.debug("create new this.publicProvider...")
      let chainId = await this.getCurrentChainId()
      log.debug("getCurrentChainId chainId => %o",chainId)
      let chain = await this.getSupportChain(chainId,true)
      log.debug("getSupportChain chain => %o",chain)
      if (chain && chain.use == 'wss') {
        this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
      } else if (chain && chain.use == 'rpc') {
        this.publicProvider = await new ethers.providers.JsonRpcProvider(chain.rpc)
      } else {
        // this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
      }
    }
    return this.publicProvider
  }

  async getSupportChain(chainId,ignoreEnabled = false){
    if(ignoreEnabled){
      let chain = this.supportChains.find(it => it.networkId == chainId) // find all
      return chain
    }else{
      let chain = this.supportChains.find(it => it.networkId == chainId && it.enabled) // find by enabled
      return chain
    }

  }

  async connectWallet(){
    await localStorage.setItem("connectStatus",'1')
    const _account = await this.getAccountEtherjs()
    this.provider = await new ethers.providers.Web3Provider(window.ethereum)
    // log.debug("connectWallet _account : %o",_account)
    return _account
  }

  getCurrentUserAndTokens(){
    let currentUser =  JSON.parse( localStorage.getItem("currentUser"))
    return currentUser
  }

  getLocalCurrentUserInfos(){
    let currentUser:any =  this.getCurrentUserAndTokens()
    // log.debug("getTokens currentUser : %o",currentUser)
    return currentUser ? currentUser.user : undefined
  }

  getTokens(){
    let currentUser:any =  this.getCurrentUserAndTokens()
    // log.debug("getTokens currentUser : %o",currentUser)
    return currentUser ? currentUser.tokens : undefined
  }

  getAccessToken(){
    let tokens:any =  this.getTokens()
    // log.debug("getAccessToken tokens : %o",tokens)
    return tokens ? (tokens.access ? tokens.access.token : undefined) : undefined
  }

  getAccessTokenObject(){
    let tokens:any =  this.getTokens()
    if(tokens && tokens.access){
      return tokens.access;
    }
  }

  getRefreshTokenObject(){
    let tokens:any =  this.getTokens()
    if(tokens && tokens.refresh){
      return tokens.refresh;
    }
  }

  isValidAccessToken(){
    let valid = false;
    const accessToken = this.getAccessTokenObject();
    if(accessToken && accessToken.token && accessToken.expires){
      const now = new Date();
      if(Date.parse(accessToken.expires) > now.getTime()){
        valid = true;
      }
    }
    return valid;
  }

  isValidRefreshToken(){
    let valid = false;
    const refreshToken = this.getRefreshTokenObject();
    if(refreshToken && refreshToken.token && refreshToken.expires){
      const now = new Date();
      if(Date.parse(refreshToken.expires) > now.getTime()){
        valid = true;
      }
    }
    return valid;
  }

  updateTokens(tokens){
    const userAndTokens = this.getCurrentUserAndTokens();
    if(userAndTokens){
      userAndTokens.tokens = tokens;
      localStorage.setItem('currentUser', JSON.stringify(userAndTokens));
    }
  }

  getRefreshToken(){
    let tokens:any =  this.getTokens()
    return tokens ? (tokens.refresh ? tokens.refresh.token : undefined) : undefined
  }

  async signinToServer(walletAddress){
    log.debug("prepare to signin to server.... wallet : ", walletAddress);
    try{

      // check if localstorage has current user or not
      let accessToken = this.getAccessTokenObject()
      log.debug('found accessToken', accessToken);
      let now = new Date()
      if(accessToken){
        log.debug(`compare now=${now.getTime()}>${Date.parse(accessToken.expires)}`);
      }
      if(accessToken == undefined || now.getTime() > Date.parse(accessToken.expires)){
        log.debug("access token is none or expired let sign in.")
        const signInResp:any = await this.singinWithMetamask(walletAddress);

        /* let wallet = new ethers.Wallet(walletAddress);
        let signature = await wallet.signMessage(singinResp.data.msg);*/
        log.debug(`sign message from server %o`, signInResp.data.msg)
        const signer = this.provider.getSigner();
        const address = await signer.getAddress();
        log.debug(`signer address %o`, address);
        let signature = await signer.signMessage(signInResp.data.msg);
        const userAndTokens:any = await this.loginWithMetamask(walletAddress, signature)
        //log.debug("signinToServer user: %o",user);
        await localStorage.setItem('currentUser', JSON.stringify(userAndTokens));
        await this.updateCurrentUserInfos(userAndTokens.user)
      }else{
        log.debug("access token still not expired so continued with out sign in")
        let _user = this.getLocalCurrentUserInfos()
        await this.updateCurrentUserInfos(_user)
      }

      return walletAddress
    }catch(error){
      console.error("error on signinToServer %o",error)
      if (error.code === 4001) {
        log.debug("User reject request.")
      }else{
        log.debug("error singin to server %o",error)
        if(error.error){
          this.fire("error","Error code: " + error.error.code,error.error.message)
        }else{
          if(error.message){
            this.fire("error",error.message)
          }else{
            if(error == 'Unknown Error'){
              this.fire("error",error,"Cannot connect to server!")
            }else{
              this.fire("error",error)
            }
          }
        }
      }
      return await this.disconnectWallet()
    }
  }

  async refreshTokenPromise(refreshToken){ // use only connecting with metamask
    const url = `${environment.apiUrl}/v1/auth/refresh-tokens`
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          refreshToken: refreshToken,
        }
        log.debug("refreshTokenPromise url,data : %o,%o",url,data)
        let tokens = await this.http.post(url, data).toPromise()
        log.debug("refreshTokenPromise tokens %o",tokens)
        if(tokens){
          const currentUser = localStorage.get
          await this.updateTokens(tokens)
          resolve(tokens)
        }else{
          resolve(null)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  renewTokenWithRefreshToken(refreshToken){
    const url = `${environment.apiUrl}/v1/auth/refresh-tokens`
    const data = {
      refreshToken: refreshToken,
    }
    return this.http.post(url, data);
  }

  async loginWithMetamask(walletAddress,signature){
    const url = `${environment.apiUrl}/v1/auth/signature/authentication`
    const data = {
      publicAddress: walletAddress,
      signature: signature
    }
    // log.debug("loginWithMetamask url: %o",url);
    // log.debug("loginWithMetamask data: %o",data);
    let res = await this.http.post(url, data).toPromise()
    // log.debug("loginWithMetamask res: %o",res);
    return res
  }

  async singinWithMetamask(walletAddress){
    const url = `${environment.apiUrl}/v1/auth/signature/singin`
    const data = {
      publicAddress: walletAddress,
    }
    // log.debug("singinWithMetamask url: %o",url);
    // log.debug("singinWithMetamask data: %o",data);
    let res = await this.http.post(url, data).toPromise()
    // log.debug("singinWithMetamask res: %o",res);
    return res
  }

  async disconnectWallet(){
    log.info('disconnectWallet called');
    await localStorage.setItem("connectStatus",'0')
    await localStorage.removeItem("walletType")
    await localStorage.removeItem('currentUser');
    this.accountSubject.next(null)
    this.account = null

    if(!this.isLoginAndLogoutWithoutRedirectToHomePage){
      await this.router.navigate(['/'])
    }

    return null
  }

  async getAccountEtherjs(debug = '-1'){

    // log.debug("debug : %o",debug)
    const enableWallet = await localStorage.getItem("connectStatus")
    // log.debug("enableWallet : %o",enableWallet)
    const userAndTokens = JSON.parse( localStorage.getItem("currentUser"));
    if(enableWallet  === '1'){
      if(!this.provider){
        await this.initProvider()
      }
      this.account = await this.getCurrentConnectedAccount()
      log.debug("getAccountEtherjs ",this.account)
      log.debug("tokens ", userAndTokens);
      log.debug("isValidAccessToken=%o", this.isValidAccessToken())
      log.debug("isValidRefreshToken=%o", this.isValidRefreshToken())
      let reconnect = true
      if(this.account && userAndTokens && userAndTokens.user && userAndTokens.user.id && userAndTokens.user.id == this.account){
        reconnect = false
      }
      log.debug(`reconnect = ${reconnect}`);
      if(reconnect){
        try {
          const walletAddress = await this.signinToServer(this.account)
          log.debug(`--- xxxx walletAddress = ${walletAddress}`);

          if(walletAddress){
            await this.updateConnectedWallet(this.account)
            return this.account;
          }
          log.debug(`remove currentUser not success`);
          localStorage.removeItem("currentUser");
          if(!this.isLoginAndLogoutWithoutRedirectToHomePage){
            location.reload();
          }
        } catch (error) {
          log.error(error);
          log.debug(`remove currentUser not success`);
          localStorage.removeItem("currentUser");
          if(!this.isLoginAndLogoutWithoutRedirectToHomePage){
            location.reload();
          }
        }
      }else{
        // use current user localstorage
        const user = userAndTokens.user
        const walletAddress = user.publicAddress
        if(walletAddress){
          await this.updateConnectedWallet(walletAddress)
          return walletAddress;
        }
      }
    }

    log.debug('return account ', this.account);
    return this.account;
  }

  async getCurrentConnectedAccount(){
    try{
      if(this.provider){
        const _accounts = await this.provider.send("eth_requestAccounts", []);
        return _accounts[0];
      }

    }catch(error){
      log.error(`error on get currentConnectedAccount`, error);
      this.fire('error', "Something wrong!", error.message)
    }
  }


  async updateConnectedWallet(walletAddress:any){
    log.debug("updateConnectedWallet walletAddress => %o",walletAddress)

    this.account = walletAddress
    this.accountSubject.next(walletAddress)
    await this.getCurrentChain()
  }

  async getCurrentChain(){
    if(!this.provider){
      await this.initProvider()
    }
    let currentChain
    let _chainId = await this.getCurrentChainId()
    if(_chainId){
      await this.subscribeChainChanged()
      await this.subscribeAccountsChanged()
      currentChain = await this.isSupportedChain(_chainId,true)
    }
    // this.currentChainSubject.next(currentChain)
    return currentChain
  }

  async getCurrentChainId(){
    try{
      let _chainId = await this.provider.getNetwork()
      return _chainId.chainId
    }catch (e){
      return environment.chainId
    }

  }

  // get from environment depends on which network we are testing
  async getChainAbbr(){
    return environment.networkAbbr
  }

  async subscribeChainChanged(){
    log.debug("subscribeChainChanged...",)

    window.ethereum.on('chainChanged', async (_networkId:any) =>{
    log.debug("chainChanged... _networkId => %o",_networkId)
      // this.isSupportedChain(Number(_networkId))
      location.reload();
    });
  }

  async subscribeAccountsChanged(){
    window.ethereum.on('accountsChanged', async (_account:any) =>{
      log.debug("accountsChanged => %o",_account)

      // await localStorage.removeItem('currentUser');
      const acc = await this.getAccountEtherjs();

      location.reload();
    });
  }


  async isSupportedChain(chainId:Number,isReturnWithObject:boolean = false){
    let supportChain = this.supportChains.find(it => it.networkId == chainId && it.enabled)

      if(!supportChain){
        log.debug("you're in a NOT support chain.")
        this.isWrongNetworkSubject.next(true)
        this.currentChainSubject.next(null)
        if(isReturnWithObject){
          return null
        }else{
          return false
        }
      }else{
        log.debug("you're in a support chain.")
        this.isWrongNetworkSubject.next(false)
        this.currentChainSubject.next(supportChain)
        if(isReturnWithObject){
          return supportChain
        }else{
          return true
        }
      }
    // }
  }

  async fire(type = 'error', title = 'Error', text = ''){
    // @ts-ignore
    Swal.fire({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000,
        icon: type,
        title: title,
        text: text,
      })
  }

  async switchToSupportChain(){
    const CHAIN = this.supportChains.find(it => it.networkId == environment.chainId )
    await this.switchChain(CHAIN)
  }

  async switchToBitkubChain(){
    const BITKUB_CHAIN = this.supportChains.find(it => it.networkId == 96)
    await this.switchChain(BITKUB_CHAIN)
    await this.router.navigate(['/'])
  }

  async switchToBitkubChain_testnet(){
    const BITKUB_CHAIN_TESTNET = this.supportChains.find(it => it.networkId == 25925)
    await this.switchChain(BITKUB_CHAIN_TESTNET)
    await this.router.navigate(['/'])
  }

  async checkCurrentChain(){
    const _chainId:any = await this.getCurrentChainId()
    this.isSupportedChain(_chainId)
  }

  async switchChain(chain:any){
    let hexChainID = '0x' + chain.networkId.toString(16)
    let chainName = chain.networkName

    let params = [{
      chainId: hexChainID,
      chainName: chain.networkName,
      rpcUrls: chain.rpcUrls,
      nativeCurrency: chain.nativeCurrency,
      blockExplorerUrls: chain.blockExplorerUrls,
    }]

    // this.fire("success","switching chain")

    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainID }], // chainId must be in hexadecimal numbers
      });

      Swal.fire({
        toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success to switch to ' + chainName, icon: 'success'
      })

      await setTimeout(() => {
        window.location.reload()
      }, 1000);
      // await this.checkCurrentChain()

    } catch (error) {
      // this.fire("error","error on wallet_switchEthereumChain")

      const errorCode = error.data ? (error.data?.originalError ? error.data?.originalError?.code : 0 ) : 0
      // logger.error("error on switch chain: %o",error);
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      // this.fire("error","errorCode: " + errorCode,"error.code: " + error.code)

      if (error.code === 4902 || errorCode == 4902 || error.code === -32603 || errorCode == -32603) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: params,
          });
        } catch (addError) {
          // logger.error("error on add chain: %o",addError);
        }
      }
    }

  }


  async switchLanguage(lang){
    if(localStorage.getItem('appLanguage')){
      localStorage.removeItem('appLanguage')
      localStorage.setItem('appLanguage',lang)
    }else{
      localStorage.setItem('appLanguage',lang)
    }
    this.currentLanguage.next(lang)
  }


  async getReservedWordWithPartRoute(partRoute = "collection/",isCheckNotReserved = false,checkedWord = ''){
    // log.debug('configured routes: ', this.router.config)
    let reserveWords:any = []
    await this.router.config.filter(it => it.path.startsWith(partRoute)).map((it) => {
      let pathStrings = it.path.split("/")
      let reserveString = pathStrings[1]
      if(reserveString && !reserveString.startsWith(":")){
        // log.debug('reserveString: ', reserveString)
        reserveWords.push(reserveString)
      }
    })

    // log.debug('reserveWords: ', reserveWords)
    if(isCheckNotReserved){
      return reserveWords.find(reserveWord => reserveWord.toLowerCase() != checkedWord.toLowerCase()) ? true : false
    }else{
      return reserveWords
    }
  }

  async updateCurrentUserInfos(currentUserInfos){
    let currentUserAndTokens = await this.getCurrentUserAndTokens()
    currentUserAndTokens.user = currentUserInfos


    log.debug("updateCurrentUserInfos currentUserAndTokens: %o",currentUserAndTokens)

    await localStorage.setItem('currentUser', JSON.stringify(currentUserAndTokens));
    this.currentUserInfosSubject.next(currentUserInfos)
  }

  async getCurrentUserInfos(){
    if(!this.account){
      await this.getAccountEtherjs("from getCurrentUserInfos")
    }else{
      if(!this.account){
        // log.debug("0000000000000000000000000000000 ");
        this.currentUserInfosSubject.next(undefined)
        return undefined
      }else{
        if(!this.currentUserInfosSubject.value){
          // log.debug("xxxxxxxxxxxxxxxxxx111111111111 ");
          let res = await this.getCurrentUserProfileInfos()
          // log.debug("xxxxxxxxxxxxxxxxxx111111111111 res %o",res);

          this.currentUserInfosSubject.next(res)
          return this.currentUserInfosSubject.value
        }else{
          // log.debug("222222222222222222222222222222222 ");
          return this.currentUserInfosSubject.value
        }
      }
    }
  }

  async updateCurrentUserProfileImageByType(formData){
    try{
      const url = `${environment.apiUrl}/v1/users/profile/image`
      log.debug("updateCurrentUserProfileImage url: %o",url);
      let res = await this.http.post(url,formData).toPromise()
      log.debug("updateCurrentUserProfileImage res: %o",res);
      return res

    }catch(error){
      let resError = {
        title: "Error on updateCurrentUserProfileImage",
        error: error,
      }
      throw(resError)
    }
  }

  async updateCurrentUserProfileInfos(name,bio,socialmedia,email){
    try{

      let data = {
        "name": name,
        "bio": bio,
        "socialmedia": socialmedia,
        "email": email,
      }
      log.debug("updateCurrentUserProfileInfos data: %o",data);

      const url = `${environment.apiUrl}/v1/users/profile/update`
      log.debug("updateCurrentUserProfileInfos url: %o",url);
      let res = await this.http.post(url,data).toPromise()
      log.debug("updateCurrentUserProfileInfos res: %o",res);
      return res

    }catch(error){
      let resError = {
        title: "Error on Update User Profile Infos",
        error: error,
      }
      throw(resError)
    }
  }

  async checkUserSlug(slug){
    try{
      const url = `${environment.apiUrl}/v1/users/info/slug?slug=` + slug
      log.debug("checkUserSlug url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("checkUserSlug res: %o",res);
      return res

    }catch(error){
      let resError = {
        title: "Error on checkUserSlug",
        error: error,
      }
      throw(resError)
    }
  }

  async getCurrentUserProfileInfos(){
    try{

      const url = `${environment.apiUrl}/v1/users/profile/info`
      log.debug("getCurrentUserProfileInfos url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("getCurrentUserProfileInfos res: %o",res);
      return res

    }catch(error){
      let resError = {
        title: "Error on getCurrentUserProfileInfos",
        error: error,
      }
      throw(resError)
    }
  }

  async getUserProfileInfosBySlug(slug){
    try{

      const url = `${environment.apiUrl}/v1/users/slug/` + slug
      log.debug("getUserProfileInfosBySlug url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("getUserProfileInfosBySlug res: %o",res);
      return res

    }catch(error){
      let resError = {
        title: "Error on getUserProfileInfosBySlug",
        error: error,
      }
      throw(resError)
    }
  }

  copyCode(textValue) {
    this.fire('success', "Copy Success!", textValue)
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = textValue;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
  }


  async isNotSupportFileExtension(file){
    let fileType = file.type.split("/").pop()
    log.debug("fileType => %o",fileType)
    let isNotSupportExtention = (this.SUPPORT_FILE_EXTENTIONS.indexOf(fileType) == -1)
    if(isNotSupportExtention){
      this.fire("error","("+fileType+") File extension is not support.","Only inlist: " + this.SUPPORT_FILE_EXTENTIONS)
      return true
    }else{
      return false
    }
  }

  async isOverSizeFile(file,type='default'){
    let fileSize = file.size
    log.debug("fileSize => %o",fileSize)
    if(fileSize > this.ALLOW_FILE_SIZE_MAP[type.toLowerCase()].size){
      this.fire("error","The file size is larger than the specified size.","Maximun size: " + this.ALLOW_FILE_SIZE_MAP[type.toLowerCase()].displaySize)
      return true
    }else{
      return false
    }
  }

  async isAddressZero(address){
    // log.debug("ethers.constants.AddressZero =>> %o",ethers.constants.AddressZero)
    // log.debug("address =>> %o",address)

    if(address == ethers.constants.AddressZero){
      // log.debug("isAddressZero =>> true")
      return true
    }else{
      // log.debug("isAddressZero =>> false")
      return false
    }
  }

  async modalConfirmBuy(lastestOrder,nftName,nftAddress,royaltyFee){
    log.debug("modalConfirmBuy lastestOrder =>> %o",lastestOrder)
    log.debug("modalConfirmBuy nftName =>> %o",nftName)
    log.debug("modalConfirmBuy nftAddress =>> %o",nftAddress)
    log.debug("modalConfirmBuy royaltyFee =>> %o",royaltyFee)

    let isConfirmBuy = false

    const PRICE = formatNumber(lastestOrder.acceptedTokenAmountNumber,this.locale,'1.0-6')
    const PRICE_UNIT = lastestOrder.acceptedTokenSymbol.toUpperCase()

    await Swal.fire({
      title: 'You are going to buy '+nftName+' with '+PRICE+' '+PRICE_UNIT,
      // showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Buy',
      // denyButtonText: `Don't save`,
      html:
      // '<p>Royalty Fee: <b id="royalty-fee"></b>'+  formatNumber(feePercent,this.locale,'1.2-2') +'%' +
      // '<br>Platform fee: <b id="platform-fee"></b>'+  formatNumber(devFeePercent,this.locale,'1.2-2') +'%</p> ' +
      '<p>Royalty fee: <b id="royalty-fee"></b>' +
      '<br>Platform fee: <b id="platform-fee"></b>' +
      '',
      didOpen:async () => {
        const freeCityContractService = await this.injector.get(FreeCityContractService);

        const devFeePercent = await freeCityContractService.getDevFeePercent()
        log.debug("devFeePercent =>> %o",devFeePercent)

        let feePercent = 0
        if(await freeCityContractService.isFreeCityNFTAddress(nftAddress)){
          feePercent = royaltyFee
        }else{
          feePercent = await freeCityContractService.getFeePercent(lastestOrder.itemAddr)
          log.debug("feePercent =>> %o",feePercent)
        }



        const container = await Swal.getHtmlContainer()
        const royaltyFeeElement = await container.querySelector('#royalty-fee')
        const freeCityFeeElement = await container.querySelector('#platform-fee')

        log.debug("royaltyFeeElement =>> %o",royaltyFeeElement)
        log.debug("freeCityFeeElement =>> %o",freeCityFeeElement)

        if(royaltyFeeElement) royaltyFeeElement.textContent = formatNumber(feePercent,this.locale,'1.2-2') +'%'
        if(freeCityFeeElement) freeCityFeeElement.textContent = formatNumber(devFeePercent,this.locale,'1.2-2') +'%'
      },
      preConfirm: async () => {
      }
    }).then( async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        log.debug("result.isConfirmed =>> %o",result.isConfirmed)
        isConfirmBuy = true
      }
    })

    log.debug("modalConfirmBuy isConfirmBuy =>> %o",isConfirmBuy)
    return isConfirmBuy
  }

  async modalConfirmApprove(lastestOrder,nftName){

    let isConfirmApproved = false

    await Swal.fire({
      title: 'You are going to approve '+nftName+' for buying NFT from FreeCity market',
      // showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Approve',
      // denyButtonText: `Don't save`,
      preConfirm: async () => {
        await Swal.showLoading()
        // await Swal.disableButtons();
        // setTimeout(() => { Swal.hideLoading() }, 1000)
        const freeCityContractService = await this.injector.get(FreeCityContractService);
        // this.isApproveLoading = true

        // try{
          let receipt = await freeCityContractService.approveToken(lastestOrder.acceptedTokenAddr)
          if(receipt){
            await this.fire('success','Approve Success', 'Success approve token to buy nft on market.')
            await Swal.hideLoading()
          }else{
            await Swal.hideLoading()
          }
        // }catch(error){
        //   log.error("error on approveToken",error)
        //   await this.fire('error','Approve failed', 'ERROR: '+ (error.data ? error.data.message : error.message))
        //   await Swal.hideLoading()
        // }
      }
    }).then( async (result) => {
      log.debug("result =>> %o",result)

      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        log.debug("result.isConfirmed =>> %o",result.isConfirmed)
        isConfirmApproved = true
      }
    }).catch(async (error) => {
      log.error("error on approveToken",error)
      await this.fire('error','Approve failed', 'ERROR: '+ (error.data ? error.data.message : error.message))
      await Swal.hideLoading()
    })

    log.debug("modalConfirmApprove isConfirmApproved =>> %o",isConfirmApproved)
    return isConfirmApproved

  }

  async modalCheckApproved(
    lastestOrder,
    nftName,
    nftAddress,
    royaltyFee,
    isBatchBuy = false,
  ){


      log.debug("modalCheckApproved =>> %o",lastestOrder)

      if(lastestOrder && lastestOrder.acceptedTokenAddr != ethers.constants.AddressZero){
        const freeCityContractService = await this.injector.get(FreeCityContractService);

        log.debug("modalCheckApproved lastestOrder.acceptedTokenAddr = "+lastestOrder.acceptedTokenAddr)
        log.debug("modalCheckApproved lastestOrder.acceptedTokenAmount = "+lastestOrder.acceptedTokenAmount)

        const isApproved = await freeCityContractService.checkTokenApprovalForMarket(
          lastestOrder.acceptedTokenAddr,
          lastestOrder.acceptedTokenAmount
        )
        log.debug("isApproved =>> %o",isApproved)

        if(!isApproved){
          const isComfirmApproved = await this.modalConfirmApprove(lastestOrder,nftName) // call modalConfirmApprove
          if(isComfirmApproved){
            if(isBatchBuy){
              return true
            }else{
              return await this.modalConfirmBuy(lastestOrder,nftName,nftAddress,royaltyFee) // call modalConfirmBuy
            }
          }
        }else{
          if(isBatchBuy){
            return true
          }else{
            return await this.modalConfirmBuy(lastestOrder,nftName,nftAddress,royaltyFee) // call modalConfirmBuy
          }
        }
      }else{
        if(isBatchBuy){
          return true
        }else{
          return await this.modalConfirmBuy(lastestOrder,nftName,nftAddress,royaltyFee) // call modalConfirmBuy
        }
      }


  }

  async modalSwitchNetwork(chainAbbr){

    const targetChain = await this.supportChains.find(it => it.networkAbbr == chainAbbr)
    const currentChainId = await this.getCurrentChainId()

    log.debug("chainAbbr =>> %o",chainAbbr)
    log.debug("this.supportChains =>> %o",this.supportChains)
    log.debug("currentChainId =>> %o",currentChainId)
    log.debug("targetChain =>> %o",targetChain)

    if(currentChainId == targetChain.networkId) return false
    else{
      await Swal.fire({
        title: `Please switch to ${targetChain.networkName}`,
        text: `In order to trade items, please switch to ${targetChain.networkName} within your MetaMask wallet.`,
        showCancelButton: true,
        confirmButtonColor: `#3085d6`,
        cancelButtonColor: `#d33`,
        confirmButtonText: `Switch network`,
        // footer: `<a href="">Why do I have this issue?</a>`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.switchToChainByChainId(targetChain.networkId)
          // return true
        }else{
          return true
        }
      })
    }

    return true
  }

  async switchToChainByChainId(chainId,isReloadAsSamePage = true){
    const CHAIN = this.supportChains.find(it => it.networkId == chainId)
    if(CHAIN){
      await this.switchChain(CHAIN)
      if(isReloadAsSamePage){
        await location.reload();
      }else{
        await this.router.navigate(['/'])
      }
    }else{
      this.fire("error","NOT SUPPORT CHAIN ID: " + chainId)
    }
  }

  async getChainETHList(){
    const backendService = this.injector.get(BackendService);
    const chainETHList:any = []
    for (const supportChain of this.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)) {
        const paymentTokens:any = await backendService.getPaymentTokensByChainAbbr(supportChain.networkAbbr)
        // log.debug("paymentTokens => %o",paymentTokens)
        const chainETH = paymentTokens.find(it => it.address == "0x0000000000000000000000000000000000000000")
        chainETHList.push(chainETH)
    }
    // log.debug("chainETHList => %o",chainETHList)
    return chainETHList
  }

  async getPaymentTokenListAllSupportedChain(){
    const backendService = this.injector.get(BackendService);
    let getPaymentTokenListAllSupportedChain:any = []
    log.debug("this.supportChains => %o",this.supportChains)
    for (const supportChain of this.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)) {
        const paymentTokens:any = await backendService.getPaymentTokensByChainAbbr(supportChain.networkAbbr)
        getPaymentTokenListAllSupportedChain = [...getPaymentTokenListAllSupportedChain,...paymentTokens]
    }
    log.debug("getPaymentTokenListAllSupportedChain => %o",getPaymentTokenListAllSupportedChain)
    return getPaymentTokenListAllSupportedChain
  }

  async saveSpendTokensLocalStorage(spendToken){
    let spendTokens:any = await this.getSpendTokensLocalStorage()
    log.debug("saveTokenLocalStorage spendTokens: ",spendTokens)
    spendTokens = spendTokens.filter(it => it.address.toLowerCase() != spendToken.address.toLowerCase())
    delete spendToken.balance;
    spendTokens.push(spendToken)
    await localStorage.setItem("spendTokens", JSON.stringify(spendTokens));
  }

  async getSpendTokensLocalStorage(){
    const spendTokens = JSON.parse(await localStorage.getItem("spendTokens") || "[]");
    log.debug("getSpendTokensLocalStorage spendTokens: ",spendTokens)
    return spendTokens
  }

  async buyNFTFreeCityMarket(asset,isBatchBuy = false){
    const freeCityContractService = this.injector.get(FreeCityContractService);
    const miningService = this.injector.get(MiningService);

    if(!(await this.modalSwitchNetwork(asset.chain))){
      // this.isBuyLoading = true
      if(await this.modalCheckApproved(
        asset.lastestOrder,
        asset.name,
        asset.collection.nftAddress,
        asset.collection.royaltyFee,
        isBatchBuy,
        )
      ){
        log.debug("buy...")
        return freeCityContractService.buy(
          // asset.lastestOrder._id,
          asset.lastestOrder.orderId,
          asset.lastestOrder.acceptedTokenType == 'kub' ? TOKEN_TYPE.KUB : TOKEN_TYPE.ERC20,
          asset.lastestOrder.acceptedTokenAddr,
          asset.lastestOrder.acceptedTokenAmount
        ).then(async (transaction)=>{
          let receipt = await transaction.wait()
          log.debug("receipt events",receipt.events)
          let soldEvent = receipt.events.find((e)=> e.event === 'Sold')
          log.debug("soldEvent ",soldEvent)
          log.debug("order id : ",soldEvent.args.id)
          log.debug("buyer : ",soldEvent.args.buyer)
          log.debug("meta : ",soldEvent.args.meta)
          log.debug("order: ",soldEvent.args.order)
          this.fire('success','Buying Success', 'Success buy nft on market.')

          if(asset.collection && asset.collection.offering){
            const collectionAddress = asset.collection.nftAddress
            log.debug("buy collectionAddress : %o",collectionAddress)

            const pools = await miningService.getPools()
            log.debug("buy pools : %o",pools)

            const assetPool = pools.find(it => it.collectionAddress.toLowerCase() == collectionAddress.toLowerCase())
            log.debug("buy assetPool : %o",assetPool)

            if(!isBatchBuy){
              if(assetPool){
                this.router.navigate(['/mining/reward/' + assetPool.id]); // redirect to redeem
                return
              }
            }else{
              let _res:any = {
                status: 291,
                message: 'success to buy with offering',
              }
              if(assetPool){
                _res.routerNavigate = '/mining/reward/' + assetPool.id
              }
              return _res
            }
          }

          if(!isBatchBuy){
            setTimeout(function(){
              window.location.reload()
            },2000)
          }else{
            return {
              status: 290,
              message: 'success to buy with offering'
            }
          }


        }).catch((error)=>{
          log.error("error on buyNFTFreeCityMarket: ",error)
          const message = error.data ? error.data.message : error.message
          this.fire('error','Buying failed', 'ERROR: '+message)

          if(!isBatchBuy){
            // do nothing
          }else{
            if(error.code == 4001){ // "MetaMask Tx Signature: User denied transaction signature."
              return {
                status: error.code,
                message: error.message
              }
            }else{
              return {
                status: 490,
                message: 'fail to buy'
              }
            }
          }
        }).finally(()=>{
          // this.isBuyLoading = false
        })
      }else{

      }
    }
  }

  async getCustomFieldsBySlug(slug){
    return this.customFieldsBySlug.find(it => it.slug == slug)
  }
}

