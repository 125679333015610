<div class="main">
    <app-header-section></app-header-section>
    <app-breadcrumb-item-details></app-breadcrumb-item-details>
    <app-item-details-section></app-item-details-section>
    <app-auctions-three-section></app-auctions-three-section>
    <app-footer-section></app-footer-section>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
    <app-scrollup></app-scrollup>
</div>
