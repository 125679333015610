<h3 class="mb-2 pl-3">ICON</h3>
<div class="d-flex flex-wrap px-3">
  <i class="icon-user mr-3 mb-3"></i>
  <i class="icon-people mr-3 mb-3"></i>
  <i class="icon-user-female mr-3 mb-3"></i>
  <i class="icon-user-follow mr-3 mb-3"></i>
  <i class="icon-user-following mr-3 mb-3"></i>
  <i class="icon-user-unfollow mr-3 mb-3"></i>
  <i class="icon-login mr-3 mb-3"></i>
  <i class="icon-logout mr-3 mb-3"></i>
  <i class="icon-emotsmile mr-3 mb-3"></i>
  <i class="icon-phone mr-3 mb-3"></i>
  <i class="icon-call-end mr-3 mb-3"></i>
  <i class="icon-call-in mr-3 mb-3"></i>
  <i class="icon-call-out mr-3 mb-3"></i>
  <i class="icon-map mr-3 mb-3"></i>
  <i class="icon-location-pin mr-3 mb-3"></i>
  <i class="icon-direction mr-3 mb-3"></i>
  <i class="icon-directions mr-3 mb-3"></i>
  <i class="icon-compass mr-3 mb-3"></i>
  <i class="icon-layers mr-3 mb-3"></i>
  <i class="icon-menu mr-3 mb-3"></i>
  <i class="icon-list mr-3 mb-3"></i>
  <i class="icon-options-vertical mr-3 mb-3"></i>
  <i class="icon-options mr-3 mb-3"></i>
  <i class="icon-arrow-down mr-3 mb-3"></i>
  <i class="icon-arrow-left mr-3 mb-3"></i>
  <i class="icon-arrow-right mr-3 mb-3"></i>
  <i class="icon-arrow-up mr-3 mb-3"></i>
  <i class="icon-arrow-up-circle mr-3 mb-3"></i>
  <i class="icon-arrow-left-circle mr-3 mb-3"></i>
  <i class="icon-arrow-right-circle mr-3 mb-3"></i>
  <i class="icon-arrow-down-circle mr-3 mb-3"></i>
  <i class="icon-clock mr-3 mb-3"></i>
  <i class="icon-plus mr-3 mb-3"></i>
  <i class="icon-minus mr-3 mb-3"></i>
  <i class="icon-close mr-3 mb-3"></i>
  <i class="icon-event mr-3 mb-3"></i>
  <i class="icon-exclamation mr-3 mb-3"></i>
  <i class="icon-organization mr-3 mb-3"></i>
  <i class="icon-trophy mr-3 mb-3"></i>
  <i class="icon-screen-smartphone mr-3 mb-3"></i>
  <i class="icon-screen-desktop mr-3 mb-3"></i>
  <i class="icon-plane mr-3 mb-3"></i>
  <i class="icon-notebook mr-3 mb-3"></i>
  <i class="icon-mustache mr-3 mb-3"></i>
  <i class="icon-mouse mr-3 mb-3"></i>
  <i class="icon-magnet mr-3 mb-3"></i>
  <i class="icon-energy mr-3 mb-3"></i>
  <i class="icon-disc mr-3 mb-3"></i>
  <i class="icon-cursor mr-3 mb-3"></i>
  <i class="icon-cursor-move mr-3 mb-3"></i>
  <i class="icon-crop mr-3 mb-3"></i>
  <i class="icon-chemistry mr-3 mb-3"></i>
  <i class="icon-speedometer mr-3 mb-3"></i>
  <i class="icon-shield mr-3 mb-3"></i>
  <i class="icon-screen-tablet mr-3 mb-3"></i>
  <i class="icon-magic-wand mr-3 mb-3"></i>
  <i class="icon-hourglass mr-3 mb-3"></i>
  <i class="icon-graduation mr-3 mb-3"></i>
  <i class="icon-ghost mr-3 mb-3"></i>
  <i class="icon-game-controller mr-3 mb-3"></i>
  <i class="icon-fire mr-3 mb-3"></i>
  <i class="icon-eyeglass mr-3 mb-3"></i>
  <i class="icon-envelope-open mr-3 mb-3"></i>
  <i class="icon-envelope-letter mr-3 mb-3"></i>
  <i class="icon-bell mr-3 mb-3"></i>
  <i class="icon-badge mr-3 mb-3"></i>
  <i class="icon-anchor mr-3 mb-3"></i>
  <i class="icon-wallet mr-3 mb-3"></i>
  <i class="icon-vector mr-3 mb-3"></i>
  <i class="icon-speech mr-3 mb-3"></i>
  <i class="icon-puzzle mr-3 mb-3"></i>
  <i class="icon-printer mr-3 mb-3"></i>
  <i class="icon-present mr-3 mb-3"></i>
  <i class="icon-playlist mr-3 mb-3"></i>
  <i class="icon-pin mr-3 mb-3"></i>
  <i class="icon-picture mr-3 mb-3"></i>
  <i class="icon-handbag mr-3 mb-3"></i>
  <i class="icon-globe-alt mr-3 mb-3"></i>
  <i class="icon-globe mr-3 mb-3"></i>
  <i class="icon-folder-alt mr-3 mb-3"></i>
  <i class="icon-folder mr-3 mb-3"></i>
  <i class="icon-film mr-3 mb-3"></i>
  <i class="icon-feed mr-3 mb-3"></i>
  <i class="icon-drop mr-3 mb-3"></i>
  <i class="icon-drawer mr-3 mb-3"></i>
  <i class="icon-docs mr-3 mb-3"></i>
  <i class="icon-doc mr-3 mb-3"></i>
  <i class="icon-diamond mr-3 mb-3"></i>
  <i class="icon-cup mr-3 mb-3"></i>
  <i class="icon-calculator mr-3 mb-3"></i>
  <i class="icon-bubbles mr-3 mb-3"></i>
  <i class="icon-briefcase mr-3 mb-3"></i>
  <i class="icon-book-open mr-3 mb-3"></i>
  <i class="icon-basket-loaded mr-3 mb-3"></i>
  <i class="icon-basket mr-3 mb-3"></i>
  <i class="icon-bag mr-3 mb-3"></i>
  <i class="icon-action-undo mr-3 mb-3"></i>
  <i class="icon-action-redo mr-3 mb-3"></i>
  <i class="icon-wrench mr-3 mb-3"></i>
  <i class="icon-umbrella mr-3 mb-3"></i>
  <i class="icon-trash mr-3 mb-3"></i>
  <i class="icon-tag mr-3 mb-3"></i>
  <i class="icon-support mr-3 mb-3"></i>
  <i class="icon-frame mr-3 mb-3"></i>
  <i class="icon-size-fullscreen mr-3 mb-3"></i>
  <i class="icon-size-actual mr-3 mb-3"></i>
  <i class="icon-shuffle mr-3 mb-3"></i>
  <i class="icon-share-alt mr-3 mb-3"></i>
  <i class="icon-share mr-3 mb-3"></i>
  <i class="icon-rocket mr-3 mb-3"></i>
  <i class="icon-question mr-3 mb-3"></i>
  <i class="icon-pie-chart mr-3 mb-3"></i>
  <i class="icon-pencil mr-3 mb-3"></i>
  <i class="icon-note mr-3 mb-3"></i>
  <i class="icon-loop mr-3 mb-3"></i>
  <i class="icon-home mr-3 mb-3"></i>
  <i class="icon-grid mr-3 mb-3"></i>
  <i class="icon-graph mr-3 mb-3"></i>
  <i class="icon-microphone mr-3 mb-3"></i>
  <i class="icon-music-tone-alt mr-3 mb-3"></i>
  <i class="icon-music-tone mr-3 mb-3"></i>
  <i class="icon-earphones-alt mr-3 mb-3"></i>
  <i class="icon-earphones mr-3 mb-3"></i>
  <i class="icon-equalizer mr-3 mb-3"></i>
  <i class="icon-like mr-3 mb-3"></i>
  <i class="icon-dislike mr-3 mb-3"></i>
  <i class="icon-control-start mr-3 mb-3"></i>
  <i class="icon-control-rewind mr-3 mb-3"></i>
  <i class="icon-control-play mr-3 mb-3"></i>
  <i class="icon-control-pause mr-3 mb-3"></i>
  <i class="icon-control-forward mr-3 mb-3"></i>
  <i class="icon-control-end mr-3 mb-3"></i>
  <i class="icon-volume-1 mr-3 mb-3"></i>
  <i class="icon-volume-2 mr-3 mb-3"></i>
  <i class="icon-volume-off mr-3 mb-3"></i>
  <i class="icon-calendar mr-3 mb-3"></i>
  <i class="icon-bulb mr-3 mb-3"></i>
  <i class="icon-chart mr-3 mb-3"></i>
  <i class="icon-ban mr-3 mb-3"></i>
  <i class="icon-bubble mr-3 mb-3"></i>
  <i class="icon-camrecorder mr-3 mb-3"></i>
  <i class="icon-camera mr-3 mb-3"></i>
  <i class="icon-cloud-download mr-3 mb-3"></i>
  <i class="icon-cloud-upload mr-3 mb-3"></i>
  <i class="icon-envelope mr-3 mb-3"></i>
  <i class="icon-eye mr-3 mb-3"></i>
  <i class="icon-flag mr-3 mb-3"></i>
  <i class="icon-heart mr-3 mb-3"></i>
  <i class="icon-info mr-3 mb-3"></i>
  <i class="icon-key mr-3 mb-3"></i>
  <i class="icon-link mr-3 mb-3"></i>
  <i class="icon-lock mr-3 mb-3"></i>
  <i class="icon-lock-open mr-3 mb-3"></i>
  <i class="icon-magnifier mr-3 mb-3"></i>
  <i class="icon-magnifier-add mr-3 mb-3"></i>
  <i class="icon-magnifier-remove mr-3 mb-3"></i>
  <i class="icon-paper-clip mr-3 mb-3"></i>
  <i class="icon-paper-plane mr-3 mb-3"></i>
  <i class="icon-power mr-3 mb-3"></i>
  <i class="icon-refresh mr-3 mb-3"></i>
  <i class="icon-reload mr-3 mb-3"></i>
  <i class="icon-settings mr-3 mb-3"></i>
  <i class="icon-star mr-3 mb-3"></i>
  <i class="icon-symbol-female mr-3 mb-3"></i>
  <i class="icon-symbol-male mr-3 mb-3"></i>
  <i class="icon-target mr-3 mb-3"></i>
  <i class="icon-credit-card mr-3 mb-3"></i>
  <i class="icon-paypal mr-3 mb-3"></i>
  <i class="icon-social-tumblr mr-3 mb-3"></i>
  <i class="icon-social-twitter mr-3 mb-3"></i>
  <i class="icon-social-facebook mr-3 mb-3"></i>
  <i class="icon-social-instagram mr-3 mb-3"></i>
  <i class="icon-social-linkedin mr-3 mb-3"></i>
  <i class="icon-social-pinterest mr-3 mb-3"></i>
  <i class="icon-social-github mr-3 mb-3"></i>
  <i class="icon-social-google mr-3 mb-3"></i>
  <i class="icon-social-reddit mr-3 mb-3"></i>
  <i class="icon-social-skype mr-3 mb-3"></i>
  <i class="icon-social-dribbble mr-3 mb-3"></i>
  <i class="icon-social-behance mr-3 mb-3"></i>
  <i class="icon-social-foursqare mr-3 mb-3"></i>
  <i class="icon-social-soundcloud mr-3 mb-3"></i>
  <i class="icon-social-spotify mr-3 mb-3"></i>
  <i class="icon-social-stumbleupon mr-3 mb-3"></i>
  <i class="icon-social-youtube mr-3 mb-3"></i>
  <i class="icon-social-dropbox mr-3 mb-3"></i>
  <i class="icon-social-vkontakte mr-3 mb-3"></i>
  <i class="icon-social-steam mr-3 mb-3"></i>
</div>
