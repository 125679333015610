import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-auctions',
  templateUrl: './live-auctions.component.html',
  styleUrls: ['./live-auctions.component.scss']
})
export class LiveAuctionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
