import { HttpClient } from '@angular/common/http';
import { SingletonService } from './singleton.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import moment from 'moment'
import BigNumber from 'bignumber.js';

import { ethers, logger } from "ethers";
import * as _ from 'lodash';

// APIS
import FEE_PROVIDER_ABI from '../../app/configs/abis/free-city/bkc-testnet/FeeProvider.json'
import MARKET_ABI from '../../app/configs/abis/free-city/bkc-testnet/FreeCityMarket.json'
import P2P_MARKET_ABI from '../../app/configs/abis/free-city/common/P2PMarket.json'
import FreeCityMinter from '../../app/configs/abis/free-city/bkc-testnet/FreeCityMinter.json'
import FreeCityNFT from '../../app/configs/abis/free-city/bkc-testnet/FreeCityNFT.json'
import ERC721_ABI from '../../app/configs/abis/free-city/bkc-testnet/ERC721.json'
import ERC20_ABI from '../../app/configs/abis/free-city/common/ERC20.json'
import KUSDT_ABI from '../../app/configs/abis/free-city/common/KUSDT.json'
import FAUCET_TOKEN_ABI from '../../app/configs/abis/free-city/common/FaucetToken.json'
import WL_MARKET_ABI from '../../app/configs/abis/free-city/market/FreecityWLMarket.json'
import LAUNCHPAD_MARKET_ABI from '../../app/configs/abis/free-city/market/FreeCityLaunchpad.json'

// import CRAFTING_STATION_ABI from '../../app/configs/abis/free-city/crafting/RefineStation.json' // V1
import CRAFTING_STATION_ABI from '../../app/configs/abis/free-city/crafting/RefineStationV2.json' // V2


import ENERGY_STORAGE_ABI from '../../app/configs/abis/free-city/crafting/EnergyStorage.json'
import EXP_STORAGE_ABI from '../../app/configs/abis/free-city/crafting/ExpStorage.json'

// ADDRESSES
// import { FreeCityAddress } from '../../app/configs/addresses/free-city/bkc-testnet/addresses'
// import {AddressBook} from "../configs/addresses/address-book";
const AddressBook = environment.addressBook

import {TOKEN_TYPE} from "../constant/valuables";

import { Logger } from '../services/logger.service';
import {environment} from "../../environments/environment";
import {CurrencyService} from "./currency.service";
import POOL_ABI from "../configs/abis/free-city/mining/FreeCityMiningPool.json";
import {Contract, Provider} from "bkc-ethcall";
const log = new Logger('FreeCityContractService');

@Injectable({
  providedIn: 'root'
})
export class FreeCityContractService {

  publicProvider: any;
  multicallPublicProvider: any;
  provider
  freeCityFeeProviderContract:any;
  freeCityMarketContract:any;
  freeCityMinterContract:any;
  freeCityAddress;

  _multicallPublicProvider

  constructor(
    private httpClient: HttpClient,
    private singletonService:SingletonService,
    private currencyService:CurrencyService
  ) {
    this.initProvider()
    //this.freeCityAddress = AddressBook.find(ab => ab.chainId == environment.chainId)
  }



  async initProvider(){
    let _chainId = await this.singletonService.getCurrentChainId()
    log.debug("Current chain id is "+_chainId)
    this.freeCityAddress = AddressBook.find(ab => ab.chainId == _chainId)

    log.debug("freeCityAddress %o",this.freeCityAddress)
    // log.debug("this.provider : %o",this.provider)
    if(!this.provider){
      //this.provider = await new ethers.providers.Web3Provider(window.ethereum)
      this.provider = this.singletonService.provider
    }
    if(!this.publicProvider){
      this.publicProvider = await this.singletonService.getPublicProvider()
    }
    this.multicallPublicProvider = new Provider();
    this.multicallPublicProvider.init(this.publicProvider)
  }

  /*async getMulticallPublicProvider(){
    if(!this.multicallPublicProvider){
      this.multicallPublicProvider = new Provider();
      this.multicallPublicProvider.init(this.publicProvider)
    }
    return this.multicallPublicProvider
  }*/

  async getEnvPublicProvider() {
    let chainId = await this.singletonService.getCurrentChainId()
    let chain = await this.singletonService.getSupportChain(chainId)
    if(chain){
      if (chain.use == 'wss') {
        return new ethers.providers.WebSocketProvider(chain.wss);
      } else if (chain.use == 'rpc') {
        return new ethers.providers.JsonRpcProvider(chain.rpc);
      } else {
        return new ethers.providers.WebSocketProvider(chain.wss);
      }
    }else{
      if (environment.use == 'wss') {
        return new ethers.providers.WebSocketProvider(environment.wss);
      } else if (environment.use == 'rpc') {
        return new ethers.providers.JsonRpcProvider(environment.rpc);
      } else {
        return new ethers.providers.WebSocketProvider(environment.wss);
      }
    }
  }

  async getMulticallPublicProvider() {
    log.debug("getMulticallPublicProvider this._multicallPublicProvider => %o",this._multicallPublicProvider)

    if(!this._multicallPublicProvider){
      log.debug("create new _multicallPublicProvider...")

      const publicProvider = await this.singletonService.getPublicProvider()
      // let _publicProvider = await this.getEnvPublicProvider();

      this._multicallPublicProvider = new Provider();
      await this._multicallPublicProvider.init(publicProvider)
      return this._multicallPublicProvider
    }else{
      return this._multicallPublicProvider
    }
  }

  async checkFreeCityAddressed(){
    log.debug("FreeCityAddress => %o",this.freeCityAddress)
  }

  async getFreeCityFeeProviderContract(){
    this.freeCityFeeProviderContract = await new ethers.Contract(this.freeCityAddress.FeeProvider_Address, FEE_PROVIDER_ABI, this.provider.getSigner())
    return this.freeCityFeeProviderContract
  }

  async getFreeCityMarketContract(){
    this.freeCityMarketContract = await new ethers.Contract(this.freeCityAddress.MARKET, MARKET_ABI, this.provider.getSigner())
    return this.freeCityMarketContract
  }

  async getFreeCityP2PMarketContract() {
    this.freeCityMarketContract = await new ethers.Contract(this.freeCityAddress.P2PMARKET, P2P_MARKET_ABI, this.provider.getSigner())
    return this.freeCityMarketContract
  }

  async getFreeCityMinterContract(){
    this.freeCityMinterContract = await new ethers.Contract(this.freeCityAddress.FreeCityMinter_Address, FreeCityMinter, this.provider.getSigner())
    return this.freeCityMinterContract
  }

  async getFeePercent(collectionAddress){
    let feeProviderContract = await this.getFreeCityFeeProviderContract()
    let feePercent = await feeProviderContract.getFeePercent(collectionAddress)
    log.debug("getFeePercent feePercent: ", feePercent)

    return Number(feePercent) / 100.0
  }

  async getDevFeePercent(){
    let feeProviderContract = await this.getFreeCityFeeProviderContract()
    let devFeePercent = await feeProviderContract.getDevFeePercent()
    log.debug("getDevFeePercent devFeePercent: ", devFeePercent)

    return Number(devFeePercent) / 100.0
  }

  async checkTokenApprovalForMarket(tokenAddress, price){
    log.debug("check approval for market "+tokenAddress)
    log.debug("check approval for wallet "+this.singletonService.account)
    log.debug("check approval for market "+this.freeCityAddress.MARKET)
    // check is peg ?
    let token
    let result
    const isPeg = await this.isPeg(tokenAddress)
    log.debug("check approval isPeg "+isPeg)

    try {
      if(await this.isPeg(tokenAddress)){
        log.debug("peg detected")
        token = await new ethers.Contract(tokenAddress, KUSDT_ABI, this.provider)
        result = await token.allowances(this.singletonService.account, this.freeCityAddress.MARKET)
      }else{
        token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.provider)
        result = await token.allowance(this.singletonService.account, this.freeCityAddress.MARKET)
      }
      log.debug("allowance of token for market ",result)
      log.debug("which gte than price ",result.gte(price))
      return result.gte(price)
    } catch (error) {
      return false
    }

  }

  async isPeg(tokenAddress){
    return await this.currencyService.isPeg(tokenAddress)
  }

  async checkTokenApprovalFor(tokenAddress, price, contractAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }
    let account = this.singletonService.getCurrentConnectedAccount()
    log.debug("check approval tokenAddress "+tokenAddress)
    log.debug("check approval wallet "+account)
    log.debug("check approval for contract "+contractAddress)
    // check is peg ?
    let token
    let result
    const isPeg = await this.isPeg(tokenAddress.toLowerCase())
    log.debug("check approval isPeg "+isPeg)

    try {
      if(await this.isPeg(tokenAddress.toLowerCase())){
        log.debug("peg detected")
        token = await new ethers.Contract(tokenAddress, KUSDT_ABI, this.publicProvider)
        result = await token.allowances(account, contractAddress)
      }else{
        token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.publicProvider)
        result = await token.allowance(account, contractAddress)
      }
      log.debug("price ", price)
      log.debug("allowance of token for market ",result)
      log.debug("which gte than price ",result.gte(price))
      return result.gte(price)
    } catch (error) {
      log.error(error)
      return false
    }

  }

  async approveToken(tokenAddress, price = undefined){
    log.debug("doing allowance of token for market ")
    if(!price){
      price = ethers.constants.MaxUint256
    }
    log.debug("amount for approval of token for market ", price)
    let token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.provider.getSigner())
    return await token.approve(this.freeCityAddress.MARKET, price)
  }

  async approveTokenTo(tokenAddress, contractAddress, price = undefined){
    log.debug("doing allowance of token for contractAddress ")
    if(!price){
      price = ethers.constants.MaxUint256
    }
    log.debug("amount for approval of token for market ", price)
    let token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.provider.getSigner())
    return await token.approve(contractAddress, price)
  }

  async checkApprove(nftAddress){
    let nft = await new ethers.Contract(nftAddress, ERC721_ABI, this.provider)
    let account = await this.singletonService.getCurrentConnectedAccount()
    return await nft.isApprovedForAll(account, this.freeCityAddress.MARKET)
  }

  async doApprove(nftAddress){
    let nft = await new ethers.Contract(nftAddress, ERC721_ABI, this.provider.getSigner())
    return nft.setApprovalForAll(this.freeCityAddress.MARKET, true)
  }

  async undoApprove(nftAddress){
    let nft = await new ethers.Contract(nftAddress, ERC721_ABI, this.provider.getSigner())
    return nft.setApprovalForAll(this.freeCityAddress.MARKET, false)
  }

  async mint(toAddress,mediaUri,creatorAddress,feeReceiverAddress,loyaltyFee){
    const KUB_AMOUNT = 0.5
    let minterContract = await this.getFreeCityMinterContract()
    log.debug("mint minterContract => %o",minterContract)

    log.debug("mint toAddress => %o",toAddress)
    log.debug("mint mediaUri => %o",mediaUri)
    log.debug("mint creatorAddress => %o",creatorAddress)
    log.debug("mint feeReceiverAddress => %o",feeReceiverAddress)
    log.debug("mint loyaltyFee => %o",loyaltyFee)

    return minterContract.mint(
      toAddress,
      mediaUri,
      creatorAddress,
      feeReceiverAddress,
      loyaltyFee*100,
      {
        // gasLimit: 10000000,
        value: (new BigNumber(KUB_AMOUNT).shiftedBy(18)).toFixed(0) // 000 000 000 000 000 000
      }
    )
  }

  async batchListing(
    itemIds,
    tokenType,
    itemAddr,
    itemAmount,
    acceptedTokenId,
    acceptedTokenType,
    acceptedTokenAddr,
    acceptedTokenAmount)
  {
    let market = await this.getFreeCityMarketContract()
    return market.batchCreate(
      itemIds,
      tokenType,
      itemAddr,
      itemAmount,
      acceptedTokenId,
      acceptedTokenType,
      acceptedTokenAddr,
      acceptedTokenAmount
    )
  }

  async listing(
    itemId,
    tokenType,
    itemAddr,
    itemAmount,
    acceptedTokenId,
    acceptedTokenType,
    acceptedTokenAddr,
    acceptedTokenAmount)
  {
    let market = await this.getFreeCityMarketContract()
    return market.create(
      itemId,
      tokenType,
      itemAddr,
      itemAmount,
      acceptedTokenId,
      acceptedTokenType,
      acceptedTokenAddr,
      acceptedTokenAmount
    )
  }

  async cancelListing(orderId)
  {
    let market = await this.getFreeCityMarketContract()
    return market.cancel(
      orderId
    )
  }

  async buy(
    orderId,
    acceptedTokenType,
    acceptedTokenAddr,
    acceptedTokenAmount
  )
  {
    log.debug("buy orderId => %o",orderId)
    log.debug("buy acceptedTokenType => %o",acceptedTokenType)
    log.debug("buy acceptedTokenAddr => %o",acceptedTokenAddr)
    log.debug("buy acceptedTokenAmount => %o",acceptedTokenAmount)

    let market = await this.getFreeCityMarketContract()
    if(acceptedTokenType == TOKEN_TYPE.KUB){
      return market.buy(
        orderId, {value : acceptedTokenAmount}
      )
    }else{
      return market.buy(
        orderId
      )
    }

  }

  async getP2PAcceptedTokenAddresses(){
    let market = await new ethers.Contract(this.freeCityAddress.P2PMARKET, P2P_MARKET_ABI, this.singletonService.publicProvider)
    return (await market.getAcceptedTokens());
  }

  async listingP2P(
    itemAddr, // placing token (punk)
    itemAmount, // amount of punk (1)
    acceptedTokenAddr,  // receive token (kusdt)
    acceptedTokenAmount) // amount of accepted (100 kusdt)
  {
    let market = await this.getFreeCityP2PMarketContract()
    return market.create(
      0,
      TOKEN_TYPE.ERC20,
      itemAddr,
      itemAmount,
      0,
      TOKEN_TYPE.ERC20,
      acceptedTokenAddr,
      acceptedTokenAmount
    )
  }

  async buyP2PWithERC20(
    orderId
  ) {
    let market = await this.getFreeCityP2PMarketContract()
    return market.buy(
      orderId
    )
  }

  async cancelP2PListing(orderId) {
    let market = await this.getFreeCityP2PMarketContract()
    return market.cancel(
      orderId
    )
  }

  async faucet(tokenAddr){
    let token = await new ethers.Contract(tokenAddr, FAUCET_TOKEN_ABI, this.provider.getSigner())
    const amount = ethers.utils.parseUnits('1000');
    return token.faucet(amount)
  }

  async checkP2PAllowance(tokenAddress){
    let account = await this.singletonService.getCurrentConnectedAccount()

    // let token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.publicProvider)
    // let result:any = await token.allowance(account, this.freeCityAddress.P2PMARKET)

    // check is peg ?
    let decimals
    let token
    let result
    if(await this.isPeg(tokenAddress)){
      log.debug("peg detected")
      token = await new ethers.Contract(tokenAddress, KUSDT_ABI, this.publicProvider)
      result = await token.allowances(account, this.freeCityAddress.P2PMARKET)
      decimals = await token.decimals()
    }else{
      token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.publicProvider)
      result = await token.allowance(account, this.freeCityAddress.P2PMARKET)
      decimals = await token.decimals()
    }

    return ethers.utils.formatUnits(result, decimals)
  }

  async approveTokenP2P(tokenAddress, price = undefined){
    log.debug("doing allowance of token for P2PMARKET ")
    if(!price){
      price = ethers.constants.MaxUint256
    }
    log.debug("amount for approval of token for P2PMARKET ", price)
    let token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.provider.getSigner())
    return token.approve(this.freeCityAddress.P2PMARKET, price)
  }

  async checkTokenApprovalForMarketP2P(tokenAddress, price){
    log.debug("check approval for P2PMARKET "+tokenAddress)
    log.debug("check approval for wallet "+this.singletonService.account)
    log.debug("check approval for P2PMARKET "+this.freeCityAddress.P2PMARKET)
    // check is peg ?
    let token
    let result
    if(await this.isPeg(tokenAddress)){
      log.debug("peg detected")
      token = await new ethers.Contract(tokenAddress, KUSDT_ABI, this.provider)
      result = await token.allowances(this.singletonService.account, this.freeCityAddress.P2PMARKET)
    }else{
      token = await new ethers.Contract(tokenAddress, ERC20_ABI, this.provider)
      result = await token.allowance(this.singletonService.account, this.freeCityAddress.P2PMARKET)
    }
    log.debug("allowance of token for P2PMARKET ",result)
    log.debug("which gte than price ",result.gte(price))
    return result.gte(price)
  }

  async isFreeCityNFTAddress(address){
    if(address && this.freeCityAddress.FreeCityNFT_Address){
      return address.toLowerCase() == this.freeCityAddress.FreeCityNFT_Address.toLowerCase()
    }else{
      return false
    }
  }

  async getBalance(address){
    let token = await new ethers.Contract(address, ERC20_ABI, this.singletonService.publicProvider)
    return await token.balanceOf(this.singletonService.account)
  }

  async getTokenName(address){
    let token = await new ethers.Contract(address, ERC20_ABI, this.singletonService.publicProvider)
    return await token.name()
  }

  async getTokenSymbol(address){
    let token = await new ethers.Contract(address, ERC20_ABI, this.singletonService.publicProvider)
    return await token.symbol()
  }

  async getTokenInfoAndBalance(tokenAddrs, userAddr, toEther = false) {
    let maps = []
    try{
      const ELEMENT_SIZE = 4 // name, symbol, decimals, balance
      let calls = []
      let _provider = await this.getMulticallPublicProvider()
      log.debug("before loop push calls _provider: ",_provider)
      for (const tokenAddr of tokenAddrs) {
        if(tokenAddr !== ethers.constants.AddressZero){
          log.debug("--- > "+tokenAddr)
          let token = new Contract(tokenAddr, ERC20_ABI);
          calls.push(token.name());
          calls.push(token.symbol());
          calls.push(token.decimals());
          calls.push(token.balanceOf(userAddr))
        }
      }
      log.debug("before   calls")
      let result:any = await _provider.tryAll(calls)
      log.debug("REsult from multicall ", result)

      let idx = 0
      let tokenIdx = 0
      for (const tokenAddr of tokenAddrs) {
        if(tokenAddr !== ethers.constants.AddressZero) {
          let data = {
            name: result[tokenIdx + idx],
            symbol: result[tokenIdx + idx + 1],
            decimals: result[tokenIdx + idx + 2],
            address: tokenAddr,
            balance: toEther ?
              ethers.utils.formatUnits(result[tokenIdx + idx + 3], result[tokenIdx + idx + 2])
              : result[tokenIdx + idx + 3]
          }
          idx += ELEMENT_SIZE - 1;
          tokenIdx++
          maps.push(data)
        }
      }
    }catch(e){
      log.error(e)
    }finally {
      return maps
    }

  }

  async getBalanceOfStandardNFT_ERC721(collectionAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const account = await this.singletonService.getCurrentConnectedAccount()
    log.debug("getBalanceOfStandardNFT_ERC721 account", account)

    const contract = await new ethers.Contract(collectionAddress, ERC721_ABI, this.publicProvider)
    return await contract.balanceOf(account)
  }


  async getTokenOfOwnerByIndexOfStandardNFT_ERC721(collectionAddress,index){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const account = await this.singletonService.getCurrentConnectedAccount()
    log.debug("getTokenOfOwnerByIndexOfStandardNFT_ERC721 account", account)

    const contract = await new ethers.Contract(collectionAddress, ERC721_ABI, this.publicProvider)
    return await contract.tokenOfOwnerByIndex(account,index)
  }


  // ============================== Whitelist ZONE =====================================================

  // address _nft, uint256[] calldata _tokenIds, uint256 _price
  async whitelistListing(marketAddress, nftAddress, itemIds, acceptedTokenAmount){
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.provider.getSigner())
    return contract.placeItems(nftAddress, itemIds, acceptedTokenAmount)
  }

  async checkWhiteListApprove(marketAddress, nftAddress){
    let nft = await new ethers.Contract(nftAddress, ERC721_ABI, this.provider)
    let account = await this.singletonService.getCurrentConnectedAccount()
    return await nft.isApprovedForAll(account, marketAddress)
  }

  async doWhitelistApprove(marketAddress, nftAddress){
    let nft = await new ethers.Contract(nftAddress, ERC721_ABI, this.provider.getSigner())
    return nft.setApprovalForAll(marketAddress, true)
  }

  async getAcceptedTokenAddressFromWLMarket(marketAddress){
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.provider.getSigner())
    return await contract.acceptedTokenAddress()
  }

  async withdrawFromWLMarket(marketAddress, amount){
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.provider.getSigner())
    return contract.batchCancel(amount)

  }

  async getAvailableOrderIds(marketAddress){
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.publicProvider)
    return await contract.getAvailableOrderIds()
  }

  async getAvailableOrders(marketAddress){
    const slice = 30
    const url = `${environment.apiUrl}/v1/supports/paymentTokens/`
    const payments = await this.httpClient.get(url).toPromise()
    if(!this.publicProvider){
      await this.initProvider()
    }
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.publicProvider)
    const _ids = await this.getAvailableOrderIds(marketAddress)
    const ids = _ids.slice(0,slice)
    let calls = ids.map(async (id)=> {
      let order = await contract.orders(id)
      let nft =  await new ethers.Contract(order.itemAddr, ERC721_ABI, this.publicProvider)
      let tokenURI = await nft.tokenURI(order.itemId)
      let meta:any = await this.httpClient.get(tokenURI).toPromise()
      let image = meta.image
      let paymentToken = _.filter(payments, {address:order.acceptedTokenAddr.toString().toLowerCase()})[0]
      return  {
        order:order,
        orderId:order.id,
        price:ethers.utils.formatEther(order.acceptedTokenAmount),
        tokenId:order.itemId,
        tokenURI: tokenURI,
        paymentToken:paymentToken,
        image:image,
        name:meta.name,
        description:meta.description,
        loading:false,
        bought:false,
        meta:meta
      }
    })

    return Promise.all(calls)
  }

  async buyFromWLMarket(orderId, marketAddress){
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.provider.getSigner())
    return contract.buy(orderId)
  }

  async isWhitelist(marketAddress, account){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.publicProvider)
    return await contract.isAddressAllowed(account)
  }

  async getQuotaLeft(marketAddress, account){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const contract = await new ethers.Contract(marketAddress, WL_MARKET_ABI, this.provider.getSigner())
    return await contract.getRemainRightToBuy(account)
  }

  // ============================== Launchpad ZONE =====================================================

  async getLPQuotaLeft(marketAddress, account){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const contract = await new ethers.Contract(marketAddress, LAUNCHPAD_MARKET_ABI, this.publicProvider)
    return await contract.getRemainRightToBuy(account)
  }

  async isLPMarketPause(marketAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }
    const contract = await new ethers.Contract(marketAddress, LAUNCHPAD_MARKET_ABI, this.publicProvider)
    return await contract.pause()
  }


  // ============================== CRAFTING ZONE =====================================================

  async getCraftingStationReceipts(craftingStationAddress,minterAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.recipes(minterAddress)
  }

  async getCraftingStationRecipeLength(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.recipeLength()
  }

  async getStakedBoosterInfosById(craftingStationAddress,stakedBoosterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.boosters(stakedBoosterId)
  }

  async getRecipes(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getRecipes()
  }

  async getConverterAddress(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.converterAddress()
  }

  async getMaxBooster(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.maxBooster()
  }

  async getBoosterAddresses(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getBoosterAddresses()
  }


  async getEnergyStorage(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.energyStorage()
  }

  async getExpStorage(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.expStorage()
  }

  async getForgeFee(craftingStationAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.forgeFee()
  }

  async stakeConverter(craftingStationAddress,converterIds){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.stakeConverter(converterIds)
  }

  async addBooster(
    craftingStationAddress,
    converterId,
    boosterAddress,
    tokenIdNumber,
  ){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.addBooster(
      converterId,
      boosterAddress,
      tokenIdNumber,
    )
  }


  async withdrawBooster(
    craftingStationAddress,
    converterTokenId,
    stakedBoosterId,
  ){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.withdrawBooster(
      converterTokenId,
      stakedBoosterId,
    )
  }

  async unstakeConverter(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.unstakeConverter(converterId)
  }

  async getUserConverterTokenIds(craftingStationAddress,walletAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getUserConverterTokenIds(walletAddress)
  }

  async getMaterials(craftingStationAddress,materialId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.materials(materialId)
  }

  async boosterReducePercent(craftingStationAddress,boosterAddress,tokenId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.boosterReducePercent(boosterAddress,tokenId)
  }

  async getBoosterLevel(craftingStationAddress,boosterAddress,tokenId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getBoosterLevel(boosterAddress,tokenId)
  }



  async getMaterialIds(craftingStationAddress,receiptId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getMaterialIds(receiptId)
  }

  async getConverterInfo(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getConverterInfo(converterId)
  }

  async getConverterLevel(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getConverterLevel(converterId)
  }

  async getConverterLevelReduce(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getConverterLevelReduce(converterId)
  }

  async getConverterEnergy(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.energies(converterId)
  }

  async getConverterExps(craftingStationAddress,converterId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.converterExps(converterId)
  }

  async forge(craftingStationAddress,converterTokenId,receiptId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.forge(converterTokenId,receiptId)
  }

  async canClaim(craftingStationAddress,converterTokenId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.canClaim(converterTokenId)
  }

  async claim(craftingStationAddress,converterTokenId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.claim(converterTokenId)
  }


  async getUserLogs(craftingStationAddress,walletAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getUserLogs(walletAddress)
  }

  async getUserLogsLength(craftingStationAddress,walletAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getUserLogsLength(walletAddress)
  }

  async getUserLogsWithOffset(craftingStationAddress,walletAddress,_offset,_amount){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.getUserLogsWithOffset(walletAddress,_offset,_amount)
  }


  async getCraftingOrderById(craftingStationAddress,_orderId){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(craftingStationAddress, CRAFTING_STATION_ABI, this.provider.getSigner())
    return await contract.orders(_orderId)
  }

  async refillEnergy(
    energyStorageAddress,
    _converterAddress,
    _converterId,
    _refillMultiply,
  ){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(energyStorageAddress, ENERGY_STORAGE_ABI, this.provider.getSigner())
    return await contract.refillEnergy(
      _converterAddress,
      _converterId,
      _refillMultiply,
    )
  }

  async getRefillInfos(energyStorageAddress,_converterAddress){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(energyStorageAddress, ENERGY_STORAGE_ABI, this.provider.getSigner())
    return await contract.refillInfos(_converterAddress)
  }

  async getConverterEXPFromStorage(converterAddress, converterTokenId ,expStorage){
    if(!this.publicProvider){
      await this.initProvider()
    }

    console.log("get converter exps")
    console.log(converterAddress)
    console.log(converterTokenId)
    console.log(expStorage)
    const contract = await new ethers.Contract(expStorage, EXP_STORAGE_ABI, this.publicProvider)
    return await contract.converterExps(converterAddress, converterTokenId)
  }

  async getConverterEnergyFromStorage(converterAddress, converterTokenId , energyStorage){
    if(!this.publicProvider){
      await this.initProvider()
    }

    const contract = await new ethers.Contract(energyStorage, ENERGY_STORAGE_ABI, this.publicProvider)
    return await contract.converterEnergy(converterAddress, converterTokenId)
  }


  // ============================== CRAFTING ZONE =====================================================



}
