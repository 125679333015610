
<div class="main">

  <app-header-section></app-header-section>

  <div *ngIf="isLoading" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-center text-muted">Fetching data</p>
  </div>
  <div *ngIf="!isLoading && !selectedCollection">
    <div class="container " style="margin-top: 8vh;margin-bottom: 5vh">
      <section class="author-area">
      <div class="row ">
        <div class="col-12 text-center">
          <img src="assets/img/icons/sunglasses.png" width="128"/>
          <h4>Collection Not Found</h4>
          <p>Sorry we can not find collection slug that you specific.</p>
          <a class="btn btn-primary text-white" [routerLink]="['/']">
            Go Back
          </a>
        </div>
      </div>
      </section>
    </div>
  </div>
  <div *ngIf="!isLoading && selectedCollection">
    <div >
      <div class="image-cover-wrapper position-relative">
        <div class="image-cover-inner">
          <div class="image-cover">
            <img [src]="selectedCollection.bannerImage | renderImageUrl: 'banner'" [alt]="selectedCollection.name">
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrapper container">

      <!-- mock flow -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="text-left my-5 d-flex align-items-center" >
            <p class="mt-0 mb-2 text-nowrap mr-4">
              select collection
            </p>
            <select class=" form-control"
              (change)="selectColection($event.target.value)" >
              <option class="text-muted" *ngFor="let collection of collections" [value]="collection.id">
                {{collection.name}} | {{collection.id}}
              </option>
            </select>
          </div>
          <hr>
        </div>
      </div> -->
      <!-- end mock flow -->

      <div class="row campaign-intro-wrapper">
        <div class="col-md-12">
          <div class="">
            <div class="campaign-info-wrapper">
              <h2 class="tx-36 m-0 font-weight-bolder">
                {{selectedCollection.name}}
              <i [ngClass]="selectedCollection.verification ? (selectedCollection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
            [title]="selectedCollection.verification ? (selectedCollection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
            class="ml-1"></i>
              </h2>
              <p class="tx-16 mb-0 mt-4 text-muted">
                {{selectedCollection.description}}
              </p>
            </div>
          </div>
          <hr class="my-5">
        </div>
      </div>


      <div class="row campaign-list-wrapper mb-5 pb-5">
        <div class="col-12">
          <div class="d-flex justify-content-between aligns-items-center flex-wrap">
            <div>
              <h3 class="mt-0 mb-2 tx-24">NFTs on sale, only {{totalResults}} left.</h3>
              <p class="mt-0 mb-2 text-muted tx-16">Show only those that have just been sold for the first time.</p>
            </div>
            <div *ngIf="isActivateBatchBuy && assets && assets.length">

              <a *ngIf="assets && assets.length >= 5" (click)="randomNFTs(5)" class="btn btn-primary text-white" data-toggle="modal" data-target="#batchBuy">
                Buy 5X
              </a>
              <a *ngIf="assets && assets.length >= 10" (click)="randomNFTs(10)" class="btn btn-primary text-white ml-2" data-toggle="modal" data-target="#batchBuy">
                Buy 10X
              </a>
              <!-- <a class="btn btn-primary text-white ml-2" data-toggle="modal" data-target="#batchBuy">
                Batch Buy
              </a> -->
            </div>
          </div>
          <div class="wrapper-nft-offering wrapper-search-result content-wrapper-tab py-4 px-0 border-left-0">
            <app-nft-card-list class="w-100"
              *ngIf="assets"
              [assets]="assets"
              [totalResults]="totalResults"
              [isFetchingAsset]="isFetchingAsset"
              [isShowTotalResults]="false"
              [isShowSelectedFilterCardSection]="false"
              >
            </app-nft-card-list>
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="batchBuy" data-backdrop="static" data-keyboard="false" #modalBatchBuy>
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">Batch Buy</h5>
          <button type="button" #closebutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block author-area">
          <div *ngIf="!isFixAmount" class="form-group mb-4">
            <div class="txt-label-form font-weight-bold mb-3">
              Enter Amount NFT :
              <span class="text-muted ml-1">(only {{totalResults}} left.)</span>
            </div>
            <div class="input-group mb-3 shadow-none">
              <input [(ngModel)]="buyAmount"
              (keyup)="checkMaximum()"
              type="text" class="form-control pl-4" placeholder="Enter Amount NFT" numbersOnly>
              <div class="input-group-append">
                <!-- <span class="input-group-text"> -->
                  <button [disabled]="!buyAmount" (click)="randomNFTs()" type="button" class="btn btn-primary text-white">
                    Random
                  </button>
                <!-- </span> -->
              </div>
            </div>

          </div>
          <!-- <div class="text-center">
            <button [disabled]="!buyAmount" (click)="randomNFTs()" type="button" class="btn btn-primary text-white">
              Random
            </button>
          </div> -->

          <div *ngIf="randomAssets && randomAssets.length">

            <div *ngIf="isLoadingRandom" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
              <div  class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="text-center text-muted">Fetching data</p>
            </div>

            <div *ngIf="!isLoadingRandom" class="nft-related nft-offering-wrapper">
              <hr *ngIf="!isFixAmount">
              <div class="txt-label-form font-weight-bold mb-0">
                NFT List :
              </div>
              <div class="nft-related-content">
                <div class="nft-related-content-inner">
                  <app-nft-card-list class="w-100"
                    *ngIf="randomAssets"
                    [assets]="randomAssets"
                    [totalResults]="totalResults"
                    [isFetchingAsset]="isFetchingAsset"
                    [isShowTotalResults]="false"
                    [isShowSelectedFilterCardSection]="false"
                    [enabledSearch]="false"
                    [enabledSort]="false"
                    [enabledGrid]="false"
                    data-dismiss="modal">
                  </app-nft-card-list>
                </div>
              </div>
            </div>
            <div *ngIf="!isLoadingRandom" class="form-group mb-3">
              <hr>
              <div class="txt-label-form font-weight-bold mb-3">
                Total :
                <span class="text-muted ml-1">
                  Approve
                  ({{approvedTotalAmount}}/{{batchBuyTotals.length}})
                </span>
              </div>
              <ul *ngFor="let batchBuyTotal of batchBuyTotals">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      {{batchBuyTotal.total | gameNumberFormat: 2 : false}}
                      {{batchBuyTotal.paymentToken | toUpperCase}}
                    </div>
                    <!-- {{batchBuyTotal.isApproved ? "Approved" : "Not Approve"}} -->
                    <button *ngIf="!batchBuyTotal.isApproved" [disabled]="batchBuyTotal.isApproveLoading" class="btn btn-primary py-1 px-3 tx-14 text-white ml-2" (click)="approve(batchBuyTotal)">
                      <span *ngIf="batchBuyTotal.isApproveLoading" class="mr-2">
                        <span  class="spinner-border spinner-border-sm text-white" role="status">
                          <span  class="sr-only">Loading...</span>
                        </span>
                      </span>
                      Approve
                    </button>
                  </div>
                </li>
              </ul>
              <hr>
              <div class="txt-label-form font-weight-bold mb-3">
                Fees :
              </div>
              <ul class="mb-4 pb-1">
                <li>Royalty fee: <b id="royalty-fee">{{this.feePercent}}</b></li>
                <li>Platform fee: <b id="platform-fee">{{this.devFeePercent}}</b></li>
              </ul>
            </div>
            <div *ngIf="!isLoadingRandom" class="modal-footer text-center pt-4 pb-1 px-0">
              <button [disabled]="(approvedTotalAmount != batchBuyTotals.length) || isBatchBuyLoading" (click)="doBatchBuy()" type="button" class="btn btn-primary d-flex align-items-center justify-content-center w-100 text-white">
                <span *ngIf="isBatchBuyLoading" class="mr-3">
                  <span  class="spinner-border text-white" role="status">
                    <span  class="sr-only">Loading...</span>
                  </span>
                </span>
                Batch Buy
                <!-- <p>
                  dismiss
                </p> -->
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>


  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
