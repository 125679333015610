import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-crafting-stations',
  templateUrl: './crafting-stations.component.html',
  styleUrls: ['./crafting-stations.component.scss']
})
export class CraftingStationsComponent implements OnInit {

  isLoading = true;
  stations = [];

  constructor(
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router
  ) { }

  async ngOnInit() {
    this.stations = await this.backendService.getCraftingStations()
    this.isLoading = false
  }

}
