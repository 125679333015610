import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { CONVERTABLE_CRYPTO_LABELS, CONVERTABLE_FIAT_LABELS, FIAT_STATIC_FACTOR_MAP } from '../../app/constant/valuables';
import {BackendService} from "./backend.service";
import {environment} from "../../environments/environment";

import { Logger } from './logger.service';
const log = new Logger('CurrencyService');

const FIATS = CONVERTABLE_FIAT_LABELS
const CRYPTOS = CONVERTABLE_CRYPTO_LABELS
const STATIC_FACTOR = FIAT_STATIC_FACTOR_MAP

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  // API_URL = `${environment.fiatAndCurrencyApiUrl}`
  FIAT_ENDPOINT = environment.fiatApiEndpoint;
  CURRENTCY_ENPOINT = environment.currencyApiEndpoint;

  defaultCurrencyConfig:any = 'USD'

  public currencyFactorTables: any = []

  public currentCurrency: Observable<any>;
  public currentCurrencySubject: BehaviorSubject<any>;

  public secondaryCurrency: Observable<any>;
  public secondaryCurrencySubject: BehaviorSubject<any>;

  public fiatList: Observable<any>;
  public fiatListSubject: BehaviorSubject<any>;

  public cyptoList: Observable<any>;
  public cyptoListSubject: BehaviorSubject<any>;

  public latestTokenPrice:any;
  public latestFiatPrice:any;
  public paymentTokens:any;

  get currentCurrencyValue(): any {
    return this.currentCurrencySubject.value;
  }

  get secondaryCurrencyValue(): any {
    return this.secondaryCurrencySubject.value;
  }

  get fiatListValue(): any {
    return this.fiatListSubject.value;
  }

  get cyptoListValue(): any {
    return this.cyptoListSubject.value;
  }

  constructor(private http: HttpClient, private backendService:BackendService) {
    // log.debug("hello CurrencyService");

    this.currentCurrencySubject = new BehaviorSubject<any>(this.defaultCurrencyConfig);
    this.currentCurrency = this.currentCurrencySubject.asObservable();

    this.secondaryCurrencySubject = new BehaviorSubject<any>(this.defaultCurrencyConfig);
    this.secondaryCurrency = this.secondaryCurrencySubject.asObservable();

    this.fiatListSubject = new BehaviorSubject<any>([]);
    this.fiatList = this.fiatListSubject.asObservable();

    this.cyptoListSubject = new BehaviorSubject<any>([]);
    this.cyptoList = this.cyptoListSubject.asObservable();
    this.init()
    this.updatePaymentTokens()
    this.updateTokenLatestPrice()
    this.updateFiatPrice()
  }

  async updatePaymentTokens(){
    this.paymentTokens = await this.backendService.getAllPaymentTokens()//await this.backendService.getPaymentTokensByChainAbbr(environment.networkAbbr)
    log.debug("getAllPaymentTokens this.paymentTokens",this.paymentTokens)
  }

  async updateTokenLatestPrice(){
    let chain = environment.networkAbbr
    if(chain == 'bkctestnet') chain = 'bkc'
    if(chain == 'polygontestnet') chain = 'polygon'
    const url = `${environment.apiUrl}/v1/supports/latest/prices?chain=${chain}`
    let res = await this.http.get(url).toPromise()
    log.debug("updateTokenLatestPrice",res)
    this.latestTokenPrice = res
  }

  async updateFiatPrice(){
    const url = `${environment.apiUrl}/v1/supports/latest/fiat`
    try {
      let res:any = await this.http.get(url).toPromise()
      log.debug("updateFiatPrice",res)
      this.latestFiatPrice = res.rates
    }catch (e) {
      log.error(e)
      this.latestFiatPrice = [

      ]
    }
  }

  async init(){
    this.currentCurrencySubject.next(await this.getCurrencyConfigFromLocal())
    this.secondaryCurrencySubject.next(await this.getSecondaryCurrencyConfigFromLocal())

    Promise.all([this.getFiats(), this.getCryptos()]).then((values:any) => {

      let fiats:any = values[0]
      let cryptos:any = values[1]

      this.fiatListSubject.next(fiats['rates'])
      this.cyptoListSubject.next(cryptos)

      // log.debug(" this.currencyFactorTables : %o",this.currencyFactorTables)
      let _currencyFactorTables = this.currencyFactorTables

      let fiatTables = []
      let cryptoTables = []

      _.forEach(fiats['rates'], function(value, key) {

        // log.debug("key: " +key + " | value: " + value );
        let targetFiatResult = FIATS.find(it => it == key)
        let factor = value // แปลงเป็น factor (1ดอลล่า = กี่สกุลเงิน)
        if(targetFiatResult){
          let newTargetFiatResult = {
            key: key.toUpperCase(),
            name: key.toUpperCase(),
            type: 'fiat',
            isExtended: true,
            factor: factor,
            overrided: true
          }
          _currencyFactorTables.push(newTargetFiatResult)
          fiatTables.push(newTargetFiatResult)
        }
      });

      _.forEach(CRYPTOS, function(crypto) {
        // log.debug("crypto: %o", crypto );
        let targetCryptoResult = cryptos.find(it => crypto == it._id.toUpperCase())
        if(targetCryptoResult){
          let factor = 1/targetCryptoResult.price // แปลงเป็น factor (1ดอลล่า = กี่เหรียญคลิปโต)
          if(targetCryptoResult){
            let newTargetCryptoResult = {
              key: targetCryptoResult._id.toUpperCase(),
              name: targetCryptoResult._id.toUpperCase(),
              type: 'crypto',
              isExtended: true,
              factor: factor,
              overrided: true
            }
            _currencyFactorTables.push(newTargetCryptoResult)
            cryptoTables.push(newTargetCryptoResult)
          }
        }
      });

      // log.debug("xxxxxxxxxxxxxxxxxxx fiatTables : %o",fiatTables)
      // log.debug("xxxxxxxxxxxxxxxxxxx cryptoTables : %o",cryptoTables)

    }).then(()=>{
      // log.debug("xxxxxxxxxxxxxxxxxxx this.currencyFactorTables : %o",this.currencyFactorTables)
      this.getFactorCurrency()
      this.getFactorSecondaryCurrency()
    })
  }

  async convertToMainCurrency(value: number,customCurrency: any = null){
    let currentCurrencyAmount // จำนวนเงินสุทธิในค่าเงินที่เลือก
    let currentConfig = await this.getCurrencyConfig()
    let factor

    if(customCurrency){
      factor = await this.getFactorByCurrency(customCurrency)
    }else{
      factor = await this.getFactorByCurrency(currentConfig)
    }
    currentCurrencyAmount = value * factor
    return currentCurrencyAmount
  }

  async getFactorCurrency(){
    if(this.currentCurrencyValue){
      return await this.getFactorByCurrency(this.currentCurrencyValue)
    }
  }

  async getFactorSecondaryCurrency(){
    if(this.secondaryCurrencyValue){
      return await this.getFactorByCurrency(this.secondaryCurrencyValue)
    }
  }

  async getFactorByCurrency(currency){
    let _currencyFactorTable = this.currencyFactorTables.find( it => it.key == currency )
    let factor
    if(_currencyFactorTable){
      factor = _currencyFactorTable.factor
    }else{
      factor = await this.getStaticFactor(currency)
    }
    return factor || 1
  }

  getFiatsList(){
    return FIATS
  }

  getCryptosList(){
    return CRYPTOS
  }

  getFiatsListApi(){
    return this.fiatListValue
  }

  getCryptosListApi(){
    return this.cyptoListValue
  }

  getMainCurrency(){
    return this.currentCurrencyValue
  }

  getCurrencyConfig(){
    return this.currentCurrencyValue
  }

  getSecondaryCurrencyConfig(){
    return this.secondaryCurrencyValue
  }

  getCurrencyConfigFromLocal(){
    let currencyConfig = localStorage.getItem('currencyConfig');
    if(!currencyConfig){
      localStorage.setItem("currencyConfig",this.defaultCurrencyConfig)
      currencyConfig = this.defaultCurrencyConfig
    }
    // this.currentCurrencySubject.next(currencyConfig)
    return currencyConfig
  }

  getSecondaryCurrencyConfigFromLocal(){
    let secondaryCurrencyConfig = localStorage.getItem('secondaryCurrencyConfig');
    if(!secondaryCurrencyConfig){
      localStorage.setItem("secondaryCurrencyConfig",this.defaultCurrencyConfig)
      secondaryCurrencyConfig = this.defaultCurrencyConfig
    }
    // this.secondaryCurrencySubject.next(secondaryCurrencyConfig)
    return secondaryCurrencyConfig
  }

  isFiatOrCrpto(){
    if(FIATS.includes(this.currentCurrencyValue)){
      return 'FIAT'
    }else if(CRYPTOS.includes(this.currentCurrencyValue)){
      return 'CRYPTO'
    }else{
      return 'FIAT'
    }
  }

  async getFiats() {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await this.http.get(this.FIAT_ENDPOINT).toPromise();
        // log.debug("getFiats: %o",res);
        resolve(res)
      } catch (error) {
        // log.debug(error);
      }
    })
  }

  async getCryptos(chain='bkc') {
    return new Promise(async (resolve, reject) => {
      resolve([])
      const endpoint = `${this.CURRENTCY_ENPOINT}?chain=${chain}`;
      // try {
      //   let res = await this.http.get(endpoint).toPromise();
      //   log.debug("getCryptos: %o",res);
      //   resolve(res)
      // } catch (error) {
      //   log.debug(error);
      // }
    })
  }

  async getStaticFactor(currency:string){
    return await STATIC_FACTOR[currency.toUpperCase()] // STATIC_FACTOR['THB']
  }

  async forceUpdateCurrency(){
    await this.updatePaymentTokens()
    await this.updateTokenLatestPrice()
    await this.updateFiatPrice()
  }

  async getPaymentTokenImage(address,chain){
    const token = this.paymentTokens.find(it => it.address.toLowerCase() == address.toLowerCase() && it.chain == chain)
    return token.assetImage
  }

  async addValuesToStatisticArray(arry){
    if(this.paymentTokens == undefined || this.latestFiatPrice == undefined || this.latestTokenPrice == undefined)
      await this.forceUpdateCurrency()

    let _arry = arry.map(elem => {

      let _token = this.paymentTokens.find(it => it.address == elem._id)
      let _symbol = _token.symbol == 'kub' ? 'kkub':_token.symbol
      let _price = this.latestTokenPrice[_symbol]
      elem.usd = elem.amount * _price
      elem.thb = this.latestFiatPrice['THB'] * elem.usd
      elem.symbol = _token.symbol
      return elem
    })

    return _arry
  }

  async changeUsdToThb(usdAmount){
    if(this.paymentTokens == undefined || this.latestFiatPrice == undefined || this.latestTokenPrice == undefined)
      await this.forceUpdateCurrency()

    return this.latestFiatPrice['THB'] * usdAmount
  }

  async getLatestTokenPrice(){
    await this.forceUpdateCurrency()
    return this.latestTokenPrice
  }

  async getUSDFromSymbol(symbol){
    if(this.paymentTokens == undefined || this.latestFiatPrice == undefined || this.latestTokenPrice == undefined)
      await this.forceUpdateCurrency()
    let _price = this.latestTokenPrice[symbol]
    return _price
  }

  async getTokenInfoFromAddressAndChain(address,networkAbbr = undefined){
    if(!networkAbbr) networkAbbr = environment.networkAbbr
    log.debug("getTokenInfoFromAddressAndChain "+address +" | "+networkAbbr)
    if(!address) return undefined
    if(this.paymentTokens == undefined || this.latestFiatPrice == undefined || this.latestTokenPrice == undefined)
      await this.forceUpdateCurrency()

    // let _token = await this.paymentTokens.find(paymentToken => paymentToken.address == address)
    let _token = await this.paymentTokens.find(paymentToken => paymentToken.address == address && paymentToken.chain == networkAbbr)
    log.debug("this.paymentTokens ",this.paymentTokens)
    log.debug("this.paymentTokens.map(it => it.chain) ",this.paymentTokens.map(it => it.chain))
    log.debug("_token ",_token)
    log.debug("networkAbbr ",networkAbbr)
    log.debug("address ",address)
    // log.debug("_token.name ",_token.name)
    // log.debug("_token.symbol ",_token.symbol)
    if(_token){
      let _symbol = _token.symbol == 'kub' ? 'kkub': _token.symbol
      let _price = this.latestTokenPrice[_symbol]
      let result = {
        address: address,
        usd: _price,
        thb: this.latestFiatPrice['THB'] * _price,
        symbol: _token.symbol
      }
      return result
    }else{
      return {
        address: address,
        symbol: 'UNKNOWN'
      }
    }

  }

  async isPeg(tokenAddress){
    if(this.paymentTokens == undefined || this.latestFiatPrice == undefined || this.latestTokenPrice == undefined)
      await this.forceUpdateCurrency()

    log.debug("isPeg this.paymentTokens ",this.paymentTokens)
    let _token = this.paymentTokens.find(it => it.address == tokenAddress)
    log.debug("isPeg _token ",_token)

    if(_token){
      return _token.peg
    }else{
      return false
    }
  }

}
