import { SelectedFiltersService } from './../../services/selected-filters.service';
import { SingletonService } from './../../services/singleton.service';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit } from '@angular/core';

import { Logger } from '../../services/logger.service';
const log = new Logger('SelectedFilterCardSectionComponent'); // log.debug

@Component({
  selector: 'app-selected-filter-card-section',
  templateUrl: './selected-filter-card-section.component.html',
  styleUrls: ['./selected-filter-card-section.component.scss']
})
export class SelectedFilterCardSectionComponent implements OnInit {

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    public selectedFiltersService:SelectedFiltersService,
  ) { }

  ngOnInit(): void {

  }


  removeSelectedCollection(collection){
    this.selectedFiltersService.selectedFilters.collections = this.selectedFiltersService.selectedFilters.collections.filter(it => it.id != collection.id)
    this.selectedFiltersService.removeSelectedCollectionSubject.next(collection)

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  removeSelectedChain(chain){
    this.selectedFiltersService.selectedFilters.chains = this.selectedFiltersService.selectedFilters.chains.filter(it => it.networkName != chain.networkName)
    this.selectedFiltersService.removeSelectedChainSubject.next(chain)

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  setEmptyPaymentToken(){
    this.selectedFiltersService.selectedFilters.price = this.selectedFiltersService.emptyPaymentToken

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  removeCategory(category){
    const index = this.selectedFiltersService.selectedFilters.categories.indexOf(category);
    if (index > -1) {
      this.selectedFiltersService.selectedFilters.categories.splice(index, 1);
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  removeAttr(attr){
    this.selectedFiltersService.selectedFilters.attrs = this.selectedFiltersService.selectedFilters.attrs.filter(x =>
      x.type != attr.type && x.value != attr.value
    );
    this.selectedFiltersService.removeSelectedAttrSubject.next(attr)

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }



  removeEventType(eventType){
    const index = this.selectedFiltersService.selectedFilters.eventTypes.indexOf(eventType);
    if (index > -1) {
      this.selectedFiltersService.selectedFilters.eventTypes.splice(index, 1);
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  async clearAll(){
    await this.selectedFiltersService.clearAllSubject.next(this.selectedFiltersService.selectedFilters)
    await this.selectedFiltersService.initSelectedFilters()

    await this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }
}
