import { Pipe, PipeTransform } from '@angular/core';
import { ToCappitalPipe } from './to-cappital.pipe';

@Pipe({
  name: 'craftingResultTypeToText'
})
export class CraftingResultTypeToTextPipe implements PipeTransform {

  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}
  transform(value: any, ...args: any[]): unknown {

    switch (value) {
      case 0:
        return "Token";
      case 1:
        return "NFT";
      default:
        return "UNKNOWN";
    }
  }

}
