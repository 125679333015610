<div class="main">
  <div class="wrapper">
    <h5>It’s a pleasure to meet you.</h5>
    <div class="btn-wrapper btn-border-animation aura-effect">
      <a (click)="connectWallet()" class="text-white">
        {{text}}
      </a>
    </div>
  </div>
</div>
