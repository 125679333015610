<div class="main">

  <app-header-section></app-header-section>

  <section class="">

    <h2 class="text-center title-h2">Explore Projects</h2>

    <p class="text-center">Featured projects</p>
    <app-project-section></app-project-section>

  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>

</div>
