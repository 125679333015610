import { HideWalletAddressPipe } from './../../pipes/hide-wallet-address.pipe';
import { BackendService } from './../../services/backend.service';
import { CurrencyService } from './../../services/currency.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
import {Router} from "@angular/router";

import { Logger } from '../../services/logger.service';
import {MiningService} from "../../services/mining.service";
import { environment } from 'src/environments/environment';
const log = new Logger('HeaderSectionComponent');

const DEMO_MODE = environment.features.DEMO_MODE

@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent implements OnInit {

  theme:any;

  // wallet params
  account:any
  isWrongNetwork:any
  currentChain:any
  nftProfile:any;

  // currency params
  public currencyConfig:any
  public fiatsList:any
  public factorCurrency:any

  // language params
  public languageOptions: any;
  public selectedLanguage: any;

  userProfileInfos
  supportChains = []

  hideSearchHeader: boolean = false

  isDemoMode = DEMO_MODE

  constructor(
    public singletonService: SingletonService,
    public currencyService: CurrencyService,
    private modalService:NgbModal,
    public _translateService: TranslateService,
    public router: Router,
    public backendService:BackendService,
    public hideWalletAddressPipe:HideWalletAddressPipe,
    public miningService:MiningService
  ) {
    this.languageOptions = {
      en: {
        title: 'EN',
        flag: 'us'
      },
      th: {
        title: 'TH',
        flag: 'th'
      }
    };
  }

  async ngOnInit(){
    // log.debug("currentLang %o ",this._translateService.currentLang)
    this.supportChains = await this.singletonService.supportChains
    this.theme = await this.getTheme();
    await this.setTheme(this.theme)

    this.account = await this.singletonService.account
    log.debug("--- this.account %o ",this.account)

    this.userProfileInfos = await this.singletonService.currentUserInfosSubject.value
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      log.debug(" getAccountSubject this.account %o ",this.account)
      this.account = account
      if(this.account){
        await this.fetchNFTProfile()
      }

      let currentAccountElement:any = document.getElementById('current-account')
      if(currentAccountElement){
        currentAccountElement.innerHTML = this.hideWalletAddressPipe.transform(this.account);
      }
    });

    this.userProfileInfos = await this.singletonService.currentUserInfosSubject.value
    this.singletonService.currentUserInfosSubject.subscribe(async (currentUserInfos) => {
      // log.debug("currentUserInfos %o ",currentUserInfos)
      this.userProfileInfos = currentUserInfos
    });

    // wallet
    this.singletonService.currentChainSubject.subscribe((currentChain) => {
      this.currentChain = currentChain
      if(currentChain){
        let chainNameElement:any = document.getElementById('current-chain-name')
        if(chainNameElement){
          chainNameElement.innerHTML = currentChain.networkName;
        }
      }
    })
    this.singletonService.isWrongNetworkSubject.subscribe((isWrongNetwork) => {
      this.isWrongNetwork = isWrongNetwork
    })

    // currency
    this.fiatsList = this.currencyService.getFiatsList()
    this.currencyConfig = this.currencyService.getCurrencyConfig();
    this.currencyService.currentCurrencySubject.subscribe(async (currency)=>{
      this.currencyConfig = currency
      this.factorCurrency = await this.currencyService.getFactorCurrency()
      //  log.debug("this.factorCurrency %o",this.factorCurrency)
    })

    // language
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

    if(this.account){
      await this.fetchNFTProfile()
    }

    this.miningService.getObserveProfileSubject().subscribe(async (changed)=>{
      if(changed){
        this.nftProfile = await this.miningService.getProfile()
      }
    })
  }

  async fetchNFTProfile(){
    // nft profile
    this.nftProfile = await this.miningService.getProfile(this.account)
    log.debug("nftProfile ",this.nftProfile)
  }


  toggleTheme(_theme: any) {
    log.debug(' _theme : ' + _theme);
    this.setTheme(_theme)
  }

  async setTheme(_theme:any = 'dark'){
    if(_theme == 'dark') {
      this.theme = 'dark';
      document.body.classList.remove("dark");
      document.body.classList.add("dark");
      await localStorage.setItem("theme",_theme)
    } else {
      this.theme = 'light';
      document.body.classList.remove("dark");
      await localStorage.setItem("theme",_theme)
    }
  }

  async getTheme(){
    let _theme = await localStorage.getItem("theme")
    if(!_theme){
        _theme = 'dark'
        await localStorage.setItem("theme",_theme)
    }
    return _theme
  }

  async connectWalletModal(walletSelectionModal:any){
    this.openBasicModal(walletSelectionModal,'modal-connect-wallet')
  }

  async connectWallet(){
    // this.connectedWalletDisplay = '[mock] 0x12..6789'
    // this.singletonService.fire('info','wallet connecting')
    this.account = await this.singletonService.connectWallet()
  }

  logout(logoutModal:any){
    this.openBasicModal(logoutModal,'modal-logout')
  }

  openBasicModal(content:any,classModal:any){
    this.modalService.open(content, {
      centered: true,
      windowClass: classModal,
      backdropClass: 'backdrop-connect-wallet',
      backdrop: 'static',
    }).result.then((result:any) => {
      // log.debug(result)
      if(result == "logout"){
        this.account = null;
        this.singletonService.disconnectWallet()
      }
    }).catch((res:any) => {});
  }

  openSwitchChainModal(modal:any){
    this.modalService.open(modal, {
      centered: true,
      // windowClass: 'modal-connect-wallet'
    });
  }

  switchChain(chain:any){
    if(!chain.soon){
      this.singletonService.switchChain(chain)
    }
  }

  setCurrencyConfig(currency:string){
    localStorage.setItem("currencyConfig",currency)
    this.currencyService.currentCurrencySubject.next(currency)
  }

  modalOpenCurrency(modal){
    this.modalService.open(modal, {
      centered: true,
      size: 'md',
      windowClass: 'modal modal-currency'
    });
  }

  modalOpenMenuMobile(modal){
    this.modalService.open(modal, {
      centered: true,
      size: 'md',
      animation: true,
      modalDialogClass: 'dialog-animated',
      windowClass: 'modal modal-menu-mobile'
    });
  }

  setLanguage(language): void {
    log.debug("setLanguage language: %o",language)

    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);
    this.singletonService.switchLanguage(language)
  }

  searchAllItems() {
    log.debug('work !!! enter search text ...')
  }

  toggleSearchHeaderWrapper(val:string) {
    this.hideSearchHeader = !this.hideSearchHeader
    var listItems = document.getElementById("wrapper-search-autocomplete");
    if(this.hideSearchHeader) {
      if (val.length >= 3) {
      listItems.style.display = "block";
      }
    } else {
      listItems.style.display = "none";
    }
  }


  async searchAutoComplete(text) {
    this.searchResults = {
      assets: [],
      collections: [],
      users: [],
    }
    let res = await this.backendService.searchKeyword(text)
    log.debug("searchAutoComplete res => %o",res)

    if(res){
      this.searchResults = res
      let users = this.searchResults.users

      log.debug("users => %o",users)

      this.searchResults.users = await Promise.all(users.map(async (it): Promise<any> => {
        it.nftProfile = await this.fetchNFTProfileByUser(it.publicAddress)
        return it;
      }));

      log.debug("this.searchResults => %o",this.searchResults)

    }

  }

  searchResults = {
    assets: [],
    collections: [],
    users: [],
  }
  delayTimer:any;
  isLoadingSearchResults = false
  async searchWithDelay(event){
    var text = event.target.value;
    const self = this
    var listItems = document.getElementById("wrapper-search-autocomplete");
    if (text.length >= 3) {
      listItems.style.display = "block";
      clearTimeout(self.delayTimer);
      self.isLoadingSearchResults = true
      self.delayTimer = setTimeout(async function() {
        try{
          await self.searchAutoComplete(text)
          self.isLoadingSearchResults = false
        }catch(err){
          self.isLoadingSearchResults = false
        }
      }, 1000);
  }else{
      listItems.style.display = "none";
    }
  }

  getImageSupportChainsByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }

  async jumpToCollection(collection){
    let path = '/collection/'+ collection.slug
    let href = this.router.url;
    if(href.startsWith('/collection/')){ // same route
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([path]);
      });
    }else{
      this.router.navigate([path]);
    }
  }

  async fetchNFTProfileByUser(_user){
    // let _user = this.publicAddress ? this.publicAddress:this.account
    log.debug("user to fetch profile ",_user)
    return await this.miningService.getProfile(_user)
    // log.debug("nftProfile ", this.nftProfile)
  }

  async jumpToNFTShow(asset){
    let path = '/nft/'+ asset.nftAddress +'/'+ asset.tokenId
    let href = this.router.url;
    if(href.startsWith('/nft/')){ // same route
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([path],{ queryParams: { chain: asset.chain }});
      });
    }else{
      this.router.navigate([path],{ queryParams: { chain: asset.chain }});
    }
  }

  async jumpToAccount(account){
    let path = '/account/'+ account.name
    let href = this.router.url;
    if(href.startsWith('/account/')){ // same route
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([path]);
      });
    }else{
      this.router.navigate([path]);
    }
  }

  // wrapper-connect-with-crypto-and-social
  // login-with-username-and-password
  // forgot-password

  openConntectSection(sectionShow:any,sectionHide:any) {
    var secHide = document.getElementById(sectionShow);
    secHide.style.display = 'none';
    var secShow = document.getElementById(sectionHide);
    secShow.style.display = 'block';
  }

}
