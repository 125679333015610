import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-help-center',
  templateUrl: './breadcrumb-help-center.component.html',
  styleUrls: ['./breadcrumb-help-center.component.scss']
})
export class BreadcrumbHelpCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
