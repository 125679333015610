import { SingletonService } from 'src/app/services/singleton.service';
import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BackendService} from "../../../services/backend.service";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

import { Logger } from 'src/app/services/logger.service';
const log = new Logger('RedeemModalComponent');

import * as _ from 'lodash';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-redeem-modal',
  templateUrl: './redeem-modal.component.html',
  styleUrls: ['./redeem-modal.component.scss']
})
export class RedeemModalComponent implements OnInit {

  readonly ITEM_OBJECTS = [
    {
      id: "1",
      name: "iPhone 14 Pro",
      capacity: "256 GB",
      color: "Deep Purple",
      textDisplay: "iPhone 14 Pro 256GB",
      disabled: true,
    },
    {
      id: "2",
      name: "iPhone 14 Pro",
      capacity: "256 GB",
      color: "Space Black",
      textDisplay: "iPhone 14 Pro 256GB",
      disabled: true,
    },
    {
      id: "3",
      name: "iPhone 14 Pro",
      capacity: "256 GB",
      color: "Gold",
      textDisplay: "iPhone 14 Pro 256GB",
      disabled: true,
    },
    {
      id: "4",
      name: "iPhone 14 Pro",
      capacity: "256 GB",
      color: "Silver",
      textDisplay: "iPhone 14 Pro 256GB",
      disabled: true,
    },
    {
      id: "5",
      name: "iPhone 14 Pro Max",
      capacity: "256 GB",
      color: "Deep Purple",
      textDisplay: "iPhone 14 Pro Max 256GB",
      disabled: false,
    },
    {
      id: "6",
      name: "iPhone 14 Pro Max",
      capacity: "256 GB",
      color: "Space Black",
      textDisplay: "iPhone 14 Pro Max 256GB",
      disabled: true,
    },
    {
      id: "7",
      name: "iPhone 14 Pro Max",
      capacity: "256 GB",
      color: "Gold",
      textDisplay: "iPhone 14 Pro Max 256GB",
      disabled: true,
    },
    {
      id: "8",
      name: "iPhone 14 Pro Max",
      capacity: "256 GB",
      color: "Silver",
      textDisplay: "iPhone 14 Pro Max 256GB",
      disabled: true,
    },
  ]

  readonly METHOD_OBJECTS = [
    // 'Walk In - Banana IT สาขา Emquartier',
    'Walk In - สาทรสแควร์ BTS ช่องนนทรี',
    'Delivery',
    // 'Delivery - 123/123, ภูเก็ต, เมือง, ฉลอง, 83000 || Walk In - Banana IT',
  ]


  @Output() dataService = new EventEmitter();
  @Input() poolInfo;
  @Input() nft;
  @Input() user;

  @Input() customAPI_URL;

  receiver:string
  isValidated = false

  title:string;
  firstname:string;
  lastname:string;
  nationalID:string;
  phone:string;

  email:string;
  method:string;
  itemID:string;
  note:string;

  color:string; // selected
  item:string; // selected


  address:string;
  province:string;
  district:string;
  subDistrict:string;
  zipcoode:string;

  submitted=false

  public form: FormGroup;
  public state = 0;

  lockedId
  isRedeemed
  isClaimed

  isLoadingRedeemRewardsInfo = false
  isLoadingSubmit = false

  YES_API_URL = "https://main.yuemmai.digital/iphone/85b2be9c-4201-491f-ad65-68f9144e52e3"
  headerAuthorization =	"a6993cdb-bbd2-433f-98e5-f54cb27dd986"

  colors = []
  items = []

  redeemedEmail

  constructor(
    public modal: NgbActiveModal,
    public backendService: BackendService,
    private _formBuilder: FormBuilder,
    private singletonService: SingletonService,
    ) { }

  async ngOnInit(){
    await this.initialDatas()

    this.isLoadingRedeemRewardsInfo = true

    const redeemRewardsInfo:any = await this.getRedeemRewardsInfo()
    log.debug("redeemRewardsInfo => ",redeemRewardsInfo)

    if(redeemRewardsInfo && redeemRewardsInfo.miningLock){
      this.lockedId = redeemRewardsInfo.miningLock.id
      this.isClaimed = redeemRewardsInfo.miningLock.claimed ? redeemRewardsInfo.miningLock.claimed : false

      this.isRedeemed = redeemRewardsInfo.miningLock.reward ? redeemRewardsInfo.miningLock.reward.redeemed : false

      this.redeemedEmail = redeemRewardsInfo.miningLock.reward ? redeemRewardsInfo.miningLock.reward.redeemInfo.email : undefined

      log.debug("this.lockedId => ",this.lockedId)
      log.debug("this.isRedeemed => ",this.isRedeemed)
      log.debug("this.isClaimed => ",this.isClaimed)

      if(this.isRedeemed) this.state = 2
    }

    this.isLoadingRedeemRewardsInfo = false

    this.isValidated =false
    this.form = this._formBuilder.group({
      // title: [this.title ? this.title:'', [Validators.required]],
      nationalID: [this.nationalID ? this.nationalID:'', [Validators.required, Validators.minLength(13), Validators.pattern(/^[0-9]+[0-9]*$/)]],
      firstname: [this.firstname ? this.firstname:'', [Validators.required]],
      lastname: [this.lastname ? this.lastname:'', [Validators.required]],
      phone: [this.phone ? this.phone:'', [Validators.required,Validators.minLength(9), Validators.pattern(/^[0-9]+[0-9]*$/)]],
      email: [this.email ? this.email:'', [Validators.required,Validators.email]],
      method: [this.method ? this.method:'', [Validators.required]],
      note: [this.note ? this.note:'', []],
      address: [this.address ? this.address:'', []],
      province: [this.province ? this.province:'', []],
      district: [this.district ? this.district:'', []],
      subDistrict: [this.subDistrict ? this.subDistrict:'', []],
      zipcoode: [this.zipcoode ? this.zipcoode:'', []],
    })
  }


  async initialDatas(){

    this.colors = [];
    log.debug("this.colors => ",this.colors)

    this.items = _.uniq(this.ITEM_OBJECTS.map(it => it.textDisplay));
    log.debug("this.items => ",this.items)

    for (const textDisplay of this.items) {
      const isAnyEnabled = this.ITEM_OBJECTS.find( it => it.textDisplay == textDisplay && it.disabled == false )
      if(!isAnyEnabled){ // delete key that all item (disabled == false)
        const index = this.items.indexOf(textDisplay);
        if (index !== -1) {
          this.items.splice(index, 1);
        }
      }
    }

    this.color = undefined
    log.debug("this.color => ",this.color)

    this.item = undefined
    log.debug("this.item => ",this.item)

    this.method = this.METHOD_OBJECTS[0]
    log.debug("this.method => ",this.method)

    // await this.getNewItemId()

    // this.items = _.uniq(this.ITEM_OBJECTS.map(it => it.color));
    // log.debug("this.colors => ",this.colors)
  }

  async send(){

  }

  async accept(){
    this.state = 1
  }

  async onSubmit(){
    Swal.fire({
      title: 'คุณแน่ใจแล้วหรือไม่?',
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.doSubmit()
        // Swal.fire('Saved!', '', 'success')
      }
    })

  }

  isEmpty(str) {
    return (!str || str.length === 0 );
  }

  async doSubmit(){
    log.debug("doSubmit...")

    this.submitted = true;
    if (this.form.invalid) {
      log.debug("form invalid...")
      return;
    }

    if(!this.itemID || !this.item || !this.color || !this.method){
      log.debug("btn invalid...")
      Swal.fire('กรุณากรอกข้อมูลให้ครบถ้วน')
      return
    }

    // if(this.method == 'Delivery'){
    //   log.debug("btn invalid...")
    //   Swal.fire('กรุณากรอกข้อมูลให้ครบถ้วน')
    //   return
    // }

    // const title = this.form.get('title').value
    const firstname = this.form.get('firstname').value
    const lastname = this.form.get('lastname').value
    const nationalID = this.form.get('nationalID').value
    const phoneNumber = this.form.get('phone').value
    const email = this.form.get('email').value
    // const method = this.form.get('method').value
    const note = this.form.get('note').value

    // log.debug("onSubmit title => ",title)
    log.debug("onSubmit firstname => ",firstname)
    log.debug("onSubmit lastname => ",lastname)
    log.debug("onSubmit nationalID => ",nationalID)
    log.debug("onSubmit phoneNumber => ",phoneNumber)
    log.debug("onSubmit email => ",email)
    log.debug("onSubmit this.method => ",this.method)
    log.debug("onSubmit note => ",note)

    log.debug("onSubmit this.itemID => ",this.itemID)

    let _method
    if(this.method == 'Delivery'){ // custom medthod string by user address infos


      _method = 'Delivery - ' // 'Delivery - 123/123, ภูเก็ต, เมือง, ฉลอง, 83000 || Walk In - Banana IT',

      const address = this.form.get('address').value
      const province = this.form.get('province').value
      const district = this.form.get('district').value
      const subDistrict = this.form.get('subDistrict').value
      const zipcoode = this.form.get('zipcoode').value
      log.debug("onSubmit address => ",address)
      log.debug("onSubmit province => ",province)
      log.debug("onSubmit district => ",district)
      log.debug("onSubmit subDistrict => ",subDistrict)
      log.debug("onSubmit zipcoode => ",zipcoode)

      if(this.isEmpty(address) || this.isEmpty(province) || this.isEmpty(district) || this.isEmpty(subDistrict) || this.isEmpty(zipcoode)){
        log.debug("address Infos invalid...")
        Swal.fire('กรุณากรอกข้อมูลที่อยู่จัดส่งให้ครบถ้วน')
        return
      }

      _method += address // Delivery - 123/123
      _method += (', ' + province) // Delivery - 123/123, ภูเก็ต
      _method += (', ' + district)
      _method += (', ' + subDistrict)
      _method += (', ' + zipcoode)

    }else{
      _method = this.method
    }

    log.debug("onSubmit _method => ",_method)

    this.isLoadingSubmit = true

    const res:any = await this.backendService.saveRedeemInfo(
      this.lockedId,
      firstname,
      lastname,
      nationalID,
      phoneNumber,
      email,
      _method,
      this.itemID,
      note,
    )

    log.debug("saveRedeemInfo res => ",res)
    if(res.success){
      this.redeemedEmail = res.reward.redeemInfo.email
      // TODO : Show QR CODE
      this.state = 2
    }else{
      this.singletonService.fire("error","Something wrong","error on save redeem infos")
    }

    this.isLoadingSubmit = false
  }

  async getRedeemRewardsInfo(){
    const res = await this.backendService.getRedeemRewardsInfo(
      this.poolInfo.address,
      this.poolInfo.collectionAddress,
      this.nft.tokenIdNumber,
    )
    log.debug("getRedeemRewardsInfo res => ",res)
    return res
  }

  get f() {
    return this.form.controls;
  }

  getErrorList(errorObject) {
    return Object.keys(errorObject);
  }

  async getNewItemId(){
    if(this.item && this.color){
      const targetItem = this.ITEM_OBJECTS.find(it => it.textDisplay == this.item && it.color == this.color)
      log.debug("getNewItemId targetItem  => ",targetItem )

      this.itemID = targetItem.id
      log.debug("getNewItemId this.itemID  => ",this.itemID )
    }
  }

  async changeItemValue(value){
    log.debug("changeItemValue value  => ",value )
    this.item = value
    this.color = undefined
    await this.getColorsByItem()
    await this.getNewItemId()
  }

  async changeColorValue(value){
    log.debug("changeColorValue value  => ",value )
    this.color = value
    await this.getNewItemId()
  }

  async getColorsByItem(){
    const enabledItems = this.ITEM_OBJECTS.filter(it=> !it.disabled && it.textDisplay == this.item)
    log.debug("getColorsByItem enabledItems  => ",enabledItems )

    const _colors = enabledItems.map(it => it.color)
    log.debug("getColorsByItem _colors  => ",_colors )

    this.colors = _.uniq(_colors);
  }

  async changeMethodValue(value){
    log.debug("changeMethodValue value  => ",value )
    this.method = value
  }

}
