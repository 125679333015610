<div class="main">
  <app-header-section></app-header-section>
  <div >
    <div *ngIf="market && !isLoading" class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="market.bannerImage | renderImageUrl: 'banner'" [alt]="market.name">
        </div>
      </div>
    </div>
  </div>
  <section class="whitelist-market">

    <div *ngIf="!market">
      <h2 class="text-center">Whitelist market not found</h2>
    </div>
    <div *ngIf="!isLoading && market">
      <h2 class="text-center">{{market.name}}</h2>
      <p class="text-center">{{market.description}}</p>
      <p class="text-center" *ngIf="!isWhitelistChecking">
        <span *ngIf="isWhitelist" class="text-success">You are whitelisted</span>
        <span *ngIf="!isWhitelist" class="text-danger">You are not whitelisted</span>
        (Quota to buy : {{quotaLeft}})
      </p>
    </div>
    <div *ngIf="isLoading || isDataFetching" class="col-12 text-center py-5 my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>


    <div class="text-center mt-5 mb-5" *ngIf="!isApproved && !isDataFetching">
      <button *ngIf="!isApproving" class="btn btn-primary w-100  text-white max-w-300" (click)="approve()">
        Approve Payment Token
      </button>
      <div *ngIf="isApproving" class="col-12 text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>


    <div class="container" *ngIf="!isDataFetching">
      <div class="row mt-5  my-3">
        <div *ngFor="let order of orders" class="col-md-3  mb-4  ">

          <div class="box-nft card no-hover">
            <div class="image-over">
              <div class="image-over-wrapper">
                <img *ngIf="market.media === 'image'" class="card-img-top" [src]="order.image | renderImageUrl : 'logo'" alt="">
                <video *ngIf="market.media === 'video'"
                       class="card-img-top video-player"
                       [poster]="order.image | renderImageUrl : 'logo'"
                       [src]="order.meta.animation_url"
                       loop>
                </video>
                <div  *ngIf="market.media === 'video'" class="btn-nft-action-vdo">
                  <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                    <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" style="margin-left: 1px;">
                    <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" style="display: none;">
                  </button>
                </div>
              </div>
            </div>
            <div class="card-caption position-relative">

              <div class="mt-1">
                <span class="tx-18 text-truncate">
                    {{order.name}}
                </span>
                <span class="text-muted ml-1">
                    #{{order.tokenId}}
                  </span>
              </div>

              <p class="tx-12 mt-0 pt-0 mb-0">{{order.description}}</p>
              <div class="tx-15 text-white-main font-weight-bold text-truncate mt-3 mb-3">
                {{order.price}}
                {{order.paymentToken.symbol ? order.paymentToken.symbol.toUpperCase() : ''}}
              </div>
              <div *ngIf="isApproved && !order.bought">
              <button class="btn btn-primary w-100  text-white " [disabled]="order.loading" (click)="buy(order.orderId)">
                <span *ngIf="order.loading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                Buy
              </button>
              </div>
              <div *ngIf="!isApproved" class="text-center">
                <i>Please approve payment token</i>
              </div>

              <div *ngIf="order.bought" class="text-center">
                <i>Already bought</i>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
