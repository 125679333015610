import { NgModule } from '@angular/core';

import { FormatNumberPipe } from './format-number.pipe';
import { ToCappitalPipe } from './to-cappital.pipe';
import { ToUpperCasePipe } from './to-upper-case.pipe';
import { ToLowerCasePipe } from './to-lower-case.pipe';
import { HideWalletAddressPipe } from './hide-wallet-address.pipe';
import { GameNumberFormatPipe } from './game-number-format.pipe';
import { UnixTimeStampToDateStringFormatPipe } from './unix-time-stamp-to-date-string-format.pipe';
import { DiffDateTimeFromNowPipe } from './diff-date-time-from-now.pipe';
import { RenderImageUrlPipe } from './render-image-url.pipe';
import { ExternalLinkByTypePipe } from './external-link-by-type.pipe';
import { FindMinimunByFieldNamePipe } from './find-minimun-by-field-name.pipe';
import { EventTypeToTextPipe } from './event-type-to-text.pipe';
import { TimeFromNowPipe } from './time-from-now.pipe';
import { CategoryTypeToTextPipe } from './category-type-to-text.pipe';
import { CombineArrayPipe } from './combine-array.pipe';
import { VerificationTypeToTextPipe } from './verification-type-to-text.pipe';
import { FeatureAttrBadgePipe } from './feature-attr-badge.pipe';
import { FindAllByPipe } from './find-all-by.pipe';
import { EndWithFormatPipe } from './end-with-format.pipe';
import { ConverterStateTypeToTextPipe } from './converter-state-type-to-text.pipe';
import { CraftingResultTypeToTextPipe } from './crafting-result-type-to-text.pipe';

@NgModule({
  declarations: [
    FormatNumberPipe,
    ToCappitalPipe,
    ToUpperCasePipe,
    ToLowerCasePipe,
    HideWalletAddressPipe,
    GameNumberFormatPipe,
    UnixTimeStampToDateStringFormatPipe,
    DiffDateTimeFromNowPipe,
    RenderImageUrlPipe,
    ExternalLinkByTypePipe,
    FindMinimunByFieldNamePipe,
    EventTypeToTextPipe,
    TimeFromNowPipe,
    CategoryTypeToTextPipe,
    CombineArrayPipe,
    VerificationTypeToTextPipe,
    FeatureAttrBadgePipe,
    FindAllByPipe,
    EndWithFormatPipe,
    ConverterStateTypeToTextPipe,
    CraftingResultTypeToTextPipe,
   ],
  imports: [],
  exports: [
    FormatNumberPipe,
    ToCappitalPipe,
    ToUpperCasePipe,
    ToLowerCasePipe,
    HideWalletAddressPipe,
    GameNumberFormatPipe,
    UnixTimeStampToDateStringFormatPipe,
    DiffDateTimeFromNowPipe,
    RenderImageUrlPipe,
    ExternalLinkByTypePipe,
    FindMinimunByFieldNamePipe,
    EventTypeToTextPipe,
    TimeFromNowPipe,
    CategoryTypeToTextPipe,
    CombineArrayPipe,
    VerificationTypeToTextPipe,
    FeatureAttrBadgePipe,
    FindAllByPipe,
    EndWithFormatPipe,
    ConverterStateTypeToTextPipe,
    CraftingResultTypeToTextPipe,
  ]
})
export class CorePipesModule {}
