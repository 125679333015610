<div class="main">
  <app-header-section></app-header-section>

  <section class="nft-reveals">

    <h2 class="text-center title-h2">NFT Reveals</h2>
    <p class="text-center">Reveal your nft boxes or summoning books to get the reward nft</p>

    <app-nft-reveal-section
      [reveals]="reveals"
      [isLoading]="isLoading"
    ></app-nft-reveal-section>

  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
