<div class="main">

  <app-header-section></app-header-section>

  <section class="">

    <h2 class="text-center">Explore Collections</h2>

    <ul class="nav nav-tabs justify-content-center explore-collections-nav" role="tablist">
      <!-- <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'trending' ?'active':''" (click)="activeTab('trending')" class="nav-link" id="trending-tab" data-toggle="tab" href="#trending" role="tab" aria-controls="trending" aria-selected="true">Trending</a>
      </li> -->
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'top' ?'active':''" (click)="activeTab('top')" class="nav-link" id="top-tab" data-toggle="tab" href="#top" role="tab" aria-controls="top" aria-selected="false">Top</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'art' ?'active':''" (click)="activeTab('art')" class="nav-link" id="art-tab" data-toggle="tab" href="#art" role="tab" aria-controls="art" aria-selected="false">Art</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'collectibles' ?'active':''" (click)="activeTab('collectibles')" class="nav-link" id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false">Collectibles</a>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'domain-names' ?'active':''" (click)="activeTab('domain-names')" class="nav-link" id="domain-names-tab" data-toggle="tab" href="#domain-names" role="tab" aria-controls="domain-names" aria-selected="false">Domain Names</a>
      </li> -->
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'music' ?'active':''" (click)="activeTab('music')" class="nav-link" id="music-tab" data-toggle="tab" href="#music" role="tab" aria-controls="music" aria-selected="false">Music</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'photography' ?'active':''" (click)="activeTab('photography')" class="nav-link" id="photography-tab" data-toggle="tab" href="#photography" role="tab" aria-controls="photography" aria-selected="false">Photography</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'sports' ?'active':''" (click)="activeTab('sports')" class="nav-link" id="sports-tab" data-toggle="tab" href="#sports" role="tab" aria-controls="sports" aria-selected="false">Sports</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'trading-cards' ?'active':''" (click)="activeTab('trading-cards')" class="nav-link" id="trading-cards-tab" data-toggle="tab" href="#trading-cards" role="tab" aria-controls="trading-cards" aria-selected="false">Trading Cards</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'utility' ?'active':''" (click)="activeTab('utility')" class="nav-link" id="utility-tab" data-toggle="tab" href="#utility" role="tab" aria-controls="utility" aria-selected="false">Utility</a>
      </li>
      <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'games-metaverse' ?'active':''" (click)="activeTab('games-metaverse')" class="nav-link" id="games-metaverse-tab" data-toggle="tab" href="#games-metaverse" role="tab" aria-controls="games-metaverse" aria-selected="false">Games/Metaverse</a>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <a [ngClass]="tab == 'virtual-worlds' ?'active':''" (click)="activeTab('virtual-worlds')" class="nav-link" id="virtual-worlds-tab" data-toggle="tab" href="#virtual-worlds" role="tab" aria-controls="virtual-worlds" aria-selected="false">Virtual Worlds</a>
      </li> -->
    </ul>
    <div class="tab-content mt-3">
      <!-- <div *ngIf="tab == 'trending'" [ngClass]="tab == 'trending' ?'show active':''" class="tab-pane fade" id="trending" role="tabpanel" aria-labelledby="trending-tab">
        <app-collections-section [sortBy]="'floorEth:desc,volumnEth:desc'" [enabledLazyLoader]="true" [isShow]="false"></app-collections-section>
      </div> -->
      <div *ngIf="tab == 'top'" [ngClass]="tab == 'top' ?'show active':''" class="tab-pane fade"  id="top" role="tabpanel" aria-labelledby="top-tab">
        <app-collections-section [sortBy]="'volumnEth:desc'" [enabledLazyLoader]="true" [isShow]="false"></app-collections-section>
      </div>
      <div *ngIf="tab == 'art'" [ngClass]="tab == 'art' ?'show active':''" class="tab-pane fade"  id="art" role="tabpanel" aria-labelledby="art-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['art']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'collectibles'" [ngClass]="tab == 'collectibles' ?'show active':''" class="tab-pane fade"  id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['collectibles']"></app-collections-section>
      </div>
      <!-- <div *ngIf="tab == 'domain-names'" [ngClass]="tab == 'domain-names' ?'show active':''" class="tab-pane fade"  id="domain-names" role="tabpanel" aria-labelledby="domain-names-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['domain-names']"></app-collections-section>
      </div> -->
      <div *ngIf="tab == 'music'" [ngClass]="tab == 'music' ?'show active':''" class="tab-pane fade"  id="music" role="tabpanel" aria-labelledby="music-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['music']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'photography'" [ngClass]="tab == 'photography' ?'show active':''" class="tab-pane fade"  id="photography" role="tabpanel" aria-labelledby="photography-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['photography']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'sports'" [ngClass]="tab == 'sports' ?'show active':''" class="tab-pane fade"  id="sports" role="tabpanel" aria-labelledby="sports-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['sports']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'trading-cards'" [ngClass]="tab == 'trading-cards' ?'show active':''" class="tab-pane fade"  id="trading-cards" role="tabpanel" aria-labelledby="trading-cards-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['trading cards']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'utility'" [ngClass]="tab == 'utility' ?'show active':''" class="tab-pane fade"  id="utility" role="tabpanel" aria-labelledby="utility-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['utility']"></app-collections-section>
      </div>
      <div *ngIf="tab == 'games-metaverse'" [ngClass]="tab == 'games-metaverse' ?'show active':''" class="tab-pane fade"  id="games-metaverse" role="tabpanel" aria-labelledby="games-metaverse-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['games-metaverse']"></app-collections-section>
      </div>
      <!-- <div *ngIf="tab == 'virtual-worlds'" [ngClass]="tab == 'virtual-worlds' ?'show active':''" class="tab-pane fade"  id="virtual-worlds" role="tabpanel" aria-labelledby="virtual-tab">
        <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['virtual-worlds']"></app-collections-section>
      </div> -->
    </div>

  </section>

  <app-modal-menu></app-modal-menu>

</div>
