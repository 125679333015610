import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SingletonService } from './singleton.service';
import { Injectable } from '@angular/core';

import { Logger } from './logger.service';
const log = new Logger('BackendService');

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
    private http: HttpClient,
    private singletonService:SingletonService,
  ) {

  }

  async getCollectionDetailsById(id){
    const url = `${environment.apiUrl}/v1/collections/${id}`
    let res = await this.http.get(url).toPromise()
    return res
  }

  async getCollectionDetailsBySlug(slug){
    const url = `${environment.apiUrl}/v1/collections/slug/${slug}`
    let res = await this.http.get(url).toPromise()
    return res
  }

  async getActivity(
    collectionIds=undefined,
    user=undefined,
    eventTypes=["created","cancel","closed"].toString(),
    sortBy="createdAt:desc",
    page=undefined,
    limit=undefined,
    tokenId=undefined,
    nftAddress=undefined,
    chains=undefined,
  ){
    // /v1/orders/events/activities?sortBy=timestamp:desc&eventTypes=created,closed&collections=62544024023c66a122f5df75
    let url = `${environment.apiUrl}/v1/orders/events/activities?`
    if(sortBy) url += `&sortBy=` + sortBy + ',_id:desc'
    if(eventTypes) url += `&eventTypes=` + eventTypes.toString()
    if(collectionIds && collectionIds.length) url += `&collections=` + collectionIds.toString()
    if(user) url += `&user=` + user.toString() // walletAddress

    if(page) url += `&page=` + page
    if(limit) url += `&limit=` + limit

    if(tokenId) url += `&tokenId=` + tokenId
    if(nftAddress) url += `&nftAddress=` + nftAddress

    if(chains) url += `&chains=` + chains

    log.debug("getActivity url: %o",url);

    let res = await this.http.get(url).toPromise()
    log.debug("getActivity res: %o",res);
    return res
  }

  async checkIsUsedCollectionSlug(slug){
      const url = `${environment.apiUrl}/v1/collections/info/slug?slug=${slug}`
      let res = await this.http.get(url).toPromise()
      return res
  }

  async getCollectionListByUserWalletAddress(walletAddress){
    const url = `${environment.apiUrl}/v1/collections/user/${walletAddress}`
    return await this.http.get(url).toPromise()
  }

  async getMetadatasNft(id){
    const url = `${environment.apiUrl}/v1/assets/${id}/meta.json`
    return await this.http.get(url).toPromise()
  }

  async getNftDetailsById(id){
    const url = `${environment.apiUrl}/v1/assets/${id}`
    return await this.http.get(url).toPromise()
  }

  async getCollectionList(categories=undefined){
    try{

      let url = `${environment.apiUrl}/v1/collections?`
      if(categories) url += `&categories=` + categories.toString()

      log.debug("getCollectionList url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("getCollectionList res: %o",res);
      return res

    }catch(error){
      console.error("error on getCollectionList %o",error)
      let resError = {
        title: "Error on getCollectionList",
        error: error,
      }
      throw(resError)
    }
  }

  async createCollection(formData){
    try{

      const url = `${environment.apiUrl}/v1/collections`
      log.debug("createCollection url: %o",url);
      let res = await this.http.post(url,formData).toPromise()
      log.debug("createCollection res: %o",res);
      return res

    }catch(error){
      console.error("error on createCollection %o",error)
      let resError = {
        title: "Error on createCollection",
        error: error,
      }
      throw(resError)
    }
  }

  async updateCollection(formData,id){
    const url = `${environment.apiUrl}/v1/collections/${id}`
    log.debug("updateCollection url: %o",url);
    return this.http.patch(url,formData).toPromise()
  }

  async createNFT(formData){
    const url = `${environment.apiUrl}/v1/assets`
    let res = await this.http.post(url,formData).toPromise()
    return res
  }

  async updateNFT(metadata,id){
    const url = `${environment.apiUrl}/v1/assets/${id}`
    let res = await this.http.patch(url,metadata).toPromise()
    return res
  }

  async freezeMetadata(id){
    const url = `${environment.apiUrl}/v1/assets/${id}/freeze`
    let res = await this.http.patch(url,{}).toPromise()
    return res
  }

  async mintedNFT(id,tokenId){
    const url = `${environment.apiUrl}/v1/assets/${id}/minted`
    let res = await this.http.patch(url,{tokenId}).toPromise()
    return res
  }

  async getAllPaymentTokens(){
    const url = `${environment.apiUrl}/v1/supports/paymentTokens/`
    log.debug("getAllPaymentTokens url: %o",url);
    return this.http.get(url).toPromise()
  }

  async getPaymentTokensByChainAbbr(chainId){
    const url = `${environment.apiUrl}/v1/supports/payment/tokens/${chainId}`
    log.debug("getPaymentTokensByChainAbbr url: %o",url);
    return this.http.get(url).toPromise()
  }

  async getCollectionCategoriesInList(){
    try{

      const url = `${environment.apiUrl}/v1/collections/info/categories`
      log.debug("getCollectionCategoriesInList url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("getCollectionCategoriesInList res: %o",res);
      return res

    }catch(error){
      console.error("error on getCollectionCategoriesInList %o",error)
      let resError = {
        title: "Error on getCollectionCategoriesInList",
        error: error,
      }
      throw(resError)
    }
  }

  async getAssets(
    collectionId=undefined,
    limit=20,
  ){
    try{
      let url = `${environment.apiUrl}/v1/assets?`
      if(collectionId) url += `&collectionId=` + collectionId
      if(limit) url += `&limit=` + limit

      log.debug("getAssets url: %o",url);
      let res = await this.http.get(url).toPromise()
      log.debug("getAssets res: %o",res);
      return res
    }catch(error){
      console.error("error on getAssets %o",error)
      let resError = {
        title: "Error on getAssets",
        error: error,
      }
      throw(resError)
    }
  }

  async getAssetInfo(contract, tokenId, chain, force = false){
    try{
      const url = `${environment.apiUrl}/v1/assets/contract/`+contract+'/'+tokenId+'?network='+chain+'&force='+force;
      log.debug("getAssetInfo url: %o",url);
      let res:any = await this.http.get(url).toPromise()
      log.debug("getAssetInfo res: %o",res);
      return res
    }catch(error){
      console.error("error on getAssetInfo %o",error)
      let resError = {
        title: "Error on getAssetInfo",
        error: error,
      }
      return {success:false}
    }

  }

  async searchKeyword(keyword){
    try{
      const url = `${environment.apiUrl}/v1/supports/search/keyword?keyword=`+keyword
      log.debug("searchKeyword url: %o",url);
      let res:any = await this.http.get(url).toPromise()
      log.debug("searchKeyword res: %o",res);
      return res
    }catch(error){
      console.error("error on searchKeyword %o",error)
      let resError = {
        title: "Error on searchKeyword",
        error: error,
      }
      return {success:false}
    }

  }

  async collectionsInfoTraits(collectionId){
    try{
      const url = `${environment.apiUrl}/v1/collections/info/traits/`+collectionId
      log.debug("searchKeyword url: %o",url);
      let res:any = await this.http.get(url).toPromise()
      log.debug("searchKeyword res: %o",res);
      return res
    }catch(error){
      console.error("error on searchKeyword %o",error)
      let resError = {
        title: "Error on searchKeyword",
        error: error,
      }
      return {success:false}
    }

  }


  async getCollectionsInfoRanking(
    resolution='30d',
    chain=undefined,
    sortBy=undefined,
    limit=undefined,
    page=undefined,
    categories=undefined,
    name=undefined,
    ownerAddress=undefined,
    feature=undefined,
    offering=undefined,
  ){
    try{
      let url = `${environment.apiUrl}/v1/collections/info/ranking?`
      if(resolution) url += `&resolution=` + resolution
      if(chain) url += `&chain=` + chain
      if(sortBy) url += `&sortBy=` + sortBy + ',collectionId:desc'
      if(limit) url += `&limit=` + limit
      if(page) url += `&page=` + page
      if(categories) url += `&categories=` + categories
      if(name) url += `&name=` + name
      if(ownerAddress) url += `&ownerAddress=` + ownerAddress
      if(feature) url += `&feature=` + feature
      if(offering) url += `&offering=` + offering

      log.debug("getCollectionsInfoRanking url: %o",url);
      let res:any = await this.http.get(url).toPromise()
      log.debug("getCollectionsInfoRanking res: %o",res);
      return res
    }catch(error){
      console.error("error on getCollectionsInfoRanking %o",error)
      let resError = {
        title: "Error on getCollectionsInfoRanking",
        error: error,
      }
      return {success:false}
    }

  }

  async getAssetAdvanceSearch(
    priceToken=undefined,
    chains=undefined,
    ownerAddress=undefined,
    priceMin=undefined,
    priceMax=undefined,
    collections=undefined,
    eventTypes=undefined,
    categories=undefined,
    creatorAddress=undefined,
    buyerAddress=undefined,
    sortBy=undefined,
    limit=undefined,
    page=undefined,
    version=2,
    traits=undefined,
    minted=true,
    lunchpad=undefined,
  ){
    try{
      let url = `${environment.apiUrl}/v1/assets/advance/search?`
      if(priceToken) url += `&priceToken=` + priceToken
      if(chains) url += `&chains=` + chains
      if(ownerAddress) url += `&ownerAddress=` + ownerAddress
      if(priceMin) url += `&priceMin=` + priceMin
      if(priceMax) url += `&priceMax=` + priceMax
      if(collections) url += `&collections=` + collections
      if(eventTypes) url += `&eventTypes=` + eventTypes
      if(categories) url += `&categories=` + categories
      if(creatorAddress) url += `&creatorAddress=` + creatorAddress
      if(buyerAddress) url += `&buyerAddress=` + buyerAddress
      if(sortBy) url += `&sortBy=` + sortBy + ',_id:desc'
      if(limit) url += `&limit=` + limit
      if(page) url += `&page=` + page
      if(version) url += `&version=` + version
      if(minted == false) url += `&minted=` + minted
      if(lunchpad) url += `&lunchpad=` + lunchpad

      let data:any = {}
      if(traits && traits.length) data.traits = traits

      log.debug("getAssetAdvanceSearch url: %o",url);
      log.debug("getAssetAdvanceSearch data: %o",data);
      let res:any = await this.http.post(url,data).toPromise()
      log.debug("getAssetAdvanceSearch res: %o",res);
      return res
    }catch(error){
      console.error("error on getAssetAdvanceSearch %o",error)
      let resError = {
        title: "Error on getAssetAdvanceSearch",
        error: error,
      }
      return {success:false}
    }

  }


  /*
  *
  *   =======
  *   MINING POOL PART
  *   =======
  *
  * */

  async getPools(){
    try{
      let url = `${environment.apiUrl}/v1/minings/?limit=30`
      let res:any = await this.http.get(url).toPromise()
      log.debug("fetch get pools from backend result : ",res)
      return res.results
    }catch(error){
      log.error("error on get pools ",error)
      return []
    }
  }

  async getPool(poolId){
    try{
      let url = `${environment.apiUrl}/v1/minings/${poolId}`
      let res = await this.http.get(url).toPromise()
      log.debug("fetch get pool id "+poolId+" from backend result : ",res)
      return res
    }catch(error){
      log.error("error on get pool ",error)
      return []
    }
  }

  async getAssetSoldHistory(address, tokenId){
    try{
      let url = `${environment.apiUrl}/v1/minings/market/sold/${address}/${tokenId}`
      let res = await this.http.get(url).toPromise()
      log.debug("fetch sold history id "+tokenId+" from backend result : ",res)
      return res
    }catch(error){
      log.debug("error on get sold ",error)
      // TODO : Get default price
      const poolsUrl = `${environment.apiUrl}/v1/minings/`
      const res:any = await this.http.get(poolsUrl).toPromise()
      //console.log(res.results)
      //console.log(address)
      const pool = res.results.find((pool)=> {
        if(pool.collectionAddress.toLowerCase() === address.toLowerCase()){
          return pool
        }
      })
      //console.log(pool)
      log.debug("get asset sold not found so get default price = "+pool.defaultPrice)
      return {usd:pool.defaultPrice}
    }

  }



    /*
  *
  *   =======
  *   P2P PART
  *   =======
  *
  * */

  async getOrderBookTokenAll(chainAbbr){
    try{
      let url = `${environment.apiUrl}/v1/orderbooks/token/all?chain=${chainAbbr}`
      log.debug("getOrderBookTokenAll url: %o",url);

      let res = await this.http.get(url).toPromise()
      return res
    }catch(error){
      console.error("error on getOrderBookTokenAll %o",error)
      let resError = {
        title: "Error on getOrderBookTokenAll",
        error: error,
      }
      return {success:false}
    }

  }

  async getOrderBookList(
      ownerAddress=undefined,
      status=undefined,
      sortBy=undefined,
      page=undefined,
      limit=undefined,
      itemAddr=undefined,
      acceptedTokenAddr=undefined,
      chain=undefined,
    ){
    try{
      let url = `${environment.apiUrl}/v1/orderbooks?`
      if(sortBy) url += `&sortBy=` + sortBy + ',_id:desc'
      if(limit) url += `&limit=` + limit
      if(page) url += `&page=` + page
      if(ownerAddress) url += `&ownerAddress=` + ownerAddress
      if(status) url += `&status=` + status
      if(itemAddr) url += `&itemAddr=` + itemAddr
      if(acceptedTokenAddr) url += `&acceptedTokenAddr=` + acceptedTokenAddr
      if(chain) url += `&chain=` + chain

      log.debug("getOrderBookList url: %o",url);
      let res = await this.http.get(url).toPromise()
      return res
    }catch(error){
      console.error("error on getOrderBookList %o",error)
      let resError = {
        title: "Error on getOrderBookList",
        error: error,
      }
      return {success:false}
    }

  }

  async getOrderBook(id){
    try {
      let url = `${environment.apiUrl}/v1/orderbooks/${id}`
      let res = await this.http.get(url).toPromise()
      return res
    } catch (error) {
      log.error("error on getOrderBook ", error)
      let resError = {
        title: "Error on getOrderBookList",
        error: error,
      }
      return {success: false}
    }
  }



    /*
  *
  *   =======
  *   MINING REDEEM PART
  *   =======
  *
  * */

  async saveRedeemInfo(
    lockedId,
    firstName,
    lastName,
    nationalID,
    phoneNumber,
    email,
    method,
    itemID,
    note,
  ){
    let data:any = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "phoneNumber": phoneNumber,
      "nationalID": nationalID,
      "method": method,
      "itemID": itemID,
      "note": note,
      "partner": "yesdigital",
    }

    if(environment.features.DEMO_MODE) data.env = "testnet" // only demo

    log.debug("saveRedeemInfo data: ", data)

    const url = `${environment.apiUrl}/v1/minings/rewards/redeem/` + lockedId
    let res = await this.http.post(url,data).toPromise()
    return res
  }

  async getRedeemRewardsInfo(
    poolAddress,
    assetAddress,
    tokenId,
  ){
    const url = `${environment.apiUrl}/v1/minings/rewards/info/` + poolAddress + '/' + assetAddress + '/'  + tokenId
    let accessToken = await this.singletonService.getAccessToken()
    let res = await this.http.get(url).toPromise()
    return res
  }

  async getReveals(){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.unboxers;
  }

  async getReveal(id){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.unboxers.find(ub=> ub.id == id)
  }

  async getMarkets(){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.whitelistMarkets;
  }

  async getMarket(id){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.whitelistMarkets.find(ub=> ub.id == id)
  }

  async getLaunchpadMarkets(){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.launchpads;
  }

  async getLaunchpadMarket(slug){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(ab => ab.chainId == environment.chainId)
    return addressBook.launchpads.find(ub=> ub.slug == slug)
  }

  async getCraftingStations(){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(item => item.chainId == environment.chainId)
    return addressBook.craftingStations;
  }

  async getCraftingStationById(id){
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(item => item.chainId == environment.chainId)
    return addressBook.craftingStations.find(item=> item.id == id)
  }

  async getConverters(){
    let addressBook = environment.addressBook.find(item => item.chainId == environment.chainId)
    return addressBook.converters
  }

  async getGashas() {
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(item => item.chainId == environment.chainId)
    return addressBook.gashas;
  }

  async getGashaById(id) {
    // TODO: fetch from server
    let addressBook = environment.addressBook.find(item => item.chainId == environment.chainId)
    return addressBook.gashas.find(item => item.id == id)
  }

}
