export const locale = {
  lang: 'th',
  data: {
    common: {
      test: 'ทดสอบ'
    },
    pagination:{
      previous: 'ก่อนหน้า',
      next: 'ถัดไป',
      items_per_page: 'แสดงจำนวน',
    }
  }
}
