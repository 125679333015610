import { CurrencyService } from './../../services/currency.service';
import { LazyLoaderService } from './../../services/lazy-loader.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Logger } from './../../services/logger.service';
const log = new Logger('NftsComponent'); // log.debug

@Component({
  selector: 'app-nfts',
  templateUrl: './nfts.component.html',
  styleUrls: ['./nfts.component.scss']
})
export class NftsComponent implements OnInit, OnDestroy {


  isFetchingAsset
  isLoading


  limit = 25
  assets = []
  totalResults = 0
  page = 1
  loadedAll = false

  chainETHList:any = []

  defaultSelectedFilters = {
    eventTypesParams: ['created']
  }

  constructor(
    private route: ActivatedRoute,
    private singletonService:SingletonService,
    private backendService:BackendService,
    private router: Router,
    private selectedFiltersService:SelectedFiltersService,
    private lazyLoaderService:LazyLoaderService,
    private currencyService:CurrencyService,
  ) {
    this.selectedFiltersService.initSelectedFilters()
  }


  ngOnDestroy(): void {
    document.body.classList.remove('static-header-mode')
  }

  async ngOnInit() {
    document.body.classList.add('static-header-mode')



    await this.loadChainETHList()

    this.selectedFiltersService.selectedFiltersChangedSubject.subscribe((event)=>{
      this.reloadData()
    })

    await this.reloadData()

    this.lazyLoaderService.lazyLoadSubject.subscribe(async ()=>{
      if(!this.loadedAll) await this.fetchAsset()
    })


  }

  resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.totalResults = 0
    this.assets = []
  }

  async reloadData(){
    await this.resetDatas()
    this.isFetchingAsset = await true
    await this.fetchAsset()
    this.isFetchingAsset = await false
  }

  async loadChainETHList(){
    this.chainETHList = await this.singletonService.getChainETHList()
    log.debug("loadChainETHList this.chainETHList => %o",this.chainETHList)
  }

  async fetchAsset(){

    let eventTypesParams = this.selectedFiltersService.getEventTypesParams()
    let paymentTokenParams = this.selectedFiltersService.getPaymentTokenParams()
    let priceMinParams = this.selectedFiltersService.getPriceMinParams()
    let priceMaxParams = this.selectedFiltersService.getPriceMaxParams()
    let collectionsParams = this.selectedFiltersService.getCollectionsParams()
    let categoriesParams = this.selectedFiltersService.getCategoriesParams()
    let chainsParams = this.selectedFiltersService.getChainsParams()
    let sortParams = this.selectedFiltersService.getSortParams()

    let traitsParams = await this.selectedFiltersService.getAttrsParams()
    log.debug("traitsParams => %o",traitsParams)

    let result:any = await this.backendService.getAssetAdvanceSearch(
      paymentTokenParams,
      chainsParams,
      undefined, // ownerAddress
      priceMinParams,
      priceMaxParams,
      collectionsParams,
      eventTypesParams,
      categoriesParams,
      undefined, // creatorAddress
      undefined, // buyerAddress
      sortParams ? sortParams : 'updatedAt:desc', // updatedAt:desc | createdAt:desc | price:desc | price:asc
      this.limit, // limit
      this.page, // page
      2, // version
      traitsParams, // traitsParams
      true, // minted
    )

    let _assets = await Promise.all(result.results.map(async (asset) => {
      if(asset.lastestOrder && asset.lastestOrder.acceptedTokenAddr){
        asset.lastestOrder.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(asset.lastestOrder.acceptedTokenAddr,asset.lastestOrder.chain)
        asset.lastestOrder.acceptedTokenSymbol = asset.lastestOrder.acceptedTokenInfo.symbol
      }
      return asset
    }));



    log.debug("_assets => %o",_assets)


    this.assets = [...this.assets,..._assets]
    if(_assets.length < this.limit) this.loadedAll = true
    this.totalResults = result.totalResults
    this.page++

    // this.assets = result.results

  }

}
