import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {MiningService} from "../../services/mining.service";
import {ActivatedRoute, Router} from "@angular/router";
import { ethers,utils } from "ethers";
const { BigNumber }= require('ethers');

import { Logger } from '../../services/logger.service';
import {environment} from "../../../environments/environment";
import {CurrencyService} from "../../services/currency.service";
const log = new Logger('MiningPoolComponent');

@Component({
  selector: 'app-mining-pool',
  templateUrl: './mining-pool.component.html',
  styleUrls: ['./mining-pool.component.scss']
})
export class MiningPoolComponent implements OnInit {

  account ;
  poolInfo:any;
  collectionInfo:any;
  poolId:any;
  tokenIds:any;

  isLoading = true
  isFaucetLoading = false
  isWithdrawLoading = false
  isSupportChain = true;
  isNFTReady = false
  isStakeAllLoading = false;
  isUnstakeAllLoading = false;
  isApproveAll = false;
  isApproveAllLoading = false;
  rewards:any
  balances:any
  nfts:any;
  userInfo:any;
  pendingRewards:any;
  pendings:any;
  pendingRewardIntervalId:any;
  aprCalculatorIntervalId:any;
  profile:any;
  walletCurrentChainId:any;
  walletCurrentChain:any;
  supportChainId:any;
  poolFound = true
  apy = 1000
  apr = 100
  totalHashPower = 0
  userHashPower = 0
  userHashPowerPercent = 0
  demoMode = false
  rewardTokens = []
  rewardTokensPerBlockValue = 0
  totalCost = 0
  pools = []
  pool:any;
  decimal=6;

  constructor(
    private singletonService:SingletonService,
    private miningService:MiningService,
    public router:Router,
    private route: ActivatedRoute,
    public currencyService:CurrencyService
  ) {
    this.poolId = this.route.snapshot.paramMap.get('poolId');
    this.demoMode = environment.features.DEMO_MODE
  }

  async ngOnInit(){
    this.pools = await this.miningService.getPools()
    this.pool = this.pools.find(it=> it.id == this.poolId)
    if(this.pool.decimal){
      this.decimal = this.pool.decimal
    }
    this.walletCurrentChain = await this.singletonService.getCurrentChain()
    this.walletCurrentChainId = this.walletCurrentChain.networkId//await this.singletonService.getCurrentChainId()

    this.isSupportChain = this.walletCurrentChain.networkAbbr === this.pool.chain;//(environment.chainId == this.walletCurrentChainId)
    this.supportChainId = environment.chainId
    this.account = await this.singletonService.getCurrentConnectedAccount()
    if(this.isSupportChain){
      this.poolInfo = await this.miningService.getPoolInfo(this.poolId)
      log.debug("poolInfo ",this.poolInfo)
      if(this.poolInfo){
        this.poolFound = true
        this.rewards = this.poolInfo.rewards
        await this.fetchOnce()
        await this.refresh()
        //await this.calculateAPR()
        this.isLoading = false

        if(this.account){
          this.pendingRewardIntervalId = setInterval(()=>{
            this.refreshPendingReward()
          }, 15000)
          /*this.aprCalculatorIntervalId = setInterval(()=>{
            this.calculateAPR()
          }, 30000)*/
        }

      }else{
        this.poolFound = false
      }
    }

  }

  async playVideos(){
    let videos = document.getElementsByClassName("nft-video")
    console.log(videos)
    /*for (const video in videos) {
      video.play()
    }*/

    for (let i = 0; i < videos.length; i++) {
      // @ts-ignore
      await videos[i].play()
    }
  }

  ngOnDestroy() {
    if (this.pendingRewardIntervalId) {
      clearInterval(this.pendingRewardIntervalId);
    }
    /*if (this.aprCalculatorIntervalId) {
      clearInterval(this.aprCalculatorIntervalId);
    }*/
  }

  async connectToSupportChain(){
    await this.singletonService.switchToSupportChain()
    window.location.reload()
  }

  async fetchOnce(){
    if(this.account){
      this.userInfo = await this.miningService.getPoolUserInfo(this.poolInfo.address)
    }
    this.rewardTokens = await this.miningService.getPoolRewardTokens(this.poolInfo.address, this.poolInfo.rewards)
    log.debug("reward tokens fetched ",this.rewardTokens)
  }

  async refresh(){

    await this.refreshBalance()
    if(this.account){
      await this.fetchTokenIds()
      await this.refreshHashPower()
      await this.refreshNFTs()
      await this.refreshPendingReward()
      //await this.refreshProfile()
      //await this.calculateAPR()
      //await this.playVideos()
    }else {
      this.isNFTReady = true
    }

    /*setTimeout(async ()=> {
      await this.playVideos()
    },3000);

     */
  }

  async fetchTokenIds(){
    let ids = await this.miningService.getOwnNFTIds(this.poolInfo.collectionAddress)
    this.tokenIds = ids ? ids:[]
    this.tokenIds = this.tokenIds.concat(this.userInfo.stakedTokenIds)
    log.debug("fetch token ids result ",this.tokenIds)
  }
  async refreshBalance(){
    this.balances = []
    for(let r of this.rewards){
      let _balance = { reward:r, amount:0 }
      if(this.account){
        if(r.decimal){
          _balance.amount = Number(utils.formatUnits((await this.miningService.getBalance(r.address)),r.decimal))
        }else{
          _balance.amount = Number(utils.formatEther((await this.miningService.getBalance(r.address))))
        }

      }
      log.debug("balance "+r.name+" ",_balance.amount)
      this.balances.push(_balance)
    }
  }
  async refreshNFTs(){
    this.isApproveAll = true
    this.isNFTReady = false;
    this.nfts = []
    // TODO : Change this to promise
    Promise.all(this.tokenIds.map(async (tokenId): Promise<any> => {
      let info = await this.miningService.getNFTMeta(this.poolInfo.collectionAddress, tokenId)
      //console.log("after get nft meta")
      let hashPower = await this.miningService.getHashPower(this.poolInfo.collectionAddress, tokenId)
      let _rarity = info.attributes.find(o=>o.trait_type == 'Rarity' || o.trait_type == 'rarity')
      // custom for spiritual
      if(this.poolInfo.collectionAddress.toLowerCase() == '0xc146cb6d1b844e998f2eb803626d13377f3bfcf0' || this.poolInfo.collectionAddress.toLowerCase() == '0x22f4f83375522a0dec0a760470c1889375319e02'){
        _rarity = info.attributes.find(o=>o.trait_type == 'Class' || o.trait_type == 'class')
      }
      let isStaked = this.userInfo.stakedTokenIds.includes(tokenId)
      let rewards = isStaked ? (await this.mapRewardPerBlock(hashPower)):[]
      let _nft = {
        tokenIdNumber: (tokenId.toNumber()),
        tokenId: (tokenId),
        hashPower: hashPower,
        isApproved:await this.miningService.checkApprove(this.poolInfo.address, this.poolInfo.collectionAddress),
        isStaked: isStaked,
        //image: await this.miningService.nftImage(this.poolInfo.collection, tokenId),
        image: info.image,
        animation_url: info.animation_url,
        profileExisted: await this.miningService.isExistedProfile(this.poolInfo.collectionAddress, tokenId),
        attributes: info.attributes,
        rarity: _rarity ? _rarity.value:undefined,
        name:info.name,
        description:info.description,
        isStakeLoading:false,
        isSetProfileLoading:false,
        isUnstakeLoading:false,
        rewards: rewards
      }
      if(!_nft.isApproved){
        this.isApproveAll = false
      }
      this.nfts.push(_nft)
    })).then(() => {
      //reach here regardless
      // {status: "fulfilled", value: 33}
      this.nfts.sort(function(a, b){
        return ((+b.isStaked) - (+a.isStaked) || (a.tokenIdNumber-b.tokenIdNumber))
      })
      log.debug("nfts ",this.nfts)
      this.isNFTReady = true
      this.calculateAPR()
    });


  }

  async mapRewardPerBlock(hashPower){
    let _rewards = this.rewardTokens.map(rt => ({...rt})); //clone

    _rewards = _rewards.map(r=>{
      r.hashPower = hashPower.toNumber()
      r.tokenPerBlock = r.rewardPerBlock * (hashPower.toNumber() / this.totalHashPower)
      r.hashPowerRatio = (hashPower.toNumber() / this.totalHashPower)
      return r
    })
    return _rewards
  }

  async refreshPendingReward(){
    this.pendingRewards = await this.miningService.pendingReward(this.poolInfo.address)
    log.debug("update pending rewards")
    this.pendings = []
    //console.log(this.pendingRewards)
    for(let r of this.rewards){
      let _pending
      if(this.pendingRewards[0]==0){
        _pending = { reward:r, amount:0 }
      }else {
        let idx = this.pendingRewards[0].indexOf(r.address)
        if(r.decimal){
          _pending = { reward:r, amount:utils.formatUnits(this.pendingRewards[1][idx], r.decimal) }

        }else{
          _pending = { reward:r, amount:utils.formatEther(this.pendingRewards[1][idx]) }
        }
        //_pending = { reward:r, amount:utils.formatEther(this.pendingRewards[1][idx]) }
      }
      log.debug("=== %o",_pending)

      this.pendings.push(_pending)
    }

    //await this.playVideos()
  }

  async refreshHashPower(){
    this.totalHashPower = (await this.miningService.getPoolTotalHashPower(this.poolInfo.address)).toNumber()
    this.userHashPower = this.userInfo.stakedHashPowerAmount
    this.userHashPowerPercent = (this.userHashPower / this.totalHashPower) * 100
  }

  async refreshProfile(){
    this.profile = await this.miningService.currentProfile()
  }

  async calculateAPR(){
    if(this.account){
      this.rewardTokensPerBlockValue = 0
      let defaultPrice =this.poolInfo.defaultPrice
      this.totalCost = this.userInfo.stakedTokenIds.length * defaultPrice//await this.miningService.getTotalCost(this.poolInfo.collectionAddress, this.userInfo.stakedTokenIds)
      log.debug("total cost ",this.totalCost)
      log.debug(this.rewardTokens)
      log.debug(this.nfts)
      for (const nft of this.nfts) {
        if(nft.isStaked){
          //console.table(nft.rewards)
          for (const rewardToken of nft.rewards) {
            //console.table(rewardToken)
            if(rewardToken.enabled){
              let price = await this.currencyService.getUSDFromSymbol(rewardToken.symbol.toLowerCase())
              //console.log("price "+price)
              this.rewardTokensPerBlockValue += rewardToken.tokenPerBlock * price
            }
          }
        }
      }
    } else{
      this.totalCost = 0
    }
    log.debug("rewardTokensPerBlockValue "+this.rewardTokensPerBlockValue)
    let blockPerDay = environment.blockPerDay
    let rewardValuePerYear = this.rewardTokensPerBlockValue * blockPerDay * 365
    this.apr = (rewardValuePerYear *100 ) / this.totalCost
    log.debug("APR "+this.apr)
    log.debug("APR pow "+Math.pow((1+(this.apr/365)), 365))
    this.apy = (Math.pow((1+(this.apr/(365*100))), 365) - 1 ) * 100
  }

  async faucet(){
    this.isFaucetLoading = true
    this.miningService.faucet(this.poolInfo.collectionAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("faucet events ",receipt.events)
      let transferEvent = receipt.events.find((e)=> e.event === 'Transfer')
      this.tokenIds.push(transferEvent.args.tokenId)
      await this.fetchTokenIds()
      await this.refresh()
      this.singletonService.fire('success','faucet success', 'Success faucet id '+transferEvent.args.tokenId)
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isFaucetLoading = false
    })
  }

  async stake(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isStakeLoading = true
    this.miningService.stake(this.poolInfo.address, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("stake events ",receipt.events)
      let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','stake success', 'Success stake nft')
      nft.isStakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','stake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isStakeLoading = false
    })
  }

  async unstake(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isUnstakeLoading = true
    this.miningService.unstake(this.poolInfo.address, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("unstake events ",receipt.events)
      let unstakeEvent = receipt.events.find((e)=> e.event === 'UnstakeToken')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','unstake success', 'Success stake nft')
      nft.isUnstakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','unstake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isUnstakeLoading = false
    })
  }

  async withdraw(){
    this.isWithdrawLoading = true
    this.miningService.withdraw(this.poolInfo.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("withdraw events ",receipt.events)
      let withdrawnEvent = receipt.events.find((e)=> e.event === 'RewardWithdrawn')
      await this.refresh()
      this.singletonService.fire('success','RewardWithdrawn success', 'Success RewardWithdrawn')
      this.isWithdrawLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','RewardWithdrawn failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isWithdrawLoading = false
    })
  }

  async approve(){
    this.isApproveAllLoading =true
    this.miningService.approve(this.poolInfo.address, this.poolInfo.collectionAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.refreshNFTs()
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApproveAllLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveAllLoading =false
    })
  }

  async setProfile(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isSetProfileLoading = true
    this.miningService.markAsProfile(this.poolInfo.collectionAddress, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("set profile events ",receipt.events)
      let event = receipt.events.find((e)=> e.event === 'ProfileChanged')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','Profile set success', 'Success Profile set')
      nft.isSetProfileLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Profile set failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isSetProfileLoading = false
    })
  }

  async sendAll(){
    this.isStakeAllLoading = true
    let _tokenIds = await this.miningService.getOwnNFTIds(this.poolInfo.collectionAddress)
    log.debug("_tokenIds to send ", _tokenIds)
    this.miningService.stakeBatch(this.poolInfo.address, _tokenIds).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("stake events ",receipt.events)
      let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','stake all success', 'Success stake nfts')
      this.isStakeAllLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','stake all failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isStakeAllLoading = false
    })
  }

  async stopAll(){
    this.isUnstakeAllLoading = true
    let _tokenIds = this.userInfo.stakedTokenIds
    log.debug("_tokenIds to stop ", _tokenIds)
    this.miningService.unstakeBatch(this.poolInfo.address, _tokenIds).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("unstake events ",receipt.events)
      let unstakeEvent = receipt.events.find((e)=> e.event === 'UnstakeToken')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','Unstake all success', 'Success stake nfts')
      this.isUnstakeAllLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Unstake all failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isUnstakeAllLoading = false
    })
  }


  playVDO(event) {
    log.debug('playVDO event : %o' , event);

    // const video = this.videoplayer.nativeElement

    var anyButton = event.target
    var buttonWrapper = anyButton.parentElement
    log.debug('playVDO buttonWrapper : %o' , buttonWrapper);

    var playButton = buttonWrapper.getElementsByClassName("img-play-vdo")[0];
    log.debug('playVDO playButton : %o' , playButton);

    var pauseButton = buttonWrapper.getElementsByClassName("img-pause-vdo")[0];
    log.debug('playVDO pauseButton : %o' , pauseButton);

    // var pauseButton = parentElement.getElementsByClassName("img-pause-vdo")[0];

    var parentElement = buttonWrapper.parentElement.parentElement
    log.debug('playVDO parentElement : %o' , parentElement);

    const video = parentElement.getElementsByClassName("video-player")[0];
    log.debug('playVDO video : %o' , video);


    // var playButton = document.getElementsByClassName("btn-action-vdo");
    // Event listener for the play/pause button
    // playButton.addEventListener("click", function() {
    if (video.paused == true) {
      // Play the video

      video.play();
      // this.videoplayer.nativeElement.play();

      // Update the button text to 'Pause'
      // playButton.innerHTML = "Pause";

      playButton.setAttribute("style", "display: none;");
      pauseButton.setAttribute("style", "display: block;");

      // playButton.hide()
      // pauseButton.show()
    } else {
      // Pause the video

      video.pause();
      // this.videoplayer.nativeElement.pause();

      // Update the button text to 'Play'
      // playButton.innerHTML = "Play";

      playButton.setAttribute("style", "display: block;");
      pauseButton.setAttribute("style", "display: none;");

      // playButton.show()
      // pauseButton.hide()
    }
    // });
  }

}
