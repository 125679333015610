<div class="main">
  <app-header-section></app-header-section>
  <div class="container">

  <section class="">
    <!--<a class="btn btn-outline-light" style="padding-top: 40px" (click)="setHashPower()">Set hash</a> -->
    <div class="row">
      <div class="col-12">
        <h3>Mining Pools</h3>
        <p>Send your NFTs to mine token and you can set them to be your profile image.</p>
        <hr/>
      </div>
    </div>

    <app-poc-mining-section
      [pools]="pools"
      [soonPools]="soonPools"
    ></app-poc-mining-section>

  </section>
  </div>
  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
