import { MiningService } from './../../services/mining.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Logger } from '../../services/logger.service';
import { BackendService } from 'src/app/services/backend.service';
const log = new Logger('ProjectDetailComponent');

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailComponent implements OnInit {

  isLoading = false

  project = {
    id: 1,
    name: "Desperado",
    description: "In DESPERADO B218, you will form a team of five heroes and face the rift walkers. Emerge victorious against the rift walkers. Claim victories, climb the ranks and you shall be rewarded handsomely. Are you ready to determine the fate of DESPERADO B218?",
    tags: ["P2E","RPG","Strategy"],
    verification: "official",
    featureImage: "https://cdn.guildfi.com/guildfi/image/upload/c_limit,w_640,f_auto/2_03b1cffbc5",
    logoImage: "https://cdn.guildfi.com/guildfi/image/upload/c_limit,w_640,q_100,f_auto/profile02_6f89ed45ee",
    bannerImage: "https://cdn.guildfi.com/guildfi/image/upload/c_limit,w_1280,f_auto/1500x500_0d25857967",
    // chains: [
    //   "bkc",
    //   "bsc"
    // ],
    // featuredTokens: []

    intro: `
      <div class="space-y-4 text-sm"><div class="MarkdownContent_markdownContent__Dx2u2"><h4 class="mt-2"><strong>5 vs. 5 Strategic Team Battle</strong></h4>
      <ul>
      <li>In DESPERADO B218, you will form a team of five heroes and face the rift walkers. Emerge victorious against the rift walkers. Claim victories, climb the ranks and you shall be rewarded handsomely.</li>
      <li>Strategically manage your mana. In DESPERADO B218, mana plays a crucial part in battle. How you use each hero’s skill every turn with the mana at hand could change the tide of battle. More heroes will join you through scouting and spirit links.</li>
      </ul>
      <h4 class="mt-5"><strong>Heroes</strong></h4>
      <ul>
      <li>Diverse and charming characters reside within the world of 'DESPERADO B218: The Scars of Exos'.</li>
      <li>Each hero has their own unique looks, Grades, Stats, and Skills. Epic and Legend Tier heroes have a chance of obtaining a ‘Unique Skill’. A hero’s Star Grade and Stats can also be upgraded.</li>
      <li>If a hero has the Charisma stat, it can be used to recruit additional heroes through the ‘Scout’ system.</li>
      <li>And if desired, specific parts of each hero's hair, body, and weapon can be customized to create a unique look.</li>
      </ul>
      <h4 class="mt-5"><strong>Battle Rules</strong></h4>
      <ul>
      <li>Each user battles by forming a team of 5 heroes.</li>
      <li>The battle consists of rounds and turns.</li>
      <li>Each user will take turns in order, and whoever manages to defeat all opposing heroes, win.</li>
      <li>Each hero has Health Slots, and a hero will fall once all of their Health Slots have been depleted.</li>
      <li>A hero’s Health Slot will deplete in relation to the damage they receive.</li>
      </ul>
      <h4 class="mt-5"><strong>DAO</strong></h4>
      <ul>
      <li>DAO is the decentralized organization for 'DESPERADO B218: The Scars of Exos', where they can contribute to determining the game’s roadmap.</li>
      <li>Once DAO gets officially established, users will be able to directly impact the management and the development of the game or vote with tokens to directly influence the game’s future.</li>
      </ul></div></div>
    `
  }

  tab = 'introduction'

  reveals = []
  whitelistMarkets = []
  pools = []
  craftingStations = []

  constructor(
    private backendService:BackendService,
    private miningService:MiningService,
    private router:Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(params => {
      if(params['tab']){
        this.tab = params['tab'];
      }
    });
  }

  async ngOnInit(){
    this.isLoading = true
    await this.fetchReveals()
    await this.fetchWhitelistMarkets()
    await this.fetchMiningPools()
    await this.fetchCraftingStations()
    this.isLoading = false

  }

  async fetchReveals(){
    // this.isLoading = true
    this.reveals = await this.backendService.getReveals()
    // this.isLoading = false
  }

  async fetchWhitelistMarkets(){
    // this.isLoading = true
    this.whitelistMarkets = await this.backendService.getMarkets()
    // this.isLoading = false
  }

  async fetchMiningPools(){
    // this.isLoading = true
    this.pools = await this.miningService.getPools()
    // this.isLoading = false
  }

  async fetchCraftingStations(){
    // this.isLoading = true
    this.craftingStations = await this.backendService.getCraftingStations()
    // this.isLoading = false
  }

  activeTab(tab){
    this.router.navigate(['/project-detail/' + this.project.id],{queryParams: { 'tab': tab }})
    this.tab = tab
    log.debug("this.tab => %o",this.tab)
  }

}
