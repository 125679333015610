import { MiningService } from './../../services/mining.service';
import { Component, OnInit, Input } from '@angular/core';

import { Logger } from '../../services/logger.service';
const log = new Logger('NftCardListComponent'); // log.debug

@Component({
  selector: 'app-use-profile-image',
  templateUrl: './use-profile-image.component.html',
  styleUrls: ['./use-profile-image.component.scss']
})
export class UseProfileImageComponent implements OnInit {

  @Input() ownerUser:any

  nftProfile
  account

  constructor(
    private miningService:MiningService,
  ) { }

  async ngOnInit() {
    if(this.ownerUser){
      this.account = this.ownerUser.publicAddress
      if(this.account){
        await this.fetchNFTProfile()
      }

      this.miningService.getObserveProfileSubject().subscribe(async (changed)=>{
        if(changed){
          this.nftProfile = await this.miningService.getProfile()
        }
      })
    }
  }

  async fetchNFTProfile(){
    // nft profile
    this.nftProfile = await this.miningService.getProfile(this.account)
    log.debug("nftProfile ",this.nftProfile)
  }

}
