import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('SelectedFiltersService'); // log.debug

@Injectable({
  providedIn: 'root'
})
export class SelectedFiltersService {

  readonly emptyPaymentToken = {
    paymentToken: {},
    min: undefined,
    max: undefined,
  }

  readonly emptySelectedFilters = {
    eventTypes: [], // ['created' , 'closed' , 'cancel']
    price: this.emptyPaymentToken,
    collections: [],
    chains: [],
    categories: [],
    attrs: [],
    sortParams: [],
  }

  public selectedFilters:any


  removeSelectedCollectionSubject:any = new Subject<any>();
  removeSelectedChainSubject:any = new Subject<any>();
  removeSelectedAttrSubject:any = new Subject<any>();

  clearAllSubject:any = new Subject<any>();

  selectedFiltersChangedSubject:any = new Subject<any>(); // trigger api

  constructor() {
    this.initSelectedFilters()
  }

  initSelectedFilters(){
    this.selectedFilters = this.cloneObject(this.emptySelectedFilters) // clone object
  }

  cloneObject(_object){
    return (JSON.parse(JSON.stringify(_object)))
  }

  isEmptySelectedFilters(){

    let a = this.cloneObject(this.emptySelectedFilters)
    let b = this.cloneObject(this.selectedFilters)

    delete a.sortParams;
    delete b.sortParams;

    // log.debug("JSON.stringify(this.emptySelectedFilters) => %o",JSON.stringify(this.emptySelectedFilters).length)
    // log.debug("JSON.stringify(this.selectedFilters) => %o",JSON.stringify(this.selectedFilters).length)

    // log.debug("JSON.stringify(this.emptySelectedFilters) => %o",JSON.stringify(this.emptySelectedFilters))
    // log.debug("JSON.stringify(this.selectedFilters) => %o",JSON.stringify(this.selectedFilters))

    let isEmptySelectedFilters = !(JSON.stringify(a).length == JSON.stringify(b).length)
    // log.debug("isEmptySelectedFilters => %o",isEmptySelectedFilters)
    return isEmptySelectedFilters
  }

  async getAttrsFormat(){
    let attrs = []
    log.debug("getAttrsFormat this.selectedFilters.attrs => %o",this.selectedFilters.attrs)
    for (const attr of this.selectedFilters.attrs) {
      let data = {
        "type": attr.type,
        "value": attr.value,
      }
      log.debug("getAttrsFormat data => %o",data)
      await attrs.push(data)
    }

    log.debug("getAttrsFormat attrs => %o",attrs)
    return attrs
  }

  getEventTypesParams(){
    return this.selectedFilters.eventTypes.length ? this.selectedFilters.eventTypes.toString() : undefined
  }
  getPaymentTokenParams(){
    return this.selectedFilters.price.paymentToken ? this.selectedFilters.price.paymentToken.address : undefined
  }
  getPriceMinParams(){
    return this.selectedFilters.price.min
  }
  getPriceMaxParams(){
    return this.selectedFilters.price.max
  }
  getCollectionsParams(){
    return this.selectedFilters.collections.length ? this.selectedFilters.collections.map(it => it.id).toString() : undefined
  }
  getCategoriesParams(){
    return this.selectedFilters.categories.length ? this.selectedFilters.categories.toString() : undefined
  }
  async getAttrsParams(){
    return this.selectedFilters.attrs.length ? (await this.getAttrsFormat()) : undefined
  }
  getChainsParams(){
    return this.selectedFilters.chains.length ? this.selectedFilters.chains.map(it => it.networkAbbr).toString() : undefined
  }
  getSortParams(){
    return this.selectedFilters.sortParams.length ? this.selectedFilters.sortParams.toString() : undefined
  }

}
