import { Pipe, PipeTransform } from '@angular/core';
import { SingletonService } from '../services/singleton.service';

import { Logger } from './../services/logger.service';
const log = new Logger('RenderImageUrlPipe'); // log.debug

@Pipe({
  name: 'endWithFormat'
})
export class EndWithFormatPipe implements PipeTransform {
  constructor(
    private singletonService:SingletonService,
  ){}
  transform(value: any, ...args: any[]): any {
    log.debug("EndWithFormatPipe value => %o",value)

    // const imageFormats = this.singletonService.SUPPORT_FILE_EXTENTIONS
    let splitedText = value.split('.')
    let fileExtention = splitedText && splitedText.length ? splitedText[splitedText.length-1]: ''
    let isEndWithFormat = this.singletonService.SUPPORT_FILE_EXTENTIONS.find(it => it.toLowerCase() == fileExtention.toLowerCase())

    log.debug("EndWithFormatPipe isEndWithFormat => %o",isEndWithFormat)
    return isEndWithFormat ? true: false;
  }

}
