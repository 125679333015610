import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';

import { mockdata } from '../../mocks/my_collections';
import { ActivatedRoute } from '@angular/router';

import { Logger } from './../../services/logger.service';
const log = new Logger('CollectionsComponent'); // log.debug

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {


  myCollections:any = []

  account
  supportChains:any = []

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.account = await this.singletonService.account
    this.supportChains = await this.singletonService.supportChains
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account
      if(this.account){
        await this.reloadData()
      }
    });

    if(this.account){
      await this.reloadData()
    }
  }

  async reloadData(){
    this.backendService.getCollectionListByUserWalletAddress(this.singletonService.account).then((res:any) => {
      log.debug(" getCollectionListByUserWalletAddress res => %o",res)
      if(res){
        let myCollections = res
        this.myCollections = myCollections.map( it => {
          it.collectionName = it.name
          it.nftAmount = 'x'
          it.collectionImageUrl = it.logoImage
          it.collectionCoverImageUrl = it.featureImage
          return it
        })
      }
    }).catch((resError) => {
      console.error("resError %o",resError)
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }

  getImageSupportChainByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }
}
