import { EndWithFormatPipe } from './end-with-format.pipe';
import { environment } from './../../environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment'

import { Logger } from './../services/logger.service';
const log = new Logger('RenderImageUrlPipe'); // log.debug

@Pipe({
  name: 'renderImageUrl'
})
export class RenderImageUrlPipe implements PipeTransform {

  constructor(
    private endWithFormatPipe:EndWithFormatPipe,
  ){}

  transform(value: any, ...args: unknown[]): unknown {
    let type:any = args[0]
    let isNeededEmptyStringWhenUndefined:any = args[1]
    let isOriginalSize:any = args[2]

    const isGIFfile = value ? (value.toLowerCase()).indexOf(".gif") != -1 : false  // gif bug if render with resized

    // log.debug("renderImageUrl type => %o",type)
    // log.debug("renderImageUrl value => %o",value)

    const version = moment().unix()
    // let resizeParams = `?width=350&height=350&version=${version}`
    let resizeParams = `?width=350&height=350`

    switch (type) {
      case "feature":
        // resizeParams = `?width=600&height=400&version=${version}`
        resizeParams = `?width=600&height=400`
        break;
      case "banner":
        // resizeParams = `?width=1400&height=400&version=${version}`
        resizeParams = `?width=1400&height=400`
        break;
      case "logo":
        // resizeParams = `?width=350&height=350&version=${version}`
        resizeParams = `?width=350&height=350`
        break;
      case "nft":
        // resizeParams = `?width=1024`
        resizeParams = `?width=500`
        break;
      default:
        isOriginalSize = true
        break;
    }

    let _url

    if(value){
      log.debug("===================================================================")

      log.debug("value => %o",value)

      const isEndWithFormatPipe = this.endWithFormatPipe.transform(value)
      log.debug("isEndWithFormatPipe => %o",isEndWithFormatPipe)

      const isStartWithHttps = value.startsWith('https://')
      log.debug("isStartWithHttps => %o",isStartWithHttps)

      if(!isEndWithFormatPipe) isOriginalSize = true
      log.debug("isOriginalSize => %o",isOriginalSize)
      log.debug("isGIFfile => %o",isGIFfile)

      // if(value.startsWith('assets/')) return value
      if(isStartWithHttps && (type != 'nft'))
        return value
      if(isOriginalSize || isGIFfile){
        _url = this.imageUrlWithPrefix(value)
        log.debug("_url 1111 => %o",_url)

        return _url
      }

      log.debug("_url 2222 resizeParams => %o",resizeParams)
      _url = this.imageUrlWithPrefixAndResize(value,resizeParams)
      log.debug("_url 2222 => %o",_url)

      return  _url
    }else{
      if(isNeededEmptyStringWhenUndefined){
        return ''
      }else{

        _url = this.getRandomDefaultImageUrlByType(type);
        log.debug("_url 3333 => %o",_url)

        return _url
      }
    }
  }

  imageUrlWithPrefix(value){
    const version = moment().unix()

    // let url = `${environment.renderImageUrlPrefix}` + value + `?version=${version}`
    let url = `${environment.renderImageUrlPrefix}` + value

    // log.debug("imageUrlWithPrefix url => %o",url)
    return url
  }

  imageUrlWithPrefixAndResize(value,resizeParams){

    let url = `${environment.renderImageWithResizeUrlPrefix}` + value + resizeParams // resizeUrl
    // let url = `${environment.renderImageWithResizeUrlPrefix}` + value

    // let url = `${environment.renderImageUrlPrefix}` + value + resizeParams // resizeUrl


    // log.debug("imageUrlWithPrefixAndResize url => %o",url)
    return url
  }

  getRandomDefaultImageUrlByType(type = "logo",luckyNumber = undefined){
    luckyNumber = isNaN(luckyNumber) ? this.getRandomInteger() : Number(luckyNumber)
    // log.debug("getRandomDefaultImageUrlByType luckyNumber => %o",luckyNumber)

    const defaultImageUrlByTypes = {
      "feature": `../../assets/img/author_${luckyNumber}.jpg`, // 600 x 400 recommended.
      "banner": `../../assets/img/author_${luckyNumber}.jpg`, // 1400 x 400 recommended.
      "logo": '../../assets/img/avatars/default.png', // 350 x 350 recommended.
    }

    let url = defaultImageUrlByTypes[type]
    return url
  }

  getRandomInteger(){
    const MIN = 1
    const MAX = 8
    return Math.round(((Math.random()*(MAX - MIN))) + MIN);
  }

}

