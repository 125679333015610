<div class="main">
  <app-header-section></app-header-section>

  <div *ngIf="currentUserInfos">
    <div class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="currentUserInfos.coverImageUrl | renderImageUrl: 'banner'">
        </div>
      </div>
    </div>
    <div class="information-collection">
      <div class="image-collection-profile" *ngIf="nftProfile">
        <div class="img-nft-profile active-nft-profile active-nft-profile image-profile-preview {{(nftProfile != undefined && nftProfile.image) ? 'aura-effect':'image-profile-general'}} ">
          <img class="avatar-sm rounded-circle" [src]="nftProfile.image | renderImageUrl: 'logo'" alt="">
        </div>
      </div>
      <div class="option-menu-account">
        <div class="btn-group mr-3" role="group">
          <!-- <a class="btn btn-outline-light border">
            <i class="icon-share"></i>
          </a> -->
          <a *ngIf="account && (currentUserInfos.publicAddress.toLowerCase() === account.toLowerCase())" [routerLink]="['/settings']" class="btn btn-outline-light border">
            <i class="icon-settings"></i>
          </a>
        </div>
      </div>
      <div class="info-content text-center">
        <h2 class="tx-28 mt-0 mb-2">
          {{currentUserInfos.name | hideWalletAddress}}
        </h2>
        <button class="btn btn-outline-light border py-2 rounded-pill">

          <!--<svg fill="gray" viewBox="-38.39985 -104.22675 332.7987 625.3605" style="width: 12px;"><path d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"></path><path d="M127.962 287.959V0L0 212.32z"></path><path d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"></path><path d="M0 236.585l127.962 180.32v-104.72z"></path><path d="M127.961 154.159v133.799l127.96-75.637z"></path><path d="M127.96 154.159L0 212.32l127.96 75.637z"></path></svg>-->
          <span class="ml-2">{{currentUserInfos.publicAddress | hideWalletAddress }}</span>
        </button>
        <div class="desc-content mt-3 mx-auto text-muted">
          Joined {{currentUserInfos.createdAt | date: 'dd/MM/yyyy'}}
        </div>
      </div>

      <div class="content-wrapper-tab mt-3 mb-5">
        <ul class="nav nav-tabs justify-content-center" role="tablist">
          <li class="nav-item" (click)="activeTab('collected')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'collected' ? 'active' : ''" id="collected-tab" data-toggle="tab" href="#collected" role="tab" aria-controls="collected" aria-selected="true">Collected
              <span class="ml-1 tx-12 align-middle">{{totalResults['collected']}}</span>
            </a>
          </li>
          <li class="nav-item" (click)="activeTab('created')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'created' ? 'active' : ''" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false">Created
              <span class="ml-1 tx-12 align-middle">{{totalResults['created']}}</span>
            </a>
          </li>
          <!-- <li class="nav-item" (click)="activeTab('favorited')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'favorited' ? 'active' : ''" id="favorited-tab" data-toggle="tab" href="#favorited" role="tab" aria-controls="favorited" aria-selected="false">Favorited
              <span>0</span>
            </a>
          </li>
          <li class="nav-item" (click)="activeTab('hidden')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'hidden' ? 'active' : ''" id="hidden-tab" data-toggle="tab" href="#hidden" role="tab" aria-controls="hidden" aria-selected="false">Hidden
              <span>0</span>
            </a>
          </li> -->
          <li class="nav-item" (click)="activeTab('activity')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'activity' ? 'active' : ''" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">Activity</a>
          </li>
          <!-- <li class="nav-item" (click)="activeTab('offer')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'offer' ? 'active' : ''" id="offer-tab" data-toggle="tab" href="#offer" role="tab" aria-controls="offer" aria-selected="false">Offer</a>
          </li> -->
          <li class="nav-item" (click)="activeTab('listings')" role="presentation">
            <a class="nav-link" [ngClass]="tab == 'listings' ? 'active' : ''" id="listings-tab" data-toggle="tab" href="#listings" role="tab" aria-controls="listings" aria-selected="false">Listings</a>
          </li>
        </ul>
        <!-- <div class="tab-content pt-4 px-4 pb-5"> -->
        <div class="tab-content">

          <div class="nft-search-view">
            <div class="wrapper-search-main">
              <app-filter-search *ngIf="tab == 'collected' || tab == 'created'" [account]="publicAddress"></app-filter-search>
              <app-filter-search *ngIf="tab == 'activity'" [account]="publicAddress" [enabledAttributes]="false" [enabledCategories]="false" [enabledPrice]="false"></app-filter-search>
              <app-filter-search *ngIf="tab == 'listings'" [account]="publicAddress" [enabledAttributes]="false" [enabledEventType]="false" [enabledCategories]="false" [enabledPrice]="false"></app-filter-search>
            </div>
            <div class="wrapper-search-result content-wrapper-tab pt-4 px-4 pb-5 mb-5">

              <div class="tab-pane fade" *ngIf="tab == 'collected'" [ngClass]="tab == 'collected' ? 'show active' : ''" id="collected" role="tabpanel" aria-labelledby="collected-tab">
                <app-nft-card-list [assets]="assets['collected']" [isFetchingAsset]="isFetchingAsset" [totalResults]="totalResults['collected']"></app-nft-card-list>
              </div>

              <div class="tab-pane fade" *ngIf="tab == 'created'" [ngClass]="tab == 'created' ? 'show active' : ''" id="created" role="tabpanel" aria-labelledby="created-tab">
                <app-nft-card-list [assets]="assets['created']" [isFetchingAsset]="isFetchingAsset" [totalResults]="totalResults['created']"></app-nft-card-list>
              </div>
              <!-- <div class="tab-pane fade" *ngIf="tab == 'favorited'" [ngClass]="tab == 'favorited' ? 'show active' : ''" id="favorited" role="tabpanel" aria-labelledby="favorited-tab">
                ...favorited...
              </div>
              <div class="tab-pane fade" *ngIf="tab == 'hidden'" [ngClass]="tab == 'hidden' ? 'show active' : ''" id="hidden" role="tabpanel" aria-labelledby="hidden-tab">
                ...hidden...
              </div> -->
              <div class="tab-pane fade" *ngIf="tab == 'activity'" [ngClass]="tab == 'activity' ? 'show active' : ''" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                <!-- ...activity... -->

                <app-activity-table *ngIf="publicAddress" [user]="publicAddress"></app-activity-table>
              </div>
              <!-- <div class="tab-pane fade" *ngIf="tab == 'offer'" [ngClass]="tab == 'offer' ? 'show active' : ''" id="offer" role="tabpanel" aria-labelledby="offer-tab">
                ...offer...
              </div> -->
              <div class="tab-pane fade" *ngIf="tab == 'listings'" [ngClass]="tab == 'listings' ? 'show active' : ''" id="listings" role="tabpanel" aria-labelledby="listings-tab">
                <!-- ...listings... -->
                <app-activity-table *ngIf="publicAddress" [user]="publicAddress" [eventTypes]="['created']"></app-activity-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <app-modal-menu></app-modal-menu>

</div>
<!-- <app-footer-section></app-footer-section> -->
