import { SingletonService } from './../../services/singleton.service';
import { BackendService } from './../../services/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

import { mockdata } from '../../mocks/index_top_collections';
import {CurrencyService} from "../../services/currency.service";

import { Logger } from '../../services/logger.service';
const log = new Logger('CollectionsSectionComponent');

@Component({
  selector: 'app-collections-section',
  templateUrl: './collections-section.component.html',
  styleUrls: ['./collections-section.component.scss']
})
export class CollectionsSectionComponent implements OnInit {

  @Input() isShow = true
  @Input() showedColorTitle = ''
  @Input() showedTitle = ''
  @Input() isHideShowedPeriodFiltered = true
  @Input() isHideShowedExporeMore = true

  @Input() categories
  @Input() enabledLazyLoader = false

  @Input() sortBy = 'volumnEth:desc'

  @Input() isOnlyFeatured = false

  @Input() customLimit = undefined
  @Input() customCollectionLink = undefined
  @Input() isOffering = undefined

  resolution = {
    value: '7d',
    displayText: '7 day',
  }

  topCollections:any = []
  isLoading = false;

  chainETHList:any = []
  supportChains:any = []

  // == start lazy loader params ==
  limit = 6
  page = 1
  isLazyLoading = false
  loadedAll = false
  scrollEvent
  scroll_position
  isScrollBottom = false
  // == end lazy loader params ==

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private route: ActivatedRoute,
    private currencyService:CurrencyService,
    private router:Router,
  ) {

  }

  async ngOnInit(){
    if(this.enabledLazyLoader) this.handleScroll(); // enable lazyloader
    this.supportChains = await this.singletonService.supportChains

    await this.reloadData()
  }

  async resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.topCollections = []
  }

  async reloadData(){
    this.isLoading = true;

    await this.resetDatas()
    await this.fetchCollections()

    this.isLoading = false;
  }

  getImageSupportChainByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }

  async fetchCollections(){
    let res = await this.backendService.getCollectionsInfoRanking(
      this.resolution.value, // resolution
      undefined, // chains
      this.sortBy ? this.sortBy : undefined, // sortBy
      this.isOnlyFeatured ? 12 : (this.customLimit ? this.customLimit : this.limit),
      this.page,
      this.categories,// categories
      undefined,
      undefined,
      this.isOnlyFeatured,
      this.isOffering,
    )
    log.debug("fetchCollections res => %o",res)
    if(!res.results) return

    let newResults = await Promise.all(res.results.map(async (it): Promise<any> => {
      log.debug("newResults it => %o",it)

      it.volumnThb = await this.currencyService.changeUsdToThb(it.volumnUsd ? it.volumnUsd : 0)
      it.floorThb = await this.currencyService.changeUsdToThb(it.floorUsd ? it.floorUsd : 0)

      log.debug("after newResults it => %o",it)

      // if(it.collection.statistic && it.collection.statistic.floors){
      //   it.collection.statistic.floors = await this.currencyService.addValuesToStatisticArray(it.collection.statistic.floors)
      //   if(it.collection.statistic.floors.length) it.collection.floorPrice = it.collection.statistic.floors.reduce((prev, curr) => (prev.usd < curr.usd ? prev : curr));;
      // }
      // if(it.collection.statistic && it.collection.statistic.volumns){
      //   it.collection.statistic.volumns = await this.currencyService.addValuesToStatisticArray(it.collection.statistic.volumns)
      //   if(it.collection.statistic.volumns.length){
      //     it.collection.tradeVolumn = {
      //       usd:it.collection.statistic.volumns.reduce(function (acc, obj) { return acc + obj.usd; }, 0),
      //       thb:it.collection.statistic.volumns.reduce(function (acc, obj) { return acc + obj.thb; }, 0),
      //     }
      //   }
      // }
      return it;
    }));

    this.topCollections = [...this.topCollections,...newResults]
    if(newResults.length < this.limit) this.loadedAll = true

    log.debug("this.topCollections => %o",this.topCollections)
  }


  async selectResolution(value,displayText){
    this.resolution = {
      value: value,
      displayText: displayText,
    }
    await this.reloadData()
  }


  handleScroll(): void {
    window.onscroll = () => this.detectBottom();
  }

  async detectBottom(){
    // log.debug("xxxxxxxxxxxxx detectBottom 11111111111111111111111")
    let scroll_direction = await (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
    this.scroll_position = await (document.body.getBoundingClientRect()).top;
    if (scroll_direction == 'down'){ // downscroll
      // log.debug("xxxxxxxxxxxxx detectBottom 2222222222222222222222222")
      // let x = window.innerHeight
      // let y = window.scrollY
      // let z = document.body.offsetHeight
      // log.debug("xxxxxxxxxxxxx detectBottom x => %o",x )
      // log.debug("xxxxxxxxxxxxx detectBottom y => %o",y )
      // log.debug("xxxxxxxxxxxxx detectBottom z => %o",z )

      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // log.debug("xxxxxxxxxxxxx detectBottom 3333333333333333333333333333")
        this.isScrollBottom = true
        log.debug("xxxxxxxxxxxxx detectBottom")

          if(!this.isLazyLoading){ // check for lazy loader is working
            await this.lazyLoader()
          }
      }
    }else{ // upscroll
      // donothing
    }
  }

  async lazyLoader(){
    this.isLazyLoading = await true
    setTimeout(async () => {
      await this.page++
      if(!this.loadedAll) await this.fetchCollections()
      this.isLazyLoading = await false
    }, 1000);
  }

  openCollectionLink(collectionSlug){
    // log.debug("jumpToNFTDetails activity => %o",activity)
    if(this.customCollectionLink){
      this.router.navigate([this.customCollectionLink + collectionSlug],{
        // queryParams: {
        //   chain: activity.asset.chain
        // }
      })
    }else{
      this.router.navigate(['/collection/' + collectionSlug],{
        // queryParams: {
        //   chain: activity.asset.chain
        // }
      })
    }
  }

}
