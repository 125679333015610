import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FreeCityContractService } from './../../services/free-city-contract.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RenderImageUrlPipe } from './../../pipes/render-image-url.pipe';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';

import { Logger } from './../../services/logger.service';
const log = new Logger('NftCreateComponent'); // log.debug

@Component({
  selector: 'app-nft-create',
  templateUrl: './nft-create.component.html',
  styleUrls: ['./nft-create.component.scss']
})
export class NftCreateComponent implements OnInit {

  id
  isFreeze = false
  isApproved = false
  isApproveLoading = false
  isMinting = false

  metaIpfs
  nftAddress
  ownerAddress

  nftFile:any  // wrapped file by FormData() -------- **required in Opensea**
  format:any
  url:any

  name:any
  externalLink:any
  description:any
  collectionId:any = "1" // inlist [MY_CREATED_COLLECTIONS] << get list from server api
  properties:any = []
  isSensitiveContent:any = false
  amountSupplyCreate:any = 1 // fixed 1 for now! (mint batch for furture)
  blockchain:any = "bkc" // inlist [SUPPORTED_CHAIN] << get list from frontend config
  // "freezeMetaData": "" // to be continue...

  MY_CREATED_COLLECTIONS:any = []
  selected_collection:any
  tempProperties:any = []
  supportChains:any = this.singletonService.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)
  currentChain:any

  account

  loadings:any = {
    submit: false,
    // validateSlug: false,
    // validateName: false,
  }

  tokenId:any // received after minted
  private subscriptions: Subscription[] = [];
  constructor(
    public singletonService:SingletonService,
    private backendService:BackendService,
    private renderImageUrlPipe:RenderImageUrlPipe,
    private route: ActivatedRoute,
    private router:Router,
    private freeCityContractService:FreeCityContractService,
    private modalService:NgbModal,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    log.debug("this.id => %o",this.id)
  }

  async ngOnInit() {
    this.account = await this.singletonService.account
    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("this.currentChain %o",this.currentChain)

    let sb1 = this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account
      if(this.account){
        await this.reloadData()
      }
    });
    this.subscriptions.push(sb1)
    if(this.account){
      await this.reloadData()
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  async reloadData(){
    // this.blockchain = await this.supportChains.find( it => it.networkAbbr == 'bkctestnet')

    const currentChain = await this.singletonService.getCurrentChain()
    if(currentChain){
      this.blockchain = currentChain
    }else{
      const defaultChainAbbr = await this.singletonService.getChainAbbr()
      this.blockchain = await this.supportChains.find( it => it.networkAbbr == defaultChainAbbr)
    }

    this.MY_CREATED_COLLECTIONS = await this.backendService.getCollectionListByUserWalletAddress(this.account)
    log.debug("this.MY_CREATED_COLLECTIONS %o",this.MY_CREATED_COLLECTIONS)

    if(this.id){

      await this.initFormValue()
      await this.checkApproved()
    }
  }

  async initFormValue(){
    await this.backendService.getNftDetailsById(this.id).then(async (res:any) => {
      log.debug("getNftDetailsById res => %o",res)
      this.isFreeze = res.isFreeze

      // metadata fields

      this.addPreviewFromUrl(res.media,"input-nft-image")

      this.name = res.name
      this.description = res.description == undefined ? ' ':res.description
      this.externalLink = res.externalLink

      this.properties = res.attributes ? res.attributes : []

      // updatable fields
      this.selected_collection = this.MY_CREATED_COLLECTIONS.find(it => it.id == res.collectionId)
      this.isSensitiveContent = res.isSensitiveContent
      this.blockchain = await this.supportChains.find( it => it.networkAbbr == res.chain)

      // utility fields
      this.metaIpfs = res.metaIpfs
      this.nftAddress = res.nftAddress
      this.ownerAddress = res.ownerAddress

      log.debug("getNftDetailsById this.metaIpfs => %o",this.metaIpfs)


      log.debug("getNftDetailsById this.ownerAddress => %o",this.ownerAddress)
      log.debug("getNftDetailsById this.account => %o",this.account)

      if(this.ownerAddress.toLowerCase() != this.account.toLowerCase()){
        // alert("Not owner!")
        this.singletonService.fire("error","You're not the owner of NFT.")
        this.router.navigate(['/'])
      }


    }).catch((resError) => {
      console.error("resError => %o",resError)
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }

  selectCollection(_collection){
    this.selected_collection = _collection
  }

  async handleFileInput_nftFile(files: FileList){
    if(files.length){
      let file = files.item(0);
      if (file) {
        let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
        if(isNotSupportFileExtension){
          await this.clearFile_nft()
          return
        }
        let isOverSizeFile = await this.singletonService.isOverSizeFile(file,'nft')
        if(isOverSizeFile){
          await this.clearFile_nft()
          return
        }
        this.nftFile = file;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        if(file.type.indexOf('image')> -1){
          this.format = 'image';
          this.addPreviewFromFile(this.nftFile,"input-nft-image")

        } else if(file.type.indexOf('video')> -1){
          this.format = 'video';
        }
        reader.onload = (event) => {
          this.url = (<FileReader>event.target).result;
        }
      }
    }
  }

  async capture(isDownload = false) {
    var canvas:any = document.getElementById("c");
    var video:any = document.getElementById("v");

    if (video) {
      canvas.getContext('2d').drawImage(video, 0, 0);

      var image = new Image();
      image.id = "pic";
      image.src = canvas.toDataURL();
      document.getElementById('image_for_crop').textContent = canvas.toDataURL();

      if(isDownload){
        const blob = await (await fetch(canvas.toDataURL())).blob();
        const file = new File([blob], 'capture.jpg', {type:"image/jpeg"});
        log.debug("file => %o",file)

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'capture.jpg';
        document.body.appendChild(a);
        a.click();
      }
    }
  }

  addEmptyProperty(){
    let emptyProperty = {
      "trait_type": "",
      "value": "",
    }
    this.tempProperties.push(emptyProperty)
  }

  removeProperty(_property){
    let emptyProperty = {
      "trait_type": "",
      "value": "",
    }
    this.tempProperties = this.tempProperties.filter(it => it.trait_type != _property.trait_type)
    if(!this.tempProperties.length){
      this.tempProperties.push(emptyProperty)
    }
  }

  createTempProperties(){
    let emptyProperty = {
      "trait_type": "",
      "value": "",
    }
    this.tempProperties = []
    if(!this.properties.length){
      this.tempProperties = [emptyProperty]
    }
    this.properties.forEach(val => this.tempProperties.push(Object.assign({}, val)));
    log.debug("this.tempProperties => %o",this.tempProperties)
    log.debug("this.properties => %o",this.properties)
  }

  saveProperties(){
    this.properties = []
    this.tempProperties = this.tempProperties.filter(it => it.trait_type && it.trait_type != '')
    this.tempProperties.forEach(val => this.properties.push(Object.assign({}, val)));
  }

  toggleIsSensitiveChecked(){
    this.isSensitiveContent = !this.isSensitiveContent
  }

  selectBlockchain(chain){
    this.blockchain = chain
  }

  clearFile_nft(){
    this.nftFile = undefined
    this.format = undefined;
    this.url = undefined
    this.clearPreview("input-nft-image")
    this.clearInputValue("input-nft-image-instance")
  }

  clearPreview(elementId){
    let previewElement:any = document.getElementById(elementId)
    if(previewElement){
      previewElement.style.backgroundImage = null;
    }
  }

  clearInputValue(elementId){
    let element:any = document.getElementById(elementId)
    if(element){
      element.value = "";
    }
  }

  addPreviewFromFile(file,elementId){
    const reader = new FileReader();
    reader.onload = e => {
      // log.debug("reader.result %o",reader.result)

      let previewElement:any = document.getElementById(elementId)
      previewElement.style.backgroundImage = "url("+reader.result+")";
    };
    reader.readAsDataURL(file);
  }

  addPreviewFromUrl(url,elementId){
    let previewElement:any = document.getElementById(elementId)
    let _url = this.renderImageUrlPipe.transform(url,'logo',true)
    previewElement.style.backgroundImage = "url("+_url+")";
  }


  jumpToNFTDetails(){
    this.router.navigate(['/nft/' + this.selected_collection.nftAddress + '/' + this.tokenId],{
      queryParams: {
        chain: this.blockchain.networkAbbr
      }
    })
  }

  async canSubmit(){
    let canSubmit = false

    let condition01 = this.id ? true : this.nftFile
    let condition02 = this.name
    let condition03 = this.id ? true : this.selected_collection
    let condition04 = this.description


    log.debug("=========== debug (all must be true)")
    log.debug("condition01: ",condition01)
    log.debug("condition02: ",condition02)
    log.debug("condition03: ",condition03)
    log.debug("condition04: ",condition04)

    canSubmit = (condition01 && condition02 && condition03 && condition04)

    log.debug("=========== Result canSubmit: %o",canSubmit)
    log.debug("canSubmit: %o",canSubmit)

    return canSubmit
  }

  async getMetadataAttributesFormat(){
    const attrsMap = {};
    this.properties.forEach((propertie) => {
      attrsMap[propertie.key] = propertie.value;
    });
    log.debug("attrsMap => %o",attrsMap)
    return attrsMap
  }

  async submit(){
    // this.singletonService.fire("info","creating...")
    if(await this.canSubmit()){

      if(this.id){ // EDIT ACTION
        // not jump just save
        let metadata = {
          "name": this.name,
          "description": this.description,
          "externalLink": this.externalLink,
          "attributes": this.properties,
          "isSensitiveContent": this.isSensitiveContent,
          'collectionId': this.selected_collection.id,
        }
        log.debug("updateNFT metadata => %o",metadata)

        this.backendService.updateNFT(metadata,this.id).then((res:any) => {
          log.debug("updateNFT res => %o",res)
          if(res){
            this.loadings.submit = false
            this.singletonService.fire("success","Success to update nft")
            // this.jumpToNFTDetails()
          }else{
            this.loadings.submit = false
            // this.singletonService.fire("error",res.message)
          }
        }).catch((resError) => {
          this.singletonService.fire("error",resError.title,resError.error)
          this.loadings.submit = false
        })
      }else{

        const formData = new FormData();
        if(this.nftFile) formData.append('file', this.nftFile);
        if(this.name) formData.append('name', this.name);
        if(this.externalLink) formData.append('externalLink', this.externalLink);
        if(this.description) formData.append('description', this.description);
        if(this.selected_collection) formData.append('collectionId', this.selected_collection.id);
        if(this.properties.length) formData.append('attributes', JSON.stringify(this.properties));
        formData.append('isSensitiveContent', this.isSensitiveContent);
        formData.append('amountSupplyCreate', this.amountSupplyCreate);
        formData.append('chain', this.blockchain.networkAbbr);
        new Response(formData).text().then(log.debug)

        this.backendService.createNFT(formData).then((res:any) => {
          log.debug("createNFT res => %o",res)
          if(res){
            this.loadings.submit = false
            this.singletonService.fire("success","Success to save nft draft.")
            this.jumpToNFTDraft(res.id)
          }else{
            this.loadings.submit = false
            // this.singletonService.fire("error",res.message)
          }
        }).catch((resError) => {
          // this.singletonService.fire("error",resError.title,resError.error)
          this.singletonService.fire('error',' on create NFT', 'ERROR: '+ (resError.data ? resError.data.message : resError.message))


          this.loadings.submit = false
        })
      }
    }else{
      this.singletonService.fire("error","Please fill out the required fields completely.")
    }
  }

  jumpToNFTDraft(id){
    this.router.navigate(['/nft/draft/'+id])
  }

  async freezeMetadata(){
    if(!this.isFreeze){
      return this.backendService.freezeMetadata(this.id).then(async (res:any) => {
        log.debug("freezeMetadata res => %o",res)
        if(res){
          this.loadings.submit = false
          this.singletonService.fire("success","Success to freeze metadata")
          await this.reloadData()
          // this.jumpToNFTDetails()
        }else{
          this.loadings.submit = false
          // this.singletonService.fire("error",res.message)
        }
      }).catch((resError) => {
        this.singletonService.fire("error",resError.title,resError.error)
        this.loadings.submit = false
      })
    }else{
      return Promise.resolve(true)
    }
  }

  async doMintNFT(){
    if(!(await this.singletonService.modalSwitchNetwork(this.blockchain.networkAbbr))){
      log.debug("freezeMetadata process...")
      this.freezeMetadata().finally(()=>{
        log.debug("doMintNFT process...")

        this.isMinting = true

        // call smart contract to mint nft
        this.freeCityContractService.mint(
          this.account,
          this.metaIpfs,
          this.account,
          this.selected_collection.royaltyAddress ? this.selected_collection.royaltyAddress : this.account,
          this.selected_collection.royaltyFee
        ).then(async (transaction)=>{
          log.debug("transaction => %o",transaction)
          let receipt = await transaction.wait()
          log.debug("mintNFT receipt => %o",receipt)

          let mintedEvent = await receipt.events.find(it => it.event == 'Minted')
          log.debug("mintNFT mintedEvent => %o",mintedEvent)
          if(mintedEvent){
            let tokenId = Number(mintedEvent.args.tokenId)
            let nft = mintedEvent.args.nft
            let receiver = mintedEvent.args.receiver
            log.debug("mintedEvent tokenId => %o",tokenId)
            log.debug("mintedEvent nft => %o",nft)
            log.debug("mintedEvent receiver => %o",receiver)
            this.tokenId = tokenId
            await this.mintedNFT(this.id, tokenId)
            await this.singletonService.fire('success','Minted', 'Success to mint NFT.')

          }

        }).catch((error)=>{
          console.error(error)
          this.singletonService.fire('error','Mint failed', 'Fail to mint NFT.')
          this.isMinting = false
        }).finally(()=>{
          this.isMinting = false
        })
      })
    }

  }

  async checkApproved(){
    this.isApproved = await this.freeCityContractService.checkApprove(this.nftAddress)
  }


  async mintedNFT(id=undefined, tokenId=undefined){
    this.backendService.mintedNFT(id, tokenId).then(async (res:any) => {
      log.debug("mintedNFT res => %o",res)
      if(res){
        this.loadings.submit = false
        this.singletonService.fire("success","Success to mint NFT")
        await document.getElementById("mintNFTProcess-close-btn").click(); // close modal
        await this.jumpToNFTDetails()
      }else{
        this.loadings.submit = false
        // this.singletonService.fire("error",res.message)
      }
    }).catch((resError) => {
      this.singletonService.fire("error",resError.title,resError.error)
      this.loadings.submit = false
    })
  }
}
