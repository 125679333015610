
<div class="main">

  <app-header-section></app-header-section>

  <div *ngIf="isLoading" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-center text-muted">Fetching data</p>
  </div>

  <div *ngIf="!isLoading && !hasLocationPermission">
    <div class="container " style="margin-top: 8vh;margin-bottom: 5vh">
      <section class="author-area">
      <div class="row ">
        <div class="col-12 text-center">
          <img src="assets/img/icons/sunglasses.png" width="128"/>
          <h4>No permission of your current location</h4>
          <p>Please allow us to access your location. (browser settings)</p>
          <a class="btn btn-primary text-white" [routerLink]="['/']">
            Go Back
          </a>
        </div>
      </div>
      </section>
    </div>
  </div>

  <div *ngIf="!isLoading && hasLocationPermission && !campaign">
    <div class="container " style="margin-top: 8vh;margin-bottom: 5vh">
      <section class="author-area">
      <div class="row ">
        <div class="col-12 text-center">
          <img src="assets/img/icons/sunglasses.png" width="128"/>
          <h4>Campaign Not Found</h4>
          <p>There are currently no campaigns available near you.</p>
          <a class="btn btn-primary text-white" [routerLink]="['/']">
            Go Back
          </a>
        </div>
      </div>
      </section>
    </div>
  </div>


  <div *ngIf="!isLoading && campaign">
    <div >
      <div class="image-cover-wrapper position-relative">
        <div class="image-cover-inner">
          <div class="image-cover">
            <img [src]="campaign.bannerImage | renderImageUrl: 'banner'">
            <!-- <img [src]="campaign.bannerImage | renderImageUrl: 'banner'" [alt]="campaign.name"> -->
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrapper container">

      <div class="row campaign-intro-wrapper">
        <div class="col-md-12">
          <div class="">
            <div class="campaign-info-wrapper">
              <h2 class="tx-36 m-0 font-weight-bolder text-center">
                {{ campaign.name }}
                <!-- | (test: 01) -->
              </h2>
              <p class="tx-16 mb-0 mt-4 text-muted text-center">
                <span *ngIf="!campaign.chainInfo">
                  Network: {{ campaign.chain }}
                </span>
                <span *ngIf="campaign.chainInfo">
                  Network: {{ campaign.chainInfo.networkName }}
                  (ID: {{campaign.chainInfo.networkId}})
                </span>
                <button *ngIf="!isSameCampaignChain && campaign.chainInfo" type="button" style="margin-left: 10px" class="btn btn-outline-primary"
                    (click)="singletonService.switchChain(campaign.chainInfo);">
                    Switch to the Campaign Chain
                </button>
              </p>
              <p class="tx-16 mb-0 mt-4 text-muted text-center">
                {{ campaign.description }}
              </p>
              <hr class="my-5">
              <h3 class="tx-30 m-0 font-weight-bolder">
                Event
              </h3>

              <div id="event-detail" [innerHTML]="campaign.decodedEventDetail">
              </div>
              <!-- <p [innerHTML]="yyInnerHTML">
              </p> -->
              <!-- <p [innerHTML]="xxInnerHTML">
              </p> -->

              <!-- <hr class="my-5">
              <h3 class="tx-30 m-0 font-weight-bolder">
                Condition
              </h3>
              <p>
                {{ campaign.condition }}
              </p> -->

              <!-- <hr>
              <p class="text-warning">
                DEBUG <br>
                account: {{ account }} <br>
                isUserReceivedNFT: {{isUserReceivedNFT}}<br>
                isUserInCampaignArea: {{isUserInCampaignArea}}<br>
              </p> -->
            </div>
          </div>
          <hr class="my-5">
        </div>
      </div>

      <div *ngIf="!isLoading && !account">
        <div class="container " style="margin-top: 8vh;margin-bottom: 5vh">
          <section class="author-area">
          <div class="row ">
            <div class="col-12 text-center">
              <img src="assets/img/icons/sunglasses.png" width="128"/>
              <h4>Please connect wallet</h4>
              <a class="btn btn-primary text-white" (click)="connectWallet()">
                Connect Wallet
              </a>
            </div>
          </div>
          </section>
        </div>
      </div>

      <div *ngIf="!isLoading && account">

        <div *ngIf="isUserReceivedNFT === true">
          <p class="text-danger text-center">
            You already have the campaign NFT.
          </p>

          <div *ngIf="isRecieveNFTLoading" class="col-12 py-2 text-center">
              <div  class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <p class="text-center text-muted mt-2">NFT Loading...</p>
          </div>

          <div *ngIf="!isRecieveNFTLoading && receivedNFT" class="custom-nft-img">

            <div class="image-over-wrapper border">
              <img *ngIf="receivedNFT.imageUrl" class="card-img-top" [src]="receivedNFT.imageUrl" alt="">
            </div>

            <p class="text-center text-white-main my-4 tx-24 font-weight-bolder">
              {{ receivedNFT.name }}
            </p>
            <p class="text-center text-muted mt-0 mb-0">
              Token ID: {{receivedNFT.tokenId}}
            </p>
            <p class="text-center text-muted mt-0">
              Collection Address: {{receivedNFT.collectionAddress}}
            </p>
            <p class="text-center text-muted mt-2">
              {{ receivedNFT.description }}
            </p>

          </div>


        </div>

        <div *ngIf="isUserReceivedNFT === false">
          <div *ngIf="isUserInCampaignArea" class="text-center">
            <p *ngIf="isEnabledCheckingUserAndCampaignLocation" class="text-success text-center" >
              You are in the campaign area.
            </p>
            <br>
            <p class="text-center">(Please complete the captcha to recieve campaign's NFT)</p>
            <p class="wrapper-re-captcha-custom text-center">
              <re-captcha
              [siteKey]="recaptchaSiteKey"
              (resolved)="addTokenLog('Normal mode resolved', $event)"
            ></re-captcha>
            </p>
            <div class="text-center mt-4">
              <button [disabled]="isMintingNFTLoading" *ngIf="isUserNotRobot && !isMintingNFTLoading" (click)="recieveNFT()" type="button" class="btn btn-primary text-white">
                Recieve NFT
              </button>

              <div *ngIf="isMintingNFTLoading" class="col-12 py-2 text-center">
                <div  class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="text-center text-muted mt-2">NFT Minting Process Loading...</p>
            </div>

            </div>
          </div>
          <div *ngIf="isEnabledCheckingUserAndCampaignLocation && !isUserInCampaignArea">
            <p class="text-danger text-center" >
              You are NOT in the campaign area.
            </p>
          </div>
        </div>

      </div>

    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
