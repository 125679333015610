<div class="main">
  <app-header-section></app-header-section>

  <div *ngIf="isLoadingMainAsset===true" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-center text-muted">Fetching asset</p>
  </div>

  <div *ngIf="assetFound">

    <div class="content-nft-show" *ngIf="isLoadingMainAsset===false">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-between flex-wrap">
            <a class="btn btn-xs btn-outline-light border mb-4" [routerLink]="['/collection/' + collection.slug]" [queryParams]="{id: collection.id}">
              <i class="fa fa-chevron-left" ></i> &nbsp; Back to collection
            </a>
            <a class="btn btn-xs btn-outline-light border ml-0 ml-sm-3 mb-4" (click)="forceRefresh()">
              <i class="fa fa-sync-alt" ></i> &nbsp; Refresh data
            </a>
          </div>
        </div>
        <div class="row">
          <!-- mobile only -->
          <div class="col-12 d-flex d-lg-none order-1 mb-4">
            <div class="nft-img-wrapper border" (click)="modalOpenShowNftImage(showNftImageModal)">
              <div class="mx-auto">
                <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" [src]="asset.media | renderImageUrl: 'nft'" alt="">

                <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                  [poster]="asset.media | renderImageUrl : 'logo' : true : true"
                  [src]="asset.animationUrl"
                  class="w-100"
                  controls loop autoplay
                  controlsList="nodownload">
                </video>

              </div>

            </div>
          </div>
          <!-- end mobile only -->

          <ng-template #showNftImageModal let-modal>
            <div class="btn-close-show-nft-image">
                <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
              <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" [src]="asset.media | renderImageUrl: 'nft'" class="m-auto">
              <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                [poster]="asset.media | renderImageUrl : 'logo' : true : true"
                [src]="asset.animationUrl"
                class="w-100 m-auto"
                controls loop autoplay
                controlsList="nodownload">
              </video>

            </div>

          </ng-template>

          <div class="col-lg-5 order-3 order-lg-1">
            <div class="nft-img-wrapper border d-none d-lg-flex align-items-center" (click)="modalOpenShowNftImage(showNftImageModal)">
              <div>
                <!-- xxx {{asset.media}} -->

                <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" [src]="asset.media | renderImageUrl: 'nft'" alt="">

                <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                  [poster]="asset.media | renderImageUrl : 'logo' : true : true"
                  [src]="asset.animationUrl"
                  class="w-100"
                  controls loop autoplay
                  controlsList="nodownload">
                </video>

              </div>

            </div>
            <div class="author-area box-nft-action-wrapper mt-4 p-3 border" *ngIf="isConverter">
              <strong class="text-white-main">On-chain dynamic fields</strong>
              <hr style="margin:10px 0px"/>
              <p class="text-white-main" style="margin-top:10px" >

                Energy remain : {{energyRemain/100 | number: '1.0-2'}}<br/>
                EXP : {{exp/1 | number: '1.0-2'}}
              </p>
            </div>
            <div class="nft-properties-and-details">

              <div class="accordion mt-4" id="accordionPropertiesAndDetails">

                <div class="card bg-transparent no-hover shadow-none border p-0">
                  <div class="card-header bg-transparent p-0 border-0" id="properties-nft">
                    <h2 class="mb-0 mt-0">
                      <button class="btn btn-block text-left p-0 btn-header-arrow p-4 shadow-none collapsed" type="button" data-toggle="collapse" data-target="#collapseProperties" aria-expanded="false" aria-controls="collapseProperties">
                        <span class="accordion-no">
                          <i class="icon-tag tx-16"></i>
                        </span> Properties
                      </button>
                    </h2>
                  </div>
                  <div id="collapseProperties" class="collapse" aria-labelledby="properties-nft" data-parent="#accordionPropertiesAndDetails">

                    <div *ngIf="asset.attributes && asset.attributes.length > 0" class="card-body border-top mt-0 p-4">
                      <div class="d-flex align-items-center gap-1 flex-wrap mb-3">
                        <div *ngFor="let attr of asset.attributes" class="property-card border">
                          <span>{{attr.trait_type}}</span>
                          <p>{{attr.value}}</p>
                          <div class="text-muted">
                            {{attr.percent | gameNumberFormat: 2 : false : '%'}}
                            have this trait
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="!isLoadingMainAsset" class="card no-hover bg-transparent shadow-none border p-0">
                  <div class="card-header bg-transparent p-0 border-0" id="details-nft">
                    <h2 class="mb-0 mt-0">
                      <button class="btn btn-block text-left p-0 btn-header-arrow p-4 shadow-none" type="button" data-toggle="collapse" data-target="#collapseDetails" aria-expanded="true" aria-controls="collapseDetails">
                        <span class="accordion-no">
                          <i class="icon-list tx-16"></i>
                        </span> Details
                      </button>
                    </h2>
                  </div>

                  <div id="collapseDetails" class="collapse show" aria-labelledby="details-nft" data-parent="#accordionPropertiesAndDetails">
                    <div class="card-body border-top mt-0 p-4">

                      <div class="d-flex justify-content-between tx-14 mb-1">
                        <div>Contract Address</div>
                        <div>
                          <a (click)="openNewTabAddressScanUrl(collection.nftAddress,collection.chain)" class="tx-14 cursor-pointer text-primary">

                            {{collection.nftAddress | hideWalletAddress}}
                          </a>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between tx-14 mb-1">
                        <div>Token ID</div>
                        <div>
                          <a (click)="openNewTabNFTScanUrl(collection.nftAddress,collection.chain,asset.tokenId)" class="tx-14 cursor-pointer text-primary">
                            {{asset.tokenId}}
                          </a>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between tx-14 mb-1">
                        <div>Token Standard</div>
                        <div>
                          {{asset.tokenType | toUpperCase}}
                        </div>
                      </div>

                      <div class="d-flex justify-content-between tx-14 mb-1">
                        <div>Blockchain</div>
                        <div>
                          {{asset.chain}}
                        </div>
                      </div>


                      <div *ngIf="asset.metaIpfs" class="d-flex justify-content-between tx-14 mb-1">
                        <div>Metadata</div>
                        <div class="text-truncate ml-3">
                          <a [href]="asset.metaIpfs" target="_blank" class="tx-14 text-primary">
                            {{asset.metaIpfs}}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-lg-7 order-2 order-lg-3">
            <div class="title-nft-show d-flex justify-content-between">
              <a class="d-flex align-items-center mw-100" [routerLink]="['/collection/' + collection.slug]" [queryParams]="{id: collection.id}">
                <span class="text-truncate">
                  {{collection.name}}
                </span>
                <!-- <img [src]="getImageSupportChainByAbbr(collection.chain)"
                [title]="collection.chain" width="16" class="ml-1"> -->
                <span class="collections-verify ml-1">
                  <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                  [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                  class=""></i>
                </span>
              </a>
              <!-- <div></div> -->
              <!-- <div></div> -->
            </div>
            <h2 class="title-nft-name tx-40 my-2">
              {{asset.name}}
              <small class="text-muted">
                #{{asset.tokenId}}
              </small>
              <img [src]="getImageSupportChainByAbbr(asset.chain)"
              [title]="asset.chain" width="24" class="ml-1">
            </h2>
            <div>
              Owned by
              <a [routerLink]="['/account/'+ (asset.ownerUser ? (asset.ownerUser.length ?asset.ownerUser[0].name: asset.ownerAddress)  : undefined) ]">
                {{ (asset.ownerUser ? (asset.ownerUser.length ?( asset.ownerUser[0].name | hideWalletAddress ): (asset.ownerAddress | hideWalletAddress))  : undefined) }}
              </a>
            </div>

            <div *ngIf="isSell" class="box-nft-action-wrapper mt-4 py-4 px-4 border">
              <div class="d-flex align-items-center justify-content-between flex-wrap">

                <!-- start PRICE-TAG -->
                <h2 class="tx-32 my-3 my-md-1">
                  <div class="txt-label-form text-muted tx-14 font-weight-normal mb-1"> Current price</div>
                  {{lastestOrder.acceptedTokenAmountNumber | number: '1.0-6'}} {{acceptedTokenSymbol.toUpperCase()}}
                  <!--{{lastestOrder.acceptToken ? lastestOrder.acceptToken.symbol.toUpperCase() : (lastestOrder.acceptedTokenType ?lastestOrder.acceptedTokenType.toUpperCase():'')}}-->
                  <p class="text-muted tx-13 mt-1">
                    ({{ lastestOrder.acceptedTokenAmountNumber * acceptedTokenInfo.usd | gameNumberFormat: 2 : false}} USD, {{ lastestOrder.acceptedTokenAmountNumber  * acceptedTokenInfo.thb | gameNumberFormat: 2 : false}} THB)
                  </p>

                </h2>
                <!-- end PRICE-TAG -->

                <!-- start BUY PROCESS -->
                <div>
                  <div *ngIf="isApproved">
                    <a class="btn btn-primary text-white tx-16" (click)="buy()">Buy</a>
                  </div>
                  <div *ngIf="!isApproved">
                    <a class="btn btn-primary text-white tx-16" (click)="approve()">Approve</a>
                  </div>
                  <button button *ngIf="isSell && isOwner &&  !isBuyLoading" class="btn btn-danger text-white mt-2 tx-16" [disabled]="isCancelListingLoading" type="button"  (click)="cancelListing()">
                    Cancel Listing
                  </button>
                </div>
                <!-- end BUY PROCESS -->

              </div>

              <div *ngIf="isApproveLoading" class="col-12 text-center py-5">
                <div  class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="text-center text-muted">Approving</p>
              </div>

              <div *ngIf="isBuyLoading" class="col-12 text-center py-5">
                <div  class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="text-center text-muted">Buying</p>
              </div>

              <div *ngIf="isCancelListingLoading" class="col-12 text-center py-5">
                <div  class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <p class="text-center text-muted">Cancel Listing</p>
              </div>

              <hr style="margin: 15px 0px"/>
              <div class="d-flex justify-content-between text-muted tx-12 mb-2">
                <div>Royalty Fee</div>
                <div>{{feePercent | number:'1.2-2'}}%</div>
              </div>
              <div class="d-flex justify-content-between text-muted tx-12">
                <div>Platform Fee</div>
                <div>{{devFeePercent | number:'1.2-2'}}%</div>
              </div>
            </div>

            <div *ngIf="!isSell" class="box-nft-action-wrapper mt-4 py-4 px-4 border">

              <!-- start SELL PROCESS -->
              <div class="d-flex justify-content-between align-items-center">
                <div *ngIf="!isSell">
                  <div class="text-muted">
                    <i class="icon-tag tx-20 mr-1 align-middle"></i>
                    This item is not for sell yet
                  </div>
                </div>
                <a *ngIf="!isSell && isOwner" class="btn btn-primary text-white mt-2 tx-16" (click)="jumpToSellPage(asset)">
                  Sell
                </a>

              </div>
              <!-- end SELL PROCESS -->

            </div>

            <!-- transfer -->
            <div *ngIf="!isSell && isOwner" class="author-area box-nft-action-wrapper mt-4 pt-0 pb-0 border">


              <div class="d-flex justify-content-between align-items-center">
                <form class="item-form pt-4 pb-4 bg-transparent">
                  <div class="form-group ">
                    <div class="flex-grow-1">
                      <div class="text-right mb-1">
                        <p class="tx-12">Fee 0.2 KUB</p>
                      </div>
                      <input type="text" class="form-control" autocomplete="off" name="recipient" [(ngModel)]="recipient" placeholder="Send to recipient address">
                      <p *ngIf="recipient" class="mt-1 pt-1 tx-10 text-muted">You are going to send this nft to {{recipient}}</p>
                    </div>
                  </div>
                  <div class="text-right">

                    <button [disabled]="isSending" *ngIf="!isSell && isOwner && isPostmanApproved" type="button" class="btn btn-primary text-white mt-2 tx-16" (click)="transfer(asset)">
                      <span *ngIf="isSending" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                      Send
                    </button>
                    <button [disabled]="isApprovePostmanLoading" *ngIf="!isSell && isOwner && !isPostmanApproved" type="button" class="btn btn-primary text-white mt-2 tx-16" (click)="approvePostman(asset)">
                      <span *ngIf="isApprovePostmanLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                      Approve
                    </button>
                  </div>
                </form>

              </div>
            </div>


            <div class="mt-4 text-muted nft-description">

              <span *ngIf="!customFieldsBySlug">
                {{displayDescription}}
              </span>



              <span *ngIf="customFieldsBySlug">

                <div class="mb-4 d-flex flex-wrap gap-2" *ngIf="customFieldsBySlug.isEnabledShowcaseItems && customFieldsBySlug.showcaseItems">
                  <div *ngFor="let showcaseItem of customFieldsBySlug.showcaseItems" class="showcase-items-wrapper py-4 px-4 border d-flex align-items-center gap-2">
                    <img *ngIf="showcaseItem.imageUrl" [src]="showcaseItem.imageUrl" [class]="showcaseItem.imageClass" alt="">
                    <span class="middle-line-custom border"></span>
                    <span class="text-white-main" [innerHTML]="showcaseItem.innerHtmlText"></span>
                  </div>
                </div>

                {{customFieldsBySlug.description}}
              </span>

            </div>

            <div class="nft-social mt-3">
              <!-- Social Icons -->
              <div class="social-icons d-flex">
                <a data-toggle="popover" data-content="Website" data-placement="bottom" *ngIf="collection.links && collection.links.www" class="icon-website-a" target="_blank" href="{{collection.links.www | externalLinkByType : 'website'}}">
                    <i class="fas fa-globe"></i>
                    <i class="fas fa-globe"></i>
                </a>
                <a data-toggle="popover" data-content="Discord" data-placement="bottom" *ngIf="collection.links && collection.links.discord" class="icon-discord-a" target="_blank" href="{{collection.links.discord | externalLinkByType : 'discord'}}">
                  <i class="fab fa-discord"></i>
                  <i class="fab fa-discord"></i>
                </a>
                <a data-toggle="popover" data-content="Instagram" data-placement="bottom" *ngIf="collection.links && collection.links.instagram" class="icon-instagram-a" target="_blank" href="{{collection.links.instagram | externalLinkByType : 'instagram'}}">
                  <i class="fab fa-instagram"></i>
                  <i class="fab fa-instagram"></i>
                </a>
                <a data-toggle="popover" data-content="Medium" data-placement="bottom" *ngIf="collection.links && collection.links.medium" class="icon-medium-a" target="_blank" href="{{collection.links.medium | externalLinkByType : 'medium'}}">
                  <i class="fab fa-medium"></i>
                  <i class="fab fa-medium"></i>
                </a>
                <a data-toggle="popover" data-content="Telegram" data-placement="bottom" *ngIf="collection.links && collection.links.telegram" class="icon-telegram-a" target="_blank" href="{{collection.links.telegram | externalLinkByType : 'telegram'}}">
                  <i class="fab fa-telegram"></i>
                  <i class="fab fa-telegram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isFailedFetchingAsset" class="nft-related">
      <div class="container " style="margin-bottom: 5vh">
        <section class="author-area">
        <div class="row ">
          <div class="col-12 text-center">
            <hr style="margin-bottom: 5vh">
            <img src="assets/img/icons/sunglasses.png" width="128"/>
            <h4>Failed to fetching related assets</h4>
            <p>Try again later</p>
          </div>
        </div>
        </section>
      </div>
    </div>

    <div *ngIf="!isFetchingAsset && collection && relatedAssets.length" class="nft-related">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <hr>
            <h5 class="title-related-collection mb-1">Related From This Collection</h5>
            <div class="nft-related-content">

              <div class="nft-related-content-inner">
                <app-nft-card-list
                  *ngIf="collection && relatedAssets.length"
                  [collection]="relatedAssets[0].collection"
                  [assets]="relatedAssets"
                  [totalResults]="totalResults"
                  [enabledSearch]="false"
                  [enabledSort]="false"
                  [isShowTotalResults]="false"
                  [enabledLazyLoader]="false"
                  [isFetchingAsset]="isFetchingAsset">
                </app-nft-card-list>
              </div>
              <div *ngIf="!isLoadingMainAsset" class="mt-4 text-center">
                <a [routerLink]="['/collection/' + collection.slug]" class="btn btn-outline-light border text-white-main">
                  View collection
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isFetchingAsset && !isFailedFetchingAsset && asset" class="nft-item-activity" >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <hr>
            <h5>Item Activity</h5>

            <div class="box-content-inner-wrapper">
              <div class="nft-item-activity-content">
                <div class="dropdown mb-4 mt-3">
                  <button class="btn dropdown-toggle w-100 py-2 pl-4 border shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span style="text-transform: capitalize;">
                      {{eventTypeFiltered ? (eventTypeFiltered | eventTypeToText) : 'All Event Types'}}
                    </span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-full">
                    <a (click)="selectEventTypeFiltered(undefined)" class="dropdown-item">
                      All Event Types
                    </a>
                    <a (click)="selectEventTypeFiltered('created')" class="dropdown-item">
                      {{'created' | eventTypeToText}}
                    </a>
                    <a (click)="selectEventTypeFiltered('closed')" class="dropdown-item">
                      {{'closed' | eventTypeToText}}
                    </a>
                    <a (click)="selectEventTypeFiltered('cancel')" class="dropdown-item">
                      {{'cancel' | eventTypeToText}}
                    </a>
                  </div>
                </div>
                <app-activity-table [tokenId]="asset.tokenId" [nftAddress]="nftAddress" [eventTypes]="eventTypeFiltered ? [eventTypeFiltered] : undefined"></app-activity-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoadingMainAsset && isFetchingAsset" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
      <div  class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <p class="text-center text-muted">Fetching related assets</p>
    </div>



    <ng-template #reviewCollectionModal let-modal>
      <div class="modal-header border-bottom p-4">
        <h5 class="modal-title mt-0">This is an unreviewed collection</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-block">
        <div class="icon-review-wrapper">

          <!-- non approve -->
          <div class="icon-review-collection icon-non-approve border">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
              <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
            </svg>
            <div class="icon-search-review">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
              <div class="icon-shield-alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-fill-exclamation" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm-.55 8.502L7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0zM8.002 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="text-center text-muted tx-16 mt-5 mb-4">Review this information to ensure it’s what you want to buy.</div>

        </div>
        <div class="info-detail-wrapper">
          <div class="info-detail-row">
            <div class="info-detail-label">Collection name</div>
            <div class="info-detail-content">
              <a href="#">bichon diecarmat</a>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Creator</div>
            <div class="info-detail-content">
              <a href="#">Diecarmat</a> <span class="ml-2">(member since Jul 26, 2021)</span>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Total sales</div>
            <div class="info-detail-content">
              <b>33 sales</b>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Total volume</div>
            <div class="info-detail-content">
              <img src="../../../assets/img/tokens/eth.svg" class="mr-1" width="16"> <b>0.006</b> <span class="ml-2">($21.85)</span>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Social links</div>
            <div class="info-detail-content">
              <a href="https://twitter.com/yourTwitter" target="_blank" class="mr-3">
                <i class="icon-social-twitter tx-20"></i>
              </a>
              <a href="https://www.instagram.com/yourInstagram/" target="_blank" class="mr-3">
                <i class="icon-social-instagram tx-20"></i>
              </a>
              <a href="https://t.me/yourTelegram" target="_blank" class="mr-3">
                <i class="icon-paper-plane tx-20"></i>
              </a>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Contract address</div>
            <div class="info-detail-content">
              <a href="#">0x495f...7b5e</a>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Total items</div>
            <div class="info-detail-content">
              <b>216 items</b>
            </div>
          </div>
          <div class="info-detail-row">
            <div class="info-detail-label">Creator</div>
            <div class="info-detail-content">
              <b>Diecarmat</b>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer author-area px-5">
        <div class="item-form p-0">
          <div class="form-check form-check-understand-review form-group">
            <input type="checkbox" name="inlineRadioOptions" id="checkUnderstand" class="form-check-input border cursor-pointer" (click)="openBuyCheckoutModal(buyCheckoutModal);modal.dismiss('Cross click');">
            <label for="checkUnderstand" class="form-check-label ml-3 cursor-pointer">I understand that FreeCity has not reviewed this collection and blockchain transactions are irreversible.</label>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #buyCheckoutModal let-modal>
      <div class="modal-header d-flex justify-content-between border-bottom p-4">
        <button type="button" class="close mx-0 font-weight-bold back-btn-modal" (click)="openReviewCollectionModal(reviewCollectionModal);modal.dismiss('Cross click');">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
          </svg>
        </button>
        <h5 class="modal-title text-center mt-0">Complete checkout</h5>
        <button type="button" class="close mx-0" (click)="modal.dismiss('Cross click');">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-block">
        <div class="d-flex justify-content-between align-items-center p-2 border-bottom">
          <div class="font-weight-bold tx-16">Item</div>
          <div class="font-weight-bold tx-16">Subtotal</div>
        </div>
        <div class="d-flex justify-content-between align-items-center px-2 py-3">
          <div class="d-flex align-items-center">
            <div class="mr-3 border h-100 rounded">
              <img src="https://lh3.googleusercontent.com/KXUPFtisVOedahDmcdnmHHoy7P75DS50HxOxm05d9BzXMqaQpIfdyWyJQfpRkDaajB8sUQsN3vMVQfWSyqkfkMaRhhNbpO1Yr_wrLg=w600" width="50" alt="">
            </div>
            <div class="text-left">
              <div><small class="text-muted tx-14">Collection name</small></div>
              <div><small class="font-weight-bold tx-15">NFT name</small></div>
              <div><small class="text-muted tx-14">Creator Fees: 4%</small></div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="text-right mr-3">
              <div>
                <small class="tx-14 font-weight-bold">
                  <img src="../../../assets/img/tokens/eth.svg" width="10" class="align-middle mr-1"> 0.05
                </small>
              </div>
              <div><small class="text-muted tx-14">$3,474.44 USD</small></div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center px-2 py-3 border-top">
          <div class="font-weight-bold tx-16">Total</div>
          <div class="d-flex align-items-center">
            <div class="text-right mr-3">
              <div>
                <small class="tx-16 font-weight-bold text-primary">
                  <img src="../../../assets/img/tokens/eth.svg" width="10" class="align-middle mr-1"> 0.05
                </small>
              </div>
              <div><small class="text-muted tx-16">$3,474.44 USD</small></div>
            </div>
          </div>
        </div>
        <div class="author-area pt-4 pb-1 px-2 border-top">
          <div class="item-form p-0">
            <div class="form-check form-check-understand-review form-group mb-0">
              <input type="checkbox" name="inlineRadioOptions" id="checkUnderstand" class="form-check-input border mt-0 cursor-pointer">
              <label for="checkUnderstand" class="form-check-label ml-3 cursor-pointer">By checking this box, I agree to FreeCity's <a href="#">Terms of Service</a></label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center d-block">
          <button class="btn btn-primary w-100 my-2 text-white max-w-300" type="button">Complete checkout</button>
      </div>
    </ng-template>

  </div>

  <div *ngIf="!assetFound">
    <div class="container " style="margin-top: 8vh;margin-bottom: 5vh">
      <section class="author-area">
      <div class="row ">
        <div class="col-12 text-center">
          <img src="assets/img/icons/sunglasses.png" width="128"/>
          <h4>NFT Not Found</h4>
          <p>Sorry we can not find NFT address or token ID that you specific.</p>
          <a class="btn btn-primary text-white" [routerLink]="['/']">
            Go Back
          </a>
        </div>
      </div>
      </section>
    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>

