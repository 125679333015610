<div class="main">
    <app-header-section></app-header-section>
    <app-breadcrumb-help-center></app-breadcrumb-help-center>
    <!-- <app-help-center-section></app-help-center-section> -->
    <app-faq-section></app-faq-section>
    <app-footer-section></app-footer-section>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
    <app-scrollup></app-scrollup>
</div>
