<section class="top-seller-area pt-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Intro -->
                <div class="intro d-flex justify-content-between align-items-end m-0">
                    <div class="intro-content">
                        <span>Creative Artist</span>
                        <h3 class="mt-3 mb-0">Top Sellers</h3>
                    </div>
                    <div class="intro-btn">
                        <a class="btn content-btn" href="authors">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row items">
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@Richard</a>
                            <span>1.5 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@JohnDeo</a>
                            <span>2.3 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@Junaid</a>
                            <span>2.5 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@Yasmin</a>
                            <span>1.9 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@ArhamH</a>
                            <span>3.2 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 item">
                <!-- Single Seller -->
                <div class="card no-hover">
                    <div class="single-seller d-flex align-items-center">
                        <a href="author">
                            <img class="avatar-md rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                        </a>
                        <!-- Seller Info -->
                        <div class="seller-info ml-3">
                            <a class="seller mb-2" href="author">@Sara</a>
                            <span>4.7 BNB</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>