import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { MiningService } from './../../services/mining.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { FreeCityContractService } from './../../services/free-city-contract.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ethers, logger,utils } from "ethers";

import {Logger} from '../../services/logger.service';
const log = new Logger('OrderBookComponent');

@Component({
  selector: 'app-order-book',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.scss']
})
export class OrderBookComponent implements OnInit {
  account:any
  spendTokenSearchText:any = ''
  acceptedTokenSearchText:any = ''

  acceptedTokenAddresses:any = []
  serverTokensAddresses:any = []

  spendTokens:any = []
  customToken:any

  selectedSpendToken:any
  selectedReceiveToken:any

  currentChain:any

  isApproved = false
  isApproveLoading = false
  isReloading = false

  isListingLoading = false
  isCustomTokenLoading = false

  spendTokenAmount:string = ''
  recieveTokenAmount:string = ''

  constructor(
    private freeCityContractService:FreeCityContractService,
    private modalService:NgbModal,
    private singletonService:SingletonService,
    private miningService:MiningService,
    private backendService:BackendService,
    private router:Router,
  ) { }

  async ngOnInit() {

    this.isReloading = true
    this.account = await this.singletonService.getCurrentConnectedAccount()
    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("this.currentChain => %o",this.currentChain)
    this.acceptedTokenAddresses = await this.freeCityContractService.getP2PAcceptedTokenAddresses()
    log.debug("this.acceptedTokenAddresses => %o",this.acceptedTokenAddresses)
    this.serverTokensAddresses = await this.backendService.getOrderBookTokenAll(this.currentChain.networkAbbr)
    log.debug("this.serverTokensAddresses => %o",this.serverTokensAddresses)
    log.debug("init start ")
    await this.initSpendTokenList()
    log.debug("end initSpendTokenList ")
    await this.initAcceptedTokenList()
    log.debug("end initAcceptedTokenList ")
    await this.initSelectSpendAndRecieveTokens()
    this.isReloading = false
  }



  async initSelectSpendAndRecieveTokens(){
    this.selectedSpendToken = await this.spendTokens[0]
    this.selectedReceiveToken = await this.acceptedTokenAddresses[0]
    if(this.selectedSpendToken.address.toLowerCase() == this.selectedReceiveToken.address.toLowerCase()){
      if(this.spendTokens[1]) this.selectedSpendToken = this.spendTokens[1]
      else if(this.acceptedTokenAddresses[1]) this.selectedReceiveToken = this.acceptedTokenAddresses[1]
      log.debug("same token...")
    }else{
      log.debug("not same token...")
    }

    log.debug("this.selectedSpendToken => %o",this.selectedSpendToken)
    log.debug("this.selectedReceiveToken => %o",this.selectedReceiveToken)


    await this.checkApproval()
  }

  async checkApproval(){
    // check for approval
    if(this.selectedSpendToken && this.selectedSpendToken.address != ethers.constants.AddressZero){
      this.isApproved = await this.freeCityContractService.checkTokenApprovalForMarketP2P(
        this.selectedSpendToken.address,
        ethers.utils.parseEther('1000000000') // 1000M
      )
      log.debug("approved = "+this.isApproved)
    }else{
      this.isApproved = true
    }
  }

  openListTokenModal(modal) {
    this.modalService.open(modal, {
      centered: true,
      scrollable: true
    });
  }


  async customImportToken(){
    this.isCustomTokenLoading = true
    log.debug("customImportToken spendTokenSearchText => %o",this.spendTokenSearchText)
    if(await ethers.utils.isAddress(this.spendTokenSearchText)){
      let tokenAddress = this.spendTokenSearchText
      log.debug("tokenAddress : %o",tokenAddress)

      let customToken = await this.freeCityContractService.getTokenInfoAndBalance(
        [tokenAddress],
        this.account,
        true
      )
      log.debug("customToken : %o",customToken)

      this.customToken = await customToken[0]
    }else{
      this.customToken = undefined
    }
    this.isCustomTokenLoading = false

  }

  async approveToken(){
    this.isApproveLoading = true
    this.freeCityContractService.approveTokenP2P(this.selectedSpendToken.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      let approveEvent = receipt.events.find((e)=> e.event === 'Approval')
      await this.singletonService.fire('success','Approve Success', 'Success approve token to buy assets on market.')
      this.isApproved = true
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveLoading = false
    })
  }

  async initSpendTokenList(){
    this.spendTokens = [...this.spendTokens,...(await this.singletonService.getSpendTokensLocalStorage())]

    log.debug("initSpendTokenList => %o",this.serverTokensAddresses)
    this.spendTokens = await this.freeCityContractService.getTokenInfoAndBalance(
      this.serverTokensAddresses.map(it => it.address),
      this.account,
      true
    )
    log.debug("this.spendTokens => %o",this.spendTokens)
  }

  async initAcceptedTokenList(){
    log.debug("initAcceptedTokenList ",this.acceptedTokenAddresses)
    this.acceptedTokenAddresses = await this.freeCityContractService.getTokenInfoAndBalance(
      this.acceptedTokenAddresses.map(it => it),
      this.account,
      true
    )
    log.debug("this.acceptedTokenAddresses => %o",this.acceptedTokenAddresses)
  }

  async listing(){
    log.debug("listing this.selectedSpendToken ",this.selectedSpendToken)
    log.debug("listing this.selectedReceiveToken ",this.selectedReceiveToken)


    let itemAddr = this.selectedSpendToken.address // PUNK
    let itemAmount =  ethers.utils.parseUnits(this.spendTokenAmount, this.selectedSpendToken.decimals)
    let acceptedTokenAddr = this.selectedReceiveToken.address // KUSDT
    let acceptedTokenAmount = ethers.utils.parseUnits(this.recieveTokenAmount, this.selectedReceiveToken.decimals)

    // let itemAmountOld = ethers.utils.parseEther(this.spendTokenAmount)
    // log.debug("listing itemAmountOld ",itemAmountOld)

    log.debug(" itemAddr ",itemAddr)
    log.debug(" itemAmount ",itemAmount)
    log.debug(" acceptedTokenAddr ",acceptedTokenAddr)
    log.debug(" acceptedTokenAmount ",acceptedTokenAmount.toString())

    if(itemAddr.toLowerCase() == acceptedTokenAddr.toLowerCase()) {
      this.singletonService.fire('warning',"Spending token and receive token are the same.")
      return
    }

    this.isListingLoading = true

    this.freeCityContractService.listingP2P(
      itemAddr, // placing token (punk)
      itemAmount, // amount of punk (1)
      acceptedTokenAddr,  // receive token (kusdt)
      acceptedTokenAmount // amount of accepted (100 kusdt)
    ).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("receipt.events ",receipt.events)
      let createdEvent = receipt.events.find((e)=> e.event === 'Created')
      log.debug("createdEvent ",createdEvent)
      log.debug("order id : ",createdEvent.args.id)
      log.debug("seller : ",createdEvent.args.seller)
      log.debug("meta : ",createdEvent.args.meta)
      log.debug("order: ",createdEvent.args.order)
      this.singletonService.fire('success','Listing', 'Success listing on market.')

      await this.singletonService.saveSpendTokensLocalStorage(this.selectedSpendToken)

      // redirect to nft view
      // await this.initSpendTokenList()
      await this.router.navigate(['/order-list']);

    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Listing failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isListingLoading = false
    })
  }

  async selectReceiveToken(token){
    this.selectedReceiveToken = token
  }

  async selectSpendToken(token){
    this.selectedSpendToken = token
    await this.checkApproval()
  }


}
