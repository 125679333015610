<div class="main">
  <app-header-section></app-header-section>

  <!-- Breamcrumb -->
  <!--
  <section class="breadcrumb-area overlay-dark d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="breadcrumb-content text-center">
                    <h2 class="m-0">Create a collection</h2>
                    <ol class="breadcrumb d-flex justify-content-center">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="/collection">Collection</a></li>
                        <li class="breadcrumb-item active">Create</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
  </section> -->
  <!-- End Breamcrumb -->


  <section class="author-area">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-md-12">
                <!-- Intro -->
                <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1">
                    <div class="intro-content">
                        <span>Collection</span>
                        <h3 class="mt-3 mb-0">
                          {{id?'Edit':'Create'}} a collection
                        </h3>
                        <p>If you want to mint a big lot of NFTs, please contact us at Facebook fan page for fee discounting.</p>
                    </div>
                </div>
              <hr/>
                <!-- Item Form -->
                <div class="item-form card no-hover bg-transparent border-0 shadow-none" style="padding-top: 10px!important;">
                  <p class="mb-5"><small><b class="text-danger">*</b> Required fields</small></p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-5">
                              <div class="txt-label-form font-weight-bold">Logo image <b class="text-danger">*</b></div>
                              <p class="mt-1 mb-2"><small>This image will also be used for navigation. 350 x 350 recommended.</small></p>
                              <div class="">
                                <label class="input-logo-image-label">
                                  <div id="input-logo-image" class="show-img-preview"></div>
                                  <input id="input-logo-image-instance" type="file" (change)="handleFileInput_logoImage($event.target.files)" hidden>
                                  <i class="icon-picture"></i>
                                  <!-- <button class="close btn-remove-img" style="right: 0;top: 0;">&times;</button> -->
                                </label>
                              </div>
                            </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold">Featured image</div>
                            <p class="mt-1 mb-2"><small>This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of FreeCity. 600 x 400 recommended.</small></p>
                            <div class="">
                              <label class="input-featured-image-label">
                                <div id="input-featured-image" class="show-img-preview"></div>
                                <input id="input-featured-image-instance" type="file" (change)="handleFileInput_featuredImage($event.target.files)" hidden>
                                <i class="icon-picture"></i>
                                <button [hidden]="!featuredImage" (click)="clearFile_featuredImage()" class="close btn-remove-img">&times;</button>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold">Banner image</div>
                            <p class="mt-1 mb-2"><small>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</small></p>
                            <div class="">
                              <label class="input-banner-image-label">
                                <div id="input-banner-image" class="show-img-preview"></div>
                                <input id="input-banner-image-instance" type="file" (change)="handleFileInput_bannerImage($event.target.files)" hidden>
                                <i class="icon-picture"></i>
                                <button [hidden]="!bannerImage" (click)="clearFile_bannerImage()" class="close btn-remove-img">&times;</button>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group mb-5">
                              <div class="txt-label-form font-weight-bold mb-2">Collection Name <b class="text-danger">*</b></div>
                              <input [(ngModel)]="name" type="text" class="form-control pl-4" placeholder="Example: Treasures of the Sea" required="required">
                            </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5 wrapper-input-group-custom">
                            <div class="txt-label-form">URL <b class="text-danger">*</b></div>
                            <p class="mt-1 mb-2"><small>
                              Slug name to link your nft collection on FreeCity.
                              <span class="text-warning">
                                Must only contain lowercase letters, numbers, and hyphens. (max size: 50)
                              </span>
                            </small></p>
                            <div class="input-group shadow-none mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text pl-4">https://freecity.com/collection/</span>
                              </div>
                              <input [(value)]="slug"
                                (input)="isValidSlugWithDelay();"
                                id="slug-input" type="text" class="form-control" placeholder=""
                                [regExFormatType]="1" alphaNumberic>
                            </div>
                            <small [ngClass]="(_isValidSlug != undefined && _isValidSlug) ? 'text-success' : 'text-danger'" id="used-slug-warning" [hidden]="_isValidSlug == undefined || loadings.validateSlug">
                               {{ (_isValidSlug != undefined && _isValidSlug) ? '(\u2714) This URL is valid.' : '(\u2716) The URL name is already taken or invalid.'}}
                            </small>
                            <small [hidden]="!loadings.validateSlug">
                              <!-- (zzz) loading... -->
                              <i class="fas fa-spinner fa-spin icon-w-h-25px mr-2 align-middle"></i> Loading...
                            </small>
                          </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5">
                          <div class="txt-label-form font-weight-bold mb-2">Description <b class="text-danger">*</b></div>
                          <textarea [(ngModel)]="description" placeholder="Provide a detailed description of your item." class="form-control pl-4" name="" id="" cols="30" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5">
                          <div class="txt-label-form font-weight-bold mb-2">Category <b class="text-danger">*</b></div>
                          <p class="mt-1 mb-2"><small>Adding a category will help make your item discoverable on FreeCity.</small></p>
                          <div class="dropdown">
                            <button class="btn dropdown-toggle pl-4 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                              {{category ? (category | toUpperCase) : 'Add Category'}}
                            </button>
                            <div class="dropdown-menu">
                              <a *ngFor="let _category of categories" (click)="selectCategory(_category)" class="dropdown-item">
                                {{_category | toUpperCase}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5 wrapper-input-group-custom">
                          <div class="txt-label-form font-weight-bold mb-2">Links </div>
                          <div class="input-group shadow-none mb-0 rounded-0 rounded-top">
                            <div class="input-group-prepend">
                              <span class="input-group-text rounded-0 pl-4">Website : </span>
                            </div>
                            <input [(ngModel)]="linkwww" [maxlength]="30" type="text" class="form-control pl-2" placeholder="YourSite.com">
                          </div>
                          <div class="input-group shadow-none mb-0 rounded-0">
                            <div class="input-group-prepend">
                              <span class="input-group-text rounded-0 pl-4">https://discord.gg/</span>
                            </div>
                            <input [(ngModel)]="linkdiscord" [maxlength]="30" type="text" class="form-control" placeholder="YourDiscord">
                          </div>
                          <div class="input-group shadow-none mb-0 rounded-0">
                            <div class="input-group-prepend">
                              <span class="input-group-text rounded-0 pl-4">https://www.instagram.com/</span>
                            </div>
                            <input [(ngModel)]="linkinstagram" [maxlength]="30" type="text" class="form-control" placeholder="YourInstagram">
                          </div>
                          <div class="input-group shadow-none mb-0 rounded-0">
                            <div class="input-group-prepend">
                              <span class="input-group-text rounded-0 pl-4">https://www.medium.com/@</span>
                            </div>
                            <input [(ngModel)]="linkmedium" [maxlength]="30" type="text" class="form-control" placeholder="YourMedium">
                          </div>
                          <div class="input-group shadow-none mb-0 rounded-0 rounded-bottom">
                            <div class="input-group-prepend">
                              <span class="input-group-text rounded-0 pl-4">https://t.me/</span>
                            </div>
                            <input [(ngModel)]="linktelegram" [maxlength]="30" type="text" class="form-control" placeholder="YourTelegram">
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5">
                          <div class="txt-label-form font-weight-bold mb-2">Creator Earnings </div>
                          <p class="mt-1 mb-2">
                            <small>
                              Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.
                              ({{this.MAX_LOYALTY_FEE}}% Max)
                            </small>
                          </p>
                          <div class="mb-2 text-muted"><small>Percentage fee</small></div>
                          <input [(value)]="creatorEarningsFee" [ngClass]="isCreatorEarningsNoGreaterThan() ? '' : 'custom-invalid'" type="text" class="form-control pl-4" placeholder="e.g. 2.5" numbersAndDot>
                          <small class="text-danger" [hidden]="isCreatorEarningsNoGreaterThan() ? true : false">
                            (X) Creator earnings cannot be greater than {{this.MAX_LOYALTY_FEE}}%
                          </small>
                        </div>
                      </div>
                      <div class="col-12" *ngIf="hasCreatorEarningsFeeValue()">
                        <div class="form-group mb-5">
                          <div class="txt-label-form font-weight-bold mb-2">Creator Wallet Address <b class="text-danger">*</b></div>
                          <input  [(ngModel)]="creatorWalletAddress" [ngClass]="validateCreatorWalletAddress() ? '' : 'custom-invalid'" type="text" class="form-control" placeholder="Please enter an address, e.g. 0x0123... or destination address" >
                        </div>
                      </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Blockchain </div>
                            <p class="mt-1 mb-2"><small>Select the blockchain where you'd like new items from this collection to be added by default.</small></p>
                            <div class="dropdown">
                              <button [disabled]="id" *ngIf="blockchain" class="btn dropdown-toggle shadow-none w-100 py-2 pl-4" type="button" data-toggle="dropdown" aria-expanded="false">
                                <img [src]="blockchain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                <span style="text-transform: capitalize;">
                                  {{blockchain.networkName}}
                                </span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-full">
                                <a *ngFor="let chain of supportChains" class="dropdown-item" (click)="selectBlockchain(chain)">
                                  <img [src]="chain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                  {{chain.networkName}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Payment tokens </div>
                            <p class="mt-1 mb-2"><small>These tokens can be used to buy and sell your items.</small></p>
                            <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
                              <div *ngFor="let paymentToken of selected_paymentTokens" class="payment-token-box d-flex align-items-center">
                                <div class="mr-3">
                                  <img [src]="paymentToken.assetImage" height="30" width="30">
                                </div>
                                <div>
                                  <div class="txt-label-form">{{paymentToken.symbol | toUpperCase}}</div>
                                  <div><small class="text-muted">{{paymentToken.name}}</small></div>
                                </div>
                                <div class="ml-3">
                                  <span *ngIf="!paymentToken.force" (click)="removePaymentToken(paymentToken)"  class="text-muted font-weight-light cursor-pointer tx-28">
                                    &times;
                                  </span>
                                </div>
                              </div>
                              <!-- <div class="payment-token-box d-flex align-items-center">
                                <div class="mr-3"><img src="https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg" height="20" width="20"></div>
                                <div>
                                  <div class="font-weight-bold">WETH</div>
                                  <div><small class="text-muted">Ethereum</small></div>
                                </div>
                                <div class="ml-3">
                                  <span class="text-muted font-weight-light cursor-pointer tx-28">
                                    &times;
                                  </span>
                                </div>
                              </div> -->
                            </div>
                            <div class="dropdown">
                              <button class="btn dropdown-toggle w-100 pl-4 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                Add Token
                              </button>
                              <div class="dropdown-menu dropdown-menu-full">
                                <a *ngFor="let paymentToken of nonForcedPaymentTokens" class="dropdown-item d-flex align-items-center" (click)="selectPaymentToken(paymentToken)">
                                  <div class="mr-2">
                                    <img [src]="paymentToken.assetImage" class="mr-2 rounded-circle" width="32" height="32">
                                  </div>
                                  <div>
                                    <div>{{paymentToken.symbol | toUpperCase}}</div>
                                    <small class="text-muted">{{paymentToken.name}}</small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 mb-5">
                          <div div class="font-weight-bold mb-2">Display theme </div>
                            <p class="mt-1 mb-2"><small>Change how your items are shown.</small></p>
                            <div class="dropdown">
                              <button class="btn dropdown-toggle shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                {{displayTheme | toUpperCase}}
                              </button>
                              <div class="dropdown-menu">
                                <a class="dropdown-item" (click)="selectDisplayTheme('Padded')">
                                  <div>Padded</div>
                                  <div>
                                    <small class="text-muted">
                                      Recommended for assets with transparent background
                                    </small>
                                  </div>
                                </a>
                                <a class="dropdown-item" (click)="selectDisplayTheme('Contained')">
                                  <div>Contained</div>
                                  <div>
                                    <small class="text-muted">
                                      Recommended for assets that are not a 1:1 ratio
                                    </small>
                                  </div>
                                </a>
                                <a class="dropdown-item" (click)="selectDisplayTheme('Cover')">
                                  <div>Cover</div>
                                  <div>
                                    <small class="text-muted">
                                      Recommended for assets that can extend to the edge
                                    </small>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div> -->
                        <div class="col-12">
                            <div class="d-flex mb-5">
                              <div class="form-group">
                                <div class="form-check form-check-inline">
                                  <input [checked]="isSensitiveContent" (click)="toggleIsSensitiveChecked()" class="form-check-input border" type="radio" name="inlineRadioOptions" id="inlineRadio1">
                                  <label class="form-check-label" for="inlineRadio1"></label>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="mb-2"><b class="txt-label-form">Explicit & sensitive content</b></div>
                                <div><small class="text-muted"> this collection as explicit and sensitive content</small></div>
                              </div>

                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group mb-5">
                              <div class="txt-label-form font-weight-bold mb-2">Featured Attribute</div>
                              <p class="mt-1 mb-2">
                                <small>
                                  This will be displayed as a badge on the nfts' image of this collection on FreeCity.
                                </small>
                              </p>
                              <input [(ngModel)]="featureAttr" type="text" class="form-control pl-4" placeholder="Example: rarity">
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <button [disabled]="loadings.submit" (click)="submit()" class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300">
                              <div *ngIf="loadings.submit">
                                  <i class="fas fa-spinner fa-spin icon-w-h-25px align-middle mr-2"></i> Loading...
                              </div>
                              <div *ngIf="!loadings.submit">
                                {{id?'Update':'Create'}} collection
                              </div>
                              <!-- {{loadings.submit ? '(zzz) loading...' : 'Create collection'}} -->
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>

