import { ToCappitalPipe } from './to-cappital.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'featureAttrBadge'
})
export class FeatureAttrBadgePipe implements PipeTransform {
  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}
  transform(attributes: any, ...args: unknown[]): unknown {
    let featureAttr:any = args[0] // text or icon

    if(attributes && attributes.length && featureAttr){
      let _featureAttr = attributes.find(attribute => (attribute.trait_type).toLowerCase() == (featureAttr).toLowerCase())
      if(_featureAttr){
        return _featureAttr.value == '' ? '---' : _featureAttr.value
      }else{
        return '---'
      }
    }else{
      return '---'
    }

  }

}
