import { RenderImageUrlPipe } from './../../pipes/render-image-url.pipe';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit,ChangeDetectorRef } from '@angular/core';

import { mockdata } from '../../mocks/collection_create_submit';


import { Logger } from './../../services/logger.service';
const log = new Logger('CollectionCreateComponent'); // log.debug

@Component({
  selector: 'app-collection-create',
  templateUrl: './collection-create.component.html',
  styleUrls: ['./collection-create.component.scss']
})
export class CollectionCreateComponent implements OnInit {
  public readonly MAX_LOYALTY_FEE = 3
  id:any
  slugCollection:any

  delayTimer:any;
  // createFields = mockdata

  logoImage:any
  featuredImage:any
  bannerImage:any
  name:any
  slug:any = ''
  description:any
  category:any // inlist ['art','collectibles','music','photography','sports','trading cards','utility']
  // links:any = {
  //   www: "",
  //   discord: "",
  //   instagram: "",
  //   medium: "",
  //   telegram: "",
  // }

  linkwww:any  // = "hello-world-nft.io"
  linkdiscord:any  // = "hello-world-nft.discord"
  linkinstagram:any  // = "hello-world-nft.instagram"
  linkmedium:any  // = "hello-world-nft.medium"
  linktelegram:any  // = "hello-world-nft.telegram"

  creatorEarningsFee:any = 0 // maximum <= 10 -------- defined in Opensea
  creatorWalletAddress:any
  blockchain:any // inlist [SUPPORTED_CHAIN] << get list from frontend config
  // paymentTokens:any
  displayTheme:any = "padded" // inlist ['padded','contained',covered]
  isSensitiveContent:any = false
  featureAttr:any

  categories:any = []
  supportChains:any = []

  selected_paymentTokens:any = []

  nonForcedPaymentTokens:any = []
  // address: "0x0000000000000000000000000000000000000000"
  // assetImage: "https://storage.googleapis.com/static.bitkubnext.com/bitkub-next/token-icons/kub.png"
  // chain: "624f69effe29cd3f181046bc"
  // id: "624f69effe29cd3f181046be"
  // name: "KUB"
  // symbol: "KUB"

  _isValidSlug:any

  loadings:any = {
    submit: false,
    validateSlug: false,
    validateName: false,
  }


  constructor(
    public singletonService:SingletonService,
    public backendService:BackendService,
    private cd: ChangeDetectorRef,
    public router:Router,
    private route: ActivatedRoute,
    private renderImageUrlPipe:RenderImageUrlPipe,
  ) {
    this.slugCollection = this.route.snapshot.paramMap.get('slugCollection');
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
  }

  async ngOnInit() {
    this.supportChains = await this.singletonService.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)

    const currentChain = await this.singletonService.getCurrentChain()
    if(currentChain){
      this.blockchain = currentChain
    }else{
      const defaultChainAbbr = await this.singletonService.getChainAbbr()
      this.blockchain = await this.supportChains.find( it => it.networkAbbr == defaultChainAbbr)
    }

    const paymentTokens = await this.initPaymentTokensBySelectedChain(this.blockchain)

    this.categories = await this.backendService.getCollectionCategoriesInList()
    log.debug("this.categories => %o",this.categories)

    if(this.id){ // FOR EDIT
      await this.initFormValue()
    }
  }

  async initFormValue(){
    await this.backendService.getCollectionDetailsById(this.id).then(async (res:any) => {
      if(res){
        let collection = res
        log.debug("getCollectionDetailsById collection => %o",collection)

        this.addPreviewFromUrl(collection.logoImage,"input-logo-image")
        this.addPreviewFromUrl(collection.featureImage,"input-featured-image")
        this.addPreviewFromUrl(collection.bannerImage,"input-banner-image")

        this.name = collection.name
        this.slug = collection.slug
        this.description = collection.description
        this.category = collection.category

        this.linkwww = collection.links ? collection.links.www : ''
        this.linkdiscord = collection.links ? collection.links.discord : ''
        this.linkinstagram = collection.links ? collection.links.instagram : ''
        this.linkmedium = collection.links ? collection.links.medium : ''
        this.linktelegram = collection.links ? collection.links.telegram : ''

        this.creatorEarningsFee = collection.royaltyFee
        this.creatorWalletAddress = collection.royaltyAddress

        this.blockchain = this.singletonService.supportChains.find( it => it.networkAbbr == collection.chain)

        if(collection.paymentTokens && collection.paymentTokens.length){
          const paymentTokens = await this.initPaymentTokensBySelectedChain(this.blockchain)
          this.selected_paymentTokens = []
          collection.paymentTokens.forEach(symbol => {
            this.selected_paymentTokens.push(paymentTokens.find(it => it.symbol == symbol))
          });
          log.debug("xxxx this.selected_paymentTokens => %o",this.selected_paymentTokens)
        }

        this.isSensitiveContent = collection.isSensitiveContent
        this.featureAttr = collection.featureAttr

      }
    }).catch((resError) => {
      console.error("resError => %o",resError)
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }

  selectCategory(_category:any){
    let category = _category.toLowerCase()
    // this.singletonService.fire("success","success to select: " + category)
    this.category = category
  }

  selectDisplayTheme(_displayTheme){
    let displayTheme = _displayTheme.toLowerCase()
    this.displayTheme = displayTheme
  }

  selectBlockchain(chain){
    this.blockchain = chain
    this.initPaymentTokensBySelectedChain(chain)
  }

  async initPaymentTokensBySelectedChain(chain){
    log.debug("initPaymentTokensBySelectedChain chain => %o",chain)

    const paymentTokens:any = await this.backendService.getPaymentTokensByChainAbbr(chain.networkAbbr)
    log.debug("paymentTokens => %o",paymentTokens)

    this.selected_paymentTokens = await paymentTokens.filter(it => it.force == true)
    this.nonForcedPaymentTokens = await paymentTokens.filter(it => it.force != true)
    log.debug("this.nonForcedPaymentTokens => %o",this.nonForcedPaymentTokens)
    log.debug("this.selected_paymentTokens => %o",this.selected_paymentTokens)

    return paymentTokens

    // this.selected_paymentTokens = await this.backendService.getPaymentTokensByChainAbbr(this.blockchain.networkAbbr)
  }

  selectPaymentToken(paymentToken){
    let isDuplicate = this.selected_paymentTokens.find( it => it.symbol == paymentToken.symbol)
    log.debug("isDuplicate => %o",isDuplicate)
    if(isDuplicate){
      this.selected_paymentTokens = this.selected_paymentTokens.filter( it => it.symbol != paymentToken.symbol)
    }else{
      this.selected_paymentTokens.push(paymentToken)
    }
  }

  removePaymentToken(paymentToken){
    this.selected_paymentTokens = this.selected_paymentTokens.filter( it => it.symbol != paymentToken.symbol)
  }

  async showUsedSlugWarning(){
    let element:any = document.getElementById("used-slug-warning")
    element.hidden = false;
  }

  async hideUsedSlugWarning(){
    let element:any = document.getElementById("used-slug-warning")
    element.hidden = true;
  }

  async isSlugNotReservedWord(){
    let isSlugNotReservedWord = await this.singletonService.getReservedWordWithPartRoute("collection/",true,this.slug)
    // isSlugNotReservedWord ? await this.hideUsedSlugWarning() : await this.showUsedSlugWarning()
    return isSlugNotReservedWord
  }

  async isZeroLoyaltyFeeOrValidCreatorWalletAddress(){
    return  (this.creatorEarningsFee == 0 ? true : (await this.validateCreatorWalletAddress()))
  }

  async isValidSlugWithDelay(){
    const self = this

    clearTimeout(self.delayTimer);
    self.loadings.validateSlug = true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.isValidSlug()
        self.loadings.validateSlug = false
      }catch(err){
        self.loadings.validateSlug = false
      }
    }, 1000);

  }

  async isValidSlug(){
    log.debug("this.slug => %o",this.slug)
    let element:any = document.getElementById("slug-input")
    if(this.slug == undefined || this.slug.trim() == ''){
      log.debug("xxxxxxxxxxxxxxxxxxx undefined slug")
      this.slug = ''
      this._isValidSlug = undefined
      element.classList.remove("text-danger")
      element.parentElement.classList.remove("custom-invalid-2")
      return false
    }else{
      let isValidSizeSlug = await this.isValidSizeSlug()
      let isNotUsedSlug = await this.isNotUsedSlug()
      let isSlugNotReservedWord = await this.isSlugNotReservedWord()

      log.debug("isValidSizeSlug => %o",isValidSizeSlug)
      log.debug("isNotUsedSlug => %o",isNotUsedSlug)
      log.debug("isSlugNotReservedWord => %o",isSlugNotReservedWord)

      this._isValidSlug = isNotUsedSlug && isSlugNotReservedWord && isValidSizeSlug
      log.debug("this._isValidSlug => %o",this._isValidSlug)

      if(this._isValidSlug){
        element.classList.remove("text-danger")
        element.parentElement.classList.remove("custom-invalid-2")
      }else{
        element.classList.add("text-danger")
        element.parentElement.classList.add("custom-invalid-2")
      }

      return this._isValidSlug
    }
  }


  async isValidSizeSlug(){
    return this.slug.length <= 50
  }

  async isNotUsedSlug(){
    if(this.slugCollection == this.slug) return true // EDIT and use old slug

    let res:any = await this.backendService.checkIsUsedCollectionSlug(this.slug)
    log.debug("res => %o",res)
    if(res.success){
      return true
    }else{
      return false
    }
  }


  async canSubmit(){
    let canSubmit = false

    let condition01 = this.id ? true : this.logoImage
    let condition02 = this.name
    let condition03 = await this.isValidSlug() // this.slug
    let condition04 = await this.isZeroLoyaltyFeeOrValidCreatorWalletAddress()
    let condition05 = await this.isCreatorEarningsNoGreaterThan()
    let condition06 = this.category
    let condition07 = this.description


    log.debug("=========== debug (all must be true)")
    log.debug("condition01: ",condition01)
    log.debug("condition02: ",condition02)
    log.debug("condition03: ",condition03)
    log.debug("condition04: ",condition04)
    log.debug("condition05: ",condition05)
    log.debug("condition06: ",condition06)
    log.debug("condition07: ",condition07)


    canSubmit = (condition01 && condition02 && condition03 && condition04 && condition05 && condition06 && condition07)

    log.debug("=========== Result canSubmit: %o",canSubmit)
    log.debug("canSubmit: %o",canSubmit)

    return canSubmit
  }

  validateCreatorWalletAddress() {
    return (/^(0x){1}[0-9a-fA-F]{40}$/i.test(this.creatorWalletAddress));
  }

  hasCreatorEarningsFeeValue(){
    const number = Number(this.creatorEarningsFee)
    // log.debug("hasCreatorEarningsFeeValue number => %o",number)
    // if(number == 0){
    //   this.creatorWalletAddress = undefined
    // }
    return number
  }

  isCreatorEarningsNoGreaterThan(){
    // log.debug("hasCreatorEarningsFeeValue => %o",this.creatorEarningsFee)
    return Number(this.creatorEarningsFee) <= this.MAX_LOYALTY_FEE
  }

  async submit(){
    // this.singletonService.fire("info","creating...")
    if(await this.canSubmit()){
      this.loadings.submit = true
      let paymentTokens = this.selected_paymentTokens.map(it => it.symbol)
      log.debug("paymentTokens => %o",paymentTokens)

      let chain = this.blockchain.networkAbbr
      log.debug("chain => %o",chain)

      const formData = new FormData();
      if(this.logoImage) formData.append('logoImage', this.logoImage);
      if(this.featuredImage) formData.append('featureImage', this.featuredImage);
      if(this.bannerImage) formData.append('bannerImage', this.bannerImage);

      if(this.name) formData.append('name', this.name);
      if(this.slug) formData.append('slug', this.slug);

      if(this.description) formData.append('description', this.description);
      if(this.category) formData.append('category', this.category);

      if(this.linkwww)formData.append('linkwww', this.linkwww ? this.linkwww.replace(/(^\w+:|^)\/\//, '') : '' );
      if(this.linkdiscord)formData.append('linkdiscord', this.linkdiscord);
      if(this.linkinstagram)formData.append('linkinstagram', this.linkinstagram);
      if(this.linkmedium)formData.append('linkmedium', this.linkmedium);
      if(this.linktelegram)formData.append('linktelegram', this.linktelegram);

      formData.append('royaltyFee', (!this.creatorEarningsFee || this.creatorEarningsFee == '' ? 0 : this.creatorEarningsFee) );
      if(this.creatorEarningsFee > 0 && this.creatorWalletAddress) formData.append('royaltyAddress', this.creatorWalletAddress);

      formData.append('chain', chain);
      formData.append('paymentTokens', paymentTokens.toString() );

      // if(this.displayTheme) formData.append('displayTheme', this.displayTheme);
      formData.append('isSensitiveContent', this.isSensitiveContent);
      if(this.featureAttr) formData.append('featureAttr', this.featureAttr);

      if(this.singletonService.account) formData.append('ownerAddress', this.singletonService.account); // fixed extra
      formData.append('collectionType', 'internal'); // fixed extra

      new Response(formData).text().then(log.debug) // debug fromData

      if(this.id){ // EDIT ACTION
        // Remove validated fields server-side
        formData.delete('chain')
        formData.delete('ownerAddress')
        formData.delete('collectionType')

        this.backendService.updateCollection(formData,this.id).then((res:any) => {
          if(res){
            this.loadings.submit = false
            this.singletonService.fire("success","Success to update collection")
            this.jumpToMyCollections()
          }else{
            this.loadings.submit = false
            // this.singletonService.fire("error",res.message)
          }
        }).catch((resError) => {
          log.debug("xxxxxxxx eror on update collection: %o",resError)
          this.loadings.submit = false
        })
      }else{ // CREATE ACTION
        this.backendService.createCollection(formData).then((res:any) => {
          if(res){
            this.loadings.submit = false
            this.singletonService.fire("success","Success to create collection")
            this.jumpToMyCollections()
          }else{
            this.loadings.submit = false
            // this.singletonService.fire("error",res.message)
          }
        }).catch((resError) => {
          this.singletonService.fire("error",resError.title,resError.error)
          this.loadings.submit = false
        })
      }


    }else{
      this.singletonService.fire("error","Please fill out the required fields completely.")
    }
  }

  addPreviewFromFile(file,elementId){
    const reader = new FileReader();
    reader.onload = e => {
      log.debug("reader.result %o",reader.result)

      let previewElement:any = document.getElementById(elementId)
      previewElement.style.backgroundImage = "url("+reader.result+")";
    };
    reader.readAsDataURL(file);
  }

  addPreviewFromUrl(url,elementId){
    let previewElement:any = document.getElementById(elementId)
    let _url = this.renderImageUrlPipe.transform(url,'logo',true)
    previewElement.style.backgroundImage = "url("+_url+")";
  }

  clearPreview(elementId){
    let previewElement:any = document.getElementById(elementId)
    previewElement.style.backgroundImage = null;
  }

  async handleFileInput_logoImage(files: FileList){
    if(files.length){
      let file = files.item(0);
      let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      if(isNotSupportFileExtension){
        await this.clearFile_logoImage()
        return
      }
      let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      if(isOverSizeFile){
        await this.clearFile_logoImage()
        return
      }
      this.logoImage = file
      this.addPreviewFromFile(this.logoImage,"input-logo-image")
    }
  }

  async handleFileInput_featuredImage(files: FileList){
    if(files.length){
      let file = files.item(0);
      let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      if(isNotSupportFileExtension){
        await this.clearFile_featuredImage()
        return
      }
      let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      if(isOverSizeFile){
        await this.clearFile_featuredImage()
        return
      }
      this.featuredImage = file
      this.addPreviewFromFile(this.featuredImage,"input-featured-image")
    }

  }

  async handleFileInput_bannerImage(files: FileList){
    if(files.length){
      let file = files.item(0);
      let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      if(isNotSupportFileExtension){
        await this.clearFile_bannerImage()
        return
      }
      let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      if(isOverSizeFile){
        await this.clearFile_bannerImage()
        return
      }
      this.bannerImage = file
      this.addPreviewFromFile(this.bannerImage,"input-banner-image")
    }
  }

  toggleIsSensitiveChecked(){
    this.isSensitiveContent = !this.isSensitiveContent
  }

  jumpToMyCollections(){
    this.router.navigate(['/collections'])
  }

  clearFile_logoImage(){
    this.logoImage = undefined
    this.clearPreview("input-logo-image")
    this.clearInputValue("input-logo-image-instance")
  }

  clearFile_featuredImage(){
    this.featuredImage = undefined
    this.clearPreview("input-featured-image")
    this.clearInputValue("input-featured-image-instance")
  }

  clearFile_bannerImage(){
    this.bannerImage = undefined
    this.clearPreview("input-banner-image")
    this.clearInputValue("input-banner-image-instance")
  }

  clearInputValue(elementId){
    let element:any = document.getElementById(elementId)
    element.value = "";
  }

}
