import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-section',
  templateUrl: './campaign-section.component.html',
  styleUrls: ['./campaign-section.component.scss']
})
export class CampaignSectionComponent implements OnInit {

  //TODO: change when go to mainnet
  collectionSlug = "yes-nft-exclusive"

  constructor() { }

  ngOnInit(): void {
  }

  async goToCampaign(){

  }

}
