import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ---- <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  //                 use with input [(value)] only | bug with [(ngModel)]
  //

                          @Output() valueChange = new EventEmitter()
                          // @Output() ngModelChange = new EventEmitter()

  //
  //            [(value)]="name" for [value]="name" (valueChange)="name = $event"
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ---- <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.replace(/[^0-9]*/g, '');
    this._el.nativeElement.value = newValue;


    // ==========================================
      this.valueChange.emit(newValue);
      // this.ngModelChange.emit(newValue);
    // ==========================================


    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
