import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nft-offerings',
  templateUrl: './nft-offerings.component.html',
  styleUrls: ['./nft-offerings.component.scss']
})
export class NftOfferingsComponent implements OnInit {

  launchpads = []
  loading = true

  constructor(
    private _backendService:BackendService,
    private router:Router,
  ) { }

  async ngOnInit(){
    this.loading = true
    let current = (new Date()).getTime()/1000
    //console.log(current)
    let _launchpads = await this._backendService.getLaunchpadMarkets()
    this.launchpads = _launchpads.filter((lp:any) => {
      return lp.time < current
    })
    this.launchpads.reverse()
    this.loading = false
  }

  goToLaunchpad(launchpad){
    this.router.navigate(['nft/launchpad/' + launchpad.slug],{})
  }

}
