import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from "rxjs";

import { Logger } from '../../services/logger.service';
const log = new Logger('CountdownComponent');

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Input() dDay
  @Input() isShowFinishText = false
  @Input() finishTextDisplay = 'Finish'
  @Input() isRefreshPageWhenTimeUp = false

  private subscription: Subscription;

  public dateNow = new Date();
  //public dDay = new Date('Jan 01 2021 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  isJustCountDown = undefined

  constructor(
    // public router:Router,
    // private route: ActivatedRoute,
  ) {
    log.debug("this.finishTextDisplay : %o",this.finishTextDisplay)
    log.debug("this.isShowFinishText : %o",this.isShowFinishText)

  }


  private getTimeDifference () {
    this.timeDifference = this.dDay.getTime() - new  Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits (timeDifference) {
    if(timeDifference > 0){
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
      this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));

      this.isJustCountDown = true
    }else{
      if(this.isJustCountDown)
        if(this.isRefreshPageWhenTimeUp)
          location.reload();

      this.secondsToDday = 0
      this.minutesToDday = 0
      this.hoursToDday = 0
      this.daysToDday = 0
    }

  }

  ngOnInit() {

    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
