import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

import { Logger } from '../../services/logger.service';
const log = new Logger('CraftingStationSectionComponent');

@Component({
  selector: 'app-crafting-station-section',
  templateUrl: './crafting-station-section.component.html',
  styleUrls: ['./crafting-station-section.component.scss']
})
export class CraftingStationSectionComponent implements OnInit {

  @Input() stations:any = []
  @Input() isLoading = false

  constructor(
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router,
    ) { }

  async ngOnInit() {
    log.debug("this.stations => %o",this.stations)
  }

  goToCraftingStation(station){
    this.router.navigate(['crafting-station/' + station.id],{})
  }
}
