<section class="explore-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Intro -->
                <div class="intro text-center">
                    <span>Explore</span>
                    <h3 class="mt-3 mb-0">Exclusive Digital Assets</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                </div>
            </div>
        </div>
        <div class="explore-slides">
            <div class="swiper-container slider-mid items">
                <div class="swiper-wrapper">
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_1.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Walking On Air</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">Richard</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>1.5 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Domain Names</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">John Deo</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>2.7 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_3.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Trading Cards</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">Arham</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>2.3 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_4.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Industrial Revolution</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">Yasmin</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>1.8 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_5.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Utility</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">Junaid</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>1.7 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Slide -->
                    <div class="swiper-slide item">
                        <div class="card">
                            <div class="image-over">
                                <a href="item-details">
                                    <img class="card-img-top" src="assets/img/auction_6.jpg" alt="">
                                </a>
                            </div>
                            <!-- Card Caption -->
                            <div class="card-caption col-12 p-0">
                                <!-- Card Body -->
                                <div class="card-body">
                                    <a href="item-details">
                                        <h5 class="mb-0">Sports</h5>
                                    </a>
                                    <div class="seller d-flex align-items-center my-3">
                                        <span>Owned By</span>
                                        <a href="author">
                                            <h6 class="ml-2 mb-0">ArtNox</h6>
                                        </a>
                                    </div>
                                    <div class="card-bottom d-flex justify-content-between">
                                        <span>1.7 ETH</span>
                                        <span>1 of 1</span>
                                    </div>
                                    <a class="btn btn-bordered-white btn-smaller mt-3" href="create"><i class="icon-handbag mr-2"></i>Place a Bid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </div>
</section>