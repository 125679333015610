export const CHAINS: any = [
  {
    // chainId: 97,
    name: 'BINANCE SMART CHAIN',
    image: 'bsc',
    abbr: 'BSC',
    evmCompetible: true,
  },
  {
    // chainId: 96,
    name: 'BITKUB CHAIN',
    image: 'bkc',
    abbr: 'BKC',
    evmCompetible: true,
  },
  {
    // chainId: 137,
    name: 'POLYGON CHAIN',
    image: 'matic',
    abbr: 'MATIC',
    evmCompetible: true,
  },
  {
    // chainId: 137,
    name:'Klaytn Chain',
    image: 'klaytn',
    abbr:'KLAYTN',
    evmCompetible: false,
  }

];

export const enum TOKEN_TYPE {
  KUB,
  ERC20,
  ERC721,
  ERC1155
}

export const CONVERTABLE_FIAT_LABELS: any = [
  "THB",
  "USD",
  // "KRW", // เกาหลี
  // "RUB", // รัสเซีย
  // "CNY", // จีน
  // "VND", // เวียดนาม
]

export const CONVERTABLE_CRYPTO_LABELS: any = [
  "BTC",
  "BNB",
  "ETH"
]

export const FIAT_STATIC_FACTOR_MAP: any = {

"AED": 3.673044,
"AFN": 86.949905,
"ALL": 103.549884,
"AMD": 485.900642,
"ANG": 1.794936,
"AOA": 612.333001,
"ARS": 98.381358,
"AUD": 1.383308,
"AWG": 1.8,
"AZN": 1.687821,
"BAM": 1.666472,
"BBD": 2.019026,
"BDT": 85.34688,
"BGN": 1.66828,
"BHD": 0.376963,
"BIF": 1996,
"BMD": 1,
"BND": 1.350884,
"BOB": 6.894648,
"BRL": 5.271599,
"BSD": 0.99997,
"BTC": 0.000024677881,
"BTN": 73.608094,
"BWP": 11.185079,
"BYN": 2.502822,
"BYR": 19600,
"BZD": 2.015618,
"CAD": 1.282925,
"CDF": 2002.999758,
"CHF": 0.923395,
"CLF": 0.028487,
"CLP": 786.049751,
"CNY": 6.467404,
"COP": 3837.25,
"CRC": 624.291198,
"CUC": 1,
"CUP": 26.5,
"CVE": 94.350026,
"CZK": 21.675402,
"DJF": 177.720036,
"DKK": 6.341902,
"DOP": 56.590173,
"DZD": 136.87806,
"EGP": 15.7015,
"ERN": 15.004954,
"ETB": 46.459769,
"EUR": 0.852842,
"FJD": 2.099104,
"FKP": 0.72248,
"GBP": 0.73205,
"GEL": 3.105009,
"GGP": 0.72248,
"GHS": 6.074974,
"GIP": 0.72248,
"GMD": 51.597151,
"GNF": 9765.000097,
"GTQ": 7.734602,
"GYD": 208.846873,
"HKD": 7.78695,
"HNL": 24.210191,
"HRK": 6.393096,
"HTG": 98.494847,
"HUF": 300.670093,
"IDR": 14240.95,
"ILS": 3.20347,
"IMP": 0.72248,
"INR": 73.796904,
"IQD": 1460.5,
"IRR": 42190.000314,
"ISK": 129.880082,
"JEP": 0.72248,
"JMD": 148.161874,
"JOD": 0.709003,
"JPY": 109.223504,
"KES": 110.306691,
"KGS": 84.79797,
"KHR": 4087.000203,
"KMF": 419.850013,
"KPW": 900.000014,
"KRW": 1184.665008,
"KWD": 0.30095,
"KYD": 0.833227,
"KZT": 426.32505,
"LAK": 9824.999842,
"LBP": 1527.000026,
"LKR": 199.969329,
"LRD": 171.350029,
"LSL": 14.829686,
"LTL": 2.95274,
"LVL": 0.60489,
"LYD": 4.509783,
"MAD": 8.98898,
"MDL": 17.679006,
"MGA": 3915.000004,
"MKD": 52.448163,
"MMK": 1827.721235,
"MNT": 2839.237786,
"MOP": 8.018609,
"MRO": 356.999828,
"MUR": 42.393009,
"MVR": 15.405123,
"MWK": 805.000017,
"MXN": 20.136925,
"MYR": 4.184503,
"MZN": 63.82995,
"NAD": 14.829489,
"NGN": 411.497004,
"NIO": 35.149737,
"NOK": 8.67349,
"NPR": 117.77295,
"NZD": 1.427735,
"OMR": 0.385022,
"PAB": 0.99997,
"PEN": 4.112497,
"PGK": 3.530162,
"PHP": 50.140984,
"PKR": 168.705123,
"PLN": 3.941303,
"PYG": 6868.924388,
"QAR": 3.64105,
"RON": 4.220598,
"RSD": 100.205771,
"RUB": 73.0263,
"RWF": 997.5,
"SAR": 3.750282,
"SBD": 8.058101,
"SCR": 13.332992,
"SDG": 441.486806,
"SEK": 8.67946,
"SGD": 1.35262,
"SHP": 1.377398,
"SLL": 10470.000302,
"SOS": 584.999852,
"SRD": 21.394018,
"STD": 20697.981008,
"SVC": 8.749739,
"SYP": 1257.438219,
"SZL": 14.819809,
"THB": 33.379826,
"TJS": 11.338875,
"TMT": 3.5,
"TND": 2.802001,
"TOP": 2.260195,
"TRY": 8.627301,
"TTD": 6.792572,
"TWD": 27.760978,
"TZS": 2319.000029,
"UAH": 26.693308,
"UGX": 3536.789547,
"USD": 1,
"UYU": 42.708413,
"UZS": 10665.000031,
"VEF": 213830222338.07285,
"VND": 22760,
"VUV": 111.631732,
"WST": 2.560319,
"XAF": 558.905802,
"XAG": 0.044459,
"XAU": 0.000564,
"XCD": 2.70255,
"XDR": 0.704728,
"XOF": 560.999566,
"XPF": 102.221651,
"YER": 250.849356,
"ZAR": 14.85125,
"ZMK": 9001.191978,
"ZMW": 16.449066,
"ZWL": 321.999592,

}
