
<div *ngIf="isLoading" class="col-12 text-center py-5 my-5">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="container mt-5">
  <div class="row">
  <ng-container  *ngFor="let reveal of reveals">
    <div *ngIf="reveal.active" class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
      <div class="card p-0 w-100">
        <div class="image-over">
          <a (click)="goToRevel(reveal)">
            <img class="card-img-top" src="{{reveal.coverImage | renderImageUrl : 'feature'}}" alt="">
          </a>
        </div>
        <div class="card-caption p-4">
          <div class="card-body py-1 mb-2">
            <a (click)="goToRevel(reveal)" >
              <h5 class="tx-16 mb-2 text-truncate">{{reveal.name}}</h5>
            </a>
            <div>
              <hr class="my-3" />
              <div class=" tx-12">
                <span class="text-white-main font-weight-light tx-14">{{reveal.description}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
  </div>
</div>
