import { CurrencyService } from './../../services/currency.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, HostListener } from '@angular/core';

import { Logger } from '../../services/logger.service';
const log = new Logger('ActivityTableComponent'); // log.debug

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit {

  @Input() collectionId:any;
  @Input() user:any;
  @Input() eventTypes:any;

  @Input() tokenId:any;
  @Input() nftAddress:any;


  @Input() tab:any = 'activity';

  activities:any = []

  isFetchingActivities = false

  // == start lazy loader params ==
  limit:any = 10
  page:any = 1
  isLazyLoading = false
  loadedAll = false
  scrollEvent
  scroll_position
  isScrollBottom = false
  // == end lazy loader params ==

  latestTokenPrice
  supportChains:any = []

  constructor(
    private route: ActivatedRoute,
    public singletonService:SingletonService,
    private backendService:BackendService,
    private router: Router,
    private selectedFiltersService: SelectedFiltersService,
    public currencyService: CurrencyService,
  ) {
    // init Selected Filters
    this.selectedFiltersService.initSelectedFilters()
  }

  async ngOnInit(){
    log.debug("this.collectionId => %o",this.collectionId)
    log.debug("this.user => %o",this.user)
    log.debug("this.eventTypes => %o",this.eventTypes)
    log.debug("this.tab => %o",this.tab)


    this.supportChains = await this.singletonService.supportChains

    // let supportChains = await this.singletonService.supportChains
    // const defaultChainAbbr = await this.singletonService.getChainAbbr()
    // const blockchain = await supportChains.find( it => it.networkAbbr == defaultChainAbbr)
    // const paymentTokens:any = await this.backendService.getPaymentTokensByChainAbbr(blockchain.networkAbbr)

    this.latestTokenPrice = await this.currencyService.getLatestTokenPrice()
    log.debug("this.latestTokenPrice => %o",this.latestTokenPrice)

    this.handleScroll(); // enable lazyloader

    this.selectedFiltersService.selectedFiltersChangedSubject.subscribe(async (event)=>{
      await this.reloadData()
    })

    await this.reloadData()
  }

  ngOnDestroy(): void {
    log.debug("ngOnDestroy work!")
    window.onscroll = null;
  }

  resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.activities = []
  }

  async reloadData(){
    this.isFetchingActivities = true
    await this.resetDatas()
    await this.fetchActivity()
    this.isFetchingActivities = false
  }


  handleScroll(): void {
    window.onscroll = () => this.detectBottom();
  }

  async detectBottom(){
    let scroll_direction = await (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
    this.scroll_position = await (document.body.getBoundingClientRect()).top;
    if (scroll_direction == 'down'){ // downscroll
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.isScrollBottom = true
        log.debug("xxxxxxxxxxxxx detectBottom")


        if(this.tab == 'activity'){ // check for multi-tab pages

          if(!this.isLazyLoading){ // check for lazy loader is working
            await this.lazyLoader()
          }

        }else{
          log.debug("this tab isn't activity...")
        }
      }
    }else{ // upscroll
      // donothing
    }
  }

  async lazyLoader(){
    this.isLazyLoading = await true
    setTimeout(async () => {
      await this.page++
      if(!this.loadedAll) await this.fetchActivity()
      this.isLazyLoading = await false
    }, 1000);
  }

  async fetchActivity(){

    let collectionIds = []
    if(this.collectionId)  collectionIds = [this.collectionId]

    let eventTypesParams = this.selectedFiltersService.getEventTypesParams()
    let collectionsParams = this.selectedFiltersService.getCollectionsParams()
    let chainsParams = this.selectedFiltersService.getChainsParams()
    let sortParams = "createdAt:desc"

    let res:any = await this.backendService.getActivity(
      collectionsParams ? collectionsParams : collectionIds,
      this.user,
      eventTypesParams ? eventTypesParams : this.eventTypes,
      sortParams, // sortBy
      this.page,
      20, // limit
      this.tokenId,
      this.nftAddress,
      chainsParams,
    )

    let _activities = await Promise.all(res.results.map(async (activity) => {
      if(activity.order && activity.order.acceptedTokenAddr){
        activity.order.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(activity.order.acceptedTokenAddr,activity.order.chain)
        activity.order.acceptedTokenSymbol = await activity.order.acceptedTokenInfo.symbol
      }
      return activity
    }));

    if(res){
      log.debug("getActivity res => %o",res)
      this.activities = [...this.activities,..._activities]
      if(_activities.length < this.limit) this.loadedAll = true
      log.debug("getActivity this.page => %o",this.page)
      log.debug("getActivity this.activities => %o",this.activities)
    }

  }

  getImageSupportChainByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }

  jumpToNFTDetails(activity){
    // log.debug("jumpToNFTDetails activity => %o",activity)
    this.router.navigate(['/nft/' + activity.asset.nftAddress + '/' + activity.asset.tokenId],{
      queryParams: {
        chain: activity.asset.chain
      }
    })
  }

  async openTxScanUrl(transactionHash,networkAbbr){
    let supportChains = await this.singletonService.supportChains
    let chain = supportChains.find(it => it.networkAbbr == networkAbbr)
    let txScanUrl = chain.scanUrl + '/tx/' + transactionHash + '/token-transfers'
    window.open(txScanUrl, '_blank').focus();
  }

}
