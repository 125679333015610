import { SingletonService } from './../services/singleton.service';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment'

@Pipe({
  name: 'unixTimeStampToDateStringFormat'
})
export class UnixTimeStampToDateStringFormatPipe implements PipeTransform {

  constructor(
    private singletonService:SingletonService,
  ){

  }
  transform(unixTimeStamp: any, ...args: unknown[]): unknown {
    let _formatDateTime:any = args[0]
    if(!_formatDateTime) _formatDateTime = this.singletonService.GLOBAL_DATETIME_FORMAT

    return moment(unixTimeStamp*1000).format(_formatDateTime);
  }

}
