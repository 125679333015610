import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('ToUpperCasePipe'); // log.debug

@Pipe({
  name: 'toUpperCase'
})
export class ToUpperCasePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    // log.debug("toUpperCase value %o",value)
    if(Array.isArray(value)){
      return value.map(it => it.toUpperCase())
    }
    return value ? value.toUpperCase() : ''
  }

}
