import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SingletonService } from 'src/app/services/singleton.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss']
})
export class RedeemComponent implements OnInit {

  constructor(
    private singletonService:SingletonService,
    private router:Router,
  ) { }

  ngOnInit() {
  }

  goToRedeemCampaign(){
    this.router.navigate(['/redeem/campaign'])
  }

}
