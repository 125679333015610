<div class="main">

  <app-header-section></app-header-section>

  <div class="mx-auto w-100 pt-5" style="max-width: 750px;">
    <h1 class="pt-5 my-5">Icons Theme</h1>

    <app-icons></app-icons>
  </div>

  <app-modal-menu></app-modal-menu>

</div>
