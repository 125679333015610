import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Logger} from '../../services/logger.service';
import {NftService} from "../../services/nft.service";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {ethers} from "ethers";
import {SingletonService} from "../../services/singleton.service";
import {NftRevealResultModalComponent} from "../nft-reveal/nft-reveal-result-modal/nft-reveal-result-modal.component";
import {RedeemConfirmModalComponent} from "./redeem-confirm-modal/redeem-confirm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

const log = new Logger('NftGashaComponent');


@Component({
  selector: 'app-nft-gasha',
  templateUrl: './nft-gasha.component.html',
  styleUrls: ['./nft-gasha.component.scss']
})
export class NftGashaComponent implements OnInit {

  id;
  gasha;
  isLoading = true;
  isApproveLoading = false;
  spinning = false;
  shouldApprove = true;
  redeemLoading =false

  resultTokenId
  resultMeta
  assetTokenIds
  assets
  balance


  constructor(
    public singletonService:SingletonService,
    public backendService: BackendService,
    public router: Router,
    public route: ActivatedRoute,
    public nftService: NftService,
    public freeCityContractService:FreeCityContractService,
    public modalService: NgbModal
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  async checkAllowance(){
    this.shouldApprove = !(await this.freeCityContractService.checkTokenApprovalFor(this.gasha.paymentTokenAddress, this.gasha.paymentAmount, this.gasha.address))
  }

  async approve(){
    this.isApproveLoading = true;
    this.freeCityContractService.approveTokenTo(this.gasha.paymentTokenAddress, this.gasha.address).then(async (transaction) => {
      let receipt = await transaction.wait()
      log.debug("approve events ", receipt.events)
      let event = receipt.events.find((e) => e.event === 'Transfer')
      await this.fetchAssets()
      await this.fetchBalance()
      await this.checkAllowance()
      await this.singletonService.fire('success', 'approve success', 'Success Approval ')
    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'approve failed', 'ERROR: ' + error.data.message)
    }).finally(() => {
      this.isApproveLoading = false
    })
  }

  async ngOnInit(){
    this.gasha = await this.backendService.getGashaById(this.id)
    await this.fetchAssets();
    await this.fetchBalance();
    await this.checkAllowance()
    this.isLoading = false

    //this.resultTokenId = 40
    //this.resultMeta = await this.nftService.getNFTMeta(this.gasha.address, this.resultTokenId)
  }

  async fetchAssets() {
    this.assetTokenIds = await this.nftService.getOwnNFTIds(this.gasha.address)
    this.assets = await this.nftService.getNftInfos(this.gasha.address, this.assetTokenIds, '')
    log.debug("_asset => %o", this.assets)
  }

  async fetchBalance(){
    this.balance = ethers.utils.formatUnits(await this.freeCityContractService.getBalance(this.gasha.paymentTokenAddress),18)
  }

  async spin(){
    this.spinning = true;
    this.nftService.spin(this.gasha).then(async (transaction) => {
      let receipt = await transaction.wait()
      log.debug("spin events ", receipt.events)
      let event = receipt.events.find((e) => e.event === 'Spun')
      this.resultTokenId = event.args.tokenId
      this.resultMeta = await this.nftService.getNFTMeta(this.gasha.address, this.resultTokenId)
      await this.fetchAssets()
      await this.fetchBalance()
      await this.singletonService.fire('success', 'spin success', 'Success spinning')
    }).catch((error) => {
      log.error("error")
      log.error(error)
      this.resultMeta = undefined
      this.resultTokenId = undefined
      if(error.data && error.data.message){
        this.singletonService.fire('error', 'spinning failed', 'ERROR: ' + error.data.message)
      }else{
        this.singletonService.fire('error', 'spinning failed please try again')
      }
    }).finally(() => {
      this.spinning = false
    })

  }

  async redeem(nft){
    let tokenId = nft.tokenId
    let redeemAmount = ethers.utils.formatUnits(await this.nftService.getRedeemAmountByTokenId(this.gasha, tokenId),18)
    const modalInstance = this.modalService.open(RedeemConfirmModalComponent, {
      centered: true,
      windowClass: 'modal modal-primary',
      size: 'lg',
      scrollable: false,
      backdrop: 'static',

    });
    modalInstance.componentInstance.gasha = this.gasha
    modalInstance.componentInstance.tokenId = tokenId
    modalInstance.componentInstance.amount = redeemAmount
    modalInstance.componentInstance.tokenName = this.gasha.paymentTokenName
    modalInstance.componentInstance.image = nft.image
    modalInstance.componentInstance.dataService.subscribe((data) => {
      log.debug("data return from modal = "+data)
      if(data == 'accepted'){
        this.redeemLoading = true;
        this.nftService.redeem(tokenId, this.gasha).then(async (transaction) => {
          let receipt = await transaction.wait()
          log.debug("Redeemed events ", receipt.events)
          let event = receipt.events.find((e) => e.event === 'Redeemed')
          await this.fetchAssets()
          await this.fetchBalance()
          await this.singletonService.fire('success', 'redeem success', 'Success redeem')
        }).catch((error) => {
          log.error("error")
          log.error(error)
          this.resultMeta = undefined
          this.resultTokenId = undefined
          if (error.data && error.data.message) {
            this.singletonService.fire('error', 'redeem failed', 'ERROR: ' + error.data.message)
          } else {
            this.singletonService.fire('error', 'redeem failed please try again')
          }
        }).finally(() => {
          this.redeemLoading = false
        })
      }else{
        this.redeemLoading = false
      }

    })
  }


}
