import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-music-box',
  templateUrl: './music-box.component.html',
  styleUrls: ['./music-box.component.scss']
})
export class MusicBoxComponent implements OnInit {

  @Input("musicPath") musicPath:any
  @Input("volumn") volumn:any = 1

  constructor() { }

  ngOnInit(): void {
    this.initBGMVolumn()
  }

  initBGMVolumn(){
    let vid:any = document.getElementById("sound")
    vid.volume = this.volumn;
  }


}
