import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrencyService} from "../../services/currency.service";

@Component({
  selector: 'app-nft-reveals',
  templateUrl: './nft-reveals.component.html',
  styleUrls: ['./nft-reveals.component.scss']
})
export class NftRevealsComponent implements OnInit {

  isLoading = true;
  reveals = []

  constructor(
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router,
  ) {

  }

  async ngOnInit() {
    this.reveals = await this.backendService.getReveals()
    //this.reveals = this.reveals.reverse()
    this.reveals = this.reveals.sort(function(a, b){
      return ((b.id-a.id))
    })
    this.isLoading = false
  }

}
