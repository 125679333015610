import { Pipe, PipeTransform } from '@angular/core';
import { ToCappitalPipe } from './to-cappital.pipe';

@Pipe({
  name: 'converterStateTypeToText'
})
export class ConverterStateTypeToTextPipe implements PipeTransform {
  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}
  transform(value: any, ...args: any[]): unknown {

    switch (value) {
      case 0:
        return "Idle";
      case 1:
        return "Working";
      default:
        return "UNKNOWN";
    }
  }

}
