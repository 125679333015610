<div class="main">
  <app-header-section></app-header-section>

  <section class="nft-gashas">

    <h2 class="text-center title-h2">NFT Gachapon</h2>
    <p class="text-center tx-14">Our web3 gachapon lets users use a specific token to purchase a chance at a random NFT. <br/>The smart contract selects an NFT from a set of possibilities and mints it to the user's wallet.</p>


    <div *ngIf="isLoading" class="col-12 text-center py-5 my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="container mt-5">
      <div class="row">
        <ng-container *ngFor="let gasha of gashas">
          <div *ngIf="gasha.active"
               class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
            <div class="card p-0 w-100">
              <div class="image-over">
                <a (click)="goToGasha(gasha)">
                  <img class="card-img-top" src="{{gasha.coverImage | renderImageUrl : 'feature'}}" alt="">
                </a>
              </div>
              <div class="card-caption p-4">
                <div class="card-body py-1 mb-2">
                  <a (click)="goToGasha(gasha)">
                    <h5 class="tx-16 mb-2 text-truncate">{{gasha.name}}</h5>
                  </a>
                  <div>
                    <hr class="my-3"/>
                    <div class=" tx-12">
                      <span class="text-white-main font-weight-light tx-14">{{gasha.description}}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>


  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
