import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-section',
  templateUrl: './create-section.component.html',
  styleUrls: ['./create-section.component.scss']
})
export class CreateSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
