// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3000',
  apiUrl: 'https://api.freecity.finance',
  fiatApiEndpoint: 'https://api.freecity.finance/v1/supports/latest/fiat',
  currencyApiEndpoint: 'https://api.testnet.freecity.finance/v1/supports/latest/prices',
  renderImageUrlPrefix: "https://freecity-test.s3.ap-southeast-1.amazonaws.com/",
  renderImageWithResizeUrlPrefix: "https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/",

  recaptchaSiteKey: '6Ld8PzkkAAAAAFimr2MU_HQRU57qE2KIOGunVL-p', // prod

  chainId:25925,
  networkAbbr:"bkctestnet",
  use:'rpc', // rpc or wss
  rpc:'https://rpc-testnet.bitkubchain.io',
  wss:'wss://wss-testnet.bitkubchain.io',

  blockPerDay:17280,
  features : {
    WATCH_LIST:false,
    DEMO_MODE:true,
  },


  addressBook:[
    {
      chainId: 25925,
      chainName: 'bkctestnet',
      FeeProvider_Address : "0xd2125Ef06CAC74480E44F1477b19735c75B54f89", // changed
      FreeCityMarket_Address : "0x9f2c944749AE3B8802EC3f16A322095614EE79Ab", // changed
      FreeCityNFT_Address : "0x83a0b2FbBac1b71ABec876e4A84eF02Ab1694047", // changed
      FreeCityMinter_Address : "0xF2e2F7CB2A0457093f1eB682591105f121E86C7f", // changed
      MockNFT721_Address : "0x0000000000000000000000000000000000000000", // ** undefined **

      P2PMARKET : "0x92aBD4f8554Cb47c8fA8c0fdB6462E38dF3Eb586", // changed
      MARKET : "0x9f2c944749AE3B8802EC3f16A322095614EE79Ab", // changed
      FreeCityMiningPool1: "0x1829df2f06b83bfB8BC177BeC1bB29c132df3bD5", // changed
      FreeCityMiningPool2: "0x0000000000000000000000000000000000000000", // ** undefined **
      FreeCityProfileManager:"0x7b2900aF5a6e91d9d3354DC92ce52a58752215F7", // changed
      HashPowerStorage:"0x7E9b952ED7E87AD616c4E4f24Ce85434060F6C4B", // changed
      KUSDT:"0xd9487684F1E1ee9C3e557e18E310002dE784b770", // -- KUSDT Token changed
      MockNFT:"0xE7248a98379Ef7be07496e4CF86e38489D92E0d6", // changed
      OtherNFT:"0x0000000000000000000000000000000000000000", // ** undefined **
      MockToken:"0xE10536EfA01274ac3759779E1F41466aa5CCC754", // -- PUNK Token changed
      MockToken2:"0xaDd6ee1D54265237260AA27DB9F012Fd0Ed6eE0e", //  -- BEAN Token changed

      Postman:"0x36a4323EE190aD80c6447AAE0D199F368340a709",

      unboxers : [
        {
          id:1,
          active:true,
          name:'Eclipse Quest Summoning Book reveal',
          description:'you can unbox or reveal your book here',
          coverImage:'https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/collections/eclipse-quest-pack/feature.png',
          address:'0x5092DdcFDCCD62A3e476AE40068bA001109CeB73',
          asset:'0xe5CE0Ef20FD15Fd482F5fA0e5d87D4eCA174b4Dd',
          reward:'0x39F29aef2bA8E3932D7edF10FA9BF4aD0F67e1d6',
          bannerImage:'https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/collections/eclipse-quest-pack/banner.png',
          fee:'0.05'
        }
      ],

      gashas : [

        {
          id:2,
          version:2,
          active:true,
          name: "XRB Farmer Gacha",
          description:"Use Farmer token to spin",
          address:'0x72ac229E8b900ee824A1B296D8Ac4Cbf8f31ADF4',
          rewardNFT:'0x4d3435b141F97bD1AE747edF3D9a8ADd07F4abB6',
          paymentTokenAddress:'0x9db79d3e13067f4CDf9A86DF3Baa8Be3AFF398bd',
          paymentTokenName: 'Farmer Token',
          paymentAmount:1,
          fee:'0.5',
          redeemFee:'0',
          bannerImage: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/campaigns/xrb/banner.jpg',
          coverImage:'https://github.com/bl0ck0x88/freecity-assets/blob/main/campaigns/xrb/farmer.jpg?raw=true',
          spinImages:[
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/3.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/10.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/20.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/40.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/60.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/80.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/farmer/100.png?raw=true",
          ]
        },
        {
          id:3,
          version:2,
          active:true,
          name: "XRB Strength Gacha",
          description:"Use Strength token to spin",
          address:'0x7b0528e4D2344FA8f0FA239CC4dDC7a3E67D1480',
          rewardNFT:'0x0C38B143CAb05b624683aA799c46139005dfc34F',
          paymentTokenAddress:'0x24EBe9216f83Ca9aD0B86B72ee6E37007e9150bb',
          paymentTokenName: 'Strength Token',
          paymentAmount:1,
          fee:'0.5',
          redeemFee:'0',
          bannerImage: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/campaigns/xrb/banner.jpg',
          coverImage:'https://github.com/bl0ck0x88/freecity-assets/blob/main/campaigns/xrb/strength.jpg?raw=true',
          spinImages:[
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/1.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/2.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/3.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/4.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/5.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/6.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/strength/7.png?raw=true",
          ]
        },
        {
          id:4,
          version:2,
          active:true,
          name: "XRB Intelligence Gacha",
          description:"Use Intelligence token to spin",
          address:'0xA722A765312C7160F0F23feF151bbBfADd75236B',
          rewardNFT:'0x8580204EE87BFe726071E536E2D9d4271a1597E5',
          paymentTokenAddress:'0x64c7A742EbbA29bA964E813ab9E116F3076cDeB2',
          paymentTokenName: 'Intelligence Token',
          paymentAmount:1,
          fee:'0.5',
          redeemFee:'0',
          bannerImage: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/campaigns/xrb/banner.jpg',
          coverImage:'https://github.com/bl0ck0x88/freecity-assets/blob/main/campaigns/xrb/intelligence.jpg?raw=true',
          spinImages:[
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/1.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/2.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/3.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/4.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/5.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/6.png?raw=true",
            "https://github.com/bl0ck0x88/freecity-assets/blob/main/gacha/intelligence/7.png?raw=true"
          ]
        },
        {
          id:5,
          version:1,
          active:true,
          name: "XRB Egg Gacha",
          description:"Use pet token to spin XRB egg NFT",
          address:'0x42BADdf7d410c04FADe7376A56d30dDe5E22ed17',
          paymentTokenAddress:'0x745BC898F8238aEE2B5019e3d709DB8457ae2A61',
          paymentTokenName: 'Pet Token',
          paymentAmount:1,
          fee:'1.0',
          redeemFee:'1.0',
          bannerImage: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/campaigns/xrb/i.jpg',
          coverImage:'https://gateway.freecity.finance/ipfs/QmessNsMn6PYJ9bPQGBbrfpgmLb75d4suX1dhSF98yaRhz',
          spinImages:[
            "https://gateway.freecity.finance/ipfs/QmessNsMn6PYJ9bPQGBbrfpgmLb75d4suX1dhSF98yaRhz",
            "https://gateway.freecity.finance/ipfs/QmZj1bHVpqvG2uc9RdiZHbCNL6gHWmX6TyDGcE758ZjaLE",
            "https://gateway.freecity.finance/ipfs/QmcvahYoyPN5bQdAmfTUfmApqMjCdr2x4aHYn8WHqBeEW2",
            "https://gateway.freecity.finance/ipfs/QmTgKW2Egn7E7Pt9McTWdwTWTnnCNW69tTJz734c9mx1k6",
            "https://gateway.freecity.finance/ipfs/QmasiLmqMgHgPnCMim1eWYhCkPExJy5g3oBmH9pQxHiseL",
            "https://gateway.freecity.finance/ipfs/QmTHgGdfkFPbNeHaP89MTKzDCbY3PgekUFc6qSWqW1TRE6",
            "https://gateway.freecity.finance/ipfs/QmZHsNcKBrrHSyUNGyNcaHbXmQ4171BiX6Fb8Bgh4tndKi",
            "https://gateway.freecity.finance/ipfs/QmZZRBzi4vaNhkgdWBg8yf7BQ9fV31kmktPmZcz9pQyfCd",
            "https://gateway.freecity.finance/ipfs/QmXBRtVVCMVycbRZqoKokVVSJxHAj6Ad8agRe3NTbnarat",
            "https://gateway.freecity.finance/ipfs/QmZgvaxjGazvzjjPXqzZf7M8ZFWhp5HF3o5DdyrX1pv22b",
            "https://gateway.freecity.finance/ipfs/QmULDHFyv5wr5dQAXHDn2M9DsAdtSWMPj5PXmX5KCEEoZ6",
            "https://gateway.freecity.finance/ipfs/QmWK6C4PUt1iNzQgiLYYAmzpEbtr8GL93kK5Mj58ToLXbv",
            "https://gateway.freecity.finance/ipfs/QmYDfuzbtP8pXj9gApMrUWF6nKxe1UFQoy3qGykRtkbLvi",
            "https://gateway.freecity.finance/ipfs/QmZCCe3ZVb7PCBK19VsWfTenGoTJVphraWuwikoJZ8Qdjq",
            "https://gateway.freecity.finance/ipfs/QmTWXJAdh8Tm27Vk8fnuRj9PTpd7cbt8tpMjq1MkH8SWX1",
            "https://gateway.freecity.finance/ipfs/QmZhGC4A3vsrkTRHRbW4sDrBs3GtDxqPjXXMS3qWpDSRrw",
            "https://gateway.freecity.finance/ipfs/QmXRv7bBS7oJAz3L9Uyhhu8vfZ4k4LhPdWkcVDV64mo89j",
            "https://gateway.freecity.finance/ipfs/QmYy8JjGhb5sTGwwc4G1DE6yJV59DkT9ZVyYPRNE18rSMx",
            "https://gateway.freecity.finance/ipfs/QmaJsnY87WaFko8nHSH1JA2vjhp1tDt3P6rpbdZNUGm5DU",
            "https://gateway.freecity.finance/ipfs/QmS5RzLDFLmKKayGcUxjAjMHrKnEZiHUXXzui3srsyGNye",
          ]
        },
      ],

      whitelistMarkets : [
        {
          id:1,
          active:true,
          name:'Dig Dragon Medal',
          description:'Medal can reveal to Dig Dragon Character.',
          coverImage:'https://euq1tlffv3.execute-api.ap-southeast-1.amazonaws.com/production/collections/digdragon/31b3bbafaa6b31965705fc613a7852a6.jpg',
          address:'0x981cbfA0886f4D674BF7d0E90F73937F90df5059',
          bannerImage:'https://euq1tlffv3.execute-api.ap-southeast-1.amazonaws.com/production/collections/digdragon/03cc33c53a078187ff0bb6c34a6d7e47.jpg',
          feePercent:'2.5',
          paymentToken:'0xd9487684F1E1ee9C3e557e18E310002dE784b770',
          media:'image',
        }
      ],

      converters: [],

      craftingStations: [
        {
          id: 1,
          active: true,
          name: 'Eclipse Quest Crafting Station',
          description: 'You can refine item or high level material here',
          coverImage: 'https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/collections/eclipse-quest-pack/feature.png',
          bannerImage: 'https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/collections/eclipse-quest-pack/banner.png',

          stationAddress: "0x4aF7e642873Ff38C6f35EF91031f7fC9C7297232",
          recipes: [
            {
              id: 0,
              name: "Super Token 1"
            },
            {
              id: 1,
              name: "Super NFT 1 "
            },
            {
              id: 2,
              name: "Super Token 2"
            },
            {
              id: 3,
              name: "Super NFT 2"
            }
          ]
        },
        {
          id: 2,
          active: true,
          name: 'XRB Refine Station',
          description: 'You can refine Capsule to Frystal here',
          coverImage: 'https://app.freecity.finance/assets/img/pools/xrb-small.png',
          bannerImage: 'https://app.freecity.finance/assets/img/pools/xrb-small.png',
          stationAddress: "0x919283356860F7e4c8F923199ebCf986690CaC1F",
          minterAddress: "0x16533197217bc00850cF41a1d57cc032c2273ddF",
          converterAddress: "0x93D513184a92C31B35aD5Bdc09518Ff446Dfb6C7",
          recipes: [
            {
              id: 0,
              name: "1.75 Frystals (Using 100 Capsules)"
            },

          ]
        }
      ],

      launchpads: [
        {
          id: '1',
          slug:'punkkub-1',
          active:true,
          name:'Punkkub #1',
          description:'Buy punkkub nft here',
          coverImage:'https://euq1tlffv3.execute-api.ap-southeast-1.amazonaws.com/production/collections/punk-mystery-pack-1/7212234566a6c9e43d12dd8996198095.jpg?width=600&height=400',
          address:'0x271f7015bdc053b539225fDD2f68b203A02312ca',
          bannerImage:'https://euq1tlffv3.execute-api.ap-southeast-1.amazonaws.com/production/collections/punk-mystery-pack-1/e242cfe9e7d7b6837f0df5cb498285ac.png?width=1400&height=400',
          feePercent:'2.5',
          paymentToken:'0xd9487684F1E1ee9C3e557e18E310002dE784b770',
          media:'image',
          shuffle:true
        },
        {
          id: '2',
          slug:'punkkub-2',
          active:true,
          name:'Punkkub #2',
          description:'Buy punkkub nft here',
          coverImage:'https://euq1tlffv3.execute-api.ap-southeast-1.amazonaws.com/production/collections/reward-box/a9cda2bee6c0304f3c52129dd29c30c4.png?width=600&height=400',
          address:'0x7534A261ee2d50373Eb44416983862352FE661D2',
          bannerImage:'https://freecity-prod.s3.ap-southeast-1.amazonaws.com/collections/reward-box/d2edac0adece8652dbbbfe871db7d702.gif',
          feePercent:'2.5',
          paymentToken:'0xd9487684F1E1ee9C3e557e18E310002dE784b770',
          media:'image',
          shuffle:true
        }
      ]

      // [Testnet ENV] - Private Testnet Address (Internal Test)

      // Deployer Wallet
      // 0x1E9fab3842660153950AAaAD83147cF2424A5EB9
      // Fee Provider
      // 0xd2125Ef06CAC74480E44F1477b19735c75B54f89 // used
      // FreeCityMarket
      // 0x9f2c944749AE3B8802EC3f16A322095614EE79Ab // used
      // FreeCityNFT
      // 0x83a0b2FbBac1b71ABec876e4A84eF02Ab1694047 // used
      // FreeCityMinter
      // 0xF2e2F7CB2A0457093f1eB682591105f121E86C7f // used
      // Mock Token
      // 0xE10536EfA01274ac3759779E1F41466aa5CCC754 // used
      // Mock KUSDT
      // 0xaDd6ee1D54265237260AA27DB9F012Fd0Ed6eE0e // used
      // Mock NFT
      // 0xE7248a98379Ef7be07496e4CF86e38489D92E0d6 // used
      // HashPower Storage
      // 0x7E9b952ED7E87AD616c4E4f24Ce85434060F6C4B // used
      // Profile Manager
      // 0x7b2900aF5a6e91d9d3354DC92ce52a58752215F7 // used
      // Mining Pool
      // 0x1829df2f06b83bfB8BC177BeC1bB29c132df3bD5 // used
      // P2P Market
      // 0x92aBD4f8554Cb47c8fA8c0fdB6462E38dF3Eb586 // used





    },
    {
      chainId: 80001, // changed
      chainName: 'polygontestnet', // changed
      FeeProvider_Address : "0x0A09F86CEB52CF7AFb86442f1c8aC2D1c066920c", // changed
      FreeCityMarket_Address : "0xa88d41e09540AD25b3edEc70aAb5d6d7601F2D5A", // changed
      FreeCityNFT_Address : "0xeE4B5088F8F0134d4711DA222ad0989A768a37fA", // changed
      FreeCityMinter_Address : "0xFFa3B713458dFD997139e6B0C085F2C035DC05f6", // changed
      MockNFT721_Address : "0x17a5a8AD450473435e513Bd5B026194456eB2B58", // changed

      P2PMARKET : "0x82725cbc5a4E7102792e3bb21182081330DACCca", // changed
      MARKET : "0xa88d41e09540AD25b3edEc70aAb5d6d7601F2D5A", // changed
      FreeCityMiningPool1: "0xAbEaa7fE8Cc8E0214d399E0e9c73a8313B4D481B",
      FreeCityMiningPool2: "0x8789DAe5cF29dBb704fD98FeDC4966EE5A39EC85",
      FreeCityProfileManager:"0xaDd23E2Da536A3F702eb1c538a165d15f84c6881", // changed
      HashPowerStorage:"0x81597f48A5CCDe9529E3678ed739F269D9C995d9", // changed
      KUSDT:"0xD9C3Bf7b3Aa1E81c35F319b10Ec5a35cbDCe464c", // changed
      MockNFT:"0x17a5a8AD450473435e513Bd5B026194456eB2B58", // changed
      OtherNFT:"0x31aeE59fe6e2613ca9416F4D7B3511aD70aFC065", // changed
      MockToken:"0x851bEAfBbdE676Dcb0d92F3aa0E50cB8d1351dC7", // changed


      // P2P FreeCityMarket Address : 0x82725cbc5a4E7102792e3bb21182081330DACCca
      // P2P KUSDT Address : 0xD9C3Bf7b3Aa1E81c35F319b10Ec5a35cbDCe464c
      // P2P KKUB Address : 0x5678Aa8b95B58175f1c6D1A2e743601A21015277
      // P2P PUNK Address : 0xa49006E38Ef17935eD6B173DAaCd7A21cb0657Fe
      // P2P BEAN Address : 0x95983E50b590e997581b0F0563A79dfEa1788632

      // Deploying the contracts with the account: 0x1E9fab3842660153950AAaAD83147cF2424A5EB9
      // FeeProvider Address : 0x0A09F86CEB52CF7AFb86442f1c8aC2D1c066920c
      // FreeCityMarket Address : 0xa88d41e09540AD25b3edEc70aAb5d6d7601F2D5A
      // FreeCityNFT Address : 0xeE4B5088F8F0134d4711DA222ad0989A768a37fA
      // FreeCityMinter Address : 0xFFa3B713458dFD997139e6B0C085F2C035DC05f6

      // // mining
      // token Address : 0x851bEAfBbdE676Dcb0d92F3aa0E50cB8d1351dC7
      // kusdt Address : 0xC0aAD7803120a922605Eea2B45E3Ff35AE3Bc3f9
      // nft Address : 0x17a5a8AD450473435e513Bd5B026194456eB2B58
      // otherNFT Address : 0x31aeE59fe6e2613ca9416F4D7B3511aD70aFC065
      // hpStorage Address : 0x81597f48A5CCDe9529E3678ed739F269D9C995d9
      // profileManager Address : 0xaDd23E2Da536A3F702eb1c538a165d15f84c6881
      // pool Address : 0xC1673572AbE099C31Db01d5dE44Faf01f7EE5Ed4
      // otherPool Address : 0x6a600b1DD8C6A4aFeb79562c158241d9680f2438
    },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
