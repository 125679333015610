import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Logger} from '../../services/logger.service';
import {NftService} from "../../services/nft.service";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {ethers} from "ethers";
import {SingletonService} from "../../services/singleton.service";
import {NftRevealResultModalComponent} from "../nft-reveal/nft-reveal-result-modal/nft-reveal-result-modal.component";
//import {RedeemConfirmModalComponent} from "./redeem-confirm-modal/redeem-confirm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RedeemConfirmModalComponent} from "../nft-gasha/redeem-confirm-modal/redeem-confirm-modal.component";

const log = new Logger('NftGashaComponent');


@Component({
  selector: 'app-nft-gasha-v2',
  templateUrl: './nft-gasha-v2.component.html',
  styleUrls: ['./nft-gasha-v2.component.scss']
})
export class NftGashaV2Component implements OnInit {

  id;
  gasha;
  isLoading = true;
  isApproveLoading = false;
  spinning = false;
  shouldApprove = true;
  redeemLoading =false

  resultTokenId
  resultMeta
  assetTokenIds
  assets
  balance


  constructor(
    public singletonService:SingletonService,
    public backendService: BackendService,
    public router: Router,
    public route: ActivatedRoute,
    public nftService: NftService,
    public freeCityContractService:FreeCityContractService,
    public modalService: NgbModal
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  async checkAllowance(){
    const _price = ethers.utils.parseUnits(''+this.gasha.paymentAmount, 'ether' )
    this.shouldApprove = !(await this.freeCityContractService.checkTokenApprovalFor(this.gasha.paymentTokenAddress, _price, this.gasha.address))
  }

  async approve(){
    this.isApproveLoading = true;
    this.freeCityContractService.approveTokenTo(this.gasha.paymentTokenAddress, this.gasha.address).then(async (transaction) => {
      let receipt = await transaction.wait()
      log.debug("approve events ", receipt.events)
      let event = receipt.events.find((e) => e.event === 'Transfer')
      await this.fetchAssets()
      await this.fetchBalance()
      await this.checkAllowance()
      await this.singletonService.fire('success', 'approve success', 'Success Approval ')
    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'approve failed', 'ERROR: ' + error.data.message)
    }).finally(() => {
      this.isApproveLoading = false
    })
  }

  async ngOnInit(){
    this.gasha = await this.backendService.getGashaById(this.id)
    await this.fetchAssets();
    await this.fetchBalance();
    await this.checkAllowance()
    this.isLoading = false

    //this.resultTokenId = 40
    //this.resultMeta = await this.nftService.getNFTMeta(this.gasha.address, this.resultTokenId)
  }

  async fetchAssets() {
    this.assetTokenIds = await this.nftService.getOwnNFTIds(this.gasha.rewardNFT)
    this.assets = await this.nftService.getNftInfos(this.gasha.rewardNFT, this.assetTokenIds, '')
    log.debug("_asset => %o", this.assets)
  }

  async fetchBalance(){
    this.balance = ethers.utils.formatUnits(await this.freeCityContractService.getBalance(this.gasha.paymentTokenAddress),18)
  }

  async spin(){
    this.spinning = true;
    this.nftService.spinV2(this.gasha).then(async (transaction) => {
      let receipt = await transaction.wait()
      log.debug("spin events ", receipt.events)
      let event = receipt.events.find((e) => e.event === 'Unboxed')
      console.log(event)
      this.resultTokenId = event.args.rewardTokenId
      this.resultMeta = await this.nftService.getNFTMeta(this.gasha.rewardNFT, this.resultTokenId)
      await this.fetchAssets()
      await this.fetchBalance()
      await this.singletonService.fire('success', 'spin success', 'Success spinning')
    }).catch((error) => {
      log.error("error")
      log.error(error)
      this.resultMeta = undefined
      this.resultTokenId = undefined
      if(error.data && error.data.message){
        this.singletonService.fire('error', 'spinning failed', 'ERROR: ' + error.data.message)
      }else{
        this.singletonService.fire('error', 'spinning failed please try again')
      }
    }).finally(() => {
      this.spinning = false
    })

  }

}
