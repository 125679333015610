<div class="main">
  <app-header-section></app-header-section>
  <div *ngIf="isLoading" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-center text-muted">Fetching project</p>
  </div>
  <div *ngIf="!isLoading">

    <div class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="project.bannerImage | renderImageUrl: 'banner'">
        </div>
      </div>
    </div>
    <div class="information-collection">
      <div class="image-collection-profile no-wrapper-option" >
        <div>
          <img [src]="project.logoImage | renderImageUrl: 'logo'" alt="">
        </div>
      </div>
      <div class="info-content text-center">
        <h2 class="tx-40 mt-2 mb-3">
          {{project.name}}
          <i [ngClass]="project.verification ? (project.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
            [title]="project.verification ? (project.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
            class="ml-1"></i>
        </h2>

        <div class="badge-wrapper">
          <span *ngFor="let tag of project.tags" class="badge tag border text-muted">
            {{tag}}
          </span>
        </div>


        <div class="desc-content mt-3 mx-auto text-muted">
          <span>
            {{project.description}}
          </span>
        </div>

      </div>

      <div class="content-wrapper-tab mt-3 mb-5">
        <ul class="nav nav-tabs justify-content-center" role="tablist">
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('introduction')"
              [ngClass]="tab == 'introduction' ?'active':''"
              class="nav-link"
              id="introduction-tab"
              data-toggle="tab"
              href="#introduction"
              role="tab"
              aria-controls="introduction"
              aria-selected="false">
              <i class="icon-list mr-2 tx-20 align-middle"></i> Introduction
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('collections')"
              [ngClass]="tab == 'collections' ?'active':''"
              class="nav-link"
              id="collections-tab"
              data-toggle="tab"
              href="#collections"
              role="tab"
              aria-controls="collections"
              aria-selected="true">
              <i class="icon-picture mr-2 tx-20 align-middle"></i> Collections
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('whitelist')"
              [ngClass]="tab == 'whitelist' ?'active':''"
              class="nav-link"
              id="whitelist-tab"
              data-toggle="tab"
              href="#whitelist"
              role="tab"
              aria-controls="whitelist"
              aria-selected="true">
              <i class="icon-event mr-2 tx-20 align-middle"></i> Whitelist
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('launchpads')"
              [ngClass]="tab == 'launchpads' ?'active':''"
              class="nav-link"
              id="launchpads-tab"
              data-toggle="tab"
              href="#launchpads"
              role="tab"
              aria-controls="launchpads"
              aria-selected="true">
              <i class="icon-magic-wand mr-2 tx-20 align-middle"></i> NFT Launchpads
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('reveals')"
              [ngClass]="tab == 'reveals' ?'active':''"
              class="nav-link"
              id="reveals-tab"
              data-toggle="tab"
              href="#reveals"
              role="tab"
              aria-controls="reveals"
              aria-selected="true">
              <i class="icon-social-dropbox mr-2 tx-20 align-middle"></i> NFT Reveals
            </a>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <a (click)="activeTab('services')"
              [ngClass]="tab == 'services' ?'active':''"
              class="nav-link"
              id="services-tab"
              data-toggle="tab"
              href="#services"
              role="tab"
              aria-controls="services"
              aria-selected="true">
              <i class="icon-graph mr-2 tx-20 align-middle"></i> Services
            </a>
          </li> -->
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('mining')"
              [ngClass]="tab == 'mining' ?'active':''"
              class="nav-link"
              id="mining-tab"
              data-toggle="tab"
              href="#mining"
              role="tab"
              aria-controls="mining"
              aria-selected="true">
              <i class="icon-diamond mr-2 tx-20 align-middle"></i> Mining
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a (click)="activeTab('crafting')"
              [ngClass]="tab == 'crafting' ?'active':''"
              class="nav-link"
              id="crafting-tab"
              data-toggle="tab"
              href="#crafting"
              role="tab"
              aria-controls="crafting"
              aria-selected="true">
              <i class="icon-wrench mr-2 tx-20 align-middle"></i> Crafting
            </a>
          </li>
        </ul>
        <!-- <div class="tab-content pt-4 px-4 pb-5 mb-5"> -->
        <div class="tab-content">

          <div class="nft-search-view">

              <!-- <div class="wrapper-search-main">
                <app-filter-search *ngIf="data && project.id"
                [enabledCollections]="false"
                [enabledCategories]="false"
                [enabledAttributes]="tab =='items'?true:false"
                [enabledPrice]="tab =='items'?true:false"
                [collectionId]="project.id"
                [defaultSelectedFilters]="defaultSelectedFilters"
                >
                </app-filter-search>
              </div> -->

              <div class="wrapper-search-result content-wrapper-tab pt-4 px-4 pb-5 mb-5 wrapper-project-detail-tabs">

                <div *ngIf="tab == 'introduction'" [ngClass]="tab == 'introduction' ?'show active':''" class="tab-pane fade project-detail-introduction" id="introduction" role="tabpanel" aria-labelledby="introduction-tab">
                  <!-- <app-nft-card-list *ngIf="data && assets" [assets]="assets" [collection]="data"  [isFetchingAsset]="isFetchingAsset" [totalResults]="totalResults"></app-nft-card-list> -->
                  <!-- <span >{{project.intro}}</span> -->
                  <div class="container">
                    <span class="text-white-main" [innerHTML]="project.intro"></span>
                  </div>
                </div>

                <div *ngIf="tab == 'collections'"  [ngClass]="tab == 'collections' ?'show active':''" class="tab-pane fade project-detail-collections" id="collections" role="tabpanel" aria-labelledby="activity-tab">
                  <!-- <span>collections tab content</span> -->
                  <app-collections-section [enabledLazyLoader]="true" [isShow]="false" [categories]="['photography']"></app-collections-section>
                </div>

                <div *ngIf="tab == 'whitelist'"  [ngClass]="tab == 'whitelist' ?'show active':''" class="tab-pane fade project-detail-whitelist" id="whitelist" role="tabpanel" aria-labelledby="activity-tab">
                  <!-- <span>whitelist tab content</span> -->
                  <app-whitelist-markets-section
                    [markets]="whitelistMarkets"
                    [isLoading]="isLoading"
                  ></app-whitelist-markets-section>
                </div>

                <div *ngIf="tab == 'launchpads'"  [ngClass]="tab == 'launchpads' ?'show active':''" class="tab-pane fade project-detail-launchpads" id="launchpads" role="tabpanel" aria-labelledby="activity-tab">
                  <app-collections-section
                    [sortBy]="'floorEth:desc,volumnEth:desc'"
                    [enabledLazyLoader]="true"
                    [isShow]="false"
                    [customLimit]="12"
                    [customCollectionLink]="'/nft/launchpad/'"
                    [isOffering]="true"
                  >
                  </app-collections-section>
                </div>

                <div *ngIf="tab == 'reveals'"  [ngClass]="tab == 'reveals' ?'show active':''" class="tab-pane fade project-detail-reveals" id="reveals" role="tabpanel" aria-labelledby="activity-tab">
                  <!-- <span>reveals tab content</span> -->
                  <app-nft-reveal-section
                    [reveals]="reveals"
                    [isLoading]="isLoading"
                  ></app-nft-reveal-section>

                </div>

                <div *ngIf="tab == 'services'"  [ngClass]="tab == 'services' ?'show active':''" class="tab-pane fade project-detail-services" id="services" role="tabpanel" aria-labelledby="activity-tab">
                  <span>services tab content</span>
                </div>

                <div *ngIf="tab == 'mining'"  [ngClass]="tab == 'mining' ?'show active':''" class="tab-pane fade project-detail-mining" id="mining" role="tabpanel" aria-labelledby="activity-tab">
                  <!-- <span>mining tab content</span> -->
                  <app-poc-mining-section
                    [pools]="pools"
                  ></app-poc-mining-section>
                </div>

                <div *ngIf="tab == 'crafting'"  [ngClass]="tab == 'crafting' ?'show active':''" class="tab-pane fade project-detail-crafting" id="crafting" role="tabpanel" aria-labelledby="activity-tab">
                  <!-- <span>crafting tab content</span> -->
                  <app-crafting-station-section
                    [stations]="craftingStations"
                  ></app-crafting-station-section>
                </div>
              </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <app-modal-menu></app-modal-menu>
  <!-- <app-footer-section></app-footer-section> -->
</div>

