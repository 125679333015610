import { MiningService } from './../../services/mining.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from "rxjs";

import { Logger } from '../../services/logger.service';
const log = new Logger('BlockToDatetimeComponent');

@Component({
  selector: 'app-block-to-datetime',
  templateUrl: './block-to-datetime.component.html',
  styleUrls: ['./block-to-datetime.component.scss']
})
export class BlockToDatetimeComponent implements OnInit {

  @Input() blockToWait
  @Input() blockTimeSecond


  private subscription: Subscription;

  public dateNow = new Date();
  //public dDay = new Date('Jan 01 2021 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  isJustCountDown = undefined

  constructor(
    // public router:Router,
    // private route: ActivatedRoute,
    private miningService: MiningService,
  ) {
    log.debug("this.blockToWait : %o",this.blockToWait)
    log.debug("this.blockTimeSecond : %o",this.blockTimeSecond)

  }


  private getTimeDifference (unixTimestamp) {
    this.timeDifference = unixTimestamp.getTime() - new  Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits (timeDifference) {
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
      this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));

      this.isJustCountDown = true
  }

  async ngOnInit() {

    const currentBlock = await this.miningService.getCurrentBlock()
    const endAtBlock = currentBlock + this.blockToWait
    const blockLeft = endAtBlock - currentBlock
    const secondLeft = blockLeft * this.blockTimeSecond
    const endDate = new Date(); // finish
    endDate.setSeconds(endDate.getSeconds() + secondLeft);

    log.debug("endDate : %o",endDate)


    this.getTimeDifference(endDate)

    // this.subscription = interval(1000)
    //   .subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

}

