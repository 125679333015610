import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-nft-reveal-result-modal',
  templateUrl: './nft-reveal-result-modal.component.html',
  styleUrls: ['./nft-reveal-result-modal.component.scss']
})
export class NftRevealResultModalComponent implements OnInit {


  @Output() dataService = new EventEmitter();
  @Input() reward;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    //console.log("%o",this.reward)
  }

}
