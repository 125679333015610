import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('CombineArrayPipe'); // log.debug

@Pipe({
  name: 'combineArray'
})
export class CombineArrayPipe implements PipeTransform {

  transform(array: any, ...args: unknown[]): unknown {
    let otherArray:any = args[0]
    otherArray.map(it => it.isSelected = true)
    let combinedArray = [...otherArray,...array]
    log.debug("-- combinedArray => %o",combinedArray)
    return combinedArray;
  }

}
