import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { IconsComponent } from './icons/icons.component';
import { WorkSectionComponent } from './work-section/work-section.component';
import { WalletSectionComponent } from './wallet-section/wallet-section.component';
import { TopSellerTwoSectionComponent } from './top-seller-two-section/top-seller-two-section.component';
import { TopSellerSectionComponent } from './top-seller-section/top-seller-section.component';
import { SignupSectionComponent } from './signup-section/signup-section.component';
import { ScrollupComponent } from './scrollup/scrollup.component';
import { ModalSearchComponent } from './modal/modal-search/modal-search.component';
import { ModalMenuComponent } from './modal/modal-menu/modal-menu.component';
import { LoginSectionComponent } from './login-section/login-section.component';
import { ItemDetailsSectionComponent } from './item-details-section/item-details-section.component';
import { HelpCenterSectionComponent } from './help-center-section/help-center-section.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';
import { ExploreSixSectionComponent } from './explore/explore-six-section/explore-six-section.component';
import { ExploreThreeSectionComponent } from './explore/explore-three-section/explore-three-section.component';
import { ExploreTwoSectionComponent } from './explore/explore-two-section/explore-two-section.component';
import { ExploreOneSectionComponent } from './explore/explore-one-section/explore-one-section.component';
import { ExploreFiveSectionComponent } from './explore/explore-five-section/explore-five-section.component';
import { CreateSectionComponent } from './create-section/create-section.component';
import { ContactSectionComponent } from './contact-section/contact-section.component';
import { CollectionsSectionComponent } from './collections-section/collections-section.component';
import { BreadcrumbSignupComponent } from './breadcrumb/breadcrumb-signup/breadcrumb-signup.component';
import { BreadcrumbLoginComponent } from './breadcrumb/breadcrumb-login/breadcrumb-login.component';
import { BreadcrumbItemDetailsComponent } from './breadcrumb/breadcrumb-item-details/breadcrumb-item-details.component';
import { BreadcrumbHelpCenterComponent } from './breadcrumb/breadcrumb-help-center/breadcrumb-help-center.component';
import { BreadcrumbExploreTwoComponent } from './breadcrumb/breadcrumb-explore-two/breadcrumb-explore-two.component';
import { BreadcrumbExploreOneComponent } from './breadcrumb/breadcrumb-explore-one/breadcrumb-explore-one.component';
import { BreadcrumbCreateComponent } from './breadcrumb/breadcrumb-create/breadcrumb-create.component';
import { BreadcrumbContactComponent } from './breadcrumb/breadcrumb-contact/breadcrumb-contact.component';
import { BreadcrumbBlogSingleComponent } from './breadcrumb/breadcrumb-blog-single/breadcrumb-blog-single.component';
import { BreadcrumbBlogComponent } from './breadcrumb/breadcrumb-blog/breadcrumb-blog.component';
import { BreadcrumbAuthorsComponent } from './breadcrumb/breadcrumb-authors/breadcrumb-authors.component';
import { BreadcrumbAuthorComponent } from './breadcrumb/breadcrumb-author/breadcrumb-author.component';
import { BreadcrumbActivityComponent } from './breadcrumb/breadcrumb-activity/breadcrumb-activity.component';
import { BlogSingleSectionComponent } from './blog-single-section/blog-single-section.component';
import { BlogSectionComponent } from './blog-section/blog-section.component';
import { AuthorsSectionComponent } from './authors-section/authors-section.component';
import { AuthorProfileSectionComponent } from './author-profile-section/author-profile-section.component';
import { ActivitySectionComponent } from './activity-section/activity-section.component';
import { AuctionsTwoSectionComponent } from './auctions/auctions-two-section/auctions-two-section.component';
import { AuctionsOneSectionComponent } from './auctions/auctions-one-section/auctions-one-section.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { HeaderSectionComponent } from './header-section/header-section.component';
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { AuctionsThreeSectionComponent } from './auctions/auctions-three-section/auctions-three-section.component';
import { AuthorSectionComponent } from './author-section/author-section.component';
import { BreadcrumbExploreThreeComponent } from './breadcrumb/breadcrumb-explore-three/breadcrumb-explore-three.component';
import { BreadcrumbExploreFourComponent } from './breadcrumb/breadcrumb-explore-four/breadcrumb-explore-four.component';
import { BreadcrumbLiveAuctionsComponent } from './breadcrumb/breadcrumb-live-auctions/breadcrumb-live-auctions.component';
import { BreadcrumbWalletConnectComponent } from './breadcrumb/breadcrumb-wallet-connect/breadcrumb-wallet-connect.component';
import { ExploreFourSectionComponent } from './explore/explore-four-section/explore-four-section.component';
import { CorePipesModule } from '../pipes/pipes.module';
import { CoreDirectivesModule } from '../directives/directives.module';
import { ActivityTableComponent } from './activity-table/activity-table.component';
import { NftCardListComponent } from './nft-card-list/nft-card-list.component';
import { SelectedFilterCardSectionComponent } from './selected-filter-card-section/selected-filter-card-section.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UseProfileImageComponent } from './use-profile-image/use-profile-image.component';
import { MusicBoxComponent } from './music-box/music-box.component';
import { CampaignSectionComponent } from './campaign-section/campaign-section.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ProjectSectionComponent } from './project-section/project-section.component';
import { NftRevealSectionComponent } from './nft-reveal-section/nft-reveal-section.component';
import { WhitelistMarketsSectionComponent } from './whitelist-markets-section/whitelist-markets-section.component';
import { PocMiningSectionComponent } from './poc-mining-section/poc-mining-section.component';
import { CraftingStationSectionComponent } from './crafting-station-section/crafting-station-section.component';
import { BlockToDatetimeComponent } from './block-to-datetime/block-to-datetime.component';
@NgModule({
  declarations: [
    ActivitySectionComponent,
    AuctionsOneSectionComponent,
    AuctionsTwoSectionComponent,
    AuctionsThreeSectionComponent,
    AuthorProfileSectionComponent,
    AuthorSectionComponent,
    AuthorsSectionComponent,
    BlogSectionComponent,
    BlogSingleSectionComponent,
    BreadcrumbActivityComponent,
    BreadcrumbAuthorComponent,
    BreadcrumbAuthorsComponent,
    BreadcrumbBlogComponent,
    BreadcrumbBlogSingleComponent,
    BreadcrumbContactComponent,
    BreadcrumbCreateComponent,
    BreadcrumbExploreOneComponent,
    BreadcrumbExploreTwoComponent,
    BreadcrumbExploreThreeComponent,
    BreadcrumbExploreFourComponent,
    BreadcrumbHelpCenterComponent,
    BreadcrumbItemDetailsComponent,
    BreadcrumbLiveAuctionsComponent,
    BreadcrumbLoginComponent,
    BreadcrumbSignupComponent,
    BreadcrumbWalletConnectComponent,
    CollectionsSectionComponent,
    ContactSectionComponent,
    CreateSectionComponent,
    ExploreOneSectionComponent,
    ExploreTwoSectionComponent,
    ExploreThreeSectionComponent,
    ExploreFourSectionComponent,
    ExploreFiveSectionComponent,
    ExploreSixSectionComponent,
    FaqSectionComponent,
    FooterSectionComponent,
    HeaderSectionComponent,
    HelpCenterSectionComponent,
    HeroSectionComponent,
    ItemDetailsSectionComponent,
    LoginSectionComponent,
    ModalMenuComponent,
    ModalSearchComponent,
    ScrollupComponent,
    SignupSectionComponent,
    TopSellerSectionComponent,
    TopSellerTwoSectionComponent,
    WalletSectionComponent,
    WorkSectionComponent,
    IconsComponent,
    FilterSearchComponent,
    ActivityTableComponent,
    NftCardListComponent,
    SelectedFilterCardSectionComponent,
    SpinnerComponent,
    UseProfileImageComponent,
    MusicBoxComponent,
    CampaignSectionComponent,
    CountdownComponent,
    ProjectSectionComponent,
    NftRevealSectionComponent,
    WhitelistMarketsSectionComponent,
    PocMiningSectionComponent,
    CraftingStationSectionComponent,
    BlockToDatetimeComponent,
  ],
  exports: [
    ActivitySectionComponent,
    AuctionsOneSectionComponent,
    AuctionsTwoSectionComponent,
    AuctionsThreeSectionComponent,
    AuthorProfileSectionComponent,
    AuthorSectionComponent,
    AuthorsSectionComponent,
    BlogSectionComponent,
    BlogSingleSectionComponent,
    BreadcrumbActivityComponent,
    BreadcrumbAuthorComponent,
    BreadcrumbAuthorsComponent,
    BreadcrumbBlogComponent,
    BreadcrumbBlogSingleComponent,
    BreadcrumbContactComponent,
    BreadcrumbCreateComponent,
    BreadcrumbExploreOneComponent,
    BreadcrumbExploreTwoComponent,
    BreadcrumbExploreThreeComponent,
    BreadcrumbExploreFourComponent,
    BreadcrumbHelpCenterComponent,
    BreadcrumbItemDetailsComponent,
    BreadcrumbLiveAuctionsComponent,
    BreadcrumbLoginComponent,
    BreadcrumbSignupComponent,
    BreadcrumbWalletConnectComponent,
    CollectionsSectionComponent,
    ContactSectionComponent,
    CreateSectionComponent,
    ExploreOneSectionComponent,
    ExploreTwoSectionComponent,
    ExploreThreeSectionComponent,
    ExploreFourSectionComponent,
    ExploreFiveSectionComponent,
    ExploreSixSectionComponent,
    FaqSectionComponent,
    FooterSectionComponent,
    HeaderSectionComponent,
    HelpCenterSectionComponent,
    HeroSectionComponent,
    ItemDetailsSectionComponent,
    LoginSectionComponent,
    ModalMenuComponent,
    ModalSearchComponent,
    ScrollupComponent,
    SignupSectionComponent,
    TopSellerSectionComponent,
    TopSellerTwoSectionComponent,
    WalletSectionComponent,
    WorkSectionComponent,
    IconsComponent,
    FilterSearchComponent,
    ActivityTableComponent,
    NftCardListComponent,
    SelectedFilterCardSectionComponent,
    SpinnerComponent,
    UseProfileImageComponent,
    MusicBoxComponent,
    CampaignSectionComponent,
    CountdownComponent,
    ProjectSectionComponent,
    NftRevealSectionComponent,
    WhitelistMarketsSectionComponent,
    PocMiningSectionComponent,
    CraftingStationSectionComponent,
    BlockToDatetimeComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    CorePipesModule,
    CoreDirectivesModule,
    FormsModule,
    Ng2SearchPipeModule,
  ],
})
export class SharedComponentsModule { }
