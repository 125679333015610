<div class="main">
  <app-header-section></app-header-section>

  <div *ngIf="!loading" class="back-btn-wrapper border">
    <div class="container">
      <div class="d-flex justify-content-start align-items-center">
        <div class="btn-group mr-3 w-100" role="group">
          <a [routerLink]="['/nft/'+asset.nftAddress+'/'+asset.tokenId]"  [queryParams]="{chain: asset.chain}" class="btn btn-outline-light bg-transparent">
            <div class="back-btn-content d-flex align-items-center">
              <span class="mr-2">&lt;</span>
              <div>
                <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" [src]="asset.media | renderImageUrl: 'nft'" width="30" width="30" alt="">
                <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                  [poster]="asset.media | renderImageUrl : 'logo'  : true : true"
                  [src]="asset.animationUrl"
                  width="30" width="30">
                </video>
              </div>
              <div class="text-left">
                <div>
                  <small class="text-muted tx-14">{{collection.name}}</small>
                  <span class="collections-verify ml-1">
                    <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                    [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                    class=""></i>
                  </span>
                </div>
                <div>
                  <small class="font-weight-bold tx-14">
                    {{asset.name}}
                    <small class="text-muted">
                      #{{asset.tokenId}}
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <section class="author-area">
    <div class="container" *ngIf="!loading">
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-5">
            <div class="preview-nft-img mb-5">
              <div class="tx-16 mb-2 font-weight-bold">Preview</div>
              <div class="card bg-transparent p-0 border">
                <!-- <div class="image-over">
                  <img class="card-img-top" [src]="asset.media | renderImageUrl: 'logo'" alt="">
                </div> -->
                <div class="nft-img-wrapper border-bottom d-flex align-items-center">
                  <div>
                    <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" [src]="asset.media | renderImageUrl: 'nft'" alt="">
                    <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                      [poster]="asset.media | renderImageUrl : 'logo'  : true : true"
                      [src]="asset.animationUrl"
                      class="w-100" controls loop autoplay
                      controlsList="nodownload">
                    </video>
                  </div>
                </div>
                <div class="card-caption col-12 p-0">
                  <div class="card-body p-0">
                    <div class="d-flex justify-content-between align-items-center py-3 px-3">
                      <div>
                        <div>
                          <small class="text-muted tx-14">{{collection.name}}</small>
                          <span class="collections-verify ml-1">
                            <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                            [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                            class=""></i>
                          </span>
                        </div>
                        <div><small class="font-weight-bold tx-14">{{asset.name}}
                          <small class="text-muted">
                            #{{asset.tokenId}}
                          </small>
                        </small></div>
                      </div>
                      <div>
                        <div class="text-right"><small class="text-muted tx-14">Price</small></div>
                        <div><small class="text-weight-bolder tx-16">
                          <img [src]="selectedPayment.assetImage" width="16" height="16" class="mr-1 align-middle" alt=""> {{price | formatNumber: '6':'1.2-6'}} {{selectedPayment.symbol | toUpperCase}}</small>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class="col-12 col-md-7">
                <div class="ml-4">
                    <div class="">
                        <h3 class="mt-0 mb-0">List item for sale</h3>
                    </div>
                    <form class="item-form  bg-transparent">

                            <div class="form-group mb-5">
                              <div div class="font-weight-bold mb-2">Price </div>
                              <div class="d-flex gap-2 flex-wrap">
                                <div class="dropdown-menu dropdown-menu-full">

                                  <a *ngFor="let paymentToken of payments" class="dropdown-item d-flex align-items-center"  >
                                    <div class="mr-2">
                                      <img [src]="paymentToken.assetImage" height="24" width="24" class="mr-2 rounded-circle" width="32" height="32">
                                    </div>
                                    <div>
                                      <div>{{paymentToken.symbol | toUpperCase}}</div>
                                      <small class="text-muted">{{paymentToken.name}}</small>
                                    </div>
                                  </a>
                                </div>
                                <div class="dropdown">
                                  <button class="btn dropdown-toggle w-100 py-2 pl-3 pr-5 h-50px shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <img [src]="selectedPayment.assetImage" class="mr-1 rounded-circle" width="16"> {{selectedPayment.symbol | toUpperCase}}
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-full">
                                    <a *ngFor="let paymentToken of payments" class="dropdown-item" (click)="selectPayment(paymentToken)">
                                      <img [src]="paymentToken.assetImage" class="mr-2 rounded-circle" width="16"> {{paymentToken.symbol | toUpperCase}}
                                    </a>
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                    <input type="text" class="form-control" autocomplete="off" name="price" [(ngModel)]="price" placeholder="Amount" decimalNumber>
                                    <!--<div class="text-right tx-13 text-muted mt-2">$180.26 Total</div> -->
                                </div>
                              </div>
                            </div>


                           <!-- <div class="form-group mb-5">
                              <div div class="font-weight-bold mb-2">Duration </div>
                              <div class="dropdown">
                                <button class="btn dropdown-toggle w-100 py-3 pl-3 pr-5 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                  <i class="icon-calendar mr-3 tx-20 align-middle"></i> 7 days
                                </button>
                                <div class="dropdown-menu dropdown-menu-full">
                                  <a class="dropdown-item" href="#">7 days</a>
                                  <a class="dropdown-item" href="#">14 days</a>
                                  <a class="dropdown-item" href="#">30 days</a>
                                </div>
                              </div>
                            </div>

-->
                            <div class="form-group mb-5">
                              <div div class="font-weight-bold mb-2">Fees </div>
                              <div class="d-flex justify-content-between">
                                <div class="text-muted tx-16">Loyalty Fee</div>
                                <div class="tx-16">{{asset.loyaltyFee | number:'1.2-2'}}%</div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <div class="text-muted tx-16">Service Fee</div>
                                <div class="tx-16">{{asset.serviceFee | number:"1.2-2"}}%</div>
                              </div>
                            </div>
                      <div *ngIf="isApproveLoading" class="col-12 text-center">
                        <div  class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p class="text-center text-muted">Approving</p>
                      </div>

                      <!-- <div *ngIf="isCancelListingLoading" class="col-12 text-center">
                        <div  class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p class="text-center text-muted">Cancel Listing</p>
                      </div> -->

                      <div *ngIf="isListingLoading" class="col-12 text-center">
                        <div  class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p class="text-center text-muted">Listing</p>
                      </div>

                      <div *ngIf="!isApproved && !isApproveLoading" class="col-12 text-center" (click)="approve()">
                        <button class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300"
                                type="button">
                          Approve
                        </button>
                      </div>

                      <div *ngIf="isApproved && !isCancelListingLoading && !isListingLoading" class="col-12 text-center">
                        <button class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300"
                                type="button" data-toggle="modal" [disabled]="price <= 0 || price === undefined"
                                data-target="#listingSellModal">Preview & Listing</button>
                      </div>

                      <!--<div *ngIf="isApproved && !isCancelListingLoading && !isListingLoading" class="col-12 text-center">
                        <button class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300"
                                type="button"  (click)="cancelListing()">Cancel Listing</button>
                      </div>-->

                      <!-- <div *ngIf="isApproved && !isApproveLoading && isRevokeEnabled" class="col-12 text-center" (click)="revoke()">
                        <button class="btn btn-warning w-100 mt-3 mt-sm-4 text-white max-w-300"
                                type="button">
                          Revoke Approval
                        </button>
                      </div> -->




                    </form>
                </div>
            </div>

        </div>
      </div>
  </section>


  <div *ngIf="!loading" class="modal" tabindex="-1" id="listingSellModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">Complete your listing</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block p-0">
          <div class="d-flex justify-content-between align-items-center p-4">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)"  [src]="asset.media | renderImageUrl: 'nft'" width="50" alt="">
                <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
                  [poster]="asset.media | renderImageUrl : 'logo'  : true : true"
                  [src]="asset.animationUrl"
                  width="50">
                </video>

              </div>
              <div class="text-left">
                <div>
                  <small class="text-muted tx-13">{{collection.name}}</small>
                  <span class="collections-verify ml-1">
                    <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                    [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                    class=""></i>
                  </span>
                </div>
                <div><small class="font-weight-bold tx-15 text-white-main">{{asset.name}}</small></div>
                <div><small class="text-muted tx-13">Quantity: <span class="text-white-main">1</span></small></div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="text-right mr-3">
                <div><small class="text-muted tx-13">Price</small></div>
                <div>
                  <small *ngIf="selectedPayment" class="tx-15 font-weight-bold">
                    <img [src]="selectedPayment.assetImage" width="16" height="16" class="mr-1 align-middle" alt=""> <span class="text-white-main">{{price | formatNumber:'6':'1.2-6'}} {{selectedPayment.symbol | toUpperCase}}</span>
                  </small>
                </div>
                <!--<div><small class="text-muted tx-13">$3,474.44 USD</small></div>-->
              </div>
              <div class="text-center">
                <i class="icon-arrow-up tx-16 cursor-pointer" *ngIf="hideSchedule" (click)="toggleScheduledSell()"></i>
                <i class="icon-arrow-down tx-16 cursor-pointer" *ngIf="!hideSchedule" (click)="toggleScheduledSell()"></i>
              </div>
            </div>
          </div>
          <div class="justify-content-around align-items-center border-top p-4 flex-wrap" [ngStyle]="{'display': hideSchedule == true ?'none':'flex'}">
            <div class="text-left text-white-main tx-14 font-weight-bold">
              NFT Contract
            </div>
            <div class="text-left">
              <div class="font-weight-bold tx-15 text-white-main" style="word-break: break-word;">{{nftAddress}}</div>
              <div class="text-muted tx-13">Token ID : {{tokenId}}</div>
            </div>
            <!--<div class="text-left text-muted tx-14 font-weight-bold">
              SCHEDULED FOR
            </div>
            <div class="text-left">
              <div class="font-weight-bold tx-15">7 days</div>
              <div class="text-muted tx-13">Apr 5,2022 10:50 AM - Apr 12,2022 10:55 AM</div>
            </div>-->
          </div>
          <div class="border-top p-4">
            <div class="text-muted  mb-3">
              <p class="tx-12">
              By listing this item, that's mean you accept to FreeCity's Privacy &amp; Terms, if you're not sure about our <a class="tx-12" [routerLink]="['/policy']" target="_blank">Privacy &amp; Terms</a> please read it before listing item.
              </p>
            </div>
            <div *ngIf="isApproved" class="col-12 text-center">
              <button class="btn btn-primary w-100 mt-3 mb-2 text-white max-w-300"
                      type="button" data-toggle="modal" [disabled]="price <= 0"
                      data-target="#listingSellModal" (click)="listing()">Listing</button>
            </div>
            <!-- <div class="accordion mt-4" id="accordionCompleteYourListing">
              <div class="card bg-transparent shadow-none border p-0">
                <div class="card-header bg-transparent p-0 border-0" id="headingOne">
                  <h2 class="mb-0 mt-0">
                    <button class="btn btn-block text-left p-0 btn-header-arrow p-4 shadow-none" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <span class="accordion-no">1</span> Initialize your wallet
                    </button>
                  </h2>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionCompleteYourListing">
                  <div class="card-body border-top mt-0 p-4">
                    To get set up for selling on OpenSea for the first time, you must initialize your wallet, which requires a one-time gas fee.

                    <p class="mt-4">
                      <small class="text-muted">
                        Waiting for initialization...
                      </small>
                    </p>

                  </div>
                </div>
              </div>
              <div class="card bg-transparent shadow-none border p-0">
                <div class="card-header bg-transparent p-0 border-0" id="headingTwo">
                  <h2 class="mb-0 mt-0">
                    <button class="btn btn-block text-left p-0 btn-header-arrow p-4 shadow-none collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="accordion-no">2</span> Approve this item for sale
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionCompleteYourListing">
                  <div class="card-body border-top mt-0 p-4">
                    To get set up for auction listings for the first time, you must approve this item for sale, which requires a one-time gas fee.
                  </div>
                </div>
              </div>
              <div class="card bg-transparent shadow-none border p-0">
                <div class="card-header bg-transparent p-0 border-0" id="headingThree">
                  <h2 class="mb-0 mt-0">
                    <button class="btn btn-block text-left p-0 btn-header-arrow p-4 shadow-none collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <span class="accordion-no">3</span> Confirm 0.05 ETH listing
                    </button>
                  </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionCompleteYourListing">
                  <div class="card-body border-top mt-0 p-4">
                    Accept the signature request in your wallet and wait for your listing to process.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer text-center">
          <button  type="button" class="btn btn-primary text-white" data-dismiss="modal" aria-label="Close">
            Save
          </button>
        </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>

</div>
