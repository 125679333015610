<div class="main">
  <app-header-section></app-header-section>


  <section class="author-area">
    <div class="container" *ngIf="!loading">
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-12">
              <div>
                  <div class="">
                      <h3 class="mt-0 mb-0">
                        NFT Batch Sell
                      </h3>
                      <span>
                        {{currentChain ? currentChain.networkName : ''}}
                      </span>
                  </div>
                  <form class="item-form card no-hover bg-transparent border-0 shadow-none">

                    <div class="form-group mb-5">
                      <div div class="font-weight-bold mb-2">NFT Address </div>
                      <div class="d-flex gap-2 flex-wrap">
                        <div class="flex-grow-1">
                            <!-- <input type="text" class="form-control" autocomplete="off" name="nftAddress" [(ngModel)]="nftAddress" placeholder="NFT Address" decimalNumber> -->
                            <input [(value)]="nftAddress"
                              name="nftAddress"
                              (input)="isValidNFTAddressWithDelay()"
                              type="text" class="form-control"
                              placeholder="NFT Address"
                              [regExFormatType]="2" alphaNumberic>
                            <div class="mt-2">
                              <small class="mr-2" [ngClass]="(canUseNFTAddress != undefined && canUseNFTAddress) ? 'text-success' : 'text-danger'" id="used-slug-warning" [hidden]="canUseNFTAddress == undefined || isLoadingValidateSlugg">
                                {{ (canUseNFTAddress != undefined && canUseNFTAddress) ? '(\u2714) This NFT address is valid.' : '(\u2716) This NFT address is invalid.'}}
                              </small>
                              <small [hidden]="!isLoadingValidateSlug">
                                <i class="fas fa-spinner fa-spin icon-w-h-25px mr-2 align-middle"></i> Loading...
                              </small>
                            </div>
                        </div>

                      </div>
                    </div>

                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress)" class="form-group mb-0">
                      <div class="row row-initial">
                        <div class="col-md-6 mb-5">
                          <div div class="font-weight-bold mb-2">From token id </div>
                          <div class="d-flex gap-2 flex-wrap">
                            <div class="flex-grow-1">
                                <input type="text" class="form-control" autocomplete="off" name="fromTokenId" (keyup)="calculateRangeTokenIds()" [(ngModel)]="fromTokenId" placeholder="From token id" numbersOnly>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-5">
                          <div div class="font-weight-bold mb-2">To token id </div>
                          <div class="d-flex gap-2 flex-wrap">
                            <div class="flex-grow-1">
                                <input type="text" class="form-control" autocomplete="off" name="toTokenId" (keyup)="calculateRangeTokenIds()" [(ngModel)]="toTokenId" placeholder="To token id" numbersOnly>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress)" class="form-group mb-5">
                      <div div class="font-weight-bold mb-2">Price </div>
                      <div class="d-flex gap-2 flex-wrap">
                        <div class="dropdown-menu dropdown-menu-full">

                          <a *ngFor="let paymentToken of payments" class="dropdown-item d-flex align-items-center"  >
                            <div class="mr-2">
                              <img [src]="paymentToken.assetImage" height="24" width="24" class="mr-2 rounded-circle" width="32" height="32">
                            </div>
                            <div>
                              <div>{{paymentToken.symbol | toUpperCase}}</div>
                              <small class="text-muted">{{paymentToken.name}}</small>
                            </div>
                          </a>
                        </div>
                        <div class="dropdown">
                          <button class="btn dropdown-toggle w-100 py-2 pl-3 pr-5 h-50px shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                            <img [src]="selectedPayment.assetImage" class="mr-1 rounded-circle" width="16"> {{selectedPayment.symbol | toUpperCase}}
                          </button>
                          <div class="dropdown-menu dropdown-menu-full">
                            <a *ngFor="let paymentToken of payments" class="dropdown-item" (click)="selectPayment(paymentToken)">
                              <img [src]="paymentToken.assetImage" class="mr-2 rounded-circle" width="16"> {{paymentToken.symbol | toUpperCase}}
                            </a>
                          </div>
                        </div>
                        <div class="flex-grow-1">
                            <input type="text" class="form-control" autocomplete="off" name="price" [(ngModel)]="price" placeholder="Amount" decimalNumber>
                        </div>
                      </div>
                    </div>

                    <!-- <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress)" class="form-group mb-5">
                      <div div class="font-weight-bold mb-2">Fees </div>
                      <div class="d-flex justify-content-between">
                        <div class="text-muted tx-16">Service Fee</div>
                        <div class="tx-16">2.5%</div>
                      </div>
                    </div> -->

                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress) && isApproveLoading" class="col-12 text-center">
                      <div  class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <p class="text-center text-muted">Approving</p>
                    </div>


                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress) && isListingLoading" class="col-12 text-center">
                      <div  class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <p class="text-center text-muted">Listing</p>
                    </div>

                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress) && !isApproved && !isApproveLoading" class="col-12 text-center" (click)="approve()">
                      <button class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300"
                              type="button">
                        Approve NFT
                      </button>
                    </div>

                    <div *ngIf="(canUseNFTAddress != undefined && canUseNFTAddress) && isApproved && !isCancelListingLoading && !isListingLoading" class="col-12 text-center">
                      <button class="btn btn-primary w-100 mt-3 mt-sm-4 text-white max-w-300"
                              type="button" data-toggle="modal" [disabled]="price <= 0 || price === undefined || rangeTokenIds.length == 0"
                              data-target="#listingSellModal">Create {{rangeTokenIds.length}} Orders</button>
                    </div>




                  </form>
              </div>
          </div>

        </div>
      </div>
  </section>

  <div *ngIf="!loading" class="modal" tabindex="-1" id="listingSellModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">Complete your listing</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block p-0">

          <div class="justify-content-around align-items-center border-top p-4 flex-wrap" [ngStyle]="{'display': hideSchedule == true ?'none':'flex'}">
            <div class="text-left text-white-main tx-14 font-weight-bold">
              NFT Contract
            </div>
            <div class="text-left">
              <div class="font-weight-bold tx-15 text-white-main" style="word-break: break-word;">{{nftAddress}}</div>
              <div class="text-muted tx-13">Token amount : {{rangeTokenIds.length}}</div>
              <div class="text-muted tx-13">Token IDs : {{rangeTokenIds}}</div>
            </div>
            <div class="text-left">
              <div><small class="text-muted tx-13">Price</small></div>
              <div>
                <small *ngIf="selectedPayment" class="tx-15 font-weight-bold">
                  <img [src]="selectedPayment.assetImage" width="16" height="16" class="mr-1 align-middle" alt=""> <span class="text-white-main">{{price | formatNumber:'6':'1.2-6'}} {{selectedPayment.symbol | toUpperCase}}</span>
                </small>
              </div>
            </div>
          </div>
          <div class="border-top p-4">
            <div class="text-muted  mb-3">
              <p class="tx-12">
              By listing this item, that's mean you accept to FreeCity's Privacy &amp; Terms, if you're not sure about our <a class="tx-12" [routerLink]="['/policy']" target="_blank">Privacy &amp; Terms</a> please read it before listing item.
              </p>
            </div>
            <div *ngIf="isApproved" class="col-12 text-center">
              <button class="btn btn-primary w-100 mt-3 mb-2 text-white max-w-300"
                      type="button" data-toggle="modal" [disabled]="price <= 0"
                      data-target="#listingSellModal" (click)="batchListing()">Listing</button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>

</div>
