<div class="main">
  <app-header-section></app-header-section>

  <!-- <app-music-box [musicPath]="'assets/sounds/morroc-sound.mp4'"  [volumn]="0.2"></app-music-box> -->

  <section>
    <div class="container">
      <div class="header-order-list d-flex justify-content-between align-items-center flex-wrap">
        <div class="mb-4">
          <h3 class="mt-0 mb-2 tx-24">Peer to Peer Trading</h3>
          <p class="text-muted tx-16 mt-0">Peer to Peer trading platform, token trading price as you want.</p>
        </div>
        <div class="mb-2">
          <span (click)="jumpToCreateOrder()" class="btn btn-primary text-white mb-2">
            Create Order
          </span>
          <span (click)="jumpToFaucet()" class="btn btn-warning text-white ml-2 mb-2">
            Faucet
          </span>
        </div>
      </div>
      <div class="filter-order-list d-flex align-items-center mb-4 mt-3 gap-2 flex-wrap">

        <div class="flex-grow-1">
          <div class="mb-2">Spending</div>
          <div class="dropdown">
            <button type="button" class="btn dropdown-toggle border py-3 px-3 w-100 shadow-none" (click)="openListTokenModal(modalListReceiveToken)">
              <div *ngIf="selectedReceiveToken" class="w-100">
                <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedReceiveToken.address.toLowerCase()}}.png?raw=true"
                     onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-1 rounded-circle" alt="">
                <span>
                  {{selectedReceiveToken.symbol | toUpperCase}}
                  <!-- {{selectedReceiveToken.address | hideWalletAddress}} -->
                </span>
              </div>
              <div *ngIf="!selectedReceiveToken" class="w-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin text-muted mr-1" viewBox="0 0 16 16">
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                </svg>
                <span>
                  All Token
                </span>
              </div>
            </button>
          </div>
        </div>

        <div class="flex-grow-1">
          <div class="mb-2">Receive</div>
          <div class="dropdown">
            <button type="button" class="btn dropdown-toggle border py-3 px-3 w-100 shadow-none" (click)="openListTokenModal(modalListToken)">
              <div *ngIf="selectedSpendToken" class="w-100">
                <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedSpendToken.address.toLowerCase()}}.png?raw=true"
                     onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-1 rounded-circle" alt="">
                <span>
                  {{selectedSpendToken.symbol | toUpperCase}}
                  <!-- {{selectedSpendToken.address | hideWalletAddress}} -->
                </span>
              </div>
              <div *ngIf="!selectedSpendToken" class="w-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin text-muted mr-1" viewBox="0 0 16 16">
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                </svg>
                <span>
                  All Token
                </span>
              </div>
            </button>
          </div>
        </div>

        <div class="flex-grow-1">
          <div class="mb-2">State</div>
          <div class="dropdown">
            <button type="button" data-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle border py-3 px-3 w-100 shadow-none">
              {{selectedEventType | eventTypeToText}}
            </button>
            <div class="dropdown-menu">
              <a (click)="selectEventType('created')" class="dropdown-item">
                {{'created' | eventTypeToText}}
              </a>
              <a (click)="selectEventType('closed')" class="dropdown-item">
                {{'closed' | eventTypeToText}}
              </a>
              <a (click)="selectEventType('cancel')" class="dropdown-item">
                {{'cancel' | eventTypeToText}}
              </a>
              <a (click)="selectEventType('all')" class="dropdown-item">
                {{'all' | eventTypeToText}}
              </a>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="mb-2">Sorting</div>
          <div class="dropdown">
            <button type="button" data-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle border py-3 px-3 w-100 shadow-none">
              {{selectedSortingDisplay}}
            </button>
            <div class="dropdown-menu">
              <a (click)="addSorting('createdAt:desc','Created: Recently')" class="dropdown-item">Created: Recently</a>
              <a (click)="addSorting('createdAt:asc','Created: Oldest')" class="dropdown-item">Created: Oldest</a>
              <!-- <a (click)="addSorting('price:asc','Price: Low to High')" class="dropdown-item">Price: Low to High</a>
              <a (click)="addSorting('price:desc','Price: High to Low')" class="dropdown-item">Price: High to Low</a> -->
            </div>
          </div>
        </div>
        <div class="">
          <div class="mb-2">Owner</div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitchOwner" [checked]="isOwnerFilter" (click)="toggleChecked()">
            <label class="custom-control-label" for="customSwitchOwner"></label>
          </div>
          <!-- <div class="dropdown">
            <input type="checkbox" [checked]="isOwnerFilter" (click)="toggleChecked()">
          </div> -->
        </div>
      </div>
      <div class="order-list-content mt-3">
        <div class="mt-4 mb-0 text-muted">{{this.totalResults}} items</div>
        <div class="">
          <div class="order-list-headers order-list-rows">
            <div class="order-list-col-no">#</div>
            <div class="order-list-col-spending">Spending</div>
            <div class="order-list-col-arrow-exchange"></div>
            <div class="order-list-col-recieve">Receive</div>
            <div class="order-list-col-state"></div>
            <div class="order-list-col-action"></div>
          </div>
          <button *ngFor="let order of orderList" class="order-list-rows border" (click)="jumpToOrderTrade(order)">
            <div class="order-list-col-no text-white-main">
              {{order.orderbookId}}
            </div>
            <div class="order-list-col-spending">
              <div class="d-flex align-items-center">
                <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{order.acceptedTokenAddr.toLowerCase()}}.png?raw=true"
                     onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="40" height="40" class="mr-2 rounded-circle">
                <div>
                  <div class="tx-14 text-muted">
                    {{order.acceptedTokenSymbol | toUpperCase}}
                  </div>
                  <div class="font-weight-medium text-white-main tx-18">
                    {{order.acceptedTokenAmountNumber | number:'1.0-6'}}
                  </div>
                </div>
              </div>
            </div>
            <div class="order-list-col-arrow-exchange">
              <svg id="Layer_1" enable-background="new 0 0 512 512" height="40" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg"><path d="m1.655 218.199c-3.358-8.106-1.502-17.436 4.701-23.64l98.699-98.699c8.475-8.475 22.208-8.475 30.682 0 8.472 8.47 8.472 22.208 0 30.678l-61.665 61.665h416.233c11.981 0 21.695 9.712 21.695 21.695s-9.714 21.695-21.695 21.695h-468.608c-8.775 0-16.684-5.288-20.042-13.394zm488.648 62.208h-468.608c-11.981 0-21.695 9.712-21.695 21.695s9.714 21.695 21.695 21.695h416.233l-61.667 61.665c-8.47 8.47-8.472 22.208 0 30.678 4.237 4.237 9.788 6.356 15.341 6.356 5.551 0 11.104-2.119 15.341-6.356l98.701-98.699c6.203-6.203 8.059-15.534 4.701-23.64s-11.267-13.394-20.042-13.394z"/></svg>
            </div>
            <div class="order-list-col-recieve">
              <div class="d-flex align-items-center">
                <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{order.itemAddr.toLowerCase()}}.png?raw=true"
                     onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="40" height="40" class="mr-2 rounded-circle">
                <div>
                  <div class="tx-14 text-muted">
                    {{order.itemSymbol | toUpperCase}}
                  </div>
                  <div class="font-weight-medium text-white-main tx-18">
                    {{order.itemAmountNumber | number:'1.0-6'}}
                  </div>
                  <div class="tx-12 text-muted">
                    {{order.acceptedTokenAmountNumber/order.itemAmountNumber | number:'1.0-6'}} {{order.acceptedTokenSymbol | toUpperCase}}/{{order.itemSymbol | toUpperCase}}
                  </div>
                </div>
              </div>
            </div>
            <div class="order-list-col-state">
              <div class="text-center">
                <span class="badge badge-{{order.status == 'created' ? 'warning':order.status == 'cancel'? 'danger':order.status == 'closed'? 'success':'secondary'}} tx-14">
                  {{order.status | eventTypeToText}}
                </span>
              </div>
            </div>
            <div class="order-list-col-action">
              <i class="icon-arrow-right text-muted tx-18 mr-1"></i>
            </div>
          </button>

          <div *ngIf="isOrderListLoading" class="col-12 text-center py-5">
            <div  class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <p class="text-center text-muted">Orders Loading</p>
          </div>

          <div *ngIf="isLazyLoading">
            <div class="col-12 text-center mt-5 py-5">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">lazy loading...</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-12 text-center py-5" *ngIf="!orderList.length && !isOrderListLoading && !isLazyLoading">
        <div class="text-center mb-2">
          <i class="icon-drawer tx-40"></i>
        </div>
        -- No order result --
      </div>
    </div>
  </section>

  <ng-template #modalListToken let-modal>
    <div class="modal-header px-4 pt-4 pb-2 mb-3">
      <h5 class="modal-title mt-0">Select Token</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="filter-token mb-4 px-4">
      <div class="input-group input-group-merge">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search font-medium-2"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          </span>
        </div>
        <input
          type="text"
          [(ngModel)]="spendTokenSearchText"
          (ngModelChange)="customImportToken()"
          class="form-control form-control-lg tx-16 bg-transparent pl-2"
          placeholder="Find a token by name or address"
        />
      </div>
    </div>
    <div class="modal-body d-block py-0 px-4">

      <div class="token-list-wrapper border-top">
        <button (click)="selectSpendToken(undefined);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-coin text-muted" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg>
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                All Token
              </div>
              <div class="tx-13 text-muted">
              </div>
            </div>
          </div>
        </button>
        <button *ngFor="let spendToken of spendTokens | filter:spendTokenSearchText" (click)="selectSpendToken(spendToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper mr-2">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{spendToken.address.toLowerCase()}}.png?raw=true"
                   onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="rounded-circle" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{spendToken.name}}
              </div>
              <div class="tx-13 text-muted" style="word-break: break-word;">
                {{spendToken.symbol | toUpperCase}}
                <!-- ({{spendToken.address}}) -->
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{spendToken.balance | number: "1.0-6"}}</div>
        </button>

        <!-- start customToken -->
        <button *ngIf="customToken" (click)="selectSpendToken(customToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{customToken.address.toLowerCase()}}.png?raw=true"
                   onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="rounded-circle" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{customToken.name}}
              </div>
              <div class="tx-13 text-muted">
                {{customToken.symbol | toUpperCase}}
                <!-- ({{customToken.address}}) -->
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{customToken.balance | number: "1.0-6"}}</div>
        </button>

        <div *ngIf="isCustomTokenLoading" class="col-12 text-center py-5">
          <div  class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="text-center text-muted">Custom Token Loading</p>
        </div>
        <!-- end customToken -->

      </div>

    </div>
  </ng-template>


  <ng-template #modalListReceiveToken let-modal>
    <div class="modal-header px-4 pt-4 pb-2 mb-3">
      <h5 class="modal-title mt-0">Select Token</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="filter-token mb-4 px-4">
      <div class="input-group input-group-merge">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search font-medium-2"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          </span>
        </div>
        <input
          type="text"
          [(ngModel)]="acceptedTokenSearchText"
          class="form-control form-control-lg tx-16 bg-transparent pl-2"
          placeholder="Find a token by name or address"
        />
      </div>
    </div>
    <div class="modal-body d-block py-0 px-4">

      <div class="token-list-wrapper border-top">
        <button (click)="selectReceiveToken(recieveToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-coin text-muted" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg>
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                All Token
              </div>
              <div class="tx-13 text-muted">
              </div>
            </div>
          </div>
        </button>
        <button *ngFor="let recieveToken of acceptedTokenAddresses | filter:acceptedTokenSearchText" (click)="selectReceiveToken(recieveToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper mr-2">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{recieveToken.address.toLowerCase()}}.png?raw=true"
                   onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="rounded-circle" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{recieveToken.name}}
              </div>
              <div class="tx-13 text-muted" style="word-break: break-word;">
                {{recieveToken.symbol | toUpperCase}}
                <!-- ({{recieveToken.address}}) -->
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{recieveToken.balance | number: "1.0-6"}}</div>
        </button>
      </div>

    </div>
  </ng-template>

  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>
</div>
