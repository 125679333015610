import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalLinkByType'
})
export class ExternalLinkByTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    let type = args[0]
    switch (type) {
      case "discord":
        return "https://discord.gg/" + value
      case "instagram":
        return "https://www.instagram.com/" + value
      case "medium":
        return "https://www.medium.com/@" + value
      case "telegram":
        return "https://t.me/" + value
      default:
        return "https://" + value
    }
  }

}
