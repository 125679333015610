<section class="campaign-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Intro -->
        <div class="intro mb-4">
          <div class="intro-content">
            <span>Exclusive Campaign</span>
            <h3 class="mt-3 mb-0">Meet the most exclusive campaign</h3>
          </div>
        </div>
        <div class="campaign-img-wrapper">
          <a [routerLink]="['/nft/launchpad/' + collectionSlug]" >
            <img src="https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/campaigns/yes/campaign.png" class="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
