import { CurrencyService } from './../../services/currency.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { FreeCityContractService } from 'src/app/services/free-city-contract.service';
import { SingletonService } from 'src/app/services/singleton.service';

import { Logger } from '../../services/logger.service';
import { LazyLoaderService } from 'src/app/services/lazy-loader.service';
const log = new Logger('NftOfferingComponent'); // log.debug

import { ethers } from "ethers";
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-nft-offering',
  templateUrl: './nft-offering.component.html',
  styleUrls: ['./nft-offering.component.scss']
})
export class NftOfferingComponent implements OnInit {

  @ViewChild('closebutton') closebutton;

  activateBatchBuySlugCollections = [
    "yes-nft-exclusive",
    "yes-batch-buy"
  ]

  collectionSlug

  selectedCollection
  collections

  isFetchingAsset
  isLoading = true

  limit = 25
  assets = []
  totalResults = 0
  page = 1
  loadedAll = false


  buyAmount = 0
  randomAssets = []
  batchBuyTotals = [
    // {
    // "paymentToken": "KUB",
    // "total": 1.5,
    // }
  ]
  approvedTotalAmount = 0
  isLoadingRandom = false
  isBatchBuyLoading = false
  isActivateBatchBuy = false
  isFixAmount = false

  feePercent
  devFeePercent

  constructor(
    public singletonService:SingletonService,
    public backendService:BackendService,
    private freeCityContractService:FreeCityContractService,
    public router:Router,
    private route: ActivatedRoute,
    private selectedFiltersService: SelectedFiltersService,
    private currencyService: CurrencyService,
    private lazyLoaderService:LazyLoaderService,
  ) {
    // init Selected Filters
    this.selectedFiltersService.initSelectedFilters()

    this.collectionSlug = this.route.snapshot.paramMap.get('collectionSlug');
    log.debug("this.collectionSlug => %o",this.collectionSlug)


  }

  async ngOnInit() {
    this.isActivateBatchBuy = this.activateBatchBuySlugCollections.includes(this.collectionSlug)
    log.debug("this.isActivateBatchBuy => %o",this.isActivateBatchBuy)

    this.isLoading = true
    await this.fetchCollections()
    this.isLoading = false



    this.selectedFiltersService.selectedFiltersChangedSubject.subscribe((event)=>{
      this.reloadData()
    })

    await this.reloadData()

    this.lazyLoaderService.lazyLoadSubject.subscribe(async ()=>{
      if(!this.loadedAll) await this.fetchAssets()
    })
  }

  resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.totalResults = 0
    this.assets = []
  }

  async reloadData(){
    await this.resetDatas()
    this.isFetchingAsset = await true
    if(this.selectedCollection){
      await this.fetchAssets()
    }
    this.isFetchingAsset = await false
  }


  async fetchCollections(){
    // const res:any = await this.backendService.getCollectionList()


    // this.collections = res.results
    // log.debug("this.collections => %o",this.collections)

    // if(!this.selectedCollection && this.collections.length){
    //   // this.selectedCollection = this.collections[0] // TODO: DELETE MOCK
    //   this.selectedCollection = this.collections.find(it => it.slug == this.collectionSlug)
    // }

    this.selectedCollection = await this.backendService.getCollectionDetailsBySlug(this.collectionSlug)
    log.debug("this.selectedCollection  => %o",this.selectedCollection )
  }

  async fetchAssets(){
    log.debug("fetchAssets this.selectedCollection => %o",this.selectedCollection)

    let eventTypesParams = this.selectedFiltersService.getEventTypesParams()
    let paymentTokenParams = this.selectedFiltersService.getPaymentTokenParams()
    let priceMinParams = this.selectedFiltersService.getPriceMinParams()
    let priceMaxParams = this.selectedFiltersService.getPriceMaxParams()
    let collectionsParams = this.selectedCollection ? [this.selectedCollection.id] : undefined // this.selectedFiltersService.getCollectionsParams()
    let categoriesParams = this.selectedFiltersService.getCategoriesParams()
    let chainsParams = this.selectedFiltersService.getChainsParams()
    let sortParams = this.selectedFiltersService.getSortParams()

    let traitsParams = await this.selectedFiltersService.getAttrsParams()

    let result:any = await this.backendService.getAssetAdvanceSearch(
      paymentTokenParams,
      chainsParams,
      undefined, // ownerAddress | this.selectedCollection.ownerAddress
      priceMinParams,
      priceMaxParams,
      collectionsParams,
      ['created'],
      categoriesParams,
      undefined, // creatorAddress
      undefined, // buyerAddress
      sortParams ? sortParams : 'updatedAt:desc', // updatedAt:desc | createdAt:desc | price:desc | price:asc
      this.limit, // limit
      this.page, // page
      2, // version
      traitsParams, // traitsParams
      true, // minted
      true, // lunchpad
    )

    log.debug("fetchAssets getAssetAdvanceSearch result => %o",result)

    let _assets:any = []
    if(result.results){
      _assets = await Promise.all(result.results.map(async (asset) => {
        if(asset.lastestOrder && asset.lastestOrder.acceptedTokenAddr){
          asset.lastestOrder.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(asset.lastestOrder.acceptedTokenAddr,asset.lastestOrder.chain)
          asset.lastestOrder.acceptedTokenSymbol = asset.lastestOrder.acceptedTokenInfo.symbol
        }
        return asset
      }));
    }

    log.debug("_assets => %o",_assets)


    this.assets = [...this.assets,..._assets]
    if(_assets.length < this.limit) this.loadedAll = true
    this.totalResults = result.totalResults
    this.page++
  }


  async selectColection(collectionId){
    log.debug("selectColection collectionId => %o",collectionId)
    this.selectedCollection = await this.collections.find(it => it.id == collectionId)
    log.debug("selectColection this.selectedCollection => %o",this.selectedCollection)

    await this.reloadData()
  }

  async randomNFTs(amount = undefined){
    this.isLoadingRandom = true

    if(amount != undefined){
      this.isFixAmount = true
      this.buyAmount = Number(amount)
    }else{
      this.isFixAmount = false
    }

    log.debug("randomNFTs this.buyAmount => %o",this.buyAmount)
    if(this.assets && this.assets.length){
      const randomInts = await this.getSuffledIntArray()
      log.debug("randomNFTs randomInts => %o",randomInts)
      this.randomAssets = []
      this.batchBuyTotals = []
      for (let index = 0; index < this.buyAmount; index++) {
        const rndInt = randomInts[index]
        log.debug("randomNFTs rndInt => %o",rndInt)
        const asset = this.assets[rndInt]
        log.debug("randomNFTs asset => %o",asset)

        // ============ start get fee ====================
        if(index == 0){

          const nftAddress = asset.collection.nftAddress
          const itemAddr = asset.lastestOrder.itemAddr

          const devFeePercent = await this.freeCityContractService.getDevFeePercent()
          log.debug("devFeePercent =>> %o",devFeePercent)

          let feePercent = 0
          if(await this.freeCityContractService.isFreeCityNFTAddress(nftAddress)){
            feePercent = asset.collection.royaltyFee
          }else{
            feePercent = await this.freeCityContractService.getFeePercent(itemAddr)
            log.debug("feePercent =>> %o",feePercent)
          }

          this.feePercent = formatNumber(feePercent,this.singletonService.locale,'1.2-2') +'%'
          this.devFeePercent = formatNumber(devFeePercent,this.singletonService.locale,'1.2-2') +'%'
        }
        // ============ end get fee ====================


        // ============ start cal totals ====================
        const paymentToken = asset.lastestOrder.acceptedTokenSymbol
        const total = asset.lastestOrder.acceptedTokenAmountNumber
        const acceptedTokenAddr = asset.lastestOrder.acceptedTokenAddr
        const acceptedTokenAmount = asset.lastestOrder.acceptedTokenAmount
        const isEther = (acceptedTokenAddr == ethers.constants.AddressZero)

        log.debug("randomNFTs paymentToken => %o",paymentToken)
        log.debug("randomNFTs total => %o",total)
        log.debug("randomNFTs acceptedTokenAddr => %o",acceptedTokenAddr)
        log.debug("randomNFTs acceptedTokenAmount => %o",acceptedTokenAmount)

        const _batchBuyTotal = this.batchBuyTotals.find(batchBuyTotal => batchBuyTotal.paymentToken == asset.lastestOrder.acceptedTokenSymbol)
        if(!_batchBuyTotal){
          this.batchBuyTotals.push({
            "paymentToken": paymentToken,
            "total": total,
            "nftName": asset.name,
            "lastestOrder": asset.lastestOrder,
            "isApproveLoading": false,
            // "isApproved": false,
            "isApproved": isEther ? true : await this.freeCityContractService.checkTokenApprovalForMarket(
              acceptedTokenAddr,
              acceptedTokenAmount
            ),
          })
        }else{
          _batchBuyTotal.total += total
        }
        // ============ end cal totals ====================

        this.randomAssets.push(asset)
      }

      log.debug("randomNFTs this.randomAssets => %o",this.randomAssets)
      log.debug("randomNFTs this.batchBuyTotals => %o",this.batchBuyTotals)

      this.approvedTotalAmount = this.batchBuyTotals.filter(batchBuyTotal => batchBuyTotal.isApproved).length
      log.debug("randomNFTs this.approvedTotalAmount => %o",this.approvedTotalAmount)

    }
    this.isLoadingRandom = false
  }

  async checkMaximum(){
    this.buyAmount = Number(this.buyAmount)
    log.debug("checkMaximum this.buyAmount => %o",this.buyAmount)
    log.debug("checkMaximum this.assets.length => %o",this.assets.length)
    if(this.buyAmount > this.assets.length){
      log.debug("set max => %o")
      this.buyAmount = this.assets.length
    }
  }

  async getSuffledIntArray(){
    let randomInts = await [...Array(this.assets.length).keys()];
    log.debug("getSuffledIntArray randomInts => %o",randomInts)
    return await randomInts
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }

  async doBatchBuy(){
    this.isBatchBuyLoading = true
    let responListPromises = []

    for (const randomAsset of this.randomAssets) {
      log.debug("doBatchBuy randomAsset => %o",randomAsset)
      const _promise = new Promise((resolve, reject) => {
        resolve(this.singletonService.buyNFTFreeCityMarket(randomAsset,true))
      })
      log.debug("doBatchBuy _promise => %o",_promise)
      responListPromises.push(_promise)
      log.debug("doBatchBuy responListPromises => %o",responListPromises)
    }

    log.debug("done loop... this.randomAssets => %o",this.randomAssets)

    await Promise.all(responListPromises).then(promises => {
      log.debug("doBatchBuy Promise.all promises => %o",promises)
      this.closebutton.nativeElement.click();

      const self = this
      setTimeout(function(){
        const criticalError = promises.find(_res => _res.status == 490)
        const successBuyAmount = promises.filter(_res => _res.status == 290 || _res.status == 291)

        if(!criticalError){

        }

        if(successBuyAmount){
          self.singletonService.fire('success','All transactions are confirmed.', 'Success to buy ' + successBuyAmount.length + ' NFTs.')

          const offeringRoute = promises.find(_res => _res.routerNavigate)
          log.debug("doBatchBuy offeringRoute => %o",offeringRoute)

          if(offeringRoute){
            self.router.navigate([offeringRoute]);
          }else{
            log.debug("waiting for reload page ...")
            setTimeout(function(){
              window.location.reload()
            },3000)
          }
        }

      },5000)

      this.isBatchBuyLoading = false
    }).catch(()=>{
      this.isBatchBuyLoading = false
    })

  }

  async approve(batchBuyTotal){
    batchBuyTotal.isApproveLoading = true

    const isApproved = await this.singletonService.modalConfirmApprove(
      batchBuyTotal.lastestOrder,
      batchBuyTotal.nftName
    )
    log.debug("approve isApproved => %o",isApproved)

    batchBuyTotal.isApproved = isApproved

    this.approvedTotalAmount = this.batchBuyTotals.filter(batchBuyTotal => batchBuyTotal.isApproved).length
    log.debug("randomNFTs this.approvedTotalAmount => %o",this.approvedTotalAmount)

    batchBuyTotal.isApproveLoading = false

    // setTimeout(() => {
    //   batchBuyTotal.isApproved = true

    //   this.approvedTotalAmount = this.batchBuyTotals.filter(batchBuyTotal => batchBuyTotal.isApproved).length
    //   log.debug("randomNFTs this.approvedTotalAmount => %o",this.approvedTotalAmount)

    //   batchBuyTotal.isApproveLoading = false

    // }, 1500);


  }
}
