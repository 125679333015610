<div class="main">
    <app-header-section></app-header-section>
    <app-hero-section></app-hero-section>

    <!-- <app-auctions-one-section></app-auctions-one-section> -->
    <!-- <app-top-seller-section></app-top-seller-section> -->
    <!-- <app-campaign-section></app-campaign-section> -->
    <app-collections-section
      [showedColorTitle]="'Featured Collections'"
      [showedTitle]="'Featured Collections'"
      [isHideShowedPeriodFiltered]="true"
      [isHideShowedExporeMore]="true"
      [isOnlyFeatured]="true"
    ></app-collections-section>
    <app-collections-section
      [showedColorTitle]="'MOST POPULAR'"
      [showedTitle]="'Popular Collections'"
      [isHideShowedPeriodFiltered]="false"
      [isHideShowedExporeMore]="false"
    ></app-collections-section>

    <!-- <app-explore-one-section></app-explore-one-section> -->
    <app-work-section></app-work-section>
    <app-footer-section></app-footer-section>
    <!-- <app-modal-search></app-modal-search> -->
    <app-modal-menu></app-modal-menu>
    <!-- <app-scrollup></app-scrollup> -->
</div>
