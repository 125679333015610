import { CurrencyService } from './../../services/currency.service';
import { LazyLoaderService } from './../../services/lazy-loader.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { BackendService } from './../../services/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { mockdata } from '../../mocks/nft_list_my_collected_nfts';
import { currentUserInfosMockdata } from '../../mocks/current_user_info';
import {MiningService} from "../../services/mining.service";

import { Logger } from '../../services/logger.service';
const log = new Logger('AccountComponent');

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  limit = 25
  loadedAll = {
    collected: false,
    craeted: false,
  }
  page = {
    collected: 1,
    craeted: 1,
  }
  totalResults = {
    collected: 0,
    craeted: 0,
  }
  assets:any = {
    collected: [],
    craeted: [],
  }

  account

  currentUserInfos:any

  searchText

  slugUser
  publicAddress

  tab = "collected"

  isFetchingAsset = false

  nftProfile:any;

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router: Router,
    private selectedFiltersService:SelectedFiltersService,
    private lazyLoaderService:LazyLoaderService,
    private currencyService:CurrencyService,
    private miningService:MiningService

  ) {
    // init Selected Filters
    this.selectedFiltersService.initSelectedFilters()

    this.route.queryParams.subscribe(params => {
      if(params['tab']){
        this.tab = params['tab'];
      }
    });
    this.slugUser = this.route.snapshot.paramMap.get('slugUser');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('static-header-mode')
  }


  async ngOnInit() {

    document.body.classList.add('static-header-mode')

    this.account = await this.singletonService.account
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account

      // await this.reloadData('1')
    });

    await this.reloadData('2')

    this.selectedFiltersService.selectedFiltersChangedSubject.subscribe((event)=>{
      this.reloadData('3')
    })

    this.lazyLoaderService.lazyLoadSubject.subscribe(async ()=>{
      if(!this.loadedAll[this.tab]) await this.fetchAsset(this.tab)
    })


  }

  resetDatas(){
    this.loadedAll = {
      collected: false,
      craeted: false,
    }
    this.page = {
      collected: 1,
      craeted: 1,
    }
    this.assets = {
      collected: [],
      craeted: [],
    }
    this.totalResults = {
      collected: 0,
      craeted: 0,
    }
  }

  async fetchNFTProfile(){
    let _user = this.publicAddress ? this.publicAddress:this.account
    log.debug("user to fetch profile ",_user)
    this.nftProfile = await this.miningService.getProfile(_user)
    log.debug("nftProfile ", this.nftProfile)
  }

  async reloadData(debug){

    log.debug('account reloadData called => ' + debug);
    await this.resetDatas()

    let res
    if(this.slugUser){
      res = await this.singletonService.getUserProfileInfosBySlug(this.slugUser)
    }else{
      res = await this.singletonService.getCurrentUserProfileInfos()
    }
    log.debug("xxxx res => %o",res)
    if(res){
      this.currentUserInfos = res
      log.debug("xxxx this.currentUserInfos => %o",this.currentUserInfos)

      this.currentUserInfos.joined = 'x'
      this.currentUserInfos.logoImageUrl = this.currentUserInfos.profileImage
      this.currentUserInfos.coverImageUrl = this.currentUserInfos.coverImage

      log.debug("xxxxzzzzzzzzzzzzzzzz this.currentUserInfos => %o",this.currentUserInfos)

      this.publicAddress = this.currentUserInfos.publicAddress

      this.isFetchingAsset = await true
      await this.fetchAsset('collected')
      await this.fetchAsset('created')
      this.isFetchingAsset = await false
    }else{
      this.router.navigate(['/error']);
      this.singletonService.fire("error","User slug not found!","slug: " + this.slugUser)
    }

    await this.fetchNFTProfile()
  }

  activeTab(tab){
    this.router.navigate(['/account/' + (this.slugUser ? this.slugUser : '')],{
      queryParams: { 'tab': tab }
    })
    this.tab = tab
  }

  async fetchAsset(_tab){

    let eventTypesParams = this.selectedFiltersService.getEventTypesParams()
    let paymentTokenParams = this.selectedFiltersService.getPaymentTokenParams()
    let priceMinParams = this.selectedFiltersService.getPriceMinParams()
    let priceMaxParams = this.selectedFiltersService.getPriceMaxParams()
    let collectionsParams = this.selectedFiltersService.getCollectionsParams()
    let categoriesParams = this.selectedFiltersService.getCategoriesParams()
    let chainsParams = this.selectedFiltersService.getChainsParams()
    let sortParams = this.selectedFiltersService.getSortParams()

    let traitsParams = await this.selectedFiltersService.getAttrsParams()

    let result:any = await this.backendService.getAssetAdvanceSearch(
      paymentTokenParams,
      chainsParams,
      undefined, // ownerAddress
      priceMinParams,
      priceMaxParams,
      collectionsParams,
      eventTypesParams,
      categoriesParams,
      _tab == 'created' ? this.publicAddress : undefined, // creatorAddress
      _tab == 'collected' ? this.publicAddress : undefined, // buyerAddress
      sortParams,
      this.limit,
      this.page[_tab],
      2, // version
      traitsParams, // traitsParams
      false, // minted
    )

    let _assets = await Promise.all(result.results.map(async (asset) => {
      if(asset.lastestOrder && asset.lastestOrder.acceptedTokenAddr){
        asset.lastestOrder.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(asset.lastestOrder.acceptedTokenAddr,asset.lastestOrder.chain)
        asset.lastestOrder.acceptedTokenSymbol = await asset.lastestOrder.acceptedTokenInfo.symbol
      }
      return asset
    }));
    log.debug("_assets => %o",_assets)

    this.assets[_tab] = [...(this.assets[_tab] ? this.assets[_tab] : []),..._assets]
    if(_assets.length < this.limit) this.loadedAll[_tab] = true
    this.totalResults[_tab] = result.totalResults
    this.page[_tab]++

    log.debug("this.assets[%o] => %o",_tab,this.assets[_tab])

  }

}
