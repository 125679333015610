import { CoreTranslationService } from './services/core-translation.service';
import { SingletonService } from './services/singleton.service';
import { Subject } from 'rxjs';
import { Component, OnInit , OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { locale as menuEnglish } from 'src/app/i18n/en';
import { locale as menuThai } from 'src/app/i18n/th';
import { Logger } from './services/logger.service';
import { environment } from 'src/environments/environment';
// import * as Waves from 'node-waves';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // title = 'netstorm';

  // private _unsubscribeAll: Subject<any>;

  appLanguage:any; // Set application default language i.e fr

  constructor(
    private singletonService: SingletonService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
  ) {
    this._translateService.addLangs([
      'en',
      'th',
    ]);

    this.appLanguage = localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : 'en';
    this._translateService.setDefaultLang('en');
    this._translateService.use(this.appLanguage ? this.appLanguage : 'en');
    this._coreTranslationService.translate(menuEnglish, menuThai);
  }

  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    // Waves.init();

    this.appLanguage = localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : 'en';
    if(!localStorage.getItem('appLanguage')){
      localStorage.setItem("appLanguage", 'en');
    }
    this.singletonService.currentLanguage.next(this.appLanguage);

    // disable log on production
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    // this._unsubscribeAll.next();
    // this._unsubscribeAll.complete();
  }
}
