<div id="menu" class="modal fade p-0 modal-menu-mobile">
    <div class="modal-dialog dialog-animated">
        <div class="modal-content h-100">
            <div class="modal-header border-bottom" data-dismiss="modal">
                Menu <i class="far fa-times-circle icon-close"></i>
            </div>
            <div class="menu modal-body align-items-start pt-0 px-0">
                <div class="w-100">
                    <div class="items p-0 text-center">
                      <ul class="navbar-nav items mx-auto">
                        <li class="nav-item dropdown">
                          <a class="nav-link text-white-main">Explore <i class="fas fa-angle-down ml-2"></i></a>
                          <ul class="dropdown-menu">
                              <li class="nav-item"><a [routerLink]="['/nfts']" class="nav-link" data-dismiss="modal">All NFTs</a></li>
                              <li class="nav-item"><a [routerLink]="['/explore-collections']" class="nav-link" data-dismiss="modal">Collections</a></li>
                              <!-- <li class="nav-item"><a [routerLink]="['/explore-projects']" class="nav-link" data-dismiss="modal">Projects</a></li> -->
                              <li class="nav-item"><a [routerLink]="['/whitelist/markets']" class="nav-link" data-dismiss="modal">Whitelist Markets</a></li>
                              <li class="nav-item"><a [routerLink]="['/nft/launchpads']" class="nav-link" data-dismiss="modal">NFT Launchpads </a></li>
                              <li class="nav-item"><a [routerLink]="['/nft/reveals']" class="nav-link" data-dismiss="modal">NFT Reveals </a></li>
                              <li class="nav-item"><a disabled class="nav-link text-muted">NFT Photobook <small class="ml-1">(Coming soon)</small></a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a class="nav-link text-white-main">Earn <i class="fas fa-angle-down ml-2"></i></a>
                          <ul class="dropdown-menu">
                              <li class="nav-item"><a [routerLink]="['/mining']" class="nav-link" data-dismiss="modal">NFT Mining</a></li>
                              <!-- <li class="nav-item"><a [routerLink]="['/crafting-stations']" class="nav-link" data-dismiss="modal">NFT Crafting</a></li> -->
                              <li class="nav-item">
                                <a [routerLink]="['/crafting-stations']" class="nav-link" data-dismiss="modal">NFT Crafting</a>
                              </li>
                            <li class="nav-item">
                              <a [routerLink]="['/nft/gashas']" class="nav-link" data-dismiss="modal">NFT Gachapon
                                <span style="margin-top: 2px" class="tx-12 badge-new badge-sm badge-pill bg-danger">NEW</span>
                              </a>
                            </li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item">
                          <a class="nav-link text-white-main" [routerLink]="['/mining']" data-dismiss="modal">NFT Mining</a>
                        </li> -->
                        <li class="nav-item">
                          <a class="nav-link text-white-main" [routerLink]="['/order-list']" data-dismiss="modal">P2P Trading</a>
                        </li>
                        <li class="nav-item dropdown">
                          <a class="nav-link text-white-main">Stat <i class="fas fa-angle-down ml-2"></i></a>
                          <ul class="dropdown-menu">
                            <li class="nav-item"><a [routerLink]="['/rankings']" class="nav-link" data-dismiss="modal">Rankings</a></li>
                            <li class="nav-item"><a [routerLink]="['/activity']" class="nav-link" data-dismiss="modal">Activity</a></li>
                          </ul>
                        </li>
                        <li *ngIf="account && !isWrongNetwork" class="nav-item dropdown ml-xl-auto icons">
                          <a class="nav-link menu-icons" *ngIf="currentChain">
                              <img [src]="currentChain.imgUrl" height="26" width="26" class="mr-1 align-top">
                              <span class="text-white-main" id="current-chain-name" style="display: none;">{{currentChain.networkName}}</span>
                              <i class="fas fa-angle-down ml-1"></i>
                          </a>
                          <ul *ngIf="singletonService.supportChains && currentChain" class="dropdown-menu">
                              <li class="nav-item" *ngFor="let chain of singletonService.supportChains | findAllBy: 'enabled'" (click)="switchChain(chain)">
                                <a class="nav-link d-flex justify-content-start text-white-main" [ngClass]="currentChain.networkName == chain.networkName?'active-chain' : ''" href="javascript:void(0)" data-dismiss="modal"><img [src]="chain.imgUrl" height="24" width="24" class="mr-2"> {{chain.networkName}}</a>
                              </li>
                          </ul>
                        </li>

                        <li class="nav-item cursor-pointer icons">
                          <a class="nav-link menu-icons" *ngIf="theme === 'light'" (click)="toggleTheme('dark')">
                            <i class="text-white-main far fa-moon tx-20"></i>
                          </a>
                          <a class="nav-link menu-icons" *ngIf="theme === 'dark'" (click)="toggleTheme('light')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" class="bi bi-sun" viewBox="0 0 16 16">
                              <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
