import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {NftService} from "../../services/nft.service";
import {environment} from "../../../environments/environment";
import { Logger } from '../../services/logger.service';
const log = new Logger('NftLaunchpadComponent');

@Component({
  selector: 'app-nft-launchpad',
  templateUrl: './nft-launchpad.component.html',
  styleUrls: ['./nft-launchpad.component.scss']
})
export class NftLaunchpadComponent implements OnInit {

  isLoading = true;
  isDataFetching=true;
  isApproving = false;
  id:any;
  slug:any;
  demoMode = false;
  account:any;
  isApproved = false
  isApproveLoading = false
  market:any;
  orders:any;
  isWhitelist = false;
  quotaLeft = 0
  isWhitelistChecking = true;

  isPause;
  isChecking = true;
  maxBuyPerWallet = 0;
  alreadyBuy = 0;
  allOrders = []

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    public router:Router,
    private route: ActivatedRoute,
    private modalService:NgbModal,
    private freeCityContractService:FreeCityContractService,
    private nftService:NftService
  ) {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.demoMode = environment.features.DEMO_MODE
  }

  async ngOnInit(){
    this.account = await this.singletonService.getCurrentConnectedAccount()
    this.market = await this.backendService.getLaunchpadMarket(this.slug)
    this.id = this.market.id
    this.isPause = await this.freeCityContractService.isLPMarketPause(this.market.address)
    this.isLoading = false;
    await this.checkApproved()
    //await this.fetchAssets();
    if(!this.isPause){
      try{
        this.isChecking = true
        await this.checkQuota()
        this.isChecking = false
      }catch (e){
        log.error(e)
      }
      this.orders = await this.freeCityContractService.getAvailableOrders(this.market.address)
      this.allOrders = await this.freeCityContractService.getAvailableOrderIds(this.market.address)
      if(this.market.shuffle){
        this.orders = this.orders.sort( () => Math.random() - 0.5)
      }
    }
    this.isDataFetching = false;
    log.debug("Orders")
    log.debug(this.orders)
  }

  async checkQuota(){

    this.quotaLeft = await this.freeCityContractService.getLPQuotaLeft(this.market.address, this.account)
  }

  async checkApproved(){
    this.isApproved = await this.freeCityContractService.checkTokenApprovalFor(this.market.paymentToken, this.market.price, this.market.address)
  }

  async approve(){
    this.isApproving = true
    this.freeCityContractService.approveTokenTo(this.market.paymentToken, this.market.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve receipt ", receipt)
      await this.singletonService.fire('success','Approved', 'Success approve payment.')
      await this.checkApproved()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproving = false
    })
  }

  async buy(orderId){
    log.debug("orderid "+orderId)
    let order = this.orders.find((obj) => obj.orderId === orderId)
    log.debug(order)
    order.loading = true


    this.freeCityContractService.buyFromWLMarket(orderId, this.market.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("buy receipt ", receipt)
      order.bought = true
      await this.checkQuota()
      await this.singletonService.fire('success','Buying', 'Success buying.')
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Buying failed', error.data.message)
    }).finally(()=>{
      order.loading = false
    })

  }

  async removeOrder(orderId){
    this.orders = this.orders.filter(object => {
      return object.orderId !== orderId;
    });
  }

  async jumpToFaucet(){
    this.router.navigate(['/faucets'])
  }

  playVDO(event) {
    log.debug('playVDO event : %o' , event);

    // const video = this.videoplayer.nativeElement

    var anyButton = event.target
    var buttonWrapper = anyButton.parentElement
    log.debug('playVDO buttonWrapper : %o' , buttonWrapper);

    var playButton = buttonWrapper.getElementsByClassName("img-play-vdo")[0];
    log.debug('playVDO playButton : %o' , playButton);

    var pauseButton = buttonWrapper.getElementsByClassName("img-pause-vdo")[0];
    log.debug('playVDO pauseButton : %o' , pauseButton);

    // var pauseButton = parentElement.getElementsByClassName("img-pause-vdo")[0];

    var parentElement = buttonWrapper.parentElement.parentElement
    log.debug('playVDO parentElement : %o' , parentElement);

    const video = parentElement.getElementsByClassName("video-player")[0];
    log.debug('playVDO video : %o' , video);


    // var playButton = document.getElementsByClassName("btn-action-vdo");
    // Event listener for the play/pause button
    // playButton.addEventListener("click", function() {
    if (video.paused == true) {
      // Play the video

      video.play();
      // this.videoplayer.nativeElement.play();

      // Update the button text to 'Pause'
      // playButton.innerHTML = "Pause";

      playButton.setAttribute("style", "display: none;");
      pauseButton.setAttribute("style", "display: block;");

      // playButton.hide()
      // pauseButton.show()
    } else {
      // Pause the video

      video.pause();
      // this.videoplayer.nativeElement.pause();

      // Update the button text to 'Play'
      // playButton.innerHTML = "Play";

      playButton.setAttribute("style", "display: block;");
      pauseButton.setAttribute("style", "display: none;");

      // playButton.show()
      // pauseButton.hide()
    }
    // });
  }

}
