import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-whitelist-markets-section',
  templateUrl: './whitelist-markets-section.component.html',
  styleUrls: ['./whitelist-markets-section.component.scss']
})
export class WhitelistMarketsSectionComponent implements OnInit {

  @Input() markets:any = []
  @Input() isLoading = false

  constructor(
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router
    ) { }

  async ngOnInit() {

  }

  goToMarket(market){
    this.router.navigate(['whitelist/market/' + market.id],{})
  }


}
