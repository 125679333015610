import { Injectable } from '@angular/core';
import {SingletonService} from "./singleton.service";
import BigNumber from 'bignumber.js';
import {ethers, logger, utils} from "ethers";
const AddressBook = environment.addressBook
import MOCK_NFT_ABI  from '../../app/configs/abis/free-city/mining/MockNFT721.json';
import UNBOXER_ABI  from '../../app/configs/abis/free-city/gacha/Unboxer.json';
import UNBOXER_V2_ABI  from '../../app/configs/abis/free-city/gacha/UnboxerV2.json';
import UNBOXER_V3_ABI  from '../../app/configs/abis/free-city/gacha/UnboxerV2.json';
import POSTMAN_ABI from '../../app/configs/abis/free-city/common/Postman.json';
import EGG_ABI from '../../app/configs/abis/free-city/gacha/PetEgg.json';
import UNBOXER_V4_ABI  from '../../app/configs/abis/free-city/gacha/UnboxerV4.json';
import {environment} from "../../environments/environment";
import { Contract, Provider } from 'bkc-ethcall';
import { Logger } from './logger.service';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
const log = new Logger('NftService');

@Injectable({
  providedIn: 'root'
})
export class NftService {

  addressBook:any;
  provider:any;
  publicProvider:any;
  multicallProvider:any;
  multicallPublicProvider:any;

  mockURI = 'https://ipfs.freecity.finance/ipfs/QmZ1sEaBdoZoZ1x4a1mMaSHrsqnCoiyacdkPids91LdU1R'
  mockURIs = [
    "https://gateway.freecity.finance/ipfs/QmR9akRaAucC7sAJBDVD4Ee9aJHmspBRu1furPQ4q29j7c",
    "https://gateway.freecity.finance/ipfs/QmR9akRaAucC7sAJBDVD4Ee9aJHmspBRu1furPQ4q29j7c",
    "https://gateway.freecity.finance/ipfs/QmR9akRaAucC7sAJBDVD4Ee9aJHmspBRu1furPQ4q29j7c",
  ]

  constructor(
    private httpClient: HttpClient,
    private singletonService:SingletonService,
  ) {
    this.addressBook = AddressBook.find(ab => ab.chainId == environment.chainId)
    log.debug("this.addressBook %o",this.addressBook)

    this.initProvider()
  }

  async initProvider(){
    let _chainId = await this.singletonService.getCurrentChainId()
    log.debug("Current chain id is "+_chainId)
    let chain = await this.singletonService.getSupportChain(_chainId)
    log.debug("chain ",chain)

    this.addressBook = AddressBook.find(ab => ab.chainId == _chainId)

    await this.singletonService.initProvider()
    if(!this.provider){
      this.provider = this.singletonService.provider
      if(this.provider){
        this.multicallProvider = new Provider();
        this.multicallProvider.init(this.provider)
      }

    }
    if(!this.publicProvider){
      this.publicProvider = this.singletonService.publicProvider
      if(!this.publicProvider){
        if (chain.use == 'wss') {
          this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
        } else if (chain.use == 'rpc') {
          this.publicProvider = await new ethers.providers.JsonRpcProvider(chain.rpc)
        } else {
          this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
        }

      }
      this.multicallPublicProvider = new Provider();
      this.multicallPublicProvider.init(this.publicProvider)
    }
  }

  async getOwnNFTIds(collectionAddress){
    let ids = []
    try{
      ids = await this.getOwnNFTIdsFromBlockchain(collectionAddress)
    }catch(e){
      log.debug("error")
      log.error(e)
    }finally {
      return ids
    }
  }

  async checkPublicProvider(){
    if(!this.publicProvider){
      await this.initProvider()
    }
  }

  // note : fetch owner length from blockchain in case erc20 support enumerable
  async getOwnNFTIdsFromBlockchain(collectionAddress){
    await this.checkPublicProvider()
    log.debug("fetch owned nft ids from blockchain using public provider %o",this.publicProvider)
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.publicProvider)
    let _account = await this.singletonService.getCurrentConnectedAccount()
    let amountOwned = await collection.balanceOf(_account)
    log.debug(" amountOwned - "+amountOwned)
    let tokenIds = []
    // TODO: Multicall/Promise.all
    let calls = []
    for (let index = 0; index < amountOwned; index++) {
      calls.push(collection.tokenOfOwnerByIndex(_account, index))
    }
    tokenIds = await Promise.all(calls)
    return tokenIds
  }

  async getNftInfos(collectionAddress, tokenIds, collectionName = ''){
    log.debug("fetch collection getNftInfos--> "+collectionAddress)
    let calls = tokenIds.map(id=> {
      let contract = new Contract(collectionAddress, MOCK_NFT_ABI)
      return contract.tokenURI(id)
    })
    if(this.multicallPublicProvider){
      let results = await this.multicallPublicProvider.tryAll(calls)
      log.debug("Multicall result = ",results)

      let metaCalls = results.map( r => {
        return this.httpClient.get(r).toPromise()
      })

      let metas:any = await Promise.all(metaCalls)
      log.debug("metas fetched ",metas)

      metas = metas.map( (meta, index) => {
        meta.tokenId = tokenIds[index]
        meta.collectionAddress = collectionAddress
        meta.collectionName = collectionName
        if(meta.attributes){
          let rarity =  meta.attributes.find(o=> o.trait_type == 'Rarity')
          meta.rarity = rarity ? rarity.value : undefined
        }else {
          meta.rarity = undefined
        }

        return meta
      })
      log.debug("meta get --- %o",metas)
      return metas
    }else{
      return [{}]
    }
  }

  async faucet(collectionAddress){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.provider.getSigner())
    return collection.faucet(this.mockURIs[(await this.generateRandom(0, this.mockURIs.length-1))])
  }

  async generateRandom(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min)
  }


  async getNFTMeta(collectionAddress, tokenId){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.publicProvider)
    let uri = await collection.tokenURI(tokenId)
    log.debug("getNFTMeta uri => %o",uri)

    if(uri && uri.indexOf("/ipfs/ipfs/")){
      uri = uri.replaceAll("/ipfs/ipfs/","/ipfs/")
    }

    let meta:any = await this.httpClient.get(uri).toPromise();
    return meta
  }

  async checkApprove(spender, asset){
    let collection = await new ethers.Contract(asset, MOCK_NFT_ABI, this.publicProvider)
    return await collection.isApprovedForAll(this.singletonService.account, spender)
  }

  async checkPostmanApprove(asset){
    let postmanAddress = this.addressBook.Postman
    return this.checkApprove(postmanAddress, asset)
  }

  async approve(spender, asset){
    let collection = await new ethers.Contract(asset, MOCK_NFT_ABI, this.provider.getSigner())
    return collection.setApprovalForAll(spender, true)
  }

  async approvePostman(asset){
    let postmanAddress = this.addressBook.Postman
    return this.approve(postmanAddress, asset)
  }

  async transfer(assetAddresses, tokenIds, recipient){
    log.debug("transfer %o with tokenids %o to %o",assetAddresses,tokenIds,recipient)
    let fee = ethers.utils.parseUnits( ''+(tokenIds.length * 0.2), 'ether')
    let postman = await new ethers.Contract(this.addressBook.Postman, POSTMAN_ABI, this.provider.getSigner())
    return postman.batchTransfer(recipient, assetAddresses, tokenIds, {value : fee})
  }

  // Unboxer part
  async unbox(reveal, assetTokenId){
    log.debug("reveal fee = "+reveal)
    let fee = ethers.utils.parseUnits(reveal.fee, 'ether')
    log.debug("fee = "+fee)
    let unboxer
    if(reveal.version == 2){
      unboxer = await new ethers.Contract(reveal.address, UNBOXER_V2_ABI, this.provider.getSigner())
    }else if(reveal.version == 3){
      unboxer = await new ethers.Contract(reveal.address, UNBOXER_V3_ABI, this.provider.getSigner())
    }else{
      unboxer = await new ethers.Contract(reveal.address, UNBOXER_ABI, this.provider.getSigner())
    }

    return unboxer.unbox(assetTokenId, {value : fee})
  }

  // gasha part
  async spin(gashaObject){
    let fee = ethers.utils.parseUnits(gashaObject.fee, 'ether')
    log.debug("fee = " + fee)
    let gasha = await new ethers.Contract(gashaObject.address, EGG_ABI, this.provider.getSigner())
    return gasha.spin({value:fee})
  }

  async redeem(tokenId, gashaObject){
    let fee = ethers.utils.parseUnits(gashaObject.fee, 'ether')
    let gasha = await new ethers.Contract(gashaObject.address, EGG_ABI, this.provider.getSigner())
    return gasha.redeem(tokenId, {value: fee})
  }

  async getRedeemAmountByTokenId(gashaObject, tokenId){
    let gasha = await new ethers.Contract(gashaObject.address, EGG_ABI, this.provider.getSigner())
    return gasha.checkRedeemAmount(tokenId)
  }

  async spinV2(gashaObject){
    let fee = ethers.utils.parseUnits(gashaObject.fee, 'ether')
    log.debug("fee = " + fee)
    let gasha = await new ethers.Contract(gashaObject.address, UNBOXER_V4_ABI, this.provider.getSigner())
    return gasha.unbox({value:fee})
  }

}
