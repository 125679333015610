import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('FindMinimunByFieldNamePipe'); // log.debug

@Pipe({
  name: 'findMinimunByFieldName'
})
export class FindMinimunByFieldNamePipe implements PipeTransform {

  transform(array: any, ...args: any[]): unknown {
    if(array && array.length){
      // log.debug("FindMinimunByFieldNamePipe array : %o",array)
      // log.debug("FindMinimunByFieldNamePipe args : %o",args)

      let minimum = undefined
      let fieldName = args[0]
      array.forEach(element => {
        let value = element[fieldName]
        // log.debug("value : %o",value)

        if(minimum == undefined) minimum = value
        if(minimum > value) minimum = value

        // log.debug("minimum : %o",minimum)
      });
      return minimum;
    }else{
      return undefined
    }
  }
}
