<div class="main">
  <app-header-section></app-header-section>

  <section class="text-center warn-hero" *ngIf="MY_CREATED_COLLECTIONS.length == 0" >
    <h3>Please create collection before create NFTs.</h3>
    <div>
      <a class="btn btn-primary text-white" [routerLink]="['/collection/create']">Create a collection</a>
    </div>
  </section>

  <section *ngIf="MY_CREATED_COLLECTIONS.length > 0" class="author-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12">
                <!-- Intro -->
                <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1">
                    <div class="intro-content">
                        <span>NFT</span>
                        <h3 class="mt-3 mb-0">
                          {{ id ? "Confirm NFT Details": "Create New NFT" }}
                          {{ isFreeze ? "(Freezed Metadata)" : ""}}
                        </h3>
                      <p>If you want to mint a big lot of NFTs, please contact us at Facebook fan page for fee discounting.</p>
                    </div>
                </div>
              <hr/>
              <div class="mt-4 alert alert-info" role="alert">
                Please review NFT details, if you change something please click <i>Update NFT Details</i> button before minting.
              </div>
                <!-- Item Form -->
                <div class="item-form card no-hover bg-transparent border-0 shadow-none">
                  <p *ngIf="!id" class="mb-5"><small ><b class="text-danger">*</b> Required fields</small></p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-5">
                              <div *ngIf="!id" class="txt-label-form font-weight-bold mb-2">
                                Upload Image
                                <!-- Video, Audio, or 3D Model  -->
                                <b class="text-danger">*</b>
                              </div>
                              <div *ngIf="id" class="txt-label-form font-weight-bold mb-2">
                                NFT Media
                              </div>
                              <p *ngIf="!id" class="mt-0 mb-3">
                                <small>
                                  File types supported: {{singletonService.SUPPORT_FILE_EXTENTIONS | toUpperCase}}
                                  <!-- SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF.  -->
                                  Max size: {{this.singletonService.ALLOW_FILE_SIZE_MAP['nft'].displaySize}}
                                </small>
                              </p>
                              <div class="">
                                <label class="input-nft-image-label">

                                  <!-- <video *ngIf="format==='video'" id="v" autoplay="autoplay" [src]="url" height="200" controls></video> -->

                                  <div *ngIf="format!=='video'" id="input-nft-image" class="show-img-preview"></div>
                                  <input *ngIf="!id" id="input-nft-image-instance" type="file" (change)="handleFileInput_nftFile($event.target.files)" hidden>
                                  <i class="icon-picture"></i>
                                  <button *ngIf="!id" [hidden]="!nftFile" (click)="clearFile_nft()" class="close btn-remove-img">&times;</button>
                                </label>
                              </div>
                            </div>
                        </div>
                        <div class="col-12">

                          <!-- ============================= START VDO TESTING ================================ -->
                          <!-- <input type="button" value="Capture" (click)="capture()"/>
                          <input type="button" value="Capture & Download" (click)="capture(true)"/>

                          <canvas id="c" width="1000" height="500"></canvas>
                          <div id="image_for_crop">image from canvas</div> -->
                          <!-- ============================= END VDO TESTING ================================ -->

                            <div class="form-group mb-5">
                              <div class="txt-label-form font-weight-bold mb-2">NFT Item Name
                                <b *ngIf="!id" class="text-danger">*</b>
                                <small>
                                  <span class="text-warning">
                                    (max size: 50)
                                  </span>
                                </small>
                              </div>
                              <input *ngIf="!isFreeze" maxlength="50" type="text" [(ngModel)]="name" class="form-control" name="name" placeholder="Item name" required="required">
                              <input *ngIf="isFreeze" disabled type="text" value="{{name}}" class="form-control" placeholder="Item name" required="required">
                            </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold">External link </div>
                            <p class="mt-1 mb-2">
                              <small>FreeCity will include a link to this URL on this item's detail page,
                              so that users can click to learn more about it. You are welcome to link to your own webpage with more details.</small></p>
                              <input *ngIf="!isFreeze" type="text" [(ngModel)]="externalLink" class="form-control" name="name" placeholder="https://yoursite.com/item/123">
                              <input *ngIf="isFreeze" disabled type="text" value="{{externalLink}}" class="form-control" name="name" placeholder="https://yoursite.com/item/123">

                          </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5">
                          <div class="txt-label-form font-weight-bold mb-2">Description <b class="text-danger">*</b></div>
                          <p class="mt-1 mb-2"><small>The description will be included on the item's detail page underneath its image.</small></p>
                          <textarea *ngIf="!isFreeze" [(ngModel)]="description" class="form-control" name="" id="" cols="30" rows="5" placeholder="Provide a detailed description of your item."></textarea>
                          <textarea *ngIf="isFreeze" disabled value="{{description}}" class="form-control" name="" id="" cols="30" rows="5" placeholder="Provide a detailed description of your item."></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-5">
                          <div>
                            <div class="txt-label-form font-weight-bold mb-2">Collection <b *ngIf="!id" class="text-danger">*</b> </div>
                            <p class="mt-1 mb-2"><small>This is the collection where your item will appear.</small></p>
                            <div class="dropdown mb-3">
                              <button class="btn dropdown-toggle w-100 dropdown-collection-selected shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                <span *ngIf="selected_collection">
                                  <img  [src]="selected_collection.logoImage | renderImageUrl: 'logo' : true" class="mr-2 rounded-circle" width="32" height="32">
                                  {{selected_collection.name}}
                                  <i [ngClass]="(selected_collection.verification) ? ((selected_collection.verification) | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                                  [title]="(selected_collection.verification) ? ((selected_collection.verification) | verificationTypeToText) : (undefined | verificationTypeToText)"
                                  class="ml-1"></i>
                                </span>
                                <span *ngIf="!selected_collection">
                                  Select Collection
                                </span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-full">
                                <a (click)="selectCollection(collection)" *ngFor="let collection of MY_CREATED_COLLECTIONS" class="dropdown-item">
                                  <img [src]="collection.logoImage | renderImageUrl: 'logo' : true" class="mr-2 rounded-circle" width="32" height="32">
                                  {{collection.name}}
                                  <i [ngClass]="(collection.verification) ? ((collection.verification) | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                                  [title]="(collection.verification) ? ((collection.verification) | verificationTypeToText) : (undefined | verificationTypeToText)"
                                  class="ml-1"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="collection-detail">
                            <div class="border-bottom">
                              <div class="d-flex align-items-center mt-3">
                                <div *ngIf="!isFreeze" class="form-group">
                                  <a (click)="createTempProperties()" class="btn-properties-plus cursor-pointer border shadow-none" data-toggle="modal" data-target="#addPropertiesModal">
                                    +
                                  </a>
                                </div>
                                <div [ngClass]="!isFreeze?'ml-4': ''" class="form-group d-flex justify-content-between">
                                    <div>
                                      <div class="mb-0">
                                        <b class="txt-label-form">Properties</b>
                                      </div>
                                      <div><small class="text-muted"> Textual traits that show up as rectangles</small></div>
                                    </div>
                                </div>
                              </div>
                              <div *ngIf="properties.length">
                                <div class="d-flex align-items-center gap-2 flex-wrap mb-3">
                                  <div *ngFor="let property of properties" class="property-card border">
                                    <span>{{property.trait_type}}</span>
                                    <p>{{property.value}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-items-center mb-3 mt-3 border-bottom">
                              <div class="form-group">
                                <div class="form-check form-check-inline mr-0">
                                  <input [checked]="isSensitiveContent" (click)="toggleIsSensitiveChecked()" class="form-check-input input-explicit mr-0 border" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="" checked>
                                  <label class="form-check-label" for="inlineRadio1"></label>
                                </div>
                              </div>
                              <div class="form-group d-flex ml-4 align-items-center justify-content-between">
                                <div class="">
                                  <div class="mb-0"><b class="txt-label-form">Explicit & sensitive content</b></div>
                                  <div><small class="text-muted"> This collection as explicit and sensitive content</small></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Supply</div>
                            <p class="mt-1 mb-2"><small>The number of items that can be minted. Currently you can mint only one item at a time. </small></p>
                            <input type="text" class="form-control" name="name" placeholder="" value="{{amountSupplyCreate}}" disabled>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group mb-5">
                            <div class="txt-label-form font-weight-bold mb-2">Blockchain </div>
                            <p class="mt-1 mb-2"><small>Select the blockchain where you'd like new items from this collection to be added by default.</small></p>
                            <div class="dropdown">
                              <button [disabled]="isFreeze" class="btn dropdown-toggle w-100 py-2 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                                <img [src]="blockchain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                <span style="text-transform: capitalize;">
                                  {{blockchain.networkName}}
                                </span>
                              </button>
                              <div *ngIf="!isFreeze" class="dropdown-menu dropdown-menu-full">
                                <a *ngFor="let chain of supportChains" class="dropdown-item" (click)="selectBlockchain(chain)">
                                  <img [src]="chain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
                                  {{chain.networkName}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12">
                          <div class="form-group mb-5">
                            <div div class="font-weight-bold mb-2">Freeze metadata</div>
                            <p class="mt-1 mb-2"><small>Freezing your metadata will allow you to permanently lock and store all of this item's content in decentralized file storage.</small></p>
                            <input type="text" class="form-control" name="name" placeholder="" value="To freeze your metadata, you must create your item first." disabled>
                          </div>
                        </div> -->
                        <div class="col-12 text-center">
                          <button *ngIf="!id" (click)="submit()" class="btn btn-primary mt-3 px-4 text-white max-w-300" type="submit">
                            Review NFT Details Before Mint
                          </button>
                          <button *ngIf="id" (click)="submit()" class="btn btn-warning mt-3 px-4 text-white max-w-300" type="submit">
                            Update NFT Details
                          </button>
                          <!-- <button *ngIf="id" [disabled]="isFreeze" (click)="freezeMetadata()" class="btn btn-primary w-100 mt-3 px-4 text-white max-w-100" type="submit">
                            Freeze Metadata
                          </button> -->
                          <button *ngIf="id" data-toggle="modal" data-target="#mintNFTProcess" class="btn btn-primary ml-3 mt-3 px-4 text-white max-w-300" type="submit">
                            Mint NFT
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>

  <div class="modal" tabindex="-1" id="mintNFTProcess">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">Mint NFT Process</h5>
          <button id="mintNFTProcess-close-btn" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block">
          <div>
            <h3>Mint NFT</h3>
            <p>
              Some details of the NFT are frozen in the process. You cannot change them after the NFT minted.
              <br>Please double check your information before confirming the minting.
            </p>
            <p class="text-danger">
              Minting Fee: 0.5 {{currentChain ? currentChain.nativeCurrency.symbol :'KUB'}} + Gas
            </p>
            <button [disabled]="isMinting" (click)="doMintNFT()" type="button" class="btn btn-primary text-white">Mint</button>

            <div *ngIf="isMinting" class="col-12 text-center py-5">
              <div  class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p class="text-center text-muted">Minting</p>
            </div>
            <!-- <button (click)="mintedNFT()">mintedNFT</button> -->
          </div>
        </div>
        <!-- <div class="modal-footer text-center">
        </div> -->
      </div>
    </div>
  </div>

  <div *ngIf="!isFreeze" class="modal" tabindex="-1" id="addPropertiesModal">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">Add Properties</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block">
          <p><small class="text-muted">Properties show up underneath your item, are clickable, and can be filtered in your collection's sidebar.</small></p>
          <div class="table">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let property of tempProperties">
                  <td>
                    <input [(ngModel)]="property.trait_type" type="text" class="form-control property-field" placeholder="Character">
                  </td>
                  <td>
                    <input [(ngModel)]="property.value" type="text" class="form-control property-field" placeholder="Male">
                  </td>
                  <td>
                    <div class="text-center">
                      <a (click)="removeProperty(property)" class="btn-delete-properties">&times;</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button (click)="addEmptyProperty()" type="button" class="btn btn-bordered-light btn-sm">
                Add more property
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button (click)="saveProperties()" type="button" class="btn btn-primary text-white" data-dismiss="modal" aria-label="Close">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>

