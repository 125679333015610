export const mockdata: any = {
  "isOwner": true, // if true can edit in flow ui
  "nftName": "Azuki",
  "tokenId": "8600",
  "nftDescription": "Tales of Ragnarok Avatar — NFT generative art for every player with utility use-cases in Tales of Ragnarok ecosystem such as profile, exclusive access, and future add-on integrated with the game and metaverse!",
  "ownedBy": "Wrister", // username of user >> can click to profile with this slug https://opensea.io/Wrister
  "nftLogoImageUrl": 'https://lh3.googleusercontent.com/KXUPFtisVOedahDmcdnmHHoy7P75DS50HxOxm05d9BzXMqaQpIfdyWyJQfpRkDaajB8sUQsN3vMVQfWSyqkfkMaRhhNbpO1Yr_wrLg=w600',

  // "topBidDetails": { // buying with bid
  //   "price":"23",
  //   "paymentToken":"WETH",
  //   "blockchain": "bkc",
  //   "quanity":"1",
  //   // "saleEnd": "April 9, 2022 at 11:59pm +07", // to be continue..
  // },
  "attributes": [
    {
      "trait_type": "backgroud",
      "value": "Off White A",
      "percent": 18,
    },
    {
      "trait_type": "clothing",
      "value": "Blue Bikini",
      "percent": 0.93,
    },
  ],
  "currentPrice": { // buying without bid
    "price":"0.25 ",
    "paymentToken":"KUB",
    "blockchain": "bkc",
    "quantity":"1",
    "saleEnd": "1664875046", // to be continue..
  },

  "collection": {
    "chain": "bkc",
    "favorite": "283",
    "createdBy": "TeamAzuki", // username of user >> can click to profile with this slug https://opensea.io/TeamAzuki
    "properties": [
      {
        "key": "backgroud",
        "value": "Off White A",
        "percent": 18,
      },
      {
        "key": "clothing",
        "value": "Blue Bikini",
        "percent": 0.93,
      },
    ],
    "description":"Take the red bean. View the collection.",
    "links": [
      { name:"website", value:"hello-world-nft.io"},
      { name:"discord", value: "hello-world-nft.io"},
      { name:"instagram", value: "hello-world-nft.io"},
      { name:"medium", value: "hello-world-nft.io"},
      { name:"telegram", value: "hello-world-nft.io"},

    ],
    "nftAddress":"0x495f947276749ce646f68ac8c248420045cb7b5e",
    "collectionName":"Azuki",
    "collectionTokenStandard":"ERC-721",
  }
}
