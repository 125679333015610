import { environment } from 'src/environments/environment';
import { MiningService } from './../../services/mining.service';
import { HideWalletAddressPipe } from './../../pipes/hide-wallet-address.pipe';
import { FreeCityContractService } from 'src/app/services/free-city-contract.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ethers, logger, utils} from "ethers";
import { SingletonService } from 'src/app/services/singleton.service';

import { Logger } from '../../services/logger.service';
const log = new Logger('CampaignComponent'); // log.debug

// import { ReCaptchaV3Service } from 'ng-recaptcha';

import ERC721_ABI from '../../../app/configs/abis/free-city/bkc-testnet/ERC721.json'
import { NftService } from 'src/app/services/nft.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

// const tempApiUrl = `https://3000.earn1.office.vpn.zchoolmate.com/`
const tempApiUrl = `${environment.apiUrl}`

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignComponent implements OnInit {

  campaignId:any // get from scan QR code
  campaign:any
  isLoading:any = false
  isRecieveNFTLoading:any = false
  isMintingNFTLoading:any = false

  hasLocationPermission:any = false
  isUserInCampaignArea:any = false
  isUserNotRobot:any = false
  isUserReceivedNFT:any = undefined
  receivedNFT:any;

  lat:any
  lng:any

  account:any

  captchaToken:any
  userLocation:any

  isEnabledCheckingUserAndCampaignLocation:any = false

  recaptchaSiteKey = `${environment.recaptchaSiteKey}`

  eventDetailInnerHTML:any = ''
  conditionDetailInnerHTML:any = ''

  isSameCampaignChain:any = false;
  isWrongNetwork:any;
  currentChain:any;

  constructor(
    private singletonService:SingletonService,
    private freeCityContractService:FreeCityContractService,
    private hideWalletAddressPipe:HideWalletAddressPipe,
    private nftService:NftService,
    private niningService:MiningService,
    private route: ActivatedRoute,
    private router:Router,
    private http: HttpClient,
  ) {
    this.campaignId = this.route.snapshot.paramMap.get('campaignId');
    log.debug('this.campaignId => ',this.campaignId)

  }

  async ngOnInit(){

    this.singletonService.isLoginAndLogoutWithoutRedirectToHomePage = true
    this.checkPermission();

    this.singletonService.accountSubject.subscribe(async (account) => {
      this.account = account
      log.debug(" account change! this.account %o ",this.account)
      // alert("account change!")
      if(account){
        this.checkPermission()
      }
    })

    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("xxx this.currentChain %o ",this.currentChain)


    // this.singletonService.isWrongNetworkSubject.subscribe((isWrongNetwork) => {
    //   this.isWrongNetwork = isWrongNetwork
    // })
  }


  ngOnDestroy() {
    this.singletonService.isLoginAndLogoutWithoutRedirectToHomePage = false
    // this.subscription.unsubscribe();
  }

  async getAccount(){
    this.account = await this.singletonService.getCurrentConnectedAccount()
    // this.account = await this.singletonService.account
    log.debug('CampaignComponent ngOnInit this.account : %o',this.account)
    if(this.account) {
      // this.singletonService.accountSubject.next(this.account)
    }
  }

  public addTokenLog(message: string, token: string | null) {
    // this.log.push(`${message}: ${this.formatToken(token)}`);
    log.debug('addTokenLog message : %o',message)
    log.debug('addTokenLog token : %o',token)

    if(token){
      this.captchaToken = token
      this.isUserNotRobot = true
    }else{
      this.captchaToken = undefined
      this.isUserNotRobot = false
    }
  }


  checkPermission(){

    if(this.isEnabledCheckingUserAndCampaignLocation){
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        log.debug('permissions result : %o',result)
        log.debug('permissions result.state : %o',result.state)

        if (result.state === 'granted') {
          // showLocalNewsWithGeolocation();
          // log.debug('this.lat : %o',this.lat)
          this.initialCampaignData();


        } else if (result.state === 'prompt') {
          // log.debug('this.lat : %o',this.lat)
          // showButtonToEnableLocalNews();
          this.initialCampaignData();

        }else{
          // Don't do anything if the permission was denied.
          this.hasLocationPermission = false

        }
       });
    }else{
      this.hasLocationPermission = true
      this.initialCampaignData();
    }
  }

  async initialCampaignData(){
    log.debug("-- initialCampaignData")



    let location:any
    let coords:any
    if(this.isEnabledCheckingUserAndCampaignLocation){
      try {
        location = await this.getLocation()
        this.userLocation = location
        this.hasLocationPermission = true
      } catch (e) {
        this.hasLocationPermission = false
      }

      coords = location.coords
      log.debug("user location : %o",location)
    }

    // this.campaign = await this.getCampaignDatas()
    this.campaign = await this.getCampaignDatas_api(this.campaignId)
    log.debug("xxx this.campaign : %o",this.campaign)

    if(this.campaign){
      this.campaign.chainInfo = this.singletonService.supportChains.find( it => it.networkAbbr == this.campaign.chain)
      log.debug("this.campaign.chainInfo : %o",this.campaign.chainInfo)

      log.debug("this.currentChain : %o",this.currentChain)


      if(this.currentChain && this.campaign.chainInfo.networkAbbr == this.currentChain.networkAbbr){
        this.isSameCampaignChain = true
        log.debug("this.isSameCampaignChain : %o",this.isSameCampaignChain)
      }else{
        this.isSameCampaignChain = false
      }

      this.campaign.decodedEventDetail = this.htmlDecode(this.campaign.eventDetail)

      if(this.isEnabledCheckingUserAndCampaignLocation){
        await this.checkUserInCampaignArea(coords)
        log.debug("this.isUserInCampaignArea : %o",this.isUserInCampaignArea)
      }else{
        this.isUserInCampaignArea = true
      }

      await this.getAccount()
      await this.checkUserReceivedNFT()
      log.debug("this.isUserReceivedNFT : %o",this.isUserReceivedNFT)
    }

  }

  getLocation() {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  }

  findDistanceInKmWithTwoCoordinates(lat1, lng1, lat2, lng2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p)/2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lng2 - lng1) * p))/2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  async checkUserInCampaignArea(coords){
    const userLat = coords.latitude
    const userLng = coords.longitude
    log.debug("userLat : %o",userLat)
    log.debug("userLng : %o",userLng)

    const campaignLat = this.campaign.coords.latitude
    const campaignLng = this.campaign.coords.longitude
    log.debug("campaignLat : %o",campaignLat)
    log.debug("campaignLng : %o",campaignLng)


    const distanceInKm = await this.findDistanceInKmWithTwoCoordinates(userLat,userLng,campaignLat,campaignLng)
    const distanceConditionInKm = (this.campaign.distanceConditionInMetres / 1000.0)
    log.debug("distanceInKm : %o",distanceInKm)
    log.debug("distanceConditionInKm : %o",distanceConditionInKm)


    if(distanceInKm <= distanceConditionInKm) this.isUserInCampaignArea = true
    else this.isUserInCampaignArea = false
  }

  async checkUserReceivedNFT(){
    this.isRecieveNFTLoading = true

    const collectionAddress = this.campaign.nftAddress
    log.debug("checkUserReceivedNFT collectionAddress : %o",collectionAddress)

    try{
      const balanceOf = Number(await this.freeCityContractService.getBalanceOfStandardNFT_ERC721(collectionAddress))
      log.debug("balanceOf : %o",balanceOf)

      if(balanceOf){
        this.isUserReceivedNFT = true
        // for (let index = 0; index < 1; index++) { // get first index
          let _tokenId = await this.freeCityContractService.getTokenOfOwnerByIndexOfStandardNFT_ERC721(collectionAddress, 0)// get first index nft only
          log.debug("_tokenId : %o",_tokenId)

          this.receivedNFT = await this.nftService.getNFTMeta(collectionAddress, _tokenId)
          this.receivedNFT.collectionAddress = collectionAddress
          this.receivedNFT.tokenId = _tokenId
          this.receivedNFT.imageUrl = this.receivedNFT.image && this.receivedNFT.image.indexOf("/ipfs/ipfs/") ? this.receivedNFT.image.replaceAll("/ipfs/ipfs/","/ipfs/") : this.receivedNFT.image

          log.debug("this.receivedNFT : %o",this.receivedNFT)

        // }
      }else{
        this.isUserReceivedNFT = false
      }
    }catch(error){
      log.error("error on checkUserReceivedNFT : %o",error)
      if(!this.isSameCampaignChain){
        this.singletonService.fire('error','Wrong network','Please switch network to the campaign network.')
      }else{
        this.singletonService.fire('error','Please connect wallet')
      }
    }


    this.isRecieveNFTLoading = false
  }

  getCampaignDatas(){

    const userLocationCoords = {
      latitude: this.userLocation.coords.latitude,
      longitude: this.userLocation.coords.longitude
    }
    // TODO: GET campaign datas from api
    return {
      id: 1,
      name: 'Campaign Name',
      description: 'Campaign description',
      eventDetail: 'Campaign event details',
      condition: 'Campaign condition details',
      bannerImage: "https://fullycrypto.com/wp-content/uploads/2021/03/XRP-Enthusiasts-in-the-US-Launch-RelistXRP-Campaign.png",

      chainId: 96,

      // nftAddress: '0xece33585a817f09ad44d69336dda8a4db8fff0d4', // not yet
      nftAddress: '0xd49C4e35ccd575b46176Eac69A3e60a89Eb0C27f', // already have

      location: 'location details',
      // coords: {
      //   // latitude: 7.08405, // 7.0840518 // in area
      //   // longitude: 100.56389, // 100.5638945 // in area

      //   latitude: 7.0, // // not in area
      //   longitude: 100.5, // // not in area
      // },
      coords: userLocationCoords,
      distanceConditionInMetres: 50,
    }

  }

  async recieveNFT(){
    // TODO: Calling api
    // alert("TODO: Calling api recieveNFT")
    this.isMintingNFTLoading = true
    try{
      const resCaptchaApi:any = await this.checkCaptchaApi(this.campaignId,this.captchaToken,this.account)
      if(resCaptchaApi.minted){
        this.singletonService.fire('success','You recieved the NFT.','Wait a moment, the system is processing.')

        const self = this
        setTimeout(async () => { // delay from chain
          await self.checkUserReceivedNFT()
          self.isMintingNFTLoading = false
        }, 6000);

      }else{
        this.isMintingNFTLoading = false
      }
    }catch(error){
      this.isMintingNFTLoading = false
      log.error("error on recieveNFT : %o",error)
      if(error && error.status == 401){
        this.singletonService.fire('error','Please connect wallet')
      }else{
        this.singletonService.fire('error','error on recieveNFT',error)
      }
    }

  }

  async connectWallet(){
    await this.singletonService.connectWallet()
  }

  // HOT FIX API
  async getCampaignDatas_api(
    campaignId
  ){
    const url = `${tempApiUrl}/v1/external/campaigns/` + campaignId
    log.debug("getCampaignDatas_api url : %o",url)

    let res = await this.http.get(url).toPromise()
    return res
  }

  async checkCaptchaApi(
    campaignId,
    captchaToken,
    account
  ){
    let data:any = {
      "captchaToken": captchaToken,
      "walletAddress": account,
    }

    log.debug("checkCaptchaApi data : %o",data)

    const url = `${tempApiUrl}/v1/external/campaigns/${campaignId}/random`
    log.debug("checkCaptchaApi url : %o",url)

    let res = await this.http.post(url,data).toPromise()
    log.debug('checkCaptchaApi res : %o',res)

    return res
  }

  htmlDecode(input: string): string {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }
}
