import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {NftService} from "../../services/nft.service";

import { Logger } from '../../services/logger.service';
import {SingletonService} from "../../services/singleton.service";
import {NftRevealResultModalComponent} from "./nft-reveal-result-modal/nft-reveal-result-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NftRevealResultV2ModalComponent} from "./nft-reveal-result-v2-modal/nft-reveal-result-v2-modal.component";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {ethers} from "ethers";
const log = new Logger('NftRevealComponent');
@Component({
  selector: 'app-nft-reveal',
  templateUrl: './nft-reveal.component.html',
  styleUrls: ['./nft-reveal.component.scss']
})
export class NftRevealComponent implements OnInit {

  isLoading = true;
  id:any;
  unboxer:any;
  demoMode = false;
  isFaucetLoading = false;
  assetIds = []
  assets = []
  account:any;
  isApproved = false
  isApproveLoading = false
  rewardIds = []
  rewards = []

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private freeCityContractService:FreeCityContractService,
    public router:Router,
    private route: ActivatedRoute,
    private modalService:NgbModal,
    private nftService:NftService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.demoMode = environment.features.DEMO_MODE
  }

  async ngOnInit(){
    this.account = await this.singletonService.account
    this.unboxer = await this.backendService.getReveal(this.id)
    await this.fetchAssets();
    this.isLoading = false;
    this.isApproved = await this.nftService.checkApprove(this.unboxer.address, this.unboxer.asset)
  }

  async approved(){
    this.isApproved =true
  }

  // Step 1
  async fetchAssets(){
    this.assetIds = await this.nftService.getOwnNFTIds(this.unboxer.asset)
    this.assets = await this.nftService.getNftInfos(this.unboxer.asset, this.assetIds, '')
    log.debug("_asset => %o",this.assets )
    this.rewardIds = await this.nftService.getOwnNFTIds(this.unboxer.reward)
    this.rewards = await this.nftService.getNftInfos(this.unboxer.reward, this.rewardIds, '')
    log.debug("_asset => %o",this.rewards )
  }

  async approve(){
    this.isApproveLoading = true
    this.nftService.approve(this.unboxer.address, this.unboxer.asset).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.approved()
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApproveLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','approve failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveLoading =false
    })
  }

  async reveal(nft){
    nft.isRevealLoading = true

    this.nftService.unbox(this.unboxer, nft.tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("Unboxed events ",receipt.events)
      let event = receipt.events.find((e)=> e.event === 'Unboxed')
      console.log(event)
      this.singletonService.fire('success','Unboxed success', 'Success Unboxed nft')

      if(this.unboxer.version == 2){
        let rewardType = event.args.rewardType
        let rewardMeta
        if(rewardType == 1){
          rewardMeta = await this.nftService.getNFTMeta(event.args.reward ,event.args.rewardTokenId)
          rewardMeta.tokenId = event.args.rewardTokenId
        }else{
          rewardMeta = await this.freeCityContractService.getTokenSymbol(event.args.reward)
        }

        let rewardTokenId = event.args.rewardTokenId
        let amount = ethers.utils.formatUnits(event.args.amount)
        const modalInstance = this.modalService.open(NftRevealResultV2ModalComponent,{
          centered: true,
          windowClass: 'modal modal-primary',
          size: 'lg',
          scrollable: false,
          backdrop: 'static',

        });

        modalInstance.componentInstance.reward = rewardMeta
        modalInstance.componentInstance.rewardType = rewardType
        modalInstance.componentInstance.rewardTokenId = rewardTokenId
        modalInstance.componentInstance.amount = amount
        modalInstance.componentInstance.rewardAddress = event.args.reward
        modalInstance.componentInstance.dataService.subscribe((data) => {
          log.debug("return data from modal %o",data)
        })
      }else{
        let reward = await this.nftService.getNFTMeta(event.args.reward ,event.args.rewardTokenId)
        const modalInstance = this.modalService.open(NftRevealResultModalComponent,{
          centered: true,
          windowClass: 'modal modal-primary',
          size: 'lg',
          scrollable: false,
          backdrop: 'static',

        });
        reward.tokenId = event.args.rewardTokenId
        modalInstance.componentInstance.reward = reward
        modalInstance.componentInstance.dataService.subscribe((data) => {
          log.debug("return data from modal %o",data)
        })
      }

      nft.hidden = true
      nft.isRevealLoading = false
    }).catch((error)=>{
      log.error("ERROR")
      log.error(error)
      if(error.data) {
        this.singletonService.fire('error','Unboxed failed', 'ERROR: '+error.data.message)
      }else{
        this.singletonService.fire('error','Something wrong', 'Please try again')
      }
    }).finally(async ()=>{
      nft.isRevealLoading = false
      await this.fetchAssets();
    })
  }

  async showInfo(nft){
    const modalInstance = this.modalService.open(NftRevealResultModalComponent,{
      centered: true,
      windowClass: 'modal modal-primary',
      size: 'lg',
      scrollable: false,
      backdrop: 'static',

    });
    modalInstance.componentInstance.reward = nft
    modalInstance.componentInstance.dataService.subscribe((data) => {
      log.debug("return data from modal %o",data)
    })
  }

  async faucet(){
    this.isFaucetLoading = true
    this.nftService.faucet(this.unboxer.asset).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("faucet events ",receipt.events)
      let transferEvent = receipt.events.find((e)=> e.event === 'Transfer')
      //this.tokenIds.push(transferEvent.args.tokenId)
      await this.fetchAssets()
      //await this.refresh()
      this.singletonService.fire('success','faucet success', 'Success faucet id '+transferEvent.args.tokenId)
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isFaucetLoading = false
    })
  }

}
