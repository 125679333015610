import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-explore-two',
  templateUrl: './breadcrumb-explore-two.component.html',
  styleUrls: ['./breadcrumb-explore-two.component.scss']
})
export class BreadcrumbExploreTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
