export const locale = {
  lang: 'en',
  data: {
    common: {
      test: 'test'
    },
    pagination:{
      previous: 'Previous',
      next: 'Next',
      items_per_page: 'Items per page',
    }
  }
}
