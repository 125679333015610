<!-- start selected filters -->
<!-- <p>
  eventTypes: {{selectedFiltersService.selectedFilters.eventTypes.length}} <br>
  collections: {{selectedFiltersService.selectedFilters.collections.length}} <br>
  chains: {{selectedFiltersService.selectedFilters.chains.length}} <br>
  categories: {{selectedFiltersService.selectedFilters.categories.length}} <br>
</p> -->

<div *ngIf="selectedFiltersService.isEmptySelectedFilters()" class="filter-lists d-flex align-items-center flex-wrap mt-4">

  <button (click)="removeEventType(eventType)" *ngFor="let eventType of selectedFiltersService.selectedFilters.eventTypes" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div>{{eventType | eventTypeToText}}</div>
    <span class="tx-28 ml-2 font-weight-light text-muted pt-1">&times;</span>
  </button>

  <!-- start selected collections zone -->
  <button *ngFor="let collection of selectedFiltersService.selectedFilters.collections" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="mr-2">
        <img class="rounded-circle" [src]="collection.logoImage | renderImageUrl:'logo'" width="30">
      </div>
      <div class="chains-name scroll-box-name">
        {{collection.name}}
        <span class="collections-verify ml-1">
          <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
          [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
          class=""></i>
        </span>
      </div>
    </div>
    <span (click)="removeSelectedCollection(collection)" class="tx-28 ml-2 font-weight-light text-muted pt-1">
      &times;
    </span>
  </button>
  <!-- end selected collections zone -->

  <!-- <button class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div>0xBTC</div>
    <span class="tx-28 ml-2 font-weight-light text-muted pt-1">&times;</span>
  </button> -->

  <!-- start selected chains zone -->
  <button *ngFor="let supportChain of selectedFiltersService.selectedFilters.chains" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="mr-2">
        <img class="rounded-circle" [src]="supportChain.imgUrl" width="30">
      </div>
      <div class="chains-name scroll-box-name">{{supportChain.networkName}}</div>
    </div>
    <span (click)="removeSelectedChain(supportChain)" class="tx-28 ml-2 font-weight-light text-muted pt-1">&times;</span>
  </button>
  <!-- end selected chains zone -->

  <!-- start selected PaymentToken zone -->
  <button *ngIf="selectedFiltersService.selectedFilters.price.paymentToken.symbol" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="mr-2 border rounded-circle">
          <img [src]="selectedFiltersService.selectedFilters.price.paymentToken.assetImage" width="12">
      </div>
      <div class="chains-name scroll-box-name">
        {{selectedFiltersService.selectedFilters.price.paymentToken.symbol | toUpperCase}}
        : >
        {{selectedFiltersService.selectedFilters.price.min | formatNumber: '2':'1.2-6'}}
        -
        {{selectedFiltersService.selectedFilters.price.max | formatNumber: '2':'1.2-6'}}
      </div>
    </div>
    <span (click)="setEmptyPaymentToken()" class="tx-28 ml-2 font-weight-light text-muted pt-1">
      &times;
    </span>
  </button>
  <!-- end selected PaymentToken zone -->

  <button (click)="removeCategory(category)" *ngFor="let category of selectedFiltersService.selectedFilters.categories" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div>{{category | categoryTypeToText}}</div>
    <span class="tx-28 ml-2 font-weight-light text-muted pt-1">&times;</span>
  </button>

  <button (click)="removeAttr(attr)" *ngFor="let attr of selectedFiltersService.selectedFilters.attrs" class="btn btn-outline-light font-weight-light px-3 py-2 mr-2 mb-2 border d-flex align-items-center justify-content-between">
    <div>{{attr.type}}: {{attr.value}}</div>
    <span class="tx-28 ml-2 font-weight-light text-muted pt-1">&times;</span>
  </button>

  <button class="btn font-weight-light p-0 ml-2 mr-2 mb-2 d-flex align-items-center justify-content-between shadow-none">
    <div class="text-primary" (click)="clearAll()">Clear All</div>
  </button>
</div>
<!-- end selected filters -->
