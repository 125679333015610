import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MiningService } from 'src/app/services/mining.service';
import { SingletonService } from 'src/app/services/singleton.service';

@Component({
  selector: 'app-poc-mining-section',
  templateUrl: './poc-mining-section.component.html',
  styleUrls: ['./poc-mining-section.component.scss']
})
export class PocMiningSectionComponent implements OnInit {

  @Input() pools:any = []
  @Input() soonPools:any = []

  @Input() isLoading = false

  constructor(
    private miningService:MiningService,
    private singletonService:SingletonService,
    private router:Router,
  ) { }

  ngOnInit(): void {
  }


  async goToMinningPool(pool){

    if(!(await this.singletonService.modalSwitchNetwork(pool.chain))){
      if(pool.miningType === 'reward'){
        await this.router.navigate(['/mining/reward/'+pool.id])
      }else{
        await this.router.navigate(['/mining/pool/'+pool.id])
      }
    }
  }

}
