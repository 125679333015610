<div class="main">
  <app-header-section></app-header-section>
  <div class="container">

  <section class="">
    <div class="row">
      <div class="col-12">
        <h3>Redeem</h3>
        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
        <hr/>
      </div>
    </div>
    <div class="row items">
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
        <div class="card p-0 w-100">
          <div class="image-over">
            <a (click)="goToRedeemCampaign()">
              <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
            </a>
          </div>
          <div class="card-caption p-4">
            <div class="card-body py-1 mb-2">
              <a (click)="goToRedeemCampaign()" >
                <h5 class="tx-16 mb-2 text-truncate">Campaign Name</h5>
              </a>
              <div>
                <hr class="my-3" />
                <div class="tx-12">
                  <span class="text-muted txt-3l-only font-weight-light tx-14">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis obcaecati qui inventore quos accusamus ad nam, enim quibusdam atque expedita, dolorem ullam fuga ducimus beatae perspiciatis? Vero natus ex deleniti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
