import { SingletonService } from './../../services/singleton.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { environment } from 'environments/environment';
// import { AuthenticationService } from 'app/auth/service';
import { Logger } from '../../services/logger.service';
const log = new Logger('JwtInterceptor');
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    // private _authenticationService: AuthenticationService
    private singletonService: SingletonService,
  ) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const currentUser = this._authenticationService.currentUserValue;
    // const isLoggedIn = currentUser && currentUser.token;
    const accessToken = this.singletonService.getAccessToken();
    log.debug(`accessToken`, accessToken);
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (accessToken && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }

    return next.handle(request);
  }
}
