import { SelectedFiltersService } from './../../services/selected-filters.service';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, HostListener, OnDestroy } from '@angular/core';

import { Logger } from '../../services/logger.service';
const log = new Logger('FilterSearchComponent'); // log.debug

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit, OnDestroy {
  public innerWidth = 9999;

  @Input() account:any = undefined
  @Input() collectionId:any = undefined

  @Input() enabledEventType = true
  @Input() enabledPrice = true
  @Input() enabledCollections = true
  @Input() enabledChains = true
  @Input() enabledCategories = true
  @Input() enabledAttributes = true

  @Input() defaultSelectedFilters = undefined

  hideFilter: boolean = false;
  hideEvent: boolean = false;
  hideStatus: boolean = false;
  hidePrice: boolean = false;
  hideCollections: boolean = true;
  hideChains: boolean = true;
  hideCategories: boolean = true;
  hideOnSale: boolean = true;
  hideBackground: boolean = true;


  supportChains:any = []
  blockchain
  paymentTokens:any = []
  categories:any = []
  collections:any = []

  selectedCollections:any = []
  isLoadingCollection = false

  selectedPaymentToken
  minSelectedPaymentToken = 0
  maxSelectedPaymentToken = 10000

  filterCollectionName
  delayTimer

  paymentTokensAllChain:any = []

  hideAttrs:any = {}
  collectionsInfoTraits = []
  searchTextAttr = {}

  constructor(
    private route: ActivatedRoute,
    private singletonService:SingletonService,
    private backendService:BackendService,
    private router: Router,
    public selectedFiltersService:SelectedFiltersService,
  ) {

  }

  ngOnDestroy(): void {
    document.body.classList.remove('hide-filter-search-leftsidebar')
  }

  async ngOnInit() {

    this.innerWidth = window.innerWidth;
    if(this.innerWidth <= 768) {
      this.hideFilter = true;
    }

    await this.selectedFiltersService.initSelectedFilters()
    await this.setDefaultSelectedFilters()

    this.supportChains = await this.singletonService.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)

    this.paymentTokensAllChain = await this.singletonService.getPaymentTokenListAllSupportedChain()
    log.debug("this.paymentTokensAllChain => %o",this.paymentTokensAllChain)

    const defaultChainAbbr = await this.singletonService.getChainAbbr()
    this.blockchain = await this.supportChains.find( it => it.networkAbbr == defaultChainAbbr)

    this.paymentTokens = this.paymentTokensAllChain
    log.debug("this.paymentTokens => %o",this.paymentTokens)

    // const currentChain = await this.singletonService.getCurrentChain()
    // if(currentChain){
    //   this.paymentTokens = this.paymentTokens.filter(it => it.chain == currentChain.networkAbbr )
    // }

    this.selectedPaymentToken = await this.paymentTokens.filter(it => it.force == true)[0]
    this.categories = await this.backendService.getCollectionCategoriesInList()
    log.debug("this.categories => %o",this.categories)


    await this.subscribeEventsWhenRemove()
    await this.fetchCollections()

    log.debug("this.collectionId => %o",this.collectionId)
    if(this.collectionId) await this.initAttrData()

  }

  async setDefaultSelectedFilters(){
    // log.debug("setDefaultSelectedFilters this.defaultSelectedFilters => %o",this.defaultSelectedFilters)
    if(this.defaultSelectedFilters){
      if(this.defaultSelectedFilters.eventTypesParams) await this.setEventTypes(this.defaultSelectedFilters.eventTypesParams)
    }
  }

  async subscribeEventsWhenRemove(){
    this.selectedFiltersService.removeSelectedCollectionSubject.subscribe((removedCollection) => {
      this.collections.push(removedCollection)
      if(this.selectedFiltersService.selectedFilters.collections.length == 0){
        // alert("removeAttrs")
        this.resetAttrParams()
      }

      this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    })
    this.selectedFiltersService.removeSelectedChainSubject.subscribe((chain) => {
      this.supportChains.push(chain)
      this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    })
    this.selectedFiltersService.removeSelectedAttrSubject.subscribe((attr) => {
      this.resetCheckboxAttr(attr)

    })

    this.selectedFiltersService.clearAllSubject.subscribe((selectedFilters) => {
      this.supportChains = [...this.supportChains,...selectedFilters.chains]
      this.collections = [...this.collections,...selectedFilters.collections]

      // ========== attr params ==========
      this.resetAttrParams()
      // =================================
    })
  }

  resetCheckboxAttr(attr){
    let checkboxElement:any = document.getElementById("attr-checkbox-"+attr.type+"-"+attr.value)
    log.debug("checkboxElement => %o",checkboxElement)
    if(checkboxElement){
      checkboxElement.checked = false
    }
  }

  resetAttrParams(){
    this.hideAttrs = {}
    this.collectionsInfoTraits = []
    this.searchTextAttr = {}
    this.selectedFiltersService.selectedFilters.attrs = []
  }

  // async getCollectionList(){
  //   this.isLoadingCollection = true
  //   this.backendService.getCollectionList().then(async (res:any) => {
  //     log.debug("getCollectionList res => %o",res)
  //     if(res.results){
  //       this.collections = await res.results.map( it => {
  //         it.collectionName = it.name
  //         it.floorPriceUSD = 'x'
  //         it.collectionImageUrl = it.logoImage
  //         it.collectionCoverImageUrl = it.featureImage
  //         it.tradeVolumnUSD = 'x'
  //         it.tradeVolumnChangedPercentByTypeUSD = 'x'
  //         return it
  //       })
  //     }
  //     this.isLoadingCollection = false
  //   }).catch((resError) => {
  //     this.singletonService.fire("error",resError.title,resError.error)
  //     this.isLoadingCollection = false
  //   })
  // }

  async delayFilterByName(){
    const self = this
    log.debug("delayFilterByName this.filterCollectionName => %o", this.filterCollectionName)
    log.debug("delayFilterByName this.isLoadingCollection => %o", this.isLoadingCollection)

    await clearTimeout(self.delayTimer);
    self.isLoadingCollection = await true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.fetchCollections()
        self.isLoadingCollection = await false
      }catch(err){
        self.isLoadingCollection = await false
      }
    }, 1000);

  }

  async fetchCollections(){
    let res = await this.backendService.getCollectionsInfoRanking(
      undefined, // resolution
      undefined, // chains
      undefined, // sortBy
      20,
      1,
      undefined,// categories
      this.filterCollectionName, // name
      this.account, // ownerAddress
    )

    let newResults:any = await Promise.all(res.results.map(async (it): Promise<any> => {
      it.collection.id = it.collection._id;
      return it.collection;
    }));

    this.collections = await this.getNonSelectedNewResults(newResults)
    log.debug("this.collections => %o",this.collections)
  }

  async getNonSelectedNewResults(newResults){
    // remove selectedCollection
    const self = this
    return newResults.filter(function(cv){
        return !self.selectedFiltersService.selectedFilters.collections.find(function(e){
            return e.id == cv.id;
        });
    });
  }

  isSelectedEventType(eventType){
    return this.selectedFiltersService.selectedFilters.eventTypes.indexOf(eventType) == -1 ? false : true
  }

  selectEventType(eventType){
    const index = this.selectedFiltersService.selectedFilters.eventTypes.indexOf(eventType);
    if (index > -1) {
      this.selectedFiltersService.selectedFilters.eventTypes.splice(index, 1);
    }else{
      this.selectedFiltersService.selectedFilters.eventTypes.push(eventType)
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  setEventTypes(eventTypes){
    this.selectedFiltersService.selectedFilters.eventTypes = eventTypes
    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  applyPrice(){
    let _price = {
      paymentToken: this.selectedPaymentToken,
      min:this.minSelectedPaymentToken,
      max:this.maxSelectedPaymentToken,
    }
    this.selectedFiltersService.selectedFilters.price = _price

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  async initAttrData(){
    if(this.selectedFiltersService.selectedFilters.collections.length == 1 || this.collectionId){
      if(this.collectionId) await this.getCollectionsInfoTraits(this.collectionId)
      else await this.getCollectionsInfoTraits(this.selectedFiltersService.selectedFilters.collections[0].id)
    }else{
      this.resetAttrParams()
    }
  }

  async selectionCollection(collection){
    log.debug("-- selectionCollection collection => %o",collection)
    log.debug("-- this.collections => %o",this.collections)

    let _collection = this.selectedFiltersService.selectedFilters.collections.find(it => it._id == collection._id)
    log.debug("-- Found _collection => %o",_collection)
    if(_collection){
      // collection.isHideFromSelected = false
      this.selectedFiltersService.selectedFilters.collections = this.selectedFiltersService.selectedFilters.collections.filter(it => it._id != _collection._id)
      this.collections.push(_collection)

      if(this.enabledAttributes) await this.initAttrData()
    }else{
      // collection.isHideFromSelected = true
      this.selectedFiltersService.selectedFilters.collections.push(collection)
      this.collections = this.collections.filter(it => it._id != collection._id)

      if(this.enabledAttributes) await this.initAttrData()
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  async selectChain(chain){
    let _chain = this.selectedFiltersService.selectedFilters.chains.find(it => it.networkName == chain.networkName)
    log.debug("-- Found _chain => %o",_chain)
    if(_chain){
      this.selectedFiltersService.selectedFilters.chains = this.selectedFiltersService.selectedFilters.chains.filter(it => it.networkName != chain.networkName)
      this.supportChains.push(chain)
    }else{
      this.selectedFiltersService.selectedFilters.chains.push(chain)
      this.supportChains = this.supportChains.filter(it => it.networkName != chain.networkName)
    }

    if(this.enabledPrice){
      await this.filterPaymentTokensBySelectedChains(this.selectedFiltersService.selectedFilters.chains)
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  async filterPaymentTokensBySelectedChains(chainList = []){
    log.debug("-- filterPaymentTokensBySelectedChains chainList => %o",chainList)
    // const currentChain = await this.singletonService.getCurrentChain()
    let _paymentTokens = []
    if(chainList.length){
      for (const chain of chainList) {
        const temp = this.paymentTokensAllChain.filter(it => it.chain == chain.networkAbbr )
        _paymentTokens = [..._paymentTokens,...temp]
      }
      log.debug("-- filterPaymentTokensBySelectedChains _paymentTokens => %o",_paymentTokens)
      this.paymentTokens = _paymentTokens
    }else{
      this.paymentTokens = this.paymentTokensAllChain
    }
    this.selectedPaymentToken = this.paymentTokens[0]
  }

  async getCollectionsInfoTraits(collectionId){
    log.debug("getCollectionsInfoTraits collectionId => %o",collectionId)
    let result:any = await this.backendService.collectionsInfoTraits(collectionId)
    log.debug("getCollectionsInfoTraits result => %o",result)
    this.collectionsInfoTraits = result
    if(this.collectionsInfoTraits.length){
      // log.debug("BEFORE this.collectionsInfoTraits => %o",this.collectionsInfoTraits)
      this.collectionsInfoTraits = await this.collectionsInfoTraits.map(it=> {
         it.properties.sort(this.compareValueName)
         return it
      })
      await this.collectionsInfoTraits.sort(this.compareTypeName)
      // log.debug("AFTER this.collectionsInfoTraits => %o",this.collectionsInfoTraits)

    }
  }

  compareTypeName( a, b ) {
    if ( a.type[0] < b.type[0] ){
      return -1;
    }
    if ( a.type[0] > b.type[0] ){
      return 1;
    }
    return 0;
  }

  compareValueName( a, b ) {
    if ( a.value[0] < b.value[0] ){
      return -1;
    }
    if ( a.value[0] > b.value[0] ){
      return 1;
    }
    return 0;
  }

  async selectCategory(category){
    const index = this.selectedFiltersService.selectedFilters.categories.indexOf(category);
    if (index > -1) {
      this.selectedFiltersService.selectedFilters.categories.splice(index, 1);
    }else{
      this.selectedFiltersService.selectedFilters.categories.push(category)
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }

  selectPaymentToken(paymentToken){
    this.selectedPaymentToken = paymentToken
  }

  selectAttr(event,type,value){
    let checked = event.target.checked
    if(checked){
      this.selectedFiltersService.selectedFilters.attrs.push({
        'type':type,
        'value':value
      })
    }else{
      this.selectedFiltersService.selectedFilters.attrs = this.selectedFiltersService.selectedFilters.attrs.filter(it =>
        it.type != type && it.value != value
      )
    }

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)

  }

  toggleSearchFilterMobile(){
    this.hideFilter = false;
  }

  toggleSearchFilter(){
    this.hideFilter = !this.hideFilter;
    if(!this.hideFilter) {
      console.log('hide filter is false');
      document.body.classList.remove('hide-filter-search-leftsidebar')
    } else {
      console.log('hide filter is true ***');
      document.body.classList.add('hide-filter-search-leftsidebar')
    }
  }

  toggleEventFilter() {
    this.hideEvent = !this.hideEvent;
  }

  toggleStatusFilter() {
    this.hideStatus = !this.hideStatus;
  }

  togglePriceFilter() {
    this.hidePrice = !this.hidePrice;
  }

  toggleCollectionsFilter() {
    this.hideCollections = !this.hideCollections;
  }

  toggleChainsFilter() {
    this.hideChains = !this.hideChains;
  }

  toggleCategoriesFilter() {
    this.hideCategories = !this.hideCategories;
  }

  toggleOnSaleFilter() {
    this.hideOnSale = !this.hideOnSale;
  }

  toggleBackgroundFilter() {
    this.hideBackground = !this.hideBackground;
  }

  toggleAttrsFilter(type) {
    this.hideAttrs[type] = !(this.hideAttrs[type] != undefined ? this.hideAttrs[type] : false);
    log.debug("yyy this.hideAttrs[%o] => %o",type,this.hideAttrs[type])
  }


  isSelectedCategory(category){
    let isSelectedCategory = this.selectedFiltersService.selectedFilters.categories.indexOf(category) == -1 ? false : true
    return isSelectedCategory
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth <= 768) {
      this.hideFilter = true;
    }
  }

}
