import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-wallet-connect',
  templateUrl: './breadcrumb-wallet-connect.component.html',
  styleUrls: ['./breadcrumb-wallet-connect.component.scss']
})
export class BreadcrumbWalletConnectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
