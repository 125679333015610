import { SingletonService } from './../../services/singleton.service';
import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
// const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'Authorization';    // for Node.js Express back-end

import { Logger } from '../../services/logger.service';
const log = new Logger('AuthInterceptor'); // log.debug

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private singletonService: SingletonService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signature/singin') && error.status === 401) {
        log.error(error)
        log.debug('try to refresh and inject new accessToken');
        return this.handle401Error(authReq, next);
      }
      return throwError(error);
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.singletonService.getRefreshToken();
      if (refreshToken)
        return this.singletonService.renewTokenWithRefreshToken(refreshToken).pipe(
          switchMap((tokens: any) => {
            const accessToken = tokens.access.token;
            this.isRefreshing = false;
            this.singletonService.updateTokens(tokens);
            this.refreshTokenSubject.next(accessToken);

            return next.handle(this.addTokenHeader(request, accessToken));
          }),
          catchError((err) => {
            log.error(err);
            this.isRefreshing = false;
            this.singletonService.disconnectWallet();
            return throwError(err);
          })
        );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    /* for Node.js Express back-end */
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }
}
