<div class="modal-header modal-header-primary pb-0">
  <div>
    <h4 class="modal-title text-white-main mt-0">You Received</h4>
    <p class="pt-0 mt-0">{{reward.name}} (Token ID: {{reward.tokenId}})</p>
  </div>

  <!-- <h4 class="modal-title text-white-main">Edit Wallet</h4> -->
  <button type="button" class="close close py-3 pr-0 pr-sm-3 pl-3" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col-12 text-center">
      <img [src]="reward.image" class="w-50"/>
    </div>
    <div class="col-12">
      <div class="text-center">
        <h5>{{reward.name}}</h5>
        <p class="tx-10">{{reward.description}}</p>
        <div class="row tx-12">
          <ng-container *ngFor="let attr of reward.attributes; let i = index">
            <div class="text-left col-sm-3"><strong>{{attr.trait_type}}</strong></div>
            <div class="text-left col-sm-3">{{attr.value}}</div>


          </ng-container>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.dismiss('Btn click')">
    Close
  </button>
</div>
