<div class="main">
  <app-header-section></app-header-section>
  <div >
    <div *ngIf="!isLoading" class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="unboxer.bannerImage | renderImageUrl: 'banner'" [alt]="unboxer.name">
        </div>
      </div>
    </div>
  </div>
  <section class="nft-reveal">

    <div *ngIf="isLoading" class="col-12 text-center py-5 my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="!unboxer">
      <h2 class="text-center">NFT Reveal not found</h2>
    </div>
    <div *ngIf="!isLoading && unboxer">
      <h2 class="text-center">{{unboxer.name}}</h2>
      <p class="text-center">{{unboxer.description}}</p>
    </div>

    <div  *ngIf="!isLoading" class="container">
      <div class="row" *ngIf="demoMode">
        <div class="col-12 mb-3">
          <hr/>
          <div class="col-md-12 text-center wrapper-btn-action-reward mb-3 mb-md-0">
            <a *ngIf="demoMode && !isFaucetLoading "
               class=" btn-animation btn-border-animation " (click)="faucet()">
              Tap faucet for NFT
            </a>

            <div class="text-center" *ngIf="isFaucetLoading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <hr/>
          <h5>Your NFTs ready to reveal.</h5>
          <ng-container *ngIf="assets.length === 0">
            <div class="text-center mt-5 mb-5">
              <h5 class="text-muted">You have no NFTs to reveal</h5>
            </div>
          </ng-container>
        </div>


        <ng-container *ngFor="let nft of assets">
          <div class="col-md-3 mb-3"  *ngIf="!nft.hidden" >
            <div class="box-nft card card-no-hover-animate">
              <div class="image-over">
                <div class="image-over-wrapper">
                  <img class="card-img-top"
                       [src]="nft.image"
                       alt="">
                </div>
              </div>
              <div class="card-body">
                <h4>{{nft.name}}</h4>
                <p class="tx-12 mt-0">Token ID: {{nft.tokenId}}</p>
              </div>
              <div class="card-footer border-top mx-0 mb-0 mt-5 py-0">
                <button *ngIf="isApproved" class="btn btn-primary text-white w-100" [disabled]="nft.isRevealLoading"
                        (click)="reveal(nft)">
                  <span *ngIf="nft.isRevealLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Reveal
                </button>
                <button *ngIf="!isApproved" class="btn btn-primary text-white w-100" [disabled]="isApproveLoading"
                        (click)="approve()">
                  <span *ngIf="isApproveLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Approve
                </button>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>

    <div *ngIf="!isLoading" class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <hr/>
          <h5>In your wallet.</h5>
          <ng-container *ngIf="rewards.length === 0">
            <div class="text-center mt-5 mb-5">
              <h5 class="text-muted">No Reward NFTs yet</h5>
            </div>
          </ng-container>
        </div>

        <div class="col-md-3 mb-3"  *ngFor="let nft of rewards">
          <div *ngIf="!nft.hidden" class="box-nft card card-no-hover-animate">
            <div class="image-over">
              <div class="image-over-wrapper">
                <img class="card-img-top"
                     [src]="nft.image"
                     alt="">
              </div>
            </div>
            <div class="card-body">
              <h4>{{nft.name}}</h4>
              <p class="tx-12 mt-0">Token ID: {{nft.tokenId}}</p>
            </div>
            <div class="card-footer border-top mx-0 mb-0 mt-5 py-0">
              <button  class="btn btn-xs btn-outline-light border mt-2 text-white w-100"
                      (click)="showInfo(nft)">
                Show Info
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>



  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
