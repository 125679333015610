<div class="main">

  <app-header-section></app-header-section>

  <section class="rankings-section">

    <h2 class="text-center">Top NFTs</h2>
    <p class="text-center mt-2">The top NFTs on FreeCity, ranked by volume, floor price and other statistics.</p>

    <div class="rankings-filter d-flex justify-content-center align-items-center gap-2">

      <div class="dropdown">
        <button class="btn dropdown-toggle py-2 border shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
          <span style="text-transform: capitalize;">
            {{resolution.displayText}}
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-full">
          <a (click)="selectResolution('7d','Last 7 days')" class="dropdown-item">Last 7 days</a>
          <a (click)="selectResolution('30d','Last 30 days')" class="dropdown-item">Last 30 days</a>
          <a (click)="selectResolution('3m','Last 3 months')" class="dropdown-item">Last 3 months</a>
          <!-- <a class="dropdown-item">All times</a> -->
        </div>
      </div>

      <!-- <div class="dropdown">
        <button class="btn dropdown-toggle py-2 border shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
          <i class="icon-grid mr-2"></i>
          <span style="text-transform: capitalize;">
            All categories
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-full">
          <a class="dropdown-item">
            <i class="icon-grid mr-2"></i>
            All categories
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/art-light.svg" class="mr-2" width="32" height="32">
            Art
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/collectibles-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Collectibles
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/domain-names-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Domain Names
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/music-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Music
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/photography-category-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Photography
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/sports-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Sports
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/trading-cards-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Trading
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/utility-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Utility
          </a>
          <a class="dropdown-item">
            <img src="https://opensea.io/static/images/icons/virtual-worlds-light.svg" class="mr-2 rounded-circle" width="32" height="32">
            Virtual Worlds
          </a>
        </div>
      </div> -->

      <div class="dropdown">
        <button class="btn dropdown-toggle py-2 border shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
          <i *ngIf="!selectedChain" class="icon-link mr-2"></i>
          <img *ngIf="selectedChain" [src]="selectedChain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
          <span style="text-transform: capitalize;">
            {{selectedChain ? selectedChain.networkName : 'All Chain'}}
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-width-custom">
          <a (click)="selectChain(undefined)" class="dropdown-item">
            <i class="icon-link mr-2"></i>
            {{'All Chain'}}
          </a>
          <a (click)="selectChain(supportChain)" *ngFor="let supportChain of supportChains" class="dropdown-item">
            <img [src]="supportChain.imgUrl" class="mr-2 rounded-circle" width="32" height="32">
            {{supportChain.networkName}}
          </a>
        </div>
      </div>

    </div>

    <div class="rankings-content mt-5">
      <div class="container">
        <div class="rankings-headers rankings-rows">
          <div class="rankings-col-collection text-white-main">Collection</div>
          <div class="rankings-col-volume text-white-main">{{resolution.value | toUpperCase}} Volume</div>
          <!-- <div class="rankings-col-24h text-white-main">24h %</div>
          <div class="rankings-col-7d text-white-main">7d %</div> -->
          <div class="rankings-col-floor-price text-white-main">Floor (USD)</div>
          <div class="rankings-col-floor-price text-white-main">Volume (USD)</div>
          <div class="rankings-col-owners text-white-main">Owners</div>
          <div class="rankings-col-items text-white-main">Items</div>
        </div>
        <a [routerLink]="['/collection/'+ _collection.collection.slug]" *ngFor="let _collection of collections; let i = index" class="rankings-rows" >
          <span class="rankings-col-collection">
            <span class="no text-white-main">
              <ng-container *ngIf="(i+1) == 1">
                <img src="/assets/img/rank/01.png" height="36">
              </ng-container>
              <ng-container *ngIf="(i+1) == 2">
                <img src="/assets/img/rank/02.png" height="36">
              </ng-container>
              <ng-container *ngIf="(i+1) == 3">
                <img src="/assets/img/rank/03.png" height="36">
              </ng-container>
              <ng-container *ngIf="(i+1) == 4">
                <img src="/assets/img/rank/04.png" height="36">
              </ng-container>
              <ng-container *ngIf="(i+1) > 4">
                {{i+1}}
              </ng-container>
            </span>
            <div class="ml-2 ml-md-4 img-event-wrapper mr-2">
              <img [src]="_collection.collection.logoImage | renderImageUrl:'logo'" width="50" height="50" class="img-event-content">
            </div>
            <span class="d-flex align-items-center">
              <span class="text-white-main tx-14 show-2-line">
                <!-- data-toggle="popover" [data-content]="_collection.collection.name" data-placement="bottom" -->
                {{_collection.collection.name}}
              </span>
              <span class="ml-1">
                <i [ngClass]="_collection.collection.verification ? (_collection.collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                [title]="_collection.collection.verification ? (_collection.collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)">
                </i>
              </span>
            </span>
          </span>
          <div class="rankings-col-volume text-white-main">
            <!-- <img [src]="_collection.collection.currentChainETH.assetImage"
              [title]="_collection.collection.currentChainETH.chain" width="16" class="mr-2"> -->
            <!-- <span>{{_collection.volumnEth.volumn | gameNumberFormat: 2 : false}}</span> -->
            <div>{{_collection.volumnUsd | gameNumberFormat: 2 : false}}</div>
            <!-- <br/> -->
            <span *ngIf="_collection.volumnThb" class="text-muted thb-value tx-11">
              (~ {{_collection.volumnThb | gameNumberFormat: 2 : false }} THB)
            </span>
          </div>
          <!-- <div class="rankings-col-24h">
            <span [ngClass]="_collection.price24HourPercent >= 0 ? 'text-success': 'text-danger'">
              {{_collection.price24HourPercent | gameNumberFormat: 2 : false : '%'}}
            </span>
          </div>
          <div class="rankings-col-7d">
            <span [ngClass]="_collection.price7DayPercent >= 0 ? 'text-success': 'text-danger'">
              {{_collection.price7DayPercent | gameNumberFormat: 2 : false : '%'}}
            </span>
          </div> -->
          <div class="rankings-col-floor-price text-white-main">
            <div>
              {{ _collection.floorUsd ? (_collection.floorUsd | gameNumberFormat: 2 : false) : '---' }}
            </div>
            <span *ngIf="_collection.floorThb" class="text-muted thb-value tx-11">
              (~ {{_collection.floorThb | gameNumberFormat: 2 : false }} THB)
            </span>
          </div>
          <div class="rankings-col-floor-price text-white-main">
            <div>
              {{ _collection.collection.tradeVolumn ? (_collection.collection.tradeVolumn.usd | gameNumberFormat: 2 : false) : '---' }}
            </div>
            <span *ngIf="_collection.collection.tradeVolumn" class="text-muted thb-value tx-11">
              (~ {{_collection.collection.tradeVolumn.thb | gameNumberFormat: 2 : false }} THB)
            </span>
          </div>
          <div class="rankings-col-owners">
            {{_collection.collection.statistic ? (_collection.collection.statistic.owners| gameNumberFormat: 0 : false) : 0 }} owners
          </div>
          <div class="rankings-col-items">
            {{_collection.collection.statistic ? (_collection.collection.statistic.assets| gameNumberFormat: 0 : false) : 0 }} assets
          </div>
        </a>
        <div *ngIf="!collections.length && !isLoading" class="col-12 text-center py-5">
            <div class="text-center mb-2">
            <!-- <i class="icon-drawer tx-40"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-list-stars" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"/>
              <path d="M2.242 2.194a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.256-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53z"/>
            </svg>
          </div>
          -- No ranking collection data --
        </div>
        <div *ngIf="isLoading" class="col-12 text-center py-5">
            loading...
        </div>
      </div>
    </div>
    <div *ngIf="isLazyLoading">
      <div class="col-12 text-center py-5">
        <div  class="spinner-border text-primary" role="status">
          <span class="sr-only">lazy loading...</span>
        </div>
      </div>
    </div>

  </section>

  <app-modal-menu></app-modal-menu>

</div>
