import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { FreeCityContractService } from 'src/app/services/free-city-contract.service';
import { SingletonService } from 'src/app/services/singleton.service';

import { Logger } from '../../services/logger.service';
const log = new Logger('NftBatchSellComponent');

import { ethers } from 'ethers';
import * as _ from 'lodash';
import { TOKEN_TYPE } from "../../constant/valuables";

@Component({
  selector: 'app-nft-batch-sell',
  templateUrl: './nft-batch-sell.component.html',
  styleUrls: ['./nft-batch-sell.component.scss']
})
export class NftBatchSellComponent implements OnInit {

  currentChain:any

  nftAddress:any = null
  fromTokenId:any
  toTokenId:any
  amount:any
  payments:any
  price:any

  rangeTokenIds = []
  supportChains
  loading = true

  selectedPayment
  isApproved = false

  canUseNFTAddress
  isApproveLoading = false;
  isListingLoading = false;

  constructor(
    public singletonService:SingletonService,
    public backendService:BackendService,
    private freeCityContractService:FreeCityContractService,
    public router:Router,
    private route: ActivatedRoute,
  ) {

  }

  async ngOnInit(){
    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("this.currentChain => %o",this.currentChain)



    if(this.currentChain){
      await this.initFormData()
    }
    this.loading = false


  }

  async initFormData(){
    this.payments = await this.backendService.getPaymentTokensByChainAbbr(this.currentChain.networkAbbr)
    log.debug("this.payments => %o",this.payments)

    this.selectedPayment = this.payments[0]
  }

  async checkApproved(){
    log.debug("checkApproved... this.nftAddress : %o",this.nftAddress)
    this.isApproved = await this.freeCityContractService.checkApprove(this.nftAddress)
    log.debug("checkApproved this.isApproved => %o",this.isApproved)

  }


  delayTimer
  isLoadingValidateSlug = false
  async isValidNFTAddressWithDelay(){
    const self = this

    clearTimeout(self.delayTimer);
    self.isLoadingValidateSlug= true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.checkNftAddress()
        self.isLoadingValidateSlug= false
      }catch(err){
        self.isLoadingValidateSlug= false
      }
    }, 1000);

  }

  async checkNftAddress(){
    const isResetFlag = (this.nftAddress == undefined) || (this.nftAddress.trim() == '')
    if(isResetFlag){
      this.canUseNFTAddress = undefined
    }else{
      if(!ethers.utils.isAddress(this.nftAddress)){
        this.canUseNFTAddress = false
      }else{
        try{
          await this.checkApproved()
          this.canUseNFTAddress = true
        }catch(err){
          this.canUseNFTAddress = false
        }
      }
    }
  }

  async calculateRangeTokenIds(){
    log.debug("calculateRangeTokenIds...")
    if(this.fromTokenId != undefined && this.toTokenId != undefined){
      this.rangeTokenIds = await this.getRangeNumber(this.fromTokenId,this.toTokenId)
      log.debug("this.rangeTokenIds => %o",this.rangeTokenIds)
    }else{
      this.rangeTokenIds = []
    }
  }

  async getRangeNumber(from,to,step = 1){ // (0,10,step = 1) =>  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    let range_arr = _.range(from,Number(to)+1,step);
    log.debug("range_arr => %o",range_arr)
    return range_arr
  }

  async approve(){
    this.isApproveLoading = true
    this.freeCityContractService.doApprove(this.nftAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve receipt ", receipt)
      this.singletonService.fire('success','Approved', 'Success approve contract to listing on market.')
      await this.checkApproved()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproveLoading = false
    })
  }

  selectPayment(payment){
    this.selectedPayment = payment
  }

  batchListing(){
    this.isListingLoading = true
    log.debug("selected payment ",this.selectedPayment)
    let itemIds = this.rangeTokenIds
    let tokenType = TOKEN_TYPE.ERC721 // TODO : receive from server
    let itemAddr = this.nftAddress
    let itemAmount = 1
    let acceptedTokenId = 0
    let acceptedTokenType = this.selectedPayment.tokenType
    let acceptedTokenAddr = this.selectedPayment.address
    let acceptedTokenAmount = ethers.utils.parseEther(this.price)

    log.debug(" itemIds ",itemIds)
    log.debug(" tokenType ",tokenType)
    log.debug(" itemAddr ",itemAddr)
    log.debug(" itemAmount ",itemAmount)
    log.debug(" acceptedTokenId ",acceptedTokenId)
    log.debug(" acceptedTokenType ",acceptedTokenType)
    log.debug(" acceptedTokenAddr ",acceptedTokenAddr)
    log.debug(" acceptedTokenAmount ",acceptedTokenAmount.toString())

    this.freeCityContractService.batchListing(
      itemIds,
      tokenType,
      itemAddr,
      itemAmount,
      acceptedTokenId,
      acceptedTokenType,
      acceptedTokenAddr,
      acceptedTokenAmount
    ).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("receipt.events ",receipt.events)
      let createdEvent = receipt.events.find((e)=> e.event === 'Created')
      log.debug("createdEvent ",createdEvent)
      log.debug("order id : ",createdEvent.args.id)
      log.debug("seller : ",createdEvent.args.seller)
      log.debug("meta : ",createdEvent.args.meta)
      log.debug("order: ",createdEvent.args.order)
      this.singletonService.fire('success','Listing', 'Success listing on market.')

      // redirect
      this.router.navigate(['/account'])

    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Listing failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isListingLoading = false
    })
  }

}
