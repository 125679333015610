<div class="main">

  <app-header-section></app-header-section>

  <div>
    <div class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img src="assets/img/campaign/yes-cover.png" alt="yes-cover-campaign">
        </div>
      </div>
    </div>
    <div class="info-wrapper container">
      <div class="row campaign-intro-wrapper">
        <div class="col-md-7">
          <div class="">
            <div class="campaign-info-wrapper">
              <h2 class="tx-36 m-0 font-kanit font-weight-bolder">YES NFT EXCLUSIVE CAMPAIGN</h2>
              <p class="tx-16 mb-0 mt-2 text-muted font-kanit">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima praesentium enim quo porro id laudantium fugit pariatur quibusdam non possimus ab corrupti cumque deserunt, adipisci ea! Animi fugit magni alias.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div>
            <div class="campaign-reward-wrapper">
              <h5 class="tx-20 mx-0 my-2 font-weight-normal text-center font-kanit">รางวัลที่คุณได้รับ</h5>
              <div class="d-flex align-items-center justify-content-end">
                <h1 class="font-weight-bolder tx-44 m-0 font-kanit">
                  1,234.56
                </h1>
                <div class="d-flex align-items-center">
                  <img src="assets/img/campaign/yes-token.png" class="mx-2" width="24" height="24" alt="yes token">
                  <span class="tx-20 font-kanit">YES</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="campaign-register-wrapper py-5">
        <h3 class="text-center text-center mt-0 mb-4 tx-32 font-kanit">ลงทะเบียนสั่งซื้อด้วยช่องทางต่อไปนี้</h3>
        <div class="d-flex align-items-center justify-content-center flex-wrap">
          <button class="btn-primary btn mx-2 font-kanit text-white">ลงทะเบียนสั่งซื้อด้วย 1,500 KUSDT</button>
          <button class="btn-primary btn mx-2 font-kanit text-white" (click)="openQRCodeModal(qrcodeModal)">ลงทะเบียนสั่งซื้อด้วยเงินสด 50,000 บาท</button>
        </div>

        <ng-template #qrcodeModal let-modal>
          <div class="text-right wrapper-close-modal">
              <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body pt-3 pb-5">
            <div class="wrapper-qrcode-inner text-center mx-auto mb-3">
              <img src="assets/img/campaign/qrcode-mock.png" class="qrcode-img" alt="qrcode mock">
              <p class="text-white-main mt-4 mb-2 pt-2 font-kanit">ชื่อบัญชี : account name</p>
              <p class="text-white-main mt-0 font-kanit">เลขบัญชี : 999-999-999</p>
            </div>
          </div>
        </ng-template>

      </div>
      <hr>
      <div class="campaign-coupon-wrapper py-5">
        <h3 class="text-center mt-0 mb-4 tx-32 font-kanit">คูปองของคุณ</h3>
        <div class="card card-coupon p-0">
          <div class="d-flex align-items-center justify-content-center">
            <div class="coupon-img-wrapper">
              <img src="assets/img/campaign/yes-img.png" w alt="yes campaign">
            </div>
            <div class="coupon-info-wrapper p-4">
              <div class="text-white-main font-kanit">คูปอง 0001</div>
              <div class="text-white-main font-kanit">สถานะ : ยังไม่ใช้สิทธิ</div>
              <div class="mt-3">
                <button class="btn btn-primary font-kanit text-white">
                  QR CODE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="campaign-list-wrapper py-5">
        <h3 class="mt-0 mb-4 tx-32 pl-3 font-kanit">NFT ที่ล็อกอยู่ของคุณ</h3>
        <div class="row items">
          <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
            <div class="card p-0 w-100">
              <div class="image-over">
                <a>
                  <img class="card-img-top" src="assets/img/campaign/yes-img.png" alt="yes-campaign">
                </a>
                <div class="nft-lock-wrapper">
                  <div class="nft-lock text-truncate btn btn-danger font-kanit"> LOCKED </div>
                </div>
              </div>
              <div class="card-caption p-4">
                <div class="card-body py-1 mb-2">
                  <a>
                    <h5 class="tx-24 mb-2 text-truncate font-kanit">Iphone YNFT</h5>
                  </a>
                  <div>
                    <hr class="my-3" />
                    <div class="">
                      <span class="text-white-main txt-3l-only font-weight-light tx-18 font-kanit">รางวัลที่ได้รับจาก NFT ชิ้นนี้ 1,234.56 <span><img src="assets/img/campaign/yes-token.png" class="mx-1" width="24" height="24" alt="yes token"> YES</span></span>
                    </div>
                    <div>
                      <small class="text-muted tx-14 font-kanit">
                        (12 YES ต่อวัน)
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-modal-menu></app-modal-menu>

</div>
