<div class="main">

  <app-header-section></app-header-section>

  <div>
    <div *ngIf="!isLoading" class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="gasha.bannerImage | renderImageUrl: 'banner'" [alt]="gasha.name">
        </div>
      </div>
    </div>
  </div>

  <section class="nft-gasha" *ngIf="gasha">
    <div *ngIf="isLoading" class="col-12 text-center py-5 my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div *ngIf="!gasha">
      <h2 class="text-center">Gasha not found</h2>
    </div>
    <div *ngIf="!isLoading && gasha" class="text-center">
      <h2 class="text-center">{{gasha.name}}</h2>
      <p class="text-center">{{gasha.description}}</p>

      <div class="btn border btn-stat cursor-default text-center mb-3">
        <div><small class="text-muted">You have</small></div>
        <h4 class="mt-2 mb-2 font-weight-bold">
          {{balance | number:"1.0-2" }}
        </h4>
        <div><small class="text-muted">{{gasha.paymentTokenName}}</small></div>
      </div>
      <div class="btn border btn-stat cursor-default text-center mb-3 ml-2">
        <div><small class="text-muted">Spin Fee</small></div>
        <h4 class="mt-2 mb-2 font-weight-bold">
          {{gasha.fee | number:"1.0-2" }}
        </h4>
        <div><small class="text-muted">KUB</small></div>
      </div>
      <div class="btn border btn-stat cursor-default text-center mb-3 ml-2">
        <div><small class="text-muted">Recycle Fee</small></div>
        <h4 class="mt-2 mb-2 font-weight-bold">
          {{gasha.redeemFee | number:"1.0-2" }}
        </h4>
        <div><small class="text-muted">KUB</small></div>
      </div>

      <div *ngIf="shouldApprove" class="text-center">
        <div *ngIf="isApproveLoading" class="col-12 text-center mt-3">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <button *ngIf="!isApproveLoading" class=" btn btn-primary mt-3" (click)="approve()">Approve</button>
      </div>
      <div *ngIf="!shouldApprove" class="text-center">
        <div class="holder" *ngIf="spinning">
          <!--<svg height="18px" class="arrow" version="1.1" viewbox="0 0 24 12" width="36px"
               xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
              <g transform="translate(-16.000000, -23.000000)">
                <g transform="translate(28.000000, 28.000000) rotate(-90.000000) translate(-28.000000, -28.000000)">
                  <polygon class="_MXu" points="32.6666667 16.3333333 21 28 32.6666667 39.6666667"></polygon>
                  <polygon points="56 0 56 56 0 56 0 0"></polygon>
                </g>
              </g>
            </g>
          </svg> -->
          <div class="wheel slow">
            <ul class="">
              <li *ngFor="let img of gasha.spinImages">
                <img [src]="img">
              </li>
            </ul>
            <ul class="">
              <li *ngFor="let img of gasha.spinImages">
                <img [src]="img">
              </li>
            </ul>
            <ul class="">
              <li *ngFor="let img of gasha.spinImages">
                <img [src]="img">
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center">
        <div *ngIf="!spinning && resultTokenId && resultMeta" class="btn border btn-stat cursor-default text-center mb-2 ml-3 mr-3">

          <h6>Congratulation, You have got</h6>

          <img [src]="resultMeta.image" class="resultNFT wiggle"/>


          <h5>{{resultMeta.name}}</h5>
          <p class="text-muted">{{resultMeta.description}}</p>
        </div>
        </div>
        <!--<div *ngIf="spinning" class="col-12 text-center py-5 my-5 mt-4 ">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <button *ngIf="!spinning" class=" mt-4 btn btn-primary pulse-button" (click)="spin()">Spin</button>

      </div>

    </div>
    <div class="owned-nft">
      <div *ngIf="redeemLoading" class="col-12 text-center py-5 my-5">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && !redeemLoading" class="container">
        <div class="row">
          <div class="col-12 mb-3">
            <hr/>
            <h5>In your wallet.</h5>
            <p class="text-muted">You can recycle to get back some of {{gasha.paymentTokenName}}. <span class="font-weight-bold text-danger">(Your NFT will be burnt)</span></p>
            <ng-container *ngIf="assetTokenIds.length === 0">
              <div class="text-center mt-5 mb-5">
                <h5 class="text-muted">No Reward NFTs yet</h5>
              </div>
            </ng-container>
          </div>

          <div class="col-md-3 mt-3 mb-3" *ngFor="let nft of assets">
            <div *ngIf="!nft.hidden" class="box-nft card card-no-hover-animate">
              <div class="image-over">
                <div class="image-over-wrapper">
                  <img class="card-img-top"
                       [src]="nft.image"
                       alt="">
                </div>
              </div>
              <div class="card-body">
                <h4>{{nft.name}}</h4>
                <p class="tx-12 mt-0">Token ID: {{nft.tokenId}}</p>
              </div>
              <div class="card-footer border-top mx-0 mb-0 mt-5 py-0">
                <button class="btn btn-xs button-outline-danger mt-2 text-white w-100"
                  (click)="redeem(nft)">
                  Recycle {{gasha.paymentTokenName}}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>



  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
