import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCappital'
})
export class ToCappitalPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(Array.isArray(value)){
      return value.map(it => ( it.charAt(0).toUpperCase() + it.slice(1) ) )
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

}
