import { CampaignModule } from './themes/campaign/campaign.module';
import { CampaignComponent } from './themes/campaign/campaign.component';
import { CraftingStationModule } from './themes/crafting-station/crafting-station.module';
import { CraftingStationsComponent } from './themes/crafting-stations/crafting-stations.component';
import { CraftingStationComponent } from './themes/crafting-station/crafting-station.component';
import { ProjectDetailComponent } from './themes/project-detail/project-detail.component';
import { ExploreProjectsComponent } from './themes/explore-projects/explore-projects.component';
import { NftOfferingsComponent } from './themes/nft-offerings/nft-offerings.component';
import { NftOfferingComponent } from './themes/nft-offering/nft-offering.component';
import { RedeemCampaignComponent } from './themes/redeem-campaign/redeem-campaign.component';
import { NftBatchSellComponent } from './themes/nft-batch-sell/nft-batch-sell.component';
import { ProListingComponent } from './themes/pro-listing/pro-listing.component';
import { ErrorPageNotAuthorizedComponent } from './themes/error-page-not-authorized/error-page-not-authorized.component';
import { ErrorPageComponent } from './themes/error-page/error-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ThemeOneComponent } from './themes/theme-one/theme-one.component';
import { ExploreOneComponent } from './themes/explore-one/explore-one.component';
import { ExploreTwoComponent } from './themes/explore-two/explore-two.component';
import { ExploreThreeComponent } from './themes/explore-three/explore-three.component';
import { ExploreFourComponent } from './themes/explore-four/explore-four.component';
import { LiveAuctionsComponent } from './themes/live-auctions/live-auctions.component';
import { ItemDetailsComponent } from './themes/item-details/item-details.component';
import { ActivityComponent } from './themes/activity/activity.component';
import { AuthorsComponent } from './themes/authors/authors.component';
import { AuthorComponent } from './themes/author/author.component';
import { WalletConnectComponent } from './themes/wallet-connect/wallet-connect.component';
import { CreateComponent } from './themes/create/create.component';
import { LoginComponent } from './themes/login/login.component';
import { SignupComponent } from './themes/signup/signup.component';
import { ContactComponent } from './themes/contact/contact.component';
import { BlogComponent } from './themes/blog/blog.component';
import { BlogSingleComponent } from './themes/blog-single/blog-single.component';
import { HelpCenterComponent } from './themes/help-center/help-center.component';

import { AuthGuard } from './auth/helpers/auth.guards';
import { CollectionComponent } from './themes/collection/collection.component';
import { CollectionCreateComponent } from './themes/collection-create/collection-create.component';
import { NftShowComponent } from './themes/nft-show/nft-show.component';
import { NftCreateComponent } from './themes/nft-create/nft-create.component';
import { CollectionsComponent } from './themes/collections/collections.component';
import { AccountComponent } from './themes/account/account.component';
import { NftSellComponent } from './themes/nft-sell/nft-sell.component';
import { IconsThemeComponent } from './themes/icons-theme/icons-theme.component';
import { SettingsComponent } from './themes/settings/settings.component';
import { NftsComponent } from './themes/nfts/nfts.component';
import {PolicyComponent} from "./themes/policy/policy.component";
import { ExploreCollectionsComponent } from './themes/explore-collections/explore-collections.component';
import {PocMiningComponent} from "./themes/poc-mining/poc-mining.component";
import {PocMiningPoolComponent} from "./themes/poc-mining-pool/poc-mining-pool.component";
import { RankingsComponent } from './themes/rankings/rankings.component';
import {MiningPoolComponent} from "./themes/mining-pool/mining-pool.component";
import {MiningPoolHealthComponent} from "./themes/mining-pool-health/mining-pool-health.component";
import { NftProfileComponent } from './themes/nft-profile/nft-profile.component';
import { OrderBookComponent } from './themes/order-book/order-book.component';
import { OrderListComponent } from './themes/order-list/order-list.component';
import { OrderTradeComponent } from './themes/order-trade/order-trade.component';
import {FaucetsComponent} from "./themes/faucets/faucets.component";
import { RedeemComponent } from './themes/redeem/redeem.component';
import {MiningRewardComponent} from "./themes/mining-reward/mining-reward.component";
import {NftRevealsComponent} from "./themes/nft-reveals/nft-reveals.component";
import {NftRevealComponent} from "./themes/nft-reveal/nft-reveal.component";
import {WhitelistMarketsComponent} from "./themes/whitelist-markets/whitelist-markets.component";
import {WhitelistMarketComponent} from "./themes/whitelist-market/whitelist-market.component";
import {WhitelistBatchSellComponent} from "./themes/whitelist-batch-sell/whitelist-batch-sell.component";
import {NftLaunchpadComponent} from "./themes/nft-launchpad/nft-launchpad.component";
import {NftLaunchpadBatchSellComponent} from "./themes/nft-launchpad-batch-sell/nft-launchpad-batch-sell.component";
import {GatheringComponent} from "./themes/playground/gathering/gathering.component";
import {NftGashaComponent} from "./themes/nft-gasha/nft-gasha.component";
import {NftGashasComponent} from "./themes/nft-gashas/nft-gashas.component";
import {NftGashaV2Component} from "./themes/nft-gasha-v2/nft-gasha-v2.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },

  {
    path: 'connect',
    component: WalletConnectComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/wallet-connect/wallet-connect.module').then(m => m.WalletConnectModule)
      },
      { path: '', redirectTo: 'connect', pathMatch: 'full' }
    ]
  },
  {
    path: 'home',
    component: ThemeOneComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/theme-one/theme-one.module').then(m => m.ThemeOneModule)
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ]
  },
  {
    path: 'faucets',
    component: FaucetsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/faucets/faucets.module').then(m => m.FaucetsModule)
      },
      {path: '', redirectTo: 'faucets', pathMatch: 'full'}
    ]
  },
  {
    path: 'activity',
    component: ActivityComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/activity/activity.module').then(m => m.ActivityModule)
      },
      { path: '', redirectTo: 'activity', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-one',
    component: ExploreOneComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-one/explore-one.module').then(m => m.ExploreOneModule)
      },
      { path: '', redirectTo: 'explore-one', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-two',
    component: ExploreTwoComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-two/explore-two.module').then(m => m.ExploreTwoModule)
      },
      { path: '', redirectTo: 'explore-two', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-three',
    component: ExploreThreeComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-three/explore-three.module').then(m => m.ExploreThreeModule)
      },
      { path: '', redirectTo: 'explore-three', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-four',
    component: ExploreFourComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-four/explore-four.module').then(m => m.ExploreFourModule)
      },
      { path: '', redirectTo: 'explore-four', pathMatch: 'full' }
    ]
  },
  {
    path: 'auctions',
    component: LiveAuctionsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/live-auctions/live-auctions.module').then(m => m.LiveAuctionsModule)
      },
      { path: '', redirectTo: 'auctions', pathMatch: 'full' }
    ]
  },
  {
    path: 'item-details',
    component: ItemDetailsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/item-details/item-details.module').then(m => m.ItemDetailsModule)
      },
      { path: '', redirectTo: 'item-details', pathMatch: 'full' }
    ]
  },
  {
    path: 'authors',
    component: AuthorsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/authors/authors.module').then(m => m.AuthorsModule)
      },
      { path: '', redirectTo: 'authors', pathMatch: 'full' }
    ]
  },
  {
    path: 'author',
    component: AuthorComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/author/author.module').then(m => m.AuthorModule)
      },
      { path: '', redirectTo: 'author', pathMatch: 'full' }
    ]
  },
  {
    path: 'wallet-connect',
    component: WalletConnectComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/wallet-connect/wallet-connect.module').then(m => m.WalletConnectModule)
      },
      { path: '', redirectTo: 'wallet-connect', pathMatch: 'full' }
    ]
  },
  {
    path: 'icons-theme',
    component: IconsThemeComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/icons-theme/icons-theme.module').then(m => m.IconsThemeModule)
      },
      { path: '', redirectTo: 'icons-theme', pathMatch: 'full' }
    ]
  },
  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/create/create.module').then(m => m.CreateModule)
      },
      { path: '', redirectTo: 'create', pathMatch: 'full' }
    ]
  },
  {
    path: 'collection', //TODO : CLOSE MOCK PAGE
    component: CollectionComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collection/collection.module').then(m => m.CollectionModule)
      },
      { path: '', redirectTo: 'collection', pathMatch: 'full' }
    ]
  },
  {
    path: 'collection/create',
    component: CollectionCreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collection-create/collection-create.module').then(m => m.CollectionCreateModule)
      },
      { path: '', redirectTo: 'collection/create', pathMatch: 'full' }
    ]
  },
  {
    path: 'collection/:slugCollection/edit',
    component: CollectionCreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collection-create/collection-create.module').then(m => m.CollectionCreateModule)
      },
      { path: '', redirectTo: 'collection/edit', pathMatch: 'full' }
    ]
  },
  {
    path: 'collection/:slugCollection',
    component: CollectionComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collection/collection.module').then(m => m.CollectionModule)
      },
      { path: '', redirectTo: 'collection', pathMatch: 'full' }
    ]
  },
  {
    path: 'collection/:slugCollection/edit',
    component: CollectionCreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collection-create/collection-create.module').then(m => m.CollectionCreateModule)
      },
      { path: '', redirectTo: 'collection', pathMatch: 'full' }
    ]
  },
  {
    path: 'collections',
    component: CollectionsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/collections/collections.module').then(m => m.CollectionsModule)
      },
      { path: '', redirectTo: 'collections', pathMatch: 'full' }
    ]
  },
  {
    path: 'rankings',
    component: RankingsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/rankings/rankings.module').then(m => m.RankingsModule)
      },
      { path: '', redirectTo: 'rankings', pathMatch: 'full' }
    ]
  },
  {
    path: 'order-book',
    component: OrderBookComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/order-book/order-book.module').then(m => m.OrderBookModule)
      },
      { path: '', redirectTo: 'order-book', pathMatch: 'full' }
    ]
  },
  {
    path: 'order-list',
    component: OrderListComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/order-list/order-list.module').then(m => m.OrderListModule)
      },
      { path: '', redirectTo: 'order-list', pathMatch: 'full' }
    ]
  },
  {
    path: 'order-trade/:chain/:orderbookId',
    component: OrderTradeComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/order-trade/order-trade.module').then(m => m.OrderTradeModule)
      },
      { path: '', redirectTo: 'order-trade', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-collections',
    component: ExploreCollectionsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-collections/explore-collections.module').then(m => m.ExploreCollectionsModule)
      },
      { path: '', redirectTo: 'explore-collections', pathMatch: 'full' }
    ]
  },
  {
    path: 'project-detail/:id',
    component: ProjectDetailComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/project-detail/project-detail.module').then(m => m.ProjectDetailModule)
      },
      { path: '', redirectTo: 'project-detail', pathMatch: 'full' }
    ]
  },
  {
    path: 'explore-projects',
    component: ExploreProjectsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/explore-projects/explore-projects.module').then(m => m.ExploreProjectsModule)
      },
      { path: '', redirectTo: 'explore-projects', pathMatch: 'full' }
    ]
  },
  {
    path: 'nfts',
    component: NftsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nfts/nfts.module').then(m => m.NftsModule)
      },
      { path: '', redirectTo: 'nfts', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft',
    component: NftShowComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-show/nft-show.module').then(m => m.NftShowModule)
      },
      { path: '', redirectTo: 'nft', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft-profile',
    component: NftProfileComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-profile/nft-profile.module').then(m => m.NftProfileModule)
      },
      { path: '', redirectTo: 'nft-profile', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/create',
    component: NftCreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-create/nft-create.module').then(m => m.NftCreateModule)
      },
      { path: '', redirectTo: 'nft/create', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/draft/:id',
    component: NftCreateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-create/nft-create.module').then(m => m.NftCreateModule)
      },
      { path: '', redirectTo: 'nft/create', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/campaign/:campaignId', // campaignId: get from scan QR code
    component: CampaignComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/campaign/campaign.module').then(m => m.CampaignModule)
      },
      { path: '', redirectTo: 'nft/campaign', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/launchpads', // nft/offerings
    component: NftOfferingsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-offerings/nft-offerings.module').then(m => m.NftOfferingsModule)
      },
      { path: '', redirectTo: 'nft/nft-offering', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/gashas', // nft/offerings
    component: NftGashasComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-gashas/nft-gashas.module').then(m => m.NftGashasModule)
      },
      {path: '', redirectTo: 'nft/gashas', pathMatch: 'full'}
    ]
  },
  {
    path: 'nft/gasha/:id', // nft/offerings
    component: NftGashaComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-gasha/nft-gasha.module').then(m => m.NftGashaModule)
      },
      {path: '', redirectTo: 'nft/gasha', pathMatch: 'full'}
    ]
  },
  {
    path: 'nft/gashav2/:id', // nft/offerings
    component: NftGashaV2Component,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-gasha-v2/nft-gasha-v2.module').then(m => m.NftGashaV2Module)
      },
      {path: '', redirectTo: 'nft/gashav2', pathMatch: 'full'}
    ]
  },
  {
    path: 'nft/launchpad/:slug', // 'nft/offering/:collectionSlug'
    component: NftLaunchpadComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-launchpad/nft-launchpad.module').then(m => m.NftLaunchpadModule)
      },
      { path: '', redirectTo: 'nft/launchpads', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/launchpad/v1/:collectionSlug', // 'nft/offering/:collectionSlug'
    component: NftOfferingComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-offering/nft-offering.module').then(m => m.NftOfferingModule)
      },
      { path: '', redirectTo: 'nft/nft-offering', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/reveal/:id',
    component: NftRevealComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-reveal/nft-reveal.module').then(m => m.NftRevealModule)
      },
      { path: '', redirectTo: 'nft/nft-reveal', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/reveals',
    component: NftRevealsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-reveals/nft-reveals.module').then(m => m.NftRevealsModule)
      },
      { path: '', redirectTo: 'nft/reveals', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/:nftAddress/:tokenId',
    component: NftShowComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-show/nft-show.module').then(m => m.NftShowModule)
      },
      { path: '', redirectTo: 'nft', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/batch-sell',
    component: NftBatchSellComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-batch-sell/nft-batch-sell.module').then(m => m.NftBatchSellModule)
      },
      { path: '', redirectTo: 'nft/sell', pathMatch: 'full' }
    ]
  },
  {
    path: 'nft/sell/:nftAddress/:tokenId',
    component: NftSellComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-sell/nft-sell.module').then(m => m.NftSellModule)
      },
      { path: '', redirectTo: 'nft/sell', pathMatch: 'full' }
    ]
  },
  {
    path: 'account',
    component: AccountComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/account/account.module').then(m => m.AccountModule)
      },
      { path: '', redirectTo: 'account', pathMatch: 'full' }
    ]
  },
  {
    path: 'account/:slugUser',
    component: AccountComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/account/account.module').then(m => m.AccountModule)
      },
      { path: '', redirectTo: 'account', pathMatch: 'full' }
    ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/settings/settings.module').then(m => m.SettingsModule)
      },
      { path: '', redirectTo: 'settings', pathMatch: 'full' }
    ]
  },
  {
    path: 'pro-listing',
    component: ProListingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/pro-listing/pro-listing.module').then(m => m.ProListingModule)
      },
      { path: '', redirectTo: 'pro-listing', pathMatch: 'full' }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/login/login.module').then(m => m.LoginModule)
      },
      { path: '', redirectTo: 'login', pathMatch: 'full' }
    ]
  },
  {
    path: 'signup',
    component: SignupComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/signup/signup.module').then(m => m.SignupModule)
      },
      { path: '', redirectTo: 'signup', pathMatch: 'full' }
    ]
  },
  {
    path: 'contact',
    component: ContactComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/contact/contract.module').then(m => m.ContactModule)
      },
      { path: '', redirectTo: 'contact', pathMatch: 'full' }
    ]
  },
  {
    path: 'blog',
    component: BlogComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/blog/blog.module').then(m => m.BlogModule)
      },
      { path: '', redirectTo: 'blog', pathMatch: 'full' }
    ]
  },
  {
    path: 'blog-single',
    component: BlogSingleComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/blog-single/blog-single.module').then(m => m.BlogSingleModule)
      },
      { path: '', redirectTo: 'blog-single', pathMatch: 'full' }
    ]
  },
  {
    path: 'help-center',
    component: HelpCenterComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/help-center/help-center.module').then(m => m.HelpCenterModule)
      },
      { path: '', redirectTo: 'help-center', pathMatch: 'full' }
    ]
  },
  {
    path: 'redeem',
    component: RedeemComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/redeem/redeem.module').then(m => m.RedeemModule)
      },
      { path: '', redirectTo: 'redeem', pathMatch: 'full' }
    ]
  },
  {
    path: 'redeem/campaign',
    component: RedeemCampaignComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/redeem-campaign/redeem-campaign.module').then(m => m.RedeemCampaignModule)
      },
      { path: '', redirectTo: 'redeem-campaign', pathMatch: 'full' }
    ]
  },
  {
    path: 'mining',
    component: PocMiningComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/poc-mining/poc-mining.module').then(m => m.PocMiningModule)
      },
      { path: '', redirectTo: 'poc-mining', pathMatch: 'full' }
    ]
  },
  {
    path: 'mining/health',
    component: MiningPoolHealthComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/mining-pool-health/mining-pool-health.module').then(m => m.MiningPoolHealthModule)
      },
      { path: '', redirectTo: 'mining', pathMatch: 'full' }
    ]
  },
  {
    path: 'mining/pool/:poolId',
    component: MiningPoolComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/mining-pool/mining-pool.module').then(m => m.MiningPoolModule)
      },
      { path: '', redirectTo: 'mining', pathMatch: 'full' }
    ]
  },
  {
    path: 'mining/reward/:poolId',
    component: MiningRewardComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/mining-reward/mining-reward.module').then(m => m.MiningRewardModule)
      },
      { path: '', redirectTo: 'mining', pathMatch: 'full' }
    ]
  },
  {
    path: 'poc-mining/pool/:poolId',
    component: PocMiningPoolComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/poc-mining-pool/poc-mining-pool.module').then(m => m.PocMiningPoolModule)
      },
      { path: '', redirectTo: 'poc-mining', pathMatch: 'full' }
    ]
  },
  {
    path: 'whitelist/markets',
    component: WhitelistMarketsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/whitelist-markets/whitelist-markets.module').then(m => m.WhitelistMarketsModule)
      },
      { path: '', redirectTo: 'whitelist/markets', pathMatch: 'full' }
    ]
  },
  {
    path: 'crafting-stations',
    component: CraftingStationsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/crafting-stations/crafting-stations.module').then(m => m.CraftingStationsModule)
      },
      { path: '', redirectTo: 'crafting-stations/', pathMatch: 'full' }
    ]
  },
  {
    path: 'crafting-station/:id',
    component: CraftingStationComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/crafting-station/crafting-station.module').then(m => m.CraftingStationModule)
      },
      { path: '', redirectTo: 'crafting-stations/', pathMatch: 'full' }
    ]
  },
  {
    path: 'whitelist/markets',
    component: WhitelistMarketsComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/whitelist-markets/whitelist-markets.module').then(m => m.WhitelistMarketsModule)
      },
      { path: '', redirectTo: 'whitelist/markets', pathMatch: 'full' }
    ]
  },
  {
    path: 'whitelist/market/:id',
    component: WhitelistMarketComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/whitelist-market/whitelist-market.module').then(m => m.WhitelistMarketModule)
      },
      { path: '', redirectTo: 'whitelist/markets', pathMatch: 'full' }
    ]
  },
  {
    path: 'whitelist/batch-sell/:id',
    component: WhitelistBatchSellComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/whitelist-batch-sell/whitelist-batch-sell.module').then(m => m.WhitelistBatchSellModule)
      },
      { path: '', redirectTo: 'whitelist/markets', pathMatch: 'full' }
    ]
  },
  {
    path: 'launchpad-admin/batch-sell/:slug',
    component: NftLaunchpadBatchSellComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/nft-launchpad-batch-sell/nft-launchpad-batch-sell.module').then(m => m.NftLaunchpadBatchSellModule)
      },
      { path: '', redirectTo: 'launchpad-admin/batch-sell', pathMatch: 'full' }
    ]
  },
  {
    path: 'playground',
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./themes/playground/playground.module').then(m => m.PlaygroundModule)
      },
    ]
  },
  {path: 'not-authorized', component: ErrorPageNotAuthorizedComponent},
  {path: 'error', component: ErrorPageComponent},
  {path: 'policy', component: PolicyComponent},
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
