import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-redeem-campaign',
  templateUrl: './redeem-campaign.component.html',
  styleUrls: ['./redeem-campaign.component.scss']
})
export class RedeemCampaignComponent implements OnInit {

  constructor(
    private modalService:NgbModal,
  ) { }

  ngOnInit() {
  }

  openQRCodeModal(modal:any){
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'modal-qr-code'
    });
  }

}
