<section class="popular-collections-area">
    <div class="container">
        <div class="row" *ngIf="isShow">
            <div class="col-12">
                <!-- Intro -->
                <div class="intro d-flex justify-content-between align-items-end m-0 flex-wrap">
                    <div class="intro-content">
                        <span>{{showedColorTitle}}</span>
                        <h3 class="d-flex mt-3 mb-0 align-items-center">
                          {{showedTitle}}

                          <div *ngIf="!isHideShowedPeriodFiltered" class="dropdown">
                            <button class="btn bg-transparent btn-outline-light dropdown-toggle shadow-none text-muted min-w-auto" type="button" id="dropdownDurationPopularCollection" data-toggle="dropdown" aria-expanded="false">
                              {{resolution.displayText}}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownDurationPopularCollection">
                              <a (click)="selectResolution('7d','7 day')" class="dropdown-item">7 day</a>
                              <a (click)="selectResolution('30d','30 day')" class="dropdown-item">30 day</a>
                            </div>
                          </div>

                        </h3>
                    </div>
                    <div  *ngIf="!isHideShowedExporeMore"class="intro-btn mb-3">
                      <a class="btn content-btn text-left" [routerLink]="['/explore-collections']" >Explore More</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div *ngIf="isLoading" class="col-12 pt-5 pb-5 text-center">
            <div  class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <p class="text-center text-muted">Fetching asset</p>
          </div>
        </div>
        <div class="row items">
            <div *ngFor="let _collection of topCollections" class="col-12 col-sm-6 col-lg-4 item d-flex align-items-stretch">
                <div class="card text-center p-0 w-100">
                    <div class="image-over">
                        <a (click)="openCollectionLink(_collection.collection.slug)" (click)="openCollectionLink(_collection.collection.slug)">
                            <img class="card-img-top" src="{{_collection.collection.featureImage | renderImageUrl : 'feature'}}" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" (click)="openCollectionLink(_collection.collection.slug)" >
                            <div class="active-nft-profile active-nft-profile-header aura-effect-header image-profile-preview img-nft-profile avatar-lg pad1px">
                                <img class="rounded-circle img-profile-collection-main" src="{{_collection.collection.logoImage | renderImageUrl : 'logo'}}" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4 px-4 pb-4 mb-2">
                            <a (click)="openCollectionLink(_collection.collection.slug)" >
                                <h5 class="d-flex align-items-center justify-content-center tx-16 mb-2">
                                  <span class="collection-name-card text-truncate tx-16">
                                    {{_collection.collection.name}}
                                  </span>
                                  <!-- <img [src]="getImageSupportChainByAbbr(_collection.collection.chain)"
                                    [title]="_collection.collection.chain"
                                    width="16" class="ml-1"> -->
                                    <i [ngClass]="_collection.collection.verification ? (_collection.collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                                  [title]="_collection.collection.verification ? (_collection.collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                                  class="ml-1"></i>
                                </h5>
                            </a>
                            <div class="text-truncate mb-2 tx-14">
                              By
                              <a [routerLink]="['/account/' + (_collection.collection.ownerUser ? _collection.collection.ownerUser.name : _collection.collection.ownerAddress)]">
                                {{(_collection.collection.ownerUser ? (_collection.collection.ownerUser.name| hideWalletAddress) : (_collection.collection.ownerAddress | hideWalletAddress))}}
                              </a>
                            </div>
                            <span class="card-description">{{_collection.collection.description}}</span>
                          <hr/>
                            <div class="row justify-content-center">
                              <div class="col-6 text-center px-0" *ngIf="_collection.floorUsd">
                                <p class="tx-15 mt-2">
                                  <span class="text-muted stat-header">Floor price</span>
                                  <br/>
                                  <span *ngIf="_collection.floorUsd" class="text-white-main">
                                    {{_collection.floorUsd | gameNumberFormat: 2 : false }} USD
                                  </span>
                                  <br/>
                                  <span *ngIf="_collection.floorThb" class="text-muted thb-value">(~ {{_collection.floorThb | gameNumberFormat: 2 : false }} THB)</span>
                                </p>
                              </div>
                              <div class="col-6 text-center px-0" *ngIf="_collection.volumnUsd">
                                <p class="tx-15 mt-2">
                                  <span class="text-muted stat-header">Volume traded</span>
                                  <br/>
                                  <span *ngIf="_collection.volumnUsd" class="text-white-main">
                                    {{_collection.volumnUsd | gameNumberFormat: 2 : false }} USD
                                  </span>
                                  <br/>
                                  <span *ngIf="_collection.volumnThb" class="text-muted thb-value">(~ {{_collection.volumnThb | gameNumberFormat: 2 : false }} THB)</span>
                                </p>
                              </div>
                            </div>

                          <div class="row" *ngIf="_collection.floorUsd == undefined && _collection.volumnUsd == undefined">
                            <div class="col-md-12 text-center">
                              <p class="tx-15 mt-4 text-muted mb-1"  >
                                <i>There are no statistics for this collection at this time</i>
                              </p>
                            </div>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center py-5 mt-2 mb-5" *ngIf="(!topCollections || !topCollections.length) && !isLoading">
              <div class="text-center mb-2">
                <i class="icon-drawer tx-40"></i>
              </div>
              -- No nft result --
            </div>
        </div>
        <div *ngIf="isLazyLoading">
          <div class="col-12 text-center py-5">
            <div  class="spinner-border text-primary" role="status">
              <span class="sr-only">lazy loading...</span>
            </div>
          </div>
        </div>
    </div>
</section>
