import { LazyLoaderService } from './../../services/lazy-loader.service';
import { FreeCityContractService } from './../../services/free-city-contract.service';
import { SingletonService } from './../../services/singleton.service';
import { MiningService } from './../../services/mining.service';
import { BackendService } from 'src/app/services/backend.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ethers, logger,utils } from "ethers";

import { Logger } from '../../services/logger.service';
const log = new Logger('OrderListComponent'); // log.debug

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  account:any

  orderList:any = []

  selectedSpendToken:any
  selectedReceiveToken:any

  selectedEventType:any = "created"
  selectedSortingDisplay:any = 'Created: Recently'
  selectedSortingParams:any = 'createdAt:desc'

  currentChain:any

  acceptedTokenAddresses:any = []
  serverTokensAddresses:any = []
  spendTokens:any = []
  customToken:any

  spendTokenSearchText:any = ''
  acceptedTokenSearchText:any = ''

  isOrderListLoading = true
  isCustomTokenLoading = false

  isOwnerFilter:boolean = false

  limit = 30
  page = 1
  totalResults = 0

  // == start lazy loader params ==
  isLazyLoading = false
  loadedAll = false
  scrollEvent
  scroll_position
  isScrollBottom = false
  // == end lazy loader params ==

  constructor(
    private modalService:NgbModal,
    private router: Router,
    private backendService: BackendService,
    private miningService: MiningService,
    private singletonService: SingletonService,
    private freeCityContractService: FreeCityContractService,
    private lazyLoaderService: LazyLoaderService,
  ) { }

  async ngOnInit() {
    this.account = await this.singletonService.getCurrentConnectedAccount()
    await this.handleScroll(); // enable lazyloader
    this.lazyLoaderService.lazyLoadSubject.subscribe(async ()=>{
      if(!this.loadedAll) await this.reloadData()
    })

    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("this.currentChain => %o",this.currentChain)

    var promise1 = new Promise(async (resolve, reject) => {
      this.acceptedTokenAddresses = await this.freeCityContractService.getP2PAcceptedTokenAddresses()
      log.debug("this.acceptedTokenAddresses => %o",this.acceptedTokenAddresses)
      await this.initAcceptedTokenList()
      await resolve(true)
    });

    var promise2 = new Promise(async (resolve, reject) => {
      this.serverTokensAddresses = await this.backendService.getOrderBookTokenAll(this.currentChain.networkAbbr)
      log.debug("this.serverTokensAddresses => %o",this.serverTokensAddresses)
      await this.initSpendTokenList()
      await resolve(true)
    });

    Promise.all([promise1, promise2]).then(async () => {
      // console.log("All data loaded...");
      await this.reloadData()
    }).catch((val) => {
      console.log(val);
    });

  }

  handleScroll(): void {
    window.onscroll = () => this.detectBottom();
  }

  async detectBottom(){
    // log.debug("-- detectBottom")
    let scroll_direction = await (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
    this.scroll_position = await (document.body.getBoundingClientRect()).top;
    if (scroll_direction == 'down'){ // downscroll
      // log.debug("zzzzzzzzzzzzzzzz detectBottom")
      let a = (window.innerHeight + window.scrollY)
      let b = document.body.offsetHeight - 5
      // log.debug("xxxxxxxxxxxxx a => %o",a)
      // log.debug("xxxxxxxxxxxxx b => %o",b)
      // log.debug("a >= b : %o", a >= b )
      if (a >= b) {
        this.isScrollBottom = true
        // log.debug("xxxxxxxxxxxxx detectBottom")

        // if(this.tab == 'activity'){ // check for multi-tab pages

            if(!this.isLazyLoading){ // check for lazy loader is working
              await this.lazyLoader()
            }

        // }else{
        //   log.debug("this tab isn't activity...")
        // }
      }
    }else{ // upscroll
      // donothing
    }
  }

  async lazyLoader(){
    this.isLazyLoading = await true
    await this.page++
    setTimeout(async () => {
      await this.reloadOrderList()
      this.isLazyLoading = await false
    }, 1000);
  }

  async reloadData(){
    this.isOrderListLoading = true
    await this.resetDatas()
    await this.reloadOrderList()
    this.isOrderListLoading = false
  }

  async initSpendTokenList(){
    this.spendTokens = [...this.spendTokens,...(await this.singletonService.getSpendTokensLocalStorage())]

    log.debug("initSpendTokenList => %o",this.serverTokensAddresses)
    this.spendTokens = await this.freeCityContractService.getTokenInfoAndBalance(
      this.serverTokensAddresses.map(it => it.address),
      this.account,
      true
    )
    log.debug("this.spendTokens => %o",this.spendTokens)
  }

  async initAcceptedTokenList(){
    this.acceptedTokenAddresses = await this.freeCityContractService.getTokenInfoAndBalance(
      this.acceptedTokenAddresses.map(it => it),
      this.account,
      true
    )
    log.debug("this.acceptedTokenAddresses => %o",this.acceptedTokenAddresses)
  }

  async reloadOrderList(){
    let result:any = await this.backendService.getOrderBookList(

      this.isOwnerFilter ? await this.singletonService.getCurrentConnectedAccount() : undefined, // ownerAddress
      this.selectedEventType != 'all' ? this.selectedEventType : undefined, // status

      // undefined, // ownerAddress
      // undefined, // status

      this.selectedSortingParams, // sortBy
      this.page, // page
      this.limit, // limit
      this.selectedSpendToken ? this.selectedSpendToken.address: undefined, // itemAddr
      this.selectedReceiveToken ? this.selectedReceiveToken.address: undefined, // acceptedTokenAddr
      this.currentChain ? this.currentChain.networkAbbr: undefined, // chain
    )
    this.totalResults = result.totalResults
    log.debug("result => %o",result)

    let _result:any = await Promise.all(result.results.map(async (token): Promise<any> => {

      let tokens:any = await this.freeCityContractService.getTokenInfoAndBalance(
        [token.acceptedTokenAddr,token.itemAddr],
        this.account,
        true
      )
      log.debug("tokens => %o",tokens)

      let spend = tokens[1]
      let receive = tokens[0]
      log.debug("receive => %o",receive)
      log.debug("spend => %o",spend)

      token.itemSymbol = spend.symbol
      token.itemAmountNumber = ethers.utils.formatUnits(token.itemAmount,spend.decimals)
      token.acceptedTokenSymbol = receive.symbol
      token.acceptedTokenAmountNumber = ethers.utils.formatUnits(token.acceptedTokenAmount,receive.decimals)
      return token
    }));

    this.orderList = [...this.orderList,..._result]
    if(_result.length < this.limit) this.loadedAll = true
    log.debug("this.orderList => %o",this.orderList)
  }

  resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.orderList = []
    this.totalResults = 0
  }

  openListTokenModal(modal) {
    this.modalService.open(modal, {
      centered: true,
      scrollable: true
    });
  }



  jumpToCreateOrder(){
    this.router.navigate(['order-book'])
  }

  jumpToFaucet(){
    // this.router.navigate(['faucets'])
    const url = this.router.serializeUrl(this.router.createUrlTree(['faucets'], { queryParams: { } }));
    window.open(url, '_blank');
  }

  jumpToOrderTrade(order){
    this.router.navigate(['/order-trade/'+order.chain+'/'+order.orderbookId])
  }

  async customImportToken(){
    this.isCustomTokenLoading = true
    log.debug("customImportToken spendTokenSearchText => %o",this.spendTokenSearchText)
    if(await ethers.utils.isAddress(this.spendTokenSearchText)){
      let tokenAddress = this.spendTokenSearchText
      log.debug("tokenAddress : %o",tokenAddress)

      let customToken = await this.freeCityContractService.getTokenInfoAndBalance(
        [tokenAddress],
        this.account,
        true
      )
      log.debug("customToken : %o",customToken)

      this.customToken = await customToken[0]
    }else{
      this.customToken = undefined
    }
    this.isCustomTokenLoading = false

  }

  async addSorting(sortingParams,displayText){ // trigger event function
    this.selectedSortingDisplay = displayText
    this.selectedSortingParams = sortingParams
    await this.reloadData()
  }

  async toggleChecked(){ // trigger event function
    this.isOwnerFilter = !this.isOwnerFilter
    await this.reloadData()
  }

  async selectEventType(eventType){ // trigger event function
    this.selectedEventType = eventType
    log.debug("this.selectedEventType => %o",this.selectedEventType)
    await this.reloadData()
  }

  async selectReceiveToken(token){ // trigger event function
    this.selectedReceiveToken = token
    await this.reloadData()
  }

  async selectSpendToken(token){ // trigger event function
    this.selectedSpendToken = token
    await this.reloadData()
  }

}
