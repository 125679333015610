import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLowerCase'
})
export class ToLowerCasePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(Array.isArray(value)){
      return value.map(it => it.toLowerCase())
    }
    return value.toLowerCase()
  }

}
