<div class="main">
  <app-header-section></app-header-section>

  <div >
    <div *ngIf="craftingStation && !isLoading" class="image-cover-wrapper position-relative">
      <div class="image-cover-inner">
        <div class="image-cover">
          <img [src]="craftingStation.bannerImage | renderImageUrl: 'banner'" [alt]="craftingStation.name">
        </div>
      </div>
    </div>
  </div>

  <section>

    <div *ngIf="!craftingStation">
      <h2 class="text-center">Crafting Station not found</h2>
    </div>
    <div *ngIf="!isLoading && craftingStation">
      <h2 class="text-center">{{craftingStation.name}}</h2>
      <p class="text-center">{{craftingStation.description}}</p>
      <div class="text-center" *ngIf="!isDataFetching">
        <p *ngIf="debug">
          <span class="d-block"><span class="text-white-main">Station Address :</span> <span class="text-muted ml-1">{{craftingStation.stationAddress}}</span></span>
          <span class="d-block"><span class="text-white-main">Whitelist Converter Address :</span> <span class="text-muted ml-1">{{whitelistConverterAddress}}</span></span>
        </p>

        <div class="btn-group  wrapper-info-content flex-wrap" role="group">
          <!-- <div class="btn border btn-stat cursor-default">
            <h4 class="mt-0 mb-1 font-weight-bold">
              {{forgeFee}} %
            </h4>
            <div><small class="text-muted">Forge Fee</small></div>
          </div> -->
          <div class="btn border btn-stat cursor-default">
            <h4 class="mt-0 mb-1 font-weight-bold">
              {{maxBooter}}
            </h4>
            <div><small class="text-muted">Max Booter</small></div>
          </div>
        </div>
        <!-- <br>
        <br>Forge Fee: {{forgeFee}} %
        <br>Max Booter Amount: {{maxBooter}}
        <br> -->

        <p *ngIf="debug">
          <span class="text-white-main">Whitelist Booster Addresses : {{boosterAddresses.length ? '' : '-'}}</span>
          <span *ngFor="let boosterAddress of boosterAddresses ; let i = index ">
            <span class="d-block text-muted">{{i+1}}. {{boosterAddress}}</span>
          </span>
        </p>
      </div>
    </div>
    <div *ngIf="isLoading || isDataFetching" class="col-12 text-center py-5 my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="container container-mobile" *ngIf="!isDataFetching">
      <div class="row mt-5 my-3">
        <div class="col-lg-6 mb-4 text-center">
          <div class="unstaked-converters-wrapper border border-radius-10px">
            <div class="col-md-12 mb-4 text-center">
              <h4 class="mb-0 mb-3 text-uppercase">
                In your wallet
              </h4>
              <p class="mt-0  mb-2 tx-12">(balance :  {{unstakedConverterNFTs ? unstakedConverterNFTs.length : ''}} converters)</p>
              <p class="mt-1">Please Approve your converter to refine station before staking</p>
            </div>
            <div class="converter-wrapper col-md-12 mb-4 text-center nft-craft-wrapper">

              <div *ngIf="!isConverterNFTsLoading  " class="row nft-items-wrapper items my-3 justify-content-start nft-craft-content">
                <div *ngFor="let unstakedConverterNFT of unstakedConverterNFTs" class="col-item-nft item mb-3">

                  <a class="box-nft card no-hover p-0" (click)="stakeConverter(unstakedConverterNFT)">
                    <div class="image-over">
                      <div class="image-over-wrapper">
                        <img *ngIf="unstakedConverterNFT.media === 'image'" class="card-img-top" [src]="unstakedConverterNFT.image | renderImageUrl : 'logo'" alt="">
                        <video *ngIf="unstakedConverterNFT.media === 'video'"
                               class="card-img-top video-player"
                               [poster]="unstakedConverterNFT.image | renderImageUrl : 'logo'"
                               [src]="unstakedConverterNFT.animation_url"
                               [muted]="'muted'"
                               loop autoplay>
                        </video>

                        <div  *ngIf="unstakedConverterNFT.media === 'video'" class="btn-nft-action-vdo">
                          <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                            <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                            <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="wrapper-btn-converter-card-action">
                      <button *ngIf="isApproveAllConverterNFTs"
                        (click)="stakeConverter(unstakedConverterNFT);$event.stopPropagation();"
                        [disabled]="unstakedConverterNFT.isStakeLoading"
                        class="btn btn-sm btn-primary text-white btn-converter-card-action">
                        Stake
                      </button>
                    </div>
                  </a>

                </div>


                <div class="col-12 text-center py-5" *ngIf="!unstakedConverterNFTs.length">
                  <div class="text-center mb-2">
                    <i class="icon-drawer tx-40"></i>
                  </div>
                  You don't have converter in wallet
                </div>
              </div>

              <div class="text-center   " *ngIf="!isConverterNFTsLoading && !isApproveAllConverterNFTs">
                <!-- isApproveAllConverterNFTs -->
                <button [disabled]="isApproveAllConverterNFTsLoading" class="btn btn-primary text-white  "
                        (click)="approveAllConverterNFTs()">
                  <span *ngIf="isApproveAllConverterNFTsLoading"
                        class="spinner-border spinner-border-sm mr-2 align-middle"></span>
                  Approve Converter
                </button>
              </div>

              <div *ngIf="isConverterNFTsLoading" class="col-12 text-center py-5 my-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4 text-center">
          <div class="staked-converters-wrapper border border-radius-10px">
            <div class="col-md-12 mb-4 text-center">
              <h4 class="mb-0 mb-3 text-uppercase">
                In station
              </h4>
              <p class="mt-0  mb-2 tx-12">(balance : {{stakedConverterNFTs ? stakedConverterNFTs.length : ''}} converters)</p>
              <p class="mt-1">Your staked converters will be shown here, click to select.</p>
            </div>
            <div class="converter-wrapper col-md-12 mb-4 text-center nft-craft-wrapper">


              <div *ngIf="!isConverterNFTsLoading && isApproveAllConverterNFTs" class="row nft-items-wrapper items my-3 justify-content-start nft-craft-content">
                <div *ngFor="let stakedConverterNFT of stakedConverterNFTs" class="col-item-nft item mb-3">

                  <a (click)="selectConverter(stakedConverterNFT)"
                  [ngClass]="selectedConverter && selectedConverter.tokenIdNumber == stakedConverterNFT.tokenIdNumber ? 'selected-box active-nft-profile active-nft-profile-header aura-effect-header image-profile-preview' : ''"
                    class="box-nft card no-hover p-0">
                    <div class="image-over">
                      <div class="image-over-wrapper">
                        <img *ngIf="stakedConverterNFT.media === 'image'" class="card-img-top" [src]="stakedConverterNFT.image | renderImageUrl : 'logo'" alt="">
                        <video *ngIf="stakedConverterNFT.media === 'video'"
                               class="card-img-top video-player"
                               [poster]="stakedConverterNFT.image | renderImageUrl : 'logo'"
                               [src]="stakedConverterNFT.animation_url"
                               [muted]="'muted'"
                               loop autoplay>
                        </video>

                        <div *ngIf="stakedConverterNFT.media === 'video'" class="btn-nft-action-vdo">
                          <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                            <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                            <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                          </button>
                        </div>
                      </div>
                      <div class="nft-tags-wrapper d-flex gap-1">
                        <div class="nft-tag text-truncate btn"
                          [class]="stakedConverterNFT.infos.state ? 'btn-warning': 'btn-info'">
                            {{stakedConverterNFT.infos.state | converterStateTypeToText}}
                        </div>
                        <div
                          *ngIf="stakedConverterNFT.canClaim"
                          class="nft-tag text-truncate btn"
                          [class]="stakedConverterNFT.canClaim ? 'btn-success': 'btn-warning'">
                            {{stakedConverterNFT.canClaim ? 'Ready to claim' : ''}}
                        </div>
                      </div>
                    </div>
                    <div class="wrapper-btn-converter-card-action unstake-btn">
                      <button
                        (click)="unstakeConverter(stakedConverterNFT);$event.stopPropagation();"
                        [disabled]="stakedConverterNFT.isStakeLoading"
                        class="btn btn-sm btn-danger text-white btn-converter-card-action p-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x text-white" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>
                          <span class="d-none d-lg-inline-block mr-1">Unstake</span>
                      </button>
                    </div>
                  </a>

                  <!-- <div>
                    <p>
                      State: {{stakedConverterNFT.infos.state | converterStateTypeToText}}<br>
                      canClaim: {{stakedConverterNFT.canClaim}}<br>
                    </p>

                    <button
                      (click)="selectConverter(stakedConverterNFT)"
                      [disabled]="stakedConverterNFT.isStakeLoading"
                      [ngClass]="selectedConverter && selectedConverter.tokenIdNumber == stakedConverterNFT.tokenIdNumber ? 'btn-success' : 'btn-primary'"
                      class="btn btn-sm text-white">

                      {{ selectedConverter && selectedConverter.tokenIdNumber == stakedConverterNFT.tokenIdNumber ? 'Selected' : 'Select' }}
                    </button>
                    <button
                      (click)="unstakeConverter(stakedConverterNFT)"
                      [disabled]="stakedConverterNFT.isStakeLoading"
                      class="btn btn-sm btn-danger text-white btn-converter-card-action">
                      Unstake
                    </button>
                  </div> -->

                </div>


                <div class="col-12 text-center py-5" *ngIf="!stakedConverterNFTs.length">
                  <div class="text-center mb-2">
                    <i class="icon-drawer tx-40"></i>
                  </div>
                  You have no staked converter in station
                </div>
              </div>

              <div *ngIf="isConverterNFTsLoading" class="col-12 text-center py-5 my-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-mobile converter-details-wrapper" *ngIf="!isDataFetching">
      <!-- <div class="row mt-5  my-3 border">
        <div class="col-md-12 mb-4 text-center">
          <span>Selected converter:</span>
          <select
            (change)="selectConverter($event.target.value)"
            class="text-center">
            <option>
              -- no selected converter ---
            </option>
            <option *ngFor="let converter of converters" [value]="converter.address">
              {{converter.address}}
            </option>
          </select>
        </div>
      </div> -->

      <div *ngIf="selectedConverter">
        <hr>
        <div class="row my-5 py-4 px-2 border border-radius-10px">

          <div class="col-lg-4">

            <div class="border border-radius-10px">

              <div class="box-nft card no-hover p-0">
                <div class="image-over border-radius-bl-br-0">
                  <div class="image-over-wrapper">
                    <img *ngIf="selectedConverter.media === 'image'" class="card-img-top" [src]="selectedConverter.image | renderImageUrl : 'logo'" alt="">
                    <video *ngIf="selectedConverter.media === 'video'"
                           class="card-img-top video-player"
                           [poster]="selectedConverter.image | renderImageUrl : 'logo'"
                           [src]="selectedConverter.animation_url"
                           [muted]="'muted'"
                           loop autoplay>
                    </video>

                    <div *ngIf="selectedConverter.media === 'video'" class="btn-nft-action-vdo">
                      <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                        <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                        <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="border-top mt-0 p-2">
                <p class="tx-14 text-white-main font-bold m-0">{{ selectedConverter.name }}</p>
                <small>
                  {{ selectedConverter.description }}
                </small>
              </div>

            </div>

            <div class="border border-radius-10px p-3 mt-4">

              <h5 class="mt-0 mb-3 tx-16">
                Equipped booster
                {{ selectedConverter.infos ? '('+selectedConverter.infos.boosterIds.length+'/'+maxBooter+')' : ''}}
              </h5>

              <div *ngIf="!selectedConverter.isStakedBoosterLoading && !isWithdrawBoosterLoading" class="row justify-content-center">
                <div *ngFor="let _index of [].constructor(maxBooter); let i = index "
                  class="col-4 px-0 border border-style-dashed">

                  <button *ngIf="!stakedBoosterNFTs || !stakedBoosterNFTs[i]"
                    data-toggle="modal"
                    data-target="#boosterModal"
                    (click)="refreshSelectedConverterAndBoosterNFTs(false,true)"
                    class="btn btn-select-equipped-booster">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-plus text-white-main " viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                  </button>

                  <div *ngIf="stakedBoosterNFTs && stakedBoosterNFTs[i]" (click)="withdrawBooster(stakedBoosterNFTs[i],i)" class="img-staked-booster-nft-wrapper cursor-pointer">
                    <img class="img-staked-booster-nft"
                    [src]="stakedBoosterNFTs[i].image | renderImageUrl : 'logo'" alt="">
                    <button (click)="withdrawBooster(stakedBoosterNFTs[i],i);$event.stopPropagation();"
                      class="btn btn-sm btn-danger text-white btn-remove-equip p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x text-white" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <!-- <span>withdraw #{{i+1}}</span> -->
                  </div>


                </div>
              </div>

              <div *ngIf="selectedConverter.isStakedBoosterLoading || isWithdrawBoosterLoading" class="col-12 text-center py-5 my-0">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Equipped boosters loading...</span>
                </div>
              </div>

            </div>

          </div>
          <div class="col-lg-8 author-area">
            <div class="wrapper-converter-details mb-4 text-left item-form p-0">
              <h4 class="mt-4 pt-lg-0 pt-2 mt-lg-1 mb-3 text-uppercase">Converter details</h4>
              <div *ngIf="!selectedConverter.isStakedBoosterLoading">

                <div class="d-flex align-items-center flex-wrap gap-2 wrapper-converter-detail mb-4">
                  <div class="border rounded">
                    Level : <br>
                    <strong class="text-white-main">{{ selectedConverter.infos ? (selectedConverter.infos.level | number:"1.0-0") : ''}}</strong>
                  </div>
                  <div class="border rounded">
                    EXP : <br>
                    <strong class="text-white-main">{{ selectedConverter.infos ? (selectedConverter.infos.exps | number:"1.0-0") : '' }}</strong>
                  </div>
                  <div class="border rounded">
                    Level Bonus : <br>
                    <strong class="text-white-main">{{selectedConverter.infos ? (selectedConverter.infos.levelReduce ? (selectedConverter.infos.levelReduce | number:"1.2-2"):'0') : '0'}}%</strong>
                    <small>Time reduction</small>
                  </div>
                  <div class="border rounded">
                    Booster Bonus : <br>
                    <strong class="text-white-main">{{selectedConverter.infos ? (selectedConverter.infos.totalReductTimePercent | number:"1.2-2") : ''}}%</strong>
                    <small>Time reduction</small>
                  </div>

                  <div class="border rounded">
                    Energy : <br>
                    <strong class="text-white-main">{{ selectedConverter.infos ? (selectedConverter.infos.energy | number:"1.2-2") : ''}}</strong>
                  </div>
                  <div class="border rounded">
                    Status : <br>
                    <strong [ngClass]="selectedConverter.infos ? (selectedConverter.infos.state ? 'text-warning' : 'text-info') : ''"> {{ selectedConverter.infos ? (selectedConverter.infos.state | converterStateTypeToText) : ''}} </strong>
                    <span *ngIf="selectedConverter.infos
                      && selectedConverter.infos.state == 1"
                      class="text-white d-block">
                      <app-countdown
                      [dDay]="selectedConverter.infos.endDateUnixTimeStamp"
                      [isShowFinishText]="true"
                      [finishTextDisplay]="'(Ready to claim)'"
                      [isRefreshPageWhenTimeUp]="true"
                    ></app-countdown>
                    </span>
                  </div>
                </div>

                <div class="border rounded mb-2 p-4">
                  Refill Energy

                  <div class="d-flex align-items-center flex-wrap gap-2 mt-2">
                    <!-- <div class="mr-3">
                      → Energy : <strong class="text-white-main">{{ selectedConverter.infos ? (selectedConverter.infos.energy | number:"1.2-2") : ''}}</strong>
                    </div> -->
                    <div *ngIf="!isApproveRefillToken">
                      <button [disabled]="isApproveRefillTokenLoading" class="btn btn-primary text-white d-flex align-items-center px-3 py-2 tx-14 rounded" (click)="approveRefillToken()">
                        <span *ngIf="isApproveRefillTokenLoading" class="spinner-border spinner-border-sm align-middle"></span>
                        <span class="text-white ml-2">Approve Refill Token</span>
                      </button>
                    </div>
                    <div *ngIf="isApproveRefillToken">
                      <div class="d-flex align-items-center flex-wrap gap-2">
                        <button [disabled]="isRefillEnergyLoading" class="btn btn-primary text-white d-flex align-items-center px-3 py-2 tx-14 rounded"
                        (click)="refillEnergy(1)">
                          <span *ngIf="isRefillEnergyLoading" class="spinner-border spinner-border-sm align-middle">
                            <span class="sr-only">Refill Energy Loading...</span>
                          </span>
                          <span class="text-white ml-2">Refill energy</span>
                        </button>

                        <div class="font-weight-medium text-white-main tx-14" *ngIf="currentChain && refillInfos && refillInfos.refillTokenInfos">
                          {{refillInfos.refillAmount | number:"1.0-2"}} Energy /
                          {{refillInfos.refillPrice | number:"1.0-2"}} {{refillInfos.refillTokenInfos.symbol | toUpperCase}}
                          <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{refillInfos.refillTokenInfos.address.toLowerCase()}}.png?raw=true"
                            onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="mr-2 rounded-circle align-top" alt="">
                        </div>
                        <div class="text-muted tx-12" *ngIf="currentChain && refillInfos && refillInfos.refillTokenInfos">
                          (balance : {{refillInfos.refillTokenInfos.balance | number:"1.0-2" }})
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-center flex-wrap justify-content-between" *ngIf="isApproveRefillToken">
                    <div class="form-group my-3 mx-0">
                      <div class="form-check form-check-inline">
                        <input name="custom-multiplier-checkbox"
                          (click)="toggleCustomRefillMultiplier()"
                          [checked]="isCustomRefillMultiplier"
                          class="form-check-input border" type="radio" id="inlineRadio">
                        <label class="form-check-label ml-1" for="inlineRadio">Custom Refill Multiplier</label>
                      </div>
                    </div>
                    <div *ngIf="isCustomRefillMultiplier && refillInfos && refillInfos.refillTokenInfos" class="position-relative mt-2 mb-3">
                      <select class="text-white-main pr-5"
                        (change)="selectRefillMultiplier($event.target.value)">
                        <option *ngFor="let _index of [].constructor(100); let i = index" [value]="i+1">
                          x{{i+1}}
                          (
                            {{(refillInfos.refillAmount * (i+1)) | number:"1.0-2"}} Energies /
                            {{(refillInfos.refillPrice * (i+1)) | number:"1.0-2"}} {{refillInfos.refillTokenInfos.symbol | toUpperCase}}
                          )
                        </option>
                      </select>
                      <div class="icon-arrow-down-select-wrapper">
                        <i class="icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- <div class="mb-3">→ LV : <strong class="text-white-main">1</strong> &nbsp;&nbsp; | &nbsp;&nbsp; EXP : <strong class="text-white-main">{{ selectedConverter.infos ? (selectedConverter.infos.exps | number:"1.0-0") : '' }}</strong></div> -->
                <!-- <div class="mb-3" [ngClass]="selectedConverter.infos ? (selectedConverter.infos.state ? 'text-warning' : 'text-info') : ''"> -->
                  <!-- <div class="d-flex align-items-center gap-2"> -->
                    <!-- → Status : {{ selectedConverter.infos ? (selectedConverter.infos.state | converterStateTypeToText) : ''}} -->

                    <!-- <span *ngIf="selectedConverter.infos
                      && selectedConverter.infos.state == 1"
                      class="text-white">
                      <app-countdown
                      [dDay]="selectedConverter.infos.endDateUnixTimeStamp"
                      [isShowFinishText]="true"
                      [finishTextDisplay]="'(Ready to claim)'"
                      [isRefreshPageWhenTimeUp]="true"
                    ></app-countdown>
                    </span> -->
                    <!-- <span *ngIf="selectedConverter.canClaim
                      && selectedConverter.infos
                      && selectedConverter.infos.state == 1"
                      class="text-white">
                      canClaim
                    </span> -->
                  <!-- </div> -->
                <!-- </div> -->

                <!--<div class="text-success mb-3">
                  → Booster Bonus :
                  <ul class="pl-5">
                    <li>
                      Total crafting time reduction: {{selectedConverter.infos ? (selectedConverter.infos.totalReductTimePercent | number:"1.2-2") : ''}}%
                    </li>
                  </ul>
                </div>

                <div class="text-success mb-3" *ngIf="selectedConverter.infos && selectedConverter.infos.levelReduce != undefined">
                  → Level Bonus :
                  <ul class="pl-5">
                    <li>
                      Total crafting time reduction: {{selectedConverter.infos ? (selectedConverter.infos.levelReduce | number:"1.2-2") : ''}}%
                    </li>
                  </ul>
                </div>
-->
              </div>
              <div *ngIf="selectedConverter.isStakedBoosterLoading" class="col-12 text-center py-5 my-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">infos loading...</span>
                </div>
              </div>

              <div *ngIf="selectedConverter && selectedConverter.isReadyToForge" class="row mb-3">
                <div class="col-md-12 px-0">
                  <hr>
                </div>
                <div class="col-md-12 mb-4 text-left px-0 d-flex flex-wrap justify-content-between align-items-center wrapper-select-recipe">
                  <label class="mb-2 mb-md-0 mr-3">Select Recipe :</label>
                  <!-- <select
                    [ngModel]='selectedReceipt ? selectedReceipt.id : -1'
                    (change)="selectReceipt($event.target.value)"
                    class="text-center">
                    <option [value]="-1">
                      -- select receipt here ---
                    </option>
                    <option *ngFor="let receipt of receipts" [value]="receipt.id">

                      <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{receipt.resultAddress.toLowerCase()}}.png?raw=true"
                        onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-2 rounded-circle" alt="">
                      {{receipt.resultAddress}} (id:{{receipt.id}})

                    </option>
                  </select> -->
                  <div class="dropdown">
                    <button  class="btn dropdown-toggle shadow-none w-100 dropdown-toggle-custom" type="button" data-toggle="dropdown" aria-expanded="false">
                      <span *ngIf="!selectedReceipt">
                        -- select recipe to craft ---
                      </span>
                      <span *ngIf="selectedReceipt">
                        <img
                          *ngIf="!selectedReceipt.isMatsLoading"
                          [src]="'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/'+ currentChain.networkAbbr + '/' + selectedReceipt.resultAddress.toLowerCase() + '.png?raw=true'"
                          onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" class="mr-2 rounded-circle" width="32" height="32">
                        <span  >
                          <!-- {{selectedReceipt.resultAddress | hideWalletAddress}} (id:{{selectedReceipt.id}})-->
                          {{selectedReceipt.name}}
                        </span>
                      </span>

                    </button>
                    <div class="dropdown-menu dropdown-menu-full">
                      <a *ngFor="let receipt of receipts" class="dropdown-item" (click)="selectReceipt(receipt.id)">
                        <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{receipt.resultAddress.toLowerCase()}}.png?raw=true"
                          onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" class="mr-2 rounded-circle" width="32" height="32">
                        <span  >
                          <!--{{receipt.resultAddress | hideWalletAddress}}  (id:{{receipt.id}}) -->

                          {{receipt.name}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="selectedReceipt" class="row my-3">
                <div class="col-md-12 border px-2 py-4 border-radius-10px">
                  <div class="row">
                    <div class="col-md-6 mb-4 text-left">
                      <div class="card no-hover">
                        <h5 class="tx-16 mb-3 mt-0">Required materials</h5>

                        <div *ngIf="!selectedReceipt.isMatsLoading">
                          <div *ngFor="let mat of selectedReceipt.materials">

                            <p class="mt-0 mb-2">
                              <img
                                src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{mat.tokenInfos.address.toLowerCase()}}.png?raw=true"
                                onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';"
                                width="44" class="mr-2 rounded-circle" alt="">
                              <!--{{ mat.tokenAddress | hideWalletAddress }}-->
                              <strong class="text-white-main" >{{mat.tokenInfos.symbol | uppercase}}</strong>
                              (x{{ mat.amount }})
                              <span  class="text-muted tx-12">
                                (balance: {{ mat.tokenInfos.balance | number:"1.2-2" }})
                              </span>
                              <!-- <span  [ngClass]="mat.isEnough ? 'text-success small' : 'text-danger small'">
                                (balance: {{ mat.tokenInfos.balance | number:"1.0-0" }})
                              </span> -->
                            </p>

                            <div class="mt-0 mb-3" class="small">

                              <span [ngClass]="mat.isApproved ? 'text-success' : 'text-danger' " *ngIf="debug">
                                [isApproved: {{ mat.isApproved}}]
                              </span>
                            </div>

                          </div>
                          <p *ngIf="selectedReceipt.materials && selectedReceipt.materials.length == 0" class="py-4 m-0">
                            -- no required mats --
                          </p>

                        </div>


                        <div *ngIf="selectedReceipt.isMatsLoading" class="col-12 text-center py-5">
                          <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Required Mats loading...</span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-6 mb-4 text-left">
                      <div class="border border-radius-10px mb-3 p-3">
                        <h5 class="text-muted mt-0 mb-0 tx-16">Block to wait</h5>
                        <p class="mt-2 text-white-main tx-24 text-right">

                          {{selectedReceipt.blockToWait | number:"1.0-0"}} Blocks <br/>
                          <span *ngIf="selectedReceipt && currentChain && !selectedReceipt.isMatsLoading" class="text-muted tx-16">
                            (~<app-block-to-datetime
                            [blockToWait]="selectedReceipt.blockToWait"
                            [blockTimeSecond]="currentChain.blockTimeSecond"
                            [isLoading]="selectedReceipt.isMatsLoading"
                          ></app-block-to-datetime>)
                          </span>
                        </p>
                      </div>
                      <div class="border border-radius-10px mb-3 p-3">
                        <h5 class="text-muted mt-0 mb-0 tx-16">Energy consume</h5>
                        <p class="mt-2 text-white-main tx-24 text-right">
                          {{selectedReceipt.energyConsume}}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 mb-4 text-center">


                      <h4 class="mb-3">
                        Result items
                        <!-- <span *ngIf="selectedReceipt">
                          (Receipt id: {{selectedReceipt.id}})
                        </span> -->
                      </h4>

                      <div class="pt-3 border-radius-10px border border-style-dashed">
                        <p class="text-primary mb-3 py-3" *ngIf="selectedReceipt.resultType == 0">
                          <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedReceipt.resultAddress.toLowerCase()}}.png?raw=true"
                            onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="44" class="mr-2 rounded-circle" alt="">
                          <!-- {{ selectedReceipt.resultAddress | hideWalletAddress }} -->
                          <strong class="text-white-main">{{ selectedReceipt.resultTokenInfos ? (selectedReceipt.resultTokenInfos.symbol | uppercase) : ''}}</strong>
                          (x{{ selectedReceipt.amount }})
                        </p>
                        <div class="text-primary mb-3"  *ngIf="selectedReceipt.resultType == 1">
                          {{ selectedReceipt.mediaJson ? selectedReceipt.mediaJson.name : '' }}
                          <!--(x{{ selectedReceipt.amount }}) -->
                          <div>
                            {{ selectedReceipt.mediaJson ? selectedReceipt.mediaJson.description : '' }}
                          </div>
                        </div>
                        <div class="my-3">
                          <span class="badge tag border" [ngClass]="selectedReceipt.resultType == 0 ? 'badge-primary text-white': 'text-muted'">
                            Token
                          </span>
                          <span class="badge tag border" [ngClass]="selectedReceipt.resultType == 1 ? 'badge-primary text-white': 'text-muted'">
                            NFT
                          </span>
                        </div>

                        <div class="box-nft card no-hover p-0 wrapper-card-receipt-data" *ngIf="selectedReceipt.resultType == 1">
                          <div class="image-over border-radius-bl-br-0">
                            <div class="image-over-wrapper">
                              <img *ngIf="selectedReceipt.media === 'image'" class="card-img-top" [src]="selectedReceipt.mediaJson.image | renderImageUrl : 'logo'" alt="">
                              <video *ngIf="selectedReceipt.media === 'video'"
                                     class="card-img-top video-player"
                                     [poster]="selectedReceipt.mediaJson.image | renderImageUrl : 'logo'"
                                     [src]="selectedReceipt.mediaJson.animation_url"
                                     loop muted autoplay>
                              </video>

                              <div  *ngIf="selectedReceipt.media === 'video'" class="btn-nft-action-vdo">
                                <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                                  <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                                  <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                                </button>
                              </div>
                            </div>
                          </div>
                          <a class="p-2" target="_blank" [href]="selectedReceipt.tokenUri">
                            metadata
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div *ngIf="selectedReceipt && selectedConverter && !selectedReceipt.isMatsLoading" class="row my-3">
                <div *ngIf="selectedReceipt.isAllApproveRequiredMats" class="col-md-12 mb-4 text-center">

                  <button *ngIf="selectedConverter.isReadyToForge"
                  [disabled]="!selectedReceipt || !selectedConverter || isForgeLoading"
                  class="btn btn-primary text-white mt-3 btn-min-w-150px d-flex align-items-center justify-content-center" (click)="forge()">

                    <div *ngIf="isForgeLoading" class="spinner-border spinner-border-sm mr-2 text-white" role="status">
                      <span class="sr-only">Forge loading...</span>
                    </div>
                    Forge

                  </button>

                  <button *ngIf="selectedConverter.infos && selectedConverter.canClaim "
                  [disabled]="!selectedReceipt || !selectedConverter || isClaimLoading"
                  class="btn btn-primary text-white mt-3 btn-min-w-150px d-flex align-items-center justify-content-center" (click)="claim()">

                    <div *ngIf="isClaimLoading" class="spinner-border spinner-border-sm mr-2 text-white" role="status">
                      <span class="sr-only">Claim loading...</span>
                    </div>
                    Claim

                  </button>

                </div>

                <div *ngIf="!selectedReceipt.isAllApproveRequiredMats" class="col-md-12 mb-4 text-center">
                  <button [disabled]="selectedReceipt.isApproveLoading"
                  class="btn btn-primary text-white mt-3 d-flex align-items-center justify-content-center" (click)="multipleApproveRequiredMats()">

                    <div *ngIf="selectedReceipt.isApproveLoading" class="spinner-border spinner-border-sm mr-2 text-white" role="status">
                      <span class="sr-only">Required Mats Approve loading...</span>
                    </div>
                    Multiple approve required materials
                    {{ selectedReceipt.notApprovedRequiredMatsAmount ? '('+selectedReceipt.notApprovedRequiredMatsAmount+')' : ''}}
                  </button>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>



      <!-- <div *ngIf="selectedConverter && selectedConverter.isReadyToForge" class="row mt-5 my-3 border">
        <hr>
        <div class="col-md-12 mb-4 text-center">
          <span>Select Receipt:</span>
          <select
            [ngModel]='selectedReceipt ? selectedReceipt.id : -1'
            (change)="selectReceipt($event.target.value)"
            class="text-center">
            <option [value]="-1">
              -- select receipt here ---
            </option>
            <option *ngFor="let receipt of receipts" [value]="receipt.id">

              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{receipt.resultAddress.toLowerCase()}}.png?raw=true"
                onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-2 rounded-circle" alt="">
              {{receipt.resultAddress}} (id:{{receipt.id}})

            </option>
          </select>
        </div>
      </div>

      <div *ngIf="selectedReceipt" class="row mt-5 my-3 border">
        <div class="col-md-4 mb-4 text-center">
          <div class="pt-5 pb-5" style="border: dashed 1px;">

            <div *ngIf="!selectedReceipt.isMatsLoading">
              <p *ngFor="let mat of selectedReceipt.materials">
                <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{mat.tokenInfos.address.toLowerCase()}}.png?raw=true"
                onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-2 rounded-circle" alt="">
                {{ mat.tokenAddress | hideWalletAddress }}
                {{mat.tokenInfos.symbol | uppercase}}
                (x{{ mat.amount }})

                <br>

                <span [ngClass]="mat.isEnough ? 'text-success' : 'text-danger' ">
                  [balance: {{ mat.tokenInfos.balance | number:"1.0-0" }}]
                </span>
                <span [ngClass]="mat.isApproved ? 'text-success' : 'text-danger' ">
                  [isApproved: {{ mat.isApproved}}]
                </span>
              </p>
              <p *ngIf="selectedReceipt.materials && selectedReceipt.materials.length == 0">
                -- no required mats --
              </p>

            </div>


            <div *ngIf="selectedReceipt.isMatsLoading" class="col-12 text-center py-5 my-5">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Required Mats loading...</span>
              </div>
            </div>

          </div>
          <h5>required materials</h5>
        </div>
        <div class="col-md-4 mb-4 text-center">
          <br>blockToWait
          <br>{{selectedReceipt.blockToWait}} blocks
          <hr>
          <br>>>>>>>>>>>>>>>>>>>>>>>>>
          <br>Energy consume
          <br>{{selectedReceipt.energyConsume}}
          <br>>>>>>>>>>>>>>>>>>>>>>>>>
        </div>
        <div class="col-md-4 mb-4 text-center">

          <span class="badge tag" [ngClass]="selectedReceipt.resultType == 0 ? 'badge-primary': ''">
            Token
          </span>
          <span class="badge tag" [ngClass]="selectedReceipt.resultType == 1 ? 'badge-primary': ''">
            NFT
          </span>

          <div class="pt-5 pb-5" style="border: dashed 1px;">
            <p class="text-primary" *ngIf="selectedReceipt.resultType == 0">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedReceipt.resultAddress.toLowerCase()}}.png?raw=true"
                onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="16" class="mr-2 rounded-circle" alt="">
              {{ selectedReceipt.resultAddress | hideWalletAddress }}
              {{ selectedReceipt.resultTokenInfos ? (selectedReceipt.resultTokenInfos.symbol | uppercase) : ''}}
              (x{{ selectedReceipt.amount }})
            </p>
            <p class="text-primary"  *ngIf="selectedReceipt.resultType == 1">
              {{ selectedReceipt.mediaJson ? selectedReceipt.mediaJson.name : '' }}
              (x {{ selectedReceipt.amount }})
              <br>
              <span>
                {{ selectedReceipt.mediaJson ? selectedReceipt.mediaJson.description : '' }}
              </span>
            </p>
            <div class="box-nft card no-hover" *ngIf="selectedReceipt.resultType == 1">
              <div class="image-over">
                <div class="image-over-wrapper">
                  <img *ngIf="selectedReceipt.media === 'image'" class="card-img-top" [src]="selectedReceipt.mediaJson.image | renderImageUrl : 'logo'" alt="">
                  <video *ngIf="selectedReceipt.media === 'video'"
                         class="card-img-top video-player"
                         [poster]="selectedReceipt.mediaJson.image | renderImageUrl : 'logo'"
                         [src]="selectedReceipt.mediaJson.animation_url"
                         loop muted autoplay>
                  </video>

                  <div  *ngIf="selectedReceipt.media === 'video'" class="btn-nft-action-vdo">
                    <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                      <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                      <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                    </button>
                  </div>
                </div>
              </div>
              <a target="_blank" [href]="selectedReceipt.tokenUri">
                metadata
              </a>
            </div>
          </div>
          <h5>Result items</h5>
        </div>
      </div>

      <div *ngIf="selectedReceipt && selectedConverter && !selectedReceipt.isMatsLoading" class="row mt-5 my-3">
        <hr>
        <div *ngIf="selectedReceipt.isAllApproveRequiredMats" class="col-md-12 mb-4 text-center">

          <button *ngIf="selectedConverter.isReadyToForge"
          [disabled]="!selectedReceipt || !selectedConverter || isForgeLoading"
          class="btn btn-primary text-white mt-4" (click)="forge()">

            <div *ngIf="isForgeLoading" class="spinner-border text-primary" role="status">
              <span class="sr-only">Forge loading...</span>
            </div>
            Forge

          </button>

          <button *ngIf="selectedConverter.infos && selectedConverter.canClaim "
          [disabled]="!selectedReceipt || !selectedConverter || isClaimLoading"
          class="btn btn-primary text-white mt-4" (click)="claim()">

            <div *ngIf="isClaimLoading" class="spinner-border text-primary" role="status">
              <span class="sr-only">Claim loading...</span>
            </div>
            Claim

          </button>

        </div>

        <div *ngIf="!selectedReceipt.isAllApproveRequiredMats" class="col-md-12 mb-4 text-center">
          <button [disabled]="selectedReceipt.isApproveLoading"
          class="btn btn-primary text-white mt-4" (click)="multipleApproveRequiredMats()">

            <div *ngIf="selectedReceipt.isApproveLoading" class="spinner-border text-primary" role="status">
              <span class="sr-only">Required Mats Approve loading...</span>
            </div>
            Multiple approve required mats
            {{ selectedReceipt.notApprovedRequiredMatsAmount ? '('+selectedReceipt.notApprovedRequiredMatsAmount+')' : ''}}
          </button>
        </div>

      </div> -->

    </div>

    <div class="container container-mobile">
      <hr>
      <div class="row my-3 px-4 border border-radius-10px">
        <div class="col-md-12 px-2 mb-4 text-center">
          <h4 class="tx-28 text-uppercase">User Logs</h4>
          <div *ngIf="!isUserLogsLoading" class="table-responsive">
            <table class="table table-borderless table-custom-wrapper">
              <thead>
                <th>#</th>

                <th>Start At Block</th>
                <th>End At Block</th>
                <th>Result Address</th>
                <!-- <th>resultType</th> -->
                <th>Amount</th>
                <th>Claimed</th>
              </thead>
              <tbody>
                <!-- <tr *ngFor="let userLog of userLogs | paginate: {
                    itemsPerPage: userLogsAmount,
                    currentPage: userLogsCurrentPage
                  };let i = index"> -->

                <tr *ngFor="let _userLogsIndex of [].constructor(userLogsLength) | paginate: {
                    itemsPerPage: userLogsAmount,
                    currentPage: userLogsCurrentPage
                  };let i = index">

                  <td>
                    <!-- {{i+1}} -->
                    {{ userLogsAmount * (userLogsCurrentPage - 1) + (i+1) }}
                  </td>
                  <td>{{userLogs[i].startAtBlock}}</td>
                  <td>{{userLogs[i].endAtBlock}}</td>
                  <td>
                    <!-- [{{userLogs[i].resultType | craftingResultTypeToText}}]
                    {{userLogs[i].resultAddress}} -->

                    <!-- here -->

                    <p class="text-primary" *ngIf="userLogs[i].resultType == 0">
                      <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{userLogs[i].resultAddress.toLowerCase()}}.png?raw=true"
                        onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="24" class="mr-2 rounded-circle" alt="">
                      {{ userLogs[i].resultAddress | hideWalletAddress }}
                      {{ userLogs[i].resultTokenInfos ? (userLogs[i].resultTokenInfos.symbol | uppercase) : ''}}
                    </p>
                    <p class="text-primary mb-3"  *ngIf="userLogs[i].resultType == 1">
                      {{ userLogs[i].mediaJson ? userLogs[i].mediaJson.name : '' }}
                      <br>
                      <span>
                        {{ userLogs[i].mediaJson ? userLogs[i].mediaJson.description : '' }}
                      </span>
                    </p>
                    <div class="box-nft card no-hover p-0" style="max-width: 200px;margin: auto;max-height: 240px;" *ngIf="userLogs[i].resultType == 1">
                      <div class="image-over border-radius-bl-br-0">
                        <div class="image-over-wrapper">
                          <img *ngIf="userLogs[i].media === 'image'" class="card-img-top" [src]="userLogs[i].mediaJson.image | renderImageUrl : 'logo'" alt="">
                          <video *ngIf="userLogs[i].media === 'video'"
                                 class="card-img-top video-player"
                                 [poster]="userLogs[i].mediaJson.image | renderImageUrl : 'logo'"
                                 [src]="userLogs[i].mediaJson.animation_url"
                                 loop muted autoplay>
                          </video>

                          <div  *ngIf="userLogs[i].media === 'video'" class="btn-nft-action-vdo">
                            <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                              <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? 'display: none;': 'margin-left: 1px;'">
                              <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" [style]="isSetDefaultVideoAutoPlay ? '': 'display: none;'" >
                            </button>
                          </div>
                        </div>
                      </div>
                      <a class="p-2" target="_blank" [href]="userLogs[i].tokenUri">
                        metadata
                      </a>
                    </div>

                  </td>
                  <!-- <td>{{userLogs[i].resultType | craftingResultTypeToText}}</td> -->
                  <td>{{userLogs[i].amount}}</td>
                  <td>{{userLogs[i].claimed}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <hr> -->
            <pagination-controls
              (pageChange)="userLogPageChanged($event)"
            ></pagination-controls>
          </div>


          <div *ngIf="isUserLogsLoading" class="col-12 text-center py-5 my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <div *ngIf="selectedConverter" class="modal" tabindex="-1" id="boosterModal">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom p-4">
          <h5 class="modal-title mt-0">
            Select available boosters
            {{ !selectedConverter.isUnstakedBoosterLoading && unstakedBoosterNFTs ? '('+unstakedBoosterNFTs.length + ')' : ''}}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-block px-4" style="min-height: 250px;">

          <div *ngIf="!selectedConverter.isUnstakedBoosterLoading">
            <div class="row nft-items-wrapper items items-equip justify-content-center">
              <div *ngFor="let unstakedBoosterNFT of unstakedBoosterNFTs"
                class="col-item-nft item mb-3">
                <a class="box-nft card no-hover p-0 border-radius-10px"
                (click)="selectBooster(unstakedBoosterNFT)"
                [ngClass]="selectedBooster == unstakedBoosterNFT ? 'selected-box active-nft-profile active-nft-profile-header aura-effect-header image-profile-preview' : '' ">

                  <div class="image-over border-radius-bl-br-0">
                    <div class="image-over-wrapper">
                      <img class="card-img-top" [src]="unstakedBoosterNFT.image | renderImageUrl : 'logo'" alt="">
                    </div>
                  </div>

                  <div class="card card-caption-modal no-hover m-0 border-radius-10px border-radius-tl-tr-0">
                    <p class="text-white-main font-bold m-0 tx-14 text-truncate mb-1">{{unstakedBoosterNFT.name}}</p>
                    <span class="d-block text-muted tx-13">Reduct Crafting Time: {{unstakedBoosterNFT.boosterReducePercent | number:"1.2-2"}}%</span>
                    <span class="d-block text-muted tx-13">NFT collection: {{unstakedBoosterNFT.boosterAddress | hideWalletAddress}}</span>
                  </div>

                </a>

              </div>
            </div>

            <!-- <div class="row">

            </div> -->


          </div>

          <div *ngIf="selectedConverter.isUnstakedBoosterLoading" class="col-12 text-center py-5 my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Available boosters loading...</span>
            </div>
          </div>

        </div>
        <div class="modal-footer" *ngIf="selectedBooster">
          <!-- <div class="border-top p-4">
            <hr> -->
            <div class="col-12 text-center">

              <div *ngIf="!selectedBooster.isApproved">
                <!-- isApproveAllBoosterNFTs -->
                <button [disabled]="isApproveAllBoosterNFTsLoading" class="btn btn-primary text-white"
                  (click)="approveAllBoosterNFTs()">
                  <span *ngIf="isApproveAllBoosterNFTsLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Approve Booster NFTs ({{selectedBooster.boosterAddress | hideWalletAddress}})
                </button>
              </div>
              <div *ngIf="selectedBooster.isApproved">
                <button
                [disabled]="!selectedBooster || selectedBooster.isStakeLoading"
                (click)="addBooster(selectedBooster)"
                class="btn btn-primary w-100 mt-3 mb-2 text-white max-w-300" type="button">
                Add booster
              </button>
              </div>

            </div>

          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>




