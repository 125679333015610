import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('GameNumberFormatPipe'); // log.debug

@Pipe({
  name: 'gameNumberFormat'
})
export class GameNumberFormatPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    // log.debug("-- GameNumberFormatPipe value => %o",value)
    let digits:any = args[0]
    let isZeroWhenUndefined:any = args[1]
    let suffix:any = args[2] ? args[2] : ''


    if(!digits) digits = 0
    if(isZeroWhenUndefined == undefined) isZeroWhenUndefined = true
    if(value == undefined) return isZeroWhenUndefined ? (0).toFixed(digits) : '---'

    if(Number(value) && value < 1) return value.toFixed(digits) + suffix
    else{
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return value >= item.value;
      });

      // log.debug("-- item => %o",item)

      return item ? this.getFormat(value,item,digits,rx) + suffix : (0).toFixed(digits) + suffix;
    }

  }

  getFormat(value,item,digits,rx){

    // log.debug("-- value => %o",value)
    // log.debug("-- item => %o",item)
    // log.debug("-- digits => %o",digits)
    // log.debug("-- rx => %o",rx)

    return (value / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
  }

}
