import { ToCappitalPipe } from './to-cappital.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTypeToText'
})
export class EventTypeToTextPipe implements PipeTransform {
  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}

  transform(value: any, ...args: any[]): unknown {
    let isText = args[0] // text or icon

    if(isText == undefined) isText = true

    // <i class="icon-basket-loaded mr-3 mb-3"></i>
    switch (value.toLowerCase()) {
      case 'created':
        return isText ? this.toCappitalPipe.transform('sale') : "icon-tag"; //  (created)
      case 'closed':
        return isText ? this.toCappitalPipe.transform('sold') : "icon-basket-loaded"; // (closed)
      case 'minted':
        return isText ? this.toCappitalPipe.transform(value) : "icon-graduation";
      case 'transfer':
        return isText ? this.toCappitalPipe.transform(value) : "icon-action-redo";
      case 'airdrop':
        return isText ? this.toCappitalPipe.transform(value) : "icon-present";
      case 'bid':
        return isText ? this.toCappitalPipe.transform(value) : "icon-energy";
      case 'offer':
        return isText ? this.toCappitalPipe.transform(value) : "icon-badge";
      case 'cancel':
        return isText ? this.toCappitalPipe.transform(value) : "icon-ban";
      default:
        return isText ?  this.toCappitalPipe.transform(value) : "icon-question";
    }
  }

}
