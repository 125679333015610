import { LazyLoaderService } from './../../services/lazy-loader.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { BackendService } from './../../services/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { collectionDetailsMockdata } from '../../mocks/collection-details';
import { collectionDetailsNftsMockdata } from '../../mocks/collection-details-nfts';
import {environment} from "../../../environments/environment";
import {CurrencyService} from "../../services/currency.service";

import * as _ from 'lodash';

import { Logger } from '../../services/logger.service';
const log = new Logger('CollectionComponent');

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit, OnDestroy {

  limit = 20
  loadedAll = false
  page = 1
  totalResults = 0

  isLoading = true
  id:any
  slugCollection:any

  isWatchListEnabled = environment.features.WATCH_LIST

  data:any = {
    collectionSummarizedDatas: {}
  }

  collectionNfts:any = []
  assets:any = []
  isFetchingAsset = false

  tab = 'items'

  defaultSelectedFilters = {
    eventTypesParams: ['created']
  }

  customFieldsBySlug:any

  constructor(
    private route: ActivatedRoute,
    private singletonService:SingletonService,
    private backendService:BackendService,
    private router: Router,
    private currencyService:CurrencyService,
    private selectedFiltersService:SelectedFiltersService,
    private lazyLoaderService:LazyLoaderService,
  ) {
    // init Selected Filters
    this.selectedFiltersService.initSelectedFilters()

    this.isLoading = true

    this.route.queryParams.subscribe(params => {
      if(params['tab']){
        this.tab = params['tab'];
      }
    });
    this.slugCollection = this.route.snapshot.paramMap.get('slugCollection');
    log.debug("this.slugCollection ",this.slugCollection)
  }

  ngOnDestroy(): void {
    document.body.classList.remove('static-header-mode')
  }

  async ngOnInit() {
    document.body.classList.add('static-header-mode')

    this.selectedFiltersService.selectedFiltersChangedSubject.subscribe((event)=>{
      this.reloadData()
    })

    this.lazyLoaderService.lazyLoadSubject.subscribe(async ()=>{
      if(!this.loadedAll) await this.fetchAsset()
    })


    await this.reloadData()
  }

  resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.assets = []
    this.totalResults = 0
  }

  async reloadData(){
    await this.resetDatas()
    this.backendService.getCollectionDetailsBySlug(this.slugCollection).then(async (res:any) => {
      log.debug("getCollectionDetailsBySlug res => ",res)
      if(res){
        // TODO: COMPLETE DIFFERENT FIELDS FROM API
        log.debug("result from collection detail ", res)
        this.data.collectionAddress = res.nftAddress
        this.data.collectionName = res.name
        this.data.collectionDescription = res.description
        this.data.collectionLogoImageUrl = res.logoImage
        this.data.collectionCoverImageUrl = res.bannerImage
        this.data.createdBy = res.ownerAddress
        this.data.ownerAddress = res.ownerAddress
        this.data.ownerUser = res.ownerUser
        this.data.blockchain = res.chain
        this.data.collectionLinks = res.links
        this.data.verification = res.verification

        this.data.slug = res.slug
        this.data.id = res.id

        this.data.collectionSummarizedDatas = res.statistic ? res.statistic : {}
        this.data.statistic = res.statistic
        this.data.floorPrice = undefined
        this.data.tradeVolumn = undefined


        // this.data.statistic.volumnThb = await this.currencyService.changeUsdToThb(this.data.statistic.volumnUsd ? this.data.statistic.volumnUsd : 0)
        // this.data.statistic.floorThb = await this.currencyService.changeUsdToThb(this.data.statistic.floorUsd ? this.data.statistic.floorUsd : 0)

        // log.debug("xxxxxxxx this.data.statistic => %o",this.data.statistic)

        if(this.data.statistic && this.data.statistic.floors){
          this.data.statistic.floors = await this.currencyService.addValuesToStatisticArray(this.data.statistic.floors)
          if(this.data.statistic.floors.length) this.data.floorPrice = this.data.statistic.floors.reduce((prev, curr) => (prev.usd < curr.usd ? prev : curr))
        }
        if(this.data.statistic && this.data.statistic.volumns){
          this.data.statistic.volumns = await this.currencyService.addValuesToStatisticArray(this.data.statistic.volumns)
          if(this.data.statistic.volumns.length){
            this.data.tradeVolumn = {
              usd:this.data.statistic.volumns.reduce(function (acc, obj) { return acc + obj.usd; }, 0),
              thb:this.data.statistic.volumns.reduce(function (acc, obj) { return acc + obj.thb; }, 0),
            }
          }
        }

        // log.debug("xxxxxxxx this.data => %o",this.data)
        // log.debug("this.singletonService.account => %o",this.singletonService.account)

        if(res.ownerAddress == this.singletonService.account){
          this.data.isOwner = true
        }else{
          this.data.isOwner = false
        }
        log.debug("this.data ",this.data)

        this.customFieldsBySlug = await this.singletonService.getCustomFieldsBySlug(res.slug)

      }else{
        this.router.navigate(['/error']);
        this.singletonService.fire("error","Collection slug not found!","slug: " + this.slugCollection)
      }
    }).catch((resError) => {
      this.singletonService.fire("error",resError.title,resError.error)
    }).finally(async ()=>{
      if(this.data.id){

        this.isFetchingAsset = await true
        await this.fetchAsset()
        this.isFetchingAsset = await false

      }
      this.isLoading = false
    })
  }

  async fetchAsset(){
    // this.isFetchingAsset = true
    // let result:any = (await this.backendService.getAssets(this.data.id))
    // this.assets = result.results
    // this.isFetchingAsset = false

    let eventTypesParams = this.selectedFiltersService.getEventTypesParams()
    let paymentTokenParams = this.selectedFiltersService.getPaymentTokenParams()
    let priceMinParams = this.selectedFiltersService.getPriceMinParams()
    let priceMaxParams = this.selectedFiltersService.getPriceMaxParams()
    let collectionsParams = this.selectedFiltersService.getCollectionsParams()
    let categoriesParams = this.selectedFiltersService.getCategoriesParams()
    let chainsParams = this.selectedFiltersService.getChainsParams()
    let sortParams = this.selectedFiltersService.getSortParams()

    let traitsParams = await this.selectedFiltersService.getAttrsParams()
    log.debug("traitsParams => %o",traitsParams)

    let result:any = await this.backendService.getAssetAdvanceSearch(
      paymentTokenParams,
      chainsParams,
      undefined, // ownerAddress
      priceMinParams,
      priceMaxParams,
      this.data.id, // collections // fixed collections
      eventTypesParams,
      categoriesParams,
      undefined, // creatorAddress
      undefined, // buyerAddress
      sortParams,
      this.limit,
      this.page,
      2,//version
      traitsParams, // traitsParams
      true,// minted
    )
    log.debug("getAssetAdvanceSearch result => %o",result)


    let _assets = await Promise.all(result.results.map(async (asset) => {
      if(asset.lastestOrder && asset.lastestOrder.acceptedTokenAddr){
        asset.lastestOrder.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(asset.lastestOrder.acceptedTokenAddr,asset.lastestOrder.chain)
        asset.lastestOrder.acceptedTokenSymbol = await asset.lastestOrder.acceptedTokenInfo.symbol
      }
      return asset
    }));


    this.assets = [...(this.assets ? this.assets : []),..._assets]
    if(_assets.length < this.limit) this.loadedAll = true
    this.totalResults = result.totalResults
    this.page++

    log.debug("this.assets => %o",this.assets)
  }

  activeTab(tab){
    this.router.navigate(['/collection/' + this.data.slug],{queryParams: { 'tab': tab }})
    this.tab = tab
    log.debug("[CollectionComponent] this.tab => %o",this.tab)
  }

  jumpToNFTDetails(activity){
    // log.debug("jumpToNFTDetails activity => %o",activity)
    this.router.navigate(['/nft/' + activity.asset.nftAddress + '/' + activity.asset.tokenId],{
      queryParams: {
        chain: activity.asset.chain
      }
    })
  }

}
