import { Router } from '@angular/router';
import { SingletonService } from 'src/app/services/singleton.service';
import { Component, OnInit } from '@angular/core';
import {MiningService} from "../../services/mining.service";

import { Logger } from '../../services/logger.service';
const log = new Logger('PocMiningComponent');

@Component({
  selector: 'app-poc-mining',
  templateUrl: './poc-mining.component.html',
  styleUrls: ['./poc-mining.component.scss']
})
export class PocMiningComponent implements OnInit {

  pools:any;

  soonPools = [
    /*{
      coverImage:'assets/img/pools/eclipse-quest.png',
      name:'Yes NFT iphone redemption',
      description:'Mining yes token, lock duration 365 days',
      link:'/mining/reward/0xcaFB9E6030567948e4462eC518A080F3BB80e62c',
      class:'reward-size-44px',
      rewards: [
        {
          image: 'assets/img/pools/currency-bag.png',
          symbol: 'Currency',
          // class: 'reward-height-40px'
        },
        {
          image: 'assets/img/pools/treasure-wooden.png',
          symbol: 'Treasure Wooden'
        },
        {
          image: 'assets/img/pools/treasure-silver.png',
          symbol: 'Treasure Silver'
        },
        {
          image: 'assets/img/pools/treasure-gold.png',
          symbol: 'Treasure Gold'
        },
      ]
    },*/
    /*{
      coverImage:'assets/img/pools/xrb-small.png',
      name:'XRB Galaxy Pool',
       description:'Mining phase#0, coming soon',
       class:'reward-size-44px',
       rewards: [
         {
           image: 'assets/img/pools/capsule.png',
           symbol: 'Capsule',
           // class: 'reward-height-40px'
         },
         {
           image: 'assets/img/pools/crystal.png',
           symbol: 'Crystal'
         },
         {
           image: 'assets/img/pools/normal.png',
           symbol: 'Normal'
         },
         {
           image: 'assets/img/pools/rare.png',
           symbol: 'Rare'
         },
         {
           image: 'assets/img/pools/super-rare.png',
           symbol: 'Super Rare'
         },
      ]
    }*/
    /*{
      coverImage:'assets/img/pools/digdragon.jpg',
      name:'Dig Dragon Pool',
      description:'Coming soon',
      //class:'reward-size-44px',
      rewards: [
           {
             image: 'assets/img/pools/btc.png',
             symbol: 'BTC'
           },
      ]

    },*/

  ]

  constructor(
    private miningService:MiningService,
    private singletonService:SingletonService,
    private router:Router,
  ) { }

  async ngOnInit(){
    this.pools = await this.miningService.getPools()
    this.pools = this.pools.reverse()
    log.debug("this.pools => %o",this.pools)
  }

  async setHashPower(){
    await this.miningService.setHashPower()
  }


}
