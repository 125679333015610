import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nft-gashas',
  templateUrl: './nft-gashas.component.html',
  styleUrls: ['./nft-gashas.component.scss']
})
export class NftGashasComponent implements OnInit {

  isLoading = true;
  gashas = [];

  constructor(
    private _backendService: BackendService,
    private router: Router,
  ) { }

  async ngOnInit(){
    this.gashas = await this._backendService.getGashas()
    this.isLoading = false;
  }

  async goToGasha(gasha){
    if(gasha.version && gasha.version === 2 ){
      await this.router.navigate(['nft/gashav2/' + gasha.id], {})
    }else{
      await this.router.navigate(['nft/gasha/' + gasha.id], {})
    }

  }

}
