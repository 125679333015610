
  <!-- Modal -->
  <ng-container >
    <div class="modal-header">
      <h5 class="modal-title text-danger">Caution</h5>
      <button type="button" class="close" (click)="dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="row">
        <div class="col-12 text-center">
          <img [src]="image" width="35%"/>
        </div>
        <div class="col-12 mt-5 text-center">
          <p>You are going to recycle NFT id {{tokenId}} for <strong>{{amount}} {{tokenName}}</strong>, recycle fee is {{gasha.redeemFee}} KUB. <br/>

            <span class="font-weight-bold text-danger">(Your NFT will be burnt)</span></p>

        </div>
      </div>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="dismiss()" rippleEffect>
        Cancel
      </button>
      <button type="button" class="btn btn-danger" (click)="accept()" rippleEffect>
        Recycle
      </button>

    </div>
  </ng-container>

