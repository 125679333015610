import { Component, OnInit, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnDestroy(): void {
    document.body.classList.remove('static-header-mode')
  }

  ngOnInit() {
    document.body.classList.add('static-header-mode')
  }

}
