<div class="main">
  <app-header-section></app-header-section>
  <div class="container" *ngIf="!isSupportChain">
    <section class="author-area " style="min-height: 70vh">
      <div class="text-center" style="margin-top: 8vh">
        <img src="assets/img/icons/sunglasses.png" width="128"/>
        <h5>Your wallet is connected to chain id that is not supported for this mining pool.</h5>
        <p>Please connect your wallet to chain id {{supportChainId}}</p>
        <button (click)="connectToSupportChain()" class="btn btn-primary text-white">
          Connect
        </button>
      </div>
    </section>
  </div>

  <div class="container" *ngIf="!poolFound">
    <section class="author-area " style="min-height: 70vh">
      <div class="text-center" style="margin-top: 8vh">
        <img src="assets/img/icons/sunglasses.png" width="128"/>
        <h5>Mining Pool Not Found</h5>
        <p>Your specific pool is not found or not active.</p>
        <button [routerLink]="['/mining']" class="btn btn-primary text-white">
          Go Back
        </button>
      </div>
    </section>
  </div>

  <div class="container" *ngIf="isSupportChain && poolFound">

    <section class="author-area" style="min-height: 70vh">
      <div *ngIf="isLoading" class="col-12 text-center ">
        <div  class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div  *ngIf="!isLoading">

        <div class="row">
          <div class="col-12">
            <h2>{{poolInfo.name}}</h2>
          </div>
          <div class="col-md-8">

            <p>
              Collection : {{poolInfo.collection}}<br/>
              Your Account : {{account}}<br/>
              Your Total Hash Power : {{userInfo.stakedHashPowerAmount}}<br/>
              Your Total NFT in Mining : {{userInfo.stakedTokenIds.length}}
            </p>
            <div *ngIf="profile">
              <h5>Profile Set Detail</h5>
              <p>
                NFT Contract : {{profile.nftAddress}}<br/>
                Token ID : {{profile.tokenId}}
              </p>
              <hr/>
            </div>
            <div class="row">
              <div class="col-md-6">
                <h5>Wallet Balance</h5>
                <p *ngFor="let balance of balances">
                  <img src="{{balance.reward.image}}" width="24px" /> {{balance.reward.name}} : {{balance.amount}}
                </p>
              </div>
              <div class="col-md-6">
                <h5>Pending Rewards</h5>
                <p *ngFor="let pending of pendings">
                  <img src="{{pending.reward.image}}" width="24px" /> {{pending.reward.name}} : {{pending.amount}}
                </p>
              </div>
            </div>

          </div>
          <div class="col-md-4">

            <a *ngIf="!isFaucetLoading" class="btn btn-warning w-100" (click)="faucet()">
              Tap faucet for NFT
            </a>
            <a *ngIf="!isWithdrawLoading" class="btn btn-primary text-white mt-3 w-100" (click)="withdraw()">
              Withdraw reward
            </a>
            <div *ngIf="isWithdrawLoading" class="col-12 text-center">
              <div  class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div class="row">
          <div class="col-12 mb-5">
            <h3>Your NFTs</h3>
            <p>Stake/Unstake will auto withdraw rewards to your wallet.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mt-3" *ngFor="let nft of nfts">
            <div class="box-nft card card-no-hover-animate">
              <div class="image-over">
                <div class="image-over-wrapper">
                  <img class="card-img-top" src="{{nft.image}}" alt="">
                </div>
              </div>
              <div class="card-body">
                <h4>{{poolInfo.collectionName}} #{{nft.tokenId}}</h4>
                Hash Power : {{nft.hashPower}}

                <div *ngIf="!nft.isApproved" class="mt-2 text-center">
                  <button class="btn btn-primary text-white w-100" (click)="approve()">Approve</button>
                </div>

                <div *ngIf="nft.isApproved && !nft.isStaked" class="mt-2 text-center">
                  <button class="btn btn-primary text-white w-100" [disabled]="nft.isStakeLoading" (click)="stake(nft.tokenId)">
                    <span *ngIf="nft.isStakeLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                    Go to mine
                  </button>
                </div>

                <div *ngIf="nft.isStaked" class="mt-2 text-center">
                  <button class="btn btn-warning text-white w-100" [disabled]="nft.isUnstakeLoading" (click)="unstake(nft.tokenId)">
                    <span *ngIf="nft.isUnstakeLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                    Stop mining
                  </button>
                </div>

                <div *ngIf="nft.profileExisted" class="mt-2 text-center">
                  <button class="btn btn-success text-white w-100" [disabled]="nft.isSetProfileLoading" (click)="setProfile(nft.tokenId)">
                    <span *ngIf="nft.isSetProfileLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                    Set as profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </section>

  </div>
  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
