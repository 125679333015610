<section class="wallet-connect-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Intro -->
                <div class="intro text-center">
                    <span>Wallet Connect</span>
                    <h3 class="mt-3 mb-0">Connect your Wallet</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center items">
            <div class="col-12 col-md-6 col-lg-4 item">
                <!-- Single Wallet -->
                <div class="card single-wallet">
                    <a class="d-block text-center" href="login">
                        <img class="avatar-lg" src="assets/img/metamask.png" alt="">
                        <h4 class="mb-0">MetaMask</h4>
                        <p>A browser extension with great flexibility. The web's most popular wallet</p>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 item">
                <!-- Single Wallet -->
                <div class="card single-wallet">
                    <a class="d-block text-center" href="login">
                        <img class="avatar-lg" src="assets/img/authereum.png" alt="">
                        <h4 class="mb-0">Authereum</h4>
                        <p>A user-friendly wallet that allows you to sign up with your phone number on any device</p>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 item">
                <!-- Single Wallet -->
                <div class="card single-wallet">
                    <a class="d-block text-center" href="login">
                        <img class="avatar-lg" src="assets/img/walletconnect.png" alt="">
                        <h4 class="mb-0">WalletConnect</h4>
                        <p>Pair with Trust, Argent, MetaMask &amp; more. Works from any browser, without an extension</p>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 item">
                <!-- Single Wallet -->
                <div class="card single-wallet">
                    <a class="d-block text-center" href="login">
                        <img class="avatar-lg" src="assets/img/dapper.png" alt="">
                        <h4 class="mb-0">Dapper</h4>
                        <p>A security-focused cloud wallet with pin codes and multi-factor authentication</p>
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 item">
                <!-- Single Wallet -->
                <div class="card single-wallet">
                    <a class="d-block text-center" href="login">
                        <img class="avatar-lg" src="assets/img/kaikas.png" alt="">
                        <h4 class="mb-0">Kaikas</h4>
                        <p>A simple-to-use wallet that works on both mobile and through a browser extension</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>