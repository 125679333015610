import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import jwt_decode from 'jwt-decode';

import { Logger } from '../../services/logger.service';
const log = new Logger('WalletConnectComponent');
@Component({
  selector: 'app-wallet-connect',
  templateUrl: './wallet-connect.component.html',
  styleUrls: ['./wallet-connect.component.scss']
})
export class WalletConnectComponent implements OnInit {

  account:any;
  text:any;

  constructor(
    private singletonService:SingletonService,
    private router:Router
  ) {
    this.text = 'Connect Wallet'
  }

  async ngOnInit() {
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account
      this.text='Wait a second, we are checking'
      let _isWhitelist = false // TODO: get from server

      const currentUser = await this.singletonService.getCurrentUserAndTokens();
      log.debug('currentUser %O', currentUser);
      let accessToken;
      if(currentUser && currentUser.tokens && currentUser.tokens.access && currentUser.tokens.access.token){
        accessToken = currentUser.tokens.access.token;
        const decoded:any = jwt_decode(accessToken);
        if(decoded && decoded.sub && decoded.sub.toLowerCase() === account.toLowerCase()){
          if(decoded.whitelist == true){
            _isWhitelist = true;
            log.debug(`you're in whitelist`);
          }
        }
      }

      if(_isWhitelist){
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 4000,
          title: 'Wallet connected and you are whitelisted ',
          text: 'Please wait, we will redirect you to free city.',
          icon: 'success',
          background: '#1F1F1F',
          iconColor: 'white',
          color:'white'
        })
        this.text='Redirecting'
        setTimeout(()=>{
          this.router.navigate(['/home'])
        },4000)
      }else{
        Swal.fire({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 12000,
          title: 'Wallet connected and you are not whitelisted ',
          text: 'Sorry but you are not whitelist please wait for public testing.',
          icon: 'error',
          background: '#1F1F1F',
          iconColor: 'red',
          color:'white'
        })
        this.text='Oh, you are not whitelist'
        // await this.singletonService.disconnectWallet();
        await localStorage.setItem("connectStatus",'0')
        await localStorage.removeItem("walletType")
        await localStorage.removeItem('currentUser');
      }

    });
  }

  async connectWallet(){
    await this.singletonService.connectWallet()
  }

}
