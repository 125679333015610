import { Component, OnInit } from '@angular/core';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from "@angular/router";
import { HideWalletAddressPipe } from 'src/app/pipes/hide-wallet-address.pipe';
import { BackendService } from 'src/app/services/backend.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Logger } from 'src/app/services/logger.service';
import { MiningService } from 'src/app/services/mining.service';
const log = new Logger('ModalMenuComponent');
@Component({
  selector: 'app-modal-menu',
  templateUrl: './modal-menu.component.html',
  styleUrls: ['./modal-menu.component.scss']
})
export class ModalMenuComponent implements OnInit {

  theme:any;

  // wallet params
  account:any
  isWrongNetwork:any
  currentChain:any
  nftProfile:any;

  // currency params
  public currencyConfig:any
  public fiatsList:any
  public factorCurrency:any

  // language params
  public languageOptions: any;
  public selectedLanguage: any;

  userProfileInfos
  supportChains = []

  hideSearchHeader: boolean = false

  constructor(
    public singletonService: SingletonService,
    public currencyService: CurrencyService,
    private modalService:NgbModal,
    public _translateService: TranslateService,
    public router: Router,
    public backendService:BackendService,
    public hideWalletAddressPipe:HideWalletAddressPipe,
    public miningService:MiningService
  ) { }

  async ngOnInit() {
    // log.debug("currentLang %o ",this._translateService.currentLang)
    this.supportChains = await this.singletonService.supportChains
    this.theme = await this.getTheme();
    await this.setTheme(this.theme)

    this.account = await this.singletonService.account
    this.userProfileInfos = await this.singletonService.currentUserInfosSubject.value
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      log.debug(" getAccountSubject this.account %o ",this.account)
      this.account = account

      let currentAccountElement:any = document.getElementById('current-account')
      if(currentAccountElement){
        currentAccountElement.innerHTML = this.hideWalletAddressPipe.transform(this.account);
      }
    });

    this.userProfileInfos = await this.singletonService.currentUserInfosSubject.value
    this.singletonService.currentUserInfosSubject.subscribe(async (currentUserInfos) => {
      // log.debug("currentUserInfos %o ",currentUserInfos)
      this.userProfileInfos = currentUserInfos
    });

    // wallet
    this.singletonService.currentChainSubject.subscribe((currentChain) => {
      this.currentChain = currentChain
      if(currentChain){
        let chainNameElement:any = document.getElementById('current-chain-name')
        if(chainNameElement){
          chainNameElement.innerHTML = currentChain.networkName;
        }
      }
    })
    this.singletonService.isWrongNetworkSubject.subscribe((isWrongNetwork) => {
      this.isWrongNetwork = isWrongNetwork
    })

    // currency
    this.fiatsList = this.currencyService.getFiatsList()
    this.currencyConfig = this.currencyService.getCurrencyConfig();
    this.currencyService.currentCurrencySubject.subscribe(async (currency)=>{
      this.currencyConfig = currency
      this.factorCurrency = await this.currencyService.getFactorCurrency()
      //  log.debug("this.factorCurrency %o",this.factorCurrency)
    })

    // language
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });

  }

  switchChain(chain:any){
    this.singletonService.switchChain(chain)
  }

  getImageSupportChainsByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }

  toggleTheme(_theme: any) {
    // log.debug(' _theme : ' + _theme);
    this.setTheme(_theme)
  }

  async setTheme(_theme:any = 'dark'){
    if(_theme == 'dark') {
      this.theme = 'dark';
      document.body.classList.remove("dark");
      document.body.classList.add("dark");
      await localStorage.setItem("theme",_theme)
    } else {
      this.theme = 'light';
      document.body.classList.remove("dark");
      await localStorage.setItem("theme",_theme)
    }
  }

  async getTheme(){
    let _theme = await localStorage.getItem("theme")
    if(!_theme){
        _theme = 'dark'
        await localStorage.setItem("theme",_theme)
    }
    return _theme
  }

}
