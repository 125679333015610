<div class="wrapper-search-filter-inner" [ngClass]="hideFilter?'':'search-filter-open'">
  <div class="search-filter-inner">
    <div class="search-filter-header cursor-pointer" (click)="toggleSearchFilter()">
      <button type="button" class="search-filter-header-button">
        <div class="search-filter-header-label">
          <!-- <i class="icon-list tx-20 line-h-24px"></i> -->
          <i class="fas fa-filter tx-18 line-h-24px align-bottom mr-2 h-auto"></i>
          Filter
        </div>
        <div class="search-filter-expand-icon">
          <svg *ngIf="!hideFilter" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
          </svg>
          <svg *ngIf="hideFilter" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>
        </div>
      </button>
    </div>
    <div *ngIf="enabledEventType" class="search-filter-row-wrapper search-filter-event">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleEventFilter()">
          <span>Event Type</span>
          <i *ngIf="hideEvent" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideEvent" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideEvent?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <button (click)="selectEventType('created')" [ngClass]="isSelectedEventType('created') ? 'active-event':''" type="button" class="search-filter-row-box-items border">
                {{'created' | eventTypeToText}}
              </button>
              <button (click)="selectEventType('closed')" [ngClass]="isSelectedEventType('closed') ? 'active-event':''" type="button" class="search-filter-row-box-items border">
                {{'closed' | eventTypeToText}}
              </button>
              <button (click)="selectEventType('cancel')" [ngClass]="isSelectedEventType('cancel') ? 'active-event':''" type="button" class="search-filter-row-box-items border">
                {{'cancel' | eventTypeToText}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="search-filter-row-wrapper search-filter-status">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleStatusFilter()">
          <span>Status</span>
          <i *ngIf="hideStatus" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideStatus" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideStatus?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <button type="button" class="search-filter-row-box-items border active-status">Buy Now</button>
              <button type="button" class="search-filter-row-box-items border">on Auction</button>
              <button type="button" class="search-filter-row-box-items border">New</button>
              <button type="button" class="search-filter-row-box-items border">Has Offers</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div *ngIf="enabledPrice" class="search-filter-row-wrapper search-filter-price">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="togglePriceFilter()">
          <span>Price</span>
          <i *ngIf="hidePrice" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hidePrice" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hidePrice?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="dropdown w-100 mb-3">
                <button *ngIf="selectedPaymentToken" type="button" data-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle border py-2 pl-3 pr-4 w-100 shadow-none">
                  <span class="img-price border rounded-circle">
                    <img [src]="selectedPaymentToken.assetImage" width="12">
                  </span>
                  {{selectedPaymentToken.name}} ({{selectedPaymentToken.symbol | toUpperCase}})
                </button>
                <div class="dropdown-menu dropdown-menu-full">
                  <a (click)="selectPaymentToken(paymentToken)" *ngFor="let paymentToken of paymentTokens" class="dropdown-item py-2 px-3">
                    <span class="img-price border rounded-circle">
                      <img [src]="paymentToken.assetImage" width="12">
                    </span>
                    {{paymentToken.name}} ({{paymentToken.symbol | toUpperCase}})
                  </a>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <input [(ngModel)]="minSelectedPaymentToken" type="number" class="form-control input-hide-scollbar" placeholder="Min">
                <span class="mx-2">to</span>
                <input [(ngModel)]="maxSelectedPaymentToken" type="number" class="form-control input-hide-scollbar" placeholder="Max">
              </div>
              <div>
                <button (click)="applyPrice()" type="button" class="btn btn-outline-light border bg-white">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="enabledCollections" class="search-filter-row-wrapper search-filter-collections">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleCollectionsFilter()">
          <span>Collections</span>
          <i *ngIf="hideCollections" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideCollections" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideCollections?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="input-group input-group-custom-wrapper mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0">
                    <i class="icon-magnifier"></i>
                  </span>
                </div>
                <input
                  [(ngModel)]="filterCollectionName" (ngModelChange)="delayFilterByName()"
                  type="text" class="form-control border-left-0 pl-0 bg-transparent"
                  name="search" autocomplete="off" placeholder="Filter">
              </div>
              <div class="wrapper-collections-box">
                <div *ngIf="isLoadingCollection">
                  <div class="col-12 text-center">
                    <div  class="spinner-border text-primary" role="status">
                      <span class="sr-only">loading...</span>
                    </div>
                  </div>
                </div>
                <div class="scroll-box">
                  <!-- selected elements -->
                  <button
                    *ngFor="let collection of selectedFiltersService.selectedFilters.collections"
                    (click)="selectionCollection(collection)"
                    [ngClass]="'active-selected'"
                    type="button" class="d-flex align-items-center bg-transparent border-0">

                    <div class="collections-image scroll-box-image">
                      <img [src]="collection.logoImage | renderImageUrl:'logo'">
                    </div>
                    <div class="collections-name scroll-box-name">
                      {{collection.name}}
                    </div>
                    <div class="collections-verify scroll-box-verify ml-1">
                      <i [ngClass]="collection.verification | verificationTypeToText : false"
                      [title]="collection.verification | verificationTypeToText"
                      class="ml-1"></i>
                    </div>
                  </button>
                  <!-- non-selected elements -->
                  <button
                    *ngFor="let collection of collections"
                    (click)="selectionCollection(collection)"
                    type="button" class="d-flex align-items-center bg-transparent border-0">

                    <div class="collections-image scroll-box-image">
                      <img  [src]="collection.logoImage | renderImageUrl:'logo'">
                    </div>
                    <div class="collections-name scroll-box-name">
                      {{collection.name}}
                    </div>
                    <div class="collections-verify scroll-box-verify ml-1">
                      <i [ngClass]="collection.verification | verificationTypeToText : false"
                      [title]="collection.verification | verificationTypeToText"
                      class="ml-1"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="enabledChains" class="search-filter-row-wrapper search-filter-chains">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleChainsFilter()">
          <span>Chains</span>
          <i *ngIf="hideChains" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideChains" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideChains?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="wrapper-chains-box">
                <div class="scroll-box">
                  <!-- selected elements -->
                  <button (click)="selectChain(supportChain)" [ngClass]="'active-selected'" *ngFor="let supportChain of selectedFiltersService.selectedFilters.chains" type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div [ngClass]="'active-selected'" class="chains-image scroll-box-image">
                      <img [src]="supportChain.imgUrl">
                    </div>
                    <div class="chains-name scroll-box-name">{{supportChain.networkName}}</div>
                  </button>

                  <!-- non-selected elements -->
                  <button (click)="selectChain(supportChain)" *ngFor="let supportChain of supportChains" type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div  class="chains-image scroll-box-image">
                      <img [src]="supportChain.imgUrl">
                    </div>
                    <div class="chains-name scroll-box-name">{{supportChain.networkName}}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="enabledCategories" class="search-filter-row-wrapper search-filter-categories">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleCategoriesFilter()">
          <span>Categories</span>
          <i *ngIf="hideCategories" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideCategories" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideCategories?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="wrapper-categories-box">
                <div class="scroll-box">
                  <button
                    (click)="selectCategory(category)"
                    [ngClass]="isSelectedCategory(category) ?'active-selected':''"
                    *ngFor="let category of categories"
                    type="button" class="d-flex align-items-center bg-transparent border-0">

                    <div class="categories-image scroll-box-image">
                      <img src="assets/img/categories/{{category|categoryTypeToText:false}}.svg" />
                    </div>
                    <div class="categories-name scroll-box-name">
                      {{category|categoryTypeToText}}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="search-filter-row-wrapper search-filter-on-sale-in">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleBackgroundFilter()">
          <span>
            <i class="icon-list tx-20 line-h-24px align-bottom mr-1"></i>
            Background
          </span>
          <i *ngIf="hideBackground" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideBackground" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideBackground?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="input-group input-group-custom-wrapper mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0">
                    <i class="icon-magnifier"></i>
                  </span>
                </div>
                <input type="text" class="form-control bg-transparent border-left-0 pl-0" autocomplete="off" name="search" placeholder="Filter">
              </div>
              <div class="wrapper-scroll-box">
                <div class="scroll-box">
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground" checked>
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Green</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Yellow</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Gray</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Purple</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Blue</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Pink</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Cosmic Purple</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Enlightened Purple</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Glitch Red</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" id="inlineCheckboxBackground">
                      <label class="form-check-label" for="inlineCheckboxBackground"></label>
                    </div>
                    <div class="background-name scroll-box-name">Jade Green</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="search-filter-row-wrapper search-filter-on-sale-in">
      <div class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleOnSaleFilter()">
          <span>OnSale</span>
          <i *ngIf="hideOnSale" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideOnSale" class="icon-arrow-up icon-arrow-search-filter"></i>
        </button>
        <div class="search-filter-row-box" [ngClass]="hideOnSale?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="input-group input-group-custom-wrapper mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0">
                    <i class="icon-magnifier"></i>
                  </span>
                </div>
                <input type="text" class="form-control bg-transparent border-left-0 pl-0" autocomplete="off" name="search" placeholder="Filter">
              </div>
              <div class="wrapper-collections-box">
                <div class="scroll-box">
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale" checked>
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">0xBTC</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">1337</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">1MT</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">2XDN</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">ABST</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">AC</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">FAZR</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">LADZ</div>
                  </button>
                  <button type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxOnSale" id="inlineCheckboxOnSale">
                      <label class="form-check-label" for="inlineCheckboxOnSale"></label>
                    </div>
                    <div class="collections-name scroll-box-name">NOIZ</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div *ngIf="enabledAttributes" class="search-filter-row-wrapper search-filter-collections">
      <div *ngFor="let attr of collectionsInfoTraits" class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleAttrsFilter(attr.type)">
          <span>{{attr.type}}</span>
          <i *ngIf="hideAttrs[attr.type]" class="icon-arrow-down icon-arrow-search-filter"></i>
          <i *ngIf="!hideAttrs[attr.type]" class="icon-arrow-up icon-arrow-search-filter"></i>
          {{attr.total}}
        </button>
        <div class="search-filter-row-box" [ngClass]="!hideAttrs[attr.type]?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="input-group input-group-custom-wrapper mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0">
                    <i class="icon-magnifier"></i>
                  </span>
                </div>
                <input
                  type="text" class="form-control border-left-0 pl-0 bg-transparent"
                  name="search" autocomplete="off" [(ngModel)]="searchTextAttr[attr.type]" placeholder="Filter">
              </div>
              <div class="wrapper-collections-box">
                <div *ngIf="isLoadingAttr">
                  <div class="col-12 text-center">
                    <div  class="spinner-border text-primary" role="status">
                      <span class="sr-only">loading...</span>
                    </div>
                  </div>
                </div>
                <div class="scroll-box">
                  <div *ngFor="let property of attr.properties | filter:searchTextAttr[attr.type]" class="attr-element">
                    <input type="checkbox" id="attr-checkbox-{{attr.type}}-{{property.value}}" (change)="selectAttr($event,attr.type,property.value)">
                    {{property.value}}
                    <span>
                      ============ ({{property.total}})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div *ngIf="enabledAttributes" class="search-filter-row-wrapper search-filter-option-collections">
      <div *ngFor="let attr of collectionsInfoTraits" class="search-filter-row">
        <button class="search-filter-row-header" (click)="toggleAttrsFilter(attr.type)">
          <span>
            <i class="icon-list tx-20 line-h-24px align-bottom mr-1"></i>
            {{attr.type}}
          </span>
          <div class="ml-auto">
            <span class="mr-2 text-muted font-weight-normal tx-13">
              {{attr.total}}
            </span>
            <i *ngIf="hideAttrs[attr.type]" class="icon-arrow-down icon-arrow-search-filter align-sub"></i>
            <i *ngIf="!hideAttrs[attr.type]" class="icon-arrow-up icon-arrow-search-filter align-sub"></i>
          </div>
        </button>
        <div class="search-filter-row-box" [ngClass]="!hideAttrs[attr.type]?'search-filter-row-box-closed':''">
          <div class="search-filter-row-box-wrapper">
            <div class="search-filter-row-box-inner">
              <div class="input-group input-group-custom-wrapper mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent border-0">
                    <i class="icon-magnifier"></i>
                  </span>
                </div>
                <input type="text" class="form-control bg-transparent border-left-0 pl-0" name="search" autocomplete="off" [(ngModel)]="searchTextAttr[attr.type]" placeholder="Filter">
              </div>
              <div class="wrapper-scroll-box">
                <div class="scroll-box">
                  <button *ngFor="let property of attr.properties | filter:searchTextAttr[attr.type]" type="button" class="d-flex align-items-center bg-transparent border-0">
                    <div class="form-check form-check-inline mr-0">
                      <input  class="form-check-input border mr-0" type="checkbox" name="inlineCheckboxBackground" (change)="selectAttr($event,attr.type,property.value)" id="attr-checkbox-{{attr.type}}-{{property.value}}">
                      <label class="form-check-label" for="attr-checkbox-{{attr.type}}-{{property.value}}"></label>
                    </div>
                    <div class="background-name scroll-box-name">{{property.value}}</div>
                    <div class="ml-auto">
                      {{property.total}}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<div class="btn-search-filter-nfts" (click)="toggleSearchFilterMobile()">
  <div class="btn btn-primary">Filter</div>
</div>
