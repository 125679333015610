import { Component, OnInit } from '@angular/core';
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {SingletonService} from "../../services/singleton.service";
import {Logger} from '../../services/logger.service';
import { environment } from 'src/environments/environment';

const log = new Logger('FaucetsComponent');
@Component({
  selector: 'app-faucets',
  templateUrl: './faucets.component.html',
  styleUrls: ['./faucets.component.scss']
})
export class FaucetsComponent implements OnInit {

  isLoading: boolean = false;
  tokens = [
    '0xd9487684F1E1ee9C3e557e18E310002dE784b770', //kusdt
  ]

  constructor(
    private freeCityContractService:FreeCityContractService,
    private singletonService:SingletonService
  ) { }

  async ngOnInit() {
    //let accpetedAddresses = await this.freeCityContractService.getP2PAcceptedTokenAddresses()
    //log.debug("accpetedAddresses ", accpetedAddresses)
    let info = await this.freeCityContractService.getTokenInfoAndBalance(this.tokens, await this.singletonService.getCurrentConnectedAccount())
    log.debug("info ", info)
  }

  async faucet(tokenAddr){
    log.debug("doing faucet")
    this.isLoading = true
    this.freeCityContractService.faucet(tokenAddr).then(async (transaction) => {
      let receipt = await transaction.wait()
      this.singletonService.fire('success', 'Faucet success', 'Faucet success please check your wallet balance')
    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'Faucet failed', 'Something went wrong please refresh and try again later.')
    }).finally(() => {
      this.isLoading = false
    })
  }

}
