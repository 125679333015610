<div class="main">
  <app-header-section></app-header-section>
  <div class="container">

    <section class="author-area">
      <!--<a class="btn btn-outline-light" style="padding-top: 40px" (click)="setHashPower()">Set hash</a> -->
      <div class="row">
        <div class="col-12">
          <h3>Mining Pools</h3>
        </div>
      </div>
      <div class="row items">

      </div>
    </section>
  </div>
  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>
</div>
