<div class="main">
  <app-header-section></app-header-section>
  <div *ngIf="isLoading" style="margin-top: 25vh;margin-bottom: 25vh" class="col-12 pt-5 pb-5 text-center">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-center text-muted">Fetching assets</p>
  </div>
  <div *ngIf="!isLoading">
  <div *ngIf="data ? data.isOwner : false" class="option-menu border">
    <div class="d-flex justify-content-end align-items-center">
      <!-- <div class="btn-group mr-3" role="group">
        <a class="btn btn-outline-light border">
          <i class="icon-pencil"></i>
        </a>
        <a class="btn btn-outline-light border">
          <i class="icon-list"></i>
        </a>
      </div> -->

      <a [routerLink]="['/collection/' + data.slug +'/edit']" [queryParams]="{id: data.id}" class="btn btn-xs btn-outline-light border mr-3" >Edit</a>
      <a [routerLink]="['/nft/create']" class="btn btn-primary text-white">Add item</a>
    </div>
  </div>

  <div class="image-cover-wrapper position-relative">
    <div class="image-cover-inner">
      <div class="image-cover">
        <img [src]="data.collectionCoverImageUrl | renderImageUrl: 'banner'">
      </div>
    </div>
  </div>
  <div class="information-collection">
    <div class="image-collection-profile" [ngClass]="data.isOwner ? '' : 'no-wrapper-option'">
      <div>
        <img [src]="data.collectionLogoImageUrl | renderImageUrl: 'logo'" alt="">
      </div>
    </div>
    <div *ngIf="isWatchListEnabled" class="add-to-watchlist">
      <a class="btn btn-outline-light border py-2">
        <span class="tx-28 mr-2 align-middle">+</span>
        Add to watchlist
      </a>
    </div>
    <div class="info-content text-center">
      <h2 class="tx-40 mt-0 mb-2">
        {{data.collectionName}}
        <i [ngClass]="data.verification ? (data.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
          [title]="data.verification ? (data.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
          class="ml-1"></i>
      </h2>
      <p>
        Created by
        <a [routerLink]="['/account/' + (data.ownerUser ? (data.ownerUser.length ?data.ownerUser[0].name: data.ownerAddress)  : undefined)]">
          {{ (data.ownerUser ? (data.ownerUser.length ?( data.ownerUser[0].name | hideWalletAddress ): (data.ownerAddress | hideWalletAddress))  : undefined) }}
        </a>
      </p>
      <div class="btn-group  wrapper-info-collection flex-wrap" role="group">
        <button type="button" class="btn btn-outline-light border btn-stat" >
          <h4 class="mt-0 mb-1 font-weight-bold">
            {{data.collectionSummarizedDatas.assets | gameNumberFormat: 0}}
          </h4>
          <div><small class="text-muted">items</small></div>
        </button>
        <button type="button" class="btn btn-outline-light border btn-stat" >
          <h4 class="mt-0 mb-1 font-weight-bold">
            {{data.collectionSummarizedDatas.owners | gameNumberFormat: 0}}
          </h4>
          <div><small class="text-muted">owners</small></div>
        </button>
        <button type="button" class="btn btn-outline-light border btn-stat">
          <div class="stat-unit " *ngIf="data.floorPrice">USD</div>
          <h4 class="mt-0 mb-2 font-weight-bold" *ngIf="data.floorPrice">
            {{data.floorPrice.usd | formatNumber: '2':'1.2-6' }}
          </h4>
          <h4 class="mt-0 mb-2 font-weight-bold" *ngIf="!data.floorPrice">
            -
          </h4>
          <div *ngIf="data.floorPrice && data.floorPrice.thb" class="text-muted thb-value thb">(~ {{data.floorPrice.thb | formatNumber: '0':'1.0-6' }} THB)</div>
          <div><small class="text-muted">floor price</small></div>
        </button>
        <button type="button" class="btn btn-outline-light border btn-stat">
          <div class="stat-unit " *ngIf="data.tradeVolumn">USD</div>
          <h4 class="mt-0 mb-2 font-weight-bold" *ngIf="data.tradeVolumn">
            {{data.tradeVolumn.usd | formatNumber: '2':'1.2-6' }}
          </h4>
          <h4 class="mt-0 mb-2 font-weight-bold" *ngIf="!data.tradeVolumn">
            -
          </h4>
          <div *ngIf="data.tradeVolumn && data.tradeVolumn.thb" class="text-muted thb-value thb">(~ {{data.tradeVolumn.thb | formatNumber: '0':'1.0-6' }} THB)</div>
          <div><small class="text-muted">volume traded</small></div>
        </button>
      </div>

      <div class="desc-content mt-3 mx-auto text-muted">
        <span *ngIf="!customFieldsBySlug">
          {{data.collectionDescription}}
        </span>

        <span *ngIf="customFieldsBySlug">
          {{customFieldsBySlug.description}}
        </span>
      </div>

    </div>

    <div class="content-wrapper-tab mt-3 mb-5">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
        <li class="nav-item" role="presentation">
          <a (click)="activeTab('items')"
            [ngClass]="tab == 'items' ?'active':''"
            class="nav-link"
            id="items-tab"
            data-toggle="tab"
            href="#items"
            role="tab"
            aria-controls="items"
            aria-selected="false">
            <i class="icon-list mr-2 tx-20 align-middle"></i> Items
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a (click)="activeTab('activity')"
            [ngClass]="tab == 'activity' ?'active':''"
            class="nav-link"
            id="activity-tab"
            data-toggle="tab"
            href="#activity"
            role="tab"
            aria-controls="activity"
            aria-selected="true">
            <i class="icon-graph mr-2 tx-20 align-middle"></i> Activity
          </a>
        </li>
      </ul>
      <!-- <div class="tab-content pt-4 px-4 pb-5 mb-5"> -->
      <div class="tab-content">

        <div class="nft-search-view">

            <div class="wrapper-search-main">
              <app-filter-search *ngIf="data && data.id"
              [enabledCollections]="false"
              [enabledCategories]="false"
              [enabledAttributes]="tab =='items'?true:false"
              [enabledPrice]="tab =='items'?true:false"
              [collectionId]="data.id"
              [defaultSelectedFilters]="defaultSelectedFilters"
              >
              </app-filter-search>
            </div>

            <div class="wrapper-search-result content-wrapper-tab pt-4 px-4 pb-5 mb-5">

              <div *ngIf="tab == 'items'" [ngClass]="tab == 'items' ?'show active':''" class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="items-tab">
                <app-nft-card-list *ngIf="data && assets" [assets]="assets" [collection]="data"  [isFetchingAsset]="isFetchingAsset" [totalResults]="totalResults"></app-nft-card-list>
              </div>

              <div *ngIf="tab == 'activity'"  [ngClass]="tab == 'activity' ?'show active':''" class="tab-pane fade pt-2 pb-5" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                <app-activity-table *ngIf="data && data.id" [collectionId]="data.id" [tab]="tab"></app-activity-table>
              </div>

            </div>

        </div>

      </div>
    </div>
  </div>
  </div>
  <app-modal-menu></app-modal-menu>
  <!-- <app-footer-section></app-footer-section> -->
</div>

