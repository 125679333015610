import { FreeCityContractService } from './../../services/free-city-contract.service';
import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {SingletonService} from "../../services/singleton.service";
import { TOKEN_TYPE } from "../../constant/valuables";
import { ethers, logger } from "ethers";
import {ActivatedRoute, Router} from "@angular/router";
import {mockdata} from "../../mocks/nft_details";

import { Logger } from '../../services/logger.service';
const log = new Logger('NftSellComponent');

@Component({
  selector: 'app-nft-sell',
  templateUrl: './nft-sell.component.html',
  styleUrls: ['./nft-sell.component.scss']
})
export class NftSellComponent implements OnInit {

  asset:any
  collection:any
  meta:any
  hideSchedule:boolean = false;
  payments:any = []
  supportChains:any = []
  chain:any;
  selectedPayment:any;
  price:any;
  loading = true
  isRevokeEnabled = true;
  isApproveLoading = false;
  isApproved = false
  isListingLoading = false;
  isCancelListingLoading = false;
  nftAddress
  tokenId

  constructor(
    public singletonService:SingletonService,
    public backendService:BackendService,
    private freeCityContractService:FreeCityContractService,
    public router:Router,
    private route: ActivatedRoute,
  ) {
    this.nftAddress = this.route.snapshot.paramMap.get('nftAddress');
    this.tokenId = this.route.snapshot.paramMap.get('tokenId');
    this.route.queryParams.subscribe(params => {
      this.chain = params['chain'];
      log.debug("this.chain => %o",this.chain)
    });
  }

  async ngOnInit() {
    let result = await this.backendService.getAssetInfo(this.nftAddress, this.tokenId, this.chain)
    log.debug("asset result ", result)

    this.asset = result.asset
    this.meta = result.meta
    this.collection = await this.backendService.getCollectionDetailsById(this.asset.collectionId)

    if(this.asset){
      this.asset.serviceFee = await this.freeCityContractService.getDevFeePercent()

      if(await this.freeCityContractService.isFreeCityNFTAddress(this.asset.nftAddress)){
        this.asset.loyaltyFee = this.collection.royaltyFee
      }else{
        this.asset.loyaltyFee = await this.freeCityContractService.getFeePercent(this.asset.nftAddress)
      }

    }

    log.debug("asset this.asset ", this.asset)
    log.debug("asset this.collection ", this.collection)


    // await this.freeCityContractService.checkFreeCityAddressed()
    this.supportChains = await this.singletonService.supportChains
    this.chain = await this.supportChains.find( it => it.networkAbbr == this.chain)
    //this.singletonService.getCurrentChain()
    this.payments = await this.backendService.getPaymentTokensByChainAbbr(this.chain.networkAbbr)
    this.selectedPayment = this.payments[0]
    await this.checkApproved()
    this.loading = false
  }

  async checkApproved(){
    this.isApproved = await this.freeCityContractService.checkApprove(this.nftAddress)
  }

  async approve(){
    this.isApproveLoading = true
    this.freeCityContractService.doApprove(this.nftAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve receipt ", receipt)
      this.singletonService.fire('success','Approved', 'Success approve contract to listing on market.')
      await this.checkApproved()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproveLoading = false
    })
  }

  async revoke(){
    this.isApproveLoading = true
    this.freeCityContractService.undoApprove(this.nftAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      this.singletonService.fire('success','Approved', 'Success approve contract to listing on market.')
      await this.checkApproved()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproveLoading = false
    })
  }

  async listing(){
    this.isListingLoading = true
    log.debug("selected payment ",this.selectedPayment)
    let itemId = this.tokenId
    let tokenType = TOKEN_TYPE.ERC721 // TODO : receive from server
    let itemAddr = this.nftAddress
    let itemAmount = 1
    let acceptedTokenId = 0
    let acceptedTokenType = this.selectedPayment.tokenType
    let acceptedTokenAddr = this.selectedPayment.address
    let acceptedTokenAmount = ethers.utils.parseEther(this.price)

    log.debug(" itemId ",itemId)
    log.debug(" tokenType ",tokenType)
    log.debug(" itemAddr ",itemAddr)
    log.debug(" itemAmount ",itemAmount)
    log.debug(" acceptedTokenId ",acceptedTokenId)
    log.debug(" acceptedTokenType ",acceptedTokenType)
    log.debug(" acceptedTokenAddr ",acceptedTokenAddr)
    log.debug(" acceptedTokenAmount ",acceptedTokenAmount.toString())

    this.freeCityContractService.listing(
      itemId,
      tokenType,
      itemAddr,
      itemAmount,
      acceptedTokenId,
      acceptedTokenType,
      acceptedTokenAddr,
      acceptedTokenAmount
    ).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("receipt.events ",receipt.events)
      let createdEvent = receipt.events.find((e)=> e.event === 'Created')
      log.debug("createdEvent ",createdEvent)
      log.debug("order id : ",createdEvent.args.id)
      log.debug("seller : ",createdEvent.args.seller)
      log.debug("meta : ",createdEvent.args.meta)
      log.debug("order: ",createdEvent.args.order)
      this.singletonService.fire('success','Listing', 'Success listing on market.')

      // redirect to nft view
      await this.jumpBack()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Listing failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isListingLoading = false
    })

  }

  async cancelListing(){
    this.isCancelListingLoading = true
    this.freeCityContractService.cancelListing(this.asset.lastestOrder.id).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("receipt.events ",receipt.events)
      let cancelEvent = receipt.events.find((e)=> e.event === 'Cancel')
      log.debug("cancelEvent ",cancelEvent)
      log.debug("order id : ",cancelEvent.args.id)
      log.debug("seller : ",cancelEvent.args.seller)
      log.debug("meta : ",cancelEvent.args.meta)
      log.debug("order: ",cancelEvent.args.order)
      this.singletonService.fire('success','Cancel Listing', 'Success cancel listing on market.')
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Cancel Listing failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isCancelListingLoading = false
    })
  }

  toggleScheduledSell() {
    this.hideSchedule =! this.hideSchedule;
  }

  selectPayment(payment){
    this.selectedPayment = payment
  }

  async jumpBack(){
    this.router.navigate(['/nft/'+this.nftAddress+'/'+this.tokenId],{ queryParams: { chain: this.chain.networkAbbr }})
  }
}
