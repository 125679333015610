import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-whitelist-markets',
  templateUrl: './whitelist-markets.component.html',
  styleUrls: ['./whitelist-markets.component.scss']
})
export class WhitelistMarketsComponent implements OnInit {

  isLoading = true;
  markets = [];

  constructor(private backendService:BackendService,
              private route: ActivatedRoute,
              private router:Router) { }

  async ngOnInit() {
    this.markets = await this.backendService.getMarkets()
    this.isLoading = false
  }

}
