import { SingletonService } from './../../services/singleton.service';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import Swal from 'sweetalert2';

import { Logger } from '../../services/logger.service';
const log = new Logger('AuthGuard'); // log.debug

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    // private _authenticationService: AuthenticationService,
    private singletonService: SingletonService,
  ) { }

  // canActivate
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this._authenticationService.currentUserValue;

    let account = await this.singletonService.account
    const currentUser = await this.singletonService.getCurrentUserAndTokens();
    log.debug(`currentUser `, currentUser);
    // TODO only on beta
    /* if (currentUser && currentUser.tokens && currentUser.tokens.access && currentUser.tokens.access.token) {
      const accessToken = currentUser.tokens.access.token;
      const decoded: any = jwt_decode(accessToken);
      if (account && decoded && decoded.sub && decoded.sub.toLowerCase() === account.toLowerCase()) {
        if (decoded.whitelist == true) {
          log.debug(`you're in whitelist`);

          await this.singletonService.checkCurrentChain()

        } else {
          this._router.navigate(['/connect']);
          return false;
        }
      }else{
        log.debug(`xxx you're in whitelist`);

      }
    } else {
      this._router.navigate(['/connect']);
      return false;
    }*/

    // TODO public test again
    if(!account || !currentUser){
      account = await this.singletonService.getAccountEtherjs('Call from AuthGuard')
      if(!account){
        log.debug("Account not found! try to get account: %o",account)
        this.singletonService.fire("warning","Please connect wallet.")
        this._router.navigate(['/not-authorized']);
      }else{
        // success to get account from provider
      }
    }else{
      // success to get account from fetch
    }

    return true;

  }
}
