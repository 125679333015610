<footer class="footer-area mt-5">
    <!-- Footer Top -->
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3 res-margin">
                    <!-- Footer Items -->
                    <div class="footer-items ">
                        <!-- Logo -->
                        <a class="navbar-brand ">
                            <img src="assets/img/logos/logo-white.png" width="15%" alt="">
                        </a>
                        <p class="text-left">Free City is a place for anyone to create, trade assets, and use your NFTs for mining.</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" target="_blank" href="https://www.facebook.com/freecity.nfts">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                          <a class="facebook">
                            <img src="assets/img/flags/flag-sg.png" height="32" width="32" style="margin: 6px"/>
                          </a>
                          <!--  <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-twitter"></i>
                            </a>

                          <a class="discord" href="#">
                            <i class="fab fa-discord"></i>
                            <i class="fab fa-discord"></i>
                          </a>
                          <a class="discord" href="#">
                            <i class="fab fa-telegram"></i>
                            <i class="fab fa-telegram"></i>
                          </a>
                          <a class="" href="#">
                            <i class="fab fa-medium"></i>
                            <i class="fab fa-medium"></i>
                          </a>

                            <a class="google-plus" href="#">
                                <i class="fab fa-google-plus-g"></i>
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                            <a class="vine" href="#">
                                <i class="fab fa-vine"></i>
                                <i class="fab fa-vine"></i>
                            </a>-->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 res-margin">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h4 class="footer-title">Freecity</h4>
                        <ul>
                            <li><a [routerLink]="['/nfts']">Explore NFT</a></li>
                            <li><a [routerLink]="['/nft/create']">Create Your NFT</a></li>
                          <li><a [routerLink]="['/collections']">Your Collections</a></li>
                          <li><a [routerLink]="['/mining']">NFT Mining Pools</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 res-margin">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h4 class="footer-title">Links</h4>
                        <ul>
                            <li><a [routerLink]="['/policy']">Privacy &amp; Terms</a></li>
                            <li><a target="_blank" href="https://freecity.gitbook.io/docs/">Document</a></li>
                            <li><a target="_blank" href="https://www.facebook.com/freecity.nfts">Facebook</a></li>
                        </ul>
                    </div>
                </div>

              <div class="col-12 col-sm-6 col-lg-3 res-margin">
                <!-- Footer Items -->
                <div class="footer-items">
                  <!-- Footer Title -->
                  <h4 class="footer-title">Official Partner</h4>
                  <ul>
                    <li><a target="_blank" href="https://www.punkkubnft.xyz/">PunkKub NFT</a></li>
                    <li><a target="_blank" href="https://www.megaland.io/">MegaLand</a></li>
                    <li><a target="_blank" href="https://diamon.finance/">Diamon</a></li>
                  </ul>
                </div>
              </div>

            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left">&copy;2022 FreeCity, All Rights Reserved.</div>
                        <!-- Copyright Right -->
                      <div class="copyright-right">

                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
