<div class="main">

  <app-header-section></app-header-section>

  <section class="content-section">
    <div class="row">
      <div class="col-12">
        <h4>Faucets</h4>
        <hr/>
      </div>
    </div>
    <div class="row" *ngIf="!isLoading">
      <div class="col-lg-12">
        <h3>Bitkub Chain Testnet</h3>
      </div>
      <div class="col-lg-6">
        <button class="btn btn-info w-100"
                (click)="faucet('0xd9487684F1E1ee9C3e557e18E310002dE784b770')">
          KUSDT 0xd9487684F1E1ee9C3e557e18E310002dE784b770
        </button>
        <!-- <button class="btn btn-info mt-2 w-100"
                (click)="faucet('0xBAE04cd49580928230C2950677A895C08167cDf2')">
          KKUB 0xBAE04cd49580928230C2950677A895C08167cDf2
        </button>
        <button class="btn btn-info mt-2 w-100"
                (click)="faucet('0xB47f12b724c03B971F9B2549d1d9A4239671cd39')">
          PUNK 0xB47f12b724c03B971F9B2549d1d9A4239671cd39
        </button>
        <button class="btn btn-info mt-2 w-100"
                (click)="faucet('0x4665868039De7D234Cfb64D121E044438e4B2491')">
          BEAN 0x4665868039De7D234Cfb64D121E044438e4B2491
        </button> -->
      </div>
      <div class="col-lg-6">
        <p>
          P2P KUSDT Address : 0xd9487684F1E1ee9C3e557e18E310002dE784b770 <br/>
          <!-- P2P KKUB Address : 0xBAE04cd49580928230C2950677A895C08167cDf2 <br/>
          P2P PUNK Address : 0xB47f12b724c03B971F9B2549d1d9A4239671cd39 <br/>
          P2P BEAN Address : 0x4665868039De7D234Cfb64D121E044438e4B2491 <br/> -->
        </p>
      </div>
    </div>
    <hr/>
    <div class="row" *ngIf="!isLoading">
      <div class="col-lg-12">
        <h3>Polygon Testnet (Mumbai)</h3>
      </div>
      <div class="col-lg-6">
        <button class="btn btn-warning w-100"
                (click)="faucet('0xD9C3Bf7b3Aa1E81c35F319b10Ec5a35cbDCe464c')">
          USDT 0xD9C3Bf7b3Aa1E81c35F319b10Ec5a35cbDCe464c
        </button>
        <button class="btn btn-warning mt-2 w-100"
                (click)="faucet('0x5678Aa8b95B58175f1c6D1A2e743601A21015277')">
          WMATIC 0x5678Aa8b95B58175f1c6D1A2e743601A21015277
        </button>
        <button class="btn btn-warning mt-2 w-100"
                (click)="faucet('0xa49006E38Ef17935eD6B173DAaCd7A21cb0657Fe')">
          PUNK 0xa49006E38Ef17935eD6B173DAaCd7A21cb0657Fe
        </button>
        <button class="btn btn-warning mt-2 w-100"
                (click)="faucet('0x95983E50b590e997581b0F0563A79dfEa1788632')">
          BEAN 0x95983E50b590e997581b0F0563A79dfEa1788632
        </button>
      </div>
      <div class="col-lg-6">
        <p>
          P2P USDT Address : 0xD9C3Bf7b3Aa1E81c35F319b10Ec5a35cbDCe464c <br/>
          P2P WMATIC Address : 0x5678Aa8b95B58175f1c6D1A2e743601A21015277 <br/>
          P2P PUNK Address : 0xa49006E38Ef17935eD6B173DAaCd7A21cb0657Fe <br/>
          P2P BEAN Address : 0x95983E50b590e997581b0F0563A79dfEa1788632 <br/>
        </p>
      </div>
    </div>
    <div class="row" *ngIf="isLoading">
      <div class="col-12 text-center">
        <app-spinner></app-spinner>
      </div>
    </div>
  </section>
</div>
