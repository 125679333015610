<header id="header">
    <!-- Navbar -->
    <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
        <div class="container-fluid header">
            <!-- Navbar Brand-->
            <a class="navbar-brand position-relative" [routerLink]="['/home']">
                <img class="navbar-brand-sticky" src="assets/img/logos/logo.png" alt="FreeCity Logo" width="56">
              <span class="brand-text brand-text-custom d-none d-md-block"> Free City</span>
              <span *ngIf="isDemoMode" class="wrapper-testnets-badge" data-toggle="popover" data-content="You are on the FreeCity test network.">Testnets</span>
              <span *ngIf="!isDemoMode" class="wrapper-powered-badge">powered by LINX</span>
            </a>
            <div class="ml-auto"></div>

            <!-- Navbar -->
            <ul class="navbar-nav items mx-auto flex-grow-1 d-flex">
              <!--
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/']">Home</a>
                </li>
                -->
                <!-- <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/nfts']">Explore</a>
                </li> -->
                <li class="nav-item dropdown hide-mobile">
                  <a class="nav-link">Explore <i class="fas fa-angle-down ml-2"></i></a>
                  <ul class="dropdown-menu" style="min-width: 260px !important;">
                      <li class="nav-item"><a [routerLink]="['/nfts']" class="nav-link">All NFTs</a></li>
                      <li class="nav-item"><a [routerLink]="['/explore-collections']" class="nav-link">Collections</a></li>
                      <!-- <li class="nav-item"><a [routerLink]="['/explore-projects']" class="nav-link">Projects</a></li> -->
                      <li class="nav-item"><a [routerLink]="['/whitelist/markets']" class="nav-link">Whitelist Markets</a></li>
                      <li class="nav-item"><a [routerLink]="['/nft/launchpads']" class="nav-link">NFT launchpads</a></li>

                      <li class="nav-item"><a [routerLink]="['/nft/reveals']" class="nav-link">NFT Reveals </a></li>

                      <li class="nav-item"><a disabled class="nav-link text-muted cursor-default">NFT Photobook <small class="ml-1">(Coming soon)</small></a></li>
                    </ul>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" [routerLink]="['/']">Explore <i class="fas fa-angle-down ml-1"></i></a>
                    <ul class="dropdown-menu">
                        <li class="nav-item"><a [routerLink]="['/explore-one']" class="nav-link">Explore Style 1</a></li>
                        <li class="nav-item"><a [routerLink]="['/explore-two']" class="nav-link">Explore Style 2</a></li>
                        <li class="nav-item"><a [routerLink]="['/explore-three']" class="nav-link">Explore Style 3</a></li>
                        <li class="nav-item"><a [routerLink]="['/explore-four']" class="nav-link">Explore Style 4</a></li>
                        <li class="nav-item"><a [routerLink]="['/auctions']" class="nav-link">Live Auctions</a></li>
                        <li class="nav-item"><a [routerLink]="['/item-details']" class="nav-link">Item Details</a></li>
                    </ul>
                </li> -->

                <!-- <li class="nav-item hide-mobile">
                  <a class="nav-link" [routerLink]="['/mining']">NFT Mining</a>
                </li> -->

                <li class="nav-item dropdown hide-mobile">
                  <a class="nav-link">Earn <i class="fas fa-angle-down ml-2"></i></a>
                  <ul class="dropdown-menu" style="min-width: 230px !important;">
                    <li class="nav-item"><a [routerLink]="['/mining']" class="nav-link">NFT Mining</a></li>
                    <!-- <li class="nav-item"><a [routerLink]="['/crafting-stations']" class="nav-link">NFT Crafting</a></li> -->
                    <li class="nav-item">
                      <a  [routerLink]="['/crafting-stations']" class="nav-link">NFT Crafting</a>
                    </li>
                    <li class="nav-item"><a [routerLink]="['/nft/gashas']" class="nav-link">NFT Gachapon <span style="margin-top: 2px" class="tx-12 badge-new text-white badge-sm badge-pill bg-danger">NEW</span></a></li>
                  </ul>
                </li>

                <!--<li class="nav-item dropdown hide-mobile">
                  <a class="nav-link">NFT Reward <i class="fas fa-angle-down ml-2"></i></a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a [routerLink]="['/mining']" class="nav-link">Mining</a></li>
                    <li class="nav-item"><a [routerLink]="['/redeem']" class="nav-link">Redeem</a></li>
                  </ul>
                </li>-->

                <li class="nav-item hide-mobile">
                  <a class="nav-link" [routerLink]="['/order-list']">P2P Trading</a>
                </li>

                <li class="nav-item dropdown hide-mobile">
                  <a class="nav-link">Stat <i class="fas fa-angle-down ml-2"></i></a>
                  <ul class="dropdown-menu">
                    <li class="nav-item"><a [routerLink]="['/rankings']" class="nav-link">Rankings</a></li>
                    <li class="nav-item"><a [routerLink]="['/activity']" class="nav-link">Activity</a></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-search-header flex-grow w-100 ml-1 position-relative" [ngClass]="hideSearchHeader?'wrapper-search-header-mobile':''">
                  <a class="nav-link ml-auto pr-2 pl-1 pl-sm-2 d-sm-flex d-md-none btn-icon-action-search-header" (click)="toggleSearchHeaderWrapper(autoCompleteSearch.value);">
                    <i class="icon-magnifier tx-28 font-weight-bolder {{router.url === '/home' ? 'icon-magnifier-home text-white-nav':'text-white-main'}}" *ngIf="!hideSearchHeader"></i>
                    <i class="icon-arrow-left tx-28 font-weight-bolder" *ngIf="hideSearchHeader"></i>
                  </a>
                  <div class="input-group input-group-search-header d-none d-md-flex" [ngClass]="hideSearchHeader?'d-flex':''">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0">
                        <i class="icon-magnifier tx-20"></i>
                      </span>
                    </div>
                    <input type="text" class="form-control border-0 pl-0 bg-transparent" #autoCompleteSearch id="autoCompleteSearch" value="" autocomplete="off" placeholder="Search items, collections, and accounts" (keyup)="searchWithDelay($event)">
                  </div>
                  <div class="wrapper-search-autocomplete" id="wrapper-search-autocomplete" style="display: none;">
                    <div *ngIf="!isLoadingSearchResults" class="wrapper-search-autocomplete-box">
                      <div *ngIf="searchResults.collections.length" class="wrapper-search-list-autocomplete">
                        <div class="wrapper-search-label-list">Collections</div>
                        <ul class="wrapper-search-list-content">
                          <li *ngFor="let collection of searchResults.collections">
                            <a (click)="jumpToCollection(collection)">
                              <div class="img-item-wrapper">
                                <div class="list-img">
                                  <img [src]="collection.logoImage | renderImageUrl: 'logo'">
                                </div>
                              </div>
                              <div class="name-item-wrapper">
                                <span class="list-name">
                                  {{collection.name}}
                                </span>
                                <span class="collections-verify ml-1">
                                  <i [ngClass]="collection.verification ? (collection.verification | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                                  [title]="collection.verification ? (collection.verification | verificationTypeToText) : (undefined | verificationTypeToText)"
                                  class=""></i>
                                </span>
                              </div>
                              <div class="amount-item-wrapper">
                                <span class="amount-img mr-2">
                                  <!-- chain img -->
                                  <img [src]="getImageSupportChainsByAbbr(collection.chain)" width="10">
                                </span>
                                <span class="amount-item">{{collection.statistic ? collection.statistic.assets : 0}} items</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="searchResults.users.length" class="wrapper-search-list-autocomplete">
                        <div class="wrapper-search-label-list">Accounts</div>
                        <ul class="wrapper-search-list-content">
                          <li *ngFor="let account of searchResults.users">
                            <a (click)="jumpToAccount(account)">
                              <div class="img-item-wrapper">
                                <div class="list-img img-nft-profile active-nft-profile active-nft-profile-header image-profile-preview" [ngClass]="(account.nftProfile != undefined && account.nftProfile.image) ? 'aura-effect-header':'image-profile-general'">
                                  <img class="avatar-sm rounded-circle" [src]="account.nftProfile.image | renderImageUrl: 'logo'">
                                </div>
                              </div>
                              <div class="name-item-wrapper">
                                <span class="list-name">
                                  {{account.name}}
                                </span>
                              </div>
                            </a>
                          </li>

                        </ul>
                      </div>
                      <div *ngIf="searchResults.assets.length" class="wrapper-search-list-autocomplete">
                        <div class="wrapper-search-label-list">Items</div>
                        <ul class="wrapper-search-list-content">
                          <li *ngFor="let asset of searchResults.assets">
                            <a (click)="jumpToNFTShow(asset)">
                              <div class="img-item-wrapper">
                                <div class="list-img">
                                  <img [src]="asset.media | renderImageUrl : 'logo'">
                                </div>
                              </div>
                              <div class="name-item-wrapper">
                                <span class="list-name">{{asset.name}}</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="!searchResults.collections.length && !searchResults.users.length && !searchResults.assets.length"  class="footer-txt-autocomplete-note tx-16">
                        No items found
                      </div>
                      <!-- <div class="footer-txt-autocomplete-note tx-16">
                        Please Enter to search all items
                      </div> -->
                    </div>
                    <div class="p-3" *ngIf="isLoadingSearchResults">
                      loading...
                    </div>
                  </div>
                </li>

                <li *ngIf="account && !isWrongNetwork" class="nav-item dropdown ml-xl-auto icons hide-mobile nav-select-chain">
                  <a class="nav-link menu-icons {{router.url === '/home' ? 'text-white-nav':''}}" *ngIf="currentChain">
                      <img [src]="currentChain.imgUrl" height="26" width="26" class="align-top">
                      <span id="current-chain-name" style="display: none;">{{currentChain.networkName}}</span>
                  </a>
                  <ul *ngIf="singletonService.supportChains && currentChain" class="dropdown-menu">
                      <li *ngFor="let chain of singletonService.supportChains | findAllBy: 'enabled'" (click)="switchChain(chain)" class="nav-item {{chain.soon ? 'disabled':''}}" >
                          <a class="nav-link d-flex justify-content-start {{chain.soon ? 'disabled':''}}" [ngClass]="currentChain.networkName == chain.networkName?'active-chain' : ''" href="javascript:void(0)"><img [src]="chain.imgUrl" height="24" width="24" class="mr-2">
                            {{chain.networkName}} {{chain.soon ? '(Soon)':''}}
                          </a>
                      </li>
                  </ul>
                </li>

                <li class="nav-item cursor-pointer icons hide-mobile" [ngClass]="!account || isWrongNetwork?'ml-xl-auto':''">
                  <a class="nav-link menu-icons" *ngIf="theme === 'light'" (click)="toggleTheme('dark')">
                    <i class="{{router.url === '/home' ? 'text-white-nav':''}} far fa-moon tx-20"></i>
                  </a>
                  <a class="nav-link menu-icons" *ngIf="theme === 'dark'" (click)="toggleTheme('light')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" class="bi bi-sun" viewBox="0 0 16 16">
                      <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                    </svg>
                  </a>
                </li>

                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" [routerLink]="['/']">Community <i class="fas fa-angle-down ml-1"></i></a>
                    <ul class="dropdown-menu">
                        <li class="nav-item"><a [routerLink]="['/blog']" class="nav-link">Blog</a></li>
                        <li class="nav-item"><a [routerLink]="['/blog-single']" class="nav-link">Blog Single</a></li>
                        <li class="nav-item"><a [routerLink]="['/help-center']" class="nav-link">Help Center</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" [routerLink]="['/']">Pages <i class="fas fa-angle-down ml-1"></i></a>
                    <ul class="dropdown-menu">

                        <!-- real -->
                        <!-- <li class="nav-item"><a [routerLink]="['/collection/create']" class="nav-link">Create Collection</a></li>
                        <li class="nav-item"><a [routerLink]="['/nft/create']" class="nav-link">Create NFT</a></li> -->
                    <!-- </ul>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/contact']" class="nav-link">Contact</a>
                </li>  -->
            </ul>
            <!-- Navbar Icons -->
            <ul class="navbar-nav icons">

              <!-- Select Chain Dropdown -->
            <!-- <ul class="navbar-nav toggle"> -->
<!-- comment 1 min ago -->

              <!-- <li *ngIf="account && !isWrongNetwork" class="nav-item dropdown">
                <a class="nav-link menu-icons {{router.url === '/home' ? 'text-white-nav':''}}" *ngIf="currentChain">
                    <img [src]="currentChain.imgUrl" height="26" width="26" class="mr-1 align-top">
                    <i class="fas fa-angle-down ml-1"></i>
                </a>
                <ul *ngIf="singletonService.supportChains && currentChain" class="dropdown-menu">
                    <li class="nav-item" *ngFor="let chain of singletonService.supportChains" (click)="switchChain(chain)">
                      <a class="nav-link d-flex justify-content-start" [ngClass]="currentChain.networkName == chain.networkName?'active-chain' : ''" href="javascript:void(0)"><img [src]="chain.imgUrl" height="24" width="24" class="mr-2"> {{chain.networkName}}</a>
                    </li>
                </ul>
              </li> -->
<!-- end comment 1 min ago -->

              <!-- <li *ngIf="account && !isWrongNetwork" ngbDropdown class="nav-item">
                <div class="btn-group">
                  <div ngbDropdown>
                    <button
                      *ngIf="currentChain"
                      ngbDropdownToggle
                      class="btn"
                      type="button"
                      id="dropdownMenuChain"
                      rippleEffect
                    >
                      <img [src]="currentChain.imgUrl" height="16" width="16" class="mr-1 align-top">
                      <span id="current-chain-name">{{currentChain.networkName}}</span>
                    </button>
                    <div *ngIf="currentChain" ngbDropdownMenu aria-labelledby="dropdownMenuChain" class="none-animation" style="top: 0 !important;" x-placement="bottom-right">
                      <a *ngFor="let chain of singletonService.supportChains" (click)="switchChain(chain)" [ngClass]="currentChain.networkId == chain.networkId ? '' : ''" ngbDropdownItem href="javascript:void(0)">
                        <img [src]="chain.imgUrl" height="16" width="16" class="mr-25">
                          {{chain.networkName}}
                      </a>
                    </div>
                  </div>
                </div>
              </li> -->
            <!-- </ul> -->
            <!--/ Select Chain Dropdown -->

               <!-- Select Language Button -->
            <!-- <ul class="navbar-nav toggle"> -->
              <!--
              <li class="nav-item dropdown dropdown-language">
                <a class="nav-link">
                    <span class="selected-language text-uppercase">{{ languageOptions[_translateService.currentLang].title }}</span>
                    <i class="fas fa-angle-down ml-1"></i>
                </a>
                <ul class="dropdown-menu">
                    <li class="nav-item" *ngFor="let lang of _translateService.getLangs()" (click)="setLanguage(lang)">
                      <a class="nav-link d-flex justify-content-start" href="javascript:void(0)"> <span class="text-uppercase align-bottom">{{ languageOptions[lang].title }}</span></a>
                    </li>
                </ul>
              </li>
-->
            <!-- <li ngbDropdown class="nav-item dropdown dropdown-language mx-2">
              <a class="round mr-025 dropdown-toggle nav-link" id="dropdown-flag" ngbDropdownToggle>
                <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }} img-language"></i>
                <span class="selected-language text-white-main tx-13 text-uppercase">{{ languageOptions[_translateService.currentLang].title }}</span>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdown-flag">
                <a class="p-2" *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                  <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }} img-language"></i>
                  <span class="text-white-main tx-13 text-uppercase align-bottom">{{ languageOptions[lang].title }}</span>
                </a>
              </div>
            </li> -->
            <!-- </ul> -->
            <!-- Select Language Button -->

              <!-- Select Currency Button -->
              <!-- <ul class="navbar-nav toggle"> -->
              <!--
              <li class="nav-item nav-select-currency">
                <a class="nav-link" (click)="modalOpenCurrency(modalCurrency)">
                  <div class="btn btn-outline-primary nav-currency rounded" style="padding: 4px 10px">
                    <span class="">
                      {{currencyConfig}}
                    </span>
                  </div>
                </a>
              </li>
              -->
            <!-- </ul> -->
            <!-- Select Currency Button -->
<!-- comment 1 min ago -->

                <!-- <li class="nav-item cursor-pointer">
                  <a class="nav-link menu-icons" *ngIf="theme === 'light'" (click)="toggleTheme('dark')">
                    <i class="{{router.url === '/home' ? 'text-white-nav':''}} far fa-moon tx-20"></i>
                  </a>
                  <a class="nav-link menu-icons" *ngIf="theme === 'dark'" (click)="toggleTheme('light')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" class="bi bi-sun" viewBox="0 0 16 16">
                      <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                    </svg>
                  </a>
                </li> -->
<!-- comment 1 min ago -->

                <!-- <li class="nav-item">
                    <a [routerLink]="['/']" class="nav-link" data-toggle="modal" data-target="#search">
                        <i class="fas fa-search"></i>
                    </a>
                </li> -->

              <li *ngIf="account && !isWrongNetwork && nftProfile" class="nav-item dropdown nav-profile-img mr-3">
                <a class="owner-meta d-flex align-items-center ml-2">
                  <!--<img class="avatar-sm rounded-circle" [src]="(userProfileInfos ? (userProfileInfos.profileImage | renderImageUrl: 'logo') : undefined)" alt=""> -->
                  <div class="img-nft-profile active-nft-profile active-nft-profile-header image-profile-preview {{nftProfile.image ? 'aura-effect-header':'image-profile-general'}} ">
                    <img class="avatar-sm rounded-circle" [src]="(nftProfile.image | renderImageUrl: 'logo')" alt="">
                  </div>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item"><a [routerLink]="['/account']" class="nav-link">My Wallet</a></li>
                  <li class="nav-item"><a [routerLink]="['/nft-profile']" class="nav-link">NFT Profile</a></li>
                  <li class="nav-item"><a [routerLink]="['/collections']" class="nav-link">My Collections</a></li>
                  <li class="nav-item"><a [routerLink]="['/collection/create']" class="nav-link">Create Collection</a></li>
                  <li class="nav-item"><a [routerLink]="['/nft/create']" class="nav-link">Create NFT</a></li>
                  <li class="nav-item"><a [routerLink]="['/pro-listing']" class="nav-link">Pro-listing</a></li>
                  <li class="nav-item"><a [routerLink]="['/settings']" class="nav-link">Settings</a></li>
                </ul>
              </li>
                  <!-- Navbar Action Button -->
                <!-- <ul class="navbar-nav action"> -->
                <li class="nav-item ml-2 wallet-connect-wrapper">
                    <a *ngIf="!account"
                      (click)="connectWalletModal(modalConnectWallet)" class="btn ml-lg-auto {{router.url === '/home' ? 'btn-bordered-white-home':'btn-bordered-white'}} px-3">
                      <i class="icon-wallet mr-2 tx-24 align-middle {{router.url === '/home' ? 'text-white-nav':''}}"></i>
                      <span class="{{router.url === '/home' ? 'text-white-nav':''}}">Connect</span>
                    </a>
                    <a *ngIf="account && !isWrongNetwork"
                      (click)="logout(logoutModal)" class="btn ml-lg-auto {{router.url === '/home' ? 'btn-bordered-white-home':'btn-bordered-white'}}  px-3">
                      <i class="icon-wallet mr-lg-2 tx-24 align-middle {{router.url === '/home' ? 'text-white-nav':''}}"></i>
                      <span class="{{router.url === '/home' ? 'text-white-nav':''}} d-lg-block d-none">
                        <span id="current-account">
                          {{account | hideWalletAddress}}
                        </span>
                      </span>
                    </a>
                    <a *ngIf="account && isWrongNetwork"
                      (click)="openSwitchChainModal(switchChainModal)" class="btn ml-lg-auto btn-danger btn-wrong-network text-white px-3">
                      Wrong network
                    </a>
                </li>
              <!-- </ul> -->
            </ul>

            <!-- Navbar Toggler -->
            <ul class="navbar-nav toggle">
                <li class="nav-item">
                    <a class="nav-link tx-30 pr-0 menu-toggle-icon" data-target="#menu" data-toggle="modal">
                        <i class="fas fa-bars toggle-icon m-0"></i>
                    </a>
                </li>
            </ul>

            <!-- =========================== mobile-menu-modal =========================== -->
            <!-- <div id="menu2" class="modal p-0 modal-menu-mobile">
              <div class="modal-dialog dialog-animated">
                  <div class="modal-content h-100">
                    <div class="modal-header border-bottom" data-dismiss="modal">
                        Menu <i class="far fa-times-circle icon-close"></i>
                    </div> -->

                    <!-- explore -->
                    <!-- <div class="">
                      <li class="dropdown">
                        <a class="">Explore <i class="fas fa-angle-down ml-2"></i></a>
                        <ul class="dropdown-menu">
                            <li class=""><a [routerLink]="['/nfts']" class="">All NFTs</a></li>
                            <li class=""><a [routerLink]="['/explore-collections']" class="">Collections</a></li>
                        </ul>
                      </li>
                    </div> -->
                    <!-- explore -->

                    <!-- Mining -->
                    <!-- <div class="">
                      <li class="">
                        <a class="" [routerLink]="['/mining']">Mining</a>
                      </li>
                    </div> -->
                    <!-- Mining -->

                    <!-- Stat -->
                    <!-- <div class="">
                      <li class="dropdown">
                        <a class="">Stat <i class="fas fa-angle-down ml-2"></i></a>
                        <ul class="dropdown-menu">
                          <li class=""><a [routerLink]="['/activity']" class="">Activity</a></li>
                          <li class=""><a [routerLink]="['/rankings']" class="">Rankings</a></li>
                        </ul>
                      </li>
                    </div> -->
                    <!-- Stat -->

                    <!-- switch chain -->
                    <!-- <div class="">
                        <li *ngIf="account && !isWrongNetwork" class="dropdown ml-xl-auto icons">
                          <a class=" menu-icons {{router.url === '/home' ? 'text-white-nav':''}}" *ngIf="currentChain">
                              <img [src]="currentChain.imgUrl" height="26" width="26" class="mr-1 align-top">
                              <span id="current-chain-name" style="display: none;">{{currentChain.networkName}}</span>
                              <i class="fas fa-angle-down ml-1"></i>
                          </a>
                          <ul *ngIf="singletonService.supportChains && currentChain" class="dropdown-menu">
                              <li class="" *ngFor="let chain of singletonService.supportChains" (click)="switchChain(chain)">
                                <a class=" d-flex justify-content-start" [ngClass]="currentChain.networkName == chain.networkName?'active-chain' : ''" href="javascript:void(0)"><img [src]="chain.imgUrl" height="24" width="24" class="mr-2"> {{chain.networkName}}</a>
                              </li>
                          </ul>
                        </li>
                    </div> -->
                    <!-- switch chain -->

                    <!-- switch theme -->
                    <!-- <div class="">
                        <li class="">
                          <a class=" menu-icons" *ngIf="theme === 'light'" (click)="toggleTheme('dark')">
                            <i class="{{router.url === '/home' ? 'text-white-nav':''}} far fa-moon tx-20"></i>
                          </a>
                          <a class=" menu-icons" *ngIf="theme === 'dark'" (click)="toggleTheme('light')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#ffffff" class="bi bi-sun" viewBox="0 0 16 16">
                              <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                            </svg>
                          </a>
                        </li>
                    </div> -->
                    <!-- switch theme -->

                  <!-- </div>
                </div>
            </div> -->
            <!-- =========================== mobile-menu-modal =========================== -->
        </div>
    </nav>
</header>

<ng-template #logoutModal let-modal>
  <div class="text-right wrapper-close-modal">
      <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body pt-2">
      <div class="text-center" style="width: 100%">

          <!--<img src="../../../assets/icons/unicorn.png" width="72"/> -->
          <h4  >
              <!-- {{ 'common.disconnect' | translate }} -->
              Disconnect
          </h4>
          <p   style="margin-top: 8px">
              <!-- {{ 'common.disconnect_confirm' | translate }} -->
              Are you sure you want to disconnect ?
          </p>
      </div>
  </div>
  <div class="text-center" style="margin-top: 5px;margin-bottom: 50px">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.close('close')">
          <!-- {{ 'common.cancel' | translate }} -->
          Cancel
      </button>
      <div class="border-primary-main-wrapper border-primary-main-modal-wrapper" style="margin-left: 10px;display: inline-block;">
        <button type="button" class="btn btn-outline-primary border-primary-main" (click)="modal.close('logout')">
          <!-- {{ 'common.disconnect_yes' | translate }} -->
          Yes, disconnect
        </button>
      </div>
  </div>
</ng-template>

<ng-template #switchChainModal let-modal>
  <div class="text-right wrapper-close-modal">
      <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center">
      <div class="text-center" style="width: 100%">

           <img src="assets/img/chains/bkc.png" width="72"/>
          <p style="margin-top: 25px">
              <!-- {{ 'common.switch' | translate }} -->

              <!-- 'Switch to Bitkub Chain ?' -->
              Switch to {{isDemoMode ? 'Bitkub Chain Testnet' : 'Bitkub Chain'}} ?
          </p>
      </div>
  </div>
  <div class="text-center" style="margin-top: 10px;margin-bottom: 30px">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.close('close')">
          <!-- {{ 'common.nope' | translate }} -->
          nope
      </button>
      <button *ngIf="isDemoMode" type="button" style="margin-left: 10px" class="btn btn-outline-primary"
              (click)="singletonService.switchToBitkubChain_testnet();modal.close('close');">
          yes
      </button>
      <button *ngIf="!isDemoMode" type="button" style="margin-left: 10px" ngbAutofocus class="btn btn-outline-primary"
              (click)="singletonService.switchToBitkubChain();modal.close('close');">
          yes
      </button>
  </div>
</ng-template>

<!-- ? currency selection -->
<ng-template #modalCurrency let-modal>
  <div class="modal-header modal-header-primary p-3">
    <h5 class="modal-title text-white-main">
      Select Currency
    </h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true"><i class="mdi mdi-close"></i></span>
      <!-- <span aria-hidden="true">&times;</span> -->
    </button>
  </div>
  <div class="modal-body modal-content-currency p-3" tabindex="0" ngbAutofocus>
    <button *ngFor="let currency of fiatsList"
      type="button"
      class="btn {{currency === currencyConfig ? 'btn-primary' : 'btn-outline-primary'}}" rippleEffect
      (click)="setCurrencyConfig(currency);modal.dismiss('Cross click');">
      {{currency}}
    </button>
  </div>
</ng-template>
<!--/ currency selection -->

<ng-template #modalConnectWallet let-modal>
  <div class="modal-body p-0">
    <button type="button" class="close close-modal-connect-wallet" (click)="modal.dismiss('Cross click')">
      <span>&times;</span>
    </button>

    <!-- connect-with-crypto-and-social -->
    <div id="wrapper-connect-with-crypto-and-social" class="wrapper-connect-with-crypto-and-social">
      <h4 class="title-connect text-white text-center mt-2 mb-3">
        Log in or Create an account
      </h4>
      <div class="wrapper-connect-crypto d-flex justify-content-center align-items-center flex-column gap-075r">
        <button class="btn-connect-wallet-modal" (click)="connectWallet();modal.dismiss()">
          <div class="d-flex align-items-center">
            <div class="img-connect-wallet"><img src="../../assets/img/wallets/metamask.png" width="50"></div>
            <div class="d-flex align-items-center flex-column text-left">
              <div class="title-connect-wallet">Metamask</div>
              <div class="desc-connect-wallet">Connect with Metamask Wallet</div>
            </div>
          </div>

        </button>
        <!-- <button class="btn-connect-wallet-modal" >
          <div class="img-connect-wallet"><img src="../../assets/img/wallets/bitkub-next-logo.svg" class="gray-filter" width="50"></div>
          <div class="title-connect-wallet">Bitkub Next (Soon)</div>
          <div class="desc-connect-wallet">Connect with Bitkub Next Wallet</div>
        </button> -->
        <button class="btn-connect-wallet-modal btn-disabled">
          <div class="d-flex align-items-center">
            <div class="img-connect-wallet"><img src="../../assets/img/wallets/linx-w.png" class="gray-filter" width="50"></div>
            <div class="d-flex align-items-center flex-column text-left">
              <div class="title-connect-wallet">Linx Wallet (Soon)</div>
              <div class="desc-connect-wallet">Connect with Linx Wallet</div>
            </div>
          </div>
        </button>
      </div>
      <h5 class="title-social text-white text-left mt-3 mb-2 pl-1">SOCIAL</h5>
      <div class="wrapper-connect-social d-flex align-items-center flex-wrap gap-075r">
        <button class="btn-connect-social-modal btn-connect-wallet-modal py-2 btn-disabled">
          <div class="d-flex align-items-center">
            <div class="img-connect-wallet"><img src="../../assets/img/wallets/facebook.png" class="gray-filter" width="50"></div>
            <div class="d-flex align-items-center flex-column text-left">
              <div class="title-connect-wallet">Facebook</div>
              <div class="desc-connect-wallet tx-10">Connect with Facebook</div>
            </div>
          </div>
        </button>
        <button class="btn-connect-social-modal btn-connect-wallet-modal py-2 btn-disabled">
          <div class="d-flex align-items-center">
            <div class="img-connect-wallet"><img src="../../assets/img/wallets/google.png" class="gray-filter" width="50"></div>
            <div class="d-flex align-items-center flex-column text-left">
              <div class="title-connect-wallet">Google</div>
              <div class="desc-connect-wallet tx-10">Connect with Google</div>
            </div>
          </div>
        </button>
        <button class="btn-connect-social-modal btn-connect-wallet-modal py-2 btn-disabled" (click)="openConntectSection('wrapper-connect-with-crypto-and-social','wrapper-login-with-username-and-password')">
          <div class="d-flex align-items-center">
            <div class="img-connect-wallet"><img src="../../assets/img/wallets/email.png" class="gray-filter" width="50"></div>
            <div class="d-flex align-items-center flex-column text-left">
              <div class="title-connect-wallet">Email</div>
              <div class="desc-connect-wallet tx-10">Sign in with email</div>
            </div>
          </div>
        </button>
      </div>
      <!-- <div class="mt-2 pl-1">
        <a class="btn content-btn text-primary tx-13 pr-2">Sign in with email </a>
      </div> -->
    </div>

    <!-- login-with-username-and-password -->
    <div id="wrapper-login-with-username-and-password" class="wrapper-login-with-username-and-password" style="display: none;">
      <div class="wrapper-login author-area">
        <form class="item-form card no-hover pt-5 pb-5">
          <h4 class="title-connect text-white-main text-center mt-0 mb-2">
            Log In
          </h4>
          <p class="text-muted tx-14 mt-0 mb-3 text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
          <div class="row">
              <div class="col-12">
                  <div class="form-group mt-3 mb-2">
                      <input type="text" class="form-control" name="name" placeholder="Your username or email">
                  </div>
              </div>
              <div class="col-12">
                  <div class="form-group mt-2 mb-4">
                      <input type="password" class="form-control" name="password" placeholder="Your Password">
                  </div>
              </div>
              <div class="col-12 text-center">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="my-2">
                    <a class="text-primary tx-14" (click)="openConntectSection('wrapper-login-with-username-and-password','wrapper-forgot-password')">Forgot password</a>
                  </div>
                  <div class="mb-2">
                    <button class="btn btn-primary mt-2 text-white py-3 px-5">Log In</button>
                  </div>
                </div>
                <hr class="my-4">
                <div class="mt-2">
                  <button class="btn btn-bordered-light text-white py-3 px-4 px-md-5 tx-14 mt-2" (click)="openConntectSection('wrapper-login-with-username-and-password','wrapper-connect-with-crypto-and-social')"> <i class="icon-arrow-left mr-2 text-white-main tx-12"></i> Login with wallet</button>
                </div>
              </div>
          </div>
      </form>
      </div>
    </div>

    <!-- forgot-password -->
    <div id="wrapper-forgot-password" class="wrapper-forgot-password" style="display: none;">
      <div class="wrapper-forgot-password author-area">
        <form class="item-form card no-hover pt-5 pb-5">
          <h4 class="title-connect text-white-main text-center mt-0 mb-2">
            Recover
          </h4>
          <p class="text-muted tx-14 mt-0 mb-3 text-center">Enter your Email to reset your password</p>
          <div class="row">
              <div class="col-12">
                  <div class="form-group mt-2 mb-3">
                      <input type="text" class="form-control" name="email" placeholder="Enter your email">
                  </div>
              </div>
              <div class="col-12 text-center">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="mb-2">
                    <button class="btn btn-bordered-light text-white py-3 px-3 px-md-4 tx-14 mt-3" (click)="openConntectSection('wrapper-forgot-password','wrapper-login-with-username-and-password')"> <i class="icon-arrow-left mr-2 text-white-main tx-12"></i> Back to login</button>
                  </div>
                  <div class="mb-2">
                    <button class="btn btn-primary mt-3 text-white py-3 px-4 px-md-5">Recover</button>
                  </div>
                </div>
              </div>
          </div>
      </form>
      </div>
    </div>

  </div>

</ng-template>
