<div class="main">
  <app-header-section></app-header-section>

  <section class="author-area nft-profile-page">

    <div class="container">
        <div class="row justify-content-center">

          <div class="col-12 col-md-12">
            <!-- Intro -->
            <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1">
              <div class="intro-content">
                  <span>nft</span>
                  <h3 class="mt-3 mb-0">
                    Profile
                  </h3>
              </div>
            </div>
          </div>

          <!-- Loading -->
          <div *ngIf="isLoading" class="col-12 text-center py-5 my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!-- Profile Form -->
          <div *ngIf="!isLoading" class="item-form card no-hover bg-transparent border-0 shadow-none px-0">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-9 order-2 order-lg-1">
                      <div class="form-group mb-5">
                        <div class="txt-label-form font-weight-bold mb-0">Profile Image </div>
                        <p class="mt-1 mb-3"><small>Select profile from your NFTs mining (Total assets in mining: {{nfts.length}} Assets)</small></p>
                        <div *ngIf="!isNftsLoading" class="nfts-profile-image-wrapper d-flex align-items-center flex-wrap gap-3">
                          <ng-container *ngFor="let nft of nfts">
                            <div class="nfts-profile-image-content aura-effect-hover
                             {{
                             selectedTokenId.eq(nft.tokenId) &&
                             selectedCollectionAddress == nft.collectionAddress &&
                             nft.tokenId.eq(profile.tokenId) &&
                             nft.collectionAddress == profile.nftAddress ? 'active-nft-profile':''}}"
                                 (click)="selectNft(nft)" title="{{nft.collectionName}}"
                            >
                              <div class="d-flex align-items-center py-2 px-2 ">
                                <img [src]="nft.image" width="55" height="55" class="mr-2" alt="">
                                <div class="nft-profile-info">
                                  <div class="text-white-main text-truncate tx-14">{{nft.collectionName}}</div>
                                  <div class="text-muted tx-12 text-truncate">{{nft.name}}</div>
                                  <div class="text-muted tx-12 text-truncate"><span *ngIf="nft.rarity">{{nft.rarity}}</span></div>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <div class="nfts-profile-image-content btn-add-more-nft-profile">
                            <div (click)="jumpToAddMore()" class="d-flex align-items-center justify-content-center tx-15 py-2 px-2">
                              + Add more
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-5">
                        <div class="txt-label-form font-weight-bold mb-0">Profile Image Frame</div>
                        <p class="mt-1 mb-2"><small>Select your profile image frame (<a href="#" class="tag-a-custom tx-13">Buy frame</a>)</small></p>
                        <div class="dropdown">
                          <button class="btn dropdown-toggle w-100 py-2 d-flex align-items-center shadow-none" type="button" data-toggle="dropdown" aria-expanded="false">
                            <img src="assets/img/profile-image-frame/freecity-frame.png" class="mr-3" width="32" height="32">
                            <div class="frame-name mr-3" style="text-transform: uppercase;">
                              FREE CITY FRAME
                            </div>
                            <div>
                              <div class="text-muted tx-14 mb-1">Rarity : Super rare</div>
                              <div class="text-muted tx-14">Frame Effect : FreeCity Signature Aurora</div>
                            </div>
                          </button>
                          <div class="dropdown-menu dropdown-menu-full">
                            <a class="dropdown-item py-2 d-flex align-items-center" type="button" data-toggle="dropdown" aria-expanded="false">
                              <img src="assets/img/profile-image-frame/freecity-frame.png" class="mr-3" width="32" height="32">
                              <div class="frame-name mr-3" style="text-transform: uppercase;">
                                FREE CITY FRAME
                              </div>
                              <div>
                                <div class="text-muted tx-14">Rarity : Super rare</div>
                                <div class="text-muted tx-14">Frame Efffect : Aurora</div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-5">
                        <div class="txt-label-form font-weight-bold mb-0">Clan (next phase)</div>
                        <p class="mt-1 mb-2"><small>Select your clan member card to show in your profile image (<a class="tag-a-custom tx-13" href="#">How to get clan member card</a>)</small></p>
                        <div class="dropdown">
                          <button class="btn dropdown-toggle w-100 py-2 shadow-none" type="button" data-toggle="dropdown" aria-expanded="false" disabled>
                            <img src="assets/img/clan/freecity.png" class="mr-2 rounded-circle" width="32" height="32">
                            <span style="text-transform: uppercase;">
                              Free City Clan
                            </span>
                          </button>
                          <div  class="dropdown-menu dropdown-menu-full">
                            <a class="dropdown-item">
                              <img src="assets/img/clan/dargon-clan.png" class="mr-2 rounded-circle" width="32" height="32">
                              <span style="text-transform: uppercase;">
                                Dragon Clan
                              </span>
                            </a>

                          </div>
                        </div>
                        <div class="mt-2 text-muted tx-13">Read Free City clan's vision mission and direction <a href="#" class="tag-a-custom tx-14 ml-1"></a><i class="icon-share-alt"></i></div>
                      </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-3 px-5 align-content-center text-center order-1 order-lg-2 mb-4 mb-lg-0">
                      <p class="text-white-main mb-4 mt-0 font-weight-bolder">Preview</p>
                      <ng-container *ngIf="selectedImage">
                        <div class=" img-nft-profile active-nft-profile aura-effect image-profile-preview">
                          <img [src]="selectedImage"
                            class="rounded-circle" alt="">
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!selectedImage">
                        <p class="text-muted py-4 border tx-14 mock-preview-img">
                          <i>Select your NFT to view</i>
                        </p>
                      </ng-container>
                    </div>
                    <div class="col-12 text-center order-3">
                      <button [disabled]="isSaving" (click)="save()" class="btn btn-primary mt-3 px-4 text-white w-100 max-w-300">
                        <span *ngIf="isSaving" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                        Save
                      </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
  </section>

  <app-modal-menu></app-modal-menu>
  <!-- <app-footer-section></app-footer-section> -->
</div>

