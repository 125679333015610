<app-selected-filter-card-section></app-selected-filter-card-section>
<div *ngIf="isFetchingActivities" class="col-12 mb-5 pb-5 text-center">
  <div  class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <p class="text-center text-muted">Fetching activities</p>
</div>
<div *ngIf="!isFetchingActivities && activities.length"  class="activity-content mt-3">
  <div class="">
    <div class="activity-headers activity-rows">
      <div class="activity-col-sale d-none d-sm-block"></div>
      <div class="activity-col-item text-white-main">Item</div>
      <div class="activity-col-price text-white-main">Price</div>
      <div class="activity-col-quantity text-white-main d-none d-sm-block">Quantity</div>
      <div class="activity-col-from text-white-main">From</div>
      <div class="activity-col-to text-white-main">To</div>
      <div class="activity-col-time text-white-main d-none d-sm-block">Time</div>
    </div>
    <button  *ngFor="let activity of activities" (click)="jumpToNFTDetails(activity)" class="activity-rows">
      <div class="activity-col-sale text-white-main text-center d-none d-sm-block">
        <i [ngClass]="activity.eventType | eventTypeToText: false" class="tx-18 mr-1 text-white-main"></i>
        <span class="">{{activity.eventType | eventTypeToText}}</span>
      </div>
      <div class="activity-col-item">
        <div class="d-flex align-items-center">
          <div class="img-event-wrapper mr-2">

            <img *ngIf="activity.asset.media && !(activity.asset.isAnimation || activity.asset.isAnimation == true)" [src]="activity.asset.media | renderImageUrl: 'logo' : true" width="50" height="50" class="img-event-content">
            <video *ngIf="activity.asset.media && activity.asset.isAnimation && activity.asset.isAnimation == true"
              [poster]="activity.asset.media | renderImageUrl : 'logo'"
              [src]="activity.asset.animationUrl"
              width="50" height="50"
              class="img-event-content">
            </video>

          </div>
          <div>
            <div class="tx-14 text-muted show-2-line">
              {{activity.asset.name}}
            </div>
            <div class="d-flex align-items-center mt-01">
              <span class="text-truncate font-weight-medium text-white-main">
                #{{activity.asset.tokenId}}
              </span>
              <img [src]="getImageSupportChainByAbbr(activity.asset.chain)"
              [title]="activity.asset.chain" width="16" class="ml-1">
              <div class="d-block d-sm-none ml-1">
                <i [ngClass]="activity.eventType | eventTypeToText: false" class="tx-12 mr-1 text-muted"></i>
                <span class="text-muted">{{activity.eventType | eventTypeToText}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="activity-col-price">
        <div class="text-right">
          <!-- <img src="../../../assets/img/tokens/eth.svg" width="10" class="mr-2"> -->
          <span class="text-white-main">
            {{activity.order.acceptedTokenAmountNumber | gameNumberFormat: 2 : false}}
            {{activity.order.acceptedTokenSymbol ? activity.order.acceptedTokenSymbol.toUpperCase() : ''}}
          </span>
        </div>
        <div class="text-muted tx-14 text-right">
          {{ activity.order.acceptedTokenAmountNumber * activity.order.acceptedTokenInfo.usd | gameNumberFormat: 2 : false}} USD
          <!-- {{( activity.order.acceptedTokenAmountNumber * latestTokenPrice[activity.order.acceptedTokenType == 'kub' ? 'kkub' : (activity.order.acceptToken ? activity.order.acceptToken.symbol.toLowerCase() : (activity.order.acceptedTokenType ?activity.order.acceptedTokenType.toLowerCase():''))]) | gameNumberFormat: 2 : false }} USD -->
        </div>
        <div class="d-block d-sm-none text-right">
          <span class="text-white-main tx-10">{{activity.createdAt | timeFromNow}}</span>
        </div>
      </div>
      <div class="activity-col-quantity d-none d-sm-block">
        <span class="text-white-main">{{activity.asset.amountSupplyCreate}}</span>
      </div>
      <div class="activity-col-from">
        <a [routerLink]="['/account/'  + (activity.seller && activity.sellerUser ? (activity.sellerUser.name) : (activity.seller))]" >
          {{activity.seller && activity.sellerUser ? (activity.sellerUser.name | hideWalletAddress ) : (singletonService.isAddressZero(activity.seller) ? '' :  (activity.seller | hideWalletAddress )) }}
        </a>
      </div>
      <div class="activity-col-to">
        <a [routerLink]="['/account/'  + (activity.buyer && activity.buyerUser ? (activity.buyerUser.name) : (activity.buyer))]" >
          {{activity.buyer && activity.buyerUser ? (activity.buyerUser.name | hideWalletAddress) : (singletonService.isAddressZero(activity.buyer) ? '' : (activity.buyer | hideWalletAddress )) }}
        </a>
      </div>
      <div class="activity-col-time d-none d-sm-block">
        <a *ngIf="activity.createdAt" class="tx-14" (click)="openTxScanUrl(activity.transactionHash,activity.chain); $event.stopPropagation();">
          <span>{{activity.createdAt | timeFromNow}}</span>
          <i *ngIf="activity.transactionHash" class="icon-share-alt tx-16 ml-1"></i>
        </a>
        <span *ngIf="!activity.createdAt">
          -
        </span>
      </div>
    </button>
  </div>
</div>
<div *ngIf="!isFetchingActivities && activities && !activities.length">
  <div class="col-12 text-center py-5">
    <div class="text-center mb-2">
      <!-- <i class="icon-drawer tx-40"></i> -->
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-list-task" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"/>
        <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z"/>
        <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"/>
      </svg>
    </div>
    No activities.
  </div>
</div>
<div *ngIf="isLazyLoading">
  <div class="col-12 text-center py-5">
    <div  class="spinner-border text-primary" role="status">
      <span class="sr-only">lazy loading...</span>
    </div>
  </div>
</div>


<!--
<div *ngIf="activities.length" class="row items mb-5">
  <table>
    <thead>
      <tr>
        <th></th>
        <th>Item</th>
        <th>Price</th>
        <th>Quantity</th>
        <th>From</th>
        <th>To</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let activity of activities" (click)="jumpToNFTDetails(activity)" class="cursor-pointer">
        <td>
          <i [ngClass]="activity.eventType | eventTypeToText: false" class="mr-3 mb-3"></i>
          {{activity.eventType | eventTypeToText}}
        </td>
        <td>
          <img [src]="activity.asset.media | renderImageUrl: 'logo' : true" height="60" width="60">
          {{activity.asset.name}}
        </td>
        <td>
          {{activity.order.acceptedTokenAmountNumber}}
          {{activity.order.acceptedTokenType | toUpperCase}}
        </td>
        <td>{{activity.asset.amountSupplyCreate}}</td>
        <td>{{activity.order.seller | hideWalletAddress}}</td>
        <td>{{activity.order.buyer | hideWalletAddress}}</td>
        <td>{{activity.timestamp | timeFromNow}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!activities.length" class="row items my-5 py-5">
  <div class="col-12 text-center">
    No activities.
  </div>
</div> -->
