import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {BackendService} from "../../services/backend.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NftService} from "../../services/nft.service";
import {environment} from "../../../environments/environment";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import { Logger } from '../../services/logger.service';
import {BigNumber, ethers} from "ethers";
import {TOKEN_TYPE} from "../../constant/valuables";
import * as _ from 'lodash';
const log = new Logger('WhitelistBatchSellComponent');

@Component({
  selector: 'app-whitelist-batch-sell',
  templateUrl: './whitelist-batch-sell.component.html',
  styleUrls: ['./whitelist-batch-sell.component.scss']
})
export class WhitelistBatchSellComponent implements OnInit {

  currentChain:any
  loading = true;
  isCancelLoading = false;
  id:any;
  demoMode = false;
  account:any;
  isApproved = false
  isApproveLoading = false
  market:any;
  payments:any
  selectedPayment
  canUseNFTAddress
  nftAddress:any = null
  isListingLoading = false;
  rangeTokenIds = []
  fromTokenId:any
  toTokenId:any
  amount:any
  price:any
  acceptedTokenAddress:any;
  ownedIds:any;
  cancelAmount = 0;
  orderIds = [];

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    public router:Router,
    private route: ActivatedRoute,
    private modalService:NgbModal,
    private freeCityContractService:FreeCityContractService,
    private nftService:NftService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');

    this.demoMode = environment.features.DEMO_MODE
  }

  async ngOnInit(){
    this.currentChain = await this.singletonService.getCurrentChain()
    this.account = await this.singletonService.account
    this.market = await this.backendService.getMarket(this.id)
    //await this.fetchAssets();
    this.loading = false;
    //this.isApproved = await this.nftService.checkApprove(this.market.address, this.market.asset)
    if(this.currentChain){
      await this.initFormData()
    }
    this.loading = false
  }

  async initFormData(){
    await this.getAcceptedTokenAddress()

    this.payments = await this.backendService.getPaymentTokensByChainAbbr(this.currentChain.networkAbbr)
    this.payments = _.filter(this.payments, {address:this.acceptedTokenAddress.toString().toLowerCase()})
    log.debug("this.payments => %o",this.payments)

    this.selectedPayment = this.payments[0]
  }

  async getAcceptedTokenAddress(){
    this.acceptedTokenAddress = await this.freeCityContractService.getAcceptedTokenAddressFromWLMarket(this.market.address)
  }

  async checkApproved(){
    log.debug("checkApproved... this.nftAddress : %o",this.nftAddress)
    this.isApproved = await this.freeCityContractService.checkWhiteListApprove(this.market.address,this.nftAddress)
    log.debug("checkApproved this.isApproved => %o",this.isApproved)

  }

  delayTimer
  isLoadingValidateSlug = false
  async isValidNFTAddressWithDelay(){
    const self = this

    clearTimeout(self.delayTimer);
    self.isLoadingValidateSlug= true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.checkNftAddress()
        self.isLoadingValidateSlug= false
      }catch(err){
        self.isLoadingValidateSlug= false
      }
    }, 1000);

  }

  async checkNftAddress(){
    log.debug("check nft "+this.nftAddress)
    const isResetFlag = (this.nftAddress == undefined) || (this.nftAddress.trim() == '')
    if(isResetFlag){
      this.canUseNFTAddress = undefined
      log.debug("reset flag nft")
    }else{
      if(!ethers.utils.isAddress(this.nftAddress)){
        this.canUseNFTAddress = false
        log.debug("not address")
      }else{
        try{
          log.debug("check approve")
          await this.checkApproved()
          this.canUseNFTAddress = true
        }catch(err){
          log.error(err)
          this.canUseNFTAddress = false
        }
        try{
          this.ownedIds = await this.nftService.getOwnNFTIds(this.nftAddress)
          this.orderIds = await this.freeCityContractService.getAvailableOrderIds(this.market.address)
          this.ownedIds = this.ownedIds.map((val)=> val.toNumber())
        }catch(e){
          log.error(e)
        }
      }
    }
  }

  async calculateRangeTokenIds(){
    log.debug("calculateRangeTokenIds...")
    if(this.fromTokenId != undefined && this.toTokenId != undefined){
      this.rangeTokenIds = await this.getRangeNumber(this.fromTokenId,this.toTokenId)
      log.debug("this.rangeTokenIds => %o",this.rangeTokenIds)
      const filteredArray = this.rangeTokenIds.filter(value => this.ownedIds.includes(value));
      this.rangeTokenIds = filteredArray
    }else{
      this.rangeTokenIds = []
    }
  }

  async getRangeNumber(from,to,step = 1){ // (0,10,step = 1) =>  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    let range_arr = _.range(from,Number(to)+1,step);
    log.debug("range_arr => %o",range_arr)
    return range_arr
  }

  async approve(){
    this.isApproveLoading = true
    this.freeCityContractService.doWhitelistApprove(this.market.address, this.nftAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve receipt ", receipt)
      this.singletonService.fire('success','Approved', 'Success approve contract to listing on market.')
      await this.checkApproved()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproveLoading = false
    })
  }

  selectPayment(payment){
    this.selectedPayment = payment
  }

  batchListing(){
    this.isListingLoading = true
    log.debug("selected payment ",this.selectedPayment)
    let itemIds = this.rangeTokenIds
    let tokenType = TOKEN_TYPE.ERC721 // TODO : receive from server
    let itemAddr = this.nftAddress
    let itemAmount = 1
    let acceptedTokenId = 0
    let acceptedTokenType = this.selectedPayment.tokenType
    let acceptedTokenAddr = this.selectedPayment.address
    let acceptedTokenAmount = ethers.utils.parseEther(this.price)

    log.debug(" itemIds ",itemIds)
    log.debug(" tokenType ",tokenType)
    log.debug(" itemAddr ",itemAddr)
    log.debug(" itemAmount ",itemAmount)
    log.debug(" acceptedTokenId ",acceptedTokenId)
    log.debug(" acceptedTokenType ",acceptedTokenType)
    log.debug(" acceptedTokenAddr ",acceptedTokenAddr)
    log.debug(" acceptedTokenAmount ",acceptedTokenAmount.toString())

    this.freeCityContractService.whitelistListing(
      this.market.address,
      this.nftAddress,
      itemIds,
      acceptedTokenAmount
    ).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("receipt.events ",receipt.events)
      let createdEvent = receipt.events.find((e)=> e.event === 'Created')
      log.debug("createdEvent ",createdEvent)
      log.debug("order id : ",createdEvent.args.id)
      log.debug("seller : ",createdEvent.args.seller)
      log.debug("meta : ",createdEvent.args.meta)
      log.debug("order: ",createdEvent.args.order)
      this.singletonService.fire('success','Listing', 'Success listing on market.')

      // redirect
      this.router.navigate(['/whitelist/market/'+this.market.id])

    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Listing failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isListingLoading = false
    })
  }

  async withdraw(){
    this.isCancelLoading = true
    this.freeCityContractService.withdrawFromWLMarket(
      this.market.address,
      this.cancelAmount
    ).then(async (transaction)=>{
      let receipt = await transaction.wait()
      let event = receipt.events.find((e)=> e.event === 'Cancel')
      this.singletonService.fire('success','Cancel', 'Success Cancel on market.')

      // redirect
      this.router.navigate(['/whitelist/market/'+this.market.id])

    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Cancel failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isCancelLoading = false
    })
  }

}
