import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import { Logger } from '../../services/logger.service';
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {MiningService} from "../../services/mining.service";
import {SingletonService} from "../../services/singleton.service";
import {ethers} from "ethers";
import {Router} from "@angular/router";
const log = new Logger('NftProfileComponent');
@Component({
  selector: 'app-nft-profile',
  templateUrl: './nft-profile.component.html',
  styleUrls: ['./nft-profile.component.scss']
})
export class NftProfileComponent implements OnInit {

  selectedTokenId;
  selectedCollectionAddress;
  selectedImage;
  pools:any;
  profile:any;
  isSetBefore = true

  nfts:any;

  isLoading = true
  isNftsLoading = false
  isSaving = false

  profileSubject:any;


  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private miningService:MiningService,
    private router:Router
  ) {

  }

  async ngOnInit() {
    this.profileSubject = this.miningService.getProfileSubject()
    await this.init()
    this.isLoading = false
  }

  async init(){
    let chainId = await this.singletonService.getCurrentChainId()
    let chain = await this.singletonService.getSupportChain(chainId)
    this.isNftsLoading = true
    this.nfts = []
    log.debug("account ",this.singletonService.account)
    // fetch available profile
    this.pools = await this.backendService.getPools()
    this.pools = this.pools.filter(it => it.chain === chain.networkAbbr)
    for (const pool of this.pools) {
      if(pool.miningType != 'reward'){
        log.debug("trying to fetch pool %o",pool)
        let userInfo = await this.miningService.getPoolUserInfo(pool.address)
        let tokenIds = userInfo.stakedTokenIds

        let _nfts = await this.miningService.getNftInfos(pool.collectionAddress, tokenIds, pool.name)
        this.nfts = this.nfts.concat(_nfts)
      }
    }

    log.debug("all nft fetched ",this.nfts)

    // fetch current profile
    let _profile = await this.miningService.currentProfile()
    this.profile = {
      user: _profile.user,
      nftAddress: _profile.nftAddress.toLowerCase(),
      tokenId:_profile.tokenId
    }
    log.debug("current profile ",this.profile)

    this.selectedTokenId = this.profile.tokenId
    this.selectedCollectionAddress = this.profile.nftAddress
    let current = this.nfts.find(nft =>
      nft.collectionAddress.toLowerCase() == this.selectedCollectionAddress
        && nft.tokenId.eq(this.selectedTokenId)
    )
    log.debug("current ",current)
    this.selectedImage = current ? current.image:undefined
    this.isSetBefore = !(this.profile.nftAddress == ethers.constants.AddressZero)
    this.isNftsLoading = false
  }

  async selectNft(nft){
    this.selectedTokenId = nft.tokenId
    this.selectedCollectionAddress = nft.collectionAddress
    this.selectedImage = nft.image
    this.profile.nftAddress = nft.collectionAddress
    this.profile.tokenId = nft.tokenId

  }

  async save(){
    this.isSaving = true
    this.miningService.markAsProfile(this.selectedCollectionAddress, this.selectedTokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("set profile events ",receipt.events)
      let event = receipt.events.find((e)=> e.event === 'ProfileChanged')
      this.profileSubject.next(true)
      this.singletonService.fire('success','Profile set success', 'Success Profile set')
      this.isSaving = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Profile set failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isSaving = false
    })
  }

  async jumpToAddMore(){
    this.router.navigate(['mining'])
  }

}
