import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-item-details',
  templateUrl: './breadcrumb-item-details.component.html',
  styleUrls: ['./breadcrumb-item-details.component.scss']
})
export class BreadcrumbItemDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
