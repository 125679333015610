<section class="live-auctions-area load-more">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Intro -->
                <div class="intro text-center">
                    <span>Auctions</span>
                    <h3 class="mt-3 mb-0">Live Auctions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                </div>
            </div>
        </div>
        <div class="row items">
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_1.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2021-12-09"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Virtual Worlds</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                                <span class="ml-2">@Richard</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.5 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2021-11-19"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Collectibles</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                                <span class="ml-2">@JohnDeo</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>2.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_3.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2021-12-15"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Arts</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                                <span class="ml-2">@MKHblots</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>2.3 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_4.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2021-12-29"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Robotic Arts</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                                <span class="ml-2">@RioArham</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.8 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_5.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-01-24"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Magazine Fall</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_6.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-03-30"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Inspiration</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_7.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-01-24"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Design Illusions</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_7.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_8.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-03-30"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Design Illusions</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_8.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_9.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-01-24"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Design Illusions</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_10.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-03-30"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Infinity</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_11.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-01-24"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Sports</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card">
                    <div class="image-over">
                        <a href="item-details">
                            <img class="card-img-top" src="assets/img/auction_12.jpg" alt="">
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="countdown-times mb-3">
                                <div class='countdown d-flex justify-content-center' data-date="2022-03-30"></div>
                            </div>
                            <a href="item-details">
                                <h5 class="mb-0">Characteristics</h5>
                            </a>
                            <a class="seller d-flex align-items-center my-3" href="author">
                                <img class="avatar-sm rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                                <span class="ml-2">@ArtNox</span>
                            </a>
                            <div class="card-bottom d-flex justify-content-between">
                                <span>1.7 BNB</span>
                                <span>1 of 1</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <a id="load-btn" class="btn btn-bordered-white mt-5" href="#">Load More</a>
            </div>
        </div>
    </div>
</section>