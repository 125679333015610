import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment'

import { Logger } from './../services/logger.service';
const log = new Logger('TimeFromNowPipe'); // log.debug

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if(value){
      const myMomentObject:any = moment(value,'YYYY-MM-DDTHH:mm:ss.sssZ');
      // log.debug("myMomentObject => %o",myMomentObject)
      return moment(myMomentObject).fromNow(false); // 4 years ago;
    }else{
      return value
    }
  }

}
