<div class="main">
  <app-header-section></app-header-section>

  <div class="mt-95px">
    <div class="nft-search-view">
      <div class="wrapper-search-main">
        <app-filter-search [defaultSelectedFilters]="defaultSelectedFilters"></app-filter-search>
      </div>
      <div class="wrapper-search-result content-wrapper-tab py-4 px-4">

        <app-nft-card-list *ngIf="assets" [assets]="assets" [totalResults]="totalResults" [isFetchingAsset]="isFetchingAsset"></app-nft-card-list>

      </div>
    </div>

  </div>
  <app-modal-menu></app-modal-menu>
</div>
