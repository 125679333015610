<div class="main">
  <app-header-section></app-header-section>

  <section class="whitelist-markets">

    <h2 class="text-center title-h2">Crafting Stations</h2>
    <p class="text-center">You can forge your material tokens into high level token or NFT.</p>

    <app-crafting-station-section
      [stations]="stations"
      [isLoading]="isLoading"
    ></app-crafting-station-section>

  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
