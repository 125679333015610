
<div class="row items">
  <ng-container  *ngFor="let pool of pools">
  <div *ngIf="pool.active" class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
    <div class="card p-0 w-100">
      <div class="image-over">
        <a (click)="goToMinningPool(pool)">
          <img class="card-img-top" src="{{pool.coverImage | renderImageUrl : 'feature'}}" alt="">
        </a>
      </div>
      <div class="card-caption p-4">
        <div class="card-body py-1 mb-2">
          <a (click)="goToMinningPool(pool)" >
            <h5 class="tx-16 mb-2 text-truncate">{{pool.name}}</h5>
          </a>
          <div class="tags-wrapper">
            <div class="tags-inner">
              <span *ngFor="let tag of pool.tags" class="tag-span" [ngStyle]="{'background-color': tag.bgColor , 'color': tag.color}">
                {{tag.text}}
              </span>
              <!-- <span class="tag-span" style="background-color: blue;color: #fff;">ART</span>
              <span class="tag-span" style="background-color: yellow;color: #000;">MATH</span>
              <span class="tag-span" style="background-color: yellow;color: #000;">MATH</span>
              <span class="tag-span" style="background-color: yellow;color: #000;">MATH</span>
              <span class="tag-span" style="background-color: yellow;color: #000;">MATH</span>
              <span class="tag-span" style="background-color: yellow;color: #000;">MATH</span> -->
            </div>
          </div>
          <div>
            <hr class="my-3" />
            <div class=" tx-12">
              <span class="text-white-main font-weight-light tx-14">{{pool.description}}</span>
            </div>
          </div>
        </div>

        <div class="card-footer p-0 m-0 border-0">
          <hr class="my-3" />
          <small class="text-muted">Rewards</small>
          <div>
            <span *ngFor="let reward of pool.rewards">
              <img src="{{reward.image}}" class="reward-img" alt="{{reward.symbol}}"/>
            </span>
            <ng-container *ngIf="pool.address.toLowerCase() == ('0x8f16168752bd2cc08b8b52ad742af1132358b360')">
                <span>
                  <img src="assets/img/pools/treasure-wooden.png" class="reward-img reward-size-44px" alt="Wooden Treasure "/>
                </span>
              <span>
                  <img src="assets/img/pools/treasure-silver.png" class="reward-img reward-size-44px" alt="Silver Treasure "/>
                </span>
              <span>
                  <img src="assets/img/pools/treasure-gold.png" class="reward-img reward-size-44px" alt="Gold Treasure"/>
                </span>
              <span >
                <img src="assets/img/pools/question.png" class="reward-img reward-size-44px" alt="Question Reward"/>
              </span>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>
  </ng-container>
  <ng-container  *ngFor="let pool of soonPools">
    <div class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
      <div class="card p-0 w-100">
        <!-- <div class="image-over-wrapper"> -->
          <div class="image-over">
            <a  class="cursor-default">
              <img class="card-img-top" src="{{pool.coverImage }}" alt="{{pool.name}}" >
            </a>
          </div>
        <!-- </div> -->
        <div class="card-caption p-4">
          <div class="card-body  py-1 mb-2">
            <a   class="cursor-default">
              <h5 class="tx-16 mb-2 text-truncate">{{pool.name}}</h5>
            </a>

            <div *ngIf="pool.description">
              <hr class="my-3" />
              <div class=" tx-12">
                <span class="text-white-main font-weight-light tx-14">{{pool.description}}</span>
              </div>
            </div>

            <div *ngIf="!pool.description">
              <hr class="my-3" />

              <div class="text-center">
                <h5 class="text-muted mt-5 mb-0 txt-coming-soon border">
                  <i class="icon-tag text-muted"></i>
                  <div>Coming</div>
                  <span>Soon</span>
                </h5>
              </div>

            </div>
          </div>

          <div class="card-footer p-0 m-0 border-0" *ngIf="pool.description">
            <hr class="my-3" />
            <small class="text-muted">Rewards</small>
            <div>
              <span *ngFor="let reward of pool.rewards">
                <img src="{{reward.image}}" class="reward-img {{pool.class}} {{reward.class}}" alt="{{reward.symbol}}"/>
              </span>

            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>
</div>
