<div class="main">
  <app-header-section></app-header-section>

  <!-- <app-music-box [musicPath]="'assets/sounds/morroc-sound.mp4'" [volumn]="0.2"></app-music-box> -->

  <section class="author-area">
    <div class="container">

      <button [routerLink]="['/order-list']" class="btn btn-outline-light border text-white-main mt-3 mt-md-0">
        Go Back
      </button>

        <!-- <button (click)="approveToken()" class="ml-2 btn btn-warning text-white">
          approve PUNK
        </button>

        <button (click)="mockSellPunk()" class="ml-2 btn btn-warning text-white">
          [TEST LISTING] 1 PUNK to 10 KUSDT
        </button> -->

        <div class="row justify-content-center">
            <div class="col-12 col-md-12 px-0">
              <div class="intro mt-5 mt-lg-0 mb-1 mb-lg-1 mx-auto max-w-700px">

                  <div class="intro-content">
                      <span>Order Book</span>
                      <h3 class="mt-3 mb-0">
                        Create New Order
                      </h3>
                  </div>
                  <p class="mt-3 mb-0 tx-14 text-muted">
                    Place your token into Peer to Peer market as an order book to receive the token that you want. The token that you want will be transferred into your wallet automatically when the order has been fulfilled.
                  </p>
              </div>
              <div class="item-form card no-hover bg-transparent card-order-book border mt-5 max-w-700px mx-auto">
                <div class="row">
                  <div class="tx-20 text-white-main font-weight-bold w-100 mb-3">I want to sell</div>
                  <div class="col-12 col-md-4 px-0">
                    <div class="form-group mb-0">
                      <div class="tx-12 mb-2 text-muted">Token</div>
                      <button
                        class="btn w-100 d-flex jusitify-content-between border text-white-main text-left align-items-center py-0 input-data px-3 font-weight-normal"
                        type="button"
                        (click)="openListTokenModal(modalListToken)">
                        <div *ngIf="selectedSpendToken" class="w-100">
                          <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedSpendToken.address.toLowerCase()}}.png?raw=true"
                          onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="24" class="mr-1 rounded-circle" alt="">
                          <span>
                            {{selectedSpendToken.symbol | toUpperCase}}
                            <!-- {{selectedSpendToken.address | hideWalletAddress}} -->
                          </span>
                        </div>
                        <span>
                          <i class="icon-arrow-down"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-md-8 pr-0 pl-3">
                    <div class="form-group mb-0">
                      <div class=" tx-12 mb-2 text-muted">Amount (Your Balance: {{(selectedSpendToken ? (selectedSpendToken.balance | number: "1.0-6") : '---')  }})</div>
                      <input [(ngModel)]="spendTokenAmount" type="text" class="form-control py-0 input-data" placeholder="0" decimalNumber>
                    </div>
                  </div>
                </div>
                <div class="mt-4 mb-3 text-center">
                  <a class="btn btn-icon-swap border rounded-circle shadow-none cursor-default">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-down-up text-white-main" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                  </a>
                </div>
                <div class="row">
                  <div class="tx-20 text-white-main font-weight-bold mb-2 w-100">To get</div>
                  <div class="col-12 col-md-4 px-0">
                    <div class="form-group mb-0">
                      <div class="tx-12 mb-2 text-muted">Token</div>
                      <button class="btn w-100 d-flex jusitify-content-between border text-white-main text-left align-items-center py-0 input-data px-3 font-weight-normal" type="button" (click)="openListTokenModal(modalListReceiveToken)">
                        <div *ngIf="selectedReceiveToken" class="w-100">
                          <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{selectedReceiveToken.address.toLowerCase()}}.png?raw=true"
                          onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="24" class="mr-1 rounded-circle" alt="">
                          <span>
                            {{selectedReceiveToken.symbol | toUpperCase}}
                            <!-- {{selectedReceiveToken.address | hideWalletAddress}} -->
                          </span>
                        </div>
                        <span>
                          <i class="icon-arrow-down"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-md-8 pr-0 pl-3">
                    <div class="form-group mb-0">
                      <div class="tx-12 mb-2 text-muted">Amount (Your Balance: {{(selectedReceiveToken ? (selectedReceiveToken.balance | number: "1.0-6") : '---') }})</div>
                      <input [(ngModel)]="recieveTokenAmount" type="text" class="form-control py-0 input-data"  placeholder="0" decimalNumber>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="spendTokenAmount && recieveTokenAmount && (spendTokenAmount != '0.0' && recieveTokenAmount != '0.0')" >
                  <div class="col-md-12 pl-0 pr-0 mt-3">
                    <div class=" order-info-box">
                      <span class="text-white">Price : </span>
                      <strong class="text-white ml-2">

                        <span *ngIf="((recieveTokenAmount ) && (spendTokenAmount ))">
                          {{recieveTokenAmount/spendTokenAmount | number:'1.0-6'}} {{selectedReceiveToken.symbol | toUpperCase}}/{{selectedSpendToken.symbol | toUpperCase}}
                          </span>
                      </strong>
                      <br/>
                      <p *ngIf="((recieveTokenAmount ) && (spendTokenAmount ))" class="text-white tx-14 mt-1">
                        This mean you are going to sell 1 {{selectedSpendToken.symbol | toUpperCase}} for {{recieveTokenAmount/spendTokenAmount | number:'1.0-6'}} {{selectedReceiveToken.symbol | toUpperCase}}<br/>
                        In total, you sell {{spendTokenAmount}} {{selectedSpendToken.symbol | toUpperCase}} for {{recieveTokenAmount}} {{selectedReceiveToken.symbol | toUpperCase}}
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="selectedSpendToken && selectedReceiveToken" class="col-12 text-center mt-5 pt-3">
                  <button *ngIf="!isApproved" [disabled]="isApproveLoading" (click)="approveToken()" class="btn btn-primary px-4 text-white w-100 max-w-300">
                    Approve
                  </button>
                  <button  *ngIf="isApproved" [disabled]="isListingLoading" (click)="listing()" class="btn btn-primary px-4 text-white w-100 max-w-300">
                    Listing
                  </button>
                </div>

                <div *ngIf="isReloading" class="col-12 text-center py-5">
                  <div  class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <p class="text-center text-muted">reloading</p>
                </div>

                <div *ngIf="isApproveLoading" class="col-12 text-center py-5">
                  <div  class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <p class="text-center text-muted">Approving</p>
                </div>

                <div *ngIf="isListingLoading" class="col-12 text-center py-5">
                  <div  class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <p class="text-center text-muted">Listing</p>
                </div>

              </div>
            </div>
        </div>
    </div>
  </section>

  <ng-template #modalListToken let-modal>
    <div class="modal-header px-4 pt-4 pb-2 mb-3">
      <h5 class="modal-title mt-0">Select Token</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="filter-token mb-4 px-4">
      <div class="input-group input-group-merge">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search font-medium-2"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          </span>
        </div>
        <input
          type="text"
          [(ngModel)]="spendTokenSearchText"
          (ngModelChange)="customImportToken()"
          class="form-control form-control-lg tx-16 bg-transparent pl-2"
          placeholder="Find a token by name or address"
        />
      </div>
    </div>
    <div class="modal-body d-block pt-0 px-4 pb-3">

      <div class="token-list-wrapper border-top">
        <button *ngFor="let spendToken of spendTokens | filter:spendTokenSearchText" (click)="selectSpendToken(spendToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{spendToken.address.toLowerCase()}}.png?raw=true"
              onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="rounded-circle" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{spendToken.name}}
              </div>
              <div class="tx-13 text-muted">
                {{spendToken.symbol | toUpperCase}}
                <!-- ({{spendToken.address}}) -->
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{spendToken.balance | number: "1.0-6"}}</div>
        </button>

        <!-- start customToken -->
        <button *ngIf="customToken" (click)="selectSpendToken(customToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{customToken.address.toLowerCase()}}.png?raw=true"
              onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" class="rounded-circle" width="20" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{customToken.name}}
              </div>
              <div class="tx-13 text-muted">
                {{customToken.symbol | toUpperCase}}
                <!-- ({{customToken.address}}) -->
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{customToken.balance | number: "1.0-6"}}</div>
        </button>

        <div *ngIf="isCustomTokenLoading" class="col-12 text-center py-5">
          <div  class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="text-center text-muted">Custom Token Loading</p>
        </div>
        <!-- end customToken -->

      </div>

    </div>
  </ng-template>


  <ng-template #modalListReceiveToken let-modal>
    <div class="modal-header px-4 pt-4 pb-2 mb-3">
      <h5 class="modal-title mt-0">Select Token</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="filter-token mb-4 px-4">
      <div class="input-group input-group-merge">
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search font-medium-2"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          </span>
        </div>
        <input
          type="text"
          [(ngModel)]="acceptedTokenSearchText"
          class="form-control form-control-lg tx-16 bg-transparent pl-2"
          placeholder="Find a token by name or address"
        />
      </div>
    </div>
    <div class="modal-body d-block pt-0 px-4 pb-3">

      <div class="token-list-wrapper border-top">
        <button *ngFor="let recieveToken of acceptedTokenAddresses | filter:acceptedTokenSearchText" (click)="selectReceiveToken(recieveToken);modal.dismiss('Cross click');" type="button" class="token-list border-bottom bg-transparent w-100 d-flex justify-content-between align-items-center">
          <div class="token-name-wrapper">
            <div class="token-img mr-2">
              <img src="https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/{{currentChain.networkAbbr}}/{{recieveToken.address.toLowerCase()}}.png?raw=true"
              onerror="this.onerror=null;this.src='../../../assets/img/avatars/default.png';" width="20" class="rounded-circle" alt="">
            </div>
            <div class="token-name">
              <div class="tx-16 text-white-main">
                {{recieveToken.name}}
              </div>
              <div class="tx-13 text-muted">
                {{recieveToken.symbol | toUpperCase}}
                ({{recieveToken.address}})
              </div>
            </div>
          </div>
          <div class="token-amount text-white-main">{{recieveToken.balance | number: "1.0-6"}}</div>
        </button>
      </div>

    </div>
  </ng-template>

  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>
</div>

