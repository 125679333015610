import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SingletonService} from "./singleton.service";
import BigNumber from 'bignumber.js';
import {ethers, logger, utils} from "ethers";

// import { AddressBook } from "../configs/addresses/address-book";
const AddressBook = environment.addressBook

import POOL_ABI from '../../app/configs/abis/free-city/mining/FreeCityMiningPool.json';
import LOCK_POOL_ABI from '../../app/configs/abis/free-city/mining/FreeCityLockMiningPool.json';
import PROFILE_MANAGER_ABI from '../../app/configs/abis/free-city/mining/FreeCityProfileManager.json';
import HASH_POWER_STORAGE_ABI from '../../app/configs/abis/free-city/mining/HashPowerStorage.json';
import MOCK_TOKEN_ABI from '../../app/configs/abis/free-city/mining/MockToken.json';
import MOCK_NFT_ABI  from '../../app/configs/abis/free-city/mining/MockNFT721.json';
import {environment} from "../../environments/environment";
import {BackendService} from "./backend.service";
import { Contract, Provider } from 'bkc-ethcall';
import { Logger } from './logger.service';
import {BehaviorSubject, Observable, Subject} from "rxjs";
const log = new Logger('MiningService');


@Injectable({
  providedIn: 'root'
})
export class MiningService {

  addressBook:any;
  provider:any;
  publicProvider:any;
  multicallProvider:any;
  multicallPublicProvider:any;

  public profileSubject = new Subject<any>();
  public profile = {
    image:'',
    clan:'',
    effect:''
  }

  // for faucet only
  mockURI = 'https://ipfs.freecity.finance/ipfs/QmZ1sEaBdoZoZ1x4a1mMaSHrsqnCoiyacdkPids91LdU1R'
  mockURIs = [
    // CONVERTER & BLACKSMITH
    //"https://ipfs.testnet.freecity.finance/ipfs/QmP8K4HQ9HxZkRd452aKZLa7A99rujyhfMWJ4a31Ycuqig",
    //"https://ipfs.testnet.freecity.finance/ipfs/QmPZoyxs2zaEZqiuJBoEVCxDuaJ5DUJx6jnvC2XBSDm3V1",
    //"https://ipfs.testnet.freecity.finance/ipfs/QmP8K4HQ9HxZkRd452aKZLa7A99rujyhfMWJ4a31Ycuqig",
    //"https://ipfs.testnet.freecity.finance/ipfs/QmPZoyxs2zaEZqiuJBoEVCxDuaJ5DUJx6jnvC2XBSDm3V1",
    //XRB
    "https://bitkubipfs.io/ipfs/QmemxeM8bPomGGpzqL6kAS9iUPhJQMPWWdZwYTVCPgoS68",
    "https://bitkubipfs.io/ipfs/QmXdEqPtgTDtcfRkGANEnKWv71oqWCDGoCTgJ37Mr85HvX",
    "https://bitkubipfs.io/ipfs/QmP6KwQUL8hkyNTxd12GGduAfirS9PAmiaecRa563e57vL",
    "https://bitkubipfs.io/ipfs/QmSRNkAYT4Zha2Gh6rcXV54r7WVru1Vj1GkYFij8xz6LRN",
    // UNKNOWN
    /*"https://ipfs.bitkubnext.io/ipfs/QmRzSYkXgpr5B6RuxvBDL8Vtio3EooW4ZRLn96pcB5KkXU",
    "https://ipfs.bitkubnext.io/ipfs/QmQwbRH7HzngCCLCLRCEoqgQsEr9r1ccpeY782puMKt4rC",
    "https://ipfs.bitkubnext.io/ipfs/QmSK6fghUieR1U2sts8CB844roSJyjRJqP7ArZPWkQY7Dd",
    "https://ipfs.bitkubnext.io/ipfs/QmXRD3aJEXsLif7g8AmgUNBoySaeanwL961dyGLzEdas55",
    "https://ipfs.bitkubnext.io/ipfs/Qmcs3y7QHbenprEehFLeF8hKbkqUiJg7XdaDtCL8gw3jhy",
    "https://ipfs.bitkubnext.io/ipfs/QmRzSYkXgpr5B6RuxvBDL8Vtio3EooW4ZRLn96pcB5KkXU",
    "https://ipfs.bitkubnext.io/ipfs/QmQwbRH7HzngCCLCLRCEoqgQsEr9r1ccpeY782puMKt4rC",
    "https://ipfs.bitkubnext.io/ipfs/QmTFSv8DN9zjyRHo7n3ooLNJFH8geeSznohqCWmcU5qK9m",
    "https://ipfs.bitkubnext.io/ipfs/QmRwPDpiw6vE3P19L5NyEioDXo9wcNHBZ37qzSH6vcWZFY",
    "https://ipfs.bitkubnext.io/ipfs/QmVmJ6hjbdx3x3fU11Qw2FavxwAYQAkTFR3gVbNgYTX3n7",
    "https://ipfs.bitkubnext.io/ipfs/QmdtpZ6Co8r4R1EsgUQ3H1oyGQaWDYYx6gPqQjufEUP8XX",
    "https://ipfs.bitkubnext.io/ipfs/QmPaFqCpcM5u2LFDxy29bDfGuSjiE5JPx87raVes46WWGr",
    "https://ipfs.bitkubnext.io/ipfs/QmXQVE2xUC65HppGV5kb7WxQQ53HhNWG3Yq6ydXfYJ4SXY",
    "https://ipfs.bitkubnext.io/ipfs/QmQ4RSQTeApAgASqxpa7XADK5ti3XPFbFQTwQRNykFkd83",
    "https://ipfs.bitkubnext.io/ipfs/QmUkTwzySyNMMoK6eH3Ckf1vtgi7ezAeyj3QiU7iCTFekf",
    "https://ipfs.bitkubnext.io/ipfs/QmWEiLPd6PERAiJ3cSbAK6rc7xqLkjA4NaAW1BxGKhRdyH",
    "https://ipfs.bitkubnext.io/ipfs/QmfNDGS8UdrtoaRnmEgmQveAUuqxq6wMzKmowTpvt9QG67",
    "https://ipfs.bitkubnext.io/ipfs/QmXGtcLispPjgrzRoE7eGaEhhsX5hR7nVVYczeRYvDf1Gv",
    "https://ipfs.bitkubnext.io/ipfs/QmagupsdcW9Ufdn5krFhh6kVUAjMxCZLstQFmTgnc5vFAf",
    "https://ipfs.bitkubnext.io/ipfs/QmahkTSTRXXZWuyi4A8y3DfREQP2LpmufDB2zG7J45Trau",
    "https://ipfs.bitkubnext.io/ipfs/QmSK6fghUieR1U2sts8CB844roSJyjRJqP7ArZPWkQY7Dd",
    "https://ipfs.bitkubnext.io/ipfs/QmNPoFZgJkYffuffF2UAbcsAbpZZwGzPWhofS6kmeJXV6s",
    "https://ipfs.bitkubnext.io/ipfs/QmUbra6UScUQkyiwCAzoS7LEcYARg8BMD8NSc6eo2ARPmx",
    "https://ipfs.bitkubnext.io/ipfs/QmQxBr7MmFLef3gDWUKvUw7eRrhTDyKBAKjTcCMbzNUaoy",
    "https://ipfs.bitkubnext.io/ipfs/QmZ7FwdKdN1cMtkF2HSfBDy4hfQh9cJhuurMStwSdTDySM",
    "https://ipfs.bitkubnext.io/ipfs/QmRMmLdPvSw9sG5CijhBG1wmJXB4DNW23seHsvXtv7zvd4",
    "https://ipfs.bitkubnext.io/ipfs/QmTYPUcuGJFvUTr6WuPUTQZ2nczJr8Yxg3LcQCAeRzw6VA",
    "https://ipfs.bitkubnext.io/ipfs/QmP4uQk2byeQJqJkFHLKDadKFCgMKW8BC3FDspwX9WkB44",
    "https://ipfs.bitkubnext.io/ipfs/QmWxbw42tAjdACeLhrX12BgxPCHazxU7S93VfqzD91ch3t",
    "https://ipfs.bitkubnext.io/ipfs/QmPATK8xSRV9D9wH88juh243kEod86mEc648aCq4FEhVFy",
    "https://ipfs.bitkubnext.io/ipfs/QmWkqAqH2HLbKmktr9LKoSPeqcDgpfofnoa5TeLk5h58D1"
     */
]

  constructor(
    private httpClient: HttpClient,
    private singletonService:SingletonService,
    private backendService:BackendService
  ) {
    this.addressBook = AddressBook.find(ab => ab.chainId == environment.chainId)
    log.debug("this.addressBook %o",this.addressBook)

    this.initProvider()
    //this.singletonService.connectWallet()

  }

  async initProvider(){
    let _chainId = await this.singletonService.getCurrentChainId()
    log.debug("Current chain id is "+_chainId)
    let chain = await this.singletonService.getSupportChain(_chainId)
    log.debug("chain ",chain)

    this.addressBook = AddressBook.find(ab => ab.chainId == _chainId)

    await this.singletonService.initProvider()
    if(!this.provider){
      this.provider = this.singletonService.provider
      if(this.provider){
        this.multicallProvider = new Provider();
        this.multicallProvider.init(this.provider)
      }

    }
    if(!this.publicProvider){
      this.publicProvider = this.singletonService.publicProvider
      if(!this.publicProvider){
        if (chain.use == 'wss') {
          this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
        } else if (chain.use == 'rpc') {
          this.publicProvider = await new ethers.providers.JsonRpcProvider(chain.rpc)
        } else {
          this.publicProvider = await new ethers.providers.WebSocketProvider(chain.wss);
        }

      }
      this.multicallPublicProvider = new Provider();
      this.multicallPublicProvider.init(this.publicProvider)
    }
  }

  getObserveProfileSubject():Observable<any>{
    return this.profileSubject.asObservable()
  }
  getProfileSubject() {
    return this.profileSubject
  }

  async getProfile(userAddress = undefined){
    // address user, uint tokenId, address nftAddress
    try{
      let _profileStruct = await this.currentProfile(userAddress)
      log.debug("_profileStruct ", _profileStruct)
      let _meta
      let _profile:any = {}
      if(_profileStruct.nftAddress != ethers.constants.AddressZero ){
        _meta = await this.getNftInfos(_profileStruct.nftAddress, [_profileStruct.tokenId])
        log.debug("profile _meta ", _meta)
        _profile = _meta[0]
      }else{
        _profile.image = undefined
      }

      // TODO : set in contract
      _profile.clan = ''
      _profile.effect = ''

      if(userAddress && this.singletonService.account == userAddress){
        this.profile = _profile
      }

      return _profile
    }catch(e){
      return {clan:'', effect:'', image:undefined}
    }

  }

  async getPools(){
    //return this.addressBook.pools
    return await this.backendService.getPools()
  }

  async getPoolInfo(poolId){
    //return this.addressBook.pools.find(o => o.id === poolId)
    return await this.backendService.getPool(poolId)
  }

  async getOwnNFTIds(collectionAddress){
    let ids = []
    try{
      ids = await this.getOwnNFTIdsFromBlockchain(collectionAddress)
    }catch(e){
      log.error(e)
    }finally {
      return ids
    }
  }

  // note : fetch owner length from blockchain in case erc20 support enumerable
  async getOwnNFTIdsFromBlockchain(collectionAddress){
    log.debug("fetch owned nft ids from blockchain")
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.publicProvider)
    let _account = this.singletonService.account
    let amountOwned = await collection.balanceOf(_account)
    let tokenIds = []
    // TODO: Multicall/Promise.all
    for (let index = 0; index < amountOwned; index++) {
      let _tokenId = await collection.tokenOfOwnerByIndex(_account, index)
      tokenIds.push(_tokenId);
    }
    return tokenIds
  }

  async getOwnNFTIdsInPoolFromBlockchain(poolAddress){
    let _account = this.singletonService.account
  }

  async getBalance(address){
    let token = await new ethers.Contract(address, MOCK_TOKEN_ABI, this.publicProvider)
    return await token.balanceOf(this.singletonService.account)
  }

  async faucet(collectionAddress){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.provider.getSigner())
    return collection.faucet(this.mockURIs[(await this.generateRandom(0, this.mockURIs.length-1))])
  }

  async getPoolUserInfo(poolAddress){
    let account = this.singletonService.account
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.publicProvider)
    let info = await pool.getUserInfo(account)
    return info;
  }

  async getPoolRewardTokens(poolAddress, tokens){
    let account = this.singletonService.account
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.publicProvider)
    let rewards = []
    for (let index = 0; index < tokens.length; index++) {
      let _info = await pool.rewardTokens(tokens[index].address)
      let reward = {
        address:tokens[index].address,
        symbol:tokens[index].symbol,
        image:tokens[index].image,
        rewardPerBlock: Number(utils.formatUnits(_info.rewardPerBlock, tokens[index].decimal ? tokens[index].decimal:18)),
        startBlock: _info.startBlock.toNumber(),
        accTokenPerShare: Number(utils.formatUnits(_info.accTokenPerShare, tokens[index].decimal ? tokens[index].decimal:18)),
        enabled: _info.enabled,
      }
      rewards.push(reward)
    }
    return rewards;
  }

  async getPoolTotalHashPower(poolAddress){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.publicProvider)
    return await pool.totalHashPowerSupply()
  }

  async getHashPower(collectionAddress, tokenId){
    let hashPowerStorage = await new ethers.Contract(this.addressBook.HashPowerStorage, HASH_POWER_STORAGE_ABI, this.publicProvider)
    return await hashPowerStorage.getHashPower(collectionAddress, tokenId)
  }

  async nftImage(collectionAddress, tokenId){
    // TODO : read from ipfs
    return 'https://ipfs.freecity.finance/ipfs/QmRdifXScYG9Hvpp9tN9YaQSh3jyr3eUrLdKeN6FSQsNxP'
  }

  // TODO : Fetch from server
  async getNFTMeta(collectionAddress, tokenId){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.publicProvider)
    let uri = await collection.tokenURI(tokenId)
    log.debug("getNFTMeta uri => %o",uri)

    let meta:any = await this.httpClient.get(uri).toPromise();
    return meta
  }

  async checkApprove(poolAddress, collectionAddress){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.publicProvider)
    return await collection.isApprovedForAll(this.singletonService.account, poolAddress)
  }

  async approve(poolAddress, collectionAddress){
    let collection = await new ethers.Contract(collectionAddress, MOCK_NFT_ABI, this.provider.getSigner())
    return collection.setApprovalForAll(poolAddress, true)
  }

  async stake(poolAddress, tokenId){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
    return pool.stake([tokenId])
  }

  async stakeBatch(poolAddress, tokenIds){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
    return pool.stake(tokenIds)
  }

  async unstake(poolAddress, tokenId){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
    return pool.unstake([tokenId])
  }

  async unstakeBatch(poolAddress, tokenIds){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
    return pool.unstake(tokenIds)
  }

  async pendingReward(poolAddress){
    try{
      let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
      return await pool.pendingReward(this.singletonService.account)
    }catch(e){
      log.error("pending reward exception "+e)
      return [0]
    }

  }

  async withdraw(poolAddress){
    let pool = await new ethers.Contract(poolAddress, POOL_ABI, this.provider.getSigner())
    return pool.withdrawReward()
  }

  async isExistedProfile(collectionAddress, tokendId){
    let profileManager = await new ethers.Contract(this.addressBook.FreeCityProfileManager, PROFILE_MANAGER_ABI, this.publicProvider)
    return await profileManager.existed(this.singletonService.account, collectionAddress, tokendId)
  }

  async currentProfile(userAddress = undefined){
    if(userAddress == undefined){
      userAddress = this.singletonService.account
    }
    if(!this.addressBook){
      this.addressBook = AddressBook.find(ab => ab.chainId == environment.chainId)
      log.debug("this.addressBook %o",this.addressBook)
    }
    log.debug("read currect profile of ", userAddress)
    let profileManager = await new ethers.Contract(this.addressBook.FreeCityProfileManager, PROFILE_MANAGER_ABI, this.publicProvider)
    return await profileManager.getUserProfile(userAddress)
  }

  async getNftInfos(collectionAddress, tokenIds, collectionName = ''){
    log.debug("fetch collection getNftInfos--> "+collectionAddress)
    let calls = tokenIds.map(id=> {
      let contract = new Contract(collectionAddress, MOCK_NFT_ABI)
      return contract.tokenURI(id)
    })



    if(this.multicallPublicProvider){
      let results = await this.multicallPublicProvider.tryAll(calls)
      log.debug("Multicall result = ",results)

      let metaCalls = results.map( r => {
        return this.httpClient.get(r).toPromise()
      })

      let metas:any = await Promise.all(metaCalls)
      log.debug("metas fetched ",metas)

      metas = metas.map( (meta, index) => {
        meta.tokenId = tokenIds[index]
        meta.collectionAddress = collectionAddress
        meta.collectionName = collectionName
        let rarity =  meta.attributes.find(o=> o.trait_type == 'Rarity')
        meta.rarity = rarity ? rarity.value : undefined
        return meta
      })
      log.debug("meta get --- %o",metas)
      return metas
    }else{
      return [{}]
    }
  }

  async markAsProfile(collectionAddress, tokenId){
    let profileManager = await new ethers.Contract(this.addressBook.FreeCityProfileManager, PROFILE_MANAGER_ABI, this.provider.getSigner())
    return profileManager.markAsProfile(collectionAddress, tokenId, {value : utils.parseUnits('0.05')})
  }

  async getTotalCost(collectionAddress, ids){
    let cost = 0
    try{

      Promise.all(ids.map(async (tokenId): Promise<any> => {
        try{
          let res:any =  (await this.backendService.getAssetSoldHistory(collectionAddress, tokenId))
          log.debug("sold history "+tokenId+" = ",res)
          if(res){
            cost += res.usd
          }else{
            cost += 12
          }
        }catch (e) {
          cost += 12
        }
      }))
      /*
      for (const tokenId of ids) {
        try{
          let res:any =  (await this.backendService.getAssetSoldHistory(collectionAddress, tokenId))
          log.debug("sold history "+tokenId+" = ",res)
          if(res){
            cost += res.usd
          }else{
            cost += 12
          }
        }catch (e) {
          cost += 12
        }
      }*/
      log.debug("total cost from service "+cost)
    }catch (e){
      return 0
    }
    return cost
  }

  async generateRandom(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  /*
  *
  *   Admin part
  *
  * */

  async setHashPower(){
    let mockPoolAddress = this.addressBook.pools[0].address
    let collection = this.addressBook.pools[0].collection
    let tokenId = 3
    let hashPowerStorage = await new ethers.Contract(this.addressBook.HashPowerStorage, HASH_POWER_STORAGE_ABI, this.provider.getSigner())
    await hashPowerStorage.update(collection, tokenId, '1000')
  }

  async getPoolInfos(addresses){
    /*let calls = addresses.map(address=> {
      let contract = new Contract(address, POOL_ABI)
      return {
        totalHashPower: contract.totalHashPowerSupply(),
        rewardTokens: contract.getListRewardTokens()
      }
    })
*/
    let calls = addresses.map(address=> {
      let contract = new Contract(address, POOL_ABI)
      return contract.totalHashPowerSupply()
    })

    /*let contract0 = new Contract(addresses[0], POOL_ABI)
    let contract1 = new Contract(addresses[0], POOL_ABI)
    let call0 = contract0.totalHashPowerSupply()
    let call1 = contract1.totalHashPowerSupply()
    let result = await this.multicallProvider.all([call0,call1])*/

    let result = await this.multicallProvider.tryAll(calls)
    log.debug("Multicall result = ",result)
  }

  // ******
  // Lock Pool
  // ******

  async lockNFT(poolAddress, tokenId){
    let pool = await new ethers.Contract(poolAddress, LOCK_POOL_ABI, this.provider.getSigner())
    return pool.lock(tokenId)
  }

  async unlockNFT(poolAddress, lockedId){
    let pool = await new ethers.Contract(poolAddress, LOCK_POOL_ABI, this.provider.getSigner())
    return pool.unlock(lockedId)
  }

  async getLockedInfos(poolAddress, userAddress){
    let pool = await new ethers.Contract(poolAddress, LOCK_POOL_ABI, this.publicProvider)
    return pool.getLockedInfos(userAddress)
  }

  async getCurrentBlock(){
    return  this.publicProvider.getBlockNumber()
  }


}
