import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explore-projects',
  templateUrl: './explore-projects.component.html',
  styleUrls: ['./explore-projects.component.scss']
})
export class ExploreProjectsComponent implements OnInit {

  constructor() { }

  async ngOnInit(){
  }

}
