import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {

  lazyLoadSubject:any = new Subject<any>(); // trigger api

  constructor() { }
}
