<div class="main">
  <app-header-section></app-header-section>

  <section class="whitelist-markets">

    <h2 class="text-center title-h2">Whitelist Market</h2>
    <p class="text-center">Only whitelisted address can buy nft from these markets.</p>

    <app-whitelist-markets-section
      [markets]="markets"
      [isLoading]="isLoading"
    ></app-whitelist-markets-section>

  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>
</div>
