import { SelectedFiltersService } from './../../services/selected-filters.service';
import { CurrencyService } from './../../services/currency.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UnixTimeStampToDateStringFormatPipe } from './../../pipes/unix-time-stamp-to-date-string-format.pipe';
import { DiffDateTimeFromNowPipe } from './../../pipes/diff-date-time-from-now.pipe';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone, ViewEncapsulation } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ethers, utils} from "ethers";
import { mockdata } from '../../mocks/nft_details';
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {TOKEN_TYPE} from "../../constant/valuables";
import {BackendService} from "../../services/backend.service";
import { Logger } from '../../services/logger.service';
import {NftService} from "../../services/nft.service";
import { environment } from 'src/environments/environment';
const log = new Logger('NftShowComponent');

@Component({
  selector: 'app-nft-show',
  templateUrl: './nft-show.component.html',
  styleUrls: ['./nft-show.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NftShowComponent implements OnInit,OnDestroy {

  nftDetails:any

  dateString:any
  displayDescription: String
  diffDateTimeFromNow:any
  diffDateTimeFromNowInterval:any

  meta:any
  asset:any
  collection:any
  account
  chain
  forceUpdate
  nftAddress
  tokenId
  isLoadingMainAsset = true;
  isBuyLoading = false;
  orderId = 0
  acceptedTokenType = TOKEN_TYPE.KUB
  acceptedTokenAddr = '0x0000000000000000000000000000000000000000'
  acceptedTokenAmount = '250000000000000000'
  acceptedTokenSymbol = ''
  acceptedTokenInfo:any;
  price = 0

  isCancelListingLoading = false
  isOwner = false
  isCreator = false
  isSell = false
  isApproved = false
  isApproveLoading = false
  lastestOrder:any;
  assetFound = true

  latestTokenPrice
  eventTypeFiltered

  isFetchingAsset = true
  isFailedFetchingAsset = false
  relatedAssets = []
  totalResults = 0

  collectionsInfoTraits = []

  supportChains:any = []

  devFeePercent = 0
  feePercent = 0

  recipient
  isPostmanApproved = false
  isApprovePostmanLoading = false
  isSending = false

  customFieldsBySlug:any

  converters:any;
  isShowEnergy = false;
  isConverter = false;
  energyRemain = 0
  converter:any;
  exp = 0;

  constructor(
    private singletonService:SingletonService,
    private freeCityContractService:FreeCityContractService,
    private diffDateTimeFromNowPipe:DiffDateTimeFromNowPipe,
    private unixTimeStampToDateStringFormatPipe:UnixTimeStampToDateStringFormatPipe,
    private modalService:NgbModal,
    private backendService:BackendService,
    public router:Router,
    private route: ActivatedRoute,
    private currencyService:CurrencyService,
    private selectedFiltersService:SelectedFiltersService,
    private cRef: ChangeDetectorRef,
    private zone:NgZone,
    private nftService:NftService
  ) {
    this.nftAddress = this.route.snapshot.paramMap.get('nftAddress');
    this.tokenId = this.route.snapshot.paramMap.get('tokenId');
    this.route.queryParams.subscribe(params => {
      this.chain = params['chain'];
      this.forceUpdate = params['force'] || false;
      log.debug("this.chain ",this.chain)
    });

  }

  async ngOnInit() {
    this.supportChains = await this.singletonService.supportChains
    this.converters = await this.backendService.getConverters()
    this.converter = this.converters.find((c) => c.address.toLowerCase() == this.nftAddress)

    this.isConverter = this.converter != undefined
    await this.fetchData();
    if(this.isConverter){
      try{
        await this.fetchConverterInfo()
      }catch (e) {
        log.error(e)
      }
    }
  }

  async fetchConverterInfo(){
    this.energyRemain = await this.freeCityContractService.getConverterEnergyFromStorage(this.nftAddress, this.tokenId, this.converter.energyStorage)
    this.exp =  await this.freeCityContractService.getConverterEXPFromStorage(this.nftAddress, this.tokenId, this.converter.expStorage)
  }

  async forceRefresh(){
    await this.fetchData(true);
  }

  async fetchData(force = false){
    this.isLoadingMainAsset = true;

    const result = await this.fetchMainAsset(force)
    if(result.success){
      await this.fetchMainAssetInfos(result)
      await this.checkTokenApproval()
      await this.checkPostmanApproval()
      this.isLoadingMainAsset = false;
      this.assetFound = true

      // setTimeout(async () => {
        try{
          await this.fetchRelatedAssets()


        }catch(error){
          log.debug("error on fetchRelatedAssets : ",error)
          this.isFetchingAsset = false
          this.isFailedFetchingAsset = true
        }
      // }, 3000);
    }else{
      this.isLoadingMainAsset = false
      this.assetFound = false
    }
  }

  async fetchMainAsset(force){
    if(!this.chain){
      this.chain = (await this.singletonService.getCurrentChain()).networkAbbr
    }
    this.latestTokenPrice = await this.currencyService.getLatestTokenPrice()
    this.account = await this.singletonService.getCurrentConnectedAccount()
    let result = await this.backendService.getAssetInfo(
      this.nftAddress,
      this.tokenId,
      this.chain,
      force ? force : this.forceUpdate
    )
    log.debug("asset info from server ",result)

    return result
  }

  async fetchMainAssetInfos(result){
    this.asset = result.asset
    this.meta = result.meta
    this.nftDetails = mockdata
    this.collection = await this.backendService.getCollectionDetailsById(this.asset.collectionId)
    log.debug("this.collection:",this.collection)

    if(this.collection){
      this.customFieldsBySlug = await this.singletonService.getCustomFieldsBySlug(this.collection.slug)
    }

    await this.fetchFees()

    if(this.asset && this.asset.description){
      this.displayDescription = this.asset.description;
    }
    if(this.displayDescription === 'undefined' || !this.displayDescription){
      if(this.collection && this.collection.description){
        this.displayDescription = this.collection.description;
      }
    }
    /*if(this.account){
      this.isOwner = this.account.toLowerCase() == this.asset.ownerAddress ? this.asset.ownerAddress.toLowerCase(): undefined
      this.isCreator = this.account.toLowerCase() == this.asset.creatorAddress ? this.asset.creatorAddress.toLowerCase(): undefined
    }*/
    if(this.asset.lastestOrder && (this.asset.lastestOrder._id || this.asset.lastestOrder.id)){
      this.lastestOrder = this.asset.lastestOrder
      this.isSell = this.lastestOrder.orderStatus == 'created'
      this.acceptedTokenAmount = this.lastestOrder.acceptedTokenAmount
      this.acceptedTokenType = this.lastestOrder.acceptedTokenType == 'kub' ? TOKEN_TYPE.KUB:TOKEN_TYPE.ERC20 // KUB:ERC20
      this.orderId = this.lastestOrder.orderId
      this.acceptedTokenAddr = this.lastestOrder.acceptedTokenAddr
      this.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(this.lastestOrder.acceptedTokenAddr,this.lastestOrder.chain)
      if(this.acceptedTokenInfo && this.acceptedTokenInfo.symbol){
        this.acceptedTokenSymbol = this.acceptedTokenInfo.symbol
      }
      //this.isOwner = (this.account.toLowerCase() ==  this.lastestOrder.seller.toLowerCase()) ? this.lastestOrder.seller.toLowerCase():undefined
    }

    if(this.account){
      this.isOwner = this.account.toLowerCase() == this.asset.ownerAddress ? this.asset.ownerAddress.toLowerCase(): undefined
      this.isCreator = this.account.toLowerCase() == this.asset.creatorAddress ? this.asset.creatorAddress.toLowerCase(): undefined
    }


  }

  async checkTokenApproval(){
    // check for approval
    if(this.lastestOrder && this.lastestOrder.acceptedTokenAddr != ethers.constants.AddressZero){
      log.debug("checkTokenApprovalForMarket acceptedTokenAddr = "+this.lastestOrder.acceptedTokenAddr)

      this.isApproved = await this.freeCityContractService.checkTokenApprovalForMarket(
        this.lastestOrder.acceptedTokenAddr,
        this.lastestOrder.acceptedTokenAmount
      )
      log.debug("approved = "+this.isApproved)
    }else{
      this.isApproved = true
    }
  }

  async checkPostmanApproval(){
    try{
      this.isPostmanApproved = await this.nftService.checkPostmanApprove(this.asset.nftAddress)
    }catch(error){
      log.debug("isPostmanApproved : ",error)
    }
  }

  async approvePostman(asset){
    this.isApprovePostmanLoading = true
    this.nftService.approvePostman(this.asset.nftAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        await this.singletonService.fire('success','approve success', 'Success Approved')
        this.isPostmanApproved = true
      }else{
        await this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApprovePostmanLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','approve failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApprovePostmanLoading =false
    })
  }

  async transfer(asset){
    this.isSending = true
    this.nftService.transfer([this.asset.nftAddress], [this.asset.tokenId], this.recipient).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("transfer events ",receipt.events)
      let event = receipt.events.find((e)=> e.event === 'BatchTransferred')
      if(event.args.timestamp){
        await this.singletonService.fire('success','sending success', 'Success Approved')
        await this.forceRefresh()
        setTimeout(function(){
          window.location.reload()
        },500)
      }else{
        await this.singletonService.fire('success','sending failed', 'Failed Approved')
      }
      this.isSending = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','sending failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isSending = false
    })
  }

  ngOnDestroy(): void {
    log.debug("clearInterval diffDateTimeFromNow...")
  }

  openReviewCollectionModal(modal:any){
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'review-collection-modal',
      modalDialogClass: 'modal-lg',
      scrollable: true
    });
  }

  openBuyCheckoutModal(modal:any) {
    this.modalService.open(modal, {
      centered: true,
      windowClass: 'buy-checkout-modal',
      modalDialogClass: 'modal-lg',
      scrollable: true
    });
  }

  modalOpenShowNftImage(modal){
    this.modalService.open(modal, {
      // fullscreen: true,
      // centered: true,
      // size: 'md',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal modal-show-nft-image',
      modalDialogClass: 'modal-fullscreen'
    });
  }

  async approve(){
    if(!(await this.singletonService.modalSwitchNetwork(this.chain))){
      this.isApproveLoading = true
      this.freeCityContractService.approveToken(this.lastestOrder.acceptedTokenAddr).then(async (transaction)=>{
        let receipt = await transaction.wait()
        let approveEvent = receipt.events.find((e)=> e.event === 'Approval')
        await this.singletonService.fire('success','Approve Success', 'Success approve token to buy nft on market.')
        this.isApproved = true
      }).catch((error)=>{
        log.error(error)
        this.singletonService.fire('error','Approve failed', 'ERROR: '+error.data.message)
      }).finally(()=>{
        this.isApproveLoading = false
      })
    }
  }

  async buy(){
    if(!(await this.singletonService.modalSwitchNetwork(this.chain))){
      this.isBuyLoading = true
      this.freeCityContractService.buy(
        this.orderId,
        this.acceptedTokenType,
        this.acceptedTokenAddr,
        this.acceptedTokenAmount
      ).then(async (transaction)=>{
        let receipt = await transaction.wait()
        log.debug("receipt events",receipt.events)
        let soldEvent = receipt.events.find((e)=> e.event === 'Sold')
        log.debug("soldEvent ",soldEvent)
        log.debug("order id : ",soldEvent.args.id)
        log.debug("buyer : ",soldEvent.args.buyer)
        log.debug("meta : ",soldEvent.args.meta)
        log.debug("order: ",soldEvent.args.order)
        this.singletonService.fire('success','Buying Success', 'Success buy nft on market.')
        setTimeout(function(){
          window.location.reload()
        },2000)
      }).catch((error)=>{
        log.error(error)
        this.singletonService.fire('error','Buying failed', 'ERROR: '+error.data.message)
      }).finally(()=>{
        this.isBuyLoading = false
      })
    }
  }

  async cancelListing(){
    if(!(await this.singletonService.modalSwitchNetwork(this.chain))){
      this.isCancelListingLoading = true
      this.freeCityContractService.cancelListing(this.orderId  ).then(async (transaction)=>{
        let receipt = await transaction.wait()
        log.debug("receipt events",receipt.events)
        let cancelEvent = receipt.events.find((e)=> e.event === 'Cancel')
        log.debug("cancelEvent ",cancelEvent)
        log.debug("order id : ",cancelEvent.args.id)
        log.debug("seller : ",cancelEvent.args.seller)
        log.debug("meta : ",cancelEvent.args.meta)
        log.debug("order: ",cancelEvent.args.order)
        this.singletonService.fire('success','Cancel Listing', 'Success cancel listing on market.')
        setTimeout(function(){
          window.location.reload()
          this.isCancelListingLoading = false
        },2000)

      }).catch((error)=>{
        log.error(error)
        this.isCancelListingLoading = false
        this.singletonService.fire('error','Cancel Listing failed', 'ERROR: '+error.data.message)
      }).finally(()=>{
        //this.isCancelListingLoading = false
      })
    }
  }

  async openNewTabAddressScanUrl(address,networkAbbr){
    let supportChains = await this.singletonService.supportChains
    let chain = supportChains.find(it => it.networkAbbr == networkAbbr)
    let txScanUrl = chain.addressUrl + address
    window.open(txScanUrl, '_blank').focus();
  }

  async openNewTabNFTScanUrl(address,networkAbbr,tokenId){
    let supportChains = await this.singletonService.supportChains
    let chain = supportChains.find(it => it.networkAbbr == networkAbbr)
    let txScanUrl = ""
    if(chain.scanNFTUrl){
      txScanUrl = chain.scanNFTUrl
      txScanUrl = txScanUrl.replace('${nftAddress}',address)
      txScanUrl = txScanUrl.replace('${tokenId}',tokenId)
    }
    window.open(txScanUrl, '_blank').focus();
  }

  async selectEventTypeFiltered(eventType){
    this.eventTypeFiltered = await eventType


    const self = this
    setTimeout(() => {
      self.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    }, 200);
  }

  async fetchFees(){
    log.debug("fetchFees this.asset =>> %o",this.asset)

    this.devFeePercent = await this.freeCityContractService.getDevFeePercent()
    log.debug("fetchFees this.devFeePercent  =>> %o",this.devFeePercent )

    if(await this.freeCityContractService.isFreeCityNFTAddress(this.collection.nftAddress)){
      this.feePercent = this.collection.royaltyFee
    }else{
      this.feePercent = await this.freeCityContractService.getFeePercent(this.nftAddress)
      log.debug("fetchFees this.feePercent =>> %o",this.feePercent)
    }
  }

  async fetchRelatedAssets(){

    let result:any = await this.backendService.getAssetAdvanceSearch(
      undefined,
      undefined,
      undefined, // ownerAddress
      undefined,
      undefined,
      this.collection.id, // collections // fixed collections
      undefined,
      undefined,
      undefined, // creatorAddress
      undefined, // buyerAddress
      "updatedAt:desc",
      8, // limit
      1, // page
      2,//version
      false, // traitsParams
      true, // minted
    )
    log.debug("fetchRelatedAssets result => %o",result)

    let _relatedAssets:any = await Promise.all(result.results.map(async (asset) => {
      if(asset.lastestOrder && asset.lastestOrder.acceptedTokenAddr){
        asset.lastestOrder.acceptedTokenInfo =  await this.currencyService.getTokenInfoFromAddressAndChain(asset.lastestOrder.acceptedTokenAddr,asset.lastestOrder.chain)
        asset.lastestOrder.acceptedTokenSymbol = await asset.lastestOrder.acceptedTokenInfo.symbol
      }
      return asset
    }));

    this.relatedAssets = _relatedAssets.filter(it=> it._id != this.asset.id)


    this.totalResults = result.totalResults
    log.debug("this.relatedAssets => %o",this.relatedAssets)

    await this.getCollectionsInfoTraits()
    await this.calculateAttrStats()

    this.isFetchingAsset = false
  }


  async getCollectionsInfoTraits(){
    let result:any = await this.backendService.collectionsInfoTraits(this.collection.id)
    log.debug("getCollectionsInfoTraits result => %o",result)
    this.collectionsInfoTraits = result
  }

  async calculateAttrStats(){
    if(this.asset.attributes && this.asset.attributes.length){

      for (const attribute of this.asset.attributes) {
        log.debug("calculateAttrStats attribute => %o",attribute)
        log.debug("calculateAttrStats this.totalResults => %o",this.totalResults)
        log.debug("calculateAttrStats this.collectionsInfoTraits => %o",this.collectionsInfoTraits)

        let trait_type = attribute.trait_type
        let trait_value = attribute.value
        let infoByType = await (this.collectionsInfoTraits.find(collectionsInfoTrait => (collectionsInfoTrait.type ? collectionsInfoTrait.type : '') == trait_type))
        let samePropertyValue = await (infoByType.properties.find(property => (property.value ? property.value : '') == trait_value))
        log.debug("calculateAttrStats samePropertyValue => %o",samePropertyValue)
        log.debug("calculateAttrStats this.totalResults => %o",this.totalResults)

        if(this.totalResults != 0 && samePropertyValue) attribute.percent = await (samePropertyValue.total * 100.00 / this.totalResults)
        else attribute.percent = undefined
      }
      log.debug("calculateAttrStats this.asset => %o",this.asset)

    }
  }

  async jumpToSellPage(asset){
    if(!(await this.singletonService.modalSwitchNetwork(this.chain))){
      this.router.navigate(['/nft/sell/'+asset.nftAddress+'/'+asset.tokenId],{
        queryParams: {
          chain: asset.chain
        }
      })
    }
  }

  getImageSupportChainByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }
}
