import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {MiningService} from "../../services/mining.service";
import {ActivatedRoute, Router} from "@angular/router";
import { ethers,utils } from "ethers";
const { BigNumber }= require('ethers');

import { Logger } from '../../services/logger.service';
import {environment} from "../../../environments/environment";
const log = new Logger('PocMiningPoolComponent');


@Component({
  selector: 'app-poc-mining-pool',
  templateUrl: './poc-mining-pool.component.html',
  styleUrls: ['./poc-mining-pool.component.scss']
})
export class PocMiningPoolComponent implements OnInit {

  account ;
  poolInfo:any;
  collectionInfo:any;
  poolId;
  tokenIds:any;
  isLoading = true
  isFaucetLoading = false
  rewards:any
  balances:any
  nfts:any;
  userInfo:any;
  pendingRewards:any;
  pendings:any;
  pendingRewardIntervalId:any;
  isWithdrawLoading = false
  profile:any;
  walletCurrentChainId:any;
  isSupportChain = true;
  supportChainId:any;
  poolFound = false

  constructor(
    private singletonService:SingletonService,
    private miningService:MiningService,
    public router:Router,
    private route: ActivatedRoute,
  ) {
    this.poolId = this.route.snapshot.paramMap.get('poolId');
  }

  async ngOnInit(){
    this.walletCurrentChainId = await this.singletonService.getCurrentChainId()
    this.isSupportChain = (environment.chainId == this.walletCurrentChainId)
    this.supportChainId = environment.chainId
    this.account = await this.singletonService.account
    if(this.isSupportChain){
      this.poolInfo = await this.miningService.getPoolInfo(Number(this.poolId))
      if(this.poolInfo){
        this.poolFound = true
        this.rewards = this.poolInfo.rewards
        await this.refresh()
        this.isLoading = false

        this.pendingRewardIntervalId = setInterval(()=>{
          this.refreshPendingReward()
        }, 5000)
      }
    }
  }

  ngOnDestroy() {
    if (this.pendingRewardIntervalId) {
      clearInterval(this.pendingRewardIntervalId);
    }
  }

  async connectToSupportChain(){
    await this.singletonService.switchToSupportChain()
    window.location.reload()
  }

  async refresh(){

    this.userInfo = await this.miningService.getPoolUserInfo(this.poolInfo.address)
    await this.refreshBalance()
    await this.fetchTokenIds()
    await this.refreshNFTs()
    await this.refreshPendingReward()
    await this.refreshProfile()
  }

  async fetchTokenIds(){
    let ids = await this.miningService.getOwnNFTIds(this.poolInfo.collection)
    this.tokenIds = ids ? ids:[]
    this.tokenIds = this.tokenIds.concat(this.userInfo.stakedTokenIds)
    log.debug("fetch token ids result ",this.tokenIds)
  }
  async refreshBalance(){
    this.balances = []
    for(let r of this.rewards){
      let _balance = { reward:r, amount:'' }
      _balance.amount = utils.commify(utils.formatEther((await this.miningService.getBalance(r.address))))
      this.balances.push(_balance)
    }
  }
  async refreshNFTs(){
    this.nfts = []
    for(let tokenId of this.tokenIds){
      let info = await this.miningService.getNFTMeta(this.poolInfo.collection, tokenId)
      let _nft = {
        tokenIdNumber: (tokenId.toNumber()),
        tokenId: (tokenId),
        hashPower: await this.miningService.getHashPower(this.poolInfo.collection, tokenId),
        isApproved:await this.miningService.checkApprove(this.poolInfo.address, this.poolInfo.collection),
        isStaked:this.userInfo.stakedTokenIds.includes(tokenId),
        //image: await this.miningService.nftImage(this.poolInfo.collection, tokenId),
        image: info.image,
        profileExisted: await this.miningService.isExistedProfile(this.poolInfo.collection, tokenId),
        attributes: info.attributes,
        name:info.name,
        description:info.description,
        isStakeLoading:false,
        isSetProfileLoading:false,
        isUnstakeLoading:false
      }
      this.nfts.push(_nft)
    }
    this.nfts.sort(function(a, b){return a.tokenIdNumber-b.tokenIdNumber})
  }

  async refreshPendingReward(){
    this.pendingRewards = await this.miningService.pendingReward(this.poolInfo.address)
    log.debug("update pending rewards")
    this.pendings = []
    for(let r of this.rewards){
      let idx = this.pendingRewards[0].indexOf(r.address)
      let _pending = { reward:r, amount:utils.formatEther(this.pendingRewards[1][idx]) }
      this.pendings.push(_pending)
    }
  }

  async refreshProfile(){
    this.profile = await this.miningService.currentProfile()
  }

  async faucet(){
    this.isFaucetLoading = true
    this.miningService.faucet(this.poolInfo.collection).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("faucet events ",receipt.events)
      let transferEvent = receipt.events.find((e)=> e.event === 'Transfer')
      this.tokenIds.push(transferEvent.args.tokenId)
      await this.fetchTokenIds()
      await this.refresh()
      this.singletonService.fire('success','faucet success', 'Success faucet id '+transferEvent.args.tokenId)
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isFaucetLoading = false
    })
  }

  async stake(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isStakeLoading = true
    this.miningService.stake(this.poolInfo.address, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("stake events ",receipt.events)
      let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      await this.refresh()
      this.singletonService.fire('success','stake success', 'Success stake nft')
      nft.isStakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','stake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isStakeLoading = false
    })
  }

  async unstake(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isUnstakeLoading = true
    this.miningService.unstake(this.poolInfo.address, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("unstake events ",receipt.events)
      let unstakeEvent = receipt.events.find((e)=> e.event === 'UnstakeToken')
      await this.refresh()
      this.singletonService.fire('success','unstake success', 'Success stake nft')
      nft.isUnstakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','unstake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isUnstakeLoading = false
    })
  }

  async withdraw(){
    this.isWithdrawLoading = true
    this.miningService.withdraw(this.poolInfo.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("withdraw events ",receipt.events)
      let withdrawnEvent = receipt.events.find((e)=> e.event === 'RewardWithdrawn')
      await this.refresh()
      this.singletonService.fire('success','RewardWithdrawn success', 'Success RewardWithdrawn')
      this.isWithdrawLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','RewardWithdrawn failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isWithdrawLoading = false
    })
  }

  async approve(){
    this.miningService.approve(this.poolInfo.address, this.poolInfo.collection).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.refreshNFTs()
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isFaucetLoading = false
    })
  }

  async setProfile(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isSetProfileLoading = true
    this.miningService.markAsProfile(this.poolInfo.collection, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("set profile events ",receipt.events)
      let event = receipt.events.find((e)=> e.event === 'ProfileChanged')
      await this.refresh()
      this.singletonService.fire('success','Profile set success', 'Success Profile set')
      nft.isSetProfileLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Profile set failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isSetProfileLoading = false
    })
  }

}
