<div class="filter-data-tab d-flex align-items-center mb-2">
  <div *ngIf="enabledSearch" class="flex-grow-1">
    <div class="input-group input-group-custom-wrapper">
      <div class="input-group-prepend">
        <span class="input-group-text bg-transparent border-0">
          <i class="icon-magnifier"></i>
        </span>
      </div>
      <input type="text" class="form-control border-left-0 pl-0 bg-transparent" autocomplete="off" name="search" [(ngModel)]="searchText" placeholder="Search">
    </div>
  </div>
  <!-- <div class="">
    <div class="dropdown">
      <button disabled type="button" data-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle border py-3 px-3"> Single items</button>
      <div class="dropdown-menu">
        <a class="dropdown-item">All items</a>
        <a class="dropdown-item">Bundles</a>
      </div>
    </div>
  </div> -->
  <div *ngIf="enabledSort" class="filter-dropdown-data-tab">
    <div class="dropdown">
      <button type="button" data-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle border py-3 px-3">
        {{selectedSortingDisplay}}
      </button>
      <div class="dropdown-menu">
        <!-- <a class="dropdown-item">Recently Listed</a> -->
        <!-- <a (click)="addSorting('')" class="dropdown-item">Recently Created</a> -->
        <!-- <a class="dropdown-item">Recently Sold</a> -->
        <!-- <a class="dropdown-item">Recently Received</a> -->
        <!-- <a class="dropdown-item">Ending Soon</a> -->
        <a (click)="addSorting('updatedAt:desc','Updated: Recently')" class="dropdown-item">Updated: Recently</a>
        <a (click)="addSorting('createdAt:desc','Created: Recently')" class="dropdown-item">Created: Recently</a>
        <!-- <a (click)="addSorting('created:asc','Created: Oldest')" class="dropdown-item">Created: Oldest</a> -->
        <a (click)="addSorting('price:asc','Price: Low to High')" class="dropdown-item">Price: Low to High</a>
        <a (click)="addSorting('price:desc','Price: High to Low')" class="dropdown-item">Price: High to Low</a>
        <!-- <a class="dropdown-item">Most Viewed</a> -->
      </div>
    </div>
  </div>
  <div *ngIf="enabledGrid" class="filter-grid-display">
    <div class="btn-group" role="group">
      <button type="button" class="btn py-0 px-3 border" [ngClass]="displayMultiGrid?'':'active'" (click)="toggleDisplayMultiGrid('non-multi')">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-grid" viewBox="0 0 16 16">
          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
        </svg>
      </button>
      <button type="button" class="btn py-0 px-3 border" [ngClass]="displayMultiGrid?'active':''" (click)="toggleDisplayMultiGrid('multi')">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-grid-3x3-gap" viewBox="0 0 16 16">
          <path d="M4 2v2H2V2h2zm1 12v-2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 10v-2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 2v2H7V2h2zm5 0v2h-2V2h2zM4 7v2H2V7h2zm5 0v2H7V7h2zm5 0h-2v2h2V7zM4 12v2H2v-2h2zm5 0v2H7v-2h2zm5 0v2h-2v-2h2zM12 1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zm-1 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2z"/>
        </svg>
      </button>
    </div>
  </div>
</div>
<div *ngIf="isShowTotalResults" class="mt-4 mb-0 text-muted">{{totalResults}} items</div>

<div *ngIf="isFetchingAsset" class="col-12 mb-5 pb-5 text-center">
  <div  class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <p class="text-center text-muted">Fetching assets</p>
</div>

<app-selected-filter-card-section *ngIf="isShowSelectedFilterCardSection"></app-selected-filter-card-section>

<div *ngIf="!isFetchingAsset" class="row nft-items-wrapper items my-3">
  <!-- <div *ngFor="let asset of assets | filter:searchText" class="col-12 col-sm-6 col-md-3 col-lg-2 col-item-nft item mb-3"> -->
  <div *ngFor="let asset of assets | filter:searchText" class="col-item-nft item mb-3">
    <a *ngIf="asset.tokenId >= 0" (click)="jumpToNFTShow(asset.nftAddress,asset.tokenId,asset.chain)" class="box-nft card no-hover p-0">
      <!-- <div class="image-over border-bottom"> -->
      <div class="image-over">
        <div class="image-over-wrapper">
          <!-- 000 {{asset.media}} -->
          <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" class="card-img-top" [src]="asset.media | renderImageUrl : 'nft'" alt="">
          <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
            class="card-img-top video-player"
            [src]="asset.animationUrl"
            [poster]="asset.media | renderImageUrl : 'logo' : true : true"
            loop>
          </video>
          <div *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true" class="btn-nft-action-vdo">
            <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
              <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" style="margin-left: 1px;">
              <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" style="display: none;">
            </button>
          </div>
        </div>

        <div class="nft-user-profile-image-wrapper">
          <app-use-profile-image [ownerUser]="asset.ownerUser"></app-use-profile-image>
        </div>
        <div *ngIf="asset.collection.featureAttr" class="nft-tags-wrapper">
          <div class="nft-tag text-truncate btn btn-primary">
            {{asset.collection.featureAttr}}:
            {{asset.attributes | featureAttrBadge: asset.collection.featureAttr}}
          </div>
        </div>
      </div>
      <div class="card-caption position-relative">
          <div class="card-body py-3">
            <span *ngIf="asset.lastestOrder && asset.lastestOrder.orderStatus == 'closed'" class="text-sold tx-1 cursor-default badge badge-danger">
              SOLD
            </span>
            <div class="d-flex justify-content-start align-items-start flex-column row-gap12px">
              <div class="box-nft-left">
                <div class="d-flex align-items-center tx-12 text-muted">
                  <span class="collection-name font-weight-bold ">
                    {{collection ? (collection.collectionName ? collection.collectionName : collection.name) : asset.collection.name}}
                  </span>
                  <!-- <img [src]="getImageSupportChainByAbbr(collection ? (collection.chain ? collection.chain : collection.blockchain) : asset.collection.chain)"
                    [title]="collection ? (collection.chain ? collection.chain : collection.blockchain) : asset.collection.chain" width="13" class="ml-1"> -->
                  <span class="collections-verify ml-1">
                    <i [ngClass]="(collection ? collection.verification : asset.collection.verification) ? ((collection ? collection.verification : asset.collection.verification) | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                    [title]="(collection ? collection.verification : asset.collection.verification) ? ((collection ? collection.verification : asset.collection.verification) | verificationTypeToText) : (undefined | verificationTypeToText)"
                    class=""></i>
                  </span>
                </div>
                <div class="nft-name mt-1 mb-0 tx-12 text-dark d-flex align-items-center">
                  <span class="tx-12 text-truncate">
                    {{asset.name}}
                  </span>
                  <span class="text-muted ml-1">
                    #{{asset.tokenId}}
                  </span>
                  <img [src]="getImageSupportChainByAbbr(asset.chain)"
                    [title]="asset.chain" width="16" class="ml-1">
                </div>
              </div>
              <div class="box-nft-right">
                <div class="text-left" *ngIf="asset.lastestOrder">
                  <div class="txt-lastest-price text-muted tx-12" title="Latest Price">
                    Latest Price
                  </div>
                  <div class="nft-price-wrapper m-0 mt-1 d-flex align-items-center">
                    <ng-container *ngIf="asset.lastestOrder && asset.lastestOrder.acceptedTokenImage">
                      <img [src]="asset.lastestOrder.acceptedTokenImage" width="16" class="rounded-circle mr-1 p-0">
                    </ng-container>
                    <div class="tx-15 text-dark font-weight-bold text-truncate" *ngIf="asset.lastestOrder.acceptedTokenAmountNumber">
                      {{asset.lastestOrder.acceptedTokenAmountNumber | gameNumberFormat: 2 : false}}
                      {{asset.lastestOrder.acceptedTokenSymbol ? asset.lastestOrder.acceptedTokenSymbol.toUpperCase() : ''}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="txt-convert-curency">
              <div *ngIf="asset.lastestOrder">
                <p *ngIf="asset.lastestOrder.acceptedTokenInfo" class="txt-convert-curency text-muted tx-13 d-block text-truncate mb-0 mt-2 text-left">
                  (
                    {{ asset.lastestOrder.acceptedTokenAmountNumber * asset.lastestOrder.acceptedTokenInfo.usd | gameNumberFormat: 2 : false}} USD,
                    {{ asset.lastestOrder.acceptedTokenAmountNumber  * asset.lastestOrder.acceptedTokenInfo.thb | gameNumberFormat: 2 : false}} THB
                  )
                </p>
              </div>
            </div>
            <!-- <div class="card-bottom mt-2 d-flex justify-content-between align-items-center border-top pt-2"> -->
            <div class="card-bottom">

                <span class="w-100">
                <a *ngIf="asset.lastestOrder && asset.lastestOrder.orderStatus == 'created'" (click)="buy(asset);$event.stopPropagation();" class="d-block">
                  <span class="btn-buy-now-nft tx-15 text-white btn btn-primary rounded-0 d-block">Buy now</span>
                </a>
                <!-- <span *ngIf="asset.lastestOrder && asset.lastestOrder.orderStatus == 'closed'" (click)="$event.stopPropagation();"  class="btn-buy-now-nft tx-15 text-danger cursor-default">
                  SOLD
                </span> -->
                <a *ngIf="asset.lastestOrder && asset.lastestOrder.orderStatus == 'closed'" class="d-block">
                  <span class="btn-buy-now-nft tx-15 text-white btn btn-view-detail rounded-0 d-block">
                    View detail
                  </span>
                </a>
                <span *ngIf="(asset.lastestOrder && asset.lastestOrder.orderStatus == 'cancel') || asset.lastestOrder == undefined" class="btn-buy-now-nft btn btn-cancle-nft tx-15 text-primary cursor-default rounded-0 d-block">
                  <i class="txt-cancel-nft text-truncate pr-25">This item is not for sell yet</i>
                </span>
              </span>

              <!-- <a class="btn-like-nft" (click)="testAlert();$event.stopPropagation();"> -->
              <!-- <a class="btn-like-nft" (click)="$event.stopPropagation();">
                  <i class="icon-heart"></i>
              </a> -->
            </div>
        </div>
      </div>
    </a>
    <a *ngIf="asset.tokenId !=0 && !asset.tokenId" [routerLink]="['/nft/draft/'+asset._id]" class="box-nft card no-hover p-0">
      <!-- <div class="image-over border-bottom"> -->
      <div class="image-over">
        <div class="image-over-wrapper">
           <!-- 111 {{asset.media}} -->
          <img *ngIf="asset.media && !(asset.isAnimation || asset.isAnimation == true)" class="card-img-top" [src]="asset.media | renderImageUrl : 'nft'" alt="">
          <video *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true"
            class="card-img-top video-player"
            [poster]="asset.media | renderImageUrl : 'logo' : true : true"
            [src]="asset.animationUrl"
            loop>
          </video>
          <div *ngIf="asset.media && asset.isAnimation && asset.isAnimation == true" class="btn-nft-action-vdo">
            <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
              <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" style="margin-left: 1px;">
              <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" style="display: none;">
            </button>
          </div>

        </div>
        <div *ngIf="asset.collection.featureAttr" class="nft-tags-wrapper">
          <div class="nft-tag">
            {{asset.collection.featureAttr}}:
            {{asset.attributes | featureAttrBadge: asset.collection.featureAttr}}
          </div>
        </div>
      </div>
      <div class="card-caption position-relative">
          <div class="card-body py-3">
            <div class="d-flex justify-content-start align-items-start flex-column row-gap12px">
              <div class="box-nft-left">

                <div class="d-flex align-items-center tx-12 text-muted">
                  <span class="collection-name font-weight-bold ">{{collection ? collection.collectionName : (asset.collection ? asset.collection.name : '')}}</span>
                  <!-- <img [src]="getImageSupportChainByAbbr(collection ? (collection.chain ? collection.chain : collection.blockchain) : asset.collection.chain)"
                  [title]="collection ? (collection.chain ? collection.chain : collection.blockchain) : asset.collection.chain" width="13" class="ml-1"> -->
                  <span class="collections-verify ml-1">
                    <i [ngClass]="(collection ? collection.verification : asset.collection.verification) ? ((collection ? collection.verification : asset.collection.verification) | verificationTypeToText: false) : (undefined | verificationTypeToText: false)"
                    [title]="(collection ? collection.verification : asset.collection.verification) ? ((collection ? collection.verification : asset.collection.verification) | verificationTypeToText) : (undefined | verificationTypeToText)"
                    class=""></i>
                  </span>
                </div>

                <div class="nft-name mt-1 mb-0 tx-12 text-dark d-flex align-items-center">
                  <span class="tx-12 text-truncate">
                    {{asset.name}}
                  </span>
                  <span class="text-warning ml-1">
                    (draft)
                  </span>
                  <img [src]="getImageSupportChainByAbbr(asset.chain)"
                    [title]="asset.chain" width="16" class="ml-1">
                </div>
              </div>
              <div class="box-nft-right">
              </div>
            </div>
            <p class="txt-convert-curency text-muted tx-13 d-block text-truncate mb-0 mt-1 text-right">
            </p>
            <!-- <div class="card-bottom mt-4 d-flex justify-content-between pt-2"> -->
            <div class="card-bottom">
              <span></span>
              <span></span>
            </div>
        </div>
      </div>
    </a>

  </div>
  <div class="col-12 text-center py-5" *ngIf="assets && !assets.length">
    <div class="text-center mb-2">
      <i class="icon-drawer tx-40"></i>
    </div>
    -- No nfts result --
  </div>
</div>
<div *ngIf="isLazyLoading">
  <div class="col-12 text-center mt-5 py-5">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">lazy loading...</span>
    </div>
  </div>
</div>
