import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './auth/helpers/auth.interceptor';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { ToUpperCasePipe } from './pipes/to-upper-case.pipe';
import { ToLowerCasePipe } from './pipes/to-lower-case.pipe';
import { ToCappitalPipe } from './pipes/to-cappital.pipe';
import { EventTypeToTextPipe } from './pipes/event-type-to-text.pipe';
import { HideWalletAddressPipe } from './pipes/hide-wallet-address.pipe';
import { RenderImageUrlPipe } from './pipes/render-image-url.pipe';
import { ErrorInterceptor } from './auth/helpers/error.interceptor';
import { JwtInterceptor } from './auth/helpers/jwt.interceptor';
import { SharedComponentsModule } from './components/shared-components.module';
import { UnixTimeStampToDateStringFormatPipe } from './pipes/unix-time-stamp-to-date-string-format.pipe';
import { DiffDateTimeFromNowPipe } from './pipes/diff-date-time-from-now.pipe';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PaginationService } from 'ngx-pagination';
import { ErrorPageComponent } from './themes/error-page/error-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { ErrorPageNotAuthorizedComponent } from './themes/error-page-not-authorized/error-page-not-authorized.component';
import {PolicyComponent} from "./themes/policy/policy.component";
import { CountUpModule } from 'ngx-countup';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { EndWithFormatPipe } from './pipes/end-with-format.pipe';


import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaLoaderService, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";

const recaptchaSiteKey = `${environment.recaptchaSiteKey}`

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ErrorPageNotAuthorizedComponent,
    PolicyComponent,
  ],
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot('G-PQC37MLF80'),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CountUpModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
  ],
  providers: [
    PaginationService,
    RecaptchaLoaderService,
    {provide: Window, useValue: window},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcNSyckAAAAAFDZEdvT1Nbd7Y11n-TXvi5xqimX" },

    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: recaptchaSiteKey
      } as RecaptchaSettings
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "en", // use French language
    },

    // pipes
    DecimalPipe,
    DiffDateTimeFromNowPipe,
    UnixTimeStampToDateStringFormatPipe,
    RenderImageUrlPipe,
    HideWalletAddressPipe,
    EventTypeToTextPipe,
    ToCappitalPipe,
    ToLowerCasePipe,
    ToUpperCasePipe,
    TimeFromNowPipe,
    EndWithFormatPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

declare global {
  interface Window {
    web3:any;
    ethereum:any;
  }
}
