import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-seller-two-section',
  templateUrl: './top-seller-two-section.component.html',
  styleUrls: ['./top-seller-two-section.component.scss']
})
export class TopSellerTwoSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
