<section class="breadcrumb-area overlay-dark d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Breamcrumb Content -->
                <div class="breadcrumb-content text-center">
                    <h2 class="m-0">Explore</h2>
                    <ol class="breadcrumb d-flex justify-content-center">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item"><a href="#">Explore</a></li>
                        <li class="breadcrumb-item active">Explore Style 2</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</section>