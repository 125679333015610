import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {MiningService} from "../../services/mining.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrencyService} from "../../services/currency.service";
import {environment} from "../../../environments/environment";
import {Logger} from "../../services/logger.service";
import {utils} from "ethers";
import {RedeemModalComponent} from "./redeem-modal/redeem-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BackendService} from "../../services/backend.service";
const log = new Logger('MiningPoolComponent');
@Component({
  selector: 'app-mining-reward',
  templateUrl: './mining-reward.component.html',
  styleUrls: ['./mining-reward.component.scss']
})
export class MiningRewardComponent implements OnInit {


  BLOCK_TIME = 5 // second, TODO: change when not bkc

  account ;
  poolInfo:any;
  collectionInfo:any;
  poolId:any;
  tokenIds:any;
  lockedInfos:any;
  lockedTokenIds:any;

  totalReward = 0
  totalRewardUSD = 0
  currentReward =0

  isLoading = true
  isFaucetLoading = false
  isWithdrawLoading = false
  isSupportChain = true;
  isNFTReady = false
  isApproveAll = false;
  isApproveAllLoading = false;
  rewards:any
  balances:any
  nfts:any;
  userInfo:any;
  pendingRewards:any;
  pendings:any;
  pendingRewardIntervalId:any;
  aprCalculatorIntervalId:any;
  profile:any;
  walletCurrentChainId:any;
  walletCurrentChain:any;
  supportChainId:any;
  poolFound = true
  apy = 651
  apr = 1
  totalHashPower = 0
  userHashPower = 0
  userHashPowerPercent = 0
  demoMode = false
  rewardTokens = []
  rewardTokensPerBlockValue = 0
  totalCost = 0
  pools = []
  pool:any;
  balance = 0;
  isFirstFetch = true

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private miningService:MiningService,
    private modalService:NgbModal,
    public router:Router,
    private route: ActivatedRoute,
    public currencyService:CurrencyService
  ) {
    this.poolId = this.route.snapshot.paramMap.get('poolId');
    this.demoMode = environment.features.DEMO_MODE
  }

  async ngOnInit(){
    this.rewards = []
    this.pool = await this.miningService.getPoolInfo(this.poolId)
    this.walletCurrentChain = await this.singletonService.getCurrentChain()
    this.walletCurrentChainId = this.walletCurrentChain.networkId
    this.isSupportChain = this.walletCurrentChain.networkAbbr === this.pool.chain;
    this.supportChainId = environment.chainId
    this.account = await this.singletonService.getCurrentConnectedAccount()
    if(this.isSupportChain){
      this.poolInfo = this.pool
      log.debug("poolInfo ",this.poolInfo)
      if(this.poolInfo){
        this.poolFound = true

        await this.fetchOnce()
        await this.refresh()
        await this.calculateAPR()
        this.isLoading = false

        if(this.account){
          this.pendingRewardIntervalId = setInterval(()=>{
            this.recalculateReward()
          }, 5000)
        }

      }else{
        this.poolFound = false
      }
    }
  }

  async fetchOnce(){
    this.balance = Number(utils.formatEther((await this.miningService.getBalance(this.pool.rewards[0].address))))
    /*if(this.account){
      this.userInfo = await this.miningService.getPoolUserInfo(this.poolInfo.address)
    }
    this.rewardTokens = await this.miningService.getPoolRewardTokens(this.poolInfo.address, this.poolInfo.rewards)
    log.debug("reward tokens fetched ",this.rewardTokens)*/
  }

  async recalculateReward(){
    let currentBlock = await this.miningService.getCurrentBlock()
    this.currentReward = 0
    for (const nft of this.nfts) {
      let blockPass = nft.isStaked ? this.calculateBlockAmount(nft.startBlock, nft.endBlock, currentBlock):0
      let _currentReward =(blockPass * nft.rewardPerBlock)
      nft.currentReward = _currentReward
      this.currentReward += _currentReward
      // console.log(_currentReward)
      this.isFirstFetch = false
    }
    this.isFirstFetch = false
  }

  async refresh(){
    if(this.account){
      await this.fetchTokenIds()
      await this.refreshNFTs()
    }else {
      this.isNFTReady = true
    }
  }


  async fetchTokenIds(){
    let ids = await this.miningService.getOwnNFTIds(this.poolInfo.collectionAddress)
    this.tokenIds = ids ? ids:[]
    this.lockedInfos = await this.miningService.getLockedInfos(this.poolInfo.address, this.account)
    this.lockedInfos = this.lockedInfos.filter(o=>  !o.isUnlocked)

    let _ids = this.lockedInfos.map(o => o.tokenId)
    this.lockedTokenIds = _ids
    this.tokenIds = this.tokenIds.concat(_ids)
    log.debug("fetch token ids result ",this.tokenIds)
  }

  async refreshNFTs(){

    this.isApproveAll = true;
    this.isNFTReady = false;
    this.nfts = []
    let currentBlock = await this.miningService.getCurrentBlock()
    for(let tokenId of this.tokenIds){
      let info = await this.miningService.getNFTMeta(this.poolInfo.collectionAddress, tokenId)
      let hashPower = await this.miningService.getHashPower(this.poolInfo.collectionAddress, tokenId)
      let _rarity = info.attributes.find(o=>o.trait_type == 'Rarity' || o.trait_type == 'rarity')
      let isStaked = this.lockedTokenIds.includes(tokenId)
      let rewards = []//isStaked ? (await this.mapRewardPerBlock(hashPower)):[]
      let lockedInfo = isStaked ? this.lockedInfos.find((o)=> o.tokenId === tokenId):undefined
      let totalBlock = isStaked ? (lockedInfo.endBlock - lockedInfo.startBlock):1
      let rewardInfo:any = await this.backendService.getRedeemRewardsInfo(this.pool.address, this.pool.collectionAddress, tokenId)
      //console.log("%o",rewardInfo)
      let rewardTotal = rewardInfo && rewardInfo.miningLock ? rewardInfo.miningLock.rewardTotal:0
      let rewardPerBlock = rewardTotal / totalBlock
      let blockPass = isStaked ? this.calculateBlockAmount(lockedInfo.startBlock, lockedInfo.endBlock, currentBlock):0
      let blockLeft = isStaked ? this.calculateBlockLeft(lockedInfo.startBlock, lockedInfo.endBlock, currentBlock):0
      let secondLeft = blockLeft * this.BLOCK_TIME
      let endDate = new Date();
      endDate.setSeconds(endDate.getSeconds() + secondLeft);
      let _nft = {
        tokenIdNumber: (tokenId.toNumber()),
        tokenId: (tokenId),
        hashPower: hashPower,
        isApproved:await this.miningService.checkApprove(this.poolInfo.address, this.poolInfo.collectionAddress),
        isStaked: isStaked,
        //image: await this.miningService.nftImage(this.poolInfo.collection, tokenId),
        image: info.image,
        profileExisted: await this.miningService.isExistedProfile(this.poolInfo.collectionAddress, tokenId),
        attributes: info.attributes,
        rarity: _rarity ? _rarity.value:undefined,
        name:info.name,
        description:info.description,
        isStakeLoading:false,
        isSetProfileLoading:false,
        isUnstakeLoading:false,
        rewards: rewards,
        lockedInfo: lockedInfo,
        // TODO : Change to support multi rewards
        reward: this.poolInfo.rewards[0],
        rewardTotal:rewardTotal,
        rewardPerBlock:rewardPerBlock,
        startBlock: isStaked ? lockedInfo.startBlock:undefined,
        endBlock: isStaked ? lockedInfo.endBlock:undefined,
        currentReward: isStaked ? (blockPass * rewardPerBlock):0,
        blockLeft: isStaked ? blockLeft:0,
        unlockable:isStaked ? lockedInfo.unlockable:false,
        endDate:endDate
      }
      if(!_nft.isApproved){
        this.isApproveAll = false
      }
      this.nfts.push(_nft)
    }
    this.nfts.sort(function(a, b){
      return ((+b.isStaked) - (+a.isStaked) || (a.tokenIdNumber-b.tokenIdNumber))
    })
    log.debug("nfts ",this.nfts)
    this.isNFTReady = true
  }

  calculateBlockAmount(start,end,current){
    if(current < end){
      if(current < start){
        return 0
      }
      return current - start
    }else if(current > end){
      return end - start
    }else{
      return 0
    }
  }
  calculateBlockLeft(start,end,current){
    if(current < end){
      if(current < start){
        return 0
      }
      return end - current
    }else if(current > end){
      return 0
    }else{
      return 0
    }
  }

  async refreshPendingReward(){
    /*this.pendingRewards = await this.miningService.pendingReward(this.poolInfo.address)
    log.debug("update pending rewards")
    this.pendings = []
    for(let r of this.rewards){
      let _pending
      if(this.pendingRewards[0]==0){
        _pending = { reward:r, amount:0 }
      }else {
        let idx = this.pendingRewards[0].indexOf(r.address)
        _pending = { reward:r, amount:utils.formatEther(this.pendingRewards[1][idx]) }
      }
      log.debug("=== %o",_pending)

      this.pendings.push(_pending)
    }*/
  }

  async calculateAPR(){
    this.totalCost = 0
    this.totalReward = 0
    this.totalRewardUSD = 0
    let price = await this.currencyService.getUSDFromSymbol(this.pool.rewards[0].symbol.toLowerCase())
    for (const nft of this.nfts) {

      if(nft.lockedInfo && !nft.lockedInfo.isUnlocked){
        this.totalCost += this.pool.defaultPrice
        this.totalReward += nft.rewardTotal
        this.totalRewardUSD += nft.rewardTotal * price
      }
    }

    let rewardValuePerYear =  this.totalRewardUSD
    this.apr = (rewardValuePerYear *100 ) / this.totalCost
    log.debug("APR "+this.apr)
    log.debug("APR pow "+Math.pow((1+(this.apr/365)), 365))
    this.apy = (Math.pow((1+(this.apr/(365*100))), 365) - 1 ) * 100
  }

  async openRedeemModal(nft){
    const modalInstance = this.modalService.open(RedeemModalComponent,{
      centered: true,
      windowClass: 'modal modal-primary',
      size: 'lg',
      // scrollable: true
    });

    modalInstance.componentInstance.poolInfo = this.poolInfo
    modalInstance.componentInstance.nft = nft
    modalInstance.componentInstance.user = this.account

    modalInstance.componentInstance.dataService.subscribe((data) => {
      log.debug("return data from modal %o",data)
    })

  }

  async faucet(){
    this.isFaucetLoading = true
    this.miningService.faucet(this.poolInfo.collectionAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      //log.debug("faucet events ",receipt.events)
      let transferEvent = receipt.events.find((e)=> e.event === 'Transfer')
      this.tokenIds.push(transferEvent.args.tokenId)
      await this.fetchTokenIds()
      await this.refresh()
      this.singletonService.fire('success','faucet success', 'Success faucet id '+transferEvent.args.tokenId)
    }).catch((error)=>{
      //log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isFaucetLoading = false
    })
  }

  async stake(tokenId){
    let nft = this.nfts.find(_nft => _nft.tokenId == tokenId)
    nft.isStakeLoading = true
    this.miningService.lockNFT(this.poolInfo.address, tokenId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("lock events ",receipt.events)
      let stakeEvent = receipt.events.find((e)=> e.event === 'Locked')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','stake success', 'Success stake nft')
      nft.isStakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','stake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isStakeLoading = false
    })
  }

  async unstake(lockedId){
    let nft = this.nfts.find(_nft => _nft.isStaked ? _nft.lockedInfo.id == lockedId:false)
    nft.isUnstakeLoading = true
    this.miningService.unlockNFT(this.poolInfo.address, lockedId).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("unstake events ",receipt.events)
      let unstakeEvent = receipt.events.find((e)=> e.event === 'Unlocked')
      await this.fetchOnce()
      await this.refresh()
      this.singletonService.fire('success','unstake success', 'Success stake nft')
      nft.isUnstakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','unstake failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      nft.isUnstakeLoading = false
    })
  }

  async withdraw(){
    this.isWithdrawLoading = true
    this.miningService.withdraw(this.poolInfo.address).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("withdraw events ",receipt.events)
      let withdrawnEvent = receipt.events.find((e)=> e.event === 'RewardWithdrawn')
      await this.refresh()
      this.singletonService.fire('success','RewardWithdrawn success', 'Success RewardWithdrawn')
      this.isWithdrawLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','RewardWithdrawn failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isWithdrawLoading = false
    })
  }

  async approve(){
    this.isApproveAllLoading =true
    this.miningService.approve(this.poolInfo.address, this.poolInfo.collectionAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.refreshNFTs()
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApproveAllLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveAllLoading =false
    })
  }



}
