import { NumberAndDotDirective } from './numbers-and-dot.directive';
import { NumberDirective } from './numbers-only.directive';
import { DecimalNumberDirective } from './decimalNumberDirective';
import { AlphaNumbericDirective } from './alpha-numberic-diractive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AlphaNumbericDirective,
    NumberDirective,
    DecimalNumberDirective,
    NumberAndDotDirective,
   ],
  imports: [],
  exports: [
    AlphaNumbericDirective,
    NumberDirective,
    DecimalNumberDirective,
    NumberAndDotDirective,
  ]
})
export class CoreDirectivesModule {}
