<div class="main">
  <app-header-section></app-header-section>

  <div class="container" *ngIf="!isSupportChain">
    <section class="author-area " style="min-height: 70vh">
      <div class="text-center" style="margin-top: 8vh">
        <img src="assets/img/icons/sunglasses.png" width="128"/>
        <h5>Your wallet is connected to chain id that is not supported for this mining pool.</h5>
        <p>Please connect your wallet to chain id {{supportChainId}}</p>
        <button (click)="connectToSupportChain()" class="btn btn-primary text-white">
          Connect
        </button>
      </div>
    </section>
  </div>

  <div class="container" *ngIf="!poolFound">
    <section class="author-area " style="min-height: 70vh">
      <div class="text-center" style="margin-top: 8vh">
        <img src="assets/img/icons/sunglasses.png" width="128"/>
        <h5>Mining Pool Not Found</h5>
        <p>Your specific pool is not found or not active.</p>
        <button [routerLink]="['/mining']" class="btn btn-primary text-white">
          Go Back
        </button>
      </div>
    </section>
  </div>

  <div class="container" *ngIf="isSupportChain && poolFound">
    <section class="author-area" style="min-height: 70vh">
      <div *ngIf="isLoading" class="col-12 py-5 my-5 text-center ">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div *ngIf="!isLoading" class="mining-pool-wrapper">
        <div class="mining-pool-info d-flex">
          <div class="info-box-left d-flex">
            <div class="info-box-left-img mr-4">
              <img [src]="poolInfo.profileImage" width="140"
                   alt="">
            </div>
            <div class="info-box-left-content">
              <h2 class="tx-36 m-0">NFT Mining</h2>
              <h5 class="tx-18 mx-0 my-2 font-weight-medium">{{poolInfo.name}}</h5>
              <p class="tx-14 m-0 text-muted">
                {{poolInfo.description}}
                (<a class="tag-a-custom tx-14" [href]="poolInfo.purchaseLink">Buy {{poolInfo.collectionName}} NFT</a>)
              </p>
            </div>
          </div>
          <div class="info-box-right ml-5">
            <div class="info-box-right-wrapper d-flex align-items-center justify-content-between">
              <div class="info-box-right-content">
                <div class="text-muted">
                  <small class="tx-12">{{poolInfo.collectionName}} NFT Mining Info</small>
                </div>
                <div class="apy-val tx-28 txt-white-main font-weight-bold">
                  APY :
                  <!-- {{apy | formatNumber : '0':'1.0-6'}} % -->
                  <b class="text-success">
                    <span class="ml-1" [countUp]="apy" [options]="{
                      decimalPlaces: 2,
                      separator: ',',
                      duration: 2
                    }" [reanimateOnClick]="false"></span>%
                  </b>
                </div>
                <div class="pl-4">
                  <!-- <div class="text-muted line-h-1 mb-1">
                    <small class="tx-12">Total token in pools : 16.42 Million</small>
                  </div> -->
                  <div class="text-muted line-h-1 mb-1">
                    <small class="tx-12">Calculate from <span class="text-white-main">{{apr | formatNumber : '0':'1.0-6'}}%</span> personal APR</small>
                  </div>
                  <div class="text-muted line-h-1 mb-1">
                    <small class="tx-12">Total hash power in pool : <span class="text-white-main">{{totalHashPower | formatNumber: '0':'1.0-6'}}
                      HPOW</span></small>
                  </div>
                  <div class="text-muted line-h-1 mb-1">
                    <small class="tx-12">My hash power in pool : <span class="text-white-main">{{userHashPower | formatNumber: '0':'1.0-6'}} HPOW
                      (-{{ userHashPowerPercent | formatNumber: '0':'1.0-6'}}%)</span></small>
                  </div>
                  <div class="text-muted line-h-1 mb-1">
                    <small class="tx-12">My NFT on mining : <span class="text-white-main">{{userInfo ? userInfo.stakedTokenIds.length:0}}</span></small>
                  </div>
                </div>
              </div>
              <div class="info-box-right-img">
                <!--
                <div class="info-box-right-img-bg">
                  <img
                    src="https://freecity-test.s3.ap-southeast-1.amazonaws.com/assets/6254517810c5feaa1d253dd8/94b051ba88312c0154d52cd858b64043.gif"
                    width="40" alt="">
                </div>
                <div class="info-box-right-name mt-2 txt-white-main tx-14 mr-2">Starkz</div>
                -->
              </div>
            </div>
            <!--<div class="text-right mt-1">
              <a href="#" class="tag-a-custom"><small class="">Share this card to social media</small></a>
            </div> -->
          </div>
        </div>
        <hr>
        <ng-container *ngIf="account">
          <div class="row align-items-center row-balance-and-pending">
            <div class="col-md-3 col-sm-6 mb-5 mb-md-0">
              <strong>Wallet Balance</strong>
              <p *ngFor="let balance of balances">
                <img src="{{balance.reward.image}}" class="mr-1" width="24px"/> {{balance.reward.name}} :
                <span *ngIf="balance.amount != undefined">
                  <!-- {{balance.amount | formatNumber: '6':'1.2-6'}} <br> -->
                  <span class="ml-1" [countUp]="balance.amount" [options]="{
                    decimalPlaces: decimal,
                    separator: ',',
                    duration: 2
                  }" [reanimateOnClick]="false"></span>
                </span>
              </p>
            </div>
            <div class="col-md-3 col-sm-6 mb-5 mb-md-0">
              <strong>Pending Rewards</strong>
              <p *ngIf="!pendings"> - </p>
              <div *ngFor="let pending of pendings">
                <div >
                  <img  src="{{pending.reward.image}}" class="mr-1" width="24px"/> {{pending.reward.name}} :
                  <h5 style="margin-bottom: 0px;display: inline-block;" *ngIf="pending.amount != undefined">
                    <!-- {{pending.amount | formatNumber: '6':'1.2-6'}} <br> -->
                    <span class="ml-1" [countUp]="pending.amount" [options]="{
                      decimalPlaces: decimal,
                      separator: ',',
                      duration: 2
                    }" [reanimateOnClick]="false"></span>

                  </h5>

                </div>

              </div>
            </div>

            <div class="col-md-6 text-right wrapper-btn-action-reward mb-3 mb-md-0">
              <a *ngIf="demoMode && !isFaucetLoading && !isWithdrawLoading"
                   class=" btn-animation btn-border-animation " (click)="faucet()">
                Tap faucet for NFT
              </a>
              <a *ngIf="(demoMode || userInfo.stakedTokenIds.length > 0 ) && !isWithdrawLoading && !isFaucetLoading"
                 class="btn btn-primary text-white ml-4 " (click)="withdraw()">
                Withdraw reward
              </a>
              <div class="text-center" *ngIf="isWithdrawLoading || isFaucetLoading">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row align-items-center">
            <div class="col-6 mb-3">
              <h4>Your NFTs</h4>
              <p>Send/Stop mining will auto withdraw rewards to your wallet.</p>

            </div>
            <div class="col-6 mb-3 text-right">
              <div *ngIf="!isApproveAll" class="mt-4 mb-1 text-center">

                <button [disabled]="isApproveAllLoading" class="btn btn-primary text-white w-50" (click)="approve()">
                  <span *ngIf="isApproveAllLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Approve NFT
                </button>
              </div>
              <ng-container *ngIf="isApproveAll">
                <button [disabled]="isStakeAllLoading" class="btn btn-bordered-white" (click)="sendAll()">
                  <span *ngIf="isStakeAllLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Send them all
                </button>
                <button [disabled]="isUnstakeAllLoading" class="btn btn-bordered-white ml-2" (click)="stopAll()">
                  <span *ngIf="isUnstakeAllLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  Stop them all
                </button>
              </ng-container>
            </div>

          </div>
          <div class="row">
            <div class="col-12 mb-3">
              <hr/>
              <div *ngIf="demoMode" class="mt-1 alert alert-warning alert-warning-custom" role="alert">
                <strong>Please note</strong>, this is demo mode, the hash power will be random.
              </div>
            </div>

            <div *ngIf="!isNFTReady" class="col-12 text-center mt-3">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>

          <div class="mining-pool-items" *ngIf="isNFTReady">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-3 mt-3 mb-4 d-flex align-items-stretch" *ngFor="let nft of nfts">
                <div class="box-nft card card-no-hover-animate">
                  <div class="label-hpow text-center">
                    <div *ngIf="nft.hashPower"
                         class="tx-18 text-white">{{nft.hashPower | formatNumber: '0':'1.0-6'}}</div>
                    <p class="m-0 tx-12 text-white">HPOW</p>
                  </div>
                  <div class="image-over">
                    <div class="image-over-wrapper">
                      <img *ngIf="!nft.animation_url" class="card-img-top"
                         [src]="nft.image"
                         alt="">
                      <video *ngIf="nft.animation_url"
                             #video
                             class="card-img-top video-player nft-video"
                             (canplay)="video.play()"
                             [src]="nft.animation_url"

                             controls loop autoplay muted
                             [muted]="'muted'"
                             controlsList="nodownload"
                      >
                      </video>

                      <!--
                      <div  *ngIf="nft.animation_url" class="btn-nft-action-vdo">
                        <button type="button" class="btn-action-vdo btn rounded-circle btn-light p-2 shadow" (click)="playVDO($event);$event.stopPropagation();">
                          <img class="img-play-vdo" src="../../../assets/img/play-fill.svg" width="44" style="margin-left: 1px;">
                          <img class="img-pause-vdo" src="../../../assets/img/pause-fill.svg" width="44" style="display: none;">
                        </button>
                      </div>-->
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>{{poolInfo.collectionName}} #{{nft.tokenId}}</h4>
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div>Rarity</div>
                      <div class="text-right txt-sky">
                        <span *ngIf="nft.rarity">{{nft.rarity}}</span>
                        <span *ngIf="!nft.rarity">-</span>
                      </div>
                    </div>
                    <hr/>

                    <div *ngIf="!nft.isStaked" class="d-flex align-items-center justify-content-between w-100 pt-3 pb-3">
                      <i class="text-muted text-sm-center">Rewards Rate will be shown after send to mine</i>
                    </div>

                    <ng-container *ngIf="nft.isStaked">
                      <div class="">
                        <div class="d-flex align-items-center justify-content-between w-100">
                          <div>Rewards Rate</div>
                        </div>
                        <div *ngFor="let reward of nft.rewards"
                             class="d-flex align-items-center justify-content-between w-100">
                          <div><img src="{{reward.image}}" width="12px" style="display: inline-block"/> {{reward.symbol}}
                          </div>
                          <div class="text-right text-muted">
                            <span *ngIf="decimal==6">{{reward.tokenPerBlock | formatNumber: '6':'1.2-6'}}</span>
                            <span *ngIf="decimal!=6">{{reward.tokenPerBlock | number:'1.12-12'}}</span>
                            /Block
                          </div>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                  <div class="card-footer border-top mx-0 mb-0 mt-3 py-0">
                    <!-- <hr/> -->

                    <div *ngIf="!nft.isApproved" class="mt-4 mb-1 text-center">
                      <button class="btn btn-primary text-white w-100" (click)="approve()">Approve</button>
                    </div>
                    <div *ngIf="nft.isApproved && !nft.isStaked" class="mt-4 mb-1 text-center">
                      <button class="btn btn-primary text-white w-100" [disabled]="nft.isStakeLoading"
                              (click)="stake(nft.tokenId)">
                        <span *ngIf="nft.isStakeLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                        Send to mine
                      </button>
                    </div>
                    <div *ngIf="nft.isStaked" class="mt-4 mb-1 text-center">
                      <button class="btn btn-dark btn-unstake text-white w-100" [disabled]="nft.isUnstakeLoading"
                              (click)="unstake(nft.tokenId)">
                        <span *ngIf="nft.isUnstakeLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                        Stop mining
                      </button>
                    </div>
                    <!--
                    <div *ngIf="nft.profileExisted" class="mt-4 mb-1 text-center">
                      <button class="btn btn-success text-white w-100" [disabled]="nft.isSetProfileLoading"
                              (click)="setProfile(nft.tokenId)">
                        <span *ngIf="nft.isSetProfileLoading" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                        Set as profile
                      </button>
                    </div>
                    -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 mt-3 mb-4 d-flex align-items-stretch" style="min-height: 500px">
                <a class="box-nft card card-add-more d-flex align-items-center justify-content-center h-100 w-100"
                   [href]="poolInfo.purchaseLink" target="_blank">
                  <div> + Add more</div>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!account">
          <div class="text-center pt-3" >
            <h3>Please connect your wallet to start mining</h3>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
  <app-modal-menu></app-modal-menu>
  <app-footer-section></app-footer-section>
</div>


