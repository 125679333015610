<div class="main">
    <app-header-section></app-header-section>

    <div class="mt-95px">
      <div class="nft-search-view">
        <div class="wrapper-search-main">
          <app-filter-search [enabledCategories]="false" [enabledAttributes]="false" [enabledPrice]="false"></app-filter-search>
        </div>
        <div class="wrapper-search-result content-wrapper-tab">
          <!-- <app-auctions-one-section></app-auctions-one-section> -->
          <div class="tab-content pt-4 px-4 pb-5 mb-5">
            <div class="tab-pane fade show active" id="items" role="tabpanel" aria-labelledby="items-tab">

              <app-activity-table></app-activity-table>
              <!-- <div class="activity-content mt-3">
                <div class="">
                  <div class="activity-headers activity-rows">
                    <div class="activity-col-sale"></div>
                    <div class="activity-col-item">Item</div>
                    <div class="activity-col-price">Price</div>
                    <div class="activity-col-quantity">Quantity</div>
                    <div class="activity-col-from">From</div>
                    <div class="activity-col-to">To</div>
                    <div class="activity-col-time">Time</div>
                  </div>
                  <button class="activity-rows">
                    <div class="activity-col-sale">
                      <i class="icon-basket tx-18 mr-1"></i>
                      Sale
                    </div>
                    <div class="activity-col-item">
                      <div class="d-flex align-items-center">
                        <img src="https://lh3.googleusercontent.com/H-eyNE1MwL5ohL-tCfn_Xa1Sl9M9B4612tLYeUlQubzt4ewhr4huJIR5OLuyO3Z5PpJFSwdm7rq-TikAh7f5eUw338A2cy6HRH75=s100" width="50" height="50" class="mr-2">
                        <div>
                          <div class="tx-14 text-muted">Moonbirds</div>
                          <div class="font-weight-medium">#5209</div>
                        </div>
                      </div>

                    </div>
                    <div class="activity-col-price">
                      <div class="text-right">
                        <img src="../../../assets/img/tokens/eth.svg" width="10" class="mr-2">
                        <span>35.9</span>
                      </div>
                      <div class="text-muted tx-14 text-right">104,784.79</div>
                    </div>
                    <div class="activity-col-quantity">
                      <span class="">1</span>
                    </div>
                    <div class="activity-col-from">
                      <a href="#">1D2C4C</a>
                    </div>
                    <div class="activity-col-to">
                      <a href="#">8BB1AC</a>
                    </div>
                    <div class="activity-col-time">
                      <a class="tx-14" href="https://etherscan.io/tx/0xc8620d86dafffb9b67d945f92367c1f7a5297c90796a7247c7cbbff0ea9200a8" target="_blank">
                        23/04/2022 19.00
                        <i class="icon-share-alt tx-16 ml-1"></i>
                      </a>
                    </div>
                  </button>

                </div>
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <app-modal-menu></app-modal-menu>
</div>
