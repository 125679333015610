import { Pipe, PipeTransform } from '@angular/core';
import { SingletonService } from './../services/singleton.service';
import moment from 'moment'

@Pipe({
  name: 'diffDateTimeFromNow'
})
export class DiffDateTimeFromNowPipe implements PipeTransform {
  constructor(
    private singletonService:SingletonService,
  ){}

  transform(dateString: unknown, ...args: unknown[]): unknown {
    let selectedField:any = args[0]
    let isCountingToFuture:any = args[1]
    let _formatDateTime:any = args[2]

    if(!selectedField) selectedField = 'days'
    if(!_formatDateTime) _formatDateTime = this.singletonService.GLOBAL_DATETIME_FORMAT
    if(!isCountingToFuture) isCountingToFuture = true

    var now = moment();
    var b = moment(dateString, _formatDateTime);
    let data

    if(isCountingToFuture){
      data = this.getDatas(b,now)
    }else{
      data = this.getDatas(now,b)
    }

    return selectedField == 'all' ? data : data[selectedField]
  }

  getDatas(a,b){
    return {
      "days": a.diff(b, 'days'),
      "hours": a.diff(b, 'hours') % 24,
      "minutes": a.diff(b, 'minutes') % 60,
      "seconds": a.diff(b, 'seconds') % 60,
    }
  }
}

