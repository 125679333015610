import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explore-collections',
  templateUrl: './explore-collections.component.html',
  styleUrls: ['./explore-collections.component.scss']
})
export class ExploreCollectionsComponent implements OnInit {
  categories = []
  tab = 'top'
  constructor(
    private route: ActivatedRoute,
    private singletonService:SingletonService,
    private backendService:BackendService,
    private router: Router,
  ) {

    this.route.queryParams.subscribe(params => {
      if(params['tab']){
        this.tab = params['tab'];
      }
    });
  }

  ngOnInit() {

  }


  activeTab(tab){
    this.router.navigate(['/explore-collections/'],{queryParams: { 'tab': tab }})
    this.tab = tab
  }
}
