<div class="main">

  <app-header-section></app-header-section>

  <section class="">

    <h2 class="text-center title-h2">NFT launchpads</h2>
    <div class="container mt-5">
      <div class="row items">
        <div class="col-md-12 mb-4 text-center">
          <h4>Version 2</h4>
        </div>

          <div *ngIf="loading" class="col-12 text-center py-5 my-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
      </div>
      <div *ngIf="!loading && launchpads.length > 0" class="row items">

            <ng-container  *ngFor="let launchpad of launchpads">
              <div *ngIf="launchpad.active" class="col-sm-6 col-md-6 col-lg-4 col-lx-3 item mt-2 mb-4 d-flex align-items-stretch">
                <div class="card p-0 w-100">
                  <div class="image-over">
                    <a (click)="goToLaunchpad(launchpad)">
                      <img class="card-img-top" src="{{launchpad.coverImage | renderImageUrl : 'feature'}}" alt="">
                    </a>
                  </div>
                  <div class="card-caption p-4">
                    <div class="card-body py-1 mb-2">
                      <a (click)="goToLaunchpad(launchpad)" >
                        <h5 class="tx-16 mb-2 text-truncate">{{launchpad.name}}</h5>
                      </a>
                      <div>
                        <hr class="my-3" />
                        <div class=" tx-12">
                          <span class="text-white-main font-weight-light tx-14">{{launchpad.description}}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>




      </div>

      <div class="row" *ngIf="launchpads.length == 0">
        <div class="col-md-12 text-center">
          <h4 class="text-muted text-center">No Launchpad yet</h4>
        </div>
      </div>
      <hr/>
    <div class="row">
      <div class="col-md-12 text-center">
        <h4>Version 1</h4>
      </div>
    </div>
    </div>

    <div class="wrapper-offering-content">
      <app-collections-section
        [sortBy]="'floorEth:desc,volumnEth:desc'"
        [enabledLazyLoader]="true"
        [isShow]="false"
        [customLimit]="12"
        [customCollectionLink]="'/nft/launchpad/v1/'"
        [isOffering]="true"
      >
      </app-collections-section>
    </div>

  </section>

  <app-footer-section></app-footer-section>
  <app-modal-menu></app-modal-menu>

</div>
