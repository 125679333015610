import { ToCappitalPipe } from './to-cappital.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'verificationTypeToText'
})
export class VerificationTypeToTextPipe implements PipeTransform {
  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}
  transform(value: any, ...args: unknown[]): unknown {
    let isText = args[0] // text or icon

    if(isText == undefined) isText = true

    // <i class="icon-basket-loaded mr-3 mb-3"></i>
    switch (value ? value.toLowerCase() : undefined) {
      case 'official':
        return isText ? this.toCappitalPipe.transform(value) : "fas fa-check-circle text-primary";
      case 'verified':
        return isText ? this.toCappitalPipe.transform(value) : "fas fa-check-circle text-primary";
      case 'mintable':
        return isText ? this.toCappitalPipe.transform(value) : "far fa-dot-circle text-warning";
      case 'unverified':
        return isText ? this.toCappitalPipe.transform(value) : "fas fa-question-circle text-secondary";
      default:
        return isText ? this.toCappitalPipe.transform("unverified") : "fas fa-question-circle text-secondary";
    }
  }

}
