import { ToCappitalPipe } from './to-cappital.pipe';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryTypeToText'
})
export class CategoryTypeToTextPipe implements PipeTransform {
  constructor(
    private toCappitalPipe:ToCappitalPipe,
  ){}
  transform(value: any, ...args: unknown[]): unknown {
    let isText = args[0] // text or icon

    if(isText == undefined) isText = true

    // <i class="icon-basket-loaded mr-3 mb-3"></i>
    switch (value.toLowerCase()) {
      case 'art':
        return isText ? this.toCappitalPipe.transform(value) : "art";
      case 'collectibles':
        return isText ? this.toCappitalPipe.transform(value) : "collectibles";
      case 'music':
        return isText ? this.toCappitalPipe.transform(value) : "music";
      case 'domain-names':
        return isText ? this.toCappitalPipe.transform(value) : "domain-names";
      case 'photography':
        return isText ? this.toCappitalPipe.transform(value) : "photography-category";
      case 'sports':
        return isText ? this.toCappitalPipe.transform(value) : "sports";
      case 'trading cards':
        return isText ? this.toCappitalPipe.transform(value) : "trading-cards";
      case 'utility':
        return isText ? this.toCappitalPipe.transform(value) : "utility";
      case 'virtual-worlds':
        return isText ? this.toCappitalPipe.transform(value) : "virtual-worlds";
      case 'games-metaverse':
        return isText ? this.toCappitalPipe.transform(value) : "games-metaverse";
      default:
        return isText ?  this.toCappitalPipe.transform(value) : "";
    }
  }

}
