import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-nft-reveal-section',
  templateUrl: './nft-reveal-section.component.html',
  styleUrls: ['./nft-reveal-section.component.scss']
})
export class NftRevealSectionComponent implements OnInit {

  @Input() reveals:any = []
  @Input() isLoading = false

  constructor(
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router,
  ) { }

  ngOnInit(): void {
  }


  goToRevel(reveal){
    this.router.navigate(['nft/reveal/' + reveal.id],{})
  }

}
