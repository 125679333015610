import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Logger } from './../services/logger.service';
const log = new Logger('AlphaNumbericDirective'); // log.debug

@Directive({
  selector: 'input[alphaNumberic]'
})
export class AlphaNumbericDirective {

  @Input('regExFormatType') regExFormatType:any = 1;

  readonly REG_EX_CONFIG_TYPE = {
    1: /[^a-z0-9-]/,
    2: /[^a-zA-Z0-9-]/,
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ---- <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  //                 use with input [(value)] only | bug with [(ngModel)]
  //

                          @Output() valueChange = new EventEmitter()
                          // @Output() ngModelChange = new EventEmitter()

  //
  //            [(value)]="name" for [value]="name" (valueChange)="name = $event"
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ---- <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) async onInputChange(event) {

    const initalValue = this._el.nativeElement.value;
    log.debug("initalValue => %o",initalValue)
    const newValue = await this.replaceAll(initalValue,this.REG_EX_CONFIG_TYPE[this.regExFormatType], '');
    log.debug("newValue => %o",newValue)
    this._el.nativeElement.value = newValue;
    // ==========================================
      this.valueChange.emit(newValue);
      // this.ngModelChange.emit(newValue);
    // ==========================================

    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  async replaceAll(str, find, replace) {
    return await str.replace(new RegExp(find, 'g'), replace);
  }

}
