<section class="work-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Intro -->
                <div class="intro mb-4">
                    <div class="intro-content">
                        <span>How It Works</span>
                        <h3 class="mt-3 mb-0">Create and sell your NFTs</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row items">
            <div class="col-12 col-sm-6 col-lg-3 item text-center">
                <!-- Single Work -->
                <div class="single-work">
                    <i class="icons icon-wallet text-effect "></i>
                    <h4>Connect your wallet</h4>
                    <p>
                      Once you’ve set up your wallet,
                      connect it to FreeCity by clicking Connect Button in the top right corner.
                    </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item text-center">
                <!-- Single Work -->
                <div class="single-work">
                    <i class="icons icon-grid text-effect"></i>
                    <h4>Create collection</h4>
                    <p>
                      Click Create and set up your collection.
                      Add social links, a description, profile & banner images,
                      and more information.
                    </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item text-center">
                <!-- Single Work -->
                <div class="single-work">
                    <i class="icons icon-drawer text-effect"></i>
                    <h4>Add your NFTs</h4>
                    <p>
                      Upload your work (image, video, audio, or 3D art),
                      add a title and description,
                      and customize your NFTs with properties.</p>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item text-center">
                <!-- Single Work -->
                <div class="single-work">
                    <i class="icons icon-bag text-effect "></i>
                    <h4>List them for sale</h4>
                    <p>
                      Choose NFT for listings,
                      select price for listings,
                      click on sale button to list your asset for sale.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
