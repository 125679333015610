import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ethers } from 'ethers';
import { BackendService } from 'src/app/services/backend.service';
import { FreeCityContractService } from 'src/app/services/free-city-contract.service';
import { MiningService } from 'src/app/services/mining.service';
import { NftService } from 'src/app/services/nft.service';
import { SingletonService } from 'src/app/services/singleton.service';

import {environment} from "../../../environments/environment";
import { Logger } from '../../services/logger.service';
const log = new Logger('CraftingStationComponent');

@Component({
  selector: 'app-crafting-station',
  templateUrl: './crafting-station.component.html',
  styleUrls: ['./crafting-station.component.scss']
})
export class CraftingStationComponent implements OnInit {

  id:any
  craftingStation:any

  isLoading = false
  isDataFetching = false
  demoMode:any
  currentChain:any
  account:any

  receipts = []
  selectedReceipt:any

  userConverterTokenIds = []
  selectedConverter:any

  requiredMats:any = []

  isSetDefaultVideoAutoPlay = true // To initial play/pause button of videos

  whitelistConverterAddress:any
  forgeFee:any
  maxBooter:any
  boosterAddresses:any = []

  isApproveAllConverterNFTs = false
  isApproveAllConverterNFTsLoading = false
  converterNFTs:any = []
  stakedConverterNFTs:any = []
  unstakedConverterNFTs:any = []

  isApproveAllBoosterNFTs = false
  isApproveAllBoosterNFTsLoading = false
  boosterNFTs:any = []
  stakedBoosterNFTs:any = []
  unstakedBoosterNFTs:any = []
  selectedBooster:any
  isWithdrawBoosterLoading = false

  energyStorage:any
  expStorage:any
  refillInfos:any
  isRefillEnergyLoading = false
  isApproveRefillToken = false
  isApproveRefillTokenLoading = false

  isCustomRefillMultiplier = false
  refillMultiplier = 1 // max 100

  isForgeLoading = false
  isClaimLoading = false

  isConverterNFTsLoading = false

  isUserLogsLoading = false
  userLogs = []
  userLogsLength = 0
  userLogsOffset = 0
  userLogsAmount = 3
  userLogsCurrentPage = 1
  debug = false;
  recipeInfos:any;

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    public router:Router,
    private route: ActivatedRoute,
    private modalService:NgbModal,
    private freeCityContractService:FreeCityContractService,
    private nftService:NftService,
    private miningService:MiningService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.demoMode = environment.features.DEMO_MODE
    this.route.queryParams
      .subscribe(params => {
          this.debug = params.debug != undefined ;
        }
      );
  }


  async ngOnInit(){
    this.isDataFetching = true
    this.account = await this.singletonService.getCurrentConnectedAccount()
    this.currentChain = await this.singletonService.getCurrentChain()
    log.debug("this.currentChain => %o",this.currentChain)

    this.craftingStation = await this.backendService.getCraftingStationById(this.id)
    this.recipeInfos = this.craftingStation.recipes
    this.isLoading = false;

    log.debug("this.craftingStation => %o",this.craftingStation)

    if(this.craftingStation){

      this.receipts = await this.getRecipes()
      log.debug("xxx this.receipts => %o",this.receipts)

      this.whitelistConverterAddress = await this.getConverterAddress()

      this.forgeFee = await this.getForgeFee()
      this.maxBooter = await this.getMaxBooster()
      this.boosterAddresses = await this.getBoosterAddresses()
      this.energyStorage = await this.getEnergyStorage()
      this.expStorage = await this.getExpStorage()

      await this.getUserLogOrders()
      await this.refreshConverterNFTs()

      this.isDataFetching = false
    }else{
      this.isDataFetching = false
    }

  }

  alert(text){
    window.alert(text)
  }

  async refreshConverterNFTs(){
    this.converterNFTs = []
    this.isConverterNFTsLoading = true

    const unstakedConverterTokenIds = await this.fetchTokenIdsFromNFTCollectionAddress(this.whitelistConverterAddress)
    log.debug("unstakedConverterTokenIds => %o",unstakedConverterTokenIds)

    const stakedConverterTokenIds = await this.getUserConverterTokenIds()
    log.debug("stakedConverterTokenIds => %o",stakedConverterTokenIds)

    const tokenObjects = [
      ...unstakedConverterTokenIds.map((it) => { return {id:it,isStaked:false}}),
      ...stakedConverterTokenIds.map((it) => { return {id:it,isStaked:true}})
    ]

    Promise.all(tokenObjects.map(async (tokenObject): Promise<any> => {
      const tokenId = tokenObject.id
      const isStaked = tokenObject.isStaked
      const tokenIdNumber = (tokenId.toNumber())

      log.debug("tokenObject.id => %o",tokenObject.id)
      let info = await this.miningService.getNFTMeta(this.whitelistConverterAddress, tokenId)
      log.debug("info => %o",info)

      let _nft = {
        tokenIdNumber: tokenIdNumber,
        tokenId: (tokenId),
        // hashPower: hashPower,
        isApproved:await this.miningService.checkApprove(this.craftingStation.stationAddress, this.whitelistConverterAddress),
        isStaked: isStaked,
        media: info.media ? info.media[0].trait_type : undefined,
        image: info.image,
        animation_url: info.animation_url,
        // profileExisted: await this.miningService.isExistedProfile(this.poolInfo.collectionAddress, tokenId),
        attributes: info.attributes,
        // rarity: _rarity ? _rarity.value:undefined,
        name:info.name,
        description:info.description,
        isStakeLoading:false,
        // isSetProfileLoading:false,
        isUnstakeLoading:false,
        // rewards: rewards
        infos: isStaked ? await this.getConverterInfo(tokenIdNumber) : undefined,
        canClaim: isStaked ? await this.canClaim(tokenIdNumber) : undefined,
      }

      this.converterNFTs.push(_nft)

    })).then(async () => {

      log.debug("xxx this.converterNFTs => %o",this.converterNFTs)
      const isNotApproveAll = this.converterNFTs.find(it => it.isApproved == false)
      if(!isNotApproveAll){
        this.isApproveAllConverterNFTs = true
      }else{
        this.isApproveAllConverterNFTs = false
      }

      this.converterNFTs = this.converterNFTs.sort(function(a, b){
        return ((a.tokenIdNumber-b.tokenIdNumber))
      })

      this.stakedConverterNFTs = this.converterNFTs.filter(it=> it.isStaked == true)
      this.unstakedConverterNFTs = this.converterNFTs.filter(it=> it.isStaked == false)
    }).finally(() =>{
      this.isConverterNFTsLoading = false
    });
  }

  async fetchSelectedConverterInfos(){
    this.selectedConverter.canClaim = await this.canClaim()
    this.selectedConverter.infos = await this.getConverterInfo()

    log.debug("fetchSelectedConverterInfos this.selectedConverter.infos => %o",this.selectedConverter.infos)


    switch (this.selectedConverter.infos.state) {
      case 0:
        this.selectedConverter.isReadyToForge = true
        log.debug("x selectConverter this.selectedConverter.isReadyToForge => %o",this.selectedConverter.isReadyToForge)

        this.selectedReceipt = undefined
        break;
      case 1:
        this.selectedConverter.isReadyToForge = false

        const currentBlock = await this.miningService.getCurrentBlock()
        log.debug("x selectConverter currentBlock => %o",currentBlock)

        const endAtBlock = this.selectedConverter.infos.currentOrder.endAtBlock
        log.debug("x selectConverter endAtBlock => %o",endAtBlock)

        const blockLeft = endAtBlock - currentBlock
        log.debug("x selectConverter blockLeft => %o",blockLeft)

        const secondLeft = blockLeft * this.currentChain.blockTimeSecond
        const endDate = new Date(); // finish
        endDate.setSeconds(endDate.getSeconds() + secondLeft);
        this.selectedConverter.infos.endDateUnixTimeStamp = endDate
        log.debug("x selectConverter this.selectedConverter.infos.endDateUnixTimeStamp => %o",this.selectedConverter.infos.endDateUnixTimeStamp)

        await this.selectReceipt(this.selectedConverter.infos.currentOrder.recipeId)

        log.debug("x selectConverter this.selectedConverter.isReadyToForge => %o",this.selectedConverter.isReadyToForge)
        break;
      default: // unknown state
        this.selectedConverter.isReadyToForge = false
        this.selectedReceipt = undefined
        break;
    }


    this.selectedConverter.infos.energy = await this.getConverterEnergy()
    this.selectedConverter.infos.exps = await this.getConverterExps()

    if(this.craftingStation.isLevelProfitInCluded){ // contract v2 (level reduction)
      this.selectedConverter.infos.level = await this.getConverterLevel()
      this.selectedConverter.infos.levelReduce = await this.getConverterLevelReduce()
    }else{
      this.selectedConverter.infos.level = 1
      this.selectedConverter.infos.levelReduce = undefined
    }

    log.debug("x selectConverter this.selectedConverter => %o",this.selectedConverter)

    this.refillInfos = await this.getRefillInfos()
    this.refillInfos.refillTokenInfos = (await this.freeCityContractService.getTokenInfoAndBalance(
      [this.refillInfos.refillTokenAddress],
      this.account,
      true
    ))[0]
    log.debug("xxx this.refillInfos => %o",this.refillInfos)

    await this.checkApprovedRefillToken()
  }

  async checkApprovedRefillToken(){
    this.isApproveRefillToken = await this.freeCityContractService.checkTokenApprovalFor(this.refillInfos.refillTokenAddress,1, this.energyStorage)
  }

  async approveRefillToken(){
    this.isApproveRefillTokenLoading = true
    this.freeCityContractService.approveTokenTo(this.refillInfos.refillTokenAddress, this.energyStorage).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve receipt ", receipt)
      await this.singletonService.fire('success','Approved', 'Success approve refil token.')
      await this.checkApprovedRefillToken()
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Approve failed', 'Something went wrong please refresh and try again later.')
    }).finally(()=>{
      this.isApproveRefillTokenLoading = false
    })
  }

  async checkApprovedRequiredMat(tokenAddress){
    return await this.freeCityContractService.checkTokenApprovalFor(tokenAddress,1, this.craftingStation.stationAddress)
  }

  approveAsync(tokenAddress){
    return new Promise(async (resolve, reject) => {
      this.freeCityContractService.approveTokenTo(tokenAddress, this.craftingStation.stationAddress).then(async (transaction)=>{
        let receipt = await transaction.wait()
        log.debug("approve receipt ", receipt)
        resolve({
          isSuccess: true,
        })
      }).catch((error)=>{
        log.error(error)
        resolve({
          isSuccess: false,
        })
      })
    })
  }

  async multipleApproveRequiredMats(){
    this.selectedReceipt.isApproveLoading = true
    const notApprovedMats = this.selectedReceipt.materials.filter(it => it.isApproved == false)

    const promises = [];
    for (const notApprovedMat of notApprovedMats) {
      promises.push(this.approveAsync(notApprovedMat.tokenAddress))
    }

    Promise.all(promises).then(async (results) => {
      log.debug("All data loaded... results: %o",results)

      const isFoundSuccessApprove = results.find(it=>it.isSuccess)
      const successApproveAmount = results.filter(it=>it.isSuccess).length

      if(isFoundSuccessApprove){

        await this.singletonService.fire(
          'success',
          'Approved',
          'Success approve required mats. ('+successApproveAmount+'/'+notApprovedMats.length+')'
        )

        await this.refreshRequiredMats()
      }
    }).catch((errors) => {
      log.error("multipleApproveRequiredMats errors %o",errors)
    }).finally(()=>{
      this.selectedReceipt.isApproveLoading = false
    });

  }

  async refreshSelectedConverterAndBoosterNFTs(
    isFetchStakedBoosters = true,
    isFetchUnstakedBoosters = false,
  ){
    log.debug("refreshSelectedConverterAndBoosterNFTs isFetchStakedBoosters => %o",isFetchStakedBoosters)
    log.debug("refreshSelectedConverterAndBoosterNFTs isFetchUnstakedBoosters => %o",isFetchUnstakedBoosters)

    this.boosterNFTs = []
    let tokenObjects = []

    if(isFetchStakedBoosters){
      this.stakedBoosterNFTs = []
      this.selectedConverter.isStakedBoosterLoading = true

      await this.fetchSelectedConverterInfos()

      const stakedBoosterTokenIds = this.selectedConverter.infos.boosterIds
      log.debug("stakedBoosterTokenIds => %o",stakedBoosterTokenIds)
      this.selectedConverter.infos.totalReductTimePercent = 0
      for (const stakedBoosterTokenId of stakedBoosterTokenIds) {
        log.debug("x stakedBoosterTokenId => %o",stakedBoosterTokenId)
        let stakedBoosterInfo:any = await this.getStakedBoosterInfosById(Number(stakedBoosterTokenId))
        log.debug("xxx stakedBoosterInfo => %o",stakedBoosterInfo)
        this.stakedBoosterNFTs.push(stakedBoosterInfo)

        this.selectedConverter.infos.totalReductTimePercent += stakedBoosterInfo.boostPercent

        stakedBoosterInfo.isStaked = true
        tokenObjects.push(stakedBoosterInfo)

      }
      log.debug("xxx this.stakedBoosterNFTs => %o",this.stakedBoosterNFTs)
    }

    if(isFetchUnstakedBoosters){
      this.selectedConverter.isUnstakedBoosterLoading = true
      this.unstakedBoosterNFTs = []

      for (const boosterAddress of this.boosterAddresses) {
        const _unstakedBoosterTokenIds = await this.fetchTokenIdsFromNFTCollectionAddress(boosterAddress)
        log.debug("unstakedBoosterTokenIds => %o",_unstakedBoosterTokenIds)
        const _tokenObjects = _unstakedBoosterTokenIds.map((it) => { return {
          id:it,
          isStaked:false,
          boosterAddress:boosterAddress,
        }})
        tokenObjects = [...tokenObjects,..._tokenObjects]
      }
    }

    Promise.all(tokenObjects.map(async (tokenObject): Promise<any> => {
      const tokenId = tokenObject.boosterTokenId ? tokenObject.boosterTokenId : tokenObject.id
      const isStaked = tokenObject.isStaked

      const stakedBoosterId = tokenObject.isStaked ? tokenObject.id : undefined
      const converterTokenId = tokenObject.isStaked ? tokenObject.converterTokenId : undefined

      const boosterAddress = tokenObject.boosterAddress
      const boostPercent = tokenObject.boostPercent != undefined ? tokenObject.boostPercent : await this.getBoosterReducePercent(boosterAddress, tokenId)
      const level = tokenObject.level != undefined ? tokenObject.level :  await this.getBoosterLevel(boosterAddress, tokenId)

      const boosterTokenId = tokenObject.boosterTokenId
      const enabled = tokenObject.enabled
      const owner = tokenObject.owner

      log.debug("tokenId => %o",tokenId)
      log.debug("boosterAddress => %o",boosterAddress)

      let info = await this.miningService.getNFTMeta(boosterAddress, tokenId)
      log.debug("info => %o",info)

      let _nft = {
        tokenIdNumber: Number(tokenId),
        tokenId: (tokenId),
        // hashPower: hashPower,
        isApproved:await this.miningService.checkApprove(this.craftingStation.stationAddress, boosterAddress),
        isStaked: isStaked,
        media: info.media ? info.media[0].trait_type : undefined,
        image: info.image,
        animation_url: info.animation_url,
        // profileExisted: await this.miningService.isExistedProfile(this.poolInfo.collectionAddress, tokenId),
        attributes: info.attributes,
        // rarity: _rarity ? _rarity.value:undefined,
        name:info.name,
        description:info.description,
        isStakeLoading:false,
        // isSetProfileLoading:false,
        isUnstakeLoading:false,
        // rewards: rewards
        boosterReducePercent: boostPercent,
        boosterAddress: boosterAddress,
        level: level,
        stakedBoosterId: stakedBoosterId,
        converterTokenId: converterTokenId,
      }

      this.boosterNFTs.push(_nft)

    })).then(async () => {
      log.debug("xxx this.boosterNFTs => %o",this.boosterNFTs)


      this.boosterNFTs = this.boosterNFTs.sort(function(a, b){
        return ((a.boosterAddress-b.boosterAddress) || (a.tokenIdNumber-b.tokenIdNumber))
      })


      if(isFetchStakedBoosters){
        this.stakedBoosterNFTs = this.boosterNFTs.filter(it=> it.isStaked == true)
        this.selectedConverter.isStakedBoosterLoading = false
        log.debug("yyy this.stakedBoosterNFTs => %o",this.stakedBoosterNFTs)

      }

      if(isFetchUnstakedBoosters){
        this.unstakedBoosterNFTs = this.boosterNFTs.filter(it=> it.isStaked == false)
        this.selectedConverter.isUnstakedBoosterLoading = false
        log.debug("yyy this.unstakedBoosterNFTs => %o",this.unstakedBoosterNFTs)
      }

    })
  }

  async approveAllConverterNFTs(){
    this.isApproveAllConverterNFTsLoading =true
    this.miningService.approve(this.craftingStation.stationAddress, this.whitelistConverterAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.refreshConverterNFTs()
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApproveAllConverterNFTsLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveAllConverterNFTsLoading =false
    })
  }

  async approveAllBoosterNFTs(){
    this.isApproveAllBoosterNFTsLoading =true
    this.miningService.approve(this.craftingStation.stationAddress, this.selectedBooster.boosterAddress).then(async (transaction)=>{
      let receipt = await transaction.wait()
      log.debug("approve events ",receipt.events)
      let approveEvent = receipt.events.find((e)=> e.event === 'ApprovalForAll')
      if(approveEvent.args.approved){
        this.singletonService.fire('success','approve success', 'Success Approved')
        await this.refreshSelectedConverterAndBoosterNFTs(true,true)
      }else{
        this.singletonService.fire('success','approve failed', 'Failed Approved')
      }
      this.isApproveAllBoosterNFTsLoading =false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','faucet failed', 'ERROR: '+error.data.message)
    }).finally(()=>{
      this.isApproveAllBoosterNFTsLoading =false
    })
  }

  async getStakedBoosterInfosById(stakedBoosterId){
    log.debug("getStakedBoosterInfosById stakedBoosterId => %o",stakedBoosterId)
    const stationAddress = this.craftingStation.stationAddress
    const stakedBoosterInfo = await this.freeCityContractService.getStakedBoosterInfosById(
      stationAddress,
      stakedBoosterId,
    )
    log.debug("getStakedBoosterInfosById stakedBoosterInfo => %o",stakedBoosterInfo)

    return {
      boostPercent: Number(stakedBoosterInfo.boostPercent) / 100.0,
      boosterAddress: stakedBoosterInfo.boosterAddress,
      boosterTokenId: Number(stakedBoosterInfo.boosterTokenId),
      converterTokenId: Number(stakedBoosterInfo.converterTokenId),
      enabled: stakedBoosterInfo.enabled,
      id: stakedBoosterInfo.id,
      level: stakedBoosterInfo.level,
      owner: stakedBoosterInfo.owner,
    }
  }

  async fetchTokenIdsFromNFTCollectionAddress(_NFTCollectionAddress){
    let ids = await this.miningService.getOwnNFTIds(_NFTCollectionAddress)
    let tokenIds = ids ? ids:[]
    log.debug("fetchTokenIds tokenIds ",tokenIds)

    // tokenIds = tokenIds.concat(this.userInfo.stakedTokenIds)
    // log.debug("fetchTokenIds tokenIds ",tokenIds)
    return tokenIds
  }

  async getUserConverterTokenIds(){
    log.debug("getUserConverterTokenIds this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const _userConverterTokenIds = await this.freeCityContractService.getUserConverterTokenIds(
      stationAddress,
      this.account,
    )
    log.debug("getUserConverterTokenIds _userConverterTokenIds => %o",_userConverterTokenIds)
    return _userConverterTokenIds
  }

  async getCraftingStationRecipeLength(){
    log.debug("getCraftingStationRecipeLength this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const _length = await this.freeCityContractService.getCraftingStationRecipeLength(stationAddress)
    log.debug("getCraftingStationRecipeLength _length => %o",_length)

    return Number(_length)
  }

  async getRecipes(){
    log.debug("getRecipes this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const recipes = await this.freeCityContractService.getRecipes(stationAddress)
    log.debug("getRecipes recipes => %o",recipes)
    let _recipes = recipes.map(_reciept => {
      let info = this.recipeInfos.find((it) => it.id == Number(_reciept.id))
      return {
        amount: Number(ethers.utils.formatEther(_reciept.amount)),
        // amount: Number(_reciept.amount),

        blockToWait: Number(_reciept.blockToWait),
        enabled: _reciept.enabled,
        energyConsume: Number(_reciept.energyConsume) / 100.0,
        id: Number(_reciept.id),
        minter: _reciept.minter,
        resultAddress: _reciept.resultAddress,
        resultType: _reciept.resultType,
        tokenUri: _reciept.tokenUri,
        name: info.name,
        priority: info.priority
      }
    })
    _recipes = _recipes.sort((a, b) => { return a.priority - b.priority } )
    log.debug("_recipes %o",_recipes )
    return  _recipes
  }

  async getConverterAddress(){
    log.debug("getConverterAddress this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const converterAddress = await this.freeCityContractService.getConverterAddress(stationAddress)
    log.debug("getConverterAddress converterAddress => %o",converterAddress)

    return converterAddress
  }

  async getMaxBooster(){
    log.debug("getMaxBooster this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const maxBooster = await this.freeCityContractService.getMaxBooster(stationAddress)
    log.debug("getMaxBooster maxBooster => %o",maxBooster)

    return maxBooster
  }

  async getBoosterAddresses(){
    log.debug("getBoosterAddresses this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const boosterAddress = await this.freeCityContractService.getBoosterAddresses(stationAddress)
    log.debug("getBoosterAddresses boosterAddress => %o",boosterAddress)

    return boosterAddress
  }

  async getEnergyStorage(){
    log.debug("getEnergyStorage this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const energyStorage = await this.freeCityContractService.getEnergyStorage(stationAddress)
    log.debug("getEnergyStorage energyStorage => %o",energyStorage)

    return energyStorage
  }

  async getExpStorage(){
    log.debug("getExpStorage this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const expStorage = await this.freeCityContractService.getExpStorage(stationAddress)
    log.debug("getExpStorage expStorage => %o",expStorage)

    return expStorage
  }


  async getForgeFee(){
    log.debug("getForgeFee this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    const forgeFee = await this.freeCityContractService.getForgeFee(stationAddress)
    log.debug("getForgeFee forgeFee => %o",forgeFee)

    // return Number(forgeFee)
    // return Number(forgeFee) / 100.0;
    // return ethers.utils.formatEther(forgeFee);
    return Number(ethers.utils.formatEther(forgeFee)) * 100.0
  }

//   "internalType": "uint256",
//   "name": "_toConverterTokenId",
//   "type": "uint256"
// },
// {
//   "internalType": "address",
//   "name": "_boosterAddress",
//   "type": "address"
// },
// {
//   "internalType": "uint256",
//   "name": "_boosterTokenId",
//   "type": "uint256"

  async addBooster(unstakedBoosterNFT){
    log.debug("addBooster unstakedBoosterNFT => %o",unstakedBoosterNFT)
    unstakedBoosterNFT.isStakeLoading = true

    log.debug("addBooster this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    await this.freeCityContractService.addBooster(
      stationAddress,
      this.selectedConverter.tokenIdNumber,
      unstakedBoosterNFT.boosterAddress,
      unstakedBoosterNFT.tokenIdNumber,
    ).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("addBooster events ",receipt.events)
      // let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      // await this.fetchOnce()
      // await this.refresh()
      await this.refreshSelectedConverterAndBoosterNFTs(true,true)
      this.singletonService.fire('success','add booster success', 'Success add booster')
      unstakedBoosterNFT.isStakeLoading = false
      this.selectedBooster = undefined
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','add booster failed', 'ERROR: '+(error.data ? error.data.message : error.message))
    }).finally(()=>{
      unstakedBoosterNFT.isStakeLoading = false
    })
  }

//   "internalType": "uint256",
//   "name": "_fromConverterTokenId",
//   "type": "uint256"
// },
// {
//   "internalType": "uint256",
//   "name": "_boosterId",
//   "type": "uint256"
// }
// ],
// "name": "withdrawBooster",

  async withdrawBooster(stakedBoosterNFT,index){
    this.isWithdrawBoosterLoading = true
    log.debug("withdrawBooster stakedBoosterNFT => %o",stakedBoosterNFT)
    log.debug("withdrawBooster index => %o",index)

    stakedBoosterNFT.isStakeLoading = true

    log.debug("withdrawBooster this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    await this.freeCityContractService.withdrawBooster(
      stationAddress,
      stakedBoosterNFT.converterTokenId,
      stakedBoosterNFT.stakedBoosterId,
    ).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("stake events ",receipt.events)
      // let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      // await this.fetchOnce()
      // await this.refresh()
      await this.refreshSelectedConverterAndBoosterNFTs()
      this.singletonService.fire('success','withdraw success', 'Success withdraw booster')
      stakedBoosterNFT.isStakeLoading = false
      this.isWithdrawBoosterLoading = false

    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','withdraw failed', 'ERROR: '+ (error.data ? error.data.message : error.message))

    }).finally(()=>{
      stakedBoosterNFT.isStakeLoading = false
      this.isWithdrawBoosterLoading = false

    })

  }

  async stakeConverter(converter){
    log.debug("stakeConverter converter => %o",converter)
    const converterId = converter.tokenIdNumber
    converter.isStakeLoading = true
    log.debug("stakeConverter this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    await this.freeCityContractService.stakeConverter(stationAddress,converterId).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("stake events ",receipt.events)
      // let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      // await this.fetchOnce()
      // await this.refresh()
      await this.refreshConverterNFTs()
      this.singletonService.fire('success','stake success', 'Success stake nft')
      converter.isStakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','stake failed', 'ERROR: '+(error.data ? error.data.message : error.message))
    }).finally(()=>{
      converter.isStakeLoading = false
    })

  }

  async unstakeConverter(converter){
    log.debug("unstakeConverter converter => %o",converter)
    const converterId = converter.tokenIdNumber
    converter.isStakeLoading = true
    log.debug("unstakeConverter this.craftingStation => %o",this.craftingStation)
    const stationAddress = this.craftingStation.stationAddress
    await this.freeCityContractService.unstakeConverter(stationAddress,converterId).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("withdraw events ",receipt.events)
      // let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      // await this.fetchOnce()
      // await this.refresh()
      await this.refreshConverterNFTs()
      if(converter.tokenIdNumber == this.selectedConverter.tokenIdNumber){
        this.selectedConverter = undefined
      }
      this.singletonService.fire('success','withdraw success', 'Success withdraw nft')
      converter.isStakeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','withdraw failed', 'ERROR: '+ (error.data ? error.data.message : error.message))
    }).finally(()=>{
      converter.isStakeLoading = false
    })

  }

  async selectConverter(selectedConverter){
    if(this.selectedConverter && this.selectedConverter.isStakedBoosterLoading){
      this.singletonService.fire('warning','Data loading', 'Please wait.')
    }else{
      this.selectedConverter = selectedConverter
      log.debug("selectConverter this.selectedConverter => %o",this.selectedConverter)

      await this.refreshSelectedConverterAndBoosterNFTs()
    }

  }

  async selectBooster(selectedBooster){
    this.selectedBooster = selectedBooster
    log.debug("selectBooster this.selectedBooster => %o",this.selectedBooster)

  }


  async selectReceipt(receiptId){
    log.debug("selectReceipt receipt => %o",receiptId)
    const _receiptId = Number(receiptId)
    if(_receiptId == undefined || _receiptId == -1){
      this.selectedReceipt = undefined
    }else{
      log.debug("selectReceipt this.receipts => %o",this.receipts)

      this.selectedReceipt = this.receipts.find(it=>it.id == _receiptId)
      log.debug("selectReceipt this.selectedReceipt => %o",this.selectedReceipt)

      await this.refreshRequiredMats()

    }
  }

  async getCraftingResultObjectByType(object,type){
    log.debug("getCraftingResultObjectByType object => %o",object)
    log.debug("getCraftingResultObjectByType type => %o",type)

    switch (type) {
      case 0:

        const tokenInfos = (await this.freeCityContractService.getTokenInfoAndBalance(
          [object.resultAddress],
          this.account,
          true
        ))[0]

        object.resultTokenInfos = tokenInfos

      break;
      case 1:

        const tokenUri = object.tokenUri
        // const self = object
        await fetch(tokenUri)
        .then(function(response) {
          return response.json();
        })
        .then(function(_json) {
          log.debug("_json => %o",_json)
          object.mediaJson = _json
          if(_json.animation_url) object.media = "video"
          else object.media = "iamge"
        });

      break;
      default:
      break;
    }

    return object

  }

  async refreshRequiredMats(){
    this.selectedReceipt.isMatsLoading = true


    log.debug("before getCraftingResultObjectByType this.selectedReceipt => %o",this.selectedReceipt)
    this.selectedReceipt = await this.getCraftingResultObjectByType(this.selectedReceipt,this.selectedReceipt.resultType)
    log.debug("after getCraftingResultObjectByType this.selectedReceipt => %o",this.selectedReceipt)


    const matIds:any = await this.getMaterialIds() // required mat ids

    const mats = []
    for (const materialId of matIds) {
      const material = await this.getMaterials(materialId)

      log.debug("material => %o",material)
      mats.push(material)
    }
    log.debug("mats => %o",mats)

    this.selectedReceipt.materials = mats
    log.debug("this.selectedReceipt.materials => %o",this.selectedReceipt.materials)

    this.selectedReceipt.isAllApproveRequiredMats = this.selectedReceipt.materials.find(it => it.isApproved == false) ? false : true
    log.debug("this.selectedReceipt.isAllApproveRequiredMats => %o",this.selectedReceipt.isAllApproveRequiredMats)

    this.selectedReceipt.notApprovedRequiredMatsAmount = this.selectedReceipt.materials.filter(it => it.isApproved == false).length
    log.debug("this.selectedReceipt.notApprovedRequiredMatsAmount => %o",this.selectedReceipt.notApprovedRequiredMatsAmount)

    // log.debug("this.selectedConverter => %o",this.selectedConverter)
    // this.selectedReceipt.isEnoughConverterEnergy = (this.selectedConverter && this.selectedConverter.infos && (this.selectedConverter.infos.energy >= this.selectedReceipt.energyConsume) )? true : false
    // log.debug("this.selectedReceipt.isEnoughConverterEnergy => %o",this.selectedReceipt.isEnoughConverterEnergy)

    this.selectedReceipt.isEnoughRequiredMats = this.selectedReceipt.materials.find(it => it.isEnough == false) ? false : true
    log.debug("this.selectedReceipt.isEnoughRequiredMats => %o",this.selectedReceipt.isEnoughRequiredMats)

    this.selectedReceipt.isMatsLoading = false
  }


  async getConverterEnergy(){
    const stationAddress = this.craftingStation.stationAddress
    const converterEnergy = await this.freeCityContractService.getConverterEnergy(
      stationAddress,
      this.selectedConverter.tokenIdNumber,
    )
    log.debug("getConverterEnergy converterEnergy => %o",converterEnergy)
    const _converterEnergy = Number(converterEnergy)
    log.debug("getConverterEnergy _converterEnergy => %o",_converterEnergy)

    return _converterEnergy / 100.0
  }

  async getConverterLevel(){
    const stationAddress = this.craftingStation.stationAddress
    const converterLevel = await this.freeCityContractService.getConverterLevel(
      stationAddress,
      this.selectedConverter.tokenIdNumber,
    )
    log.debug("getConverterLevel converterLevel => %o",converterLevel)
    const _converterLevel = Number(converterLevel)
    log.debug("getConverterLevel _converterLevel => %o",_converterLevel)

    return _converterLevel
  }

  async getConverterLevelReduce(){
    const stationAddress = this.craftingStation.stationAddress
    const converterLevelReduce = await this.freeCityContractService.getConverterLevelReduce(
      stationAddress,
      this.selectedConverter.tokenIdNumber,
    )
    log.debug("getconverterLevelReduce converterLevelReduce => %o",converterLevelReduce)
    const _converterLevelReduce = Number(converterLevelReduce)
    log.debug("getconverterLevelReduce _converterLevelReduce => %o",_converterLevelReduce)

    return _converterLevelReduce / 100.0
  }

  async getConverterExps(){
    const stationAddress = this.craftingStation.stationAddress
    const converterExps = await this.freeCityContractService.getConverterExps(
      stationAddress,
      this.selectedConverter.tokenIdNumber,
    )
    log.debug("getConverterExps converterExps => %o",converterExps)
    return Number(converterExps)
  }

  async getConverterInfo(tokenIdNumber = undefined){
    const stationAddress = this.craftingStation.stationAddress

    const _tokenIdNumber = (tokenIdNumber != undefined ? tokenIdNumber : this.selectedConverter.tokenIdNumber)
    log.debug("canClaim _tokenIdNumber => %o",_tokenIdNumber)

    const converterInfo = await this.freeCityContractService.getConverterInfo(
      stationAddress,
      _tokenIdNumber,
    )
    log.debug("getConverterInfo converterInfo => %o",converterInfo)
    const currentOrder = {
      amount: Number(converterInfo.currentOrder.amount),
      claimed: converterInfo.currentOrder.claimed,
      converterTokenId: Number(converterInfo.currentOrder.converterTokenId),
      endAtBlock: Number(converterInfo.currentOrder.endAtBlock),
      id: Number(converterInfo.currentOrder.id),
      minter: converterInfo.currentOrder.minter,
      owner: converterInfo.currentOrder.owner,
      recipeId: Number(converterInfo.currentOrder.recipeId),
      resultAddress: converterInfo.currentOrder.resultAddress,
      resultTokenId: Number(converterInfo.currentOrder.resultTokenId),
      resultType: converterInfo.currentOrder.resultType,
      startAtBlock: Number(converterInfo.currentOrder.startAtBlock),
      tokenUri: converterInfo.currentOrder.tokenUri,
    }
    return {
      boosterIds: converterInfo.boosterIds,
      currentOrder: currentOrder,
      owner: converterInfo.owner,
      state: converterInfo.state,
    }
  }

  async getMaterialIds(){
    const stationAddress = this.craftingStation.stationAddress
    const receiptId = this.selectedReceipt.id
    log.debug("getMaterialIds receiptId => %o",receiptId)

    const materialIds = await this.freeCityContractService.getMaterialIds(
      stationAddress,
      receiptId,
    )
    log.debug("getMaterialIds materialIds => %o",materialIds)
    return materialIds
  }

  async getMaterials(materialId){
    log.debug("getMaterials materialId => %o",materialId)
    const stationAddress = this.craftingStation.stationAddress
    const materials = await this.freeCityContractService.getMaterials(
      stationAddress,
      materialId,
    )
    log.debug("getMaterials materials => %o",materials)

    const tokenInfos = (await this.freeCityContractService.getTokenInfoAndBalance(
      [materials.tokenAddress],
      this.account,
      true
    ))[0]
    log.debug("getMaterials tokenInfos => %o",tokenInfos)

    const amount = Number(ethers.utils.formatEther(materials.amount))
    return {
      id: Number(materials.id),
      tokenAddress: materials.tokenAddress,
      // amount: Number(materials.amount),
      amount: amount,
      tokenInfos: tokenInfos,
      isApproved: await this.checkApprovedRequiredMat(materials.tokenAddress),
      isEnough: Number(tokenInfos.balance) >= amount,
    }
  }

  async getBoosterReducePercent(boosterAddress, tokenId){
    log.debug("boosterReducePercent boosterAddress => %o",boosterAddress)
    log.debug("boosterReducePercent tokenId => %o",tokenId)
    const stationAddress = this.craftingStation.stationAddress
    const boosterReducePercent = await this.freeCityContractService.boosterReducePercent(
      stationAddress,
      boosterAddress,
      tokenId,
    )
    log.debug("boosterReducePercent boosterReducePercent => %o",boosterReducePercent)
    return Number(boosterReducePercent) / 100.0
  }

  async getBoosterLevel(boosterAddress, tokenId){
    log.debug("getBoosterLevel boosterAddress => %o",boosterAddress)
    log.debug("getBoosterLevel tokenId => %o",tokenId)
    const stationAddress = this.craftingStation.stationAddress
    const boosterLevel = await this.freeCityContractService.getBoosterLevel(
      stationAddress,
      boosterAddress,
      tokenId,
    )
    log.debug("getBoosterLevel boosterLevel => %o",boosterLevel)
    return Number(boosterLevel)
  }

  async getRefillInfos(){
    log.debug("getRefillInfos this.whitelistConverterAddress => %o",this.whitelistConverterAddress)
    const refillInfos = await this.freeCityContractService.getRefillInfos(
      this.energyStorage,
      this.whitelistConverterAddress,
    )
    log.debug("getRefillInfos refillInfos => %o",refillInfos)
    return {
      partnerAddress: refillInfos.partnerAddress,
      partnerPercent: Number(refillInfos.partnerPercent) / 100.0,
      refillAmount: Number(refillInfos.refillAmount) / 100.0,
      refillPrice: Number(ethers.utils.formatEther(refillInfos.refillPrice)),
      refillTokenAddress: refillInfos.refillTokenAddress,
    }

  }

  async selectRefillMultiplier(refillMultiplier){
    this.refillMultiplier = Number(refillMultiplier)
    log.debug("selectRefillMultiplier this.refillMultiplier => %o",this.refillMultiplier)
  }

  async toggleCustomRefillMultiplier(){
    this.isCustomRefillMultiplier = !this.isCustomRefillMultiplier
    if(!this.isCustomRefillMultiplier) this.refillMultiplier = 1
  }

  async refillEnergy(){
    this.isRefillEnergyLoading = true

    log.debug("refillEnergy this.refillMultiplier => %o",this.refillMultiplier)

    const energyStorageAddress = this.energyStorage
    await this.freeCityContractService.refillEnergy(
      energyStorageAddress,
      this.whitelistConverterAddress,
      this.selectedConverter.tokenIdNumber,
      this.refillMultiplier,
    ).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("refillEnergy events ",receipt.events)
      // let stakeEvent = receipt.events.find((e)=> e.event === 'StakeTokens')
      // await this.fetchOnce()
      // await this.refresh()

      this.isCustomRefillMultiplier = false
      this.refillMultiplier = 1

      await this.refreshSelectedConverterAndBoosterNFTs()
      this.singletonService.fire('success','Refill Energy success', 'Success Refill Energy')
      this.isRefillEnergyLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','Refill Energy failed', 'ERROR: '+ (error.data ? error.data.message : error.message))
    }).finally(()=>{
      this.isRefillEnergyLoading = false
    })
  }

  async forge(){


    if(!this.selectedReceipt || !this.selectedConverter){
      this.singletonService.fire('error','Please select converter and recipt')
      return
    }

    if(!this.selectedConverter.isReadyToForge){
      this.singletonService.fire('error','Converter is not ready to forge')
      return
    }
    if(!this.selectedReceipt.isAllApproveRequiredMats){
      this.singletonService.fire('error','Some mats are not approve to station')
      return
    }
    // if(!this.selectedReceipt.isEnoughConverterEnergy){
    //   this.singletonService.fire('error','Converter has not enough energy to forge with this recipt')
    //   return
    // }
    if(!this.selectedReceipt.isEnoughRequiredMats){
      this.singletonService.fire('error','Not enough materials to forge')
      return
    }


    this.isForgeLoading = true

    log.debug("forge this.craftingStation.stationAddress => %o",this.craftingStation.stationAddress)
    log.debug("forge this.selectedConverter.tokenIdNumber => %o",this.selectedConverter.tokenIdNumber)
    log.debug("forge this.selectedReceipt.id => %o",this.selectedReceipt.id)

    await this.freeCityContractService.forge(
      this.craftingStation.stationAddress,
      this.selectedConverter.tokenIdNumber,
      this.selectedReceipt.id,
    ).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("forge events ",receipt.events)

      await this.refreshSelectedConverterAndBoosterNFTs()
      await this.getUserLogOrders()

      this.singletonService.fire('success','forge success')
      this.isForgeLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','forge failed', 'ERROR: '+(error.data ? error.data.message : error.message))
    }).finally(()=>{
      this.isForgeLoading = false
    })

  }

  async canClaim(tokenIdNumber = undefined){
    log.debug("canClaim this.craftingStation.stationAddress => %o",this.craftingStation.stationAddress)

    const _tokenIdNumber = (tokenIdNumber != undefined ? tokenIdNumber : this.selectedConverter.tokenIdNumber)
    log.debug("canClaim _tokenIdNumber => %o",_tokenIdNumber)

    const _canClaim = await this.freeCityContractService.canClaim(
      this.craftingStation.stationAddress,
      _tokenIdNumber,
    )
    log.debug("canClaim _canClaim => %o",_canClaim)

    return _canClaim

  }

  async claim(){
    this.isClaimLoading = true
    // "inputs": [
    //   {
    //     "internalType": "uint256",
    //     "name": "_converterTokenId",
    //     "type": "uint256"
    //   }
    // ],
    // "name": "claim",
    // "outputs": [],
    // "stateMutability": "nonpayable",
    // "type": "function"

    await this.freeCityContractService.claim(
      this.craftingStation.stationAddress,
      this.selectedConverter.tokenIdNumber,
    ).then(async (transaction) => {

      let receipt = await transaction.wait()
      log.debug("claim events ",receipt.events)

      await this.refreshSelectedConverterAndBoosterNFTs()
      await this.getUserLogOrders()
      this.selectedReceipt = undefined

      this.singletonService.fire('success','claim success')
      this.isClaimLoading = false
    }).catch((error)=>{
      log.error(error)
      this.singletonService.fire('error','claim failed', 'ERROR: '+(error.data ? error.data.message : error.message))
    }).finally(()=>{
      this.isClaimLoading = false
    })
  }

  async getUserLogsLength(){
    const userLogsLength = await this.freeCityContractService.getUserLogsLength(
      this.craftingStation.stationAddress,
      this.account,
    )
    log.debug("getUserLogsLength userLogsLength => %o",userLogsLength)

    return Number(userLogsLength)
  }

  async getUserLogs(){
    const userLogs = await this.freeCityContractService.getUserLogs(
      this.craftingStation.stationAddress,
      this.account,
    )
    log.debug("getUserLogs userLogs => %o",userLogs)

    return Number(userLogs)
  }


  async getUserLogsWithOffset(_offset,_amount){
    log.debug("getUserLogsWithOffset _offset => %o",_offset)
    log.debug("getUserLogsWithOffset _amount => %o",_amount)

    const getUserLogsWithOffset = await this.freeCityContractService.getUserLogsWithOffset(
      this.craftingStation.stationAddress,
      this.account,
      _offset,
      _amount,
    )
    log.debug("getUserLogsWithOffset getUserLogsWithOffset => %o",getUserLogsWithOffset)

    return getUserLogsWithOffset
  }


  getOrderAsync(orderId){
    return new Promise(async (resolve, reject) => {
      this.freeCityContractService.getCraftingOrderById(
          this.craftingStation.stationAddress,
          orderId
        ).then(async (result)=>{
        // let receipt = await transaction.wait()
        // log.debug("approve receipt ", receipt)
        let userLog:any = {
          amount: Number(ethers.utils.formatEther(result.amount)),
          // amount: Number(result.amount),
          claimed: result.claimed,
          converterTokenId: Number(result.converterTokenId),
          endAtBlock: Number(result.endAtBlock),
          id: Number(result.id),
          minter: result.minter,
          owner: result.owner,
          recipeId: Number(result.recipeId),
          resultAddress: result.resultAddress,
          resultTokenId: Number(result.resultTokenId),
          resultType: result.resultType,
          startAtBlock: Number(result.startAtBlock),
          tokenUri: result.tokenUri,
        }
        resolve(userLog)
      }).catch((error)=>{
        log.error(error)
        resolve(error)
      })
    })
  }

  async getUserLogOrders(){
    this.isUserLogsLoading = true

    this.userLogsLength =  await this.getUserLogsLength()
    log.debug("getUserLogOrders this.userLogsLength => %o",this.userLogsLength)

    // const userLogs =  await this.getUserLogs()
    // log.debug("getUserLogOrders userLogs => %o",userLogs)

    const orderIds = await this.getUserLogsWithOffset(
      // this.userLogsOffset,
      // this.userLogsAmount
      this.userLogsAmount * (this.userLogsCurrentPage - 1),
      this.userLogsAmount
    )

    log.debug("getUserLogOrders orderIds => %o",orderIds)

    let promises = []
    for (const orderId of orderIds) {
      promises.push(this.getOrderAsync(orderId))
    }


    Promise.all(promises).then(async (results) => {
      log.debug("getUserLogOrders All data loaded... results: %o",results)


      this.userLogs = results
      Promise.all(this.userLogs.map(async (userLog): Promise<any> =>
        await this.getCraftingResultObjectByType(userLog,userLog.resultType)
      ))

      log.debug("getUserLogOrders All data loaded... this.userLogs: %o",this.userLogs)

    }).catch((errors) => {
      log.error("multipleApproveRequiredMats errors %o",errors)
    }).finally(()=>{
      this.isUserLogsLoading = false
    });

  }

  async userLogPageChanged(event){
    log.debug('userLogPageChanged event: %o' ,event);
    this.userLogsCurrentPage = event

    await this.getUserLogOrders()
  }


  playVDO(event) {
    log.debug('playVDO event : %o' , event);

    // const video = this.videoplayer.nativeElement

    var anyButton = event.target
    var buttonWrapper = anyButton.parentElement
    log.debug('playVDO buttonWrapper : %o' , buttonWrapper);

    var playButton = buttonWrapper.getElementsByClassName("img-play-vdo")[0];
    log.debug('playVDO playButton : %o' , playButton);

    var pauseButton = buttonWrapper.getElementsByClassName("img-pause-vdo")[0];
    log.debug('playVDO pauseButton : %o' , pauseButton);

    // var pauseButton = parentElement.getElementsByClassName("img-pause-vdo")[0];

    var parentElement = buttonWrapper.parentElement.parentElement
    log.debug('playVDO parentElement : %o' , parentElement);

    const video = parentElement.getElementsByClassName("video-player")[0];
    log.debug('playVDO video : %o' , video);


    // var playButton = document.getElementsByClassName("btn-action-vdo");
    // Event listener for the play/pause button
    // playButton.addEventListener("click", function() {
    if (video.paused == true) {
      // Play the video

      video.play();
      // this.videoplayer.nativeElement.play();

      // Update the button text to 'Pause'
      // playButton.innerHTML = "Pause";

      playButton.setAttribute("style", "display: none;");
      pauseButton.setAttribute("style", "display: block;");

      // playButton.hide()
      // pauseButton.show()
    } else {
      // Pause the video

      video.pause();
      // this.videoplayer.nativeElement.pause();

      // Update the button text to 'Play'
      // playButton.innerHTML = "Play";

      playButton.setAttribute("style", "display: block;");
      pauseButton.setAttribute("style", "display: none;");

      // playButton.show()
      // pauseButton.hide()
    }
    // });
  }



}
