<div class="main">
  <app-header-section></app-header-section>

  <section class="author-area">
    <div class="container max-w700px">
      <div class="row">
        <div class="d-flex align-items-center justify-content-between w-100 mb-5">
          <h3>Profile Settings</h3>
          <a [routerLink]="['/account']" class="btn btn-outline-light border">Preview</a>
        </div>
        <div *ngIf="userProfileInfos" style="width: 100%;">
            <div class="row d-flex justify-content-center text-center align-items-center">

            <!-- <div class="col-md-6 mb-4">
              <div class="txt-label-form font-weight-bold">Logo image</div>
              <p class="mt-1 mb-3"><small>Recommended 350px x 350px</small></p>
              <div class="">
                <label class="input-logo-image-label">
                  <div id="input-logo-image" class="show-img-preview" [ngStyle]="{'background-image': 'url(' + (userProfileInfos.profileImage | renderImageUrl: 'logo': true) + ')'}"></div>
                  <input (change)="handleFileInput_profileImage($event.target.files)" type="file" hidden>
                  <i class="icon-picture"></i>
                </label>
              </div>
            </div> -->

            <div class="col-md-12 mb-4">
              <div class="txt-label-form font-weight-bold">Banner image</div>
              <p class="mt-1 mb-3"><small>Recommended 1400px x 400px</small></p>
              <div class="">
                <label class="input-banner-image-label">
                  <div id="input-banner-image" [ngStyle]="{'background-image': 'url(' + (userProfileInfos.coverImage | renderImageUrl: 'banner': true) + ')'}"   class="show-img-preview"></div>
                  <input (change)="handleFileInput_bannerImage($event.target.files)" type="file" hidden>
                  <i class="icon-picture"></i>
                </label>
              </div>
            </div>

          </div>
          <div class="row item-form px-0 py-4">
            <div class="col-md-12">

              <div class="form-group mb-4">
                <div class="txt-label-form font-weight-bold mb-2">Name
                    <small>
                      <span class="text-warning">
                        - Must only contain uppercase and lowercase letters, numbers, and hyphens. (max size: 50)
                      </span>
                    </small>
                </div>
                <input [(value)]="userProfileInfos.name"
                  (input)="isValidSlugWithDelay()"
                  type="text" class="form-control"
                  [regExFormatType]="2" alphaNumberic>
                <small [ngClass]="(canUseSlug != undefined && canUseSlug) ? 'text-success' : 'text-danger'" id="used-slug-warning" [hidden]="canUseSlug == undefined || isLoadingValidateSlugg">
                  {{ (canUseSlug != undefined && canUseSlug) ? '(\u2714) This name is valid.' : '(\u2716) The name is already taken or invalid.'}}
                </small>
                <small [hidden]="!isLoadingValidateSlug">
                  <i class="fas fa-spinner fa-spin icon-w-h-25px mr-2 align-middle"></i> Loading...
                </small>
              </div>
              <div class="form-group mb-4">
                <div class="txt-label-form font-weight-bold mb-2">Email address</div>
                <input [(ngModel)]="userProfileInfos.email" type="email" class="form-control">
              </div>

              <div class="form-group mb-4">
                <div class="txt-label-form font-weight-bold mb-2">Bio</div>
                <textarea [(ngModel)]="userProfileInfos.bio" [maxlength]="140" class="form-control" rows="6"></textarea>
              </div>
              <div class="form-group mb-4 wrapper-input-group-custom">
                <div div class="txt-label-form font-weight-bold mb-2">Links </div>
                <div class="input-group mb-0 rounded-0 rounded-top">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-0">
                      <i class="icon-globe tx-20 mr-3"></i>
                      Website :
                    </span>
                  </div>
                  <input [(ngModel)]="userProfileInfos.socialmedia.website" [maxlength]="30"  type="text" class="form-control pl-2" placeholder="YourSite.com">
                </div>
                <div class="input-group mb-0 rounded-0  ">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-0">
                      <!-- <i class="icon-discord tx-20 mr-3"></i> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-discord tx-20 mr-3" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                      </svg>
                      https://www.discord.com/
                    </span>
                  </div>
                  <input [(ngModel)]="userProfileInfos.socialmedia.discord" [maxlength]="30" type="text" class="form-control" placeholder="YourDiscord">
                </div>
                <div class="input-group mb-0 rounded-0  ">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-0">
                      <i class="icon-social-facebook tx-20 mr-3"></i>
                      https://www.facebook.com/
                    </span>
                  </div>
                  <input [(ngModel)]="userProfileInfos.socialmedia.facebook" [maxlength]="30" type="text" class="form-control" placeholder="YourFacebook">
                </div>
                <div class="input-group mb-0 rounded-0 ">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-0">
                      <i class="icon-social-instagram tx-20 mr-3"></i>
                      https://www.instagram.com/
                    </span>
                  </div>
                  <input [(ngModel)]="userProfileInfos.socialmedia.instagram" [maxlength]="30" type="text" class="form-control" placeholder="YourInstagram">
                </div>
                <div class="input-group mb-0 rounded-0 rounded-bottom">
                  <div class="input-group-prepend">
                    <span class="input-group-text rounded-0">
                      <i class="icon-social-twitter tx-20 mr-3"></i>
                      https://www.twitter.com/
                    </span>
                  </div>
                  <input [(ngModel)]="userProfileInfos.socialmedia.twitter" [maxlength]="30" type="text" class="form-control" placeholder="YourTwitter">
                </div>
              </div>

              <div class="form-group mb-4">
                <div class="txt-label-form font-weight-bold mb-2">Wallet Address</div>
                <div class="input-wallet-profile position-relative">
                  <i (click)="this.singletonService.copyCode(userProfileInfos.publicAddress)" title="Copy Address" class="icon-docs mr-3 cursor-pointer"></i>
                  <input disabled value="{{userProfileInfos.publicAddress}}" type="text" class="form-control">
                </div>
              </div>
              <div class="text-center mt-5">
                <button [disabled]="canUseSlug == false" (click)="submitSetting()" class="btn btn-primary text-white">
                  Save
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <app-modal-menu></app-modal-menu>

</div>
