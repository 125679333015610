<section class="popular-collections-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Intro -->
                <div class="intro text-center">
                    <span>Authors</span>
                    <h3 class="mt-3 mb-0">Our Creators</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                </div>
            </div>
        </div>
        <div class="row items">
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_1.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Junaid Hasan</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_2.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Arham</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_3.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Yasmin</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_4.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>William</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_5.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Johnson</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_6.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Sara Scotch</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_7.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_7.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>John Deo</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 item">
                <div class="card no-hover text-center">
                    <div class="image-over">
                        <a href="author">
                            <img class="card-img-top" src="assets/img/author_8.jpg" alt="">
                        </a>
                        <!-- Seller -->
                        <a class="seller" href="author">
                            <div class="seller-thumb avatar-lg">
                                <img class="rounded-circle" src="assets/img/avatar_8.jpg" alt="">
                            </div>
                        </a>
                    </div>
                    <!-- Card Caption -->
                    <div class="card-caption col-12 p-0">
                        <!-- Card Body -->
                        <div class="card-body mt-4">
                            <a href="author">
                                <h5>Artnox</h5>
                            </a>
                            <a class="btn btn-bordered-white btn-smaller" href="#">Follow</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>