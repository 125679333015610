<div class="modal-header modal-header-primary pb-0" >
  <div>
  <h4 class="modal-title text-white-main mt-0">ลงทะเบียนเพื่อรับสิทธิ์</h4>
  <p class="pt-0 mt-0">สำหรับ {{nft.name}} (token id: {{this.nft.tokenIdNumber}})</p>
  </div>

  <!-- <h4 class="modal-title text-white-main">Edit Wallet</h4> -->
  <button type="button" class="close close py-3 pr-0 pr-sm-3 pl-3" (click)="modal.dismiss('Cross click')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" tabindex="0" ngbAutofocus  >

  <div class="w-100" *ngIf="isLoadingRedeemRewardsInfo">
    <div class="col-12 pt-5 pb-5 text-center">
      <div  class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <p class="text-center text-muted">Loading...</p>
    </div>
  </div>

  <div class="w-100" *ngIf="!isLoadingRedeemRewardsInfo">
    <hr/>
    <ng-container *ngIf="state == 0">
      <div class="term-condition">

        <p class="tx-13">
        <strong class="mb-1 d-flex">ข้อกำหนดและเงื่อนไขในการนำ YES NFT แลกรับ iPhone</strong>

        <span class="d-flex">
          <span class="mr-1">1</span>
          <span>
            ผู้เข้าร่วมแคมเปญสามารถเลือกรับ iPhone ที่ร้าน BaNANA หรือช่องทาง Delivery จากทาง YES ได้
          </span>
        </span>

        <span class="d-flex">
          <span class="mr-1">2</span>
          <span>
            กรณีเลือกรับ iPhone ที่ร้าน BaNANA ผู้เข้าร่วมแคมเปญจะต้องแสดงบัตรประชาชนและ QR Code ที่ได้รับจากการเข้าร่วมแคมเปญต่อเจ้าหน้าที่ ณ จุดบริการเพื่อแลกรับ iPhone
          </span>
        </span>

        <span class="d-flex">
          <span class="mr-1">3</span>
          <span>
            กรณีที่ผู้เข้าร่วมแคมเปญประสงค์นำ iPhone มาคืนหลังจากครบระยะเวลาการใช้งาน 365 วันเพื่อรับ “YES NFT” ผู้เข้าร่วมแคมเปญจะต้องยอมรับเงื่อนไข ดังนี้…
          </span>
        </span>

        <span class="d-flex pl-2">
          <span class="mr-1">3.1</span>
          <span>คืน iPhone ที่ iCare (สาขาที่เข้าร่วมแคมเปญ) ภายในระยะเวลา 30 วัน เพื่อรับ YES NFT กลับคืน</span>
        </span>

        <span class="d-flex pl-2">
          <span class="d-flex">
            <span class="mr-1">3.2</span>
             <span>ชำระค่าเสื่อมสภาพการใช้งาน iPhone 2%/เดือน (ตามระยะเวลาที่ใช้งาน) ณ ศูนย์บริการ iCare </span>
          </span>
          <span class="d-flex">
            * ลูกค้าที่ไม่ประสงค์คืน iPhone ไม่ต้องชำระค่าเสื่อมสภาพการใช้งาน
          </span>
          <span class="d-flex">
            ** ลูกค้าที่ไม่ประสงค์คืน iPhone จะไม่สามารถรับ YES NFT
          </span>
        </span>

        <span class="d-flex pl-2">
          <span class="mr-1">
            3.3
          </span>
          <span>
            ความเสียหายที่เกิดจากสาเหตุดังต่อไปนี้ ผู้เข้าร่วมแคมเปญจะต้องดำเนินการส่งซ่อมแซมและชำระเงินค่าบริการที่ศูนย์บริการ iCare เอง จึงจะสามารถคืน iPhone ได้
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.3.1</span>
          <span>
            กรณี iPhone ที่มีสภาพชำรุด หน้าจอแตก ร้าว หรืออุปกรณ์ตัวเครื่องชำรุด (ปัญหาทุกกรณีสามารถรับการซ่อมแซมและจ่ายค่าซ่อมแซมที่ศูนย์บริการได้)
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.3.2</span>
          <span>
            กรณี iPhone มีความผิดปกติของการใช้งาน เช่น การแสดงผลของเสียง การแสดงผลของหน้าจอ เป็นต้น (ปัญหาทุกกรณีสามารถรับการซ่อมแซมและจ่ายค่าซ่อมแซมที่ศูนย์บริการได้)
          </span>
        </span>

        <span class="d-flex pl-2">
          <span class="mr-1">3.4</span>
          <span>
            ความเสียหายที่เกิดจากสาเหตุดังต่อไปนี้ ผู้เข้าร่วมแคมเปญจะไม่สามารถคืน iPhone ได้ และไม่ได้อยู่ในความรับผิดชอบของบริษัทฯ
          </span>
        </span>

        <span class="d-flex pl-4">
          <span class="mr-1">3.4.1</span>
          <span>
            iPhone ที่ทำงานบกพร่องอันเกิดจากการใช้งานผิดวัตถุประสงค์ หรือใช้งานไม่ถูกวิธีตามที่ระบุไว้ที่กล่องสินค้า หรือในคู่มือการใช้งาน
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.2</span>
          <span>
            iPhone ที่มีสภาพชำรุด ได้แก่ แตก หัก บุบ งอ ยุบ ร้าว หรือมีชิ้นส่วนของ iPhone บางชิ้นขาดหายไป
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.3</span>
          <span>
            iPhone ที่มีความเสียหายอันเนื่องมาจากการเกิดอุบัติเหตุตก หรือหล่นน้ำ และภัยธรรมชาติที่เกิดขึ้น
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.4</span>
          <span>
            iPhone ที่มีสภาพเกิดจากการเก็บรักษาที่ไม่ถูกต้องทำให้ iPhone เกิดความเสียหาย เช่น มีคราบน้ำ รอยสนิม ตะไคร่น้ำ หรือแผงวงจรมีรอยไหม้
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.5</span>
          <span>
            iPhone ที่มีสภาพความเสียหายอันเกิดการจากขนส่งอันเนื่องจากลูกค้าส่ง iPhone มาคืน
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.6</span>
          <span>
            iPhone ที่มีความเสียหายเกิดจากการดัดแปลงใด ๆ หรือ การซ่อมที่กระทำโดยบุคคลอื่นที่ไม่ใช่เจ้าหน้าที่ของศูนย์บริการ iCare
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.7</span>
          <span>
            iPhone เสื่อมสภาพตามการใช้งาน หรือวัสดุสิ้นเปลือง เช่น แบตเตอรี่ หม้อแปลงไฟฟ้า วัสดุในการลองฐานตัวสินค้า วัสดุหุ้มสินค้า และอื่น ๆ จะไม่ได้อยู่ในความรับผิดชอบของบริษัทฯ
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.8</span>
          <span>
            บริษัทฯ จะไม่รับผิดชอบในส่วนของข้อมูลดิจิตอลใด ๆ อันเกิดจากการทำงานบกพร่อง หรือการดำเนินการซ่อม ผลิตภัณฑ์ รวมถึงข้อมูลดิจิตอลที่อยู่ภายในตัวสินค้า ในกรณีที่มีความเสียหายกับข้อมูล หรือข้อมูลสูญหาย
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.9</span>
          <span>
            บริษัทฯ ขอสงวนสิทธิ์ในการไม่รับคืน iPhone ที่ไม่สามารถบ่งชี้ตัวสินค้า หรือสาระสำคัญของตัว iPhone ได้ เช่น สติกเกอร์การรับประกันฉีกขาด หมายเลขเครื่องถูกขีด หรือแก้ไขจนไม่สามารถมองเห็นสาระสำคัญ
          </span>
        </span>
        <span class="d-flex pl-4">
          <span class="mr-1">3.4.10</span>
          <span>
            ทางบริษัทฯ ไม่รับเปลี่ยนหรือรับคืน iPhone ที่เกิดจากความไม่พอใจ หรือการสั่งซื้อที่เกิดจากความผิดพลาดของลูกค้า ไม่ว่ากรณีใด ๆ ทั้งสิ้น
          </span>
        </span>

        <span class="d-flex">
          <span class="mr-1">4</span>
          <span>
            ในกรณีที่ผู้เข้าร่วมแคมเปญไม่นำ iPhone มาคืนภายใน 30 วัน หลังจากครบกำหนดถือครอง 365 วัน จะไม่สามารถใช้ YES NFT เพื่อเคลมเป็นเหรียญ YES Token ได้ โดยกรรมสิทธิ์ของ iPhone จะตกเป็นของลูกค้าโดยทันที
          </span>
        </span>

        </p>

      </div>
      <div class="row text-center">
        <div class="col-md-12 text-center">
          <button class="mt-5 btn   btn-primary   " (click)="accept()"   >
            ยอมรับเงื่อนไขและข้อตกลง
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="state == 1">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="row">
            <div class="col-lg-12 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">อีเมล <span class="text-red">*</span></label>
                <input type="text"
                      name="email"
                      placeholder="กรอกข้อมูลอีเมล"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                      formControlName="email"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.email.errors && f.email.errors.required">ต้องไม่เป็นค่าว่าง</p>
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.email.errors && f.email.errors.email">ต้องเป็นรูปแบบอีเมลล์</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">ชื่อ <span class="text-red">*</span></label>
                <input type="text"
                      name="firstname"
                      placeholder="กรอกข้อมูลชื่อ"
                      [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                      formControlName="firstname"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.firstname.errors && f.firstname.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
            <div class="col-lg-6 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">นามสกุล <span class="text-red">*</span></label>
                <input type="text"
                      name="lastname"
                      placeholder="กรอกข้อมูลนามสกุล"
                      [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                      formControlName="lastname"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.lastname.errors && f.lastname.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 px-0 px-lg-3">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">เลขบัตรประชาชน <span class="text-red">*</span></label>
                <input type="text"
                      name="nationalID"
                      placeholder="กรอกข้อมูลเลขบัตรประชาชน"
                      [ngClass]="{ 'is-invalid': submitted && f.nationalID.errors }"
                      formControlName="nationalID"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.nationalID.errors && f.nationalID.errors.required">ต้องไม่เป็นค่าว่าง</p>
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.nationalID.errors && f.nationalID.errors.pattern">รูปแบบไม่ถูกต้อง</p>
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.nationalID.errors && f.nationalID.errors.minlength">ความยาวไม่ถูกต้อง</p>
              </div>
            </div>
            <div class="col-lg-6 px-0 px-lg-3">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">เบอร์โทรติดต่อ <span class="text-red">*</span></label>
                <input type="text"
                      name="phone"
                      placeholder="กรอกข้อมูลเบอร์โทรติดต่อ"
                      [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                      formControlName="phone"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.phone.errors && f.phone.errors.required">ต้องไม่เป็นค่าว่าง</p>
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.phone.errors && f.phone.errors.pattern">รูปแบบไม่ถูกต้อง</p>
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.phone.errors && f.phone.errors.minlength">ความยาวไม่ถูกต้อง</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 px-0 px-lg-3">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">ประเภทสินค้า <span class="text-red">*</span></label>
                <div class="dropdown">
                  <button
                    [ngClass]="item ? '': 'invalid-btn'"
                    class="btn dropdown-toggle w-100 shadow-none border" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span>{{item ? item : '-- กรุณาเลือกประเภทสินค้า --'}}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-full">
                    <a *ngFor="let _item of items" class="dropdown-item" (click)="changeItemValue(_item)">
                      {{_item}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 px-0 px-lg-3" *ngIf="item">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">สีสินค้า <span class="text-red">*</span></label>
                <div class="dropdown">
                  <button
                    [ngClass]="color ? '': 'invalid-btn'"
                    class="btn dropdown-toggle w-100 shadow-none border" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span>{{color ? color : '-- กรุณาเลือกสีสินค้า --'}}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-full">
                    <a *ngFor="let _color of colors" class="dropdown-item" (click)="changeColorValue(_color)">
                      {{_color}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 px-0 px-lg-3">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">วิธีการรับสินค้า <span class="text-red">*</span></label>
                <div class="dropdown">
                  <button class="btn dropdown-toggle w-100 shadow-none border" type="button" data-toggle="dropdown" aria-expanded="false">
                    <span>{{method}}</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-full">
                    <a *ngFor="let _method of METHOD_OBJECTS" class="dropdown-item" (click)="changeMethodValue(_method)">
                      {{_method}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="method == 'Delivery'">
            <div class="col-lg-12 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">ที่อยู่ <span class="text-red">*</span></label>
                <input type="text"
                      name="address"
                      placeholder="กรอกข้อมูลที่อยู่"
                      [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                      formControlName="address"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.address.errors && f.address.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="method == 'Delivery'">
            <div class="col-lg-3 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">จังหวัด <span class="text-red">*</span></label>
                <input type="text"
                      name="province"
                      placeholder="กรอกจังหวัด"
                      [ngClass]="{ 'is-invalid': submitted && f.province.errors }"
                      formControlName="province"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.province.errors && f.province.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
            <div class="col-lg-3 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">อำเภอ <span class="text-red">*</span></label>
                <input type="text"
                      name="district"
                      placeholder="กรอกอำเภอ"
                      [ngClass]="{ 'is-invalid': submitted && f.district.errors }"
                      formControlName="district"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.district.errors && f.district.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
            <div class="col-lg-3 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">ตำบล <span class="text-red">*</span></label>
                <input type="text"
                      name="subDistrict"
                      placeholder="กรอกตำบล"
                      [ngClass]="{ 'is-invalid': submitted && f.subDistrict.errors }"
                      formControlName="subDistrict"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.subDistrict.errors && f.subDistrict.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
            <div class="col-lg-3 px-0 px-lg-3">
              <div class="form-group mb-4">
                <label class="text-white-sub mb-1">รหัสไปรษณีย์ <span class="text-red">*</span></label>
                <input type="text"
                      name="zipcoode"
                      placeholder="กรอกรหัสไปรษณีย์"
                      [ngClass]="{ 'is-invalid': submitted && f.zipcoode.errors }"
                      formControlName="zipcoode"
                />
                <p class="text-danger mt-1 pt-0 tx-12" *ngIf="submitted && f.zipcoode.errors && f.zipcoode.errors.required">ต้องไม่เป็นค่าว่าง</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 px-0 px-lg-3">
              <div class="form-group mb-4" >
                <label class="text-white-sub mb-1">ข้อความเพิ่มเติม</label>
                <textarea
                      name="note"
                      placeholder="กรอกข้อความเพิ่มเติม"
                      [ngClass]="{ 'is-invalid': submitted && f.note.errors }"
                      formControlName="note"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-lg-12 text-center px-0 px-lg-3">
              <button class="mt-3 mb-2 btn  btn-primary" [disabled]="isLoadingSubmit" >
                <span *ngIf="isLoadingSubmit" class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                  บันทีกข้อมูล
              </button>
            </div>
          </div>

        </form>

    </ng-container>
    <ng-container *ngIf="state == 2">
      <!-- <div class="d-flex justify-content-center">

        <div class="bg-white p-2" style="width: 250px;height: 250px"  >
          <img src="assets/img/campaign/qrcode-mock.png" class="w-100" />
        </div>



      </div> -->
      <div class="d-flex justify-content-center">
        <img src="assets/img/icons/mail.png" width="128"/>
      </div>

      <div class="mt-4 text-center">
        <p class="pt-2 pb-2" style="font-size:18px;">
          <!-- กรุณานำ QR Code ข้างต้นแสดงให้กับเจ้าหน้าที่ที่เกี่ยวข้องเพื่อรับสิทธิ์ในการรับสินค้า -->
          ระบบได้ทำการบันทึกข้อมูลของท่านแล้ว กรุณาตรวจสอบอีเมล์ของท่าน
          <br>
          email: {{this.redeemedEmail}}
        </p>
      </div>
    </ng-container>
  </div>

</div>
