<div class="main">
    <app-header-section></app-header-section>
    <app-breadcrumb-authors></app-breadcrumb-authors>
    <app-authors-section></app-authors-section>
    <app-top-seller-two-section></app-top-seller-two-section>
    <app-footer-section></app-footer-section>
    <app-modal-search></app-modal-search>
    <app-modal-menu></app-modal-menu>
    <app-scrollup></app-scrollup>
</div>
