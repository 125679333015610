import { Router } from '@angular/router';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-listing',
  templateUrl: './pro-listing.component.html',
  styleUrls: ['./pro-listing.component.scss']
})
export class ProListingComponent implements OnInit {
  supportChains:any = []
  blockchain

  nftAddress
  tokenId

  constructor(
    public singletonService:SingletonService,
    private router:Router,
  ) { }

  async ngOnInit() {
    this.supportChains = await this.singletonService.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)
    // this.blockchain = await this.supportChains.find( it => it.networkAbbr == 'bkctestnet')
  }

  selectBlockchain(chain){
    this.blockchain = chain
    let element = document.getElementById("dropdown-list")
    if(element) element.classList.remove("show")

  }

  canPreview(){
    let canPreview = this.nftAddress && this.tokenId && this.blockchain
    return canPreview
  }

  preview(){
    // alert("preview")
    this.router.navigate(['/nft/' + this.nftAddress + '/' + this.tokenId],{
      queryParams: {
        chain: this.blockchain.networkAbbr
      }
    })
  }
}
