import { FreeCityContractService } from './../../services/free-city-contract.service';
import { CurrencyService } from './../../services/currency.service';
import { LazyLoaderService } from './../../services/lazy-loader.service';
import { SelectedFiltersService } from './../../services/selected-filters.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import {TOKEN_TYPE} from "../../constant/valuables";

import { Logger } from '../../services/logger.service';
import { MiningService } from 'src/app/services/mining.service';
const log = new Logger('NftCardListComponent'); // log.debug

@Component({
  selector: 'app-nft-card-list',
  templateUrl: './nft-card-list.component.html',
  styleUrls: ['./nft-card-list.component.scss']
})
export class NftCardListComponent implements OnInit {

  @Input() assets:any = []
  @Input() collection:any = undefined
  @Input() totalResults:any = 0

  @Input() enabledSearch = true
  @Input() enabledSort = true
  @Input() enabledGrid = true

  @Input() isShowTotalResults = true

  @Input() enabledLazyLoader:any = true
  @Input() isFetchingAsset:any = false

  @Input() isShowSelectedFilterCardSection:any = true



  isLoading = false

  topCollections:any = []
  paymentTokens:any = []

  searchText

  // selectedSortingDisplay:any = 'Created: Recently'
  selectedSortingDisplay:any = 'Updated: Recently'
  // selectedSortingDisplay:any = 'Price: Low to High'



  displayMultiGrid: boolean = false;

  supportChains:any = []

  // == start lazy loader params ==
  isLazyLoading = false
  loadedAll = false
  scrollEvent
  scroll_position
  isScrollBottom = false
  // == end lazy loader params ==

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    public selectedFiltersService:SelectedFiltersService,
    public lazyLoaderService:LazyLoaderService,
    private router:Router,
    private currencyService:CurrencyService,
    private freeCityContractService:FreeCityContractService,
    private miningService:MiningService,
  ) {

  }

  async ngOnInit(){
    log.debug("enabledLazyLoader => %o",this.enabledLazyLoader)
    log.debug("assets => %o",this.assets)
    log.debug("collection => %o",this.collection)
    log.debug("xx this.selectedFiltersService.selectedFilters.sortParams => %o",this.selectedFiltersService.selectedFilters.sortParams)

    this.supportChains = await this.singletonService.supportChains

    if(this.enabledLazyLoader) this.handleScroll(); // enable lazyloader



    const self = this
    setTimeout(async () => {
      this.assets.map( async (asset) => {
        asset.text = 'hello world'
        if(asset.lastestOrder) asset.lastestOrder.acceptedTokenImage = await this.currencyService.getPaymentTokenImage(asset.lastestOrder.acceptedTokenAddr,asset.chain)
      })
      log.debug("xx assets => %o",this.assets)

      await self.setDefaultGridDisplay()
    }, 1000);
  }

  playVDO(event) {
    log.debug('playVDO event : %o' , event);

    // const video = this.videoplayer.nativeElement

    var anyButton = event.target
    var buttonWrapper = anyButton.parentElement
    log.debug('playVDO buttonWrapper : %o' , buttonWrapper);

    var playButton = buttonWrapper.getElementsByClassName("img-play-vdo")[0];
    log.debug('playVDO playButton : %o' , playButton);

    var pauseButton = buttonWrapper.getElementsByClassName("img-pause-vdo")[0];
    log.debug('playVDO pauseButton : %o' , pauseButton);

    // var pauseButton = parentElement.getElementsByClassName("img-pause-vdo")[0];

    var parentElement = buttonWrapper.parentElement.parentElement
    log.debug('playVDO parentElement : %o' , parentElement);

    const video = parentElement.getElementsByClassName("video-player")[0];
    log.debug('playVDO video : %o' , video);


    // var playButton = document.getElementsByClassName("btn-action-vdo");
    // Event listener for the play/pause button
    // playButton.addEventListener("click", function() {
      if (video.paused == true) {
        // Play the video

        video.play();
        // this.videoplayer.nativeElement.play();

        // Update the button text to 'Pause'
        // playButton.innerHTML = "Pause";

        playButton.setAttribute("style", "display: none;");
        pauseButton.setAttribute("style", "display: block;");

        // playButton.hide()
        // pauseButton.show()
      } else {
        // Pause the video

        video.pause();
        // this.videoplayer.nativeElement.pause();

        // Update the button text to 'Play'
        // playButton.innerHTML = "Play";

        playButton.setAttribute("style", "display: block;");
        pauseButton.setAttribute("style", "display: none;");

        // playButton.show()
        // pauseButton.hide()
      }
    // });
  }

  async setDefaultGridDisplay(){
    const displayMultiGrid = await localStorage.getItem("filter-grid-display")
    // log.debug("displayMultiGrid => %o",displayMultiGrid)
    if(displayMultiGrid == 'multi-grid') await this.toggleDisplayMultiGrid('multi')
  }

  getImageSupportChainByAbbr(networkAbbr){
    const supportChain = this.supportChains.find(it => it.networkAbbr == networkAbbr)
    return supportChain ? supportChain.imgUrl: ''
  }

  async toggleDisplayMultiGrid(value) {
    // log.debug("toggleDisplayMultiGrid value => %o",value)

    var wrapperNft = document.querySelector(".nft-items-wrapper");
    log.debug("toggleDisplayMultiGrid wrapperNft => %o",wrapperNft)

    if(wrapperNft){
      if(value == 'multi') {
        // log.debug("toggleDisplayMultiGrid 1",)
        this.displayMultiGrid = true;
        wrapperNft.classList.add("multi-grid");
        await localStorage.setItem("filter-grid-display","multi-grid")
      } else {
        // log.debug("toggleDisplayMultiGrid 2",)
        this.displayMultiGrid = false;
        wrapperNft.classList.remove("multi-grid");
        await localStorage.removeItem("filter-grid-display")
      }
    }
  }

  addSorting(sortingParams,displayText){
    this.selectedFiltersService.selectedFilters.sortParams = [sortingParams]
    this.selectedSortingDisplay = displayText

    this.selectedFiltersService.selectedFiltersChangedSubject.next(true)
    log.debug("this.selectedFiltersService.selectedFilters => %o",this.selectedFiltersService.selectedFilters)
  }


  handleScroll(): void {
    window.onscroll = () => this.detectBottom();
  }

  async detectBottom(){
    let scroll_direction = await (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
    this.scroll_position = await (document.body.getBoundingClientRect()).top;
    if (scroll_direction == 'down'){ // downscroll
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.isScrollBottom = true
        log.debug("xxxxxxxxxxxxx detectBottom")

        // if(this.tab == 'activity'){ // check for multi-tab pages

            if(!this.isLazyLoading){ // check for lazy loader is working
              await this.lazyLoader()
            }

        // }else{
        //   log.debug("this tab isn't activity...")
        // }
      }
    }else{ // upscroll
      // donothing
    }
  }

  async lazyLoader(){
    this.isLazyLoading = await true
    setTimeout(async () => {
      await this.lazyLoaderService.lazyLoadSubject.next(true)
      // await this.fetchAsset()
      this.isLazyLoading = await false
    }, 1000);
  }

  async jumpToNFTShow(nftAddress,tokenId,chain){
    let path = '/nft/'+ nftAddress +'/'+ tokenId
    let href = this.router.url;
    if(href.startsWith('/nft/')){ // same route
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([path],{ queryParams: { chain: chain }});
      });
    }else{
      // this.router.navigate([path],{ queryParams: { chain: chain }});

      const url = this.router.serializeUrl(this.router.createUrlTree([path], { queryParams: { chain: chain } }));
      window.open(url, '_blank')
    }
  }

  testAlert() {
    alert('click function work!!!');
  }

  async buy(asset){
    log.debug("buy asset : %o",asset)

    await this.singletonService.buyNFTFreeCityMarket(asset)
  }

}
