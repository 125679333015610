import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-redeem-confirm-modal',
  templateUrl: './redeem-confirm-modal.component.html',
  styleUrls: ['./redeem-confirm-modal.component.scss']
})
export class RedeemConfirmModalComponent implements OnInit {

  @Output() dataService = new EventEmitter();
  @Input() gasha;
  @Input() tokenId;
  @Input() amount;
  @Input() tokenName;
  @Input() image;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  async accept(){
    this.dataService.emit("accepted");
    this.modal.close()
  }
  async dismiss(){
    this.dataService.emit("dismiss");
    this.modal.close()
  }

}
