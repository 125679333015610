import { CurrencyService } from './../../services/currency.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from './../../services/backend.service';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';

import { Logger } from './../../services/logger.service';
import { environment } from 'src/environments/environment';
const log = new Logger('RankingsComponent'); // log.debug

@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {

  resolution = {
    value: '7d',
    displayText: 'Last 7 days',
  }
  selectedChain

  currentChain

  supportChains:any = []

  collections:any = []
  paymentTokens:any = []
  currentChainETH

  chainETHList:any = []

  isLoading = false

  defaultNetworkAbbr:any = environment.networkAbbr


  // == start lazy loader params ==
  limit = 30
  page = 1
  isLazyLoading = false
  loadedAll = false
  scrollEvent
  scroll_position
  isScrollBottom = false
  // == end lazy loader params ==

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private route: ActivatedRoute,
    private router:Router,
    private currencyService:CurrencyService,
  ) {

  }

  async ngOnInit() {
    this.handleScroll(); // enable lazyloader
    this.supportChains = await this.singletonService.supportChains.filter(supportChain => supportChain.enabled && supportChain.soon != true)
    // const defaultChainAbbr = await this.singletonService.getChainAbbr()

    await this.loadChainETHList()
    await this.reloadData()
  }

  async loadChainETHList(){
    // this.currentChain = (await this.singletonService.getCurrentChain())
    // log.debug("this.currentChain => %o",this.currentChain)

    // this.paymentTokens = await this.backendService.getPaymentTokensByChainAbbr(this.currentChain ? this.currentChain.networkAbbr : this.defaultNetworkAbbr)
    // log.debug("this.paymentTokens => %o",this.paymentTokens)
    // this.currentChainETH = this.paymentTokens.find(it => it.address == "0x0000000000000000000000000000000000000000")
    // log.debug("this.currentChainETH => %o",this.currentChainETH)

    // log.debug("this.supportChains => %o",this.supportChains)
    // for (const supportChain of this.supportChains) {
    //   // if(supportChain.enabled && supportChain.soon != true){
    //     const paymentTokens:any = await this.backendService.getPaymentTokensByChainAbbr(supportChain.networkAbbr)
    //     log.debug("paymentTokens => %o",paymentTokens)
    //     const chainETH = paymentTokens.find(it => it.address == "0x0000000000000000000000000000000000000000")
    //     this.chainETHList.push(chainETH)
    //   // }
    // }
    // log.debug("this.chainETHList => %o",this.chainETHList)

    this.chainETHList = await this.singletonService.getChainETHList()
    log.debug("loadChainETHList this.chainETHList => %o",this.chainETHList)
  }

  async resetDatas(){
    this.loadedAll = false
    this.page = 1
    this.collections = []
  }

  async reloadData(){
    this.isLoading = true
    await this.resetDatas()
    await this.fetchCollections()
    this.isLoading = false
  }

  async fetchCollections(){
    let res = await this.backendService.getCollectionsInfoRanking(
      this.resolution.value, // resolution
      this.selectedChain ? this.selectedChain.networkAbbr : undefined, // chains
      undefined, // sortBy
      this.limit,
      this.page,
    )
    log.debug("fetchCollections getCollectionsInfoRanking res => %o",res)

    let newResults = await Promise.all(res.results.map(async (it): Promise<any> => {
      // init chainETH

      it.collection.currentChainETH = await this.chainETHList.find(chainETH => chainETH.chain == it.collection.chain)

      if(it.collection.statistic && it.collection.statistic.floors){
        it.collection.statistic.floors = await this.currencyService.addValuesToStatisticArray(it.collection.statistic.floors)
        if(it.collection.statistic.floors.length) it.collection.floorPrice = it.collection.statistic.floors.reduce((prev, curr) => (prev.usd < curr.usd ? prev : curr))
      }
      if(it.collection.statistic && it.collection.statistic.volumns){
        it.collection.statistic.volumns = await this.currencyService.addValuesToStatisticArray(it.collection.statistic.volumns)
        if(it.collection.statistic.volumns.length){
          it.collection.tradeVolumn = {
            usd:it.collection.statistic.volumns.reduce(function (acc, obj) { return acc + obj.usd; }, 0),
            thb:it.collection.statistic.volumns.reduce(function (acc, obj) { return acc + obj.thb; }, 0),
          }
        }
      }

      it.volumnThb = await this.currencyService.changeUsdToThb(it.volumnUsd ? it.volumnUsd : 0)
      it.floorThb = await this.currencyService.changeUsdToThb(it.floorUsd ? it.floorUsd : 0)

      log.debug("fetchCollections it => %o",it)

      return it;
    }));

    this.collections = [...this.collections,...newResults]
    if(newResults.length < this.limit) this.loadedAll = true

    log.debug("this.collections => %o",this.collections)
  }

  async selectResolution(value,displayText){
    this.resolution = {
      value: value,
      displayText: displayText,
    }
    await this.reloadData()
  }

  async selectChain(chain){
    this.selectedChain = chain
    await this.reloadData()
  }

  handleScroll(): void {
    window.onscroll = () => this.detectBottom();
  }

  async detectBottom(){
    // log.debug("xxxxxxxxxxxxx detectBottom 11111111111111111111111")
    let scroll_direction = await (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
    this.scroll_position = await (document.body.getBoundingClientRect()).top;
    if (scroll_direction == 'down'){ // downscroll
      // log.debug("xxxxxxxxxxxxx detectBottom 2222222222222222222222222")
      // let x = window.innerHeight
      // let y = window.scrollY
      // let z = document.body.offsetHeight
      // log.debug("xxxxxxxxxxxxx detectBottom x => %o",x )
      // log.debug("xxxxxxxxxxxxx detectBottom y => %o",y )
      // log.debug("xxxxxxxxxxxxx detectBottom z => %o",z )

      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        // log.debug("xxxxxxxxxxxxx detectBottom 3333333333333333333333333333")
        this.isScrollBottom = true
        log.debug("xxxxxxxxxxxxx detectBottom")

          if(!this.isLazyLoading){ // check for lazy loader is working
            await this.lazyLoader()
          }
      }
    }else{ // upscroll
      // donothing
    }
  }

  async lazyLoader(){
    this.isLazyLoading = await true
    setTimeout(async () => {
      await this.page++
      if(!this.loadedAll) await this.fetchCollections()
      this.isLazyLoading = await false
    }, 1000);
  }

}
