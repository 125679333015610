import { SingletonService } from './../../services/singleton.service';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit } from '@angular/core';

import { Logger } from './../../services/logger.service';
const log = new Logger('SettingsComponent'); // log.debug

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  userProfileInfos:any

  logoImage:any
  coverImage:any

  canUseSlug:any
  oldName:any

  constructor(
    private backendService:BackendService,
    private singletonService:SingletonService,

  ) { }

  async ngOnInit() {
    this.singletonService.getCurrentUserProfileInfos().then((res:any) => {
      log.debug("xxxx res => %o",res)
      if(res){
        this.userProfileInfos = res
        this.oldName = this.userProfileInfos.name
        if(!this.userProfileInfos.socialmedia){
          this.userProfileInfos.socialmedia = {
            discord: "",
            facebook: "",
            instagram: "",
            twitter: "",
            website: "",
          }
        }
        log.debug("xxxx this.userProfileInfos => %o",this.userProfileInfos)

        // this.userProfileInfos = res.results.map( it => {
        //   it.collectionName = it.name
        //   it.nftAmount = 'x'
        //   it.collectionImageUrl = it.logoImage
        //   it.collectionCoverImageUrl = 'x'
        //   return it
        // })
      }
    }).catch((resError) => {
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }


  async getSocialmediaDataFormat(){

    let data:any = {}
    let socialmedia = this.userProfileInfos.socialmedia
    if(socialmedia.discord && socialmedia.discord.trim() != '') data.discord = socialmedia.discord
    if(socialmedia.facebook && socialmedia.facebook.trim() != '') data.facebook = socialmedia.facebook
    if(socialmedia.instagram && socialmedia.instagram.trim() != '') data.instagram = socialmedia.instagram
    if(socialmedia.twitter && socialmedia.twitter.trim() != '') data.twitter = socialmedia.twitter
    if(socialmedia.website && socialmedia.website.trim() != '') data.website = socialmedia.website.replace(/(^\w+:|^)\/\//, '');
    return data
  }

  async submitSetting(){
    let socialmedia = await this.getSocialmediaDataFormat()
    this.singletonService.updateCurrentUserProfileInfos(
      this.userProfileInfos.name,
      this.userProfileInfos.bio,
      socialmedia,
      this.userProfileInfos.email
    ).then(async (res:any) => {
      log.debug("xxxx res => %o",res)
      if(res){
        await this.singletonService.updateCurrentUserInfos(res)
        await this.ngOnInit()
        this.singletonService.fire("success","Success to save profile infos.")
      }
    }).catch((resError) => {
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }

  async updateCurrentUserProfileImageByType(file,type){
    const formData = new FormData();
    if(file) formData.append('file', file);
    if(type) formData.append('type', type);

    new Response(formData).text().then(log.debug) // debug fromData

    this.singletonService.updateCurrentUserProfileImageByType(formData).then(async (res:any) => {
      log.debug("xxxx res => %o",res)
      if(res){
        await this.singletonService.updateCurrentUserInfos(res)
        await this.ngOnInit()
        this.singletonService.fire("success","Success to save image.")
      }
    }).catch((resError) => {
      this.singletonService.fire("error",resError.title,resError.error)
    })
  }

  // clearPreview(elementId){
  //   let previewElement:any = document.getElementById(elementId)
  //   previewElement.style.backgroundImage = null;
  // }

  showPreview(file,elementId){
    const reader = new FileReader();
    reader.onload = e => {
      log.debug("reader.result %o",reader.result)

      let previewElement:any = document.getElementById(elementId)
      previewElement.style.backgroundImage = "url("+reader.result+")";
    };
    reader.readAsDataURL(file);
  }

  async handleFileInput_profileImage(files: FileList){
    let file = files.item(0)
    if(file){
      let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      if(isNotSupportFileExtension){
        return
      }
      let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      if(isOverSizeFile){
        return
      }
    }

    this.logoImage = files.item(0);
    await this.updateCurrentUserProfileImageByType(this.logoImage,'profile')
    await this.showPreview(this.logoImage,"input-logo-image")
  }
  async handleFileInput_bannerImage(files: FileList){
    let file = files.item(0)
    if(file){
      let isNotSupportFileExtension = await this.singletonService.isNotSupportFileExtension(file)
      if(isNotSupportFileExtension){
        return
      }
      let isOverSizeFile = await this.singletonService.isOverSizeFile(file)
      if(isOverSizeFile){
        return
      }
    }
    this.coverImage = files.item(0);
    await this.updateCurrentUserProfileImageByType(this.coverImage,'cover')
    await this.showPreview(this.coverImage,"input-banner-image")
  }

  async checkUserSlug(){
    log.debug("this.userProfileInfos.name => %o",this.userProfileInfos.name)
    if(this.userProfileInfos.name == '' || !this.userProfileInfos.name || this.userProfileInfos.name.length > 50){
      this.canUseSlug = false
    }else{
      if(this.oldName == this.userProfileInfos.name){
        this.canUseSlug = undefined
      }else{
        let res:any = await this.singletonService.checkUserSlug(this.userProfileInfos.name)
        log.debug("this.oldName => %o",this.oldName)
        if(res){
          this.canUseSlug = res.success
        }else{
          this.canUseSlug = false
        }
      }
    }
  }

  delayTimer
  isLoadingValidateSlug = false
  async isValidSlugWithDelay(){
    const self = this

    clearTimeout(self.delayTimer);
    self.isLoadingValidateSlug= true
    self.delayTimer = setTimeout(async function() {
      try{
        await self.checkUserSlug()
        self.isLoadingValidateSlug= false
      }catch(err){
        self.isLoadingValidateSlug= false
      }
    }, 500);

  }

}
