import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-section',
  templateUrl: './project-section.component.html',
  styleUrls: ['./project-section.component.scss']
})
export class ProjectSectionComponent implements OnInit {

  isLoading = false

  mockProjectsList = [
    {
      id: 1,
      name: "Desperado",
      description: "In DESPERADO B218, you will form a team of five heroes and face the rift walkers. Emerge victorious against the rift walkers. Claim victories, climb the ranks and you shall be rewarded handsomely. Are you ready to determine the fate of DESPERADO B218?",
      tags: ["P2E","RPG","Strategy"],
      featureImage: "https://cdn.guildfi.com/guildfi/image/upload/c_limit,w_640,f_auto/2_03b1cffbc5",
      // chains: [
      //   "bkc",
      //   "bsc"
      // ],
      // featuredTokens: []
    },
    {
      id: 2,
      name: "YES",
      description: "Yes Description",
      tags: ["Utility"],
      featureImage: "https://biuw5strgd.execute-api.ap-southeast-1.amazonaws.com/production/collections/yes-nft-exclusive/feature.jpg",
      // chains: [
      //   "bkc",
      //   "bsc"
      // ],
      // featuredTokens: []
    },
  ]

  projectsApiResult = {
    limit: 12,
    page: 1,
    results: this.mockProjectsList, // projects object
    totalPages: 1,
    totalResults: 2,
  }

  constructor(
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.fetchProjects()

  }

  async fetchProjects(){

  }

  goToProject(project){
    this.router.navigate(['project-detail/' + project.id],{})
  }

}
