import { DecimalPipe, formatNumber } from '@angular/common';
import { BigNumber } from 'bignumber.js';
import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../services/logger.service';
const log = new Logger('FormatNumberPipe'); // log.debug

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {
  }
  transform(value: any, ...args: any[]): any {
    let decimals = args[0]
    let format = args[1] ? args[1] : "1.0-6"
    // log.debug("formatNumber pipe...value :%o",value)
    // log.debug("formatNumber pipe...args :%o",args)
    //log.debug("formatNumber pipe...decimals :%o",decimals)
    //log.debug("formatNumber pipe...format :%o",format)

    // if(value instanceof  BigNumber){
    //   return value.toFixed(decimals)
    // }
    if(value == Infinity){
      return "∞"
    }
    if(value){
      let number = Number(value).toFixed(Number(decimals))
      return this._decimalPipe.transform(number,format)
    }else{
      return 0
    }
  }

}
