import { Component, OnInit } from '@angular/core';
import {BackendService} from "../../services/backend.service";
import {Logger} from '../../services/logger.service';
import {ActivatedRoute} from "@angular/router";
import {SingletonService} from "../../services/singleton.service";
import {FreeCityContractService} from "../../services/free-city-contract.service";
import {ethers} from "ethers";

const log = new Logger('OrderTradeComponent');
@Component({
  selector: 'app-order-trade',
  templateUrl: './order-trade.component.html',
  styleUrls: ['./order-trade.component.scss']
})
export class OrderTradeComponent implements OnInit {

  orderBookId: any;
  orderInfo: any;
  chain: any;
  account:any;
  isOwnOrder: boolean = false;
  isLoading: boolean = false;
  tokens:any;
  spend:any;
  receive:any;
  allowance:any;
  shouldApprove: boolean = false;

  constructor(
    private singletonService:SingletonService,
    private backendService:BackendService,
    private freeCityContractService:FreeCityContractService,
    private route: ActivatedRoute,
  ) {
    this.orderBookId = this.route.snapshot.paramMap.get('orderbookId');
    this.chain = this.route.snapshot.paramMap.get('chain');
  }

  async ngOnInit() {
    this.account = await this.singletonService.getCurrentConnectedAccount()
    this.orderInfo = await this.backendService.getOrderBook(this.chain+'-'+this.orderBookId)
    log.debug("order info ",this.orderInfo)
    log.debug("account ",this.account)
    this.isOwnOrder = (this.account.toLowerCase() === this.orderInfo.seller.toLowerCase())
    this.tokens = await this.freeCityContractService.getTokenInfoAndBalance(
      [this.orderInfo.itemAddr,this.orderInfo.acceptedTokenAddr],
      this.account,
      true
    )

    log.debug("tokens info ", this.tokens)
    this.receive = this.tokens[0]
    this.spend = this.tokens[1]

    this.orderInfo.acceptedTokenAmountEther = ethers.utils.formatUnits(this.orderInfo.acceptedTokenAmount, this.spend.decimals)
    this.orderInfo.itemAmountEther = ethers.utils.formatUnits(this.orderInfo.itemAmount, this.receive.decimals)
    await this.checkAllowance()
  }

  async checkAllowance(){
    log.debug("checkAllowance...")

    this.allowance = await this.freeCityContractService.checkP2PAllowance(this.spend.address)
    log.debug("allowance ",this.allowance)
    this.shouldApprove = (Number(this.allowance) < Number(this.orderInfo.acceptedTokenAmountEther))
    log.debug("this.orderInfo.acceptedTokenAmountEther ",this.orderInfo.acceptedTokenAmountEther)

    log.debug("this.shouldApprove ",this.shouldApprove)
  }

  async approve(){
    this.isLoading = true
    this.freeCityContractService.approveTokenP2P(
      this.spend.address,
      undefined//this.orderInfo.acceptedTokenAmount
    ).then(async (transaction) => {
      let receipt = await transaction.wait()
      setTimeout(async ()=>{
        await this.checkAllowance()
        this.isLoading = false
      },1000)
      this.singletonService.fire('success', 'Approve success', 'Approve success')
    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'Approve failed', 'Something went wrong please refresh and try again later.')
      this.isLoading = false
    })
  }

  async buy() {
    this.isLoading = true
    this.freeCityContractService.buyP2PWithERC20(
      this.orderInfo.orderbookId
    ).then(async (transaction) => {
      let receipt = await transaction.wait()
      setTimeout(async () => {
        await this.singletonService.fire('success', 'Buy success', 'Buy success, please wait to recapture status')
        this.isLoading = false
        window.location.reload()
      }, 3000)
    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'Buy failed', 'Something went wrong please refresh and try again later.')
      this.isLoading = false
    })
  }

  async cancel() {
    this.isLoading = true
    this.freeCityContractService.cancelP2PListing(
      this.orderInfo.orderbookId
    ).then(async (transaction) => {
      let receipt = await transaction.wait()
      setTimeout(async () => {
        await this.singletonService.fire('success', 'Cancel success', 'Cancel success, please wait to recapture status')
        this.isLoading = false
        window.location.reload()
      }, 3000)

    }).catch((error) => {
      log.error(error)
      this.singletonService.fire('error', 'Cancel failed', 'Something went wrong please refresh and try again later.')
      this.isLoading = false
    })
  }

  isInsufficient(){
    let isInsufficient = (Number(this.orderInfo.acceptedTokenAmountEther) > Number(this.spend.balance))
    return isInsufficient
  }

}
