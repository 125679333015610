import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explore-four',
  templateUrl: './explore-four.component.html',
  styleUrls: ['./explore-four.component.scss']
})
export class ExploreFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
